import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ClientFormIcon } from "../../icons/TaskListEdit.svg";

import { useCurrentUserRole } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { useLocation, useNavigate } from "react-router-dom";
import { ClientFormClientListDto } from "@growth-machine-llc/stridist-api-client";
import { colorSystem } from "../../theme";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.25, 3.25),
    borderRadius: theme.spacing(1),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    marginTop: theme.spacing(2.5),
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },

  icon: {
    marginRight: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  name: {
    fontSize: 18,
    fontWeight: 600,
  },

  status: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    "&::after": {
      content: "''",
      display: "inline-block",
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginLeft: theme.spacing(0.75),
    },
  },

  completed: {
    "&::after": {
      backgroundColor: colorSystem.green,
    },
  },
  incomplete: {
    "&::after": {
      backgroundColor: colorSystem.orange,
    },
  },
}));

export interface ClientFormCardItemProps extends BoxProps {
  clientForm: ClientFormClientListDto;
}

export function ClientFormCardItem(props: ClientFormCardItemProps) {
  const s = useStyles();
  const navigate = useNavigate();
  const { className, clientForm, ...other } = props;
  const { name, slug, created, submittedAt } = clientForm;
  const location = useLocation();
  const role = useCurrentUserRole();
  const href = `${
    role === UserRole.COACH ? location.pathname.replace("/overview", "") : ""
  }/forms/${slug}`;

  const handleClick = React.useCallback(() => navigate(href), [href]);

  return (
    <Box className={clsx(s.root, className)} onClick={handleClick} {...other}>
      <ClientFormIcon className={s.icon} />
      <Box>
        <Typography className={s.name}>{name}</Typography>
        <Typography
          className={clsx(s.status, submittedAt ? s.completed : s.incomplete)}
        >
          {created.format("MMM DD, YYYY")} •{" "}
          {submittedAt ? "Completed" : "Incomplete"}
        </Typography>
      </Box>
    </Box>
  );
}
