import React from "react";
import { UsersTable } from "../../../components/admin/UsersTable";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";

export function AdminUsersRoute() {
  return (
    <>
      <UsersTable />
      <TrackInfoTool
        trackInfo={{
          name: "Admin - Users",
        }}
      />
    </>
  );
}
