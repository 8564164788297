export const deleteWorkoutExercise = (
  workoutSectionId: number,
  exerciseId: number,
  inputContent: any,
) => {
  const parsedContent = JSON.parse(inputContent);

  const workoutSection = parsedContent.find(
    (section) => section.id === workoutSectionId,
  );
  if (!workoutSection) return;

  const updatedExercises = workoutSection.workout.exercises.filter(
    (exercise) => exercise.id !== exerciseId,
  );
  workoutSection.workout.exercises = updatedExercises;
  return JSON.stringify(parsedContent);
};
