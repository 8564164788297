import {
  Card,
  CardProps,
  CardHeader,
  Grid,
  Box,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { EmptyValueBars } from "../item/EmptyValueBars";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    "margin-right": theme.spacing(6),
  },
  empty: {
    "&:not(:last-child)": {
      paddingRight: theme.spacing(3),
    },
  },
  goals: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    marginTop: theme.spacing(4.5),

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
}));

export interface ClientGoalsCardSkeletonProps extends CardProps {}

export function ClientGoalsCardSkeleton(props: ClientGoalsCardSkeletonProps) {
  const s = useStyles();
  const { breakpoints } = useTheme();

  const isXs = useMediaQuery(breakpoints.down("xs"));
  const isSm = useMediaQuery(breakpoints.only("sm"));
  const isMdUp = useMediaQuery(breakpoints.up("md"));

  const skeletonCount = isSm ? 2 : isMdUp ? 3 : 0;

  return (
    <Card {...props} sx={{ padding: 3 }}>
      <CardHeader
        sx={{ "&.MuiCardHeader-root": { padding: "0 !important" } }}
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={44}
            height={44}
          />
        }
        title={
          <Grid item xs={6} sm={4} md={1}>
            <Skeleton animation="wave" height={30} />
          </Grid>
        }
        subheader={
          <Grid item xs={12} sm={6} md={2}>
            <Skeleton animation="wave" height={20} />
          </Grid>
        }
      />
      {!isXs && (
        <CardContent
          sx={{ "&.MuiCardContent-root": { padding: "0 !important" } }}
        >
          <Box className={s.goals}>
            {Array.from({ length: skeletonCount }).map((_, i) => (
              <EmptyValueBars key={i} className={s.empty} />
            ))}
          </Box>
        </CardContent>
      )}
    </Card>
  );
}
