import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CoachClientProgram } from "../../../../components/program/CoachClientProgram";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { toEnum } from "../../../../utils/misc";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import { CompletionPeriod } from "../../../../constants";
import { useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../../../services/EnrollmentsClientService";
import { useClient } from "../../../../hooks/useClient";
import { useCoachClientPrograms } from "../programs/CoachClientProgramsRouteWrapper";

export const COACH_CLIENT_ENROLLMENT_WEEKS_QUERY_KEY =
  "coach-client-enrollment-weeks";

export function CoachClientProgramRoute() {
  const { program } = useParams();
  const { enrollments } = useCoachClientPrograms();
  // TODO_API_V2_COACH_CLIENT_PROGRAM_COMPONENT STR-1359: Uncomment if Overview option for is integrated
  // const [period] = useQueryParam("period", CompletionPeriod.BEFORE_NOW, {
  //   normalize: (param) =>
  //     toEnum(param, CompletionPeriod, CompletionPeriod.BEFORE_NOW),
  // });

  const client = useClient();

  const { data: enrollment, isLoading } = useQuery({
    queryKey: [
      COACH_CLIENT_ENROLLMENT_WEEKS_QUERY_KEY,
      { program, client: client.id },
    ],
    queryFn: () =>
      EnrollmentsClientService.getClientEnrollmentProgram(program, client.id),
    enabled: !!enrollments,
  });

  return (
    <>
      <RefreshSlug
        slugInfo={{
          slug: enrollment?.slug,
          slugId: enrollment?.slugId,
        }}
      />
      <CoachClientProgram
        userName={client?.username}
        program={enrollment}
        programIsLoading={isLoading}
      />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Client Program",
          properties: {
            clientUsername: client?.username,
          },
        }}
      />
    </>
  );
}
