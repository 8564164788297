import React from "react";

import { LocalStorageKeys, UserRole } from "../constants";
import { nativeIntercom } from "../utils/intercom";

import { useAnalytics } from "./useAnalytics";
import { useCurrentWorkspace } from "./useCurrentWorkspace";
import { useCurrentUser } from "./useCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGOUT_ROUTE } from "../routes/routes";
import { useQueryClient } from "@tanstack/react-query";
import useAuth from "./auth/useAuth";
import { CURRENT_USER_QUERY_KEY } from "../wrappers/current-user/CurrentUserWrapper";

interface UseLogoutProps {
  returnUrl?: string;
  immediate?: boolean;
}
export function useLogout({ returnUrl, immediate }: UseLogoutProps = {}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [trackEvent] = useAnalytics();
  const { logout } = useAuth();
  const queryClient = useQueryClient();

  return React.useCallback(
    (onSuccess?: () => void) => {
      if (location.pathname === LOGOUT_ROUTE || immediate) {
        logout().finally(() => {
          localStorage.removeItem(LocalStorageKeys.FAVICON_LOGO_URL);
          nativeIntercom.logout();
          trackEvent("Logout");

          // Important: Clear all queries cache connections.
          queryClient.clear();

          returnUrl && navigate(returnUrl);
          !returnUrl && navigate(0);

          onSuccess && onSuccess();
        });
      } else {
        navigate(LOGOUT_ROUTE);
      }
    },
    [logout, trackEvent, returnUrl],
  );
}
