import React from "react";
import { CoachSettingsAccount } from "../../../../components/settings/CoachSettingsAccount";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useQuery } from "@tanstack/react-query";
import NotificationsSettingsService from "../../../../services/NotificationsSettingsService";

export const COACH_NOTIFICATIONS_SETTINGS_QUERY_KEY =
  "coach-notifications-settings";

export function CoachSettingsProfileRoute() {
  const me = useCurrentUser();
  const { data: settings } = useQuery({
    queryKey: [COACH_NOTIFICATIONS_SETTINGS_QUERY_KEY],
    queryFn: () =>
      NotificationsSettingsService.getCurrentUserNotificationsSettings(),
  });
  return (
    <>
      {settings && (
        <CoachSettingsAccount user={me} notificationsSetting={settings} />
      )}
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Settings",
        }}
      />
    </>
  );
}
