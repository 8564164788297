import React from "react";

import { SelectableItem, SelectableItemProps } from "./SelectableItem";
import { EnrollGroupDto } from "@growth-machine-llc/stridist-api-client";

export interface GroupAddableItemProps
  extends Pick<
    SelectableItemProps,
    "className" | "added" | "disabled" | "disabledText"
  > {
  group: EnrollGroupDto;
  onToggle: (added: boolean, group: EnrollGroupDto) => void;
}

export function GroupAddableItem(props: GroupAddableItemProps) {
  const { className, group, onToggle, ...other } = props;
  const { name, size } = group;

  const handleToggle: SelectableItemProps["onToggle"] = React.useCallback(
    (added) => {
      onToggle(added, group);
    },
    [group, onToggle],
  );

  return (
    <SelectableItem
      className={className}
      header={name}
      subheader={`${size} client${size === 1 ? "" : "s"}`}
      onToggle={handleToggle}
      {...other}
    />
  );
}
