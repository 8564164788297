import React from "react";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SkeletonCard } from "../../components/card/SkeletonCard";
import { Pagination } from "../../components/pagination/Pagination";
import { EnrollmentCard } from "../../components/card/EnrollmentCard";
import { PaginationContext } from "../../utils/paging";

import { EnrollmentDto } from "@growth-machine-llc/stridist-api-client";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { EnrollmentSort } from "../../constants";
import { useQueryParams } from "../../hooks/useQueryParams";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

export interface EnrollmentListProps {
  enrollments: EnrollmentDto[];
  programEnrollmentDataLoading: boolean;
  queryParams: any;
}

export const ENROLLMENT_STATS_QUERY_KEY = "enrollments-stats"

export function EnrollmentList(props: EnrollmentListProps) {
  const { enrollments, programEnrollmentDataLoading, queryParams } = props;
  const s = useStyles();
  const { first } = React.useContext(PaginationContext);

  const { data: programEnrollmentsStats, refetch: refetchProgramEnrollmentsStats } = useQuery({
    queryKey: [ENROLLMENT_STATS_QUERY_KEY],
    queryFn: async () => {
      const cachedStatsIds = programEnrollmentsStats.map((stat) => stat.enrollmentId);
      const idsToFetch = enrollments
        .filter((enrollment) => !cachedStatsIds.includes(enrollment.id))
        .map((enrollment) => enrollment.id) || [];

      if (idsToFetch.length === 0) {
        return programEnrollmentsStats;
      }

      const statsData = await EnrollmentsClientService.getEnrollmentsStats(idsToFetch);
      return [...programEnrollmentsStats, ...statsData];
    },
    initialData: [],
    placeholderData: keepPreviousData,
    enabled: !!enrollments,
  });

  React.useEffect(() => {
    if (enrollments) {
      refetchProgramEnrollmentsStats();
    }
  }, [enrollments]);

  const sortEnrollments = (enrollments: EnrollmentDto[], sort: EnrollmentSort) => {
    switch (sort) {
      case EnrollmentSort.NAME_ASC:
        return [...enrollments].sort((a, b) => a.user?.displayName?.localeCompare(b.user?.displayName ?? "") ?? 0);
      case EnrollmentSort.EMAIL_ASC:
        return [...enrollments].sort((a, b) => a.user?.email?.localeCompare(b.user?.email ?? "") ?? 0);
      case EnrollmentSort.SCORE_ASC:
        return [...enrollments].sort((a, b) => {
          const aScore = programEnrollmentsStats?.find((stat) => stat.clientId === a.user?.id)?.completionRate?.score || 0;
          const bScore = programEnrollmentsStats?.find((stat) => stat.clientId === b.user?.id)?.completionRate?.score || 0;
          return bScore - aScore;
        });
      case EnrollmentSort.CREATED_AT_DESC:
        return [...enrollments].sort((a, b) => {
          const aCreated = a.created ? a.created.valueOf() : 0;
          const bCreated = b.created ? b.created.valueOf() : 0;
          return bCreated - aCreated;
      });
      default:
        return enrollments;
    }
  };

  const sortedEnrollments = React.useMemo(
    () => sortEnrollments(enrollments, queryParams.sort as EnrollmentSort),
    [enrollments, queryParams.sort, programEnrollmentsStats]
  );

  return !programEnrollmentDataLoading ? (
    <Container className={s.root} maxWidth="md">
      {sortedEnrollments.length > 0 &&
        sortedEnrollments.map((enrollment) => {
          const enrollmentStats = programEnrollmentsStats?.find(
            (stat) => stat?.clientId === enrollment?.user?.id
          );
          
          return (
            <EnrollmentCard
              key={enrollment.id}
              className={s.card}
              enrollment={enrollment}
              score={enrollmentStats?.completionRate?.score}
              scoreLoading={!enrollmentStats}
            />
          );
        })}
      <Pagination totalCount={sortedEnrollments.length} perPage={first} />
    </Container>
  ) : (
    <Container className={s.root} maxWidth="md">
      {Array.from({ length: 3 }).map((_, i) => (
        <SkeletonCard key={i} className={s.card} />
      ))}
    </Container>
  );
}
