import React from "react";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SkeletonCard } from "../../components/card/SkeletonCard";
import { Pagination } from "../../components/pagination/Pagination";
import { GroupEnrollmentCard } from "../../components/groups/GroupEnrollmentCard";
import { PaginationContext } from "../../utils/paging";

import { EnrollmentGroupDto } from "@growth-machine-llc/stridist-api-client";
import { GroupEnrollmentSort } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  header: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
  },

  card: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

export interface GroupEnrollmentListProps {
  groupEnrollments: EnrollmentGroupDto[];
  programEnrollmentDataLoading: boolean;
  queryParams: any;
}

export function GroupEnrollmentList(props: GroupEnrollmentListProps) {
  const { groupEnrollments, programEnrollmentDataLoading, queryParams } = props;
  const s = useStyles();
  const { first } = React.useContext(PaginationContext);

  const sortGroupEnrollments = (
    groupEnrollments: EnrollmentGroupDto[],
    sort: GroupEnrollmentSort,
  ) => {
    switch (sort) {
      case GroupEnrollmentSort.NAME_ASC:
        return groupEnrollments.sort(
          (a, b) => a.group?.name?.localeCompare(b.group?.name ?? "") ?? 0,
        );
      case GroupEnrollmentSort.CREATED_AT_ASC:
        return groupEnrollments.sort((a, b) =>
          a.group?.created?.isBefore(b.group?.created ?? "") ? -1 : 1,
        );
      default:
        return groupEnrollments;
    }
  };

  const sortedGroupEnrollments = React.useMemo(
    () =>
      sortGroupEnrollments(
        groupEnrollments,
        queryParams.sort as GroupEnrollmentSort,
      ),
    [groupEnrollments, queryParams.sort],
  );

  return !programEnrollmentDataLoading ? (
    <Container className={s.root} maxWidth="md">
      {sortedGroupEnrollments.length > 0 &&
        sortedGroupEnrollments.map((group) => (
          <GroupEnrollmentCard
            key={group.id}
            className={s.card}
            groupEnrollment={group}
          />
        ))}
      <Pagination totalCount={sortedGroupEnrollments.length} perPage={first} />
    </Container>
  ) : (
    <Container className={s.root} maxWidth="md">
      {Array.from({ length: 3 }).map((_, i) => (
        <SkeletonCard key={i} className={s.card} />
      ))}
    </Container>
  );
}
