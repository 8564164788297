import React from "react";
import { Box, Card, Skeleton } from "@mui/material";

const HabitCheckInsSkeleton = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginBottom: 2,
        }}
      >
        <Skeleton width={200} />
        <Skeleton width={150} height={20} />
      </Box>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Box sx={{ marginRight: 5 }}>
          <Skeleton width={40} height={60} />
        </Box>
        <Box sx={{ flexGrow: 1, padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Skeleton width={150} />
            <Skeleton width="20%" />
            <Skeleton width="20%" />
            <Skeleton width="20%" />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default HabitCheckInsSkeleton;
