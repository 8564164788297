import React, { useEffect, useState } from "react";
import { Box, IconButton, Divider, Menu } from "@mui/material";
import { Icons } from "../../plate-ui/Icons/icons";
import { ChevronDown, Link2, Pen, Trash, Type } from "lucide-react";
import { LinkPreviewVariant } from "../types/links";
import MinimizedTooltip from "../../tooltip/MinimizedTooltip";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useCreateCustomAsset } from "../../component-library/hooks/useCreateCustomAsset";
import {
  getMediaElementMenuActions,
  MediaAlign,
} from "../menus/MediaElementMenu";
import { useEditorComponent } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.editor.border,
    borderWidth: 1,
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  active: {
    "& svg": {
      color: theme.palette.common.black,
      transition: "color 0.1s ease-in",
    },
    "&:hover": {
      backgroundColor: theme.palette.editor.background,
      "& svg": {
        color: `${theme.palette.common.black} !important`,
      },
    },
    backgroundColor: theme.palette.editor.background,
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.editor.background,
      "& svg": {
        color: theme.palette.editor.icon,
      },
      transition: "background-color 0.1s ease-in, color 0.1s ease-in",
    },
    "& svg": {
      color: theme.palette.text.primary,
      width: 24,
      height: 24,
      transition: "color 0.1s ease-in",
    },
  },
  divider: {
    backgroundColor: theme.palette.editor.border,
    borderColor: theme.palette.editor.border,
  },
}));

export const EMBED_WEBSITE = "embed_website";

export enum EmbedElementType {
  BOOKMARK = "Bookmark",
  EMBED = "Embed",
  VIDEO = "Video",
  AUDIO = "Audio",
  IMAGE = "Image",
  FILE = "File",
}

export interface Action {
  icon: React.ReactElement;
  action?: (event: any) => void;
  label: string;
  index?: number;
  dropdownMenu?: {
    index?: number;
    icon: React.ReactElement;
    action: (event: any) => void;
  }[];
}

interface IMoreMenuProps {
  handleRemoveNode: (event: any) => void;
  empty?: boolean;
  setOpenDialog?: (open: boolean) => void;
  type?: EmbedElementType;
  setType?: (type: LinkPreviewVariant) => void;
  handleCrop?: (event: any) => void;
  element?: Element;
  activeIndex?: number;
  handleChangeClick?: (event: React.MouseEvent) => void;
  handleCoverChange?: (event: React.MouseEvent, value: string) => void;
  handleAlignChange?: (align: MediaAlign) => void;
  isMenuOpen?: boolean;
}

const DefaultActions = (handleRemoveNode: (event: any) => void): Action[] => [
  {
    icon: <Trash fontSize="small" />,
    action: handleRemoveNode,
    label: "Delete",
  },
];

const BookmarkActions = (
  handleRemoveNode: (event: any) => void,
  setOpenDialog: (open: boolean) => void,
  setType: (type: LinkPreviewVariant) => void,
  setActiveIndex: (index: number) => void,
): Action[] => [
  {
    index: 0,
    icon: <Link2 fontSize="small" />,
    action: () => {
      setActiveIndex(0);
      setType(LinkPreviewVariant.INLINE);
    },
    label: "Only URL",
  },
  {
    index: 1,
    icon: <Type fontSize="small" />,
    action: () => {
      setActiveIndex(1);
      setType(LinkPreviewVariant.TEXT);
    },
    label: "Preview",
  },
  {
    index: 2,
    icon: <Icons.bookText fontSize="small" />,
    action: () => {
      setActiveIndex(2);
      setType(LinkPreviewVariant.RICH);
    },
    label: "Preview with image",
  },
  {
    icon: <Pen fontSize="small" />,
    action: () => setOpenDialog(true),
    label: "Replace",
  },
  {
    icon: <Trash fontSize="small" />,
    action: handleRemoveNode,
    label: "Delete",
  },
];

const EmbedActions = (
  handleRemoveNode: (event: any) => void,
  setOpenDialog: (open: boolean) => void,
): Action[] => [
  {
    icon: <Trash fontSize="small" />,
    action: handleRemoveNode,
    label: "Delete",
  },
  {
    icon: <Pen fontSize="small" />,
    action: () => setOpenDialog(true),
    label: "Replace",
  },
];

const getActions = (
  type: EmbedElementType,
  props: IMoreMenuProps,
  setActiveItemIndex: (index: number) => void,
  createAsset: any,
  component: any,
): Action[] => {
  const {
    handleRemoveNode,
    setOpenDialog,
    handleChangeClick,
    handleCoverChange,
    handleCrop,
    handleAlignChange,
    setType,
    element,
  } = props;

  switch (type) {
    case EmbedElementType.IMAGE:
      return getMediaElementMenuActions({
        element: element,
        onChangeClick: handleChangeClick,
        onRemove: handleRemoveNode,
        onCropClick: handleCrop,
        onCoverChange: handleCoverChange,
        onSetAlign: handleAlignChange,
        setActiveItemIndex: setActiveItemIndex,
        createAsset,
        component,
      });
    case EmbedElementType.VIDEO:
      return getMediaElementMenuActions({
        element: element,
        onChangeClick: handleChangeClick,
        onRemove: handleRemoveNode,
        onCoverChange: handleCoverChange,
        createAsset,
        component,
      });
    case EmbedElementType.AUDIO:
      return getMediaElementMenuActions({
        element: element,
        onChangeClick: setOpenDialog,
        onRemove: handleRemoveNode,
        createAsset,
        component,
      });
    case EmbedElementType.BOOKMARK:
      return BookmarkActions(
        handleRemoveNode,
        setOpenDialog,
        setType,
        setActiveItemIndex,
      );
    case EmbedElementType.EMBED:
      return EmbedActions(handleRemoveNode, setOpenDialog);
    case EmbedElementType.FILE:
      return getMediaElementMenuActions({
        element: element,
        onChangeClick: setOpenDialog,
        onRemove: handleRemoveNode,
        createAsset,
        component,
      });
    default:
      return [];
  }
};

export const MoreMenu = (props: IMoreMenuProps) => {
  const { handleRemoveNode, empty, type, activeIndex, isMenuOpen } = props;
  const s = useStyles();
  const [activeItemIndex, setActiveItemIndex] = useState<number>(
    activeIndex ?? undefined,
  );

  const popupState = usePopupState({
    variant: "popover",
    popupId: "alignment-menu",
  });

  useEffect(() => {
    !isMenuOpen && popupState.close();
  }, [isMenuOpen]);

  const { mutate: createAsset } = useCreateCustomAsset();
  const component = useEditorComponent();

  const actions = empty
    ? DefaultActions(handleRemoveNode)
    : getActions(type, props, setActiveItemIndex, createAsset, component);

  return (
    <Box
      className={s.root}
      display="flex"
      alignItems="center"
      bgcolor="background.paper"
      zIndex={1}
      position="absolute"
      borderRadius={1}
      py={0.5}
    >
      {actions.map((action, index, actions) => (
        <React.Fragment key={index}>
          {index > 0 &&
            !(
              action.index !== undefined &&
              actions[index - 1].index !== undefined
            ) && (
              <Divider className={s.divider} orientation="vertical" flexItem />
            )}
          <MinimizedTooltip title={action.label}>
            {action.dropdownMenu ? (
              <React.Fragment>
                <IconButton
                  className={clsx(s.iconButton, {
                    [s.active]: popupState.isOpen,
                  })}
                  disableRipple
                  {...bindTrigger(popupState)}
                  size="small"
                >
                  {action.icon}
                  <ChevronDown fontSize={"small"}></ChevronDown>
                </IconButton>
                <Menu
                  MenuListProps={{
                    sx: {
                      py: (theme) => theme.spacing(0.5),
                      borderColor: (theme) => theme.palette.editor.border,
                      borderWidth: 1,
                      borderRadius: 1,
                    },
                  }}
                  {...bindMenu(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {action.dropdownMenu.map((item, index) => (
                    <Box
                      sx={{
                        my: 0.2,
                      }}
                      key={index}
                    >
                      <IconButton
                        className={clsx(s.iconButton, {
                          [s.active]:
                            item.index !== undefined &&
                            item.index === activeItemIndex,
                        })}
                        disableRipple
                        key={index}
                        onClick={(e) => {
                          item.action(e);
                          popupState.close();
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        size="small"
                      >
                        {item.icon}
                      </IconButton>
                    </Box>
                  ))}
                </Menu>
              </React.Fragment>
            ) : (
              <IconButton
                className={clsx(s.iconButton, {
                  [s.active]:
                    action.index !== undefined &&
                    action.index === activeItemIndex,
                })}
                disableRipple
                onClick={action.action}
                size="small"
              >
                {action.icon}
              </IconButton>
            )}
          </MinimizedTooltip>
        </React.Fragment>
      ))}
    </Box>
  );
};
