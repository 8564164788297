import { InfiniteData } from "@tanstack/react-query";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import { ACTIVITY_LIST_QUERY_KEY } from "../CoachActivity";
import {
  ActivityDto,
  IActivitiesCompositeCursorPaginatedList,
} from "@growth-machine-llc/stridist-api-client";
import ActivitiesService from "../../../services/ActivitiesService";
import { ActivityStatus } from "../../../constants";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";

export const useCoachToggleArchiveActivityMutation = () => {
  const updateFn = INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem;

  return useOptimisticUpdateMutation({
    queryKey: [ACTIVITY_LIST_QUERY_KEY],
    mutationFn: ActivitiesService.coachToggleArchiveActivity,
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<IActivitiesCompositeCursorPaginatedList>,
        { id },
      ) => {
        return updateFn<ActivityDto>(oldData, id);
      },
      predicateFn: (queryKey, { archived }) => {
        return predicateQueryKeyByParams(queryKey, {
          status: archived ? ActivityStatus.ACTIVE : ActivityStatus.ARCHIVED,
        });
      },
    },
    invalidateQueryOptions: {
      enabled: true,
      predicateFn: (queryKey, { archived }) =>
        predicateQueryKeyByParams(queryKey, {
          status: archived ? ActivityStatus.ARCHIVED : ActivityStatus.ACTIVE,
        }),
    },
  });
};
