import clsx from "clsx";
import React from "react";
import { Card, CardContent, Container, Skeleton, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PageSkeletonProps } from "./PageSkeleton";
import { DefaultLoader } from "./DefaultLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
    padding: 0,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 0),
    },
  },
  card: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

export interface ProgramComponentsSkeletonProps extends PageSkeletonProps {}

export function ProgramComponentsSkeleton(
  props: ProgramComponentsSkeletonProps,
) {
  const { className, cardsCount = 3, ...other } = props;
  const s = useStyles();

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Box>
          <Skeleton height={33} variant="text" width={100} animation="wave" />
          <Skeleton height={25} variant="text" width={150} animation="wave" />
        </Box>
        <DefaultLoader size="extraLarge" />
      </Box>
      <Skeleton
        variant="text"
        height={35}
        width={100}
        animation="wave"
        sx={{ mb: 4 }}
      />
      {Array.from({ length: cardsCount }).map((_, i) => (
        <Card key={i} className={s.card}>
          <CardContent>
            <Skeleton variant="text" width={200} animation="wave" />
            <Skeleton variant="text" width={150} animation="wave" />
          </CardContent>
        </Card>
      ))}
    </Container>
  );
}
