import {
  IUpdateGroupClientsCommand,
  UpdateGroupClientsCommand,
} from "@growth-machine-llc/stridist-api-client";
import { clientGroupsClient } from "../api/ApiClients";

import ServiceBase from "./ServiceBase";

class ClientGroupsService extends ServiceBase {
    async updateGroupMembers(data: IUpdateGroupClientsCommand) {
        const command = UpdateGroupClientsCommand.fromJS(data);
        return clientGroupsClient.updateGroupClients(command);
    }

    async removeGroupMember(groupId: number, clientId: number) {
        return clientGroupsClient.removeGroupMember(groupId, clientId);
    }
}

export default new ClientGroupsService();
