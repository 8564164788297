import ClientGoalsClientService from "../../../services/ClientGoalsClientService";
import { PaginatedListOfClientGoalDto } from "@growth-machine-llc/stridist-api-client";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import { CLIENT_GOALS_LIST_QUERY_KEY } from "../../../components/card/ClientGoalsCard";

export default function useDeleteClientGoalMutation(clientId: number) {
  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_GOALS_LIST_QUERY_KEY, { clientId }],
    mutationFn: ClientGoalsClientService.deleteClientGoal,
    optimisticUpdater: {
      updateFn: (store: PaginatedListOfClientGoalDto, id) => {
        const deletedGoal = store.items?.find(
          (clientGoal) => clientGoal.id === id,
        );

        if (deletedGoal) {
          store.items = store.items?.filter(
            (clientGoal) => clientGoal.id !== id,
          );
        }
        store.totalCount = store.totalCount - 1;
        return store;
      },
    },
    invalidateQueryOptions: {
      enabled: true,
      predicateFn: (queryKey) =>
        predicateQueryKeyByParams(queryKey, {
          clientId,
        }),
    },
  });
}
