import clsx from "clsx";
import React from "react";
import { Box, BoxProps, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SearchField } from "../fields/SearchField";
import { ClientSort, clientSortOptions } from "../../constants";

import CoachClientsSortHeader from "./CoachClientsSortHeader";
import { TagDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  search: {
    backgroundColor: theme.palette.background.paper,
  },

  sort: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    fontWeight: 500,

    '& [aria-haspopup="listbox"]': {
      color: theme.palette.text.secondary,

      "&::before": {
        content: '"Sort by: "',
      },
    },
  },

  sortOption: {
    fontWeight: 500,
  },

  noVisibleItem: {
    display: "none",
  },
}));

export interface CoachClientsFilterProps extends BoxProps {
  query: string;
  sortKey: ClientSort;
  tagList: TagDto[];

  onChangeQuery?: (query: string) => void;
  onChangeSortKey?: (sortKey: ClientSort) => void;
  onChangeFilterTagId?: (tagId: number) => void;
  filterTag?: number;
  disabled: boolean;
}

export function CoachClientsFilter(props: CoachClientsFilterProps) {
  const s = useStyles();
  const {
    className,
    query,
    sortKey,
    tagList,
    onChangeQuery,
    onChangeSortKey,
    onChangeFilterTagId,
    filterTag,
    disabled,
    ...other
  } = props;

  const handleChangeQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const query = event.currentTarget.value;

      if (onChangeQuery) {
        onChangeQuery(query);
      }
    },
    [onChangeQuery],
  );

  const handleChangeSort = React.useCallback(
    (event) => {
      const sortKey = event.target.value as ClientSort;

      if (onChangeSortKey) {
        onChangeSortKey(sortKey);
      }
    },
    [onChangeSortKey],
  );

  const handleChangeSortNameProgram = React.useCallback(
    (sortByName) => {
      if (onChangeSortKey)
        onChangeSortKey(
          sortByName
            ? sortKey === ClientSort.NAME_ASC
              ? ClientSort.NAME_DESC
              : ClientSort.NAME_ASC
            : sortKey === ClientSort.PROGRAM_END_ASC
              ? ClientSort.PROGRAM_END_DESC
              : ClientSort.PROGRAM_END_ASC,
        );
    },
    [onChangeSortKey, sortKey],
  );

  const itemClassName = React.useCallback(
    (label) => {
      switch (label) {
        case clientSortOptions.NAME_ASC:
          return s.noVisibleItem;
        case clientSortOptions.NAME_DESC:
          return s.noVisibleItem;
        case clientSortOptions.PROGRAM_END_ASC:
          return s.noVisibleItem;
        case clientSortOptions.PROGRAM_END_DESC:
          return s.noVisibleItem;
        default:
          return;
      }
    },
    [s],
  );

  const valueSort = React.useMemo(() => {
    switch (sortKey) {
      case ClientSort.NAME_ASC:
        return "";
      case ClientSort.NAME_DESC:
        return "";
      case ClientSort.PROGRAM_END_ASC:
        return "";
      case ClientSort.PROGRAM_END_DESC:
        return "";
      default:
        return sortKey;
    }
  }, [sortKey]);

  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <SearchField
          className={s.search}
          variant="outlined"
          fullWidth
          placeholder="Search clients..."
          value={query}
          onChange={handleChangeQuery}
        />

        <Select
          className={s.sort}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          variant="outlined"
          fullWidth
          value={valueSort}
          onChange={handleChangeSort}
        >
          {Object.entries(clientSortOptions).map(([key, label]) => (
            <MenuItem
              className={clsx(s.sortOption, itemClassName(label))}
              key={key}
              value={key}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <CoachClientsSortHeader
        sortKey={sortKey}
        handleChangeSortNameProgram={handleChangeSortNameProgram}
        tagList={tagList}
        onChangeFilterTagId={onChangeFilterTagId}
        filterTag={filterTag}
        disabled={disabled}
      />
    </>
  );
}
