import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  FormControl,
  FormLabel,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton } from "../button/ActionButton";

import { MealLoggingSettingsDrawer } from "./MealLoggingSettingsDrawer";
import { GetWorkspaceClientPortalDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  formControl: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(3),

    "& > label": {
      flexGrow: 1,
    },
  },

  primaryText: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 600,
  },

  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export interface MealLoggingSettingsProps extends BoxProps {
  workspace: GetWorkspaceClientPortalDto;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked?: boolean,
  ) => void;
}

export function MealLoggingSettings(props: MealLoggingSettingsProps) {
  const { className, workspace, onChange, ...other } = props;
  const { mealLoggingQuestionsTotal } = workspace;
  const s = useStyles();
  const [enabled, setEnabled] = React.useState(workspace.mealLogging);
  const [editQuestions, setEditQuestions] = React.useState(false);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setEnabled(checked);

      if (onChange) {
        onChange(event, checked);
      }
    },
    [onChange],
  );

  const handleEditQuestionsOpen = React.useCallback(() => {
    setEditQuestions(true);
  }, []);

  const handleEditQuestionsClose = React.useCallback(() => {
    setEditQuestions(false);
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <FormControl component="div" className={s.formControl}>
        <FormLabel htmlFor="mealLogging">
          <Typography className={s.primaryText}>Enable meal logging</Typography>
          <Typography className={s.secondaryText}>
            If enabled, clients can log meals from the home calendar
          </Typography>
        </FormLabel>

        <Switch
          id="mealLogging"
          name="mealLogging"
          checked={enabled}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl component="fieldset" className={s.formControl}>
        <FormLabel>
          <Typography className={s.primaryText}>
            Meal logging prompts
          </Typography>
          <Typography className={s.secondaryText}>
            {mealLoggingQuestionsTotal} prompt
            {mealLoggingQuestionsTotal > 1 ? "s" : ""}
          </Typography>
        </FormLabel>

        <ActionButton onClick={handleEditQuestionsOpen}>Customize</ActionButton>
      </FormControl>

      {editQuestions && (
        <MealLoggingSettingsDrawer
          workspace={workspace}
          onClose={handleEditQuestionsClose}
          open
        />
      )}
    </Box>
  );
}
