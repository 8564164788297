import clsx from "clsx";
import React, { useEffect } from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  debounce,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";
import { useQueryParam } from "../../hooks/useQueryParam";
import { showToastAlert } from "../../utils/toast";
import { EditorCoverImageContext } from "../../hooks/useEditorCoverImage";
import { usePreview } from "../../hooks/usePreview";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ComponentSchedule } from "../schedule/ComponentSchedule";
import {
  EditorComponentItemDisabledContext,
  CheckInComponentContext,
  useNewEditor,
} from "../new-editor/hooks";
import {
  getComponentSpecifics,
  getComponentDefaultTitle,
} from "../../utils/component";
import { HabitPromptEdit } from "../habit-prompt/HabitPromptEdit";
import { HabitPrompt } from "../habit-prompt/HabitPrompt";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { ComponentPublishButton } from "../program/ComponentPublishButton";
import { useHistoryBlock } from "../history-block/hooks";
import { CoachComponentTitle } from "../program-component/CoachComponentTitle";
import { ComponentIcon } from "../program-component/ComponentIcon";
import {
  ComponentType,
  CoachComponentSavingState,
  joinedComponentSlugs,
  ComponentRepeat,
  ReminderType,
} from "../../constants";
import { Schedule } from "../schedule/types";
import { colorSystem } from "../../theme";
import {
  COMPONENT_TEMPLATES_LIST_QUERY_KEY,
  ComponentTemplate,
} from "../component-template/ComponentTemplateDialog";
import { AccordionProps } from "../accordion/Accordion";
import { EditorDirty } from "../../hooks/useSetDirty";

import { CoachComponentBar } from "./CoachComponentBar";
import { MessageComponentPanel } from "./MessageComponentPanel";
import {
  getEmptyNode,
  isEmptyContent,
  unfixLegacyContent,
} from "../editor/utils/common";
import { useLocation, useParams } from "react-router-dom";
import { WorkoutAddButton } from "../workout/WorkoutAddButton";
import { UseComponentTemplate } from "../component-template/UseComponentTemplate";
import { EditorElementView } from "../new-editor/utils/editorUtils";
import { menuItemDisabled } from "../new-editor/utils/menuItemUtil";
import ComponentBarHeightContext from "../../contexts/ComponentBarHeightContext";
import { getCookie } from "./utils";
import JsonEditor from "../editor/JsonEditor";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";
import { useProgramWeeks } from "../../hooks/useProgramWeeks";
import {
  ComponentDto2,
  IUpdateComponentCommand,
  UpdateComponentCommand,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ComponentsService from "../../services/ComponentsService";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { ComponentStatus } from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import { updateComponentProps } from "../../redux/curriculum/curriculum-slice";
import {
  selectComponentTempIdsMap,
  selectWeekTempIdsMap,
} from "../../redux/api/selectors";
import { COACH_PROGRAM_COMPONENT_QUERY_KEY } from "../../routes/coach/programs/preview/component/CoachProgramComponentPreviewRoute";
import { CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY } from "../../routes/coach/client/calendar/CoachClientCalendarRoute";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { setShouldRefetchCurriculum } from "../../redux/api/api-slice";

const ComponentEditor = React.lazy(
  () => import("../new-editor/ComponentEditor"),
);

const useStyles = makeStyles((theme) => {
  return {
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },

    root: {
      paddingTop: theme.spacing(6.5),
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(12),
        maxWidth: 1100,
      },
    },

    settings: {
      margin: theme.spacing(4, 0),
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },

    previewHeader: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2, 0, 1.5),
    },

    icon: {
      marginRight: theme.spacing(1),
    },

    previewComponentType: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },

    previewTitle: {
      fontSize: 24,
      fontWeight: "bold",
      color: theme.palette.common.black,
      wordBreak: "break-word",
    },

    spinner: {
      color: theme.palette.activity.checkin,
      marginTop: theme.spacing(0.25),
    },

    publishing: {
      "& .MuiAlert-root": {
        boxShadow: theme.shadows[4],
        borderRadius: theme.spacing(0.5),
        paddingRight: theme.spacing(10),
        color: theme.palette.activity.checkin,
        backgroundColor: theme.palette.common.white,
      },
    },

    previewBarPublishButton: {
      fontSize: 13,
      fontWeight: "bold",
      lineHeight: "16px",
      padding: theme.spacing(1.25, 2),
      color: theme.palette.common.white,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: `${theme.palette.primary.main}4D`,
      marginRight: theme.spacing(2),

      "&.Mui-disabled": {
        color: `${colorSystem.white}4D`,
      },
    },

    editing: {
      paddingTop: theme.spacing(16),
    },

    mobileContainer: {
      paddingTop: "148px",
    },
  };
});

type SaveInput = Omit<UpdateComponentCommand, "days" | "init" | "toJSON"> & {
  status?: ComponentStatus;
  days?: Schedule["days"];
};

const slugRegexp = new RegExp(`/(${joinedComponentSlugs})s?/.*`);

export interface CoachComponentProps extends Omit<ContainerProps, "children"> {
  componentData: ComponentDto2;
  readOnly?: boolean;
  autoSaveDelay?: number;
  handleCloseDialog?: () => void;
  programName?: string;
}

export function CoachComponent(props: CoachComponentProps) {
  const {
    className,
    componentData: component,
    readOnly,
    autoSaveDelay = 2000,
    handleCloseDialog,
    programName,
  } = props;
  const dispatch = useCurriculumDispatch();
  const tempIdsMap = useCurriculumSelector(selectComponentTempIdsMap);
  const tempWeeksIdsMap = useCurriculumSelector(selectWeekTempIdsMap);
  const { slug } = useParams();
  const user = useCurrentUser();
  const componentType = component.type as ComponentType;
  const defaultTitle = getComponentDefaultTitle(componentType);
  const [version, setVersion] = React.useState(Date.now());

  const autoSaveEnabled = !readOnly;
  const [savingState, setSavingState] = React.useState(
    CoachComponentSavingState.EMPTY,
  );
  const [title, setTitle] = React.useState(
    defaultTitle === component.title ? "" : component.title,
  );
  const {
    weekId,
    days,
    duration,
    repeat,
    reminderType,
    reminderTime,
    messageTime,
  } = component;

  const [schedule, setSchedule] = React.useState<Schedule>({
    weekId: weekId,
    days: JSON.parse(days),
    duration,
    repeat: repeat as ComponentRepeat,
    reminderType: reminderType as ReminderType,
    reminderTime: reminderTime,
    messageTime: messageTime,
  });

  useEffect(() => {
    setSchedule({
      weekId: weekId,
      days: JSON.parse(days),
      duration,
      repeat: repeat as ComponentRepeat,
      reminderType: reminderType as ReminderType,
      reminderTime: reminderTime,
      messageTime: messageTime,
    });
  }, [component]);

  const [habitPrompt, setHabitPrompt] = React.useState(component.habitPrompt);

  const editor = useNewEditor();
  const componentContent = component.content
    ? JSON.parse(component.content)
    : null;

  const [content, setContent] = React.useState<Node[] | null>(
    componentContent &&
      !(Array.isArray(componentContent) && componentContent.length === 0)
      ? componentContent
      : [getEmptyNode()],
  );

  const devCookie = getCookie("stabilization");

  const [messageContent, setMessageContent] = React.useState<Node[] | null>(
    JSON.parse(component.messageContent) || [],
  );

  const [validationErrors, setValidationErrors] = React.useState<any>(null);
  const [dirty, setDirty] = React.useState(false);
  const [saved, setSaved] = React.useState(true);
  const [preview, setPreview] = usePreview();
  const location = useLocation();
  const [previewQueryParam] = useQueryParam("preview", false);
  const s = useStyles();
  const handleError = useGenericErrorHandler({
    setFieldError: setValidationErrors,
  });
  const { showToastAlert, updateToastAlert, closeToastAlert } = useToastAlert();
  const [unsafe, historyBlockUpdate] = useHistoryBlock();
  const [schedulePanelOpen, setSchedulePanelOpen] = React.useState(false);
  const [promptPanelOpen, setPromptPanelOpen] = React.useState(false);
  const [messagePanelOpen, setMessagePanelOpen] = React.useState(false);
  const programWeeks = useProgramWeeks();

  React.useEffect(() => {
    if (promptPanelOpen && (schedulePanelOpen || messagePanelOpen)) {
      setPromptPanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulePanelOpen, messagePanelOpen]);

  React.useEffect(() => {
    if (schedulePanelOpen && (promptPanelOpen || messagePanelOpen)) {
      setSchedulePanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptPanelOpen, messagePanelOpen]);

  React.useEffect(() => {
    if (messagePanelOpen && (schedulePanelOpen || promptPanelOpen)) {
      setMessagePanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulePanelOpen, promptPanelOpen]);

  React.useEffect(() => {
    historyBlockUpdate(dirty);
  }, [dirty, historyBlockUpdate]);

  const { typeName } = getComponentSpecifics(componentType);

  const [textFieldContent, setTextFieldContent] = React.useState<string>(
    JSON.stringify(content, null, 2),
  );

  const save = React.useCallback(
    (data: SaveInput, callback?: (error?: boolean) => void) => {
      /*
        *** Drafting logic must be refactored ***

        It is intentional. The idea of using "debounce" function
          inside React component wasn't a good one. That mechanism
          should be overwritten (and probably generalized) as soon
          as we have a chance.
      */
      setDirty(false);
      setSavingState(CoachComponentSavingState.SAVING);
      const input: IUpdateComponentCommand = {
        ...data,
        id: component.id,
        days: JSON.stringify(data.days),
        weekId: tempWeeksIdsMap[data.weekId] ?? data.weekId,
      };

      if (!input.title) {
        input.title = defaultTitle;
      }

      setValidationErrors(null);

      saveComponent(input, {
        onSuccess: () => {
          setSaved(true);
          setDirty(false);
          setSavingState(CoachComponentSavingState.SAVED);
          historyBlockUpdate(false);
          if (data.status === ComponentStatus.PUBLISHED) {
            closeAfterSave();
          }
          if (programName?.startsWith("@")) {
            queryClient.invalidateQueries({
              queryKey: [COMPONENT_TEMPLATES_LIST_QUERY_KEY],
              exact: false,
              refetchType: "none",
            });
          }
          if (
            input.status === ComponentStatus.PUBLISHED &&
            input.duration &&
            input.duration > 1
          ) {
            dispatch(setShouldRefetchCurriculum(true));
          }
        },
        onError: (error) => {
          setDirty(true);
          handleError(error);
          setSavingState(CoachComponentSavingState.EMPTY);
        },
        onSettled(_, error) {
          callback && callback(!!error);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSave = React.useMemo(() => debounce(save, autoSaveDelay), []);

  const getDataToSave = React.useCallback(
    (update: SaveInput = {}) => {
      const isContentUpdated = !!update.content;
      if (isContentUpdated) {
        update.content = JSON.stringify(
          unfixLegacyContent(JSON.parse(update.content)),
        );
      }
      return {
        ...schedule,
        messageTimeString: schedule.messageTime || null,
        reminderTimeString: schedule.reminderTime || null,
        title,
        habitPrompt,
        ...(!isContentUpdated && {
          content: JSON.stringify(unfixLegacyContent(content)),
        }),
        ...update,
      };
    },
    [content, schedule, title, habitPrompt],
  );

  const handleChange = React.useCallback(
    (update: SaveInput = {}) => {
      setDirty(true);
      setSaved(false);

      if (autoSaveEnabled) {
        const data = getDataToSave(update);

        handleSave(data);
      }
    },
    [autoSaveEnabled, getDataToSave, handleSave],
  );

  const handlePublish = React.useCallback(() => {
    const toastId = showToastAlert("loading", {
      message: "Publishing...",
    });
    save(
      getDataToSave({
        status: ComponentStatus.PUBLISHED as ComponentStatus,
      }),
      (error?: boolean) => {
        !error &&
          updateToastAlert(toastId, {
            severity: "success",
            message: "Published!",
          });
        closeToastAlert(toastId);
      },
    );
  }, [s.spinner, s.publishing, save, getDataToSave]);

  const handleTitleChange = React.useCallback(
    (event) => {
      const title = event.target.value;

      setTitle(title);
      handleChange({ title });
    },
    [handleChange],
  );

  const handleSchedulePanelChange: AccordionProps["onChange"] =
    React.useCallback(
      (_, expanded) => {
        if (schedulePanelOpen !== expanded) {
          setSchedulePanelOpen(expanded);
        }
      },
      [schedulePanelOpen],
    );

  const handlePromptPanelChange: AccordionProps["onChange"] = React.useCallback(
    (_, expanded) => {
      if (promptPanelOpen !== expanded) {
        setPromptPanelOpen(expanded);
      }
    },
    [promptPanelOpen],
  );

  const handleMessagePanelChange: AccordionProps["onChange"] =
    React.useCallback(
      (_, expanded) => {
        if (messagePanelOpen !== expanded) {
          setMessagePanelOpen(expanded);
        }
      },
      [messagePanelOpen],
    );

  const handleScheduleChange = React.useCallback(
    (schedule: Schedule) => {
      setDirty(true);
      setSchedule(schedule);

      handleChange(schedule);
    },
    [handleChange],
  );

  const handleHabitPromptChange = React.useCallback(
    (habitPrompt: string) => {
      setDirty(true);
      setHabitPrompt(habitPrompt);

      handleChange({ habitPrompt });
    },
    [handleChange],
  );

  const handleMessageContentChange = React.useCallback(
    (value: Node[] | null) => {
      setDirty(true);
      setMessageContent(value);
      handleChange({ messageContent: JSON.stringify(value) });
    },
    [handleChange],
  );

  const handleContentChange = React.useCallback(
    (value: Node[] | null) => {
      setContent(value);
      handleChange({ content: JSON.stringify(value) });
    },
    [handleChange],
  );

  const handleCoverImageChange = React.useCallback(
    (image: string) => {
      setDirty(true);
      handleChange({ image });
    },
    [handleChange],
  );

  const handleContentTemplate = React.useCallback(
    (template: ComponentTemplate) => {
      setTitle(template.title);
      setHabitPrompt(template.habitPrompt);
      setContent(JSON.parse(template.content));
      setVersion(Date.now());

      handleChange(template);
    },
    [handleChange],
  );

  const queryClient = useQueryClient();

  const { mutate: saveComponent } = useMutation({
    mutationFn: ComponentsService.update,
    onSuccess: (response, variables) => {
      // Update query cache
      queryClient.setQueryData<ComponentDto2>(
        [COACH_PROGRAM_COMPONENT_QUERY_KEY, { slugId: component.slugId }],
        (prev) => {
          // Find temp id by real
          const tempId = Number(
            Object.keys(tempIdsMap).find((k) => tempIdsMap[k] === component.id),
          );

          // Update curriculum store (replace temp id)
          dispatch(
            updateComponentProps({
              componentId: component.id,
              data: {
                ...prev,
                ...variables,
                ...response,
              },
              tempId,
              tempWeeksIdsMap,
              apiHandled: true,
            }),
          );

          return new ComponentDto2({
            ...prev,
            ...variables,
            ...response,
            updatedAt: dayjs().utc(),
          });
        },
      );

      queryClient.invalidateQueries({
        queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY],
        exact: false,
      });
    },
  });

  const closeAfterSave = React.useCallback(() => {
    setPreview(null);

    handleCloseDialog();
  }, [location.pathname, setPreview, typeName, unsafe]);

  const isEmpty = React.useMemo(() => isEmptyContent(content), [content]);

  const previewSrc = React.useMemo(() => {
    const param = "preview=true";
    const search = location.search
      ? `${location.search}&${param}`
      : `?${param}`;
    const path = `${location.pathname.substring(
      0,
      location.pathname.lastIndexOf("/"),
    )}/${component.slug}`;

    return `${path}${search}`;
  }, [location.pathname, location.search, component.slug]);

  const publishButton = React.useMemo(
    () => (
      <ComponentPublishButton
        className={s.previewBarPublishButton}
        componentData={{ ...component }}
        onClick={handlePublish}
        empty={isEmpty}
        dirty={dirty && !saved}
      />
    ),
    [
      component,
      dirty,
      handlePublish,
      isEmpty,
      s.previewBarPublishButton,
      saved,
    ],
  );

  const isDisabledMenuItem = (type: string) => menuItemDisabled(type, content);

  const [componentBarHeight, setComponentBarHeight] = React.useState(0);
  const componentBarHeightContextValue = {
    componentBarHeight,
    setComponentBarHeight,
  };

  const theme = useTheme();

  return (
    <ComponentBarHeightContext.Provider value={componentBarHeightContextValue}>
      <EditorDirty.Provider value={setDirty}>
        <EditorCoverImageContext.Provider value={handleCoverImageChange}>
          <EditorComponentItemDisabledContext.Provider
            value={isDisabledMenuItem}
          >
            <CheckInComponentContext.Provider
              value={{
                view: previewQueryParam
                  ? EditorElementView.Preview
                  : EditorElementView.Coach,
              }}
            >
              {preview ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: theme.spacing(PREVIEW_BAR_SPACING),
                    height: "100vh",
                    width: "100%",
                    my: "auto",
                  }}
                >
                  <PreviewBar actions={publishButton} />
                  <PreviewBox
                    src={`${COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug)}/${component.type.toLowerCase()}s/${component.slug}/?preview=true`}
                  />
                </Box>
              ) : (
                <>
                  <Container
                    maxWidth="md"
                    className={clsx(
                      s.root,
                      className,
                      !readOnly && !previewQueryParam && s.editing,
                    )}
                  >
                    {!previewQueryParam && (
                      <>
                        <CoachComponentBar
                          componentData={component}
                          onSaveClick={handlePublish}
                          onCloseClick={handleCloseDialog}
                          savingState={savingState}
                          isEmpty={isEmpty}
                          isDirty={dirty}
                          editor={editor}
                        />

                        <Typography color="textSecondary" gutterBottom>
                          {typeName}
                        </Typography>

                        <CoachComponentTitle
                          component={component}
                          error={Boolean(
                            validationErrors && validationErrors.title,
                          )}
                          onChange={handleTitleChange}
                          placeholder={`Give Your ${typeName} a Title`}
                          defaultValue={defaultTitle}
                        />

                        <div className={s.settings}>
                          <ComponentSchedule
                            layout="expansion"
                            componentType={componentType}
                            schedule={schedule}
                            onChange={handleScheduleChange}
                            panelOpen={schedulePanelOpen}
                            onPanelChange={handleSchedulePanelChange}
                            programWeeks={programWeeks}
                          />
                        </div>

                        {component.type === ComponentType.HABIT && (
                          <div className={s.settings}>
                            <HabitPromptEdit
                              prompt={habitPrompt}
                              onChange={handleHabitPromptChange}
                              open={promptPanelOpen}
                              onPanelChange={handlePromptPanelChange}
                            />
                          </div>
                        )}

                        {component.type === ComponentType.MESSAGE && (
                          <div className={s.settings}>
                            <MessageComponentPanel
                              componentId={component.id}
                              content={messageContent}
                              onChange={handleMessageContentChange}
                              open={messagePanelOpen}
                              onPanelChange={handleMessagePanelChange}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {previewQueryParam && (
                      <>
                        <Box className={s.previewHeader}>
                          <ComponentIcon
                            className={s.icon}
                            componentData={component}
                            variant="icon"
                          />
                          <Typography
                            className={s.previewComponentType}
                            variant="body1"
                            component="span"
                            children={typeName}
                          />
                        </Box>
                        <Typography
                          className={s.previewTitle}
                          variant="h1"
                          children={title}
                        />

                        {component.type === ComponentType.HABIT && (
                          <HabitPrompt completed={false} prompt={habitPrompt} />
                        )}
                      </>
                    )}

                    {componentType !== ComponentType.MESSAGE && (
                      <>
                        <ComponentEditor
                          key={version}
                          editor={editor}
                          value={content}
                          placeholder="Add your content here"
                          onChange={handleContentChange}
                          readOnly={readOnly || previewQueryParam}
                          disabled={Boolean(previewQueryParam)}
                          inlineToolbar
                          sidebar
                          deserialize
                          preNormalize
                          multiline
                          autoFocus
                          delay={250}
                          topToolbar={user.topToolbar}
                          useStickyTop
                        >
                          {component.type === ComponentType.WORKOUT &&
                            !previewQueryParam && <WorkoutAddButton />}
                          {component.type !== ComponentType.MESSAGE &&
                            !previewQueryParam &&
                            isEmptyContent(content) && (
                              <UseComponentTemplate
                                onApplyTemplate={handleContentTemplate}
                                componentType={component.type as ComponentType}
                              />
                            )}
                        </ComponentEditor>
                        {!previewQueryParam && devCookie === "true" && (
                          <JsonEditor
                            content={content}
                            onContentChange={(newContent) => {
                              handleContentChange(newContent);
                              setVersion(Date.now());
                            }}
                          />
                        )}
                      </>
                    )}
                  </Container>
                </>
              )}
            </CheckInComponentContext.Provider>
          </EditorComponentItemDisabledContext.Provider>
        </EditorCoverImageContext.Provider>
      </EditorDirty.Provider>
    </ComponentBarHeightContext.Provider>
  );
}
