import React from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as Add } from "../../icons/AddSquare.svg";
import { RowType } from "../../constants";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import { useCurriculumDispatch } from "../../redux/hooks";
import {
  addWorkoutExercise,
  deleteWorkoutExercise,
  deleteWorkoutSection,
  duplicateWorkoutExercise,
  duplicateWorkoutSection,
} from "../../redux/curriculum/curriculum-slice";

const useStyles = makeStyles((theme) => ({
  root: {
    "& svg": {
      width: 16,
      height: 16,
      color: "black",
    },

    "& .MuiListItem-root:hover": {
      backgroundColor: theme.palette.quote,
    },

    "& .MuiListItemIcon-root": {
      margin: theme.spacing(0, -1, 0, 1),
    },
    "& .MuiListItemText-primary": {
      fontWeight: 500,
      fontSize: "1rem",
    },
  },
  paper: {
    minWidth: theme.spacing(35),
    borderRadius: theme.spacing(1.5),
  },
}));

interface IWeekWorkoutMenuProps extends MenuProps {
  weekId: number;
  componentId: number;
  type: RowType.EXERCISE | RowType.WORKOUT_SECTION;
  cellData: any;
  handleEditExerciseClick: () => void;
}

const WeekWorkoutMenu = (props: IWeekWorkoutMenuProps) => {
  const {
    weekId,
    componentId,
    type,
    cellData,
    handleEditExerciseClick,
    ...other
  } = props;
  const dispatch = useCurriculumDispatch();

  const s = useStyles();

  const workout = cellData?.workoutSectionData;
  const workoutSectionId = workout?.id;
  const exerciseId = cellData?.exerciseData?.id;

  const handleDuplicateClick = () => {
    switch (type) {
      case RowType.WORKOUT_SECTION: {
        dispatch(duplicateWorkoutSection(componentId, workoutSectionId));
      }
      case RowType.EXERCISE: {
        dispatch(
          duplicateWorkoutExercise(componentId, workoutSectionId, exerciseId),
        );
      }

      default:
        break;
    }
  };

  const handleDeleteClick = () => {
    switch (type) {
      case RowType.WORKOUT_SECTION: {
        dispatch(deleteWorkoutSection(componentId, workoutSectionId));
        return;
      }
      case RowType.EXERCISE: {
        dispatch(
          deleteWorkoutExercise(componentId, workoutSectionId, exerciseId),
        );
        return;
      }

      default:
        break;
    }
  };

  const handleAddExerciseClick = () => {
    dispatch(addWorkoutExercise(componentId, workoutSectionId));
  };
  return (
    <Menu
      className={s.root}
      classes={{ paper: s.paper }}
      id="basic-menu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      variant="menu"
      {...other}
    >
      <MenuItem onClick={handleDuplicateClick}>
        <ListItemIcon children={<DuplicateIcon />} />
        <ListItemText primary="Duplicate" />
      </MenuItem>
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon children={<BinIcon />} />
        <ListItemText primary="Delete" />
      </MenuItem>
      {type === RowType.WORKOUT_SECTION && (
        <MenuItem onClick={handleAddExerciseClick}>
          <ListItemIcon children={<Add />} />
          <ListItemText primary="Add exercise" />
        </MenuItem>
      )}
      {type === RowType.EXERCISE && (
        <MenuItem onClick={handleEditExerciseClick}>
          <ListItemIcon
            children={<MenuOpenRoundedIcon style={{ width: 18, height: 18 }} />}
          />
          <ListItemText primary="Edit details" />
        </MenuItem>
      )}
    </Menu>
  );
};

export default WeekWorkoutMenu;
