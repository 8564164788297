import clsx from "clsx";
import React from "react";
import { Drawer, DrawerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { ClientNutritionTargetDrawerEditView } from "./ClientNutritionTargetDrawerEditView";
import { ClientNutritionDrawerHeader } from "./ClientNutritionDrawerHeader";
import { ClientNutritionTargetListView } from "./ClientNutritionTargetListView";

import {
  nutritionTrackingTypeArray,
  nutritionCommentsTypeArray,
  nutritionTargetsArray,
  nutritionComments,
} from "../../constants";
import { ClientNutritionTargetDrawerSetDateView } from "./ClientNutritionTargetDrawerSetDateView";
import { useClient } from "../../hooks/useClient";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import dayjs from "dayjs";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { CLIENT_NUTRITION_TARGETS_QUERY_KEY } from "../../components/client-nutrition-targets/ClientNutritionCard";
import NutritionTargetsService from "../../services/NutritionTargetsService";
import {
  NutritionCommentType,
  NutritionTargetDto,
  NutritionTargetType,
  NutritionTrackingType,
} from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    marginBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  overlay: {},
}));

export enum ClientNutritionTargetDrawerView {
  LIST = "LIST",
  EDIT = "EDIT",
  DATE_PICKER = "DATE_PICKER",
}

export interface ClientNutritionTargetDrawerProps extends DrawerProps {
  nutritionTargets: any;
  refresh?: () => void;
  clientInfo: { clientId?: number; username?: string };
}

export function ClientNutritionTargetDrawer(
  props: ClientNutritionTargetDrawerProps,
) {
  const { className, clientInfo, onClose, open, nutritionTargets, refresh } =
    props;
  const s = useStyles();
  const { clientId, username } = clientInfo;
  const [targetId, setTargetId] = React.useState(undefined);
  const [dirty, setDirty] = React.useState(false);
  const [view, setView] = React.useState<ClientNutritionTargetDrawerView>(
    ClientNutritionTargetDrawerView.LIST,
  );
  const [targetValue, setTargetValue] = React.useState(
    nutritionTargetsArray[0].value,
  );
  const [commentTypeValue, setCommentTypeValue] = React.useState(
    nutritionCommentsTypeArray[0].value,
  );
  const [trackingTypeValue, setTrackingTypeValue] = React.useState(
    nutritionTrackingTypeArray[0].value,
  );
  const [proteinValue, setProteinValue] = React.useState("0.0");
  const [carbohydrateValue, setCarbohydrateValue] = React.useState("0.0");
  const [fatValue, setFatValue] = React.useState("0");
  const [caloriesValue, setCaloriesValue] = React.useState("0");
  const [date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  const user = useCurrentUser();
  const client = useClient();

  const { mutate: createNutritionTarget, isPending: isCreating } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_NUTRITION_TARGETS_QUERY_KEY, { username }],
      mutationFn: NutritionTargetsService.createNutritionTarget,
      optimisticUpdater: {
        updateFn: (oldData: NutritionTargetDto[], newData, tempId) => {
          const newNutritionEntry = NutritionTargetDto.fromJS({
            ...newData,
            id: tempId,
            clientId,
            lastModified: dayjs(),
          });

          return [newNutritionEntry, ...oldData];
        },
      },
      successUpdater: {
        updateFn: (
          oldData: NutritionTargetDto[],
          id: number,
          tempId: number,
        ) => {
          return oldData.map((item) => {
            if (item.id === tempId) {
              return {
                ...item,
                id,
              } as NutritionTargetDto;
            }
            return item;
          });
        },
      },
    });

  const { mutate: updateNutritionTarget, isPending: updateIsLoading } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_NUTRITION_TARGETS_QUERY_KEY, { username }],
      mutationFn: NutritionTargetsService.updateNutritionEntry,
      optimisticUpdater: {
        updateFn: (
          oldData: NutritionTargetDto[],
          updatedEntry: NutritionTargetDto,
        ) => {
          if (!oldData) return [updatedEntry];
          return oldData.map((entry) =>
            entry.id === updatedEntry.id ? updatedEntry : entry,
          );
        },
      },
    });

  const { mutate: deleteNutritionTarget, isPending: isDeleting } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_NUTRITION_TARGETS_QUERY_KEY, { username }],
      mutationFn: NutritionTargetsService.deleteNutritionTarget,
      optimisticUpdater: {
        updateFn: (oldData: NutritionTargetDto[], id) => {
          return oldData.filter((target) => target.id !== id);
        },
      },
    });

  const { mutate: reorderNutritionTarget } = useOptimisticUpdateMutation({
    queryKey: [CLIENT_NUTRITION_TARGETS_QUERY_KEY, { username }],
    mutationFn: NutritionTargetsService.reorderNutritionTargets,
    optimisticUpdater: {
      updateFn: (
        store: NutritionTargetDto[],
        variables: {
          clientId: number;
          idsToOrdinals: { [key: number]: number };
        },
      ) => {
        store.forEach((target) => {
          const newOrdinal = variables.idsToOrdinals[target.id];
          if (newOrdinal !== undefined) {
            target.ordinal = newOrdinal;
          }
        });

        store.sort((a, b) => (a.ordinal ?? 0) - (b.ordinal ?? 0));
        return store;
      },
    },
  });

  const userId = React.useMemo(() => client?.id || user.id, [user, client]);

  const setDefault = React.useCallback(() => {
    setTargetId(undefined);
    setDirty(false);
    setTargetValue(nutritionTargetsArray[0].value);
    setCommentTypeValue(nutritionCommentsTypeArray[0].value);
    setTrackingTypeValue(nutritionTrackingTypeArray[0].value);
    setProteinValue("0.0");
    setCarbohydrateValue("0.0");
    setFatValue("0");
    setDate(dayjs().format("YYYY-MM-DD"));
  }, []);

  const handleClickSave = React.useCallback(() => {
    const nutritionTarget = NutritionTargetDto.fromJS({
      id: targetId,
      trackingType: trackingTypeValue as NutritionTrackingType,
      targetType: targetValue as NutritionTargetType,
      commentType: commentTypeValue as NutritionCommentType,
      carbs: +carbohydrateValue,
      protein: +proteinValue,
      fat: +fatValue,
      calories: +caloriesValue,
      date: dayjs(date),
      clientId: userId as number,
      lastModified: dayjs(),
    });

    if (view === ClientNutritionTargetDrawerView.EDIT && targetId) {
      updateNutritionTarget(nutritionTarget);
    } else {
      createNutritionTarget(nutritionTarget, {
        onSuccess: (data) => {
          setTargetId(data);
        },
      });
    }
  }, [
    userId,
    trackingTypeValue,
    targetValue,
    commentTypeValue,
    carbohydrateValue,
    proteinValue,
    fatValue,
    caloriesValue,
    date,
    createNutritionTarget,
    updateNutritionTarget,
    view,
    targetId,
  ]);

  const handleDelete = React.useCallback(() => {
    deleteNutritionTarget(targetId, {
      onSuccess: () => {
        setView(ClientNutritionTargetDrawerView.LIST);
        setDefault();
      },
    });
  }, [deleteNutritionTarget, targetId, setDefault]);

  const validateInputNumber = React.useCallback(
    (value, prevValue, defaultValue = "0") => {
      if (+value < 0) {
        return defaultValue;
      } else if (value.length >= 6) {
        return prevValue;
      } else return value;
    },
    [],
  );

  const handleListUpdate = React.useCallback(
    (nutritionTargets) => {
      const idsToOrdinals = nutritionTargets.reduce(
        (acc, target, index) => {
          acc[String(target.id)] = index + 1;
          return acc;
        },
        {} as { [key: string]: number },
      );

      reorderNutritionTarget({ clientId: userId as number, idsToOrdinals });
    },
    [reorderNutritionTarget, userId],
  );

  const handleDateChange = React.useCallback((date) => {
    setDate(date);
    setView(ClientNutritionTargetDrawerView.EDIT);
  }, []);

  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleCreate = React.useCallback(() => {
    setView(ClientNutritionTargetDrawerView.EDIT);
  }, [setView]);

  const handleBackDate = React.useCallback(() => {
    setView(ClientNutritionTargetDrawerView.EDIT);
  }, [setView]);

  const handleChangeTarget = React.useCallback(
    (value) => {
      setTargetValue(value);
    },
    [setTargetValue],
  );

  const handleBack = React.useCallback(() => {
    setView(ClientNutritionTargetDrawerView.LIST);
    setDefault();
  }, [setDefault]);

  const handleEditClick = React.useCallback((event, target) => {
    setTargetId(target.id);
    setTargetValue(target.targetType);
    setCommentTypeValue(target.commentType);
    setTrackingTypeValue(target.trackingType);
    setProteinValue(target.protein);
    setCaloriesValue(target.calories);
    setFatValue(target.fat);
    setCarbohydrateValue(target.carbs);
    setDate(target.date);

    setView(ClientNutritionTargetDrawerView.EDIT);
  }, []);

  const handleChangeCommentType = React.useCallback(
    (value) => {
      const {
        PROTEIN_ONLY,
        TRACKING_ONLY,
        PROTEIN_CALORIES,
        CALORIES_ONLY,
        FULL_MACROS,
      } = nutritionComments;
      switch (value) {
        case PROTEIN_ONLY:
          setCarbohydrateValue("0.0");
          setFatValue("0");
          setCaloriesValue("0");
          break;
        case CALORIES_ONLY:
          setProteinValue("0.0");
          setCarbohydrateValue("0.0");
          setFatValue("0");
          break;
        case PROTEIN_CALORIES:
          setCarbohydrateValue("0.0");
          setFatValue("0");
          break;
        case TRACKING_ONLY:
        case FULL_MACROS:
          setProteinValue("0.0");
          setCarbohydrateValue("0.0");
          setFatValue("0");
          setCaloriesValue("0");
          break;
      }
      setCommentTypeValue(value);
    },
    [setCommentTypeValue],
  );

  const handleChangeTrackingType = React.useCallback(
    (value) => {
      setDefault();
      setTrackingTypeValue(value);
    },
    [setTrackingTypeValue, setDefault],
  );

  const handleChangeProtein = React.useCallback(
    (value) => {
      setDirty(true);
      setProteinValue(validateInputNumber(value, proteinValue, "0.0"));
    },
    [setProteinValue, proteinValue, validateInputNumber],
  );

  const handleChangeCarbohydrate = React.useCallback(
    (value) => {
      setDirty(true);
      setCarbohydrateValue(
        validateInputNumber(value, carbohydrateValue, "0.0"),
      );
    },
    [setCarbohydrateValue, carbohydrateValue, validateInputNumber],
  );

  const handleChangeFat = React.useCallback(
    (value) => {
      setDirty(true);
      setFatValue(validateInputNumber(value, fatValue));
    },
    [setFatValue, fatValue, validateInputNumber],
  );

  const handleChangeCalories = React.useCallback(
    (value) => {
      setDirty(true);
      setCaloriesValue(validateInputNumber(value, caloriesValue));
    },
    [setCaloriesValue, caloriesValue, validateInputNumber],
  );

  React.useEffect(() => {
    if (commentTypeValue === nutritionComments.FULL_MACROS) {
      const calories =
        +fatValue * 9 + +proteinValue * 4 + +carbohydrateValue * 4;
      setCaloriesValue(calories.toString());
    }
  }, [fatValue, carbohydrateValue, proteinValue, commentTypeValue]);

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: clsx(
          s.paper,
          view === ClientNutritionTargetDrawerView.DATE_PICKER && s.overlay,
        ),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={
        <>
          <ClientNutritionDrawerHeader
            className={s.header}
            onClose={handleClose}
            onBack={view !== ClientNutritionTargetDrawerView.LIST && handleBack}
            onDelete={
              view === ClientNutritionTargetDrawerView.EDIT &&
              targetId &&
              handleDelete
            }
            title={
              !targetId && view === ClientNutritionTargetDrawerView.EDIT
                ? "Create Nutrition Target"
                : "Update Nutrition Targets"
            }
          />
          {view === ClientNutritionTargetDrawerView.EDIT ? (
            <ClientNutritionTargetDrawerEditView
              dirty={dirty}
              creating={isCreating}
              date={date}
              targetValue={targetValue}
              commentTypeValue={commentTypeValue}
              trackingTypeValue={trackingTypeValue}
              proteinValue={proteinValue}
              fatValue={fatValue}
              caloriesValue={caloriesValue}
              carbohydrateValue={carbohydrateValue}
              onChangeTarget={handleChangeTarget}
              onChangeCommentType={handleChangeCommentType}
              onChangeTrackingType={handleChangeTrackingType}
              onChangeProtein={handleChangeProtein}
              onChangeCarbohydrate={handleChangeCarbohydrate}
              onChangeFat={handleChangeFat}
              onChangeCalories={handleChangeCalories}
              onClickCalendar={() =>
                setView(ClientNutritionTargetDrawerView.DATE_PICKER)
              }
              onClickSave={handleClickSave}
            />
          ) : view === ClientNutritionTargetDrawerView.DATE_PICKER ? (
            <ClientNutritionTargetDrawerSetDateView
              date={date}
              onBack={handleBackDate}
              onDateChange={handleDateChange}
            />
          ) : (
            <ClientNutritionTargetListView
              onItemActionClick={handleEditClick}
              onCreateClick={handleCreate}
              nutritionTargets={nutritionTargets}
              onListUpdate={handleListUpdate}
              disabledReorder={isCreating || isDeleting}
            />
          )}
        </>
      }
    />
  );
}
