import clsx from "clsx";
import React from "react";
import { List, Typography, ListItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentType } from "../../constants";
import { getContentType } from "../../utils/component";

import { ActivityList, ActivityListProps } from "./ActivityList";
import {
  ActivityDto,
  BaseWeekDto,
} from "@growth-machine-llc/stridist-api-client";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 0),
    padding: 0,
  },
  item: {
    display: "block",
    padding: 0,
    margin: theme.spacing(0, 0, 5, 0),
  },
  header: {
    fontSize: 16,
    margin: theme.spacing(0, 0, 2, 0),
    textTransform: "uppercase",
  },
  habitText: {
    float: "right",
    color: theme.palette.text.secondary,
  },
  compact: {
    "& $header": {
      color: theme.palette.primary.main,
    },
  },
}));

const componentTypes = [
  ComponentType.LESSON,
  ComponentType.HABIT,
  ComponentType.CHECKIN,
  ComponentType.WORKOUT,
  ComponentType.MESSAGE,
];

export interface ActivityByTypesListProps
  extends Omit<ActivityListProps, "activities"> {
  activities: ActivityDto[];
  userName?: string;
  clientsScreen?: boolean;
  idProgram?: string;
  week?: BaseWeekDto;
}

export function ActivityByTypesList(props: ActivityByTypesListProps) {
  const {
    className,
    activities,
    variant,
    userName,
    clientsScreen,
    idProgram,
    week,
    ...other
  } = props;
  const s = useStyles();

  //TODO_API_V2_PREVIEW: Move checking for preview to the some context
  const location = useLocation();
  const preview = location.pathname.includes("/preview");

  const types = componentTypes.filter((componentType) =>
    activities.some(({ component }) => component.type === componentType),
  );
  return (
    <List
      className={clsx(s.root, className, {
        [s.compact]: variant === "compact",
      })}
      {...other}
    >
      {types.map((componentType) => (
        <ListItem key={componentType} className={s.item}>
          {componentType === ComponentType.HABIT && variant !== "compact" && (
            <Typography variant="body2" className={s.habitText}>
              Did you complete this today?
            </Typography>
          )}
          <Typography variant="h6" className={s.header}>
            {getContentType(componentType)}s
          </Typography>
          <ActivityList
            activities={activities.filter(
              ({ component }) => component.type === componentType,
            )}
            userName={userName}
            variant={variant}
            clientsScreen={clientsScreen}
            idProgram={idProgram}
            week={week}
            preview={preview}
          />
        </ListItem>
      ))}
    </List>
  );
}
