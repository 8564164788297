import {
  UserRole,
  PHONE_REGEX,
  RequiresUpgradeReason,
  Plan,
} from "../constants";
import dayjs from "dayjs";
import { UserInfo } from "../hooks/useCurrentUser";

/**
 * Extracts first name from display name. For example:
 *
 *   "John Wick" => "John"
 *   "Mr. John Wick" => "John"
 */
export function getFirstName(user, fallback = "Guest") {
  const name = (user && user.displayName) || fallback || "";
  const parts = name
    .split(" ")
    .map((x) => x.trim())
    .filter(Boolean);

  return parts[0].length < 5 && parts[0].endsWith(".")
    ? parts[1] || parts[0] || ""
    : parts[0] || "";
}

export function getLastName(user): string | null {
  const name = (user && user.displayName) || "";
  const parts = name
    .split(" ")
    .map((x) => x.trim())
    .filter(Boolean);

  return parts.length > 1 ? parts[parts.length - 1] : null;
}

export function validateEmail(value: string) {
  return /^.+@.+\..+$/.test(value);
}

export function validatePhone(value: string) {
  return PHONE_REGEX.test(value);
}

export function getUserRole(pathname: string): UserRole {
  return pathname === "/coach" || pathname.startsWith("/coach/")
    ? UserRole.COACH
    : UserRole.CLIENT;
}

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export function isSubscriptionCanceled(
  subscriptionStatus: string | null = null,
) {
  return ["canceled", "unpaid"].includes(subscriptionStatus);
}

export function getRequiresUpgrade(
  trialExpired = false,
  plan: Plan | null = null,
  subscriptionStatus: string | null = null,
): [boolean, RequiresUpgradeReason] {
  if (trialExpired && !plan) {
    return [true, RequiresUpgradeReason.TRAIL_EXPIRED];
  } else if (
    plan &&
    subscriptionStatus &&
    isSubscriptionCanceled(subscriptionStatus)
  ) {
    return [true, RequiresUpgradeReason.SUBSCRIPTION_CANCELED];
  } else {
    return [false, null];
  }
}

export function humanizeDate(
  date: string | number | Date,
  timeZone: string = dayjs.tz.guess(),
  // TODO: Consider about default format
  format: string = "YYYY-MM-DD",
) {
  const formatter = new Intl.DateTimeFormat("en-US", { timeZone });

  return dayjs(formatter.format(new Date(date))).format(format);
}

export function humanizeUserPlan(plan: string) {
  switch (plan) {
    case Plan.UP_TO_5:
      return "5 clients";
    case Plan.UP_TO_15:
      return "15 clients";
    case Plan.UP_TO_30:
      return "30 clients";
    case Plan.UP_TO_50:
      return "50 clients";
    case Plan.UP_TO_75:
      return "75 clients";
    case Plan.UP_TO_100:
      return "100 clients";
    case Plan.UP_TO_200:
      return "200 clients";
    case Plan.FREE:
      return "Free";
    case Plan.STARTER:
      return "Starter";
    case Plan.PRO:
      return "Pro";
    case Plan.YEARLY_UP_TO_50:
      return "50 clients";
    case Plan.YEARLY_UP_TO_100:
      return "100 clients";
    case Plan.YEARLY_UP_TO_200:
      return "200 clients";
    case Plan.YEARLY_UNLIMITED:
      return "unlimited";

    case Plan.UP_TO_INFINITY:
    default:
      return "unlimited";
  }
}

export function isClientSignUpComplete(user: UserInfo) {
  // NOTE: This condition was moved from `SignupClientCompleteScreen` component and matches fields required there.
  // Consider adding more fields if needed.
  return (
    user?.displayName &&
    user?.gender &&
    user?.birthday &&
    user?.location &&
    user?.height > 0 &&
    user?.weight > 0
  );
}
