import { groupBy } from "lodash";
// TODO_Editor
// import { SchemaElements } from "../editor/normalizers/withSchema";

import { WorkoutExercise, WorkoutSection } from "./types";
import {
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
  Units,
} from "../../constants";
import { generateId } from "../new-editor/utils/nodeUtil";
import { fixCompletedRepsTypoInWorkoutSection } from "../editor/utils/common";
import { WORKOUT_SECTION } from "../new-editor/components/workout/WorkoutSectionElement";
import { getExerciseLocalStorageConfig } from "../../utils/local-storage";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { rangeRegex } from "../../utils/regex";

export const isSupersetAtIndex = (
  exercises: WorkoutExercise[],
  index: number,
) => index > 0 && exercises[index - 1].superset === exercises[index].superset;

export const orderedExercises = (
  exercises: WorkoutExercise[],
  move?: [number, number],
) => {
  const result = exercises.map((it) => ({
    ...it,
    superset: it.superset || it.id,
  }));

  if (move) {
    result.splice(move[1], 0, result.splice(move[0], 1)[0]);
  }

  for (let i = 0; i < result.length; i++) {
    const it = result[i];
    const current = it.superset;
    const previous = result[i - 1]?.superset;
    const next = result[i + 1]?.superset;

    if (previous !== current && next !== current) {
      result[i] = {
        ...it,
        superset: it.id,
      };
    }

    if (previous && next && previous === next) {
      result[i] = {
        ...it,
        superset: next,
      };
    }
  }

  return result;
};

export const countExercises = (exercises: WorkoutExercise[]) => {
  let counter = 0;

  return exercises.map((it, index) => {
    if (it.superset === exercises[index - 1]?.superset) {
      counter += 1;
    } else if (it.superset === exercises[index + 1]?.superset) {
      counter = 1;
    } else {
      counter = 0;
    }

    return counter;
  });
};

export const createEmptyWorkout = () => ({
  title: "",
  exercises: [],
});

export const createEmptySet = (defaultRepsValue?: string) => {
  return { reps: defaultRepsValue ?? "", weight: "" };
};

export const DEFAULT_NUMBER_OF_EXERCISE_SETS = 3;
export const DEFAULT_VALUE_OF_EXERCISE_REPS = "12";

export const normalizeObsoleteExercise = (exercise: any) => {
  const id = generateId();
  const DEFAULT_EMPTY_EXERCISE = {
    id,
    superset: id,
    title: "",
    subtitle: "",
    sets: Array.from({ length: 1 }, () => createEmptySet()),
    instructions: "",
    images: [],
    typeSet: ExerciseTypeSet.WEIGHT,
    typeReps: ExerciseTypeReps.WHOLE,
    units: Units.METRIC,
  };
  return { ...DEFAULT_EMPTY_EXERCISE, ...exercise };
};

export const createEmptyExercise = (
  defaultNumberOfSets: number = 1,
  defaultRepsValue?: string,
) => {
  const id = generateId();
  return {
    id,
    superset: id,
    title: "",
    subtitle: "",
    sets: Array.from({ length: defaultNumberOfSets }, () =>
      createEmptySet(defaultRepsValue),
    ),
    instructions: "",
    images: [],
    typeReps:
      defaultRepsValue && rangeRegex.test(defaultRepsValue)
        ? ExerciseTypeReps.RANGE
        : ExerciseTypeReps.WHOLE,
    typeSet: getExerciseLocalStorageConfig().typeSet,
    units: getExerciseLocalStorageConfig().units,
  };
};

export const generateEmptyWorkoutSection = (
  exercises: number,
  defaultNumberOfSets: number = 1,
  defaultRepsValue?: string,
) => {
  return {
    children: [
      {
        text: "",
      },
    ],
    id: generateId(),
    type: WORKOUT_SECTION,
    workout: {
      exercises: Array.from({ length: exercises }, () =>
        createEmptyExercise(defaultNumberOfSets, defaultRepsValue),
      ),
    },
  };
};

export const TEMPLATE_WORKOUT_TITLE = "Full Body A";
export const useGenerateTemplateWorkoutSection = () => {
  const pinBenchPressId = generateId();
  const trapBarDeadliftId = generateId();
  const weightedPullUpId = generateId();
  const singleLegSeatedLegExtensionId = generateId();
  const standingDumbbellLateralRaiseId = generateId();
  const alternatingDumbbellCurlId = generateId();
  return {
    children: [
      {
        text: "",
      },
    ],
    id: generateId(),
    type: WORKOUT_SECTION,
    title: "Full Body A",
    workout: {
      title: "Workout",
      exercises: [
        {
          id: pinBenchPressId,
          superset: pinBenchPressId,
          title: "Pin Bench Press",
          subtitle: "",
          sets: [
            {
              reps: "10",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "10",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "10",
              weight: "",
              extraMeasurement: "2",
            },
          ],
          images: [],
          typeSet: ExerciseTypeSet.WEIGHT,
          typeReps: ExerciseTypeReps.WHOLE,
          units: useCurrentUser()?.units ?? Units.METRIC,
          assetId: null,
          video: "https://youtu.be/VeRmg8tjsCo",
          typeExtraMeasurement: ExerciseTypeExtra.REPS_IN_RESERVE,
        },
        {
          id: trapBarDeadliftId,
          sets: [
            {
              reps: "5",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "5",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "5",
              weight: "",
              extraMeasurement: "2",
            },
          ],
          title: "Trap Bar Deadlift",
          units: useCurrentUser()?.units ?? Units.METRIC,
          video: "https://www.youtube.com/watch?v=lcaWjAw75vw",
          images: [],
          assetId: null,
          typeSet: ExerciseTypeSet.WEIGHT,
          subtitle: "",
          superset: trapBarDeadliftId,
          typeReps: ExerciseTypeReps.WHOLE,
          instructions: "",
          typeExtraMeasurement: ExerciseTypeExtra.REPS_IN_RESERVE,
        },
        {
          id: weightedPullUpId,
          sets: [
            {
              reps: "6-8",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "6-8",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "6-8",
              weight: "",
              extraMeasurement: "2",
            },
          ],
          title: "Weighted Pull-Up",
          units: useCurrentUser()?.units ?? Units.METRIC,
          video: "https://www.youtube.com/watch?v=_U5vqJocrbA",
          images: [],
          assetId: null,
          typeSet: ExerciseTypeSet.WEIGHT,
          subtitle: "",
          superset: weightedPullUpId,
          typeReps: ExerciseTypeReps.RANGE,
          typeExtraMeasurement: ExerciseTypeExtra.REPS_IN_RESERVE,
        },
        {
          id: singleLegSeatedLegExtensionId,
          sets: [
            {
              reps: "12",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "12",
              weight: "",
              extraMeasurement: "2",
            },
            {
              reps: "12",
              weight: "",
              extraMeasurement: "2",
            },
          ],
          title: "Single Leg Seated Leg Extension",
          units: useCurrentUser()?.units ?? Units.METRIC,
          images: [],
          typeSet: ExerciseTypeSet.WEIGHT,
          subtitle: "",
          superset: singleLegSeatedLegExtensionId,
          typeReps: ExerciseTypeReps.WHOLE,
          assetId: null,
          video: "https://www.youtube.com/watch?v=5j5nnaRpslk",
          typeExtraMeasurement: ExerciseTypeExtra.REPS_IN_RESERVE,
        },
        {
          id: standingDumbbellLateralRaiseId,
          sets: [
            {
              reps: "10",
              weight: "",
              extraMeasurement: "1",
            },
            {
              reps: "10",
              weight: "",
              extraMeasurement: "1",
            },
            {
              reps: "10",
              weight: "",
              extraMeasurement: "1",
            },
          ],
          title: "Standing Dumbbell Lateral Raise ",
          units: useCurrentUser()?.units ?? Units.METRIC,
          video: "https://youtu.be/vmDQHXHwllc",
          images: [],
          assetId: null,
          typeSet: ExerciseTypeSet.WEIGHT,
          subtitle: "",
          superset: standingDumbbellLateralRaiseId,
          typeReps: ExerciseTypeReps.WHOLE,
          typeExtraMeasurement: ExerciseTypeExtra.REPS_IN_RESERVE,
        },
        {
          id: alternatingDumbbellCurlId,
          sets: [
            {
              reps: "10",
              weight: "",
              extraMeasurement: "1",
            },
            {
              reps: "10",
              weight: "",
              extraMeasurement: "1",
            },
            {
              reps: "10",
              weight: "",
              extraMeasurement: "1",
            },
          ],
          title: "Alternating Dumbbell Curl",
          units: useCurrentUser()?.units ?? Units.METRIC,
          video: "https://www.youtube.com/watch?v=b2J2wuZVqKk",
          images: [],
          assetId: null,
          typeSet: ExerciseTypeSet.WEIGHT,
          subtitle: "",
          superset: standingDumbbellLateralRaiseId,
          typeReps: ExerciseTypeReps.WHOLE,
          typeExtraMeasurement: ExerciseTypeExtra.REPS_IN_RESERVE,
        },
      ],
    },
  };
};

export const generateNewWorkoutContent = (
  section: number,
  exercises: number,
) => {
  return Array.from({ length: section }, () => ({
    children: [
      {
        text: "",
      },
    ],
    id: generateId(),
    type: WORKOUT_SECTION,
    workout: {
      exercises: Array.from({ length: exercises }, () =>
        createEmptyExercise(
          DEFAULT_NUMBER_OF_EXERCISE_SETS,
          DEFAULT_VALUE_OF_EXERCISE_REPS,
        ),
      ),
    },
    title: "",
  }));
};

export const defaultSectionTitle = "Untitled Section";
export const defaultExerciseTitle = "Untitled Exercise";

type ActivityType = {
  completedAt: string;
  component: {
    title: string;
  };
};

export type UserInfo = {
  displayName: string;
  photoURL?: string;
  email?: string;
};

export type WorkoutExerciseResult = {
  id: string;
  client: UserInfo & { id: string };
  section: WorkoutSection;
  activity: ActivityType;
};

export type WorkoutActivitySection = WorkoutSection & {
  activity: ActivityType;
};

export type WorkoutExerciseClientResult = {
  results: number;
  sections: WorkoutActivitySection[];
} & WorkoutExerciseResult["client"];

export const groupWorkoutClients = (
  workoutExerciseResults: WorkoutExerciseResult[],
  exercise: WorkoutExercise,
): WorkoutExerciseClientResult[] =>
  Object.values(
    groupBy(
      workoutExerciseResults.map(({ client }) => client),
      ({ id }) => id,
    ),
  )
    .map((clients) => clients[0])
    .map((client) => {
      const sections = workoutExerciseResults
        .filter((it) => it.client.id === client.id)
        .map(({ section: { exercises, ...section }, activity }) => ({
          ...section,
          activity,
          exercises: exercises.filter(
            ({ assetId, title }) =>
              title === exercise.title ||
              (assetId && assetId === exercise.assetId),
          ),
        }));

      const results = sections
        .map(({ exercises }) => exercises.length)
        .reduce((a, b) => a + b, 0);

      return {
        ...client,
        sections,
        results,
      };
    });

export const setBeforeMaskedValueChange = (newState, oldState, userInput) => {
  const { value } = newState;
  let selection = newState.selection;
  let cursorPosition = selection ? selection.start : null;

  if (userInput === "-" && cursorPosition < 4) {
    cursorPosition = 4;
    selection = { start: cursorPosition, end: cursorPosition };
  }

  return {
    value,
    selection,
  };
};

export const hasExtraColumn = (typeExtraMeasurement: ExerciseTypeExtra) => {
  return (
    typeExtraMeasurement !== null &&
    typeExtraMeasurement !== undefined &&
    typeExtraMeasurement !== ExerciseTypeExtra.NONE
  );
};

export const getExtraColumnLabel = (extraColumn: ExerciseTypeExtra) => {
  switch (extraColumn) {
    case ExerciseTypeExtra.RATE_OF_PERCEIVED_EXERTION:
      return "RPE";
    case ExerciseTypeExtra.REPS_IN_RESERVE:
      return "RIR";
    default:
      return "";
  }
};

export const getRepsColumnLabel = (typeReps: string, history: boolean) => {
  return "Reps";
};

export const isValidSetField = (
  value: string,
  name: string,
  typeReps: ExerciseTypeReps,
  typeSet: ExerciseTypeSet,
) => {
  if (value === undefined || value === null) {
    return false;
  }

  const sanitizedValue = value.trim();
  if (sanitizedValue === "" || sanitizedValue === "-") {
    return true;
  }

  const maxLength = getSetFieldMaxLength(name, typeReps, typeSet);
  if (sanitizedValue.replace(".", "").length > maxLength) {
    return false;
  }

  if (
    name === "reps" &&
    typeReps === ExerciseTypeReps.WHOLE &&
    sanitizedValue.includes(".")
  ) {
    return false;
  }

  if (name === "reps" && typeReps === ExerciseTypeReps.RANGE) {
    const [startRange, endRange, tail] = sanitizedValue.split("-");
    // if more than one "-" symbol
    if (tail !== undefined) {
      return false;
    }
    return (
      isValidSetField(startRange, name, ExerciseTypeReps.WHOLE, typeSet) &&
      isValidSetField(endRange, name, ExerciseTypeReps.WHOLE, typeSet)
    );
  }

  return sanitizedValue === "0" || isPositiveNumber(sanitizedValue);
};

export const getSetFieldMaxLength = (
  name: string,
  typeReps: ExerciseTypeReps,
  typeSet: ExerciseTypeSet,
) => {
  if (name === "type") {
    return 100;
  }
  if (name === "extraMeasurement") {
    return 3;
  }

  const repsNames = ["reps", "completedReps"];
  if (repsNames.includes(name)) {
    return typeReps === ExerciseTypeReps.RANGE ? 7 : 3;
  }

  return typeSet === ExerciseTypeSet.DISTANCE ? 4 : 3;
};

export const MAX_EXERCISE_SETS_COUNT = 20;

export const isPositiveNumber = (value: number | string): boolean => {
  if (typeof value === "string") {
    const validFloatRegex = /^[+-]?(\d+|\d+\.\d+|\.\d+)$/;

    if (!validFloatRegex.test(value)) {
      return false;
    }

    return isPositiveNumber(parseFloat(value));
  }

  return !isNaN(value) && value > 0;
};

export const getRange = (
  name: string,
  value: string,
  rangeStart: string,
  rangeEnd: string,
): string => {
  if (name === "rangeStart") {
    const end = rangeEnd ? rangeEnd : "";
    return `${value}-${end}`;
  } else if (name === "rangeEnd") {
    const start = rangeStart ? rangeStart : "";
    return `${start}-${value}`;
  } else {
    throw new Error("Invalid name provided");
  }
};

export const parseWorkoutResultValueJsonToWorkoutSection = (value: string) => {
  const workoutSection = JSON.parse(value);
  fixCompletedRepsTypoInWorkoutSection(workoutSection);
  return workoutSection as WorkoutSection;
};
