import React, { useState, useEffect, useMemo } from "react";

import { CheckCircle, Error } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import useMeasure from "react-use-measure";
import LoadingButton, { LoadingButtonProps } from "./LoadingButton";

type LoadingButtonStatus = "success" | "error" | "pending" | "idle";

export interface LoadingButtonWithStatusProps
  extends Omit<LoadingButtonProps, "loadingPosition" | "loading"> {
  status: LoadingButtonStatus;
  successText?: string;
  errorText?: string;
  successIcon?: React.ReactNode;
  errorIcon?: React.ReactNode;
  statusIconPosition?: "start" | "end";
  delay?: number | { success: number; error: number };
}

const LoadingButtonWithStatus = ({
  status,
  successText = "Done!",
  errorText = "Error!",
  successIcon = <CheckCircle />,
  errorIcon = <Error />,
  statusIconPosition = "start",
  delay = 2000,
  children: childrenFromProps,
  sx: sxFromProps,
  ...props
}: Omit<LoadingButtonWithStatusProps, "loadingPosition" | "loading">) => {
  const [localStatus, setLocalStatus] = useState<LoadingButtonStatus>("idle");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const { success: successDelay, error: errorDelay } =
    typeof delay === "number" ? { success: delay, error: delay } : delay;

  const statusIcons = {
    success: successIcon,
    error: errorIcon,
    _default: null,
  };

  useEffect(() => {
    if (status === "pending") {
      setLocalStatus("pending");
    } else if (status === "success") {
      setLocalStatus("success");
      if (timer) clearTimeout(timer);
      setTimer(setTimeout(() => setLocalStatus("idle"), successDelay));
    } else if (status === "error") {
      setLocalStatus("error");
      if (timer) clearTimeout(timer);
      setTimer(setTimeout(() => setLocalStatus("idle"), errorDelay));
    }
  }, [status]);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  const loading = useMemo(() => localStatus === "pending", [localStatus]);

  const theme = useTheme();

  const children = useMemo(() => {
    switch (localStatus) {
      case "success":
        return successText;
      case "error":
        return errorText;
      default:
        return childrenFromProps;
    }
  }, [localStatus, childrenFromProps]);

  const [ref, { width }] = useMeasure();

  const showResult = useMemo(() => {
    return ["error", "success"].includes(localStatus);
  }, [localStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        width: props.fullWidth ? "100%" : width || "auto",
        transition: "width 0.5s",
        justifyContent: "flex-end",
      }}
    >
      <LoadingButton
        loading={loading}
        loadingPosition="center"
        sx={{
          ...sxFromProps,
          ...(showResult && {
            backgroundColor: `${theme.palette[localStatus].main} !important`,
            color: `${theme.palette.getContrastText(theme.palette[localStatus].main)} !important`,
          }),
          ...(loading && {
            color: "transparent !important",
          }),
        }}
        children={
          <Box
            ref={ref}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: theme.spacing(1),
              whiteSpace: "nowrap",
              padding: theme.spacing(0.2, 1.5),
            }}
          >
            {statusIconPosition === "start" && statusIcons[localStatus]}
            {children}
            {statusIconPosition === "end" && statusIcons[localStatus]}
          </Box>
        }
        {...props}
      />
    </Box>
  );
};

export { LoadingButton, LoadingButtonWithStatus };
