import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { HistoryBlock } from "../../../../components/history-block/HistoryBlock";
import { CurriculumEditScreen } from "../../../../components/screen/CurriculumEditScreen";
import LayoutLoadingContext from "../../../../contexts/LayoutLoadingContext";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { containsKeyInLocalStorage } from "../../../../hooks/useLocalStorage";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../../../redux/hooks";
import { setCurriculum } from "../../../../redux/curriculum/curriculum-slice";
import { AsyncStatus } from "../../../../redux/utils";
import { ProgramWeeksContext } from "../../../../hooks/useProgramWeeks";
import {
  selectCurriculum,
  selectProgramWeeks,
} from "../../../../redux/curriculum/selectors/curriculum";
import { useQuery } from "@tanstack/react-query";
import ProgramsService from "../../../../services/ProgramsService";
import { PageSkeleton } from "../../../../components/loading/PageSkeleton";
import { extractSlugId } from "../../../../utils/slug";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../../../api/ReactQueryConfig";
import { useToastAlert } from "../../../../components/app/ToastAlert/ToastAlertProvider";
import { ErrorScreen } from "../../../../components/app/ErrorBoundaryScreen";
import { useSelector } from "react-redux";
import { selectShouldRefetchCurriculum } from "../../../../redux/api/selectors";
import { setLoading, setShouldRefetchCurriculum } from "../../../../redux/api/api-slice";

export const CURRICULUM_VIEW_KEY = "curriculum-view";
export const getCurriculumViewStorageKey = (
  programSlug: string,
  programId?: string,
) => {
  const formatCurriculumViewStorageKey = (programIdentifier: string) =>
    `${CURRICULUM_VIEW_KEY}-${programIdentifier}`;

  const slugKey = formatCurriculumViewStorageKey(programSlug);
  const hasSlugKey = containsKeyInLocalStorage(slugKey);

  // Program curriculum data used to be stored in local storage by program id.
  // We are moving it to be based on program slug instead as it is available
  // directly at route initial load (for program id we need to make a call to BE).
  //
  // TODO drop this fallback to program id after this code is in production for some time.
  // TODO think about why do we need to store program view preferences in both URL
  // and local storage. There should be once source of truth, not sure that we need both.
  const idKey = programId ? formatCurriculumViewStorageKey(programId) : null;
  const hasIdKey = idKey ? containsKeyInLocalStorage(idKey) : false;
  if (hasIdKey) {
    if (!hasSlugKey) {
      const value = window.localStorage.getItem(idKey);
      window.localStorage.setItem(slugKey, value);
    }

    window.localStorage.removeItem(idKey);
  }

  return slugKey;
};

export const INITIAL_WEEK_DETAILS = {
  startNumber: 1,
  endNumber: 3,
};

export const MAX_WEEK_RANGE = 3;
export const getLimitedWeekMaxNumber = (
  startNumber: number,
  endNumber: number,
) => {
  return Math.min(startNumber + MAX_WEEK_RANGE - 1, endNumber);
};

export function CoachProgramCurriculumRoute() {
  const { slug } = useParams();
  const slugId = extractSlugId(slug);
  const loadingContext = useContext(LayoutLoadingContext);
  const programWeeks = useCurriculumSelector(selectProgramWeeks);
  const { showToastAlert } = useToastAlert();
  // TODO_API_V2_CURRICULUM handle loading/error states
  const { value, status, error } = useCurriculumSelector(selectCurriculum);
  const program = value?.program;

  const dispatch = useCurriculumDispatch();
  const shouldRefetchCurriculum = useCurriculumSelector(selectShouldRefetchCurriculum);

  const {
    data: curriculumView,
    isLoading: isLoadingCurriculum,
    isError: isQueryError,
    error: queryError,
    refetch,
  } = useQuery({
    queryKey: [CURRICULUM_VIEW_KEY, { slugId }],
    queryFn: () => ProgramsService.getCurriculum(slug),
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  useEffect(() => {
    if (shouldRefetchCurriculum) {
      dispatch(setLoading(true));
      refetch();
    }
  }, [shouldRefetchCurriculum]);

  useEffect(() => {
    if (isQueryError) {
      showToastAlert("error", {
        message: queryError?.message || "Failed to load curriculum",
      });
    }
  }, [isQueryError, queryError]);

  useEffect(() => {
    // init storage setup
    if (curriculumView && curriculumView?.curriculum.slug === slug) {
      dispatch(setLoading(false));
      dispatch(setShouldRefetchCurriculum(false));
      dispatch(setCurriculum(curriculumView.curriculum));
    }
  }, [curriculumView, slug]);

  useEffect(() => {
    if (isLoadingCurriculum) {
      loadingContext.setLoading(true);
    } else {
      loadingContext.setLoading(false);
    }
  }, [isLoadingCurriculum]);

  return (
    <>
      {program && !isLoadingCurriculum && !shouldRefetchCurriculum ? (
        <ProgramWeeksContext.Provider value={programWeeks}>
          <HistoryBlock message="We are still working on it, hang tight">
            {status !== AsyncStatus.Idle && program.slug === slug && (
              <CurriculumEditScreen program={program} />
            )}
          </HistoryBlock>
          <TrackInfoTool
            trackInfo={{
              name: "Coach - Program Curriculum",
              properties: {
                programId: program?.id,
                programName: program?.name,
                programSlug: program?.slug,
              },
            }}
          />
        </ProgramWeeksContext.Provider>
      ) : !isQueryError ? (
        <PageSkeleton
          maxWidth="slg"
          style={{ marginBlock: 16, paddingInline: 24 }}
        />
      ) : (
        <ErrorScreen error={queryError} />
      )}
    </>
  );
}
