import { createContext, useContext } from "react";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

export type TGroupRoute = Pick<
  GroupDto2,
  "id" | "name" | "totalMembersCount" | "coachId" | "membersCanNotPost"
>;

export interface IGroupContextProps {
  groupBriefInfo: TGroupRoute;
  setGroupBriefInfo: (groupRouteQuery: TGroupRoute) => void;
  resetGroupBriefInfo: () => void;
}

const GroupContext = createContext<IGroupContextProps | undefined>(undefined);

export const useLayoutGroup = () => {
  if (GroupContext === undefined) {
    throw new Error("useGroupRoute must be used within a GroupProvider");
  }
  return useContext(GroupContext);
};

export default GroupContext;
