import { loadStripe } from "@stripe/stripe-js";

const PROD_ORIGINS = [
  "https://app.stridist.com",
  "https://new.app.stridist.com",
];

export const STRIPE_MODE = PROD_ORIGINS.includes(window.location.origin)
  ? "live"
  : "test";

const publishableKey =
  STRIPE_MODE === "live"
    ? process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY
    : process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;

export const initStripe = async () => {
  return await loadStripe(publishableKey);
};
