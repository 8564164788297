import { analyticsClient } from "../api/ApiClients";
import {
  ISendAnalyticsCommand,
  SendAnalyticsCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class AnalyticsService extends ServiceBase {
  async sendAnalytics(variables: ISendAnalyticsCommand) {
    const command = new SendAnalyticsCommand(variables);
    return analyticsClient.sendAnalytics(command);
  }
}

export default new AnalyticsService();
