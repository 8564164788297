import clsx from "clsx";
import React from "react";
import {
  Box,
  Button,
  DialogProps,
  Divider,
  TextFieldProps,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import {
  ClientAddableItem,
  ClientAddableItemProps,
} from "../item/ClientAddableItem";
import { ReactComponent as Close } from "../../icons/Close.svg";
import { SearchField } from "../fields/SearchField";
import {
  GroupDto2,
  PaginatedListOfGroupDto,
} from "@growth-machine-llc/stridist-api-client";
import ClientsService from "../../services/ClientsService";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { GroupMemberSkeleton } from "../loading/GroupMemberSkeleton";
import { useDebounce } from "../../hooks/useDebounce";
import { useQueryClient } from "@tanstack/react-query";
import ClientGroupsService from "../../services/ClientGroupsService";
import { COACH_GROUPS_LIST_QUERY_KEY } from "../../routes/coach/groups/CoachGroupsListRoute";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { GROUP_MEMBERS_LIST_QUERY_KEY } from "../group-members/GroupMembersList";
import { COACH_GROUP_WRAPPER_QUERY_KEY } from "../../routes/coach/group/CoachGroupRouteWrapper";
import { extractSlugId } from "../../utils/slug";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: theme.spacing(65.5),
  },

  lozenges: {
    padding: theme.spacing(2, 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  lozenge: {
    display: "inline-flex",
    height: 34,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    backgroundColor: `${theme.palette.primary.main}99`,
    padding: theme.spacing(0.5, 2),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",

    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  remove: {
    width: 22,
    height: 22,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },

  clients: {
    height: 438,
    overflowY: "auto",
  },

  item: {
    padding: theme.spacing(2.5, 0),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },

  inviteDivider: {
    margin: theme.spacing(2, -3),
  },
}));

export interface ManageGroupDialogProps extends DialogProps {
  group: GroupDto2;
}

export const MANAGE_GROUP_DIALOG_CLIENTS_QUERY_KEY =
  "manage-group-dialog-clients";
const MANAGE_GROUP_DIALOG_CLIENTS_PAGE_SIZE = 10;
const FETCH_NEXT_PAGE_WHEN_REMAINING = 2;

export function ManageGroupDialog(props: ManageGroupDialogProps) {
  const { className, onClose, group, ...other } = props;
  const s = useStyles();
  const queryClient = useQueryClient();

  const [filter, setFilter] = React.useState("");
  const [addedClients, setAddedClients] = React.useState([]);

  const slugId = extractSlugId(group.slug);

  const debouncedQuery = useDebounce(filter, 300);

  const { mutate: updateGroupClients, isPending: updateInFlight } =
    useOptimisticUpdateMutation({
      queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
      mutationFn: ClientGroupsService.updateGroupMembers,
      optimisticUpdater: {
        updateFn: (oldData: PaginatedListOfGroupDto, variables) => {
          const groupIndex = oldData.items.findIndex(
            (group) => group.id === variables.id,
          );

          if (groupIndex !== -1) {
            oldData.items[groupIndex].totalMembersCount = addedClients.length;
            oldData.items[groupIndex].members = addedClients;
          }
          return oldData;
        },
      },
      // TODO_API_V2_GROUP_MEMBERS STR-1257: Consider adding an updater for COACH_GROUP_WRAPPER query
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [
              MANAGE_GROUP_DIALOG_CLIENTS_QUERY_KEY,
              { groupId: group.id },
            ],
            refetchType: "inactive",
          });
          queryClient.invalidateQueries({
            queryKey: [COACH_GROUP_WRAPPER_QUERY_KEY, { slugId: slugId }],
          });
          queryClient.invalidateQueries({
            queryKey: [GROUP_MEMBERS_LIST_QUERY_KEY, { groupId: group.id }],
          });
        },
      },
    });

  const {
    data: clientsData,
    ref: clientElementRef,
    isLoading: isLoadingInitial,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [
      MANAGE_GROUP_DIALOG_CLIENTS_QUERY_KEY,
      { groupId: group.id, query: debouncedQuery },
    ],
    queryFn: ({ pageParam = 1 }) =>
      ClientsService.getTargetClients(
        pageParam as number,
        MANAGE_GROUP_DIALOG_CLIENTS_PAGE_SIZE,
        {
          groupId: group.id,
          searchQuery: debouncedQuery === "" ? undefined : debouncedQuery,
        },
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const clients = clientsData?.pages.flatMap((page) => page.items) ?? [];

  React.useEffect(() => {
    // TODO_API_V2_REACT_QUERY_V5: Consider better solution as replacement for removed `onSuccess`
    clients &&
      setAddedClients((v) => [
        ...v,
        ...clients.filter((client) => client.targetBool && !v.includes(client)),
      ]);
  }, [clientsData]);

  const handleFilterChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setFilter(event.target.value);
    },
    [],
  );

  const handleFilterReset = React.useCallback(() => {
    setFilter("");
  }, []);

  const resetState = React.useCallback(() => {
    setFilter("");
    setAddedClients([]);
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleUpdate = React.useCallback(() => {
    updateGroupClients({
      id: group.id,
      emails: addedClients.map(({ email }) => email),
    });
    handleClose();
  }, [addedClients, group.id, handleClose, updateGroupClients]);

  const removeClient = React.useCallback(
    (email: string) => {
      const i = addedClients.findIndex(
        (addedClient) => addedClient.email === email,
      );

      if (i !== -1) {
        const updated = [...addedClients];
        updated.splice(i, 1);
        setAddedClients(updated);
      }
    },
    [addedClients],
  );

  const handleToggle: ClientAddableItemProps["onToggle"] = React.useCallback(
    (added, client) => {
      if (added) {
        setAddedClients((v) => [...v, client]);
      } else {
        removeClient(client.email);
      }
    },
    [removeClient],
  );

  const getRemoveHandler = React.useCallback(
    (email: string) => () => removeClient(email),
    [removeClient],
  );

  const filteredClients = React.useMemo(() => {
    return filter
      ? clients.filter(({ displayName, email }) =>
          [displayName, email].some((value) =>
            value.toLowerCase().includes(filter.toLowerCase()),
          ),
        )
      : clients;
  }, [clients, filter]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={group.name}
      closeDisabled={updateInFlight}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <SearchField
        variant="outlined"
        fullWidth
        value={filter}
        onChange={handleFilterChange}
        onReset={handleFilterReset}
        placeholder="Search clients"
      />

      {/* Temporarily remove lozenges with false */}
      {Boolean(addedClients.length) && false && (
        <Box className={s.lozenges}>
          {addedClients.map(({ email, displayName }) => (
            <Box key={email} className={s.lozenge}>
              <Typography component="span" children={displayName} />
              <Close className={s.remove} onClick={getRemoveHandler(email)} />
            </Box>
          ))}
        </Box>
      )}

      <Box className={s.clients}>
        {isLoadingInitial
          ? Array.from({ length: 3 }).map((_, i) => (
              <GroupMemberSkeleton key={`group-member-skeleton-${i}`} />
            ))
          : filteredClients.map((client, i, arr) => {
              const added = Boolean(
                addedClients.find(({ email }) => email === client.email),
              );

              return (
                <React.Fragment key={client.id}>
                  {i === arr.length - FETCH_NEXT_PAGE_WHEN_REMAINING && (
                    <div ref={clientElementRef} />
                  )}
                  <ClientAddableItem
                    className={s.item}
                    client={client}
                    added={added}
                    onToggle={handleToggle}
                  />
                  {i < arr.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}

        {hasNextPage && (
          <LoadMoreButton
            sx={{ mb: 2 }}
            fullWidth
            onClick={() => !isFetchingNextPage && fetchNextPage()}
            disabled={isFetching && !isFetchingNextPage}
            loading={isFetchingNextPage}
          />
        )}
      </Box>

      <Divider />

      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={group.totalMembersCount > 0 ? "Save changes" : "Add members"}
        onClick={handleUpdate}
        disabled={updateInFlight}
      />
    </BaseDialog>
  );
}
