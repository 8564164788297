import React from "react";
import type { AnalyticsJS } from "../hooks/useAnalytics";
import { uuidv4 } from "./uuid";
import { platform } from "./platform";
import AnalyticsService from "../services/AnalyticsService";
import { useMutation } from "@tanstack/react-query";
import { AnalyticsRequestType } from "@growth-machine-llc/stridist-api-client";

type Callback = () => void;

const getAnonymousId = (key = "anonymousId") => {
  let id = localStorage.getItem(key);

  if (!id) {
    localStorage.setItem(key, (id = uuidv4()));
  }

  return id;
};

export const useSSRAnalytics = (userId): AnalyticsJS => {
  const anonymousId = getAnonymousId();

  const { mutate: sendAnalytics, isPending } = useMutation({
    mutationKey: ["send-analytics"],
    mutationFn: AnalyticsService.sendAnalytics,
  });

  const sendRequest = (
    type: AnalyticsRequestType,
    payload: any,
    callback?: Callback,
  ) =>
  sendAnalytics(
    {
      type,
      payload: JSON.stringify(payload),
    },
    {
      onSuccess: () => {
        if (callback) {
          callback();
        }
      },
    },
  );

  return {
    identify: (userId: string, traits: Object, callback?: Callback) =>
      sendRequest(
        AnalyticsRequestType.IDENTIFY,
        {
          anonymousId,
          userId,
          traits,
        },
        callback,
      ),

    track: (event: string, properties?: Object, callback?: Callback) =>
      sendRequest(
        AnalyticsRequestType.TRACK,
        {
          anonymousId,
          userId,
          event,
          properties,
        },
        callback,
      ),

    page: (name?: string, properties?: Object, callback?: Callback) =>
      sendRequest(
        AnalyticsRequestType.PAGE,
        {
          anonymousId,
          userId,
          name,
          properties,
        },
        callback,
      ),

    device: (userId: string, deviceToken: string, callback?: Callback) =>
      sendRequest(
        AnalyticsRequestType.DEVICE,
        {
          anonymousId,
          userId,
          platform,
          deviceToken,
        },
        callback,
      ),
  };
};
