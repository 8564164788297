import clsx from "clsx";
import React from "react";
import { Button, DialogProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "./BaseDialog";
import { EnrollmentsForCalendarDto } from "@growth-machine-llc/stridist-api-client";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { MANAGE_PROGRAMS_DIALOG_QUERY_KEY } from "./ChooseProgramEnrollDialog";
import { useQueryClient } from "@tanstack/react-query";
import { CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY } from "../../routes/coach/client/calendar/CoachClientCalendarRoute";
import { useClient } from "../../hooks/useClient";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
  },

  button: {
    width: `calc(50% - ${theme.spacing(1)})`,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    padding: theme.spacing(2.25, 0),
  },

  cancel: {
    borderWidth: 2,
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },

  remove: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

export interface ConfirmRemoveFromProgramDialogProps extends DialogProps {
  enrollment: EnrollmentsForCalendarDto;
  onRemove?: () => void;
}

export function ConfirmRemoveFromProgramDialog(
  props: ConfirmRemoveFromProgramDialogProps,
) {
  const { className, enrollment, onRemove, onClose, ...other } = props;
  const s = useStyles();
  const { id, client, program } = enrollment;
  const { username } = useClient();

  const queryClient = useQueryClient();

  const { mutate: unenrollClient, isPending: unenrollClientInFlight } = useOptimisticUpdateMutation({
    queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
    mutationFn: EnrollmentsClientService.unenrollClient,
    optimisticUpdater: {
      updateFn: (
        oldData: EnrollmentsForCalendarDto[], id
      ) => {
        return oldData.filter(enrollment => enrollment.id !== id);
      },
    },
    options: {
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: [MANAGE_PROGRAMS_DIALOG_QUERY_KEY],
          exact: false
        });
      },
    },
  });

  const handleCancelClick = React.useCallback(
    (event) => onClose(event, "backdropClick"),
    [onClose],
  );

  const handleRemoveClick = React.useCallback(
    (event) => {
      unenrollClient(id);
    },
    [id, onClose, onRemove, unenrollClient],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`Remove ${client.displayName} from ${program.name}?`}
      onClose={onClose}
      PaperProps={{
        className: s.paper,
      }}
      {...other}
    >
      <Box className={s.buttons}>
        <Button
          className={clsx(s.button, s.cancel)}
          fullWidth
          variant="outlined"
          children="Cancel"
          onClick={handleCancelClick}
          disabled={unenrollClientInFlight}
        />
        <Button
          className={clsx(s.button, s.remove)}
          fullWidth
          variant="contained"
          children="Remove"
          onClick={handleRemoveClick}
          disabled={unenrollClientInFlight}
        />
      </Box>
    </BaseDialog>
  );
}
