import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import { selectComponentTempIdsMap } from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";
import { IUpdateComponentCommand } from "@growth-machine-llc/stridist-api-client";
import { selectComponentById } from "../../../selectors/curriculum";
import { deleteOperation } from "./operation";

export default function* deleteWorkoutSectionWorker(payload: {
  componentId: number;
  workoutSectionId: number;
}) {
  const { componentId, workoutSectionId } = payload;

  const component = yield select(selectComponentById(componentId));
  if (!component) return;
  const map: TempIdsMap = yield select(selectComponentTempIdsMap);
  const checkedComponentId = map?.[componentId] || componentId;

  const updatedContent = deleteOperation(workoutSectionId, component.content);

  const command: IUpdateComponentCommand = {
    id: checkedComponentId,
    content: updatedContent,
  };

  try {
    yield call(ComponentsService.update, command);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
