import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActivityResponseCard } from "../activity/ActivityResponseCard";
import { ActivitySort, defaultCoachActivityFilters } from "../../constants";
import {
  ACTIVITY_LIST_QUERY_KEY,
  getCoachActivityTypes,
} from "../activity/CoachActivity";

import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { PageSkeleton } from "../loading/PageSkeleton";
import {
  ActivityTypesFilter,
  ActivityTypesFilterProps,
} from "../filters/ActivityTypesFilter";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import ActivitiesService from "../../services/ActivitiesService";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
  },

  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },

  more: {
    marginBottom: theme.spacing(4),
  },
}));

export interface ClientActivityScreenProps
  extends Omit<ContainerProps, "children"> {}

const CLIENT_ACTIVITY_LIST_PAGE_SIZE = 10;

export function ClientActivityScreen(props: ClientActivityScreenProps) {
  const { className, ...other } = props;

  const [visibleModalVideo, setVisibleModalVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);

  const s = useStyles();
  const [filters, setFilters] = React.useState(defaultCoachActivityFilters);

  const handleFiltersChange: ActivityTypesFilterProps["onChange"] =
    React.useCallback(
      (filters) => {
        setFilters(filters);
      },
      [setFilters],
    );

  const activityTypes = getCoachActivityTypes(filters);

  const {
    data: activitiesData,
    ref: activityElementRef,
    isLoading: isLoadingInitial,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [ACTIVITY_LIST_QUERY_KEY, { activityTypes }],
    queryFn: ({ pageParam }) => {
      return ActivitiesService.getActivities(
        pageParam as string,
        CLIENT_ACTIVITY_LIST_PAGE_SIZE,
        activityTypes,
        null,
        ActivitySort.COMPLETED_AT_DESC,
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
  });

  const isLoading = isLoadingInitial || (isFetching && !isFetchingNextPage);

  const activities = activitiesData?.pages.flatMap((page) => page.items) ?? [];

  const handleMoreClick = React.useCallback(() => {
    !isFetchingNextPage && fetchNextPage();
  }, [fetchNextPage, isFetchingNextPage]);

  const handleOpenVideo = React.useCallback((url) => {
    setVisibleModalVideo(true);
    setVideoUrl(url);
  }, []);

  const handleCloseVideo = React.useCallback(() => {
    setVisibleModalVideo(false);
    setVideoUrl(null);
  }, []);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.actions}>
        <ActivityTypesFilter filters={filters} onChange={handleFiltersChange} />
      </Box>
      {!activities || isLoading ? (
        <PageSkeleton fullWidth />
      ) : activities.length > 0 ? (
        <>
          {activities.map((activity) => (
            <>
              <div ref={activityElementRef}></div>
              <ActivityResponseCard
                key={activity.id}
                activity={activity}
                enableArchive={false}
                handleOpenVideo={handleOpenVideo}
              />
            </>
          ))}

          {isFetchingNextPage && <PageSkeleton fullWidth cardsCount={2} />}
        </>
      ) : (
        <Typography>There are no new activities</Typography>
      )}
      <ConfirmActionDialog
        title=""
        onCancel={handleCloseVideo}
        onConfirm={() => {}}
        open={visibleModalVideo}
        activity
        videoUrl={videoUrl}
      />
    </Container>
  );
}
