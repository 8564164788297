import { useCallback, useState } from "react";
import StorageService from "../services/StorageService";
import { useMutation } from "@tanstack/react-query";

type TranscodeVideoFn = (file: string) => Promise<string>;

export function useTranscodeVideo() {
  const { mutate: transcodeVideo, isPending: isTranscoding } = useMutation({
    mutationKey: ["transcode-video"],
    mutationFn: StorageService.transcodeVideo,
  });

  const transcode: TranscodeVideoFn = useCallback(
    (url: string): Promise<string> => {
      return new Promise((resolve, reject) =>
        transcodeVideo(
          {
            url,
          },
          {
            onError: (error) => {
              reject(error.message);
            },
            onSuccess: ({ url }) => {
              resolve(url);
            },
          },
        ),
      );
    },
    [transcodeVideo],
  );

  return [transcode, isTranscoding] as const;
}
