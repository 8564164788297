import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RefreshSlug } from "../routes/RefreshSlug";
import { GroupSettingsCard } from "./GroupSettingsCard";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupSettingsScreenProps
  extends Omit<ContainerProps, "children"> {
  group: GroupDto2;
}

export function GroupSettingsScreen(props: GroupSettingsScreenProps) {
  const { className, group, ...other } = props;
  const s = useStyles();

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <RefreshSlug
        slugInfo={{
          slug: group?.slug,
          slugId: group?.slugId,
        }}
      />
      <GroupSettingsCard group={group} />
    </Container>
  );
}
