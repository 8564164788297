import clsx from "clsx";
import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MessageContent } from "./MessageContent";
import { CardAvatar } from "../../components/card/CardAvatar";
import { colorSystem } from "../../theme";
import { MessageDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    clear: "both",
    margin: theme.spacing(0.5, 0),
  },

  date: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0, 3, 0),
  },

  message: {
    wordBreak: "break-word",
    clear: "both",
    maxWidth: "70%",
    display: "flex",
    alignItems: "flex-end",

    "$incoming &": {
      float: "left",
    },

    "$outgoing &": {
      float: "right",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(6),

    "$avatar + &": {
      marginLeft: 0,
    },
  },

  wrapper: {
    "$incoming &": {
      borderRadius: theme.spacing(3, 3, 3, 0.25),
      backgroundColor: colorSystem.gray8,

      "& a": {
        color: `${theme.palette.primary.main} !important`,
      },
    },

    "$outgoing &": {
      borderRadius: theme.spacing(3, 3, 0.25, 3),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      "& a": {
        color: `${theme.palette.common.white} !important`,
      },
    },
  },

  paragraphWrapper: {
    background: theme.palette.selected.main,
    padding: theme.spacing(2, 3),
  },

  avatar: {
    margin: theme.spacing(0, 1, 0, 0),

    width: theme.spacing(5),
    height: theme.spacing(5),

    "$incoming &": {
      float: "left",
      flexDirection: "row",
    },

    "$outgoing &": {
      float: "right",
      flexDirection: "row-reverse",
    },
  },

  userName: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    margin: theme.spacing(2.5, 0, 1, 8),
  },

  incoming: {},
  outgoing: {},
}));

export interface MessageProps {
  displayDate: boolean;
  displayAvatar: boolean;
  displayUserName: boolean;
  message: MessageDto;
}

export function Message(props: MessageProps) {
  const { displayDate, displayAvatar, displayUserName, message } = props;
  const s = useStyles(message);
  const { messageAuthor: user } = message;

  return (
    <Box
      className={clsx(s.root, {
        [s.incoming]: message.direction === "INCOMING",
        [s.outgoing]: message.direction === "OUTGOING",
      })}
    >
      {displayDate && (
        <Typography className={s.date}>{message.formattedDate}</Typography>
      )}

      {displayUserName && message.direction === "INCOMING" && (
        <Typography className={s.userName}>
          {message.messageAuthor.displayName}
        </Typography>
      )}

      <Box className={s.message}>
        {displayAvatar && message.direction === "INCOMING" && (
          <CardAvatar
            className={s.avatar}
            alt={user.displayName}
            src={user.photoUrl}
            children={user.displayName.substring(0, 1).toUpperCase()}
          />
        )}

        <Box className={s.content}>
          <MessageContent
            content={message.content}
            wrapperClassName={s.wrapper}
            paragraphWrapperClassName={s.paragraphWrapper}
          />
        </Box>
      </Box>
    </Box>
  );
}
