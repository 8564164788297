import { CustomCellEditorProps, useGridCellEditor } from "ag-grid-react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { ExerciseAutocomplete } from "../../exercise-library/ExerciseAutocomplete";
import {
  AutocompleteRenderInputParams,
  Box,
  IconButton,
  TextField,
  createTheme,
} from "@mui/material";
import { ExerciseAsset } from "../../exercise-library/types";
import makeStyles from "@mui/styles/makeStyles";
import {
  CustomProgramCellEditorProps,
  getDefaultExerciseCellTitle,
} from "../utils";
import { RowType } from "../../../constants";
import { Video, ChevronDown } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      height: 40,
      minHeight: "unset",
    },
  },

  autocomplete: {
    "&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root": {
      paddingRight: "9px",
    },
  },

  textField: {
    "& > div": {
      borderRadius: 0,
    },
  },

  input: {
    fontSize: 13,
    fontWeight: 500,
    fontStyle: "normal",
  },

  noBorder: {
    border: "none",
  },

  option: {
    padding: theme.spacing(0.2, 0.2),
    fontSize: 13,
    fontWeight: 500,
  },

  iconWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.black,
    marginRight: theme.spacing(-0.25),
  },
}));

interface Image {
  type?: "video" | "image";
  url: string;
  name?: string;
  cover?: boolean;
}

interface Asset {
  assetId?: string;
  title: string;
  subtitle: string;
  instructions: string;
  images: Image[];
  video?: string;
}

export default memo(
  ({
    initialValue,
    value,
    onValueChange,
    data,
    handleExerciseUpdate,
    api,
    rowIndex,
    column,
    setExerciseDrawerData,
    paramsData,
  }: CustomCellEditorProps & CustomProgramCellEditorProps) => {
    const enableCallbackRef = useRef(false);

    const getAssetFromExercise = (e): Asset =>
      e && {
        assetId: e.assetId,
        title: e.title,
        subtitle: e.subtitle,
        instructions: e.instructions,
        images: e.images?.map((i) => ({
          type: i.type,
          url: i.url,
          name: i.name,
          cover: i.cover,
        })),
        video: e.video,
      };

    const inputRef = useRef<HTMLInputElement>(null);

    const [asset, setAsset] = useState<Asset | null>(
      data.exerciseData.assetId
        ? getAssetFromExercise(data.exerciseData)
        : null,
    );

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const s = useStyles();
    const theme = createTheme();

    const inputProps = {
      id: "workout-exercise-autocomplete",
      name: "title",
      placeholder: "ex. Weighted Crunch",
      fullWidth: true,
    };

    const handleAutocompleteChange = React.useCallback(
      (exerciseData: ExerciseAsset) => {
        setAsset(
          getAssetFromExercise({
            ...exerciseData.exercise,
            assetId: exerciseData.assetId,
          }),
        );
      },
      [],
    );

    const handleAutocompleteTitleChange = (_, title: string, options) => {
      onValueChange(title);
      const matchingOption = options.find(
        (option) =>
          option?.exercise?.title?.toLowerCase() === title?.toLowerCase(),
      );
      if (matchingOption) {
        handleAutocompleteChange(matchingOption);
      }
    };

    const isCancelAfterEnd = useCallback(() => {
      const openDrawer = () => {
        const drawerData = {
          componentId: paramsData.componentId,
          componentContent: paramsData.componentContent,
          workoutSection: paramsData.workoutSectionData,
          exercise: {
            ...paramsData.exerciseData,
            title: value,
            video: asset?.video,
            instructions: asset?.instructions,
            images: asset?.images,
          },
        };
        setExerciseDrawerData(drawerData);
      };

      if (initialValue === value) {
        openDrawer();
        return true;
      }

      if (value === "") {
        const rowNode = api!.getDisplayedRowAtIndex(rowIndex)!;
        api.flashCells({
          rowNodes: [rowNode],
          columns: [column],
        });
        return true;
      }

      handleExerciseUpdate(
        {
          ...data.workoutSectionData,
          exercises: data.exercises.map((e) => ({
            ...e,
            ...(e.id === data.exerciseData.id && asset ? asset : {}),
            ...(e.id === data.exerciseData.id ? { title: value } : {}),
          })),
        },
        data.componentContent,
        data.workoutSectionData.id,
        data.componentId,
        enableCallbackRef.current ? openDrawer : undefined,
      );
      enableCallbackRef.current = false;

      return false;
    }, [value]);

    useGridCellEditor({
      isCancelAfterEnd,
    });

    return (
      <div className={s.root}>
        <ExerciseAutocomplete
          classes={{ root: s.autocomplete, option: s.option }}
          renderCustomInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              {...inputProps}
              variant="outlined"
              className={s.textField}
              InputProps={{
                ...params.InputProps,
                className: s.input,
                endAdornment: (
                  <>
                    <Box className={s.iconWrapper}>
                      <ChevronDown size={18} strokeWidth={1.75} />
                      <IconButton
                        size="small"
                        color="inherit"
                        onClick={(e) => {
                          enableCallbackRef.current = true;
                          api.stopEditing();
                          e.stopPropagation();
                        }}
                        sx={{ marginBottom: 0.2 }}
                      >
                        <Video
                          size={22}
                          strokeWidth={1.2}
                          color={theme.palette.common.black}
                        />
                      </IconButton>
                    </Box>
                  </>
                ),
                classes: { notchedOutline: s.noBorder },
              }}
              inputRef={inputRef}
            />
          )}
          inputValue={
            value === getDefaultExerciseCellTitle(RowType.EXERCISE) &&
            initialValue === getDefaultExerciseCellTitle(RowType.EXERCISE)
              ? ""
              : value
          }
          onInputChange={handleAutocompleteTitleChange}
          onChange={handleAutocompleteChange}
        />
      </div>
    );
  },
);
