import clsx from "clsx";
import React, { useEffect } from "react";
import { Box, BoxProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Avatar } from "../avatar/Avatar";
import { HeightOverflow } from "../container/HeightOverflow";
import { EditorFieldEditor } from "../new-editor/EditFieldEditor";
import { Likeable } from "../likeable/Likeable";
import { colorSystem } from "../../theme";

import { GroupMemberBadges } from "./GroupMemberBadges";
import { GroupPostCommentsListItemNoReplies } from "./GroupPostCommentsListItemNoReplies";
import { GroupPostsCommentForm } from "./GroupPostsCommentForm";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  GroupPostCommentDto,
  GroupPostCommentReplyDto,
  ICursorPaginatedListOfGroupPostCommentDto,
} from "@growth-machine-llc/stridist-api-client";
import { InfiniteData } from "@tanstack/react-query";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../utils/optimisticUpdate";
import { GROUP_POST_COMMENTS_LIST_QUERY_KEY } from "../../hooks/groupPosts/useGroupPostComments";
import { useToggleLikeMutation } from "../likeable/mutations/Like";
import { useReadNotificationMutation } from "../app/mutations/useReadNotificationMutation";
import { TimeAgo } from "../../routes/utils/TimeAgo";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "&::before": {
      width: 1,
      height: "100%",
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 0,
      left: theme.spacing(6.5),
      backgroundColor: colorSystem.secondaryGray,
    },

    "&$first::before": {
      height: "calc(100% - 21px)",
    },
  },

  header: {
    padding: theme.spacing(2, 3, 1, 3),
    display: "flex",
    alignItems: "center",
  },

  author: {
    flexGrow: 1,
  },

  avatar: {
    borderWidth: 0,
    margin: theme.spacing(0, 2, 0, 0),

    [theme.breakpoints.up("md")]: {
      width: 40,
      height: 40,
      marginLeft: theme.spacing(1),
    },
  },

  displayName: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "1.5em",
  },

  time: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  content: {
    fontSize: 14,
    fontWeight: 500,

    '& [data-slate-node="element"]': {
      padding: 0,
      border: 0,
    },
  },

  expand: {
    fontSize: 14,
    fontWeight: 500,
  },

  body: {
    padding: theme.spacing(1, 3, 3, 10),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 3, 3, 11),
    },
  },

  moreButton: {
    marginTop: theme.spacing(2),
  },

  reply: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    cursor: "pointer",
  },

  form: {
    padding: 0,
    backgroundColor: "transparent",

    "&$noReplies": {
      padding: theme.spacing(2, 0, 0),
    },
  },

  first: {},
  noReplies: {},
}));

export interface GroupPostCommentsListItemProps extends BoxProps {
  groupId: number;
  postId: number;
  parentCommentId?: number;
  comment: GroupPostCommentDto;
  headerClassName?: string;
  avatarClassName?: string;
  bodyClassName?: string;
  first?: boolean;
  onReplyClick?: (comment: GroupPostCommentDto) => void;
}

export function GroupPostCommentsListItem(
  props: GroupPostCommentsListItemProps,
) {
  const {
    className,
    comment,
    groupId,
    parentCommentId,
    postId,
    headerClassName,
    avatarClassName,
    bodyClassName,
    first = false,
    onReplyClick,
    ...other
  } = props;
  const s = useStyles();

  const { author, content, replies, formattedDate, created, notification } =
    comment;
  const createdAt = (comment as GroupPostCommentReplyDto).createdAt;
  const user = useCurrentUser();
  const [loading, setLoading] = React.useState(false);
  const setLoaded = React.useCallback(() => setLoading(false), []);
  const [replyToComment, setReplyToComment] =
    React.useState<GroupPostCommentReplyDto | null>(null);

  const { mutate: notificationRead, isPending: readingNotification } =
    useReadNotificationMutation<
      InfiniteData<ICursorPaginatedListOfGroupPostCommentDto>
    >({
      queryKey: [GROUP_POST_COMMENTS_LIST_QUERY_KEY, { postId }],
      readUpdater: (prev, { groupPostCommentId }) => {
        const isReply = !!parentCommentId;
        notification.read = true;
        return INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
          prev,
          isReply ? parentCommentId : groupPostCommentId,
          (prev) => ({
            ...prev,
            ...(isReply
              ? {
                  replies: prev.replies.map((reply) =>
                    reply.id === groupPostCommentId
                      ? new GroupPostCommentReplyDto({ ...reply, notification })
                      : reply,
                  ),
                }
              : { notification }),
          }),
        );
      },
    });

  const {
    like,
    unlike,
    isLoading: likeTogglePending,
  } = useToggleLikeMutation({
    target: "comment",
    postId: postId,
    isReply: parentCommentId ? true : false,
  });

  const onLikeToggle = (liked: boolean) => {
    liked ? like(comment.id) : unlike(comment.id);
  };

  React.useEffect(() => {
    if (notification) {
      if (
        notification?.userId === user.id &&
        !notification?.read &&
        !readingNotification
      ) {
        notificationRead({
          groupPostCommentId: comment.id,
        });
      }
    }
  }, [
    comment.id,
    notification,
    notificationRead,
    readingNotification,
    user.id,
  ]);

  const handleChildReplyClick = () => {
    setReplyToComment((_comment) =>
      _comment?.id === comment.id ? null : comment,
    );
  };

  const handleCommentSubmit = () => {
    setReplyToComment(null);
  };

  const handleMoreClick = React.useCallback(() => {
    if (!loading) {
      // TODO_API_V2_GROUP_POSTS: Implement pagination if needed. Currently fetching all replies.
      // setLoading(true);
      // loadNext(pageSize, {
      //   onComplete: setLoaded,
      // });
    }
  }, [loading, setLoaded]);

  const handleReplyClick = () => {
    if (onReplyClick) {
      onReplyClick(comment);
    } else {
      handleChildReplyClick();
    }
  };

  return (
    <Box className={clsx(s.root, className, first && s.first)} {...other}>
      <Box className={clsx(s.header, headerClassName)}>
        <Avatar
          className={clsx(s.avatar, avatarClassName)}
          displayName={author.displayName}
          photoURL={author.photoUrl}
        />
        <Box className={s.author}>
          <Box display={"flex"}>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              {author.displayName}
            </Typography>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              <GroupMemberBadges role={author.role} />
            </Typography>
          </Box>
          <Typography className={s.time} variant="subtitle2">
            {<TimeAgo date={created}></TimeAgo>}
          </Typography>
        </Box>
        <Likeable
          likes={comment.likes}
          totalLikesCount={comment.totalLikes}
          likedByMe={comment.likedByMe}
          onLikeToggle={onLikeToggle}
          disabled={likeTogglePending}
        />
      </Box>

      <Box className={clsx(s.body, bodyClassName)}>
        <Typography component="div" className={s.content}>
          <HeightOverflow
            maxHeight={68}
            renderLabel={(expanded: boolean) => (
              <Typography className={s.expand}>
                {expanded ? "Show less" : "Show more"}
              </Typography>
            )}
            disableOverlay
          >
            <EditorFieldEditor value={content} readOnly multiline />
          </HeightOverflow>
        </Typography>

        <Typography
          className={s.reply}
          children="Reply"
          variant="body1"
          onClick={handleReplyClick}
        />

        {/*
        // TODO_API_V2_GROUP_POSTS: Implement pagination if needed. Currently fetching all replies.
        {hasNext && (
          <Button
            className={s.moreButton}
            onClick={handleMoreClick}
            disabled={loading}
            fullWidth
            children="Show older comments"
          />
        )} */}

        {replies?.map((reply, index) => (
          <GroupPostCommentsListItemNoReplies
            key={reply.id}
            groupId={groupId}
            postId={postId}
            parentCommentId={comment.id}
            comment={reply}
            first={index === 0}
            onReplyClick={handleChildReplyClick}
          />
        ))}

        {replies && replyToComment && (
          <GroupPostsCommentForm
            className={clsx(s.form, replies.length === 0 && s.noReplies)}
            post={null}
            postId={postId}
            comment={replyToComment}
            placeholder={`Send feedback to ${replyToComment.author.displayName}`}
            helperText=""
            onSubmit={handleCommentSubmit}
            focusOnInit
          />
        )}
      </Box>
    </Box>
  );
}
