import { clientFormsClient } from "../api/ApiClients";

import ServiceBase from "./ServiceBase";

class ClientFormsClientService extends ServiceBase {
  async getForm(slug: string, username?: string) {
    return clientFormsClient.getClientFormClient(slug, username);
  }

  async getFormsList(
    page: number,
    pageSize: number,
    clientId: number | null | undefined,
  ) {
    return clientFormsClient.getClientFormClientList(page, pageSize, clientId);
  }
}

export default new ClientFormsClientService();
