import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ArrowBackIcon } from "../../icons/ArrowBack.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import LoadingButton from "../button/LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
  },

  backArrow: {
    marginLeft: theme.spacing(-1.5),

    "& svg": {
      width: 32,
      height: 32,
    },
  },

  close: {
    marginRight: theme.spacing(-1.5),
  },
}));

export interface ClientGoalsDrawerHeaderProps extends BoxProps {
  showTitle?: boolean;
  onBack?: () => void;
  onDelete?: () => void;
  onClose: () => void;
  isLoadingDelete?: boolean;
  disabled?: boolean;
  hideDelete?: boolean;
}

export function ClientGoalsDrawerHeader(props: ClientGoalsDrawerHeaderProps) {
  const {
    className,
    showTitle = false,
    onBack,
    onDelete,
    onClose,
    isLoadingDelete,
    disabled = false,
    hideDelete = false,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box>
        {showTitle && (
          <Typography className={s.title} variant="h2" children="Goals" />
        )}
        {onBack && (
          <IconButton
            className={s.backArrow}
            color="primary"
            children={<ArrowBackIcon />}
            onClick={onBack}
            disabled={disabled}
            size="large"
          />
        )}
      </Box>

      <Box>
        {onDelete && !hideDelete && (
          <IconButton
            color="primary"
            children={<BinIcon />}
            onClick={onDelete}
            disabled={isLoadingDelete}
            size="large"
          />
        )}
        <IconButton
          className={s.close}
          color="primary"
          children={<CloseIcon />}
          onClick={onClose}
          disabled={disabled}
          size="large"
        />
      </Box>
    </Box>
  );
}
