import React from "react";

import firebase from "firebase";
import NotificationsService from "../services/NotificationsService";
import {
  IThreadDto,
  MessageDirection,
  MessageDto,
  ICursorPaginatedListOfNotificationBriefDto,
  IPaginatedListOfThreadsDto,
  NotificationDto2,
  IActivitiesCompositeCursorPaginatedList,
  ICursorPaginatedListOfGroupPostDto,
  GroupPostDto,
  ICursorPaginatedListOfGroupPostCommentDto,
  GroupPostCommentDto,
  ActivityFeedbackDto,
  NotificationBriefDto,
} from "@growth-machine-llc/stridist-api-client";
import { InfiniteData, QueryKey, useQueryClient } from "@tanstack/react-query";
import {
  MESSAGES_THREAD_QUERY_KEY,
  MESSAGES_THREADS_QUERY_KEY,
} from "../components/screen/MessagingScreen";
import {
  createInfinitePaginatedDataUpdater,
  IdItem,
  INFINITE_PAGINATED_DATA_UPDATERS,
} from "../utils/optimisticUpdate";
import { NOTIFICATIONS_MENU_LIST_QUERY_KEY } from "../components/menu/NotificationsMenu";
import {
  CLIENT_ACTIVITY_ROUTE,
  CLIENT_GROUP_POST_ROUTE,
  CLIENT_MESSAGE_ROUTE,
  COACH_ACTIVITY_ROUTE,
  COACH_GROUP_POST_ROUTE,
  COACH_MESSAGES_ROUTE,
} from "../routes/routes";
import { ACTIVITY_LIST_QUERY_KEY } from "../components/activity/CoachActivity";
import { GROUP_POSTS_LIST_QUERY_KEY } from "../components/group-posts/GroupPostsList";
import { GROUP_POST_COMMENTS_LIST_QUERY_KEY } from "./groupPosts/useGroupPostComments";
import { useUpdateNotificationsCounter } from "./useUpdateCurrentUser";
import { ACTIVITY_FEEDBACKS_QUERY_KEY } from "../components/activity-feedback/ActivityFeedbackDrawer";
import { matchPath } from "react-router-dom";
import { CLIENT_FORMS_CARD_QUERY_KEY } from "../components/client-forms/ClientFormsCard";

export const liveUpdatesEnabled = true;

const safelyAddItem = <T extends IdItem>(
  items: T[],
  item: T,
  position: "before" | "after" = "after",
) => {
  if (items.some((i) => i.id === item.id)) {
    return items;
  }
  return position === "before" ? [item, ...items] : [...items, item];
};

type ResolveNotificationType = "update" | "invalidate";
type CacheOptions = {
  invalidateCache: (
    queryKey: QueryKey,
    refetchType?: "active" | "none",
  ) => void;
  updateCache: <T>(
    queryKey: QueryKey,
    updater: (data: T) => T,
    exact?: boolean,
  ) => void;
  getCache: <T>(queryKey: QueryKey) => T | undefined;
};

const resolveMessageNotification = (
  message: NotificationDto2["messageDto"],
  type: ResolveNotificationType,
  cacheOptions: CacheOptions,
) => {
  const { invalidateCache: invalidate, updateCache: update } = cacheOptions;
  if (type === "update") {
    const recipient = message.messageAuthor.username;
    const threadUpdaterFn = (prev: IThreadDto) => ({
      ...prev,
      messages: safelyAddItem(
        prev.messages,
        new MessageDto({
          ...message,
          direction: MessageDirection.INCOMING,
        }),
      ),
    });
    const threadLastMessageUpdaterFn = (prev: IPaginatedListOfThreadsDto) => ({
      ...prev,
      items: prev.items.map((thread) => {
        if (thread.slug === recipient) {
          const content = JSON.parse(message.content);
          thread.content = content[0].children[0].text;
          thread.read = false;
        }
        return thread;
      }),
    });

    update([MESSAGES_THREAD_QUERY_KEY, { recipient }], threadUpdaterFn);
    update([MESSAGES_THREADS_QUERY_KEY], threadLastMessageUpdaterFn);
  } else {
    invalidate([
      MESSAGES_THREAD_QUERY_KEY,
      { recipient: message.messageAuthor.username },
    ]);
    invalidate([MESSAGES_THREADS_QUERY_KEY]);
  }
};

const resolveActivityFeedbackNotification = (
  activityFeedback: NotificationDto2["activityFeedbackDto"],
  type: ResolveNotificationType,
  cacheOptions: CacheOptions,
) => {
  if (type === "update") {
    cacheOptions.updateCache<
      InfiniteData<IActivitiesCompositeCursorPaginatedList>
    >(
      [ACTIVITY_LIST_QUERY_KEY],
      (prev) => {
        const activityToUpdate = prev.pages
          .flatMap((page) => page.items)
          .find((activity) => activity.id === activityFeedback.activityId);
        if (activityToUpdate) {
          return INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
            prev,
            activityFeedback.activityId,
            {
              activityFeedbacks: safelyAddItem(
                activityToUpdate.activityFeedbacks,
                activityFeedback,
              ),
            },
          );
        }
        return prev;
      },
      false,
    );
  } else {
    cacheOptions.updateCache<ActivityFeedbackDto[]>(
      [ACTIVITY_FEEDBACKS_QUERY_KEY, { id: activityFeedback.activityId }],
      (prev) => safelyAddItem(prev, activityFeedback),
    );
    cacheOptions.invalidateCache([ACTIVITY_LIST_QUERY_KEY]);
  }
};

const resolveGroupPostNotification = (
  groupPost: NotificationDto2["groupPostDto"],
  type: ResolveNotificationType,
  cacheOptions: CacheOptions,
) => {
  const queryKey = [GROUP_POSTS_LIST_QUERY_KEY, { groupId: groupPost.groupId }];
  if (type === "update") {
    cacheOptions.updateCache<InfiniteData<ICursorPaginatedListOfGroupPostDto>>(
      queryKey,
      (prev) =>
        createInfinitePaginatedDataUpdater<GroupPostDto, void>(
          (items, _arg, _tempId, page) =>
            page === 0 ? safelyAddItem(items, groupPost, "before") : items,
        )(prev, undefined),
    );
  } else {
    cacheOptions.invalidateCache(queryKey);
  }
};

const resolveGroupPostCommentNotification = (
  groupPostComment: NotificationDto2["groupPostCommentDto"],
  postId: number,
  groupId: number,
  type: ResolveNotificationType,
  cacheOptions: CacheOptions,
) => {
  const commentQueryKey = [GROUP_POST_COMMENTS_LIST_QUERY_KEY, { postId }];
  const pendingCommentQueryKey = [
    GROUP_POST_COMMENTS_LIST_QUERY_KEY,
    { postId, pending: true },
  ];
  if (type === "update") {
    const isReply = !!groupPostComment.parentCommentId;
    if (isReply) {
      [commentQueryKey, pendingCommentQueryKey].forEach((key) => {
        cacheOptions.updateCache<
          InfiniteData<ICursorPaginatedListOfGroupPostCommentDto>
        >(key, (prev) =>
          createInfinitePaginatedDataUpdater<GroupPostCommentDto, void>(
            (items, _arg, _tempId, page) =>
              items.map((item) => {
                return item.id === groupPostComment.parentCommentId
                  ? new GroupPostCommentDto({
                      ...item,
                      replies: safelyAddItem(
                        item.replies,
                        groupPostComment,
                        "after",
                      ),
                    })
                  : item;
              }),
          )(prev, undefined),
        );
      });
    } else {
      cacheOptions.updateCache<
        InfiniteData<ICursorPaginatedListOfGroupPostCommentDto>
      >(pendingCommentQueryKey, (prev) =>
        createInfinitePaginatedDataUpdater<GroupPostCommentDto, void>(
          (items, _arg, _tempId, page, totalPages) =>
            page === totalPages - 1
              ? safelyAddItem(items, groupPostComment, "after")
              : items,
        )(prev, undefined),
      );
    }

    cacheOptions.updateCache<InfiniteData<ICursorPaginatedListOfGroupPostDto>>(
      [GROUP_POSTS_LIST_QUERY_KEY, { groupId }],
      (prev) =>
        INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties<GroupPostDto>(
          prev,
          postId,
          (prev) => ({ ...prev, totalComments: prev.totalComments + 1 }),
        ),
    );
  } else {
    [commentQueryKey, pendingCommentQueryKey].forEach((key) => {
      cacheOptions.invalidateCache(key);
    });
  }
};

const resolveClientFormNotification = (cacheOptions: CacheOptions) => {
  cacheOptions.invalidateCache([CLIENT_FORMS_CARD_QUERY_KEY], "active");
};

const useNotificationsResolver = () => {
  const requireLiveUpdate = (routes: string[]) => {
    return routes.some((route) => {
      return matchPath(route, window.location.pathname) !== null;
    });
  };

  const queryClient = useQueryClient();
  const updateNotificationsCounter = useUpdateNotificationsCounter();

  const invalidateCache = (
    queryKey: QueryKey,
    refetchType?: "active" | "none",
  ) => queryClient.invalidateQueries({ queryKey, refetchType });
  const updateCache = <T>(
    queryKey: QueryKey,
    updater: (data: T) => T,
    exact?: boolean,
  ) =>
    queryClient.setQueriesData<T>(
      { queryKey, exact },
      (prev) => (prev ? updater(prev) : prev),
      {},
    );
  const getCache = <T>(queryKey: QueryKey) =>
    queryClient.getQueryData<T>(queryKey);

  const cacheOptions = { invalidateCache, updateCache, getCache };

  return React.useCallback(
    (notification: NotificationDto2) => {
      if (notification.messageDto) {
        const routes = [COACH_MESSAGES_ROUTE, CLIENT_MESSAGE_ROUTE];
        const resolveType = requireLiveUpdate(routes) ? "update" : "invalidate";

        const messageDto = notification.messageDto;
        updateNotificationsCounter("increment", "unreadMessagesCount");
        resolveMessageNotification(messageDto, resolveType, cacheOptions);
      } else if (notification.groupPostDto) {
        const routes = [COACH_GROUP_POST_ROUTE, CLIENT_GROUP_POST_ROUTE];
        const resolveType = requireLiveUpdate(routes) ? "update" : "invalidate";

        const groupPostDto = notification.groupPostDto;
        updateNotificationsCounter("increment", "notificationUnreadCount");
        resolveGroupPostNotification(groupPostDto, resolveType, cacheOptions);
      } else if (notification.groupPostCommentDto) {
        const routes = [COACH_GROUP_POST_ROUTE, CLIENT_GROUP_POST_ROUTE];
        const resolveType = requireLiveUpdate(routes) ? "update" : "invalidate";

        const groupPostCommentDto = notification.groupPostCommentDto;
        updateNotificationsCounter("increment", "notificationUnreadCount");
        resolveGroupPostCommentNotification(
          groupPostCommentDto,
          notification.groupComment.groupPost.id,
          notification.groupComment.groupPost.group.id,
          resolveType,
          cacheOptions,
        );
      } else if (notification.activityFeedbackDto) {
        const routes = [COACH_ACTIVITY_ROUTE, CLIENT_ACTIVITY_ROUTE];
        const resolveType = requireLiveUpdate(routes) ? "update" : "invalidate";

        const activityFeedbackDto = notification.activityFeedbackDto;
        updateNotificationsCounter("increment", "unreadActivitiesCount");
        resolveActivityFeedbackNotification(
          activityFeedbackDto,
          resolveType,
          cacheOptions,
        );
      } else if (notification.clientForm) {
        updateNotificationsCounter("increment", "notificationUnreadCount");
        resolveClientFormNotification(cacheOptions);
      }

      updateCache<InfiniteData<ICursorPaginatedListOfNotificationBriefDto>>(
        [NOTIFICATIONS_MENU_LIST_QUERY_KEY],
        (prev) =>
          createInfinitePaginatedDataUpdater<NotificationBriefDto, void>(
            (items, _arg, _tempId, page) =>
              page === 0 ? safelyAddItem(items, notification, "before") : items,
          )(prev, undefined),
      );
    },
    [requireLiveUpdate, queryClient],
  );
};

interface LiveUpdatesOptions {
  userId: number;
  enabled?: boolean;
}
export const useLiveUpdates = ({
  userId,
  enabled = liveUpdatesEnabled,
}: LiveUpdatesOptions) => {
  const ref = React.useMemo(() => {
    const path = `users/${userId}/updates`;
    return firebase.database().ref(path);
  }, [userId]);

  const resolveNotification = useNotificationsResolver();
  const handleSnapshot = React.useCallback(
    async (snapshot: firebase.database.DataSnapshot) => {
      if (enabled) {
        try {
          const notificationId = snapshot.val();
          resolveNotification(
            await NotificationsService.getNotification(notificationId),
          );
        } catch (e) {
          console.error("Error getting notification", e);
        }
      }
      snapshot.ref.remove();
    },
    [],
  );
  React.useEffect(() => {
    if (enabled) {
      ref.limitToLast(1).on("child_added", handleSnapshot);
      return () => ref.off("child_added", handleSnapshot);
    }
  }, [enabled, handleSnapshot, ref]);
};
