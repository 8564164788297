import React from "react";
import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";
import { ReactComponent as WorkoutsIcon } from "../../icons/WorkoutsIcon.svg";
import { ReactComponent as ExercisesIcon } from "../../icons/ExercisesIcon.svg";
import ClientWorkouts from "./ClientWorkouts";
import ClientExercises from "./ClientExercises";
import ClientWorkoutsExercisesHeader from "./ClientWorkoutsExercisesHeader";
import { CardPagination } from "../pagination/CardPagination";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    overflow: "visible",
  },
  recorded: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },
  headerTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowIconDown: {
    transform: "rotate(90deg)",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
}));

const ClientWorkoutsExercises = (props: any) => {
  const { typeBox, clientId: id } = props;
  const s = useStyles();
  const [lastRecorded, setLastRecorded] = React.useState(null);
  const [query, setQuery] = React.useState("");

  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const [selectWorkout, setSelectWorkout] = React.useState(null);
  const [selectExercise, setSelectExercise] = React.useState(null);
  const [type, setType] = React.useState(typeBox);
  const [isOpenContent, setIsOpenContent] = React.useState(true);

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  const chooseWorkoutItem = React.useCallback((data) => {
    setSelectWorkout(data);
  }, []);

  const chooseExerciseItem = React.useCallback((data) => {
    setSelectExercise(data);
  }, []);

  const sumSessions = React.useCallback((value) => {
    let sum = 0;
    value.exercises.forEach(({ result }) => {
      sum += result.length;
    });
    return sum;
  }, []);

  const sumLbs = React.useCallback((value) => {
    let lbs = 0;
    value.exercises.forEach(({ result }) => {
      const summ = Array.isArray(result)
        ? result
            .map((i) => (i.weight === "-" || !i.weight ? 0 : i.weight))
            .reduce((prev, curr) => +prev + +curr, 0)
        : result;
      lbs += summ;
    });
    return lbs;
  }, []);

  const timeWorkout = React.useCallback((value) => {
    const timeWorkout = value ? JSON.parse(value) : { time: 0 };
    return timeWorkout.time;
  }, []);

  const handleClose = React.useCallback(() => {
    if (selectWorkout) {
      setSelectWorkout(null);
    } else setSelectExercise(null);
  }, [selectWorkout]);

  const handleCloseExercise = React.useCallback(() => {
    setSelectExercise(null);
  }, []);

  const handleExitBox = React.useCallback(() => {
    setIsOpenContent(false);
    setType(typeBox);
  }, [typeBox]);

  return (
    <Card className={clsx(s.root)}>
      {!smUp && !isOpenContent && (
        <Box className={s.headerTitleContainer}>
          <Box className={s.header}>
            {typeBox === "workout" ? (
              <WorkoutsIcon className={s.icon} />
            ) : (
              <ExercisesIcon className={s.icon} />
            )}
            <Box>
              <Typography
                variant="h6"
                className={s.headerTitle}
                children={typeBox === "workout" ? "Workouts" : "Exercises"}
              />
              {lastRecorded ? (
                <Typography className={s.recorded} variant="body1">
                  Last recorded {lastRecorded}
                </Typography>
              ) : null}
            </Box>
          </Box>
          <ArrowIcon
            className={isOpenContent ? "" : s.arrowIconDown}
            onClick={() => {
              setIsOpenContent(!isOpenContent);
              setQuery("");
            }}
          />
        </Box>
      )}
      {isOpenContent && (
        <>
          <ClientWorkoutsExercisesHeader
            selectWorkout={selectWorkout}
            selectExercise={selectExercise}
            handleCloseWorkout={handleClose}
            handleExitBox={handleExitBox}
            type={type}
            setType={setType}
          />
          {type === "workout" ? (
            <ClientWorkouts
              selectWorkout={selectWorkout}
              handleCloseWorkout={handleClose}
              chooseWorkoutItem={chooseWorkoutItem}
              sumSessions={sumSessions}
              timeWorkout={timeWorkout}
              sumLbs={sumLbs}
              clientId={id}
              setLastRecorded={typeBox === "workout" && setLastRecorded}
              lastRecorded={lastRecorded}
            />
          ) : (
            <ClientExercises
              selectExercise={selectExercise}
              handleCloseExercise={handleCloseExercise}
              chooseExerciseItem={chooseExerciseItem}
              clientId={id}
              setLastRecorded={typeBox === "exercises" && setLastRecorded}
              lastRecorded={lastRecorded}
              query={query}
              setQuery={setQuery}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default ClientWorkoutsExercises;
