import { threadsClient } from "../api/ApiClients";
import {
  IPaginatedListOfThreadsDto,
  IUpdateThreadCommand,
  UpdateThreadCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class ThreadsService extends ServiceBase {
  async getAllThreads() {
    // TODO_API_V2 STR-1347: Implement pagination after API endpoint is updated with search
    return threadsClient.getThreads(1, 9999);
  }

  async getThread(recipient: string) {
    // TODO_API_V2 STR-1347: Implement pagination
    return threadsClient.getThread(recipient, 1, 9999);
  }

  async updateThread(variables: IUpdateThreadCommand) {
    const command = UpdateThreadCommand.fromJS(variables);
    return threadsClient.updateThread(command);
  }
}

export default new ThreadsService();
