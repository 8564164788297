import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Box,
  Typography,
  Checkbox,
  Divider,
  FormLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import omit from "lodash.omit";

import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { NotificationsSetting, SOMETHING_WENT_WRONG } from "../../constants";
import { colorSystem } from "../../theme";
import { SettingsCard } from "../card/SettingsCard";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";
import { INotificationsSettingDto } from "@growth-machine-llc/stridist-api-client";

import NotificationsSettingsService from "../../services/NotificationsSettingsService";
import { CLIENT_NOTIFICATION_SETTINGS_QUERY_KEY } from "../../routes/client/settings/notifications/ClientSettingsNotificationRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },

  compact: {},

  row: {
    [theme.breakpoints.up("sm")]: {
      display: "grid",
      gridTemplateColumns: "6fr 1fr 1fr 1fr",
      gridGap: theme.spacing(2),
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),

    "&$option": {
      display: "none",
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      "&$option": {
        display: "block",
      },
    },
  },

  type: {
    fontSize: 14,
    color: theme.palette.common.black,

    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },

  description: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },

  divider: {
    margin: theme.spacing(1, 0),
    backgroundColor: colorSystem.secondaryGray,
    height: 0,

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2, 0),
      height: 1,
    },
  },

  option: {
    [theme.breakpoints.down("sm")]: {
      "&$label": {
        display: "none",
      },

      "&:not($label)": {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0, 2, 0),

        borderColor: colorSystem.border1,
        borderStyle: "solid",
        borderWidth: 1,
      },

      "& label": {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.primary,
      },
    },

    [theme.breakpoints.up("sm")]: {
      textAlign: "center",

      "& label": {
        display: "none",
      },
    },
  },
}));

interface NotificationItemOption {
  label: string;
  key: string;
}

interface NotificationItem {
  name: string;
  description: string;
  options: NotificationItemOption[];
}

const items: NotificationItem[] = [
  {
    name: "New messages",
    description: "I receive a new message from my coach.",
    options: [
      {
        label: "Email",
        key: "newMessageEmail",
      },

      null,

      {
        label: "Push",
        key: "newMessagePush",
      },
    ],
  },
  {
    name: "Comments & Feedback",
    description: "New comments and feedback.",
    options: [
      {
        label: "Email",
        key: "newResponseEmail",
      },

      null,

      {
        label: "Push",
        key: "newResponsePush",
      },
    ],
  },
  {
    name: "Weekly Updates",
    description: "Receive weekly program updates.",
    options: [
      {
        label: "Email",
        key: "weeklyUpdate",
      },
    ],
  },
  {
    name: "Daily Updates",
    description: "Receive daily program updates.",
    options: [
      {
        label: "Email",
        key: "dailyUpdate",
      },
    ],
  },
  {
    name: "Reminders",
    description: "Reminders about scheduled activities.",
    options: [
      {
        label: "Email",
        key: "remindersEmail",
      },
      {
        label: "SMS",
        key: "remindersSms",
      },
      {
        label: "Push notification",
        key: "remindersPush",
      },
    ],
  },
  {
    name: "New group posts",
    description: "A new post is made in a group I’m part of.",
    options: [
      {
        label: "Email",
        key: "newGroupPostEmail",
      },

      null,

      {
        label: "Push",
        key: "newGroupPostPush",
      },
    ],
  },
  {
    name: "Replies to my group posts and comments",
    description: "I get a reply on a post or comment I made.",
    options: [
      {
        label: "Email",
        key: "groupReplyEmail",
      },

      null,

      {
        label: "Push",
        key: "groupReplyPush",
      },
    ],
  },
];

export interface ClientSettingsNotificationsProps
  extends Omit<ContainerProps, "children"> {
  settings: INotificationsSettingDto;
}

export function ClientSettingsNotifications(
  props: ClientSettingsNotificationsProps,
) {
  const { className, settings: userNotificationsSettings, ...other } = props;
  const s = useStyles();
  const [dirty, setDirty] = React.useState(false);
  const [state, setState] = React.useState<NotificationsSetting>(
    omit(userNotificationsSettings, ["id"]),
  );

  const queryClient = useQueryClient();

  const {
    mutate: updateNotification,
    isPending: updateNotificationInFlight,
    status: updateUserNotificationStatus,
  } = useMutation({
    mutationKey: ["update-user-notifications-settings"],
    mutationFn: NotificationsSettingsService.updateNotificationsSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CLIENT_NOTIFICATION_SETTINGS_QUERY_KEY],
      });
    },
  });

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setState((state) => ({ ...state, [event.target.name]: checked }));
      setDirty(true);
    },
    [],
  );

  const handleSave = React.useCallback(() => {
    updateNotification(state, {
      onSuccess: () => {
        setDirty(false);
      },
    });
  }, [state, updateNotification]);

  const disabled = !dirty || updateNotificationInFlight;

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={dirty} />
      <SettingsCard
        header="Notification Settings"
        subheader="Send notifications for the following:"
        ButtonProps={{
          disabled: disabled,
          onClick: handleSave,
          status: updateUserNotificationStatus,
        }}
      >
        <Box className={s.row}>
          <Typography variant="h4" className={s.label}>
            Notification type
          </Typography>
          <Typography variant="h4" className={clsx(s.label, s.option)}>
            Email
          </Typography>
          <Typography variant="h4" className={clsx(s.label, s.option)}>
            SMS
          </Typography>
          <Typography variant="h4" className={clsx(s.label, s.option)}>
            Push
          </Typography>
        </Box>

        {items.map(({ name, description, options }, index) => (
          <React.Fragment key={name}>
            <Box className={s.row}>
              <Box>
                <Typography variant="h5" className={s.type} children={name} />
                <Typography
                  variant="body1"
                  className={s.description}
                  children={description}
                />
              </Box>

              {options.map((option, index) =>
                option ? (
                  <Box className={s.option} key={option?.key || index}>
                    <Checkbox
                      id={option.key}
                      name={option.key}
                      checked={state[option.key]}
                      onChange={handleChange}
                    />
                    <FormLabel htmlFor={option.key}>{option.label}</FormLabel>
                  </Box>
                ) : (
                  <Box key={index} />
                ),
              )}
            </Box>
            {index < items.length - 1 && <Divider className={s.divider} />}
          </React.Fragment>
        ))}
      </SettingsCard>
    </Container>
  );
}
