import React from "react";
import { ReactComponent as HomeIcon } from "../../icons/home.svg";
import { ReactComponent as ProgramsIcon } from "../../icons/archive-folder.svg";
import { ReactComponent as ClientsIcon } from "../../icons/man.svg";
import { ReactComponent as ActivityIcon } from "../../icons/pulse.svg";
import { ReactComponent as MessagesIcon } from "../../icons/letter-open.svg";
import { ReactComponent as GroupsIcon } from "../../icons/Hierarchy.svg";
import { ReactComponent as MarketingIcon } from "../../icons/marketing.svg";
import { UnreadActivitiesCounter } from "../counter/UnreadActivitiesCounter";
import { UnreadMessagesCounter } from "../counter/UnreadMessagesCounter";
import {
  CLIENT_ACTIVITY_ROUTE,
  CLIENT_GROUP_POST_ROUTE,
  CLIENT_HOME_ROUTE,
  CLIENT_PROFILE_ROUTE,
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  COACH_ACTIVITY_INBOX_ROUTE,
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_MESSAGES_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
  MESSAGES_ROUTE,
} from "../../routes/routes";
import { getISODate } from "../../utils/date";

export const menuItems = {
  client: [
    {
      text: "Home",
      link: HOME_ROUTE,
      Icon: HomeIcon,
    },
    {
      text: "Programs",
      link: CLIENT_PROGRAMS_ACTIVE_ROUTE,
      Icon: ProgramsIcon,
    },
    {
      text: "Groups",
      link: CLIENT_GROUP_POST_ROUTE,
      Icon: GroupsIcon,
    },
    {
      text: <UnreadActivitiesCounter>Activity</UnreadActivitiesCounter>,
      link: CLIENT_ACTIVITY_ROUTE,
      Icon: ActivityIcon,
    },
    {
      text: <UnreadMessagesCounter>Messages</UnreadMessagesCounter>,
      link: MESSAGES_ROUTE,
      Icon: MessagesIcon,
    },
    {
      text: "Profile",
      link: CLIENT_PROFILE_ROUTE,
      Icon: ClientsIcon,
    },
  ],
  coach: [
    {
      text: "Programs",
      link: COACH_PROGRAMS_PUBLISHED_ROUTE,
      Icon: ProgramsIcon,
    },
    {
      text: "Clients",
      link: COACH_CLIENTS_ACTIVE_ROUTE,
      Icon: ClientsIcon,
    },
    {
      text: "Groups",
      link: COACH_GROUPS_ACTIVE_ROUTE,
      Icon: GroupsIcon,
    },
    {
      text: <UnreadActivitiesCounter>Activity</UnreadActivitiesCounter>,
      link: COACH_ACTIVITY_INBOX_ROUTE,
      Icon: ActivityIcon,
    },
    {
      text: <UnreadMessagesCounter>Messages</UnreadMessagesCounter>,
      link: COACH_MESSAGES_ROUTE,
      Icon: MessagesIcon,
    },
    {
      text: "Marketing",
      link: "https://marketing.stridist.com/",
      Icon: MarketingIcon,
    },
  ],
};
