import { Box, CircularProgress, Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { Suspense, useEffect } from "react";
import { CommonElementId } from "../../constants";
import CoachComponentBarLoader from "../program-component/CoachComponentBarLoader";
import { grey } from "@mui/material/colors";
import { CoachProgramComponentPreviewRoute } from "../../routes/coach/programs/preview/component/CoachProgramComponentPreviewRoute";
import { useSearchParams } from "react-router-dom";

export const ComponentDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface IComponentDialogProps {
  componentSlug: string;
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleClickOpenDialog: (e?: any, slug?: string) => void;
}

const ComponentDialog = (props: IComponentDialogProps) => {
  const {
    componentSlug,
    openDialog,
    handleCloseDialog,
    handleClickOpenDialog,
  } = props;
  const [searchParams, _] = useSearchParams();
  const componentSearchParam = searchParams.get("component");

  useEffect(() => {
    !componentSearchParam && handleCloseDialog();
  }, [componentSearchParam]);

  return (
    <Dialog
      id={CommonElementId.COMPONENT_DIALOG}
      open={openDialog}
      TransitionComponent={ComponentDialogTransition}
      onClose={handleCloseDialog}
      keepMounted
      hideBackdrop
      PaperProps={{
        sx: {
          width: "100vw",
          height: "100vh",
          maxHeight: "unset",
          maxWidth: "unset",
          margin: "unset",
        },
      }}
    >
      <Suspense
        fallback={
          <>
            <CoachComponentBarLoader backClick={handleCloseDialog} />
            <Box display={"flex"} height={1} color={grey[400]}>
              <CircularProgress
                size={20}
                color={"inherit"}
                sx={{
                  margin: "auto",
                }}
              />
            </Box>
          </>
        }
      >
        <CoachProgramComponentPreviewRoute
          key={componentSlug}
          componentSlugProp={componentSlug}
          handleCloseDialog={handleCloseDialog}
          handleClickOpenDialog={handleClickOpenDialog}
        />
      </Suspense>
    </Dialog>
  );
};

export default ComponentDialog;
