import { inviteCodesClient } from "../api/ApiClients";
import {
  CreateInviteCodeCommand,
  ICreateInviteCodeCommand,
  IUpdateInviteCodeStartDateCommand,
  UpdateInviteCodeStartDateCommand,
} from "@growth-machine-llc/stridist-api-client";

import BaseService from "./ServiceBase";

class InviteCodeService extends BaseService {
  async getNotAssignedCode(){
    return inviteCodesClient.getNotAssignedCode();
  }
  
  async createNotAssignedCode() {
    const command = new CreateInviteCodeCommand();
    return inviteCodesClient.createInviteCode(command);
  }

  async createCode(data: ICreateInviteCodeCommand) {
    const command = new CreateInviteCodeCommand(data);
    return inviteCodesClient.createInviteCode(command);
  }

  async updateCodeStartDate(data: IUpdateInviteCodeStartDateCommand) {
    const command = new UpdateInviteCodeStartDateCommand(data);
    return inviteCodesClient.updateInviteCodeStartDate(command);
  }

  async deleteCode(id: number) {
    return inviteCodesClient.deleteInviteCode(id);
  }
}

export default new InviteCodeService();
