export const addWorkoutExercise = (
  workoutSectionId: number,
  exerciseId: number | undefined,
  inputContent: any,
  newExercise,
) => {
  const parsedContent = JSON.parse(inputContent);
  const workoutSection = parsedContent.find(
    (section) => section.id === workoutSectionId,
  );
  if (!workoutSection) return;

  const { exercises } = workoutSection.workout;

  if (exerciseId !== undefined) {
    const targetIndex = exercises.findIndex(
      (exercise) => exercise.id === exerciseId,
    );

    // Insert after the found exerciseId or at the end if not found
    if (targetIndex !== -1) {
      exercises.splice(targetIndex + 1, 0, newExercise);
    } else {
      exercises.push(newExercise);
    }
  } else {
    exercises.unshift(newExercise); // Insert at the start if exerciseId is undefined
  }

  return JSON.stringify(parsedContent);
};
