import clsx from "clsx";
import React from "react";
import { Drawer, List, ListItem, useMediaQuery } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useLogout } from "../../hooks/useLogout";
import { Link } from "../link/Link";

import logo from "../app/logo.svg";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    [theme.breakpoints.up("md")]: {
      width: theme.adminDrawer.width,
      zIndex: 1,
    },
    borderRight: "1px solid",
    borderRightColor: theme.palette.border.primary,
  },
  navItem: {
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5, 0),
    display: "flex",
    justifyContent: "center",
  },
  navItemSelected: {
    backgroundColor: theme.palette.selected.main,
  },
}));

function AppDrawer(props) {
  const { className, open, location, ...other } = props;
  const { breakpoints } = useTheme();
  const mediaQuery = `(min-width:${breakpoints.values.md}px)`;
  const mdUp = useMediaQuery(mediaQuery);
  const s = useStyles();
  const logout = useLogout({ immediate: true });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isSelected = React.useCallback(
    (navPath) =>
      location.pathname === navPath ||
      location.pathname.startsWith(`${navPath}/`),
    [location.pathname],
  );

  const handleLogOut = React.useCallback(
    (event) => {
      event.preventDefault();
      logout();
    },
    [logout],
  );

  const handleHome = React.useCallback((event) => {
    event.preventDefault();
    queryClient.clear();
    navigate("/");
  }, []);

  const menuItems = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
        onClick: handleHome,
        icon: <img src={logo} alt="logo" />,
      },
      {
        text: "Users",
        link: "/admin/users",
        icon: <PeopleIcon />,
      },
      {
        link: "/logout",
        text: "Log out",
        onClick: handleLogOut,
        icon: <LogoutIcon />,
      },
    ],
    [handleLogOut],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      {...other}
      classes={{ paper: s.paper }}
      open={mdUp ? true : open}
      variant={mdUp ? "permanent" : "temporary"}
      anchor="left"
    >
      <List>
        {menuItems.map((x) => (
          <ListItem
            key={x.link}
            className={clsx(s.navItem, {
              [s.navItemSelected]: isSelected(x.link),
            })}
            button
            component={Link}
            href={x.link}
            onClick={x.onClick || props.onClose}
            underline="none"
          >
            {x.icon}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

const AdminDrawer = AppDrawer;
export { AdminDrawer };
