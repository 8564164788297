import React, { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { CoachClientsFilter } from "../../../components/coach-clients/CoachClientsFilter";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { ClientSort } from "../../../constants";
import { toEnum } from "../../../utils/misc";
import { CoachClientFiltersContext } from "../../../contexts/CoachClientFiltersContext";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { polyfillCSS } from "../../../utils/css";
import {
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_CLIENTS_FORMS_ROUTE,
} from "../../../routes/routes";
import CoachClientListContext from "../../../contexts/CoachClientListContext";
import { ClientsVm, TagDto } from "@growth-machine-llc/stridist-api-client";
import { useQuery } from "@tanstack/react-query";
import TagsService from "../../../services/TagsService";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(10)} + var(--safe-area-inset-right)) !important`,
    ),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
    },
  },
  filters: {
    marginBottom: theme.spacing(3),
  },
}));

export const TAGS_QUERY_KEY = "tags";

const CoachClientsWrapper = () => {
  const s = useStyles();
  const location = useLocation();

  const [coachClientsList, setCoachClientsList] = React.useState<ClientsVm>();
  const coachClientListContextValue = { coachClientsList, setCoachClientsList };

  const { data: tagList } = useQuery({
    queryKey: [TAGS_QUERY_KEY],
    queryFn: TagsService.getTags,
  });

  const [page, setPage] = React.useState<number>(1);
  const [query, setQuery] = React.useState("");
  const [filterTag, setFilterTag] = useQueryParam<number>("tag", null, {
    silent: true,
    normalize: parseInt,
  });
  const [sortKey, setSortKey] = useQueryParam<ClientSort>(
    "sort",
    ClientSort.NAME_ASC,
    {
      silent: true,
      normalize: (param) => toEnum(param, ClientSort, ClientSort.NAME_ASC),
    },
  );

  useEffect(() => {
    setQuery("");
  }, [location.pathname]);

  const handleQueryChange = React.useCallback(
    (query: string) => {
      setQuery(query);
      setPage(1);
    },
    [setQuery],
  );

  const handleSortKeyChange = React.useCallback(
    (sortKey: ClientSort) => {
      setSortKey(sortKey);
    },
    [setSortKey],
  );

  const handleFilterTagIdChange = React.useCallback(
    (tagId: number) => {
      setFilterTag(tagId);
    },
    [setFilterTag],
  );

  const contextValue = {
    page,
    setPage,
    query,
    setQuery,
    filterTag,
    setFilterTag,
    sortKey,
    setSortKey,
  };

  return (
    <>
      <CoachClientFiltersContext.Provider value={contextValue}>
        <CoachClientListContext.Provider value={coachClientListContextValue}>
          {location.pathname === COACH_CLIENTS_FORMS_ROUTE ? (
            <Outlet />
          ) : (
            <Container className={s.root} maxWidth="xl">
              <CoachClientsFilter
                className={s.filters}
                query={query}
                onChangeQuery={handleQueryChange}
                sortKey={sortKey}
                onChangeSortKey={handleSortKeyChange}
                tagList={tagList || []}
                onChangeFilterTagId={handleFilterTagIdChange}
                filterTag={filterTag}
                disabled={
                  !location.pathname.includes(COACH_CLIENTS_ACTIVE_ROUTE)
                }
              />
              <Outlet />
            </Container>
          )}
        </CoachClientListContext.Provider>
      </CoachClientFiltersContext.Provider>
    </>
  );
};

export default CoachClientsWrapper;
