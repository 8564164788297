export const SLICE_NAMES = {
  CURRICULUM: "curriculum",
  API: "api",
};

export enum AsyncStatus {
  /**
   * The request has not started yet.
   * This is the default or initial state.
   */
  Idle = "idle",

  /**
   * The request is in progress.
   * Indicates that the operation has started but has not yet finished.
   */
  Pending = "pending",

  /**
   * The request was successful.
   * Data has been successfully retrieved or the operation was completed.
   */
  Succeeded = "succeeded",

  /**
   * The request failed.
   * There may be an error message or failure details.
   */
  Failed = "failed",
}

export interface AsyncState<T> {
  value: T;
  status: AsyncStatus;
  error: string | null;
}

export const createInitAsyncState = <T>(value: T): AsyncState<T> => {
  return {
    value,
    status: AsyncStatus.Idle,
    error: null,
  };
};

export const handlePending = <T>(state: AsyncState<T>) => {
  state.status = AsyncStatus.Pending;
  state.error = null;
};

export const handleRejected = <T>(state: AsyncState<T>, action) => {
  state.status = AsyncStatus.Failed;
  state.error = action.error.message ?? "Unknown Error";
};

export const handleFulfilled = <T>(state: AsyncState<T>) => {
  state.status = AsyncStatus.Succeeded;
  state.error = null;
};
