import clsx from "clsx";
import React from "react";
import {
  TableRow,
  TableRowProps,
  TableCell,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardRounded } from "@mui/icons-material";
import { UserMenu } from "./UserMenu";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AdminUserInfoDto,
  UserRole,
} from "@growth-machine-llc/stridist-api-client";
import { formatDateToLongString } from "../../utils/date";
import { Dayjs } from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  userCard: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",

    "& > :nth-child(1)": {
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(2.5),
      },
    },
  },

  email: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
  },
}));

export interface UsersTableRowProps extends TableRowProps {
  user: AdminUserInfoDto;
}

export function UsersTableRow(props: UsersTableRowProps) {
  const { className, user, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const location = useLocation();

  const handleForwardClick = React.useCallback(() => {
    navigate(`${location.pathname}/${user.id}`);
  }, [user.username]);

  return (
    <TableRow className={clsx(s.root, className)} hover {...other}>
      <TableCell component="th" scope="row">
        <Box className={s.userCard}>
          <Avatar src={user.photoUrl} />
          <Box>
            {user.displayName}
            <br />
            <span className={s.email}>{user.email}</span>
          </Box>
        </Box>
      </TableCell>
      {user.role === UserRole.COACH && (
        <>
          <TableCell align="center">{user.clientsCount}</TableCell>
          <TableCell align="center">{user.programsCount}</TableCell>
        </>
      )}
      <TableCell align="center">
        {user.lastLoginAt
          ? formatDateToLongString(user.lastLoginAt as Dayjs)
          : "-"}
      </TableCell>
      <TableCell align="center">
        <IconButton
          children={<ArrowForwardRounded />}
          onClick={handleForwardClick}
          size="large"
        />
        <UserMenu user={user} />
      </TableCell>
    </TableRow>
  );
}
