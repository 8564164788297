import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { EditorDialog, EditorDialogProps } from "../dialog/EditorDialog";
import { useUpdateGroupMutation } from "../groups/mutations/UpdateGroup";
import { GroupDescriptionEditor } from "./GroupDescriptionEditor";
import { isEmptyContent } from "../editor/utils/common";
import { useGroupRoute } from "../../routes/coach/group/CoachGroupRouteWrapper";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupDescriptionDialogProps extends EditorDialogProps {
  slug: string;
  description: string;
}

export function GroupDescriptionDialog(props: GroupDescriptionDialogProps) {
  const { className, id, description, slug, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const { updateSettingsMutation } = useUpdateGroupMutation({
    currentSlug: slug,
  });
  const { mutate: updateGroup, isPending: pending } = updateSettingsMutation;
  const initialValue = React.useMemo(
    () => (description ? JSON.parse(description) : []),
    [description],
  );
  const [content, setContent] = React.useState(initialValue);
  const isEmpty = isEmptyContent(content);
  const isInitiallyEmpty = isEmptyContent(initialValue);
  const groupProps = useGroupRoute();

  const handleSubmit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }

      updateGroup({
        ...groupProps.group,
        id: groupProps.group.id,
        name: groupProps.group.name,
        description: JSON.stringify(content),
      });
    },
    [content, id, onClose, updateGroup],
  );

  return (
    <EditorDialog
      className={clsx(s.root, className)}
      title="Group description"
      submitDisabled={pending || (isEmpty && isInitiallyEmpty)}
      onSubmit={handleSubmit}
      {...other}
    >
      <GroupDescriptionEditor value={content} onChange={setContent} />
    </EditorDialog>
  );
}
