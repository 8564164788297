import { progressPhotoClient } from "../api/ApiClients";
import {
  ICreateProgressPhotoSetCommand,
  CreateProgressPhotoSetCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class ProgressPhotoService extends ServiceBase {
  async getPhotoSets(clientId: number, pageNumber: number, pageSize: number) {
    return progressPhotoClient.getPhotoSets(clientId, pageNumber, pageSize);
  }

  async getPhotos(clientId: number, pageNumber: number, pageSize: number) {
    return progressPhotoClient.getPhotos(clientId, pageNumber, pageSize);
  }

  async createPhotoSet(variables: ICreateProgressPhotoSetCommand) {
    const command = CreateProgressPhotoSetCommand.fromJS(variables);
    return progressPhotoClient.createPhotoSet(command);
  }

  async deleteSinglePhoto(id: number) {
    return progressPhotoClient.deleteSinglePhoto(id);
  }
}

export default new ProgressPhotoService();
