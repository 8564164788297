import React, { FC } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import {
  Check as CheckIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";

import { useQuery } from "@tanstack/react-query";
import TerraService from "../../services/TerraService";

export interface TerraConnectProps extends ButtonProps<"a"> {
  connected: boolean;
}

export const CLIENT_TERRA_WIDGET_QUERY_KEY = "client-terra-widget";

const TerraConnectButton: FC<TerraConnectProps> = (props) => {
  const { connected, children, ...restProps } = props;

  const { data: terraWidgetSession } = useQuery({
    queryKey: [CLIENT_TERRA_WIDGET_QUERY_KEY],
    queryFn: () => TerraService.generateWidgetSession(),
    select: (data) => data?.sessionUrl,
  });

  return (
    <Button
      fullWidth
      variant="contained"
      size="large"
      startIcon={
        terraWidgetSession ? (
          connected && <CheckIcon />
        ) : (
          <CircularProgress size={16} />
        )
      }
      endIcon={<OpenInNewIcon />}
      disabled={!terraWidgetSession}
      href={terraWidgetSession}
      target="_blank"
      rel="noopener noreferrer"
      {...restProps}
    >
      {connected ? "Tracker Connected" : "Connect Tracker"}
      {children}
    </Button>
  );
};

export default TerraConnectButton;
