import clsx from "clsx";
import React from "react";
import { List, ListProps, Typography, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDebounce } from "../../hooks/useDebounce";
import { GroupMemberListItem } from "./GroupMemberListItem";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import GroupsService from "../../services/GroupsService";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { GroupMemberSkeleton } from "../loading/GroupMemberSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },

  empty: {
    paddingBottom: theme.spacing(3),
  },
}));

export interface GroupMembersListProps extends ListProps {
  group: GroupDto2;
  query?: string;
}

export const GROUP_MEMBERS_LIST_QUERY_KEY = "group-members-list";
const GROUP_MEMBERS_LIST_PAGE_SIZE = 10;
const FETCH_NEXT_PAGE_WHEN_REMAINING = 2;

export function GroupMembersList(props: GroupMembersListProps) {
  const { className, query = "", group, ...other } = props;
  const { id: groupId, members: initialMembers } = group;
  const delayedQuery = useDebounce(query, 250);

  const {
    data: membersData,
    ref: memberElementRef,
    isLoading: isLoadingInitial,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [GROUP_MEMBERS_LIST_QUERY_KEY, { groupId, query: delayedQuery }],
    queryFn: ({ pageParam = 1 }) =>
      GroupsService.getGroupMembers(
        groupId,
        pageParam as number,
        GROUP_MEMBERS_LIST_PAGE_SIZE,
        query === "" ? undefined : query,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const s = useStyles();

  const handleLoadMoreClick = () => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const members = membersData?.pages.flatMap((page) => page.items) ?? [];

  return (
    <List className={clsx(s.root, className)} {...other}>
      {isLoadingInitial ? (
        Array.from({ length: 2 }).map((_, index, arr) => (
          <>
            <GroupMemberSkeleton key={`group-member-skeleton-${index}`} />
            {arr.length - 1 !== index && <Divider />}
          </>
        ))
      ) : members.length > 0 ? (
        <>
          {members.map((member, index) => (
            <>
              {members.length - index === FETCH_NEXT_PAGE_WHEN_REMAINING && (
                <div ref={memberElementRef} />
              )}
              <GroupMemberListItem
                key={member.id}
                groupId={groupId}
                member={member}
                divider={index < members.length - 1}
              />
            </>
          ))}
          {hasNextPage && (
            <LoadMoreButton
              onClick={handleLoadMoreClick}
              disabled={isFetching}
              loading={isFetchingNextPage}
              fullWidth
            >
              Show more
            </LoadMoreButton>
          )}
        </>
      ) : (
        <Typography component="div" className={s.empty}>
          No members found
        </Typography>
      )}
    </List>
  );
}
