import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  TextField,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  ProgramSelectableItem,
  ProgramSelectableItemProps,
} from "../item/ProgramSelectableItem";
import { useClient } from "../../hooks/useClient";
import { useDebounce } from "../../hooks/useDebounce";
import { getFirstName } from "../../utils/user";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { BaseDialog } from "./BaseDialog";

import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import CoachProgramsService from "../../services/CoachProgramsService";
import {
  BriefClientDto,
  BriefProgramDto,
  EnrollmentsForCalendarDto,
  IBatchEnrollClientsCommand,
  IBriefClientDto,
  IBriefProgramDto,
  IEnrollmentsForCalendarDto,
  IUnenrollEnrollClientCommand,
} from "@growth-machine-llc/stridist-api-client";
import { ProgramDialogSkeleton } from "../loading/ProgramDialogSkeleton";
import { SearchField } from "../fields/SearchField";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY } from "../../routes/coach/client/calendar/CoachClientCalendarRoute";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../coach-programs/CoachProgramsListScreen";
import { COACH_CLIENT_PROGRAMS_QUERY_KEY } from "../../routes/coach/client/programs/CoachClientProgramsRouteWrapper";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
    height: 456,
  },

  search: {
    marginBottom: theme.spacing(1),
  },

  item: {
    "&:not(:first-child)": {
      borderTop: `1px solid ${theme.palette.border.primary}`,
    },
  },
}));

export interface ChooseProgramEnrollDialogProps extends DialogProps {
  enrollments: EnrollmentsForCalendarDto[];
  startDate: string;
  deleteEnrollmentId?: number;
  onSuccess?: () => void;
}

export const MANAGE_PROGRAMS_DIALOG_QUERY_KEY = "manage-programs-dialog";
const MANAGE_PROGRAMS_DIALOG_PAGE_SIZE = 10;
const FETCH_NEXT_PAGE_WHEN_REMAINING = 2;

export function ChooseProgramEnrollDialog(
  props: ChooseProgramEnrollDialogProps,
) {
  const {
    className,
    enrollments,
    startDate,
    deleteEnrollmentId,
    onSuccess,
    onClose,
    ...other
  } = props;
  const s = useStyles();
  const [filter, setFilter] = React.useState("");
  const queryClient = useQueryClient();
  const { username, email, id } = useClient();

  const debouncedQuery = useDebounce(filter, 300);

  const {
    data: programsData,
    isLoading: isLoadingInitial,
    ref: programElementRef,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [MANAGE_PROGRAMS_DIALOG_QUERY_KEY, { query: debouncedQuery }],
    queryFn: ({ pageParam = 1 }) =>
      CoachProgramsService.getPublishedPrograms(
        pageParam as number,
        MANAGE_PROGRAMS_DIALOG_PAGE_SIZE,
        debouncedQuery === "" ? undefined : debouncedQuery,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const isLoading = isLoadingInitial || (isFetching && !isFetchingNextPage);

  const { mutate: enrollClient, isPending: enrollClientInFlight } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
      mutationFn: EnrollmentsClientService.batchEnrollClients,
      optimisticUpdater: {
        updateFn: (oldData) => oldData,
      },
      successToastMessage: `Program has been ${
        deleteEnrollmentId ? "changed in" : "added to"
      } client's calendar`,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
          });

          queryClient.resetQueries({
            queryKey: [COACH_CLIENT_PROGRAMS_QUERY_KEY, { client: id }],
          });

          queryClient.invalidateQueries({
            queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
          });

          queryClient.resetQueries({
            queryKey: [MANAGE_PROGRAMS_DIALOG_QUERY_KEY],
            exact: false,
          });
        },
      },
    });

  const {
    mutate: unenrollEnrollClient,
    isPending: unenrollEnrollClientInFlight,
  } = useOptimisticUpdateMutation({
    queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
    mutationFn: EnrollmentsClientService.unenrollEnrollClient,
    optimisticUpdater: {
      updateFn: (oldData) => oldData,
    },
    successToastMessage: `Program has been ${
      deleteEnrollmentId ? "changed in" : "added to"
    } client's calendar`,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
        });

        queryClient.resetQueries({
          queryKey: [MANAGE_PROGRAMS_DIALOG_QUERY_KEY],
          exact: false,
        });
      },
    },
  });

  const programs =
    programsData?.pages
      .flatMap((page) => page.items)
      .filter(
        (program) =>
          !enrollments.some(
            (enrollment) => enrollment.program?.id === program.id,
          ),
      ) ?? [];

  const resetState = React.useCallback(() => {
    setFilter("");
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleSearchChange = React.useCallback((event) => {
    setFilter(event.target.value);
  }, []);

  const onCompleted = React.useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleProgramClick: ProgramSelectableItemProps["onClick"] =
    React.useCallback(
      (program) => {
        const input: IBatchEnrollClientsCommand = {
          programId: program.id,
          startDate,
          emails: [email],
        };

        if (deleteEnrollmentId) {
          unenrollEnrollClient(
            {
              enrollmentId: deleteEnrollmentId,
              programId: program.id,
              startDate,
            },
            {
              onSuccess: onCompleted,
            },
          );
        } else {
          enrollClient(input, {
            onSuccess: onCompleted,
          });
        }
      },
      [
        deleteEnrollmentId,
        enrollClient,
        startDate,
        unenrollEnrollClientInFlight,
      ],
    );

  const disabled = enrollClientInFlight || unenrollEnrollClientInFlight;

  const filteredPrograms = React.useMemo(() => {
    return programs.filter(({ name }) =>
      name.toLowerCase().includes(filter.toLowerCase()),
    );
  }, [programs, filter]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Choose a program"
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <SearchField
        className={s.search}
        variant="outlined"
        fullWidth
        value={filter}
        onChange={handleSearchChange}
        onReset={resetState}
        label="Search programs"
      />

      <Box>
        {isLoading ? (
          Array.from({ length: 3 }).map((_, i) => (
            <ProgramDialogSkeleton key={`program-dialog-skeleton-${i}`} />
          ))
        ) : programs.length === 0 ? (
          <Typography variant="subtitle1">No programs found</Typography>
        ) : (
          <>
            {filteredPrograms.length > 0
              ? filteredPrograms.map((program, i, arr) => (
                  <React.Fragment key={program.id}>
                    {i === arr.length - FETCH_NEXT_PAGE_WHEN_REMAINING && (
                      <div ref={programElementRef} />
                    )}
                    <ProgramSelectableItem
                      className={s.item}
                      program={program}
                      onClick={handleProgramClick}
                      disabled={disabled}
                    />
                    {i < arr.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              : Array.from({ length: 3 }).map((_, i) => (
                  <ProgramDialogSkeleton key={`program-dialog-skeleton-${i}`} />
                ))}
          </>
        )}

        {hasNextPage && filteredPrograms.length > 0 && (
          <LoadMoreButton
            sx={{ mb: 2 }}
            fullWidth
            onClick={() => !isFetchingNextPage && fetchNextPage()}
            disabled={isFetching && !isFetchingNextPage}
            loading={isFetchingNextPage}
          />
        )}
      </Box>
    </BaseDialog>
  );
}
