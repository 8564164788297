import clsx from "clsx";
import React from "react";
import { List, ListProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { uniqBy } from "lodash";

import { ComponentType } from "../../constants";

import { ActivityListItemProps, ActivityListItem } from "./ActivityListItem";
import { ActivityCompletionProgress } from "./ActivityCompletion";
import {
  ActivityDto,
  BaseWeekDto,
  ComponentActivityDto,
  ComponentBriefDto,
} from "@growth-machine-llc/stridist-api-client";
import { ISO_DATE_FORMAT } from "../../utils/date";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  activity: {
    padding: 0,
    marginBottom: 24,
  },
}));

interface ActivitiesByDate {
  [key: string]: ActivityDto[];
}

export interface ActivityListProps extends ListProps {
  activities: ComponentActivityDto[];
  variant: ActivityListItemProps["variant"];
  userName?: string;
  clientsScreen?: boolean;
  idProgram?: string;
  week?: BaseWeekDto;
  preview?: boolean;
}

function getCompletionProgress(
  component: ComponentBriefDto,
  activities: ComponentActivityDto[],
) {
  const siblings = activities.filter((it) => it.component.id === component.id);
  const completionProgress = [
    ComponentType.HABIT,
    ComponentType.CHECKIN,
  ].includes(component.type as any)
    ? siblings.map(
        ({ date, completedAt }): ActivityCompletionProgress => ({
          date: date.format(ISO_DATE_FORMAT),
          completed: !!completedAt,
        }),
      )
    : undefined;

  return completionProgress;
}

export function ActivityList(props: ActivityListProps) {
  const {
    className,
    activities,
    variant,
    userName,
    clientsScreen,
    idProgram,
    week,
    preview = false,
    ...other
  } = props;

  const s = useStyles();

  const uniqueActivities = React.useMemo(() => {
    return uniqBy(activities, (activity) => activity.component.id);
  }, [activities]);

  //TODO_API_V2_ACTIVITY: Consider using positions instead of sorting by days and full week
  const sortingActivities = React.useMemo(() => {
    return uniqueActivities.sort((a, b) => {
      const aHasFullWeek =
        JSON.parse(a.component.days).filter(Boolean).length === 7;
      const bHasFullWeek =
        JSON.parse(b.component.days).filter(Boolean).length === 7;

      if (aHasFullWeek && !bHasFullWeek) return -1;
      if (!aHasFullWeek && bHasFullWeek) return 1;

      // Otherwise, keep their original order
      return 0;
    });
  }, [uniqueActivities]);

  return uniqueActivities.length ? (
    <List className={clsx(s.root, className)} {...other}>
      {week ? (
        <>
          {uniqueActivities.length ? (
            <React.Fragment>
              {sortingActivities.map((activity) => {
                const { component } = activity;
                const completionProgress =
                  ["summary", "compact"].includes(variant) &&
                  getCompletionProgress(component, activities);
                return (
                  <ActivityListItem
                    className={s.activity}
                    key={activity.id}
                    activity={activity}
                    completionProgress={completionProgress}
                    variant={variant}
                    userName={userName}
                    clientsScreen={clientsScreen}
                    idProgram={idProgram}
                    preview={preview}
                  />
                );
              })}
            </React.Fragment>
          ) : null}
        </>
      ) : (
        <>
          {uniqueActivities.map((activity) => {
            const { component } = activity;
            const completionProgress =
              ["summary", "compact"].includes(variant) &&
              getCompletionProgress(component, activities);
            return (
              <React.Fragment key={activity.id}>
                <ActivityListItem
                  className={s.activity}
                  key={activity.id}
                  activity={activity}
                  completionProgress={completionProgress}
                  variant={variant}
                  userName={userName}
                  clientsScreen={clientsScreen}
                  idProgram={idProgram}
                  preview={preview}
                />
              </React.Fragment>
            );
          })}
        </>
      )}
    </List>
  ) : (
    <Typography>No lessons this week</Typography>
  );
}
