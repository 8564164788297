import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as StarIcon } from "../../icons/Star.svg";
import { UserRole } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1),
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: 700,
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export interface GroupMemberBadgesProps extends BoxProps {
  role: UserRole | string;
}

export function GroupMemberBadges(props: GroupMemberBadgesProps) {
  const { className, role, ...other } = props;
  const s = useStyles();

  return (
    role === UserRole.COACH && (
      <Box
        display={"flex"}
        sx={{ alignItems: "center" }}
        className={clsx(s.root, className)}
        {...other}
      >
        <StarIcon className={s.icon} />
        Coach
      </Box>
    )
  );
}
