import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CalendarComponentCard } from "../card/CalendarComponentCard";

import { ReactComponent as CompletedIcon } from "../../icons/CheckMarkCircle.svg";

import { useNavigate } from "react-router-dom";
import { COACH_CLIENTS_ROUTE } from "../../routes/routes";
import { ActivitiesForCalendarDto } from "@growth-machine-llc/stridist-api-client";
import { ISO_DATE_FORMAT } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
    cursor: "pointer",
    width: "100%",
  },

  completed: {
    color: theme.palette.primary.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

export interface CoachClientCalendarActivityProps extends BoxProps {
  activity: ActivitiesForCalendarDto;
}

export function CoachClientCalendarActivity(
  props: CoachClientCalendarActivityProps,
) {
  const navigate = useNavigate();
  const { className, activity, ...other } = props;
  const s = useStyles();
  const {
    component,
    completed,
    enrollment: { client, program },
  } = activity;
  const handleClick = React.useCallback(() => {
    navigate(
      [
        COACH_CLIENTS_ROUTE,
        client.username,
        "programs",
        program.slug,
        activity.date.format(ISO_DATE_FORMAT),
        component.slug,
      ].join("/"),
    );
  }, [activity.date, client.username, component.slug, program.slug]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <CalendarComponentCard
        component={component}
        subtitle={
          <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
            {component.type}{" "}
            {completed && <CompletedIcon className={s.completed} />}
          </Box>
        }
        onClick={handleClick}
      />
    </Box>
  );
}
