import React from "react";
import { Autocomplete, TextField, Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const CountrySelector = ({ countriesList, control }) => {
  const filteredCountriesList = countriesList.filter(
    (country) => country.name !== "Country"
  );

  return (
    <Controller
      control={control}
      name="country"
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const selectedCountry =
          countriesList.find((c) => c.name === value) || null;

        return (
          <Autocomplete
            options={filteredCountriesList}
            getOptionLabel={(option) => option.name}
            value={selectedCountry}
            onChange={(_, newValue) => {
              onChange(newValue ? newValue.name : "");
            }}
            isOptionEqualToValue={(option, value) =>
              option.name === value.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                placeholder="Select your country"
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error ? error.message : " "}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {selectedCountry && selectedCountry.flag && (
                        <img
                          src={selectedCountry.flag}
                          alt={`${selectedCountry.name} flag`}
                          style={{ width: 20, height: 14, marginRight: 8 }}
                        />
                      )}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                display="flex"
                alignItems="center"
              >
                {option.flag && (
                  <img
                    src={option.flag}
                    alt={`${option.name} flag`}
                    style={{ width: 20, height: 14, marginRight: 8 }}
                  />
                )}
                <Typography variant="body1">{option.name}</Typography>
              </Box>
            )}
          />
        );
      }}
    />
  );
};

export default CountrySelector;
