import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import NotificationsService from "../../../services/NotificationsService";
import { ACTIVITY_LIST_QUERY_KEY } from "../CoachActivity";
import { createInfinitePaginatedDataUpdater } from "../../../utils/optimisticUpdate";
import {
  ActivityDto,
  IActivitiesCompositeCursorPaginatedList,
} from "@growth-machine-llc/stridist-api-client";
import { useReadNotificationMutation } from "../../app/mutations/useReadNotificationMutation";

export const useReadActivityFeedbackMutation = () => {
  return useReadNotificationMutation<
    InfiniteData<IActivitiesCompositeCursorPaginatedList>
  >({
    queryKey: [ACTIVITY_LIST_QUERY_KEY],
    exactQueryKey: false,
    readUpdater(prev, variables) {
      return readStatusUpdater(variables.activityFeedbackId)(prev);
    },
  });
};

const readStatusUpdater = (activityFeedbackId: number) =>
  createInfinitePaginatedDataUpdater<ActivityDto, void>((items) => {
    const activity = items.find((a) =>
      a.activityFeedbacks.some((f) => f.id === activityFeedbackId),
    );
    return items.map((item) => {
      if (item.id === activity?.id) {
        item.activityFeedbacks = item.activityFeedbacks.map((feedback) => {
          if (feedback.id === activityFeedbackId) {
            feedback.read = true;
          }
          return feedback;
        });
      }
      return item;
    });
  });
