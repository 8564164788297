import React, { Suspense, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CoachProgramsFilter } from "../../../components/coach-programs/CoachProgramsFilter";
import { CoachProgramCardDisplay } from "../../../components/coach-programs/CoachProgramCard";
import { ProgramFilterFolder, ProgramSort } from "../../../constants";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { toEnum } from "../../../utils/misc";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { polyfillCSS } from "../../../utils/css";
import clsx from "clsx";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { COACH_PROGRAMS_FOLDERS_ROUTE } from "../../../routes/routes";
import { ProgramListFilterContext } from "../../../contexts/ProgramListFilterContext";
import { CoachProgramsListScreenContext } from "../../../contexts/CoachProgramsListScreenContext";
import { useQuery } from "@tanstack/react-query";
import CoachProgramsService from "../../../services/CoachProgramsService";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(5)} + var(--safe-area-inset-right)) !important`,
    ),
  },

  grid: {},
  list: {},

  clients: {
    display: "grid",
    gridGap: theme.spacing(3),
    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
      gridGap: theme.spacing(3.5),
    },

    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: theme.spacing(4),
    },

    "$list &": {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
  },

  filters: {
    marginBottom: theme.spacing(3),
  },

  card: {
    marginBottom: theme.spacing(3),
  },

  moreButton: {
    marginTop: theme.spacing(1.5),
  },
}));

export const COACH_PROGRAM_FOLDERS_QUERY_KEY = "coach-program-folders";

const CoachProgramsListWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const s = useStyles();

  const { data: programFolders, isLoading: programFoldersLoading } = useQuery({
    queryKey: [COACH_PROGRAM_FOLDERS_QUERY_KEY],
    queryFn: CoachProgramsService.getProgramFolders,
  });

  const [query, setQuery] = React.useState("");

  useEffect(() => {
    setQuery("");
  }, [location.pathname]);

  const [sortKey, setSortKey] = useQueryParam<ProgramSort>(
    "sort",
    ProgramSort.NAME_ASC,
    {
      silent: true,
      normalize: (param) => toEnum(param, ProgramSort, ProgramSort.NAME_ASC),
    },
  );
  const [, setFolder] = useQueryParam<ProgramFilterFolder>("folder", null, {
    silent: false,
  });

  const [display, setDisplay] = useLocalStorage(
    "programs-display",
    CoachProgramCardDisplay.list,
  );
  const [searchParams] = useSearchParams();
  const folder = searchParams.get("folder");
  const folderId = folder as ProgramFilterFolder;

  const handleQueryChange = React.useCallback(
    (query: string) => {
      setQuery(query);
    },
    [setQuery],
  );

  const handleFolderChange = React.useCallback(
    (folder: ProgramFilterFolder) => {
      setFolder(folder);
    },
    [setFolder],
  );

  const handleSortKeyChange = React.useCallback(
    (sortKey: ProgramSort) => {
      setSortKey(sortKey);
    },
    [setSortKey],
  );

  const filterContextValue = {
    query,
    sortKey,
    folderId,
    display,
  };

  const contextValue = {
    programFolders,
    programFoldersLoading,
  };

  return (
    <ProgramListFilterContext.Provider value={filterContextValue}>
      <CoachProgramsListScreenContext.Provider value={contextValue}>
        <Container
          className={clsx(s.root, {
            [s.list]: display === CoachProgramCardDisplay.list,
            [s.grid]: display === CoachProgramCardDisplay.grid,
          })}
          maxWidth="xl"
        >
          {location.pathname !== COACH_PROGRAMS_FOLDERS_ROUTE && (
            <CoachProgramsFilter
              className={s.filters}
              programFolders={programFolders}
              query={query}
              onChangeQuery={handleQueryChange}
              folderId={folderId}
              onChangeFolder={handleFolderChange}
              sortKey={sortKey}
              onChangeSortKey={handleSortKeyChange}
              display={display}
              onChangeDisplay={setDisplay}
            />
          )}
          <Suspense fallback={<PageSkeleton fullWidth />}>
            <Outlet />
          </Suspense>
        </Container>
      </CoachProgramsListScreenContext.Provider>
    </ProgramListFilterContext.Provider>
  );
};

export default CoachProgramsListWrapper;
