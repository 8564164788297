import { ElementType } from "../../editor/types/elements";
import { CustomAssetType } from "../../../constants";

import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import CustomAssetsService from "../../../services/CustomAssetsService";
import { InfiniteData } from "@tanstack/react-query";
import {
  CustomAssetDto,
  ICustomAssetDto,
  PaginatedListOfCustomAssetDto,
} from "@growth-machine-llc/stridist-api-client";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY } from "./useCustomAssets";

export const getElementCustomAssetType = (element: any): CustomAssetType => {
  switch (element.type) {
    case ElementType.IMAGE:
    case ElementType.VIDEO:
    case ElementType.AUDIO:
    case ElementType.FILE:
      return (element.type as string).toUpperCase() as CustomAssetType;
  }
};

export const normalizeCustomAsset = ({ id, ...element }: any) => {
  const name = element.name as string;
  const content = JSON.stringify(element);
  const assetType = getElementCustomAssetType(element);

  return { name, assetType, content };
};

type CreateCustomAssetOptions = {
  successMessage?: string;
};

export function useCreateCustomAsset({
  successMessage,
}: CreateCustomAssetOptions = {}) {
  return useOptimisticUpdateMutation({
    queryKey: [COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY],
    mutationFn: CustomAssetsService.createCustomAsset,
    successToastMessage: successMessage ?? "Asset created.",
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<PaginatedListOfCustomAssetDto>,
        variables: ICustomAssetDto,
        id: number,
      ) => {
        return INFINITE_PAGINATED_DATA_UPDATERS.addItem(
          oldData,
          new CustomAssetDto({
            ...variables,
            assetType: getElementCustomAssetType(variables.assetType),
            id,
          }),
        ) as InfiniteData<PaginatedListOfCustomAssetDto>;
      },
    },
    successUpdater: {
      updateFn: (oldData, id, tempId) =>
        INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(oldData, tempId, {
          id,
        }) as InfiniteData<PaginatedListOfCustomAssetDto>,
    },
  });
}
