import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GHL_MISSING_ACCOUNT_QUERY_KEY } from "../UserDetails";
import BillingService from "../../../services/BillingService";

export const useCreateSubAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: BillingService.createSubAccount,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [GHL_MISSING_ACCOUNT_QUERY_KEY, { id: variables.coachId }],
      });
    },
  });
};
