import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import { selectComponentTempIdsMap } from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";
import { IUpdateComponentCommand } from "@growth-machine-llc/stridist-api-client";

export default function* updateComponentDaysWorker(payload: {
  componentId: number;
  days: string;
}) {
  const { componentId, days } = payload;

  const map: TempIdsMap = yield select(selectComponentTempIdsMap);
  const checkedComponentId = map?.[componentId] || componentId;

  const command: IUpdateComponentCommand = {
    id: checkedComponentId,
    days,
  };

  try {
    yield call(ComponentsService.update, command);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
