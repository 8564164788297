import { useMutation, useQueryClient } from "@tanstack/react-query";
import WorkspacesService from "../../../services/WorkspacesService";
import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import { COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY } from "../../../routes/coach/settings/client-portal/CoachSettingsClientPortalRoute";
import {
  IGetWorkspaceClientPortalDto,
  IUserInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { UserInfo } from "../../../hooks/useCurrentUser";

export const useUpdateClientPortalMutation = (
  props?: UseReactQueryPropsBase,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["update-workspace"],
    mutationFn: WorkspacesService.updateWorkspace,
    ...props,
    onSuccess: (result, variables) => {
      queryClient.setQueryData(
        [COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY],
        (prev: IGetWorkspaceClientPortalDto) => {
          return { ...prev, ...variables };
        },
      );
      queryClient.setQueryData([CURRENT_USER_QUERY_KEY], (prev: UserInfo) => {
        return {
          ...prev,
          workspace: {
            ...prev.workspace,
            logoUrl: variables.logoUrl,
          },
        };
      });

      props?.onSuccess && props.onSuccess(result);
    },
  });
};
