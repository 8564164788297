import clsx from "clsx";
import React from "react";
import {
  ListItem,
  ListItemProps,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "../link/Link";
import { Avatar } from "../avatar/Avatar";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(-2),
  },

  itemAvatar: {
    minWidth: theme.spacing(6),

    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(7),
    },
  },

  avatar: {
    margin: theme.spacing(0, 0, 0, 0),

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: theme.spacing(0, 2, 0, 0),
      fontSize: 18,
    },
  },

  primaryText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: 14,
  },
}));

export interface GroupSidebarMemberItemProps extends ListItemProps {
  member: GroupDto2["members"][number];
}

export function GroupSidebarMemberItem(props: GroupSidebarMemberItemProps) {
  const { className, member, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const isCoach = user.role === UserRole.COACH;
  const itemProps = isCoach
    ? {
        button: true,
        href: `/coach/clients/${member.username}/overview`,
        component: Link,
      }
    : {
        button: false,
      };

  return (
    <ListItem
      className={clsx(s.root, className)}
      {...itemProps}
      {...(other as any)}
    >
      <ListItemAvatar className={s.itemAvatar}>
        <Avatar
          className={s.avatar}
          displayName={member.displayName}
          photoURL={member.photoUrl}
        />
      </ListItemAvatar>

      <ListItemText
        classes={{
          primary: s.primaryText,
        }}
        primary={member.displayName}
      />
    </ListItem>
  );
}
