import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InfoBox } from "../../components/info/InfoBox";
import { GroupCard } from "../../components/groups/GroupCard";
import { ManageGroupDialogProps } from "../../components/groups/ManageGroupDialog";
import { Pagination } from "../../components/pagination/Pagination";
import { PaginationContext } from "../../utils/paging";

import { IPaginatedListOfGroupDto } from "@growth-machine-llc/stridist-api-client";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  card: {
    marginBottom: theme.spacing(3),
  },

  none: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(15),
    },
  },
}));

export interface GroupListProps extends Omit<ContainerProps, "children"> {
  groups: IPaginatedListOfGroupDto;
}

export function GroupList(props: GroupListProps) {
  const { className, groups, ...other } = props;
  const s = useStyles();
  const { first } = React.useContext(PaginationContext);

  return (
    <Container className={clsx(s.root, className)} maxWidth="lg" {...other}>
      {groups.totalCount ? (
        <>
          {groups.items.map((group) => (
            <OptimisticUpdateContainer id={group.id}>
              <GroupCard className={s.card} key={group.id} group={group} />
            </OptimisticUpdateContainer>
          ))}
          <Pagination totalCount={groups.totalCount} perPage={first} />
        </>
      ) : (
        <InfoBox
          className={s.none}
          title="No groups"
          subtitle="You haven’t created any client groups, yet."
        />
      )}
    </Container>
  );
}
