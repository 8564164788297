import { useMutation, useQueryClient } from "@tanstack/react-query";
import ActivitiesService from "../../services/ActivitiesService";
import { IActivityBriefDto } from "@growth-machine-llc/stridist-api-client";
import { extractSlugId } from "../../utils/slug";
import { PROGRAM_COMPONENT_QUERY_KEY } from "./useActivity";

type WorkoutParams = {
  username: string;
  date: string;
  component: string;
};

export interface WorkoutStartEndParams {
  id: number;
  workoutAction: string;
}

type WorkoutStartEndMutationParams = WorkoutParams & {
  workoutActionType: "startWorkout" | "endWorkout";
};

export const useWorkoutStartEndMutation = ({
  username,
  date,
  component,
  workoutActionType,
}: WorkoutStartEndMutationParams) => {
  const queryClient = useQueryClient();
  const slugId = extractSlugId(component);
  const queryKey = [
    PROGRAM_COMPONENT_QUERY_KEY,
    { username, date, slugId: slugId },
  ];
  const { mutate, isPending } = useMutation({
    mutationKey: queryKey,
    mutationFn: (input: WorkoutStartEndParams) => {
      return ActivitiesService.upsertActivity({
        id: input.id,
        [workoutActionType]: input.workoutAction,
      });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData<IActivityBriefDto>(queryKey, (prev) => ({
        ...prev,
        [workoutActionType]: variables.workoutAction,
      }));
    },
  });

  return { mutate, isPending };
};
