import clsx from "clsx";
import React from "react";
import { DialogProps, Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import { SearchField } from "../fields/SearchField";
import { ActionButton } from "../button/ActionButton";
import { IGifLight, fetchGiphy, giphyUrlById } from "../../utils/giphy";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useDebounce } from "../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
  },

  paper: {
    minWidth: theme.spacing(45.5),
    maxWidth: theme.spacing(45.5),

    [theme.breakpoints.up("sm")]: {
      minWidth: theme.spacing(65.75),
      maxWidth: theme.spacing(65.75),
    },

    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(106.5),
      maxWidth: theme.spacing(106.5),
    },
  },

  items: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(3, -3, 3, 0),
    overflowY: "scroll",
    height: theme.spacing(32),
  },

  item: {
    width: theme.spacing(18.5),
    height: theme.spacing(18.5),
    margin: theme.spacing(0, 2, 2, 0),
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "transparent",
  },

  selected: {
    borderColor: theme.palette.primary.main,
  },
}));

export interface SelectGifDialogProps extends DialogProps {
  limit?: number;
  onSelectGif?: (url: string) => void;
}

export function SelectGifDialog(props: SelectGifDialogProps) {
  const { className, limit = 100, onSelectGif, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const onError = useGenericErrorHandler({});
  const [query, setQuery] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState<IGifLight[]>([]);
  const [hasMore, setHasMore] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  const delayedQuery = useDebounce(query, 1000);

  const handleMoreClick = React.useCallback(() => {
    setOffset((value) => value + limit);
  }, [limit]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setQuery(event.target.value);
      setRefresh(true);
    },
    [],
  );

  const handleItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const {
        dataset: { gifId },
      } = event.currentTarget;

      setSelected((value) => (value === gifId ? null : gifId));
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }

      if (onSelectGif) {
        onSelectGif(selected);
      }
    },
    [onClose, onSelectGif, selected],
  );

  React.useEffect(() => {
    if (!loading) {
      if (refresh) {
        setItems([]);
      }

      setLoading(true);
      fetchGiphy({
        query: delayedQuery,
        limit,
        offset,
      })
        .then((items) => {
          setItems((value) => (refresh ? items : [...value, ...items]));
          setHasMore(items.length > 0);
          setLoading(false);
          setRefresh(false);
        })
        .catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, onError, delayedQuery]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      classes={{
        paper: s.paper,
      }}
      title="Find a GIF"
      {...other}
    >
      <SearchField
        fullWidth
        variant="outlined"
        placeholder="Search free high-resolution images"
        value={query}
        onChange={handleChange}
      />
      <Box className={s.items}>
        {items.length > 0 ? (
          items.map(({ id, title }) => (
            <Box
              key={id}
              className={clsx(s.item, selected === id && s.selected)}
              title={title}
              data-gif-id={id}
              onClick={handleItemClick}
              style={{
                backgroundImage: `url("${giphyUrlById(id)}")`,
              }}
            />
          ))
        ) : loading ? (
          <Typography variant="body1" children="Loading..." />
        ) : (
          <Typography variant="body1" children="No results" />
        )}

        {hasMore && (
          <Button onClick={handleMoreClick} fullWidth disabled={loading}>
            Load more
          </Button>
        )}
      </Box>
      <ActionButton
        fullWidth
        size="large"
        onClick={handleSubmit}
        disabled={!selected}
      >
        Choose image
      </ActionButton>
    </BaseDialog>
  );
}
