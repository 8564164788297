import { Card, CardProps, Box, Skeleton, Grid } from "@mui/material";
import React from "react";

export interface ActiveProgramSkeletonCardProps extends CardProps {}

const createSkeletonGridItems = () => {
  return Array.from({ length: 2 }, (_, index) => (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        height: 170,
        width: 200,
        mt: { md: "24px" },
      }}
      key={`item${index + 1}`}
    >
      <Skeleton
        animation="wave"
        variant="rounded"
        height={15}
        width={index === 0 ? 200 : 170}
        sx={{ borderRadius: 6 }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={10}
        sx={{ mt: 2, borderRadius: 6 }}
        width={120}
      />
    </Box>
  ));
};

export function ActiveProgramSkeletonCard(
  props: ActiveProgramSkeletonCardProps,
) {
  const skeletonGridItems = createSkeletonGridItems();

  return (
    <Card {...props}>
      <Grid container rowGap={{ xs: 3, md: 0 }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ flex: { xs: "1 1 100%", md: "1 1 0%" } }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              width: { xs: "100%", md: "90%" },
              height: { xs: 100, md: 250 },
              leftBorderRadius: 4,
              rightBorderRadius: { xs: 4, md: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={2}
            sx={{ padding: "24px", pl: { sm: 0 } }}
            justifyContent={"space-around"}
          >
            {skeletonGridItems}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
