import InvitesService from "../../../services/InvitesService";
import {
  PaginatedListOfClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";

export default function useInviteAddedClientsMutation() {
  const queryClient = useQueryClient();
  return useOptimisticUpdateMutation({
    queryKey: [
      COACH_CLIENTS_LIST_QUERY_KEY,
      { status: [UserInviteStatus.PENDING, UserInviteStatus.ADDED] },
    ],
    mutationFn: InvitesService.inviteAddedClients,
    optimisticUpdater: {
      updateFn: (store: PaginatedListOfClientInfoDto, variables) => {
        store.items = store.items?.map((client) => {
          if (variables.ids.includes(client.id)) {
            client.status = UserInviteStatus.PENDING;
          }
          return client;
        });
        return store;
      },
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [COACH_CLIENTS_LIST_QUERY_KEY, { isDialog: true }],
        });
      },
    },
    disableToastAlerts: true,
  });
}
