import React from "react";

import { AuthTokenData } from "../utils/auth";

import firebase from "firebase";
import useAuth from "./auth/useAuth";
import { useMutation } from "@tanstack/react-query";

export type AuthTokenSignInResponse = {
  fireBaseToken?: string;
  data?: AuthTokenData;
};

export const useAuthTokenSignIn = (
  callback?: (error: Error | null, response?: AuthTokenSignInResponse) => void,
) => {
  const { exchangeOAuthToken: retrieveCustomToken } = useAuth();
  const { mutate: signInWithAuthToken, isPending: inFlight } = useMutation({
    mutationKey: ["sign-in-with-auth-token"],
    mutationFn: retrieveCustomToken,
  });
  const [loading, setLoading] = React.useState(false);
  const disabled = inFlight || loading;

  const signIn = React.useCallback(
    (data: AuthTokenData) =>
      signInWithAuthToken(data.id_token, {
        onSuccess: (customToken) => {
          setLoading(true);
          firebase
            .auth()
            .signInWithCustomToken(customToken)
            .then((credential) => credential.user.getIdToken())
            .then((fireBaseToken) => {
              setLoading(false);
              callback(null, {
                fireBaseToken,
                data,
              });
            })
            .catch((error) => {
              setLoading(false);
              callback(error, {
                data,
              });
            });
        },
      }),
    [callback, signInWithAuthToken],
  );

  return [signIn, disabled] as const;
};
