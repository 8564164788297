import React from "react";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Box } from "@mui/material";
import NotificationsSettingsService from "../../../../services/NotificationsSettingsService";
import { ClientSettingsNotifications } from "../../../../components/settings/ClientSettingsNotifications";

export const CLIENT_NOTIFICATION_SETTINGS_QUERY_KEY =
  "client-notification-settings";

export function ClientSettingsNotificationRoute() {
  const { data: settings, isLoading } = useQuery({
    queryKey: [CLIENT_NOTIFICATION_SETTINGS_QUERY_KEY],
    queryFn: () =>
      NotificationsSettingsService.getCurrentUserNotificationsSettings(),
  });

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        settings && <ClientSettingsNotifications settings={settings} />
      )}
      <TrackInfoTool
        trackInfo={{
          name: "Client - Notifications Settings",
        }}
      />
    </>
  );
}
