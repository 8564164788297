const lowercaseCharacter = new RegExp("^(?=.*[a-z])");
const uppercaseCharacter = new RegExp("^(?=.*[A-Z])");
const numericCharacter = new RegExp("^(?=.*[0-9])");
const specialCharacter = new RegExp("^(?=.*[!@#\\\\$%^&*,.`()_+={}|:;<>?/])");

type PasswordRules = {
  [key: string]: {
    regex: RegExp;
    message: string;
  };
};
export const passwordRules: PasswordRules = {
  lowercaseCharacter: {
    regex: lowercaseCharacter,
    message: "Enter at least 1 lowercase character",
  },
  uppercaseCharacter: {
    regex: uppercaseCharacter,
    message: "Enter at least 1 uppercase character",
  },
  numericCharacter: {
    regex: numericCharacter,
    message: "Enter at least 1 numeric character",
  },
  specialCharacter: {
    regex: specialCharacter,
    message: "Enter at least 1 special character",
  },
};

export const validatePassword = (password: string) => {
  const errors = Object.values(passwordRules)
    .map((rule) => {
      if (!rule.regex.test(password)) {
        return rule.message;
      }
    })
    .filter(Boolean);

  return errors.length ? errors[0] : null;
};
