import { EnrollmentsVm } from "@growth-machine-llc/stridist-api-client";
import { PROGRAM_ENROLLMENTS_QUERY_KEY } from "../../../routes/coach/program/enrollments/CoachProgramEnrollmentsRoute";
import EnrollmentsClientService from "../../../services/EnrollmentsClientService";
import { COACH_PROGRAMS_WRAPPER_QUERY_KEY } from "../../../wrappers/router/coach/CoachProgramsWrapper";
import {
  MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY,
  MANAGE_PROGRAM_GROUPS_DIALOG_QUERY_KEY,
} from "../../groups/ClientsAndGroupsSelectDialog";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { useQueryClient } from "@tanstack/react-query";
import { ENROLLMENT_STATS_QUERY_KEY } from "../../list/EnrollmentList";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../../coach-programs/CoachProgramsListScreen";

export const useEnrollMutation = (type: "group" | "clients", slugId, count: number) => {
  const queryClient = useQueryClient();

  const mutationFn =
    type === "group"
      ? EnrollmentsClientService.batchEnrollGroups
      : EnrollmentsClientService.batchEnrollClients;

  const successToastMessage =
    count === 1
      ? `1 ${type === "group" ? "group" : "client"} enrolled.`
      : `${count} ${type === "group" ? "groups" : "clients"} enrolled.`;

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [PROGRAM_ENROLLMENTS_QUERY_KEY, { slugId }],
    });

    queryClient.invalidateQueries({
      queryKey: [COACH_PROGRAMS_WRAPPER_QUERY_KEY, { slugId }],
    });

    queryClient.invalidateQueries({
      queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
    });

    queryClient.resetQueries({
      queryKey:
        type === "group"
          ? [MANAGE_PROGRAM_GROUPS_DIALOG_QUERY_KEY]
          : [MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY],
      exact: false,
    });

    queryClient.invalidateQueries({
      queryKey: [ENROLLMENT_STATS_QUERY_KEY],
    });
  };

  return useOptimisticUpdateMutation({
    queryKey: [PROGRAM_ENROLLMENTS_QUERY_KEY, { slugId }],
    mutationFn,
    optimisticUpdater: {
      updateFn: (oldData: EnrollmentsVm) => oldData,
    },
    successToastMessage,
    options: { onSuccess },
  });
};
