import { useEffect, useMemo, useState } from "react";
import { MutationKey, useMutation } from "@tanstack/react-query";
import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import InvitesService from "../../../services/InvitesService";
import { formatDuration, intervalToDuration } from "date-fns";

type UseInviteClientByCodeMutationProps = {
  mutationKey?: MutationKey;
} & UseReactQueryPropsBase;

export const useInviteClientByCodeMutation = (
  props?: UseInviteClientByCodeMutationProps,
) => {
  const [nextAttemptIn, setNextAttemptIn] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setNextAttemptIn((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { nextResend, canSend } = useMemo(() => {
    if (nextAttemptIn !== undefined) {
      const duration = intervalToDuration({
        start: 0,
        end: nextAttemptIn * 1000,
      });
      const nextResend = formatDuration(duration);
      const canSend = nextAttemptIn === 0;
      return { nextResend, canSend };
    }
    return { nextResendFormatted: undefined, canSend: false };
  }, [nextAttemptIn]);

  const { mutate: sendInvite, isPending } = useMutation({
    ...props,
    mutationFn: InvitesService.inviteClientByCode,
    onSuccess(data) {
      setNextAttemptIn(data.nextAttemptIn);
      props?.onSuccess?.(data);
    },
  });

  return { sendInvite, isPending, nextAttemptIn: nextResend, canSend };
};
