import { InfiniteData } from "@tanstack/react-query";
import { PaginatedListOfMealLoggingDto } from "@growth-machine-llc/stridist-api-client";

import MealLogging319Service from "../../../services/MealLoggingService";

import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";

import { CLIENT_MEALLOGGING_LIST_QUERY_KEY } from "../ClientMealLoggingEntriesList";

export const useRemoveMealLoggingEntryMutation = (
  invalidatesForDate: string,
) => {
  const filterOutItem = INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem;
  const optimisticUpdater = (
    data: InfiniteData<PaginatedListOfMealLoggingDto>,
    id: number,
  ) => filterOutItem(data, id) as InfiniteData<PaginatedListOfMealLoggingDto>;

  return useOptimisticUpdateMutation({
    mutationFn: MealLogging319Service.deleteMealLoggingEntry,
    queryKey: [CLIENT_MEALLOGGING_LIST_QUERY_KEY, { date: invalidatesForDate }],
    optimisticUpdater: {
      updateFn: optimisticUpdater,
    },
    invalidateQueryOptions: {
      enabled: true,
    },
  });
};
