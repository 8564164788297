import { call } from "redux-saga/effects";
import ComponentsService from "../../../../../services/ComponentsService";

export default function* lockAllComponentsWorker(payload: {
    programId: number;
    locked: boolean;
  }) {
  const { programId, locked } = payload;
  try {
    yield call(ComponentsService.togleLockAll, programId, locked);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
