import React from "react";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { ClientSettingsProfile } from "../../../../components/settings/ClientSettingsProfile";

export const CLIENT_SETTINGS_QUERY_KEY =
  "client-profile-settings";

export function ClientSettingsProfileRoute() {
  const me = useCurrentUser();
  return(
    <>
      {me && (
        <ClientSettingsProfile user={me} />
      )}
      <TrackInfoTool
        trackInfo={{
          name: "Client - Settings",
        }}
      />
    </>
  );
}
