import useInfiniteScrollQuery from "../../../hooks/useInfiniteScroll";
import { useDebounce } from "../../../hooks/useDebounce";
import { AssetType, CustomAssetType } from "../../../constants";
import CustomAssetsService from "../../../services/CustomAssetsService";
import { LibraryAssetType } from "../constants";

export const COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY =
  "component-library-asset-list";
const COMPONENT_LIBRARY_ASSET_LIST_PAGE_SIZE = 20;

type useCustomAssetsOptions = {
  query: string;
  assetTypes: AssetType[] | LibraryAssetType[]; // TODO_V2_API clean up enums mess
};

export function useCustomAssets({ query, assetTypes }: useCustomAssetsOptions) {
  const delayedQuery = useDebounce(query, 250);

  return useInfiniteScrollQuery({
    queryKey: [
      COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY,
      { query: delayedQuery, assetTypes },
    ],
    queryFn: ({ pageParam = 1 }) =>
      CustomAssetsService.getCustomAssets(
        pageParam as number,
        COMPONENT_LIBRARY_ASSET_LIST_PAGE_SIZE,
        assetTypes as unknown as CustomAssetType[],
        query === "" ? undefined : query,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });
}
