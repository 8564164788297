import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Divider, Button, Skeleton } from "@mui/material";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ManIcon } from "../../icons/man3.svg";
import { ReactComponent as WindowIcon } from "../../icons/window1.svg";
import { ReactComponent as CheckMarkCircleIcon } from "../../icons/CheckMarkCircle2.svg";
import { ReactComponent as GHLIconGray } from "../../icons/GoHighLevelGrey.svg";
import { ReactComponent as GHLIconWhite } from "../../icons/GoHighLevelWhite.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin2.svg";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { UserRole } from "../../constants";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { UpdateTrialExpirationDateDialog } from "../dialog/UpdateTrialExpirationDateDialog";

import { AdminPaper } from "./AdminPaper";
import { AdminLabeledValue } from "./AdminLabeledValue";
import { AdminTag } from "./AdminTag";
import { useNavigate } from "react-router-dom";
import { UserInfoDto2 } from "@growth-machine-llc/stridist-api-client";
import { useUpdateUserMutation } from "./mutations/useUpdateUserMutation";
import { useDeleteUserMutation } from "./mutations/useDeleteUserMutation";
import { useQuery } from "@tanstack/react-query";
import BillingService from "../../services/BillingService";
import { useCreateSubAccountMutation } from "./mutations/useCreateSubAccountMutation";
import { useDeleteSubAccountMutation } from "./mutations/useDeleteSubAccountMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  paper: {
    flexGrow: 1,
  },

  info: {
    marginRight: theme.spacing(5),
  },

  actions: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },

  button: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },

  addButton: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.common.white,

    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },

  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));

export interface UserDetailsProps extends BoxProps {
  user: UserInfoDto2;
}

export const GHL_MISSING_ACCOUNT_QUERY_KEY = "ghl-missing-account-key";

export function UserDetails(props: UserDetailsProps) {
  const { className, user, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [updateTrialDialogOpen, setUpdateTrialDialogOpen] =
    React.useState(false);
  const [addAsAdminDialogOpen, setAddAsAdminDialogOpen] = React.useState(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] =
    React.useState(false);
  const [deleteSubAccountDialogOpen, setDeleteSubAccountDialogOpen] =
    React.useState(false);
  const { mutate: updateUser, isPending: updatingUser } =
    useUpdateUserMutation();

  const { mutate: deleteUser, isPending: deletingClient } =
    useDeleteUserMutation();
  const switchUser = useSwitchUser();

  const { mutate: createSubAccount, isPending: creatingSubAccount } =
    useCreateSubAccountMutation();

  const { mutate: deleteSubAccount, isPending: deletingSubAccount } =
    useDeleteSubAccountMutation();

  const { data: missingAccount, isLoading: missingAccountInFlight } = useQuery({
    queryKey: [GHL_MISSING_ACCOUNT_QUERY_KEY, { id: user?.id }],
    queryFn: () => BillingService.getMissingSubAccounts([user?.email]),
  });

  const isGHLAccountMissing = React.useMemo(() => {
    return missingAccount?.notFoundEmails?.includes(user.email) ?? false;
  }, [missingAccount, user.email]);

  const handleLogInAsUser = React.useCallback(() => {
    // TODO_API_V2_USER_ID: Temporary convert userId to string. Remove when after complete migrated
    switchUser(+user.id);
  }, [switchUser, user.id]);

  const handleUpdateTrial = React.useCallback(() => {
    setUpdateTrialDialogOpen(true);
  }, []);

  const handleCloseUpdateTrial = React.useCallback(() => {
    setUpdateTrialDialogOpen(false);
  }, []);

  const handleAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(true);
  }, []);

  const handleCloseAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(false);
  }, []);

  const updateUserAdmin = React.useCallback(
    (admin: boolean) => {
      updateUser({ id: user.id, admin });
    },
    [updateUser, user.id],
  );

  const handleConfirmAddAsAdmin = React.useCallback(() => {
    updateUserAdmin(true);
    setAddAsAdminDialogOpen(false);
  }, [updateUserAdmin]);

  const handleRemoveAsAdmin = React.useCallback(() => {
    updateUserAdmin(false);
  }, [updateUserAdmin]);

  const handleDeleteAccount = React.useCallback(() => {
    setDeleteAccountDialogOpen(true);
  }, []);

  const handleCloseDeleteAccount = React.useCallback(() => {
    setDeleteAccountDialogOpen(false);
  }, []);

  const handleConfirmDeleteAccount = React.useCallback(() => {
    deleteUser(user.id);
  }, [deleteUser, user.id]);

  const handleCreateSubAccount = React.useCallback(() => {
    createSubAccount({ coachId: user.id });
  }, [createSubAccount, user.id]);

  const handleDeleteSubAccount = React.useCallback(() => {
    setDeleteSubAccountDialogOpen(true);
  }, []);

  const handleCloseDeleteSubAccount = React.useCallback(() => {
    setDeleteSubAccountDialogOpen(false);
  }, []);

  const handleConfirmDeleteSubAccount = React.useCallback(() => {
    deleteSubAccount(user.id);
    setDeleteSubAccountDialogOpen(false);
  }, [deleteSubAccount, user.id]);

  const disabled = updatingUser || deletingClient;
  const ghlDisabled =
    creatingSubAccount || deletingSubAccount || missingAccountInFlight;

  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <AdminPaper className={clsx(s.paper, s.info)} title="User Info">
          <AdminLabeledValue label="Email" value={user.email}>
            {user.admin && <AdminTag />}
          </AdminLabeledValue>

          <Divider />

          <AdminLabeledValue label="Role" value={user.role} />

          <Divider />

          <AdminLabeledValue
            label="Trial Expiration"
            value={user.trialExpiryDate?.format("MMM D, YYYY")}
          />

          <Divider />

          <AdminLabeledValue
            label="GoHighLevel"
            value={
              missingAccountInFlight ? (
                <Skeleton width={120} />
              ) : isGHLAccountMissing ? (
                "Disconnected"
              ) : (
                "Connected"
              )
            }
            valueComponent={
              missingAccountInFlight ? (
                <Skeleton variant="rectangular" width={200} height={40} />
              ) : isGHLAccountMissing ? (
                <Button
                  className={clsx(s.button, s.addButton)}
                  variant="text"
                  startIcon={<GHLIconWhite />}
                  onClick={handleCreateSubAccount}
                  children="Add GHL account"
                  disabled={ghlDisabled}
                />
              ) : (
                <Button
                  className={clsx(s.button, s.deleteButton)}
                  variant="text"
                  startIcon={<BinIcon />}
                  onClick={handleDeleteSubAccount}
                  children="Delete GHL account"
                  disabled={ghlDisabled}
                  style={{ marginTop: 0 }}
                />
              )
            }
          />

          <Divider />

          <Box className={s.actions}>
            <Button
              className={s.button}
              variant="text"
              startIcon={<ManIcon />}
              onClick={handleLogInAsUser}
              children="Log in as user"
              disabled={disabled}
            />

            <Button
              className={s.button}
              variant="text"
              startIcon={<WindowIcon />}
              onClick={handleUpdateTrial}
              children="Update trial expiration"
              disabled={disabled}
            />

            {user.admin ? (
              <Button
                className={s.button}
                variant="text"
                startIcon={<CheckMarkCircleIcon />}
                onClick={handleRemoveAsAdmin}
                children="Remove as Admin"
                disabled={disabled}
              />
            ) : user.role === UserRole.COACH ? (
              <Button
                className={s.button}
                variant="text"
                startIcon={<CheckMarkCircleIcon />}
                onClick={handleAddAsAdmin}
                children="Add as Admin"
                disabled={disabled}
              />
            ) : null}
            <Button
              className={clsx(s.button, s.deleteButton)}
              startIcon={<BinIcon />}
              onClick={handleDeleteAccount}
              children="Delete account"
              disabled={disabled}
            />
          </Box>
        </AdminPaper>
      </Box>

      <UpdateTrialExpirationDateDialog
        user={user}
        open={updateTrialDialogOpen}
        onClose={handleCloseUpdateTrial}
      />

      <ConfirmActionDialog
        title="Are you sure you want to make this user an admin?"
        open={addAsAdminDialogOpen}
        onClose={handleCloseAddAsAdmin}
        onCancel={handleCloseAddAsAdmin}
        onConfirm={handleConfirmAddAsAdmin}
      />

      <ConfirmActionDialog
        title={`Are you sure you want to delete this ${user.role.toLowerCase()}?`}
        description={
          user.role === UserRole.COACH &&
          "All their data and clients will be removed and cannot be recovered."
        }
        open={deleteAccountDialogOpen}
        disabled={disabled}
        onClose={handleCloseDeleteAccount}
        onCancel={handleCloseDeleteAccount}
        onConfirm={handleConfirmDeleteAccount}
      />

      <ConfirmActionDialog
        title="Are you sure you want to delete the GHL account?"
        description="This action cannot be undone. All associated data will be permanently removed."
        open={deleteSubAccountDialogOpen}
        disabled={deletingSubAccount}
        onClose={handleCloseDeleteSubAccount}
        onCancel={handleCloseDeleteSubAccount}
        onConfirm={handleConfirmDeleteSubAccount}
      />
    </>
  );
}
