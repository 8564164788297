import { QueryKey, useQueryClient } from "@tanstack/react-query";
import {
  OptimisticUpdateMutationOptions,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import {
  IActivityBriefDto as ActivityDto,
  IUpsertActivityCommand as UpsertCommand,
} from "@growth-machine-llc/stridist-api-client";
import ActivitiesService from "../../../services/ActivitiesService";
import { PROGRAM_COMPONENT_QUERY_KEY } from "../../../hooks/activities/useActivity";
import dayjs from "dayjs";
import { CLIENT_HOME_ROUTE_ACTIVITY_QUERY } from "../../screen/ClientHomeScreen";
import { CLIENT_ENROLLMENT_WEEK_QUERY_KEY } from "../../program/ClientProgram";
import { CLIENT_PROFILE_PROGRAMS_LIST_QUERY_KEY } from "../../coach-clients/ClientDetailsPrograms";
import { CLIENT_PROGRAMS_LIST_QUERY_KEY } from "../../../routes/client/programs/list/ClientProgramListRoute";
import { ACTIVITY_LIST_QUERY_KEY } from "../CoachActivity";
import { WEIGHT_MEASUREMENTS_QUERY_KEY } from "../../client-body-weight/ClientBodyWeightCard";
import { CLIENT_BODY_MEASUREMENT_LIST_QUERY_KEY } from "../../card/ClientMeasurementsCard";

type UpdateActivityMutationType<TCommand> = OptimisticUpdateMutationOptions<
  void,
  unknown,
  TCommand,
  ActivityDto
>;
type UpdateActivityComponentMutation<TCommand> = Pick<
  UpdateActivityMutationType<TCommand>,
  "mutationFn" | "disableToastAlerts" | "optimisticUpdater"
> & {
  invalidateKeys?: QueryKey[];
};

export const useUpdateActivityComponentMutation = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();

  const useUpdateActivityMutation = <TCommand>({
    mutationFn,
    optimisticUpdater,
    disableToastAlerts = false,
    invalidateKeys = [],
  }: UpdateActivityComponentMutation<TCommand>) =>
    useOptimisticUpdateMutation({
      queryKey: [PROGRAM_COMPONENT_QUERY_KEY],
      exactQueryKey: false,
      disableToastAlerts,
      mutationFn,
      optimisticUpdater,
      options: {
        onSuccess: (_date) => {
          // Always invalidate activities page on any related mutation
          [...invalidateKeys, [ACTIVITY_LIST_QUERY_KEY]].forEach((queryKey) => {
            queryClient.invalidateQueries({ queryKey, exact: false });
          });
        },
      },
    });

  const upsertActivityFn = (variables: UpsertCommand) =>
    ActivitiesService.upsertActivity({ id, ...variables });

  const { mutate: updateComponentContent, isPending: isUpdatingContent } =
    useUpdateActivityMutation<Pick<UpsertCommand, "content">>({
      disableToastAlerts: true,
      mutationFn: (variables) =>
        upsertActivityFn({ ...variables, edited: true }),
      optimisticUpdater: {
        updateFn: (prev) => prev,
      },
    });

  const useActivitySubmitMutation = (disabledAlerts?: boolean) =>
    useUpdateActivityMutation<Pick<UpsertCommand, "content">>({
      mutationFn: (variables) =>
        upsertActivityFn({ ...variables, submitted: true }),
      optimisticUpdater: {
        updateFn: (prev) => ({
          ...prev,
          submittedAt: dayjs(),
        }),
      },
      invalidateKeys: [
        [CLIENT_HOME_ROUTE_ACTIVITY_QUERY],
        [CLIENT_ENROLLMENT_WEEK_QUERY_KEY],
        [CLIENT_PROFILE_PROGRAMS_LIST_QUERY_KEY],
        [CLIENT_PROGRAMS_LIST_QUERY_KEY],
        [WEIGHT_MEASUREMENTS_QUERY_KEY],
        [CLIENT_BODY_MEASUREMENT_LIST_QUERY_KEY],
      ],
      disableToastAlerts: disabledAlerts,
    });

  const { mutate: submitComponent, isPending: isSubmittingWithAlert } =
    useActivitySubmitMutation();

  const {
    mutate: submitComponentWithNoAlert,
    isPending: isSubmittingWithNoAlert,
  } = useActivitySubmitMutation(true);

  const isSubmitting = isSubmittingWithAlert || isSubmittingWithNoAlert;

  const { mutate: completeComponent, isPending: isCompleting } =
    useUpdateActivityMutation<Pick<UpsertCommand, "completed">>({
      mutationFn: (variables) => upsertActivityFn({ ...variables }),
      optimisticUpdater: {
        updateFn: (prev, { completed }) => ({
          ...prev,
          completedAt: completed ? dayjs() : null,
        }),
      },
      invalidateKeys: [
        [CLIENT_HOME_ROUTE_ACTIVITY_QUERY],
        [CLIENT_ENROLLMENT_WEEK_QUERY_KEY],
        [CLIENT_PROFILE_PROGRAMS_LIST_QUERY_KEY],
        [CLIENT_PROGRAMS_LIST_QUERY_KEY],
      ],
    });

  const isPending = isUpdatingContent || isSubmitting || isCompleting;

  return {
    updateComponentContent,
    submitComponent,
    completeComponent,
    submitComponentWithNoAlert,
    isPending,
  };
};
