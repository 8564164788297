import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as Art } from "../../icons/UndrawWorkChart.svg";
import { isMobileApp } from "../../utils/mobile";

import { GroupPostsAddButton } from "./GroupPostsAddButton";
import NoPosts from "../../icons/NoPosts";
import NoNotes from "../../icons/NoNotes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(15, 0),
    },
  },

  title: {
    marginTop: theme.spacing(3),
  },

  text: {
    fontSize: 18,
    fontWeight: 500,
  },

  button: {
    marginTop: theme.spacing(3),
    marginBottom: isMobileApp ? theme.spacing(3) : "",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(17),
      paddingRight: theme.spacing(17),
    },
  },
}));

export interface GroupPostsEmptyProps extends BoxProps {
  groupId: number;
}

export function GroupPostsEmpty(props: GroupPostsEmptyProps) {
  const { className, groupId, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <NoPosts fill={theme.palette.primary.main} />

      <Typography className={s.title} variant="h5">
        Write your first post!
      </Typography>
      <Typography className={s.text}>
        The group is most likely to engage if you begin the conversation.
      </Typography>
      <GroupPostsAddButton groupId={groupId} className={s.button}>
        Create your first post
      </GroupPostsAddButton>
    </Box>
  );
}
