import { weeksClient } from "../api/ApiClients";
import { WeekMoveDirection } from "../constants";
import ServiceBase from "./ServiceBase";
import { AddWeekCommand, DuplicateWeekCommand, MoveWeekCommand, UpdateWeekComponentsOrderCommand, UpdateWeekDescriptionCommand } from "@growth-machine-llc/stridist-api-client";

class WeeksService extends ServiceBase {
  async add(programId: number) {
    const command = new AddWeekCommand({ programId });
    return weeksClient.addWeek(command);
  }

  async delete(weekId: number) {
    return weeksClient.deleteWeek(weekId);
  }

  async duplicate(id: number, programId: number) {
    const command = new DuplicateWeekCommand({ id, programId });
    return weeksClient.duplicateWeek(command);
  }

  async move(weekId: number, direction: WeekMoveDirection) {
    const command = new MoveWeekCommand({ id: weekId, direction });
    return weeksClient.moveWeek(command);
  }

  async updatePositions(weekId: number, positions: string[]) {
    const command = new UpdateWeekComponentsOrderCommand({ weekId, positions });
    return weeksClient.updateWeekComponentsOrder(command);
  }

  async updateDescription(weekId: number, description: string) {
    const command = new UpdateWeekDescriptionCommand({ id: weekId, description });
    return weeksClient.updateWeekDescription(command);
  }
}

export default new WeeksService();