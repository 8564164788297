import React, { FormEvent } from "react";
import { DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { Alert } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../../../components/dialog/FullScreenDialog";

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import UserEmails from "../../../services/UserEmailsService";
import { useGenericErrorHandler } from "../../../hooks/useGenericErrorHandler";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2),
  },
  cardHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardButton: {
    marginTop: theme.spacing(2),
  },
}));

const initialState = {
  email: "",
  code: "",
  sent: false,
};

const initialErrors = {
  _: undefined,
  email: undefined,
  code: undefined,
};

export interface ChangeEmailDialogProps
  extends Omit<FullScreenDialogProps, "title" | "children" | "id"> {
  id: number;
  email?: string;
}

export function ChangeEmailDialog(props: ChangeEmailDialogProps) {
  const { id: id, email = "", ...other } = props;
  const codeRef = React.useRef(null);
  const [state, setState] = React.useState({
    ...initialState,
    email,
  });
  const [errors, setErrors] = React.useState(initialErrors);
  const s = useStyles();

  // Focus the verification code field once verification email is sent
  React.useEffect(() => {
    if (state.sent) codeRef.current.focus();
  }, [state.sent]);

  const queryClient = useQueryClient();

  const { mutate: commitSendCode, isPending: sendCodeInFlight } = useMutation({
    mutationKey: ["commit-send-code"],
    mutationFn: UserEmails.sendEmailVerificationCode,
  });

  const { mutate: commitUpdateEmail, isPending: updateEmailInFlight } =
    useMutation({
      mutationKey: ["update-email"],
      mutationFn: UserEmails.updateEmail,

      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
      },
    });

  const onError = useGenericErrorHandler({
    setFieldError: (field, message) => {
      setErrors((x) => ({ ...x, [field in state ? field : "_"]: message }));
    },
  });

  const handleChange = React.useCallback((event) => {
    const { name, value } = event.target;
    setState((x) => ({ ...x, [name]: value }));
    setErrors((x) => (x[name] ? { ...x, [name]: undefined } : x));
  }, []);

  const sendCode = React.useCallback(() => {
    setErrors(initialErrors);
    commitSendCode(
      { email: state.email },
      {
        onSuccess: () => {
          setState((x) => ({ ...x, sent: true }));
        },
        onError: (error: any) => {
          onError(error);
        },
      },
    );
  }, [commitSendCode, onError, state.email]);

  const handleSendCode = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      sendCode();
    },
    [sendCode],
  );

  const updateEmail = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setErrors(initialErrors);

      commitUpdateEmail(
        { email: state.email, code: state.code },
        {
          onSuccess: (data) => {
            props.onClose(event, "escapeKeyDown");
          },
          onError: (error: any) => {
            onError(error);
          },
        },
      );
    },
    [commitUpdateEmail, onError, props, state.code, state.email],
  );

  return (
    <FullScreenDialog
      title={state.sent ? "Confirm email change" : "Change my email"}
      {...other}
    >
      <DialogContent>
        <form
          id="change-email-form"
          onSubmit={state.sent ? updateEmail : handleSendCode}
        >
          {errors._ && (
            <Alert className={s.field} severity="error">
              {errors._}
            </Alert>
          )}
          {state.sent && !errors._ && (
            <Alert className={s.field} severity="info">
              Please check your email and enter verification code below.
            </Alert>
          )}
          <TextField
            className={s.field}
            name="email"
            type="email"
            label="New Email Address"
            placeholder="email@example.com"
            value={state.email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
            disabled={sendCodeInFlight || state.sent}
            fullWidth
            //needed to to fix cutting of label
            margin="dense"
          />
          {state.sent && (
            <TextField
              ref={codeRef}
              className={s.field}
              name="code"
              label="Verification Code"
              placeholder="* * * * * *"
              value={state.code}
              onChange={handleChange}
              error={Boolean(errors.code)}
              helperText={errors.code}
              disabled={updateEmailInFlight}
              fullWidth
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          form="change-email-form"
          disabled={sendCodeInFlight || updateEmailInFlight}
          children={state.sent ? "Submit" : "Continue"}
        />
      </DialogActions>
    </FullScreenDialog>
  );
}
