import React from "react";
import { ClientHomeScreen } from "../../../components/screen/ClientHomeScreen";
import { getISODate } from "../../../utils/date";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

export function ClientHomeRoute() {
  const { date: paramDate } = useParams();

  const isValidDate = (date: string) => {
    return dayjs(date, "YYYY-MM-DD", true).isValid();
  };

  const date = isValidDate(paramDate) ? paramDate : getISODate();

  return (
    <>
      <ClientHomeScreen date={date} />
    </>
  );
}
