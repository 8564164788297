import { createContext } from "react";
import {
  ClientEnrollmentDto,
  EnrollmentsStatusCountDto,
} from "@growth-machine-llc/stridist-api-client";

export interface IProgramsContextProps {
  enrollmentsCount: EnrollmentsStatusCountDto;
  setEnrollmentsCount: (enrollmentsCount: EnrollmentsStatusCountDto) => void;
}

const ProgramsContext = createContext<IProgramsContextProps | undefined>(
  undefined,
);

export default ProgramsContext;
