import dayjs from "dayjs";
import { activityFeedbacksClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class ActivityFeedbacksService extends ServiceBase {
  async getMealLoggingActivity(date: dayjs.Dayjs) {
    return activityFeedbacksClient.getMealLoggingActivity(
      date.format("YYYY-MM-DD"),
    );
  }

  async getActivityFeedbacks(activityId: number) {
    return activityFeedbacksClient.getActivityFeedbacks(activityId);
  }
}

export default new ActivityFeedbacksService();
