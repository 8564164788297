import clsx from "clsx";
import React, { startTransition, useCallback, useEffect } from "react";
import {
  Container,
  ContainerProps,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Units } from "../../constants";

import { useCurrentUser, useCurrentUserId } from "../../hooks/useCurrentUser";
import { useMediaMobile } from "../../hooks/useMediaMobile";

import { ClientMeasurementsCard } from "../card/ClientMeasurementsCard";
import { ClientNutritionCard } from "../client-nutrition-targets/ClientNutritionCard";
import { ClientGoalsCard } from "../card/ClientGoalsCard";
import { ClientProgressPhotosCard } from "../card/ClientProgressPhotosCard";
import { ClientSummary } from "../coach-clients/ClientSummary";
import { ClientFormsCard } from "../client-forms/ClientFormsCard";
import { ClientStepsCard } from "../client-steps/ClientStepsCard";
import { ReactComponent as SettingIcon } from "../../icons/Setting.svg";

import ClientWorkoutsExercises from "../client-workouts/ClientWorkoutsExercises";
import { ClientBodyWeightCard } from "../client-body-weight/ClientBodyWeightCard";
import { useNavigate } from "react-router-dom";
import { CLIENT_SETTING_ROUTE } from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { isMobileApp } from "../../utils/mobile";
import { useTerraSdk } from "../../utils/terra-sdk";
import ClientDetailsPrograms from "../coach-clients/ClientDetailsPrograms";
import { useQuery } from "@tanstack/react-query";
import { CLIENT_TERRA_AUTH_TOKEN_QUERY_KEY } from "../settings/ClientSettingsProfile";
import TerraService from "../../services/TerraService";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
    position: "relative",
  },

  bodyweightCard: {
    cursor: "pointer",

    [theme.breakpoints.up("lg")]: {
      height: 224,
    },
  },
}));

export interface ClientProfileScreenProps
  extends Omit<ContainerProps, "children"> {}

export const ClientProfileActions = (
  phoneClickHandler,
  settingsClickHandler,
  isMobile,
) => {
  const theme = useTheme();
  return (
    <Stack direction={"row"}>
      {!isMobileApp && (
        <IconButton
          style={{
            borderRadius: theme.spacing(0.5),
          }}
          onClick={phoneClickHandler}
          size="large"
        >
          <PhoneIphoneOutlinedIcon sx={{ color: "black" }} />
          {!isMobile && (
            <span
              style={{
                fontWeight: 500,
                fontSize: 18,
                lineHeight: "22px",
                color: "black",
                paddingLeft: theme.spacing(1.5),
              }}
            >
              Download app
            </span>
          )}
        </IconButton>
      )}
      <IconButton
        style={{
          borderRadius: theme.spacing(0.5),
        }}
        onClick={settingsClickHandler}
        size="large"
      >
        <SettingIcon />
        {!isMobile && (
          <span
            style={{
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "22px",
              color: "black",
              paddingLeft: theme.spacing(1.5),
            }}
          >
            Settings
          </span>
        )}
      </IconButton>
    </Stack>
  );
};

export function ClientProfileScreen(props: ClientProfileScreenProps) {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { className, ...other } = props;

  const s = useStyles();
  const isMobile = useMediaMobile("md", false);
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const { data: terraOptions } = useQuery({
    queryKey: [CLIENT_TERRA_AUTH_TOKEN_QUERY_KEY],
    queryFn: () => TerraService.generateAuthToken(),
  });

  const openSettings = React.useCallback(() => {
    navigate(CLIENT_SETTING_ROUTE);
  }, []);

  const { isImpersonating } = useCurrentUser();

  const {
    connected: terraConnected,
    init: initTerraSdk,
    sync: syncTerraSdk,
    error: terraError,
  } = useTerraSdk(terraOptions);

  useEffect(() => {
    if (terraError) {
      console.warn("Terra error: ", terraError);
    }
  }, [terraError]);

  const syncTerra = useCallback(async () => {
    if (isImpersonating) {
      return;
    }
    if (!terraConnected) {
      return;
    }
    await initTerraSdk();
    await syncTerraSdk();
  }, [isImpersonating, terraConnected, initTerraSdk, syncTerraSdk]);

  useEffect(() => {
    if (terraOptions) {
      syncTerra().catch((error) => {
        console.warn("Terra capacitor sync error", error);
      });
    }
  }, [terraOptions]);

  return (
    <>
      <Container className={clsx(s.root, className)} {...other}>
        <Grid container sx={{ mb: 3 }} spacing={3}>
          <Grid item xs={12} lg={12}>
            <ClientSummary client={currentUser} />
          </Grid>

          <Grid item xs={12}>
            <ClientStepsCard
              client={{
                id: currentUser.id,
                units: currentUser.units,
              }}
            />
          </Grid>

          <ClientDetailsPrograms clientId={currentUser.id} />

          {
            <Grid item xs={12}>
              <ClientFormsCard clientId={currentUser.id} />
            </Grid>
          }

          <Grid item xs={12}>
            <ClientNutritionCard
              client={{
                clientId: currentUser.id,
                username: currentUser.username,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ClientGoalsCard clientId={currentUser.id} />
          </Grid>

          <Grid item xs={12} md={6}>
            <ClientBodyWeightCard
              clientId={currentUser.id}
              clientUnits={currentUser.units}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ClientMeasurementsCard
              clientId={currentUser.id}
              units={currentUser.units}
            />
          </Grid>

          <Grid item xs={12}>
            <ClientProgressPhotosCard clientId={currentUser.id} />
          </Grid>

          <Grid item xs={12}>
            <ClientWorkoutsExercises
              typeBox="workout"
              clientId={currentUser.id}
            />
          </Grid>

          {!smUp && (
            <Grid item xs={12}>
              <ClientWorkoutsExercises
                typeBox="exercises"
                clientId={currentUser.id}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client - Profile",
        }}
      />
    </>
  );
}
