import clsx from "clsx";
import React from "react";
import { Box, DialogProps, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ActionButton } from "../button/ActionButton";
import { BaseDialog } from "./BaseDialog";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import LoadingActionButton from "../button/LoadingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: 650,
    width: 650,
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.common.black,
  },

  messageInput: {
    cursor: "text",
    height: 240,

    "& > textarea": {
      height: "100% !important",
    },
  },

  buttons: {
    display: "flex",
    marginTop: theme.spacing(3),
  },

  cancelButton: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    borderRadius: 4,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
    width: "30%",
    height: theme.spacing(7),
  },

  inviteButton: {
    flexGrow: 1,
    height: theme.spacing(7),
  },

  addButton: {
    margin: theme.spacing(0, 3, 0, 0),
  },
}));

export interface SendEmailDialogProps extends DialogProps {
  sendingEmail: boolean;
  sendEmail: (message: string) => void;
}

export function SendEmailDialog(props: SendEmailDialogProps) {
  const { className, onClose, sendEmail, sendingEmail, ...other } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();

  const DEFAULT_MESSAGE = `Hello!\n\nI’d like to invite you to be my client on ${brandName}. It’s a coaching platform that will help us stay organized, chat with each other, and track your progress.\n\nIt takes less than a minute to join – see you there!`;

  const [message, setMessage] = React.useState<string>(DEFAULT_MESSAGE);

  const handleMessageChange = React.useCallback(({ target: { value } }) => {
    setMessage(value);
  }, []);

  const handleCancel = React.useCallback(() => {
    onClose(undefined, undefined);
  }, [onClose]);

  const disabled = sendingEmail;

  return (
    <>
      <BaseDialog
        className={clsx(s.root, className)}
        title={"Invite by email"}
        classes={{
          paper: s.paper,
        }}
        onClose={onClose}
        {...other}
      >
        <Box>
          <TextField
            fullWidth
            multiline
            value={message}
            onChange={handleMessageChange}
            InputProps={{
              disableUnderline: true,
              className: s.messageInput,
            }}
          />
          <Box className={s.buttons}>
            <Button
              className={s.cancelButton}
              variant="outlined"
              children="Cancel"
              onClick={handleCancel}
              disabled={disabled}
            />
            <LoadingActionButton
              className={clsx(s.inviteButton, s.addButton)}
              children="Send Invite"
              onClick={() => sendEmail(message)}
              disabled={disabled}
              loading={sendingEmail}
            />
          </Box>
        </Box>
      </BaseDialog>
    </>
  );
}
