import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import GroupsService from "../../../services/GroupsService";
import { COACH_GROUPS_LIST_QUERY_KEY } from "../../../routes/coach/groups/CoachGroupsListRoute";
import {
  IGroupDto2,
  IUpdateGroupCommand,
  PaginatedListOfGroupDto,
  UpdateGroupCommand,
} from "@growth-machine-llc/stridist-api-client";
import { useQueryClient } from "@tanstack/react-query";
import { NAVBAR_GROUP_LIST_QUERY_KEY } from "../GroupNavItemList";
import { COACH_GROUP_WRAPPER_QUERY_KEY } from "../../../routes/coach/group/CoachGroupRouteWrapper";
import { extractSlugId, refreshSlug, slugify } from "../../../utils/slug";

interface UpdateGroupMutationProps {
  currentSlug: string;
  cacheKey?: string;
  disableToastAlerts?: boolean;
}

export const GROUP_NAME_MAX_LENGTH = 200;

export const useUpdateGroupMutation = (props: UpdateGroupMutationProps) => {
  const queryClient = useQueryClient();
  const { currentSlug, ...options } = props;
  const slugId = extractSlugId(currentSlug);

  const onSuccessNavbarInvalidate = () => {
    queryClient.invalidateQueries({
      queryKey: [NAVBAR_GROUP_LIST_QUERY_KEY],
    });
  };

  const updateGroupListFn = (
    oldData: PaginatedListOfGroupDto,
    variables: IUpdateGroupCommand,
  ) => {
    const groupIndex = oldData.items.findIndex(
      (group) => group.id === variables.id,
    );

    if (groupIndex != -1) {
      Object.keys(variables).forEach((key) => {
        oldData.items[groupIndex][key] = variables[key];
      });
      if ("name" in variables) {
        oldData.items[groupIndex].slug = refreshSlug(
          currentSlug,
          variables.name,
        );
      }
    }

    return oldData;
  };

  const updateGroupWrapperFn = (
    oldData: IGroupDto2,
    variables: IUpdateGroupCommand,
  ) => {
    return {
      ...oldData,
      ...variables,
      ...(variables.name && {
        slug: refreshSlug(currentSlug, variables.name),
      }),
    };
  };

  const updateListMutation = useOptimisticUpdateMutation({
    ...options,
    queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
    exactQueryKey: false,
    mutationFn: GroupsService.updateGroup,
    optimisticUpdater: {
      updateFn: updateGroupListFn,
    },
    options: {
      onSuccess: (_, variables) => {
        onSuccessNavbarInvalidate();
        queryClient.setQueryData(
          [COACH_GROUP_WRAPPER_QUERY_KEY, { slugId }],
          (oldData: IGroupDto2) => updateGroupWrapperFn(oldData, variables),
        );
      },
    },
  });

  const updateSettingsMutation = useOptimisticUpdateMutation({
    ...options,
    queryKey: [COACH_GROUP_WRAPPER_QUERY_KEY, { slugId }],
    mutationFn: GroupsService.updateGroup,
    optimisticUpdater: {
      updateFn: updateGroupWrapperFn,
    },
    options: {
      onSuccess: (_, variables: IUpdateGroupCommand) => {
        onSuccessNavbarInvalidate();
        queryClient.setQueriesData<PaginatedListOfGroupDto>(
          {
            queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
          },
          (oldData) => updateGroupListFn(oldData, variables),
        );
      },
    },
  });

  return { updateListMutation, updateSettingsMutation };
};
