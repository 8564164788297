import { workspacesClient } from "../api/ApiClients";
import {
  ISetWorkspaceColorCommand,
  IUpdateMealLoggingPromptsCommand,
  IUpdateWorkspaceCommand,
  SetWorkspaceColorCommand,
  UpdateMealLoggingPromptsCommand,
  UpdateWorkspaceCommand,
  GetWorkspaceClientPortalDto,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class WorkspaceService extends ServiceBase {
  async getWorkspace(slug: string) {
    return workspacesClient.getWorkspace(slug);
  }

  async getWorkspaceClientPortal() {
    return workspacesClient.getWorkspaceClientPortal();
  }

  async getCoachWorkspaceTheme() {
    return workspacesClient.getCoachWorkspaceTheme();
  }

  async updateWorkspace(variables: IUpdateWorkspaceCommand) {
    const command = UpdateWorkspaceCommand.fromJS(variables);
    return workspacesClient.updateWorkspace(variables.id, command);
  }

  async updateCoachWorkspaceTheme(variables: ISetWorkspaceColorCommand) {
    const command = SetWorkspaceColorCommand.fromJS(variables);
    return workspacesClient.setWorkspaceColor(command.id, command);
  }

  async updateMealLoggingPrompts(variables: IUpdateMealLoggingPromptsCommand) {
    const command = UpdateMealLoggingPromptsCommand.fromJS(variables);
    return workspacesClient.updateMealLoggingPromts(command.id, command);
  }
}

export default new WorkspaceService();
