import { urlClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class UrlService extends ServiceBase {
  async getUrlMetadata(url: string) {
    return urlClient.getUrlMetadata(url);
  }
}

export default new UrlService();
