import React from "react";
import { GroupPostsScreen } from "../../../../components/group-posts/GroupPostsScreen";
import { UserRole } from "../../../../constants";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useGroupRoute } from "../CoachGroupRouteWrapper";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";

export function CoachGroupPostsRoute() {
  const { group } = useGroupRoute();
  const { id: userId, role } = useCurrentUser();

  const canPost =
    group && (!group.membersCanNotPost || group.coachId === userId);

  return (
    <>
      {group && (
        <>
          <GroupPostsScreen group={group} />
          <TrackInfoTool
            trackInfo={{
              name: `${
                role === UserRole.COACH ? "Coach" : "Client"
              } - Group Posts`,
              properties: {
                groupSlug: group.slug,
                groupName: group.name,
              },
            }}
          />
        </>
      )}
    </>
  );
}
