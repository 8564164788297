import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { Avatar } from "../avatar/Avatar";
import { MoreMenuButton } from "../button/MoreMenuButton";
import EditorFieldEditor from "../new-editor/EditFieldEditor";
import { HeightOverflow } from "../container/HeightOverflow";
import { Likeable } from "../likeable/Likeable";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { GroupPostsCommentForm } from "./GroupPostsCommentForm";
import { GroupPostCommentsList } from "./GroupPostCommentsList";
import { GroupMemberBadges } from "./GroupMemberBadges";
import { GroupPostMenu } from "./GroupPostMenu";
import useDependantKey from "../../hooks/useDependantKey";
import {
  GroupPostDto,
  ICursorPaginatedListOfGroupPostDto,
} from "@growth-machine-llc/stridist-api-client";
import { useGroupPostComments } from "../../hooks/groupPosts/useGroupPostComments";
import { InfiniteData } from "@tanstack/react-query";
import { GROUP_POSTS_LIST_QUERY_KEY } from "./GroupPostsList";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../utils/optimisticUpdate";
import { useToggleLikeMutation } from "../likeable/mutations/Like";
import { useReadNotificationMutation } from "../app/mutations/useReadNotificationMutation";
import { TimeAgo } from "../../routes/utils/TimeAgo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    boxShadow: theme.shadows[4],

    marginBottom: theme.spacing(3),
    overflow: "hidden",
  },

  header: {
    padding: theme.spacing(3, 3, 1, 3),
    display: "flex",
    alignItems: "center",
  },

  avatar: {
    margin: theme.spacing(0, 2, 0, 0),

    [theme.breakpoints.up("md")]: {
      width: 48,
      height: 48,
    },
  },

  author: {
    flexGrow: 1,
  },

  displayName: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "1.5em",
  },

  time: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  body: {
    padding: theme.spacing(1, 3, 3, 10),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 3, 3, 11),
    },
  },

  more: {
    color: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

  title: {
    fontSize: 14,
    fontWeight: 600,
  },

  content: {
    fontSize: 14,
    fontWeight: 500,
  },

  expand: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export interface GroupPostsListItemProps extends BoxProps {
  post: GroupPostDto;
}

export function GroupPostsListItem(props: GroupPostsListItemProps) {
  const { className, post, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const { title, content, formattedDate, notification, author, created } = post;
  const menuState = usePopupState({
    variant: "popover",
    popupId: "group-post-menu",
  });

  const canEdit = user.id === author?.id || user.role === UserRole.COACH;

  const { mutate: notificationRead, isPending: readingNotification } =
    useReadNotificationMutation<
      InfiniteData<ICursorPaginatedListOfGroupPostDto>
    >({
      queryKey: [GROUP_POSTS_LIST_QUERY_KEY, { groupId: post.groupId }],
      readUpdater: (prev, { groupPostId }) => {
        notification.read = true;
        return INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
          prev,
          groupPostId,
          { notification },
        );
      },
    });

  const {
    like,
    unlike,
    isLoading: likeTogglePending,
  } = useToggleLikeMutation({
    target: "post",
    groupId: post.groupId,
  });

  const onLikeToggle = React.useCallback(
    (liked: boolean) => {
      liked ? like(post.id) : unlike(post.id);
    },
    [like, post.id],
  );

  React.useEffect(() => {
    if (notification) {
      if (
        notification?.userId === user.id &&
        !notification?.read &&
        !readingNotification
      ) {
        notificationRead({ groupPostId: post.id });
      }
    }
  }, [post.id, notification, notificationRead, readingNotification, user.id]);

  const contentKey = useDependantKey(content);
  const {
    comments: postComments,
    pendingComments,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    observeElementRef: observePostElementRef,
  } = useGroupPostComments({
    postId: post.id,
    enabled: post.totalComments > 0,
  });

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {/* TODO_API_V2_GROUP_POSTS STR-1344: Improve with having initial comments from post after API pagination supports cursor */}
      {/*NOTE: Whenever element with this ref intersecting user view, it will fetch comments for current post */}
      <div ref={observePostElementRef} />

      <Box className={s.header}>
        <Avatar
          className={s.avatar}
          displayName={author.displayName}
          photoURL={author.photoUrl}
        />
        <Box className={s.author}>
          <Box display={"flex"}>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              {author.displayName}
            </Typography>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              <GroupMemberBadges role={author.role} />
            </Typography>
          </Box>
          <Typography className={s.time} variant="subtitle2">
            {<TimeAgo date={created.toString()}></TimeAgo>}
          </Typography>
        </Box>
        <Likeable
          likes={post.likes}
          totalLikesCount={post.totalLikes}
          likedByMe={post.likedByMe}
          onLikeToggle={onLikeToggle}
          disabled={likeTogglePending}
        />

        {canEdit && (
          <>
            <MoreMenuButton
              className={s.more}
              styleVariant="inverse"
              {...bindTrigger(menuState)}
            />

            <GroupPostMenu
              post={post}
              {...bindMenu(menuState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
          </>
        )}
      </Box>

      <Box className={s.body}>
        {title && (
          <Typography className={s.title} gutterBottom>
            {title}
          </Typography>
        )}
        <Typography component="div" className={s.content}>
          <HeightOverflow
            maxHeight={72}
            renderLabel={(expanded: boolean) => (
              <Typography className={s.expand}>
                {expanded ? "Show less" : "Show more"}
              </Typography>
            )}
            disableOverlay
          >
            <EditorFieldEditor
              key={contentKey}
              value={content}
              readOnly
              multiline
            />
          </HeightOverflow>
        </Typography>
      </Box>

      <GroupPostCommentsList
        fetching={isLoading}
        postId={post.id}
        groupId={post.groupId}
        comments={postComments}
        pendingComments={pendingComments}
        totalComments={post.totalComments}
        hasMoreComments={hasNextPage}
        fetchMoreComments={fetchNextPage}
        fetchingMoreComments={isFetchingNextPage}
      />
      <GroupPostsCommentForm post={post} comment={null} />
    </Box>
  );
}
