import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { Menu, MenuProps } from "../menu/Menu";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";
import { GROUP_MEMBERS_LIST_QUERY_KEY } from "./GroupMembersList";
import { clientGroupsClient } from "../../api/ApiClients";
import { IPaginatedListOfGroupMemberDto } from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { COACH_GROUPS_LIST_QUERY_KEY } from "../../routes/coach/groups/CoachGroupsListRoute";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../utils/optimisticUpdate";
import { useParams } from "react-router-dom";
import { COACH_GROUP_WRAPPER_QUERY_KEY } from "../../routes/coach/group/CoachGroupRouteWrapper";
import { MANAGE_GROUP_DIALOG_CLIENTS_QUERY_KEY } from "../groups/ManageGroupDialog";
import { extractSlugId } from "../../utils/slug";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupMemberMenuProps extends MenuProps {
  groupId: number;
  memberId: number;
}

export function GroupMemberMenu(props: GroupMemberMenuProps) {
  const { className, groupId, memberId, ...other } = props;
  const s = useStyles();
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const slugId = extractSlugId(slug);

  const { mutate: removeGroupMember } = useOptimisticUpdateMutation({
    queryKey: [GROUP_MEMBERS_LIST_QUERY_KEY, { groupId }],
    mutationFn: (memberId: number) =>
      clientGroupsClient.removeGroupMember(groupId, memberId),
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<IPaginatedListOfGroupMemberDto>,
        memberId: number,
      ) => INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem(oldData, memberId),
    },
    options: {
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: [MANAGE_GROUP_DIALOG_CLIENTS_QUERY_KEY, { groupId }],
        });
        queryClient.invalidateQueries({
          queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
          refetchType: "none",
        });
        // TODO_API_V2_GROUP_MEMBERS STR-1257: Consider adding an updater for COACH_GROUP_WRAPPER query
        queryClient.invalidateQueries({
          queryKey: [COACH_GROUP_WRAPPER_QUERY_KEY, { slugId }],
        });
      },
    },
  });

  const handleDelete = React.useCallback(() => {
    removeGroupMember(memberId);
  }, [memberId, removeGroupMember]);

  return (
    <Menu className={clsx(s.root, className)} {...other}>
      <MenuItem onClick={handleDelete}>
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText>Remove from group</ListItemText>
      </MenuItem>
    </Menu>
  );
}
