import { Draft, PayloadAction, current } from "@reduxjs/toolkit";
import { WeekMoveDirectionType } from "../../../../constants";
import { WeekDto, WeekMoveDirection } from "@growth-machine-llc/stridist-api-client";
import { generateId } from "../../../../components/new-editor/utils/nodeUtil";
import { CurriculumState } from "../../curriculum-slice";
import {
  NormalizedCurriculumComponent,
  NormalizedCurriculumWeek,
} from "../../../types";
import { normalizeCurriculumWeek } from "../../../normalize";

const adjustWeekNumbers = (state: Draft<CurriculumState>) => {
  state.value.program.weeks.forEach((weekId, index) => {
    state.value.weeks[weekId].weekNumber = index + 1;
  });
};

export const addWeekActions = {
  prepare() {
    return {
      payload: generateId(),
    };
  },
  reducer(state: Draft<CurriculumState>, action: PayloadAction<number>) {
    const weekId = action.payload;
    if (!weekId) return;

    const newWeek: NormalizedCurriculumWeek = {
      id: weekId,
      weekNumber: state.value.program.length + 1,
      positions: [],
      components: [],
      description: null,
    };

    state.value.weeks[weekId] = newWeek;
    state.value.program.weeks.push(weekId);
    state.value.program.length += 1;
  },
};

export const deleteWeekActions = (
  state: Draft<CurriculumState>,
  action: PayloadAction<number>,
) => {
  const weekId = action.payload;
  if (!weekId) return;

  delete state.value.weeks[weekId];
  state.value.program.weeks = state.value.program.weeks.filter(
    (id) => id !== weekId,
  );
  state.value.program.length -= 1;

  adjustWeekNumbers(state);
};

export const duplicateWeekActions = {
  prepare(weekToDuplicateId: number) {
    return {
      payload: {
        newWeekId: generateId(),
        weekToDuplicateId,
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{ newWeekId: number; weekToDuplicateId: number }>,
  ) {
    const { weekToDuplicateId, newWeekId } = action.payload;
    if (!weekToDuplicateId || !newWeekId) return;

    const weekToDuplicate = state.value.weeks[weekToDuplicateId];
    if (!weekToDuplicate) return;

    let shouldProceedAction = true;
    const sort = [...weekToDuplicate.positions].map((value, index) => {
      const componentId = weekToDuplicate.components.find((cId) => cId.toString() === value);
      if (!componentId) {
        // do not procceed with any changes to store
        // since ids are impossible to map after api response
        shouldProceedAction = false;
      }
      
      return {
        componentId,
        position: index,
      };
    });

    if (!shouldProceedAction) return;

    sort.sort((a, b) => a.position - b.position);

    const sortedComponentsToDuplicate = sort.map((value) => value.componentId);

    const newComponents: NormalizedCurriculumComponent[] =
      sortedComponentsToDuplicate.map((componentId, index) => {
        const newComponent = {
          ...state.value.components[componentId],
          id: newWeekId + index + 1,
          weekId: newWeekId,
          slug: `${newWeekId + index + 1}-tempslug`,
        };

        state.value.components[newComponent.id] = newComponent;

        return newComponent;
      });

    const newPositions = newComponents.map((component) => String(component.id));

    const newWeek: NormalizedCurriculumWeek = {
      waitingForApiResponse: true,
      id: newWeekId,
      weekNumber: 0,
      description: weekToDuplicate.description,
      //positions: newPositions,
      //components: newComponents.map((c) => c.id),
      components: [],
      positions: [],
    };

    // Insert new week after the target week in program.weeks
    const targetIndex = state.value.program.weeks.indexOf(weekToDuplicateId);
    if (targetIndex !== -1) {
      state.value.program.weeks.splice(targetIndex + 1, 0, newWeekId);
    }
    state.value.program.length += 1;

    // Add new week to the normalized weeks record
    state.value.weeks[newWeekId] = newWeek;

    adjustWeekNumbers(state);
  },
};

export const moveWeekActions = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ id: number; direction: WeekMoveDirectionType }>,
) => {
  const { id, direction } = action.payload;
  if (!id || !direction) return;

  const weekIndex = state.value.program.weeks.indexOf(id);
  if (
    weekIndex === -1 ||
    (direction === WeekMoveDirection.UP && weekIndex === 0) ||
    (direction === WeekMoveDirection.DOWN &&
      weekIndex === state.value.program.weeks.length - 1)
  ) {
    return;
  }

  const swapIndex =
    direction === WeekMoveDirection.UP ? weekIndex - 1 : weekIndex + 1;

  // Swap the weeks in the program's weeks array
  [state.value.program.weeks[weekIndex], state.value.program.weeks[swapIndex]] =
    [
      state.value.program.weeks[swapIndex],
      state.value.program.weeks[weekIndex],
    ];

  adjustWeekNumbers(state);
};

export const updatePositionsAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ id: number; positions: string[] }>,
) => {
  const { id, positions } = action.payload;
  if (!id || !positions) return;

  const week = state.value.weeks[id];
  if (!week) return;

  week.positions = positions;
};

export const updateDescriptionAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ id: number; description: string }>,
) => {
  const { id, description } = action.payload;
  if (!id || !description) return;

  const week = state.value.weeks[id];
  if (!week) return;

  week.description = description;
};

export const updateWaitingForApiStatusAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ tempWeekId: number; waiting: boolean }>,
) => {
  const { tempWeekId, waiting } = action.payload;

  const week = state.value.weeks[tempWeekId];
  if (!week) return;

  week.waitingForApiResponse = waiting;
};

export const setDuplicatedWeekAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ tempId: number, week: WeekDto }>,
) => {
  const { tempId, week } = action.payload;
  const { normalizedWeek, components } = normalizeCurriculumWeek(week);

  const tempWeek = state.value.weeks[tempId];
  // delete temp components
  tempWeek.components.forEach((componentId) => {
    delete state.value.components[componentId];
  });
  // delete temp week
  delete state.value.weeks[tempId];
  // add new week
  state.value.weeks[normalizedWeek.id] = normalizedWeek;
  // add new components
  normalizedWeek.components.forEach((componentId) => {
    state.value.components[componentId] = components[componentId];
  });
  // update program.weeks with correct order
  const targetIndex = state.value.program.weeks.indexOf(tempId);
  if (targetIndex !== -1) {
    state.value.program.weeks.splice(targetIndex, 1, normalizedWeek.id);
  }
}
