import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  ComponentIcons,
  SpecialIconNames,
  ComponentTypeDefaultIcons,
} from "../program/icons";

import {
  ComponentForCalendarDto,
  ComponentTemplateDto,
  IComponentForCalendarDto,
} from "@growth-machine-llc/stridist-api-client";
import { CurriculumComponent } from "../../redux/types";

const useStyles = makeStyles((theme) => ({
  root: {},
  small: {
    width: 24,
    height: 24,

    "& .MuiIconButton-label": {
      width: "inherit",
    },

    "&$special": {
      width: 32,
      height: 32,
    },
  },
  special: {},
  icon: {
    color: theme.palette.secondary.main,

    "$small &": {
      width: 24,
      height: 24,
    },

    "$small$special &": {
      width: 32,
      height: 32,
    },

    '$small:not($special) & [stroke-width="2"]': {
      strokeWidth: 2.5,
    },
  },

  button: {
    padding: 0,
    borderRadius: 0,
  },
}));

export interface ComponentIconProps extends IconButtonProps {
  componentData:
    | IComponentForCalendarDto
    | CurriculumComponent
    | ComponentTemplateDto;
  variant?: "button" | "icon";
}

export function ComponentIcon(props: ComponentIconProps) {
  const s = useStyles();
  const {
    className,
    componentData,
    variant = "button",
    size,
    ...other
  } = props;
  const { iconName, type } = componentData;
  const Icon = ComponentIcons[iconName] || ComponentTypeDefaultIcons[type];
  const special = SpecialIconNames.includes(iconName);
  const classNames = clsx(s.root, className, {
    [s.small]: size === "small",
    [s.special]: special,
    [s.button]: variant === "button",
  });

  if (!Icon) {
    throw new Error(`Couldn't find "${iconName}" icon`);
  }

  const icon = <Icon className={s.icon} />;

  return variant === "button" ? (
    <IconButton className={classNames} disableRipple {...other} size="large">
      {icon}
    </IconButton>
  ) : (
    <Box className={classNames}>{icon}</Box>
  );
}
