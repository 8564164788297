import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga'
import curriculumSlice from "./curriculum/curriculum-slice";
import apiSlice from "./api/api-slice";
import undoable from "redux-undo";
import { watchRequests } from "./sagas";

const REDUX_HISTORY_LIMIT = 5;
const REDUX_HISTORY_IGNORED_ACTIONS = [];

const undoableCounter = undoable(curriculumSlice, {
  limit: REDUX_HISTORY_LIMIT,
  filter: (action) => !REDUX_HISTORY_IGNORED_ACTIONS.includes(action.type),
});

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    curriculum: undoableCounter,
    api: apiSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(watchRequests)

export type CurriculumRootState = ReturnType<typeof store.getState>;
export type CurriculumDispatch = typeof store.dispatch;
export type CurriculumStore = typeof store;
