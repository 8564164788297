import React, { useContext } from "react";
import { CoachClientsListScreen } from "../../../../components/coach-clients/CoachClientsListScreen";
import { CoachClientFiltersContext } from "../../../../contexts/CoachClientFiltersContext";
import { UserInviteStatus } from "@growth-machine-llc/stridist-api-client";

interface CoachClientsListRouteProps {
  status: string[];
}

export function CoachClientsListRoute({ status }: CoachClientsListRouteProps) {
  return (
    <>
      <CoachClientsListScreen status={status as UserInviteStatus[]} />
    </>
  );
}

export const CoachClientsActiveRoute = () => {
  return (
    <>
      <CoachClientsListRoute status={[UserInviteStatus.ACTIVE]} />
    </>
  );
};

export const CoachClientsPendingRoute = () => {
  return (
    <>
      <CoachClientsListRoute
        status={[UserInviteStatus.PENDING, UserInviteStatus.ADDED]}
      />
    </>
  );
};

export const CoachClientsArchivedRoute = () => {
  return (
    <>
      <CoachClientsListRoute status={[UserInviteStatus.ARCHIVED]} />
    </>
  );
};
