import {
  Card,
  CardProps,
  CardHeader,
  Grid,
  Box,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    "margin-right": theme.spacing(6),
  },
  content: {
    marginTop: theme.spacing(3.299),
  },
  action: {
    "& .MuiCardHeader-action": {
      margin: 0,
      alignSelf: "center",
    },
  },
  buttonSkeleton: {
    width: 150,
    height: 44,
    borderRadius: 10,
  },
}));

export interface BodyMeasurementSkeletonProps extends CardProps {}

export function BodyMeasurementSkeletonCard(
  props: BodyMeasurementSkeletonProps,
) {
  const s = useStyles();
  const { breakpoints } = useTheme();

  const isXs = useMediaQuery(breakpoints.only("xs"));

  return (
    <>
      {!isXs && (
        <CardContent
          sx={{ "&.MuiCardContent-root": { padding: "0 !important" } }}
        >
          <Box className={s.content}>
            {Array.from({ length: 8 }).map((_, i) => (
              <Skeleton key={i} animation="wave" height={20} sx={{ mb: 3 }} />
            ))}
          </Box>
        </CardContent>
      )}
    </>
  );
}
