import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Typography, Box, IconButton } from "@mui/material";

import { AppLayout } from "../app/AppLayout";
import { ReactComponent as ArrowForwardIcon } from "../../icons/ArrowForwardOutline.svg";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";

import { Link } from "../link/Link";
import { ProgramTemplatesList } from "../program/ProgramTemplatesList";

import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../../routes/routes";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import CoachProgramsService from "../../services/CoachProgramsService";
import { DefaultLoader } from "../loading/DefaultLoader";
import ProgramTemplatesService from "../../services/ProgramTemplatesService";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    margin: theme.spacing(5, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
  },

  button: {
    backgroundColor: "transparent",
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(-2),

    "& span": {
      fontSize: 18,
      fontWeight: 600,
      margin: theme.spacing(0, 0.5),
    },
  },

  backIcon: {
    "& svg": {
      transform: "rotate(180deg)",
      width: 24,
      height: 24,
    },
  },
}));

export const PROGRAM_TEMPLATES_QUERY_KEY = "program-templates";

export function ProgramTemplatesScreen() {
  const backUrl = COACH_PROGRAMS_PUBLISHED_ROUTE;
  const s = useStyles();
  const location = useLocation();

  const { data: programTemplates, isLoading } = useQuery({
    queryKey: [PROGRAM_TEMPLATES_QUERY_KEY],
    queryFn: () => ProgramTemplatesService.getProgramTemplates(),
  });

  return (
    <AppLayout
      appBar={false}
      appDrawer={false}
      trackInfo={{
        name: "Coach - Program Templates",
      }}
      hideUpgradeBanner={location.pathname.endsWith("/new")}
    >
      <Container className={s.root}>
        <Box className={s.header}>
          <Box>
            <Link href={backUrl} underline="none">
              <IconButton className={clsx(s.button, s.backIcon)} size="large">
                <ArrowForwardIcon />
                <Typography component="span">Back</Typography>
              </IconButton>
            </Link>
            <Typography variant="h2" className={s.title} gutterBottom>
              Choose a program template
            </Typography>
            <Typography variant="body1" className={s.text}>
              {programTemplates?.totalCount ?? 0} templates available
            </Typography>
          </Box>

          <Link href={backUrl} underline="none">
            <IconButton className={s.button} size="large">
              <CloseIcon />
            </IconButton>
          </Link>
        </Box>

        {isLoading ? (
          <Box width="100%" height={"80vh"}>
            <DefaultLoader fillParent />
          </Box>
        ) : (
          <ProgramTemplatesList templates={programTemplates?.programTemplates} />
        )}
      </Container>
    </AppLayout>
  );
}
