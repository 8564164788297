import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import GroupsService from "../../../services/GroupsService";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useParams } from "react-router-dom";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";
import { useLayoutGroup } from "../../../contexts/GroupContext";
import { Box } from "@mui/material";
import { CircularLoader } from "../../../components/loading/CircularLoader";
import { DefaultLoader } from "../../../components/loading/DefaultLoader";
import { extractSlugId } from "../../../utils/slug";

interface GroupContextProps {
  group: GroupDto2;
  isLoading: boolean;
}

const GroupContext = createContext<GroupContextProps | undefined>(undefined);

const useGroupRoute = () => {
  const context = useContext(GroupContext);
  if (context === undefined) {
    throw new Error("useGroup must be used within a GroupProvider");
  }
  return context;
};

export const COACH_GROUP_WRAPPER_QUERY_KEY = "coach-group-wrapper";
const CoachGroupRouteWrapper = () => {
  const { slug } = useParams();
  const slugId = extractSlugId(slug);
  const { setGroupBriefInfo, resetGroupBriefInfo } = useLayoutGroup();

  const { data: group, isLoading } = useQuery({
    queryKey: [COACH_GROUP_WRAPPER_QUERY_KEY, { slugId }],
    queryFn: () => GroupsService.getGroup(slug, 10),
  });

  useEffect(() => {
    resetGroupBriefInfo();
  }, [slugId]);

  useEffect(() => {
    group &&
      setGroupBriefInfo({
        id: group.id,
        name: group.name,
        totalMembersCount: group.totalMembersCount,
        membersCanNotPost: group.membersCanNotPost,
        coachId: group.coachId,
      });
  }, [group]);

  return (
    <GroupContext.Provider value={{ group, isLoading }}>
      {isLoading ? (
        <Box height={"100vh"}>
          <DefaultLoader fillParent />
        </Box>
      ) : (
        <Outlet />
      )}
    </GroupContext.Provider>
  );
};

export { CoachGroupRouteWrapper, useGroupRoute };
