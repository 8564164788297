import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAuth from "../../../hooks/auth/useAuth";
import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import { processQueryVariables } from "../../../utils/reactQuery";
import { IsMobileAppModalShown, UserRole } from "../../../constants";
import { useNavigate } from "react-router-dom";
import {
  HOME_ROUTE,
  ADMIN_USERS_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
} from "../../../routes/routes";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";

export const useSignInMutation = (props?: UseReactQueryPropsBase) => {
  localStorage.removeItem(IsMobileAppModalShown);
  const { login } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["signIn"],
    mutationFn: login,
    retry: 0,
    ...props,
    onSuccess: async (user) => {
      // Important: Invalidate all queries to ensure the new user's data is fetched
      queryClient.clear();

      props?.onSuccess && props.onSuccess(user);
    },
  });
};

interface SignInAsMutationProps extends UseReactQueryPropsBase {
  redirect?: boolean;
  wasImpersonating?: boolean;
}
export const useSignInAsMutation = (props?: SignInAsMutationProps) => {
  const { loginAs } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { redirect = true, wasImpersonating } = props || {};

  return useMutation({
    mutationKey: ["signInAs"],
    mutationFn: loginAs,
    retry: 0,
    ...props,
    onSuccess: async (user) => {
      if (redirect) {
        const { userRole, admin } = user;
        navigate(
          userRole === UserRole.CLIENT
            ? HOME_ROUTE
            : admin && wasImpersonating
              ? ADMIN_USERS_ROUTE
              : COACH_PROGRAMS_PUBLISHED_ROUTE,
        );
      }

      // Important: Clear all queries to ensure the new user's data is fetched
      queryClient.clear();

      props?.onSuccess && props.onSuccess(user);
    },
  });
};
