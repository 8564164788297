import {
  GroupPostCommentDto,
  UserBriefInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";

export const createNewGroupPostComment = (
  tempId: number,
  author: UserBriefInfoDto,
  content?: string,
) => {
  return new GroupPostCommentDto({
    id: tempId,
    likedByMe: false,
    likes: [],
    replies: [],
    created: dayjs(),
    content: content,
    formattedDate: dayjs().from(dayjs()),
    author: author,
  });
};
