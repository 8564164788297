import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField } from "@mui/material";

import { EditorDialog, EditorDialogProps } from "../dialog/EditorDialog";
import { useBackupState } from "../../hooks/useLocalStorage";
import { MessageEditor } from "../new-editor/MessageEditor";

import { useUpsertGroupPostMutation } from "./mutations/UpsertGroupPost";
import { GroupPostEditor } from "./GroupPostEditor";
import { useEditorRef } from "@udecode/plate-common";
import { GroupPostDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  editor: {
    marginTop: theme.spacing(3),
    marginLeft: 2,
    marginRight: 2,
    height: `calc(100% - ${theme.spacing(11)})`,
  },

  input: {
    "& .MuiInputBase-multiline": {
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    },

    "& .fixed-toolbar-buttons": {
      marginLeft: theme.spacing(-1),
    },
  },
}));

export interface GroupPostsEditDialogProps extends EditorDialogProps {
  groupId: number;
  post?: GroupPostDto;
}

export function GroupPostsEditDialog(props: GroupPostsEditDialogProps) {
  const { className, groupId, post, ...other } = props;
  const { onClose } = props;
  const s = useStyles();

  const [title, setTitle, removeTitleBackup] = useBackupState(
    "group-post-title",
    post?.id,
    post?.title,
  );

  const buttonRef = React.useRef<HTMLButtonElement>();
  const {
    createGroupPost: { mutate: createGroupPost, isPending: creatingGroupPost },
    updateGroupPost: { mutate: updateGroupPosts, isPending: updatingGroupPost },
  } = useUpsertGroupPostMutation({ groupId });

  const postId = post?.id;

  const handleUpsertGroupPost = React.useCallback(
    (fragment?: any[], callback?: () => void) => {
      const input = {
        title,
        content: JSON.stringify(fragment),
      };

      const options = {
        onSuccess: () => {
          removeTitleBackup();
          callback();
          if (onClose) {
            onClose({}, "backdropClick");
          }
        },
      };
      if (postId) {
        updateGroupPosts({ id: postId, ...input }, options);
      } else {
        createGroupPost({ groupId, ...input }, options);
      }
    },
    [
      createGroupPost,
      postId,
      updateGroupPosts,
      title,
      groupId,
      removeTitleBackup,
      onClose,
    ],
  );

  const handleTitleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setTitle(event.target.value);
    },
    [setTitle],
  );

  const editor = useEditorRef();

  const pending = creatingGroupPost || updatingGroupPost;

  return (
    <EditorDialog
      className={clsx(s.root, className)}
      title={post ? "Edit post" : "New Post"}
      submitPending={pending}
      submitDisabled={pending}
      submitLabel={post ? "Update post" : "Submit post"}
      buttonRef={buttonRef}
      {...other}
      classes={{
        wrapper: "h-full",
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Title (Optional)"
        value={title}
        onChange={handleTitleChange}
        fullWidth
      />

      <MessageEditor
        className={s.editor}
        backupKeyType="group-post-message"
        backupKeyId={post?.id}
        message={post?.content && JSON.parse(post?.content)}
        placeholder="Share your thoughts, or ask a question."
        buttonRef={buttonRef}
        onMessageSubmit={handleUpsertGroupPost}
        InputProps={{ inputComponent: GroupPostEditor }}
        inputProps={{ editor }}
        hideSendButton
        rowsMax={null}
        classes={{
          input: clsx(s.input, "!overflow-y-auto h-full"),
        }}
        rootMinHeight={160}
      />
    </EditorDialog>
  );
}
