import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import UserNotesService from "../../../services/UserNotesService";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { UserNoteContentDto } from "@growth-machine-llc/stridist-api-client";
import { CLIENT_NOTES_QUERY_KEY } from "../../screen/CoachClientNotesScreen";
import { useClient } from "../../../hooks/useClient";
import dayjs from "dayjs";
import { useGenericErrorHandler } from "../../../hooks/useGenericErrorHandler";

const useCreateNoteMutation = ({ onError }: { onError?: () => void }) => {
  const client = useClient();
  const handleError = useGenericErrorHandler({
    disableDefaultSnackbar: true,
  });

  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_NOTES_QUERY_KEY, { client: client?.id }],
    mutationFn: UserNotesService.createUserNote,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: any, newNote, tempId) =>
        INFINITE_PAGINATED_DATA_UPDATERS.addItem(
          oldData,
          UserNoteContentDto.fromJS({
            ...newNote,
            id: tempId,
            created: dayjs(),
          }),
        ),
    },
    successUpdater: {
      updateFn: (oldData, id, tempId) =>
        INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(oldData, tempId, {
          id,
        }),
    },
    options: {
      onError: (error, variables, context: any) => {
        onError?.();
        handleError(error, variables, context, context?.toastId);
      },
    },
  });
};

export default useCreateNoteMutation;
