import React from "react";

import { ClientSmallProgramCard } from "./ClientSmallProgramCard";
import { ClientCardCompletion } from "./ClientCardCompletion";
import {
  ClientEnrollmentDto,
  ClientStatsDto,
} from "@growth-machine-llc/stridist-api-client";

export interface ClientActiveProgramCardProps {
  enrollment?: ClientEnrollmentDto;
  stats?: ClientStatsDto;
}

export function ClientActiveProgramCard(props: ClientActiveProgramCardProps) {
  const { enrollment, stats, ...other } = props;
  const { currentDay, totalDays } = enrollment;

  return (
    <ClientSmallProgramCard
      enrollment={enrollment}
      subtitle={`Day ${currentDay} of ${totalDays}`}
      content={<ClientCardCompletion enrollment={enrollment} stats={stats} />}
      {...other}
    />
  );
}
