import { messagesClient } from "../api/ApiClients";
import {
  CreateManyMessagesCommand,
  ICreateManyMessagesCommand,
  CreateMessageCommand,
  ICreateMessageCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class MessagesService extends ServiceBase {
  async CreateManyMessages(data: ICreateManyMessagesCommand): Promise<void> {
    const command = CreateManyMessagesCommand.fromJS(data);
    return messagesClient.createManyMessages(command);
  }

  async getRecipients(pageNumber: number, pageSize: number, search?: string) {
    return messagesClient.getMessageRecipients(pageNumber, pageSize, search);
  }

  async createMessage(variables: ICreateMessageCommand) {
    const command = CreateMessageCommand.fromJS(variables);
    return messagesClient.createMessage(command);
  }
}

export default new MessagesService();
