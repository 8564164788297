import React, { useEffect } from "react";
import { CoachSettingsPlan } from "../../../../components/settings/CoachSettingsPlan";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useQueryClient } from "@tanstack/react-query";
import { CURRENT_USER_QUERY_KEY } from "../../../../wrappers/current-user/CurrentUserWrapper";

export function CoachSettingsPlanRoute() {
  const user = useCurrentUser();

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [CURRENT_USER_QUERY_KEY],
      refetchType: "all",
    });
  }, []);

  return (
    <>
      {user && <CoachSettingsPlan user={user} />}
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Plan",
        }}
      />
    </>
  );
}
