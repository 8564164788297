import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, List } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GroupSidebarMemberItem } from "./GroupSidebarMemberItem";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  label: {
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}));

export interface GroupSidebarMembersProps extends BoxProps {
  members: GroupDto2["members"];
  totalMembersCount: GroupDto2["totalMembersCount"];
}

export function GroupSidebarMembers(props: GroupSidebarMembersProps) {
  const { className, members, totalMembersCount, ...other } = props;
  const s = useStyles();

  return (
    totalMembersCount > 0 && (
      <Box className={clsx(s.root, className)} {...other}>
        <Typography variant="h4" className={s.label}>
          Members
        </Typography>

        <List>
          {members.map((member) => (
            <GroupSidebarMemberItem key={member.id} member={member} />
          ))}
        </List>
      </Box>
    )
  );
}
