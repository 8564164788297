import CoachProgramsService from "../../../services/CoachProgramsService";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../CoachProgramsListScreen";
import {
  IPaginatedListOfProgramDto,
  ProgramDto,
} from "@growth-machine-llc/stridist-api-client";
import { ProgramStatus } from "../../../constants";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { InfiniteData } from "@tanstack/react-query";

export const useArchiveProgramMutation = () => {
  const filterOutProgram = INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem;

  return useOptimisticUpdateMutation({
    mutationFn: CoachProgramsService.archiveProgram,
    queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
    optimisticUpdater: {
      updateFn: (oldData: InfiniteData<IPaginatedListOfProgramDto>, params) =>
        filterOutProgram<ProgramDto>(oldData, params.programId),
      predicateFn: (queryKey) =>
        // Filter out program from all tabs except the `ARCHIVED` tab
        !predicateQueryKeyByParams(queryKey, {
          status: ProgramStatus.ARCHIVED,
        }),
    },
    invalidateQueryOptions: {
      enabled: true,
      predicateFn: (queryKey) =>
        predicateQueryKeyByParams(queryKey, {
          status: ProgramStatus.ARCHIVED,
        }),
    },
  });
};
