import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const updateComponentDaysAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    days: string;
  }>,
) => {
  const { componentId, days } = action.payload;
  const component = state.value.components[componentId];
  if (!component || !days) return;

  state.value.components[componentId] = {
    ...component,
    days,
  };
};
