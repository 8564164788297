import clsx from "clsx";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  useProgramSchedule,
  ScheduleUpdate,
} from "../../hooks/useProgramSchedule";
import { ProgramCalendarWeekProps } from "../program-calendar/ProgramCalendarWeek";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ProgramSpreadsheetWeek } from "./ProgramSpreadsheetWeek";
import {
  PADDING_LEFT_DESKTOP,
  PADDING_LEFT_MOBILE,
  PADDING_RIGHT_DESKTOP,
  PADDING_RIGHT_MOBILE,
} from "../app/AppBar";
import { polyfillCSS } from "../../utils/css";
import { SpreadsheetInfoDrawerContext } from "../../hooks/useSpreadsheetInfoDrawer";
import { SpreadsheetInfoDrawer } from "./instructions/SpreadsheetInfoDrawer";
import { ColumnField } from "./utils";
import { NormalizedCurriculumProgram } from "../../redux/types";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectCurriculum } from "../../redux/curriculum/selectors/curriculum";
import { denormalizeCurriculum } from "../../redux/normalize";
import ComponentDialog from "../program/ComponentDialog";
import { useDeepCompareMemoize } from "../../utils/memo";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: polyfillCSS(`calc(${PADDING_LEFT_MOBILE(theme)} * -1)`),
    marginRight: polyfillCSS(`calc(${PADDING_RIGHT_MOBILE(theme)}  * -1)`),

    [theme.breakpoints.up("sm")]: {
      marginLeft: polyfillCSS(`calc(${PADDING_LEFT_DESKTOP(theme)} * -1)`),
      marginRight: polyfillCSS(`calc(${PADDING_RIGHT_DESKTOP(theme)} * -1)`),
    },
  },
  weeksWrapper: {
    overflowX: "auto",
  },
  weeks: {
    flexDirection: "column",
    width: "100%",

    paddingLeft: theme.spacing(4),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(6),
    },

    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gridAutoRows: "auto",
    columnGap: 60,
  },
  week: {
    margin: theme.spacing(3, 0, 4, 0),
  },
}));

export interface ProgramSpreadsheetProps extends BoxProps {
  filters?: ProgramCalendarWeekProps["filters"];
  startDate?: string;
  onOpenMenu?: ProgramCalendarWeekProps["onOpenMenu"];
  program: NormalizedCurriculumProgram;
  weeks?: number[];
  programId?: number;
  activityPreview: boolean;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  duplicateWeekInFlight?: boolean;
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickMove: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
}

export function ProgramSpreadsheet(props: ProgramSpreadsheetProps) {
  const {
    activityPreview,
    setActivityPreview,
    className,
    program,
    filters,
    startDate,
    onOpenMenu,
    weeks,
    duplicateWeekInFlight,
    weeksActions,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const programSlug = params.slug;
  const componentSlugSearchParams = searchParams.get("component");

  const s = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [scheduleUpdate, setScheduleUpdate] = React.useState<ScheduleUpdate>();

  const scheduleAssets = denormalizeCurriculum(
    useCurriculumSelector(selectCurriculum).value,
  );

  const [components, schedule] = useProgramSchedule(
    scheduleAssets,
    filters,
    scheduleUpdate,
  );

  const [activityPreviewItem, setActivityPreviewItem] = useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);

  const [infoDrawerColumnField, setInfoDrawerColumnField] =
    useState<ColumnField>();

  // TODO refactor and extract into a single place from spreadsheet and calendar
  const handleClickOpenDialog = (e?: any, slug?: string) => {
    slug && setActivityPreviewItem(slug);
    navigate(
      `?component=${slug ?? componentSlugSearchParams ?? activityPreviewItem}`,
      { replace: true },
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    // update search params
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("component");
    setSearchParams(updatedParams);
    setOpenDialog(false);
  };

  useEffect(() => {
    if (componentSlugSearchParams && programSlug) {
      setActivityPreview(true);
      setActivityPreviewItem(componentSlugSearchParams);
      handleClickOpenDialog();
    }
  }, [componentSlugSearchParams]);

  useEffect(() => {
    isMd && setActivityPreview(false);
  }, [isMd]);

  const memoWeeks = useDeepCompareMemoize(weeks);

  const spreadsheetWeek = React.useMemo(
    () => (
      <Box className={clsx(s.weeks, className)}>
        {weeks.map((week, i) => (
          <ProgramSpreadsheetWeek
            className={s.week}
            key={week}
            weekId={week}
            schedule={schedule}
            filters={filters}
            programId={props.programId}
            startDate={startDate}
            onOpenMenu={onOpenMenu}
            setActivityPreview={setActivityPreview}
            activityPreviewItem={activityPreview && activityPreviewItem}
            setActivityPreviewItem={setActivityPreviewItem}
            handleClickOpenDialog={handleClickOpenDialog}
            duplicateWeekInFlight={duplicateWeekInFlight}
            weeksActions={weeksActions}
            // for every grid item set zIndex starting from last from 1 to *n
            zIndex={(weeks.length - i) * 7}
          />
        ))}
      </Box>
    ),
    [schedule, filters, startDate, duplicateWeekInFlight, memoWeeks],
  );

  return (
    <SpreadsheetInfoDrawerContext.Provider
      value={[infoDrawerColumnField, setInfoDrawerColumnField]}
    >
      <Box className={clsx(s.root, className)}>
        <Box className={s.weeksWrapper}>{spreadsheetWeek}</Box>
        <ComponentDialog
          componentSlug={activityPreviewItem}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          handleClickOpenDialog={handleClickOpenDialog}
        />
        <SpreadsheetInfoDrawer />
      </Box>
    </SpreadsheetInfoDrawerContext.Provider>
  );
}
