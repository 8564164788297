import { activitiesClient, activityFeedbacksClient } from "../api/ApiClients";
import {
  ActivitySortType,
  ActivityType,
  CompletionPeriod,
  CreateActivityFeedbackCommand,
  ICreateActivityFeedbackCommand,
  IPaginatedListOfThreadsDto,
  IToggleActivityArchivedCommand,
  IUpdateThreadCommand,
  IUpsertActivityCommand,
  MarkAnswersReadCommand,
  ToggleActivityArchivedCommand,
  UpdateThreadCommand,
  UpsertActivityCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";
import { ActivitySort } from "../constants";

class ActivitiesService extends ServiceBase {
  async getActivities(
    afterCursor: string | null,
    pageSize: number,
    types?: ActivityType[],
    archived?: boolean,
    orderBy?: ActivitySort,
    clientIds: number[] = null,
    groupIds: number[] = null,
  ) {
    return activitiesClient.getActivitiesWithPagination(
      pageSize,
      afterCursor,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      types,
      archived,
      orderBy as unknown as ActivitySortType,
      clientIds,
      groupIds,
    );
  }
  async getActivitiesDateRange(
    startDate: string,
    endDate: string,
  ): Promise<string[]> {
    return activitiesClient
      .getActivitiesDateRange(startDate, endDate)
      .then((response) => {
        return response.map((date) => date.format("YYYY-MM-DD"));
      });
  }
  async getAllComponents(date: string) {
    const pageSize = 999;
    const pageNumber = 1;
    return activitiesClient.getComponentActivities(
      pageNumber,
      pageSize,
      null,
      null,
      date,
      null,
      null,
      false,
      null,
      null,
    );
  }

  async getHabitsCheckIns(
    clientId: number,
    slug: string,
    period: CompletionPeriod,
  ) {
    return activitiesClient.getHabitsCheckIns(clientId, slug, period);
  }

  async coachReadActivity(id: number) {
    const command = new MarkAnswersReadCommand({ id });
    return activitiesClient.markAnswersRead(command);
  }

  async coachToggleArchiveActivity(variables: IToggleActivityArchivedCommand) {
    const command = new ToggleActivityArchivedCommand(variables);
    return activitiesClient.toggleArchived(command);
  }

  async createActivityFeedback(variables: ICreateActivityFeedbackCommand) {
    const command = new CreateActivityFeedbackCommand(variables);
    return activityFeedbacksClient.createActivityFeedback(command);
  }
  async getActivityWithComponents(
    client: string,
    date: string,
    component: string,
  ) {
    return activitiesClient.getActivity(client, date, component);
  }

  async getActivitySummary(client: string, componentSlug: string) {
    return activitiesClient.getSummary(client, componentSlug);
  }

  async upsertActivity(variables: IUpsertActivityCommand) {
    const command = new UpsertActivityCommand(variables);
    return activitiesClient.upsert(command);
  }
}

export default new ActivitiesService();
