export const duplicateOperation = (
  workoutSectionId: number,
  inputContent: string,
  newExerciseId: number,
) => {
  const parsedContent = JSON.parse(inputContent);

  const targetIndex = parsedContent.findIndex(
    (element) => element.id === workoutSectionId,
  );
  if (targetIndex === -1) return JSON.stringify(parsedContent);

  const duplicatedWorkoutSection = {
    ...parsedContent[targetIndex],
    id: newExerciseId,
  };
  return JSON.stringify([
    ...parsedContent.slice(0, targetIndex + 1),
    duplicatedWorkoutSection,
    ...parsedContent.slice(targetIndex + 1),
  ]);
};
