import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { SortableList } from "../sortable-list/SortableList";

import { ClientNutritionTargetDrawerListItem } from "./ClientNutritionTargetDrawerListItem";
import { NutritionTargetDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ClientGoalsListProps {
  nutritionTargets: NutritionTargetDto[];
  onItemActionClick: any;
  onUpdate: (nutritionTargets: NutritionTargetDto[]) => void;
  disabled?: boolean;
}

export function ClientNutritionTargetDrawerList(props: ClientGoalsListProps) {
  const {
    nutritionTargets,
    onItemActionClick,
    onUpdate,
    disabled = false,
    ...other
  } = props;
  const s = useStyles();

  const handleUpdate = (updatedTargets: NutritionTargetDto[]) => {
    if (onUpdate) {
      onUpdate(updatedTargets);
    }
  };

  return (
    <SortableList
      className={s.root}
      itemType="client_nutrition_target"
      ListItem={ClientNutritionTargetDrawerListItem as any}
      ListItemProps={{ onAction: onItemActionClick, disabled: disabled }}
      options={nutritionTargets as any}
      onUpdate={handleUpdate}
      {...other}
    ></SortableList>
  );
}
