import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Box, BoxProps, TextField, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";

import { useClient } from "../../hooks/useClient";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ProgressLine } from "../progress/ProgressLine";
import AddCircle from "../../icons/AddCircle";
import dayjs from "dayjs";
import { ClientGoalDto } from "@growth-machine-llc/stridist-api-client";
import useUpdateCurrentGoalValueMutation from "./mutations/useUpdateCurrentGoalValueMutation";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  name: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },
  date: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  button: {
    top: -12,
  },
  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  input: {
    "& input": {
      fontWeight: 500,
    },
  },
  inputWrapper: {
    width: "100%",
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.25s ease-in",
  },
  inputWrapperVisible: {
    maxHeight: 80,
    transition: "max-height 0.15s ease-out",
  },
  progress: {
    width: "100%",
    maxWidth: "100%",
    marginTop: theme.spacing(2),
  },
}));

export interface ClientGoalProps extends BoxProps {
  goal: ClientGoalDto;
  clientId: number;
}

export function ClientGoal(props: ClientGoalProps) {
  const { className, goal, clientId, ...other } = props;
  const s = useStyles();
  const client = useClient();
  const user = useCurrentUser();
  const { mutate: updateCurrentValue, isPending: updatingCurrentValue } =
    useUpdateCurrentGoalValueMutation(clientId);
  const [dif, setDif] = React.useState("");
  const [editGoal, setEditGoal] = useState(false);
  const [currentValue, setCurrentValue] = React.useState(goal.currentValue);
  const formattedDate = goal?.date ? goal.date.format("MMM D, YYYY") : null;
  const daysDif = React.useCallback(() => {
    const now = dayjs();
    const end = formattedDate;
    const duration = dayjs.duration(now.diff(end));
    const days = Math.floor(duration.asDays());

    if (days <= 0) {
      setDif(`${Math.abs(days).toString()} days left`);
    } else {
      setDif(`late  ${days.toString()} days`);
    }
  }, [formattedDate]);

  useEffect(() => {
    if (formattedDate) {
      daysDif();
    }
  }, [formattedDate, daysDif]);

  const save = React.useCallback(() => {
    const dirty = Boolean(currentValue && goal.currentValue !== currentValue);

    if (dirty) {
      updateCurrentValue({
        goalId: goal?.id,
        currentValue: Number(currentValue),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id, user.id, currentValue, updateCurrentValue]);

  const handleCurrentValueChange = React.useCallback(
    (event) => {
      const pattern = /^[0-9]+$/;
      const inputValue = event.target.value;

      if (
        (pattern.test(inputValue) || inputValue === "") &&
        inputValue !== "0"
      ) {
        if (+event.target.value > +goal.targetValue) {
          setCurrentValue(goal.targetValue);
        } else {
          setCurrentValue(event.target.value);
        }
      }
    },
    [goal.targetValue],
  );

  const handleCurrentValueBlur = React.useCallback(() => {
    save();
  }, [save]);

  const calculateProgress = React.useMemo(() => {
    const value = (100 / +goal.targetValue) * +currentValue;
    return value > 100 ? 100 : value;
  }, [goal.targetValue, currentValue]);
  const theme = useTheme();
  return (
    <OptimisticUpdateContainer className={clsx(s.root, className)} id={goal.id}>
      <div className={s.container}>
        <Typography className={s.name} children={goal.name} />
        <Typography className={s.date} children={dif} />
      </div>
      <IconButton
        className={s.button}
        aria-label="Update current value"
        onClick={() => setEditGoal(!editGoal)}
        size="large"
      >
        <AddCircle fill={theme.palette.primary.main} />
      </IconButton>
      <Box className={clsx(s.inputWrapper, editGoal && s.inputWrapperVisible)}>
        <Typography variant="h4" className={s.label}>
          Set new current value
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={currentValue === null ? "" : currentValue}
          onChange={handleCurrentValueChange}
          onBlur={handleCurrentValueBlur}
          className={s.input}
          error={!currentValue && currentValue !== null}
          disabled={updatingCurrentValue}
        />
      </Box>
      {/^[0-9]+$/.test(goal.targetValue) && (
        <ProgressLine value={calculateProgress} className={s.progress} />
      )}
    </OptimisticUpdateContainer>
  );
}
