import { useMutation } from "@tanstack/react-query";
import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import SignUpService from "../../../services/SignUpService";

export const usePreCoachRegistrationMutation = (
  props?: UseReactQueryPropsBase,
) => {
  return useMutation({
    mutationKey: ["pre-coach-sign-up"],
    mutationFn: SignUpService.preCoachSignUp,
    retry: 0,
    ...props,
  });
};
