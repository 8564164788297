import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LikesList } from "./LikesList";
import { LikesButton } from "./LikesButton";
import { LikeDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",

    "& > :last-child": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export interface LikeableProps extends BoxProps {
  likes: LikeDto[];
  totalLikesCount: number;
  likedByMe: boolean;
  onLikeToggle: (liked: boolean) => void;
  disabled?: boolean;
}

export function Likeable(props: LikeableProps) {
  const {
    className,
    likes,
    totalLikesCount,
    likedByMe,
    onLikeToggle,
    disabled,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <LikesList likes={likes} totalLikesCount={totalLikesCount} />
      <LikesButton
        likedByMe={likedByMe}
        onLikeToggle={onLikeToggle}
        disabled={disabled}
      />
    </Box>
  );
}
