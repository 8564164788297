import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { ComponentStatus } from "@growth-machine-llc/stridist-api-client";

export const archiveComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    weekId: number;
    componentId: number;
    componentStatusData: {
      status: ComponentStatus;
      previousStatus: ComponentStatus;
    };
  }>,
) => {
  const { componentId, componentStatusData } = action.payload;
  if (!componentId || !componentStatusData) return;

  const { status, previousStatus } = componentStatusData;
  if (!status) return;

  const newStatus =
    status === ComponentStatus.ARCHIVED
      ? previousStatus && previousStatus !== ComponentStatus.DRAFT
        ? previousStatus
        : ComponentStatus.DRAFT
      : ComponentStatus.ARCHIVED;

  state.value.components[componentId] = {
    ...state.value.components[componentId],
    status: newStatus,
  };
};
