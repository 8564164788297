import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  MenuItem,
  ListItemText,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useDropzone } from "react-dropzone";

import { Menu } from "../menu/Menu";
import { UnsplashDialog } from "../unsplash/UnsplashDialog";
import { ActionButton } from "../button/ActionButton";
import { useUploadFile } from "../../hooks/useUploadFile";
import { AssetType } from "../../constants";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";

const useStyles = makeStyles((theme) => ({
  root: {},

  description: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  label: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  container: {
    display: "flex",
    alignItems: "center",
  },

  image: {
    width: theme.spacing(22.25),
    height: theme.spacing(11.25),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(3),
  },

  button: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
  },

  deleteLinkButton: {
    color: theme.palette.text.secondary,
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginLeft: "auto",
  },
}));

export interface SettingsCoverImageProps extends BoxProps {
  assetType: AssetType;
  image?: string;
  refType: string;
  nodeId: number;
  onImageChange?: (image: string) => void;
  disabled?: boolean;
  removable?: boolean;
}

export function SettingsCoverImage(props: SettingsCoverImageProps) {
  const {
    className,
    assetType,
    image,
    nodeId,
    refType,
    onImageChange,
    children,
    disabled = false,
    removable = true,
    ...other
  } = props;
  const s = useStyles();

  const unsplashDialogState = usePopupState({
    variant: "popover",
    popupId: "select-unsplash-image",
  });

  const menuState = usePopupState({
    variant: "popover",
    popupId: "client-form-cover-menu",
  });

  const [upload, , uploadInFlight] = useUploadFile({
    id: nodeId,
    refType,
    getAssetType: () => assetType,
    onUpload: ({ url }) => onImageChange(url),
  });

  const onUpload = React.useCallback(
    (files: File[]) => upload(files[0]),
    [upload],
  );

  const handleMenuClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      menuState.open(event);
    },
    [menuState],
  );

  const handleRemove = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      menuState.close();
      onImageChange(null);
    },
    [menuState, onImageChange],
  );

  const handleSelectUnsplashImage = React.useCallback(
    (url: string) => {
      menuState.close();
      onImageChange(url);
    },
    [menuState, onImageChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onUpload,
    accept: getMimeTypes(ElementType.IMAGE),
  });

  const { onClick: onUploadClick } = getRootProps();

  const handleUploadClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      menuState.close();
      onUploadClick(event);
    },
    [menuState, onUploadClick],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography variant="h4" className={s.label}>
        Cover image
      </Typography>

      {children && (
        <Typography className={clsx(s.description)}>{children}</Typography>
      )}

      <Box className={s.container}>
        <input
          {...getInputProps()}
          accept="image/*"
          id="image-file"
          type="file"
        />

        {image && <CardMedia className={s.image} image={image} />}

        <ActionButton
          className={s.button}
          onClick={handleMenuClick}
          disabled={uploadInFlight || disabled}
        >
          Upload new cover
        </ActionButton>

        {image && removable && (
          <IconButton
            className={s.deleteLinkButton}
            children={<BinIcon />}
            onClick={handleRemove}
            size="large"
          />
        )}

        {menuState.isOpen && (
          <Menu {...bindMenu(menuState)}>
            <MenuItem onClick={handleUploadClick}>
              <ListItemText primary="From computer" />
            </MenuItem>

            <MenuItem {...bindTrigger(unsplashDialogState)}>
              <ListItemText primary="Unsplash" />
            </MenuItem>

            {image && removable && (
              <MenuItem onClick={handleRemove}>
                <ListItemText primary="Delete image" />
              </MenuItem>
            )}
          </Menu>
        )}

        {unsplashDialogState.isOpen && (
          <UnsplashDialog
            open
            onClose={unsplashDialogState.close}
            onSelect={handleSelectUnsplashImage}
          />
        )}
      </Box>
    </Box>
  );
}
