import React from "react";

import {
  NavigationFilterList,
  NavigationFilterListProps,
} from "../filters/NavigationFilterList";
import { useQueryParam } from "../../hooks/useQueryParam";
import {
  EnrollmentSort,
  EnrollmentsView,
  enrollmentSortLabels,
} from "../../constants";

export interface EnrollmentsFiltersProps {}

export const EnrollmentsFilters = (props: EnrollmentsFiltersProps) => {
  const [view] = useQueryParam("view", EnrollmentsView.CLIENTS);

  const filters: NavigationFilterListProps["filters"] = React.useMemo(
    () => [
      {
        name: "sort",
        defaultValue: EnrollmentSort.CREATED_AT_DESC,
        options: [
          {
            name: EnrollmentSort.CREATED_AT_DESC,
            label: enrollmentSortLabels[EnrollmentSort.CREATED_AT_DESC],
          },
          {
            name: EnrollmentSort.NAME_ASC,
            label: enrollmentSortLabels[EnrollmentSort.NAME_ASC],
          },

          ...(view === EnrollmentsView.CLIENTS
            ? [
                {
                  name: EnrollmentSort.EMAIL_ASC,
                  label: enrollmentSortLabels[EnrollmentSort.EMAIL_ASC],
                },

                {
                  name: EnrollmentSort.SCORE_ASC,
                  label: enrollmentSortLabels[EnrollmentSort.SCORE_ASC],
                },
              ]
            : []),
        ],
      },
    ],
    [view],
  );

  return <NavigationFilterList filters={filters} />;
};
