import {
  ProgramInviteCodeDto,
  ProgramSettingsDto,
} from "@growth-machine-llc/stridist-api-client";
import { PROGRAM_SETTINGS_QUERY_KEY } from "../../../../routes/coach/program/settings/CoachProgramSettingsRoute";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import InviteCodesService from "../../../../services/InviteCodesService";

interface CreateInviteCodeMutationProps {
  slugId: string;
}

export const useCreateInviteCodeMutation = ({
  slugId,
}: CreateInviteCodeMutationProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: InviteCodesService.createCode,
    onSuccess: (data) => {
      queryClient.setQueriesData(
        {
          queryKey: [PROGRAM_SETTINGS_QUERY_KEY, { slugId }],
        },
        (oldData: ProgramSettingsDto) => {
          return new ProgramSettingsDto({
            ...oldData,
            inviteCode: new ProgramInviteCodeDto(data),
          });
        },
      );
    },
  });
};
