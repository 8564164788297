import React, { createContext, useState } from "react";
import { ProgramFolderDto } from "@growth-machine-llc/stridist-api-client";

interface CoachProgramsListScreenContextType {
  programFolders: ProgramFolderDto[];
  programFoldersLoading: boolean;
}

export const CoachProgramsListScreenContext = createContext<
  CoachProgramsListScreenContextType | undefined
>(undefined);

export const useCoachProgramsListScreenContext = () => {
  const context = React.useContext(CoachProgramsListScreenContext);
  if (context === undefined) {
    throw new Error(
      "useCoachProgramsListScreenContext must be used within a CoachProgramsListScreenProvider",
    );
  }
  return context;
};
