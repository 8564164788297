import React from "react";
import { ClientPortalSettings } from "../../../../components/client-portal-settings/ClientPortalSettings";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useQuery } from "@tanstack/react-query";
import WorkspacesService from "../../../../services/WorkspacesService";

export const COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY =
  "coach-settings-client-portal";

export function CoachSettingsClientPortalRoute() {
  const { data: workspace } = useQuery({
    queryKey: [COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY],
    queryFn: WorkspacesService.getWorkspaceClientPortal,
  });

  return (
    <>
      {workspace && <ClientPortalSettings workspace={workspace} />}
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Client Portal",
        }}
      />
    </>
  );
}
