import { axiosInstance } from "./AxiosInterceptor";
import {
  BillingsClient,
  BillingAddressesClient,
  UsersClient as FbUsersClient,
  ClientsClient,
  ClientGoalsClient,
  GroupsClient,
  InvitesClient,
  MealLoggingsClient,
  ThreadClient,
  NotificationsSettingsClient,
  WorkspacesClient,
  InviteCodesClient,
  ProgramsClient,
  ProgramFoldersClient,
  ProgramTemplatesClient,
  MessagesClient,
  ClientFormsClient,
  TagsClient,
  EnrollmentsClient,
  TerraUsersClient,
  UserEmailsClient,
  ActivitiesClient,
  NotificationsClient,
  ActivityFeedbacksClient,
  BodyMeasurementsClient,
  NutritionEntriesClient,
  NutritionTargetsClient,
  ClientGroupsClient,
  GroupPostsClient,
  GroupPostCommentsClient,
  BodyWeightMeasurementsClient,
  WorkoutsClient,
  StepsCountsClient,
  ProgressPhotoClient,
  StorageClient,
  UserNotesClient,
  ClientTagsClient,
  UnsplashClient,
  ComponentsClient,
  WeeksClient,
  CustomAssetsClient,
  UrlClient,
  AnalyticsClient,
} from "@growth-machine-llc/stridist-api-client";

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const fbUsersClient = new FbUsersClient(apiBaseUrl, axiosInstance);
const clientsClient = new ClientsClient(apiBaseUrl, axiosInstance);
const invitesClient = new InvitesClient(apiBaseUrl, axiosInstance);
const groupsClient = new GroupsClient(apiBaseUrl, axiosInstance);
const billingAddressesClient = new BillingAddressesClient(
  apiBaseUrl,
  axiosInstance,
);
const notificationsSettingsClient = new NotificationsSettingsClient(
  apiBaseUrl,
  axiosInstance,
);
const workspacesClient = new WorkspacesClient(apiBaseUrl, axiosInstance);
const billingsClient = new BillingsClient(apiBaseUrl, axiosInstance);
const mealLoggingsClient = new MealLoggingsClient(apiBaseUrl, axiosInstance);
const inviteCodesClient = new InviteCodesClient(apiBaseUrl, axiosInstance);
const threadsClient = new ThreadClient(apiBaseUrl, axiosInstance);
const messagesClient = new MessagesClient(apiBaseUrl, axiosInstance);
const clientFormsClient = new ClientFormsClient(apiBaseUrl, axiosInstance);
const programsClient = new ProgramsClient(apiBaseUrl, axiosInstance);
const programFoldersClient = new ProgramFoldersClient(
  apiBaseUrl,
  axiosInstance,
);
const programTemplatesClient = new ProgramTemplatesClient(
  apiBaseUrl,
  axiosInstance,
);
const tagsClient = new TagsClient(apiBaseUrl, axiosInstance);
const enrollmentsClient = new EnrollmentsClient(apiBaseUrl, axiosInstance);
const terraClient = new TerraUsersClient(apiBaseUrl, axiosInstance);
const userEmailsClient = new UserEmailsClient(apiBaseUrl, axiosInstance);
const activitiesClient = new ActivitiesClient(apiBaseUrl, axiosInstance);
const notificationsClient = new NotificationsClient(apiBaseUrl, axiosInstance);
const mealLoggingClient = new MealLoggingsClient(apiBaseUrl, axiosInstance);
const activityFeedbacksClient = new ActivityFeedbacksClient(
  apiBaseUrl,
  axiosInstance,
);
const clientGoalsClient = new ClientGoalsClient(apiBaseUrl, axiosInstance);
const clientGroupsClient = new ClientGroupsClient(apiBaseUrl, axiosInstance);
const groupPostsClient = new GroupPostsClient(apiBaseUrl, axiosInstance);
const groupPostCommentsClient = new GroupPostCommentsClient(
  apiBaseUrl,
  axiosInstance,
);
const bodyWeightMeasurementsClient = new BodyWeightMeasurementsClient(
  apiBaseUrl,
  axiosInstance,
);
const nutritionEntriesClient = new NutritionEntriesClient(
  apiBaseUrl,

  axiosInstance,
);
const nutritionTargetsClient = new NutritionTargetsClient(
  apiBaseUrl,

  axiosInstance,
);

const bodyMeasurementClient = new BodyMeasurementsClient(
  apiBaseUrl,
  axiosInstance,
);
const workoutsClient = new WorkoutsClient(apiBaseUrl, axiosInstance);
const customAssetsClient = new CustomAssetsClient(apiBaseUrl, axiosInstance);
const stepsCountsClient = new StepsCountsClient(apiBaseUrl, axiosInstance);
const progressPhotoClient = new ProgressPhotoClient(apiBaseUrl, axiosInstance);
const storageClient = new StorageClient(apiBaseUrl, axiosInstance);
const userNotesClient = new UserNotesClient(apiBaseUrl, axiosInstance);
const clientTagsClient = new ClientTagsClient(apiBaseUrl, axiosInstance);
const unsplashClient = new UnsplashClient(apiBaseUrl, axiosInstance);
const componentsClient = new ComponentsClient(apiBaseUrl, axiosInstance);
const weeksClient = new WeeksClient(apiBaseUrl, axiosInstance);
const urlClient = new UrlClient(apiBaseUrl, axiosInstance);
const analyticsClient = new AnalyticsClient(apiBaseUrl, axiosInstance);

export {
  fbUsersClient,
  clientsClient,
  invitesClient,
  groupsClient,
  notificationsSettingsClient,
  workspacesClient,
  mealLoggingsClient,
  billingsClient,
  billingAddressesClient,
  messagesClient,
  inviteCodesClient,
  programsClient,
  programFoldersClient,
  programTemplatesClient,
  threadsClient,
  clientFormsClient,
  tagsClient,
  enrollmentsClient,
  terraClient,
  userEmailsClient,
  activitiesClient,
  notificationsClient,
  activityFeedbacksClient,
  bodyMeasurementClient,
  clientGoalsClient,
  clientGroupsClient,
  groupPostsClient,
  groupPostCommentsClient,
  mealLoggingClient,
  bodyWeightMeasurementsClient,
  nutritionEntriesClient,
  nutritionTargetsClient,
  workoutsClient,
  customAssetsClient,
  stepsCountsClient,
  progressPhotoClient,
  storageClient,
  userNotesClient,
  clientTagsClient,
  unsplashClient,
  componentsClient,
  weeksClient,
  urlClient,
  analyticsClient,
};
