import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LikeListItem } from "./LikeListItem";
import { LikeDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingRight: theme.spacing(1),
  },

  bubble: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    borderRadius: "50%",
    borderWidth: 1,
    borderStyle: "solid",

    marginLeft: 0,
    marginRight: theme.spacing(-1),
  },

  avatar: {
    borderColor: theme.palette.background.paper,
  },

  rest: {
    borderColor: theme.palette.quote,
    color: theme.palette.avatar,
    lineHeight: "30px",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
}));

export interface LikesListProps extends BoxProps {
  likes: LikeDto[];
  totalLikesCount: number;
}

export function LikesList(props: LikesListProps) {
  const { className, likes, totalLikesCount, ...other } = props;
  const s = useStyles();
  const restCount = totalLikesCount - likes.length;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {likes.map((like) => (
        <LikeListItem
          key={like.id}
          className={clsx(s.bubble, s.avatar)}
          like={like}
        />
      ))}

      {restCount > 0 && (
        <Typography className={clsx(s.bubble, s.rest)}>+{restCount}</Typography>
      )}
    </Box>
  );
}
