export const addOperation = (
  workoutSectionId: number,
  inputContent: string,
  newWorkoutSection,
) => {
  const parsedContent = JSON.parse(inputContent);

  if (workoutSectionId === undefined) {
    // Insert at the beginning if workoutSectionId is undefined
    return JSON.stringify([newWorkoutSection, ...parsedContent]);
  } else {
    // Find the index of the workoutSectionId and insert new section after it
    const targetIndex = parsedContent.findIndex(
      (section) => section.id === workoutSectionId,
    );
    if (targetIndex === -1) {
      // If target is not found, add at the end
      return JSON.stringify([...parsedContent, newWorkoutSection]);
    }
    // Insert new section right after the target
    return JSON.stringify([
      ...parsedContent.slice(0, targetIndex + 1),
      newWorkoutSection,
      ...parsedContent.slice(targetIndex + 1),
    ]);
  }
};
