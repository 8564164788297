import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getISODate, getDayAbbreviation } from "../../utils/date";

import { CompletionByDateDto } from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  day: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: theme.spacing(3),

    "&:not(:last-child)": {
      marginRight: theme.spacing(1.5),
    },

    "&::before": {},

    "&:not($completed)::before": {},

    "&$empty": {
      color: theme.palette.quote,
    },

    "&$none": {
      color: theme.palette.progress.red,
    },

    "&$some": {
      color: theme.palette.progress.yellow,
    },
  },

  check: {
    width: 20,
    height: 20,
    color: theme.palette.progress.green,
  },

  dayText: {
    fontSize: 14,
    fontWeight: "bold",
  },

  completed: {
    color: theme.palette.progress.green,
  },
  none: {},
  some: {},
  empty: {},
}));

export interface ClientLast7DaysCompletionProps extends BoxProps {
  completionByDate: CompletionByDateDto[];
}

export function ClientLast7DaysCompletion(
  props: ClientLast7DaysCompletionProps,
) {
  const { className, completionByDate, ...other } = props;
  const s = useStyles();

  const dates = React.useMemo(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    return Array.from({ length: 7 })
      .map((_, i) => dayjs(start).subtract(i, "day"))
      .reverse();
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {dates.map((date) => {
        const day = getDayAbbreviation(getISODate(date.toDate()));
        const completion = completionByDate.find((it) =>
          it.date.isSame(dayjs(date), "day"),
        )?.completion;
        const tooltipText = `${completion?.completed || 0}/${
          completion?.total || 0
        } completed`;

        return (
          <Tooltip
            key={getISODate(date.toDate())}
            arrow
            placement="top"
            title={tooltipText}
          >
            <Box
              className={clsx({
                [s.day]: true,
                [s.completed]: completion?.rate === 100,
                [s.some]: completion?.rate > 0 && completion?.rate < 100,
                [s.none]: completion?.rate === 0,
                [s.empty]: !completion,
              })}
            >
              <Typography
                className={s.dayText}
                component="span"
                children={day}
              />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
}
