import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";

import {
  getISODate,
  parseISODate,
  getDaysDiff,
  getCalendarDates,
  monthsLabels,
  daysOfWeek,
  ISO_DATE_FORMAT,
} from "../../utils/date";

import {
  ActivitySummary,
  BaseCompletion,
  ScheduledDate,
} from "@growth-machine-llc/stridist-api-client";
import { useQuery } from "@tanstack/react-query";
import ActivitiesService from "../../services/ActivitiesService";
import LinearLoader from "../loading/LinearLoader/LinearLoader";
import { extractSlugId } from "../../utils/slug";

const DISK_SIZE = 34;

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },

  month: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    margin: theme.spacing(0, 1),
  },

  chevron: {
    padding: 0,

    "& svg": {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },

  calendar: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    "& > div": {
      textAlign: "center",
      margin: theme.spacing(0.5, 0),
    },
  },

  dayOfWeek: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    textAlign: "center",
  },

  day: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textAlign: "center",
    position: "relative",
    cursor: "default",
    marginTop: theme.spacing(2.5),

    "& > span": {
      zIndex: 1,
      position: "relative",
      lineHeight: `${DISK_SIZE}px`,
    },
  },

  scheduled: {
    cursor: "pointer",
    fontWeight: "bold",
    color: theme.palette.common.black,

    "&$today": {
      color: theme.palette.common.white,
    },

    "&$selected:not($today)": {
      color: theme.palette.primary.main,
    },

    "&::before": {
      content: '""',
      width: DISK_SIZE,
      height: DISK_SIZE,
      display: "block",
      position: "absolute",
      left: `calc(50% - ${DISK_SIZE / 2}px)`,
      borderRadius: "50%",
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },

    "&$today::before": {
      backgroundColor: theme.palette.primary.main,
    },

    "&:not($completed):not($today)::before": {
      display: "none",
    },

    "&$completed::before": {
      border: `solid 2px ${theme.palette.common.black}`,
    },

    "&$completed$today$selected::before": {
      border: "none",
    },
  },

  range: {
    "&::after": {
      top: DISK_SIZE / 2 - 1,
      height: 2,
      width: "100%",
      content: '""',
      display: "block",
      position: "absolute",
      backgroundColor: theme.palette.common.black,
    },

    "&$start::after, &$end::after": {
      width: "50%",
    },

    "&$start::after": {
      left: "50%",
    },

    "&$end::after": {
      right: "50%",
    },
  },

  stats: {
    margin: theme.spacing(4, 0),
    display: "flex",
    justifyContent: "space-between",

    "& h6": {
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "center",
      color: theme.palette.primary.main,
    },

    "& p": {
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "center",
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
    },
  },

  disabled: {
    color: theme.palette.text.disabled,
    pointerEvents: "none",
  },

  today: {},
  selected: {},
  completed: {},
  start: {},
  end: {},
}));

type ScheduleDate = {
  date: string;
  id: number;
  selected: boolean;
  completed: boolean;
  streak: number;
  range: boolean;
  rangeStart: boolean;
  rangeEnd: boolean;
};

const getMonths = (schedule: ScheduleDate[], selected: string) => {
  const months = [];
  const now = new Date();
  let defaultMonthIndex = 0;

  for (let i = 0; i < schedule.length; i++) {
    const { date } = schedule[i];
    const [year, month] = parseISODate(date);
    const previous = months[months.length - 1];

    if (!previous || previous.year !== year || previous.month !== month) {
      months.push({
        year,
        month,
      });

      if (year <= now.getFullYear() && month <= now.getMonth()) {
        defaultMonthIndex = months.length - 1;
      }
    }
  }

  if (!months.length) {
    months.push({
      year: now.getFullYear(),
      month: now.getMonth(),
    });
  }

  //TODO: Refactor setting default month index based on selected date
  if (selected) {
    const [selectedYear, selectedMonth] = parseISODate(selected);
    const selectedMonthIndex = months.findIndex(
      (m) => m.year === selectedYear && m.month === selectedMonth,
    );

    if (selectedMonthIndex !== -1) {
      defaultMonthIndex = selectedMonthIndex;
    }
  }

  return { months, defaultMonthIndex };
};

const getSchedule = (
  schedule: ScheduledDate[],
  selectedDate?: string,
): ScheduleDate[] => {
  let streak = 0;

  const result = schedule.map((it, index) => {
    const { id, completedAt, date } = it;
    const prevSchedule = schedule[index - 1];
    const selected = date.format(ISO_DATE_FORMAT) === selectedDate;

    if (prevSchedule) {
      const prevDate = prevSchedule.date;

      if (getDaysDiff(date.toDate(), prevDate.toDate()) > 1) {
        streak = 0;
      }
    }

    streak = completedAt ? streak + 1 : 0;

    return {
      id,
      date: date.format(ISO_DATE_FORMAT),
      completed: Boolean(completedAt),
      selected,
      streak,
      range: false,
      rangeStart: false,
      rangeEnd: false,
    };
  });

  result.forEach((it, index) => {
    if (it.streak > 1) {
      it.range = true;
      it.rangeEnd = it.streak > (result[index + 1]?.streak || 0);

      if (it.streak === 2) {
        result[index - 1].range = true;
        result[index - 1].rangeStart = true;
      }
    }
  });

  return result;
};

const getCalendar = (year: number, month: number, schedule: ScheduleDate[]) => {
  const dates = getCalendarDates(year, month).map((date) => ({
    day: date.getDate(),
    date: getISODate(date),
  }));

  const calendar = dates.map(({ date, day }) => {
    const status = schedule.find((it) => date === it.date);
    const scheduled = Boolean(status);
    const completed = scheduled && status.completed;
    const selected = status && status.selected;
    const activityId = scheduled && status.id;

    return {
      date,
      day,
      scheduled,
      selected,
      completed,
      activityId,
      range: status && status.range,
      rangeStart: status && status.rangeStart,
      rangeEnd: status && status.rangeEnd,
    };
  });

  return calendar;
};

export interface SelectedDay {
  activityId: string;
  date: string;
  completed: boolean;
}

export interface ActivityCalendarProps extends BoxProps {
  componentSlug: string;
  client: { username: string };
  onDaySelect?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: SelectedDay,
  ) => void;
  selected?: string;
  today?: string;
}
export const CLIENT_ACTIVITY_SUMMARY_QUERY_KEY = "client-activity-summary";
export function ActivityCalendar(props: ActivityCalendarProps) {
  const {
    className,
    componentSlug,
    client,
    onDaySelect,
    selected,
    today = getISODate(),
    ...other
  } = props;
  const s = useStyles();
  const componentSlugId = extractSlugId(componentSlug);
  const {
    data: summary,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      CLIENT_ACTIVITY_SUMMARY_QUERY_KEY,
      { username: client.username, slugId: componentSlugId },
    ],
    queryFn: () => {
      return ActivitiesService.getActivitySummary(
        client.username,
        componentSlug,
      );
    },
    placeholderData: new ActivitySummary({
      completion: new BaseCompletion({
        rate: 0,
        completed: 0,
        total: 0,
      }),
      schedule: [],
    }),
  });

  const isLoadingInitial = isFetching && isPlaceholderData;

  const schedule = React.useMemo(
    () => getSchedule(summary.schedule, selected),
    [summary.schedule, selected],
  );

  const { months, defaultMonthIndex } = React.useMemo(
    () => getMonths(schedule, selected),
    [schedule, selected],
  );
  const [monthIndex, setMonthIndex] = React.useState(defaultMonthIndex);
  const { year, month } = months[monthIndex];

  const calendar = React.useMemo(
    () => getCalendar(year, month, schedule),
    [month, schedule, year],
  );

  const handlePrevMonth = React.useCallback(() => {
    setMonthIndex(monthIndex - 1);
  }, [monthIndex]);

  const handleNextMonth = React.useCallback(() => {
    setMonthIndex(monthIndex + 1);
  }, [monthIndex]);

  const handleDayClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const { activityId, date, completed } = event.currentTarget.dataset;
      if (activityId && onDaySelect) {
        const value: SelectedDay = {
          activityId,
          date,
          completed: completed === "true",
        };

        onDaySelect(event, value);
      }
    },
    [onDaySelect],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <IconButton
          className={s.chevron}
          onClick={handlePrevMonth}
          disabled={monthIndex === 0}
          children={<ChevronLeftRounded />}
          size="large"
        />
        <Typography
          className={s.month}
          variant="h4"
          children={`${monthsLabels[month]} ${year}`}
        />
        <IconButton
          className={s.chevron}
          onClick={handleNextMonth}
          disabled={monthIndex === months.length - 1}
          children={<ChevronRightRounded />}
          size="large"
        />
      </Box>

      <Box className={s.stats}>
        <Box>
          <Typography variant="h6">{summary.completion.rate}%</Typography>
          <Typography variant="body2">Completion Rate</Typography>
        </Box>

        <Box>
          <Typography variant="h6">{summary.longestStreak}</Typography>
          <Typography variant="body2">Longest Streak</Typography>
        </Box>

        <Box>
          <Typography variant="h6">{summary.completion.completed}</Typography>
          <Typography variant="body2">Total Completions</Typography>
        </Box>
      </Box>

      <Box className={s.calendar}>
        {daysOfWeek.map((day) => (
          <Typography className={s.dayOfWeek} key={day} children={day} />
        ))}

        {calendar.map((it, index) => {
          const {
            date,
            day,
            activityId,
            scheduled,
            completed,
            selected,
            range,
            rangeStart,
            rangeEnd,
          } = it;

          return (
            <Typography
              key={index}
              data-activity-id={activityId || ""}
              data-date={date}
              data-completed={completed}
              className={clsx(s.day, {
                [s.today]: date === today,
                [s.selected]: selected,
                [s.scheduled]: scheduled,
                [s.completed]: completed,
                [s.range]: range,
                [s.start]: rangeStart,
                [s.end]: rangeEnd,
                [s.disabled]: isLoadingInitial,
              })}
              onClick={handleDayClick}
              children={<span>{day}</span>}
            />
          );
        })}
      </Box>
      <Box width="100%" height={4} paddingTop={2} paddingX={4}>
        {isFetching && <LinearLoader />}
      </Box>
    </Box>
  );
}
