import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Breakpoint } from "@mui/material";
import { COACH_PROGRAMS_ROUTE } from "../../../routes/routes";
import CommonSuspenseWrapper from "../client/CommonSuspenseWrapper";
import EnrollmentsClientService from "../../../services/EnrollmentsClientService";
import { useQuery } from "@tanstack/react-query";
import { ProgramContext } from "../../../contexts/ProgramContext";
import { CurriculumErrorAppDialog } from "../../../components/dialog/app/CurriculumErrorAppDialog";
import { useCurriculumSelector } from "../../../redux/hooks";
import { selectApiErrorState } from "../../../redux/api/selectors";
import { extractSlugId } from "../../../utils/slug";

export const COACH_PROGRAMS_WRAPPER_QUERY_KEY = "coach-programs-wrapper";

const ErrorHandler = () => {
  const curriculumHasError = useCurriculumSelector(selectApiErrorState);
  return curriculumHasError ? (
    <CurriculumErrorAppDialog open={curriculumHasError} />
  ) : null;
};

const CoachProgramsWrapper = () => {
  const location = useLocation();
  const { slug } = useParams();
  const slugId = extractSlugId(slug);
  const avatarsToTake = 4;

  const {
    data: program,
    isLoading: programLoading,
    error,
  } = useQuery({
    queryKey: [COACH_PROGRAMS_WRAPPER_QUERY_KEY, { slugId }],
    queryFn: () =>
      EnrollmentsClientService.getProgramEnrollmentInfo(slug, avatarsToTake),
  });

  if (error) {
    console.error("Error fetching program data:", error);
  }

  const context = useContext(ProgramContext);

  useEffect(() => {
    if (program) {
      context.setProgramQuery(program);
    }
  }, [program]);

  const maxWidth: Breakpoint = React.useMemo(() => {
    if (!location.pathname.includes(COACH_PROGRAMS_ROUTE)) return undefined;
    else if (location.pathname.includes("curriculum")) {
      return "slg";
    } else if (location.pathname.includes("enrollments")) {
      return "md";
    }
    return undefined;
  }, [location.pathname]);

  return (
    <>
      <ErrorHandler />
      {CommonSuspenseWrapper({ maxWidth })}
    </>
  );
};

export default CoachProgramsWrapper;
