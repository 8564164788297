import React from "react";
import AppLayout from "../../../../../components/app/AppLayout";
import { RefreshSlug } from "../../../../../components/routes/RefreshSlug";
import { ClientProgram } from "../../../../../components/program/ClientProgram";
import { useParams } from "react-router-dom";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../../../routes";
import { useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../../../../services/EnrollmentsClientService";
import { DefaultLoader } from "../../../../../components/loading/DefaultLoader";
import { useCurrentUserId } from "../../../../../hooks/useCurrentUser";
import { extractSlugId } from "../../../../../utils/slug";

export const COACH_PROGRAM_PREVIEW_QUERY_KEY = "coach-program-preview";

export function CoachProgramPreviewRoute() {
  const params = useParams();
  const slug = params.slug;
  const slugId = extractSlugId(slug);
  const clientId = useCurrentUserId();
  const { data: enrollmentProgram, isLoading } = useQuery({
    queryKey: [COACH_PROGRAM_PREVIEW_QUERY_KEY, { slugId, clientId }],
    queryFn: () =>
      EnrollmentsClientService.getClientEnrollmentProgram(slug, clientId),
  });

  return isLoading ? (
    <DefaultLoader fillScreen />
  ) : (
    <AppLayout
      appDrawer={false}
      title={enrollmentProgram?.name || "Program"}
      subtitle={enrollmentProgram?.description}
      trackInfo={{
        name: "Coach - Preview Program",
      }}
      breadcrumbs={[
        {
          name: enrollmentProgram?.name || "Program",
          link: COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug),
        },
      ]}
      cover={enrollmentProgram?.image}
    >
      <RefreshSlug
        slugInfo={{
          slug: enrollmentProgram.slug,
          slugId: enrollmentProgram.slugId,
        }}
      />
      <ClientProgram slug={slug} program={enrollmentProgram} />
    </AppLayout>
  );
}
