import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "lodash";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { MessageContent } from "../messages/MessageContent";
import {
  ActivityFeedbackDto,
  UserBriefInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { useReadActivityFeedbackMutation } from "./mutations/useReadActivityFeedbackMutation";
import { formatUtcTimeAgo } from "../../utils/date";
import { TimeAgo } from "../../routes/utils/TimeAgo";

const useStyles = makeStyles((theme) => ({
  root: {},

  avatar: {
    width: 48,
    height: 48,
    borderRadius: 8,
    marginRight: theme.spacing(2),
  },

  info: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },

  infoText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.common.black,
    whiteSpace: "break-spaces",
  },

  contentWrapper: {
    marginTop: theme.spacing(1),
    whiteSpace: "break-spaces",
  },
}));
export interface ActivityResponseItemProps extends BoxProps {
  feedback: ActivityFeedbackDto;
}

export function ActivityResponseItem(props: ActivityResponseItemProps) {
  const { className, feedback, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const { id, author, content, read, created: createdAt } = feedback;
  const { mutate: notificationRead, isPending: inFlight } =
    useReadActivityFeedbackMutation();

  React.useEffect(() => {
    if (author.id !== user.id && !read && !inFlight) {
      notificationRead({ activityFeedbackId: id });
    }
  }, [id, read, notificationRead, inFlight, author.id, user.id]);

  const name = React.useMemo(
    () =>
      user.id === author.id
        ? "You"
        : `${author.role === UserRole.COACH ? "Coach" : ""} ${
            author.displayName
          }`,
    [author.displayName, author.id, author.role, user.id],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.info}>
        <Avatar
          className={s.avatar}
          alt={name}
          src={author.photoUrl}
          variant="square"
        />
        <Box className={s.infoText}>
          <Typography
            className={s.title}
            variant="h4"
            children={`${name} added feedback`}
          />
          <Typography
            className={s.subtitle}
            variant="h5"
            children={<TimeAgo date={createdAt?.toString()}></TimeAgo>}
          />
        </Box>
      </Box>
      <MessageContent content={content} textClassName={s.text} />
    </Box>
  );
}
