import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

// TODO_API_V2 currently `originWeek` is weekNumber not id,
// update calendar drag to return id both for init and target
export const updateComponentPositionAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    originWeek: number;
    newWeekId: number;
    componentId: number;
    days: string;
  }>,
) => {
  const { originWeek, newWeekId, componentId, days } = action.payload;
  if (!originWeek || !newWeekId || !componentId || !days) return;

  // Get the origin week object
  const originWeekObj = Object.values(state.value.weeks).find(
    (week) => week.weekNumber === originWeek,
  );
  if (!originWeekObj) return;

  // Get the target week object
  const targetWeekObj = state.value.weeks[newWeekId];
  if (!targetWeekObj) return;

  // Get the component to move
  const componentToMove = state.value.components[componentId];
  if (!componentToMove) return;

  // Remove the component from the origin week
  originWeekObj.components = originWeekObj.components.filter(
    (compId) => compId !== componentId,
  );
  originWeekObj.positions = originWeekObj.positions.filter(
    (id) => id !== String(componentId),
  );

  // Add the component to the target week
  targetWeekObj.components.push(componentId);
  targetWeekObj.positions.push(String(componentId));

  // Update the component with new weekId and days
  state.value.components[componentId] = {
    ...componentToMove,
    weekId: newWeekId,
    days,
  };
};
