import { ICreateMealLoggingCommand, IUpdateMealLoggingCommand, CreateMealLoggingCommand, UpdateMealLoggingCommand } from "@growth-machine-llc/stridist-api-client";
import { mealLoggingClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

interface GetMealLoggingsParams {
  date: string;
  pageNumber: number;
  pageSize: number;
}

class MealLoggingService extends ServiceBase {
  async getLoggings(params: GetMealLoggingsParams) {
    return mealLoggingClient.getMealLoggings(
      params.date,
      params.pageNumber,
      params.pageSize,
    );
  }

  async createMealLoggingEntry(variables: ICreateMealLoggingCommand) {
    const command = CreateMealLoggingCommand.fromJS(variables);
    return mealLoggingClient.createMealLogging(command);
  }

  async updateMealLoggingEntry(variables: IUpdateMealLoggingCommand) {
    const command = UpdateMealLoggingCommand.fromJS(variables);
    return mealLoggingClient.updateMealLogging(variables.id, command);
  }

  async deleteMealLoggingEntry(id: number) {
    return mealLoggingClient.deleteMealLogging(id);
  }
}

export default new MealLoggingService();
