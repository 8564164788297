import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import CustomAssetsService from "../../../services/CustomAssetsService";
import { InfiniteData } from "@tanstack/react-query";
import {
  IUpdateCustomAssetCommand,
  PaginatedListOfCustomAssetDto,
} from "@growth-machine-llc/stridist-api-client";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY } from "./useCustomAssets";

type UpdateCustomAssetOptions = {
  successMessage?: string;
};

export function useUpdateCustomAsset({
  successMessage,
}: UpdateCustomAssetOptions = {}) {
  return useOptimisticUpdateMutation({
    queryKey: [COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY],
    mutationFn: CustomAssetsService.updateCustomAsset,
    successToastMessage: successMessage ?? "Asset updated.",
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<PaginatedListOfCustomAssetDto>,
        variables: IUpdateCustomAssetCommand,
      ) =>
        INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
          oldData,
          variables.id,
          { ...variables },
        ) as InfiniteData<PaginatedListOfCustomAssetDto>,
    },
    invalidateQueryOptions: {
      enabled: true,
      refetchType: "none",
    },
    exactQueryKey: false,
  });
}
