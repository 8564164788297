import clsx from "clsx";
import React, { useEffect } from "react";
import {
  Box,
  BoxProps,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  monthsLabels,
  getDayOfWeek,
  startOfWeek,
  endOfWeek,
  addToDate,
  getWeekDiff,
  parseDateISOString,
  getISODate,
} from "../../utils/date";
import { AutoResizeInfiniteList } from "../list/AutoSizeInfiniteList";
import { sevenDays } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {},

  weeksList: {
    height: theme.spacing(7.5),
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(9.25),
      maxWidth: 1132,
    },
  },

  daysList: {
    height: theme.spacing(8.75),
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(9.75),
      maxWidth: 1132,
    },
  },

  selected: {},
  current: {},
  scheduled: {},

  weekLabel: {
    "& > span:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
    },

    "& $range": {
      padding: theme.spacing(0.25, 1),
      border: "1px solid transparent",
    },

    "&$selected $range": {
      backgroundColor: `${theme.palette.secondary.main}1a`,
      borderRadius: theme.spacing(0.5),
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
    },

    "&$selected$current $range": {
      backgroundColor: `${theme.palette.primary.main}1a`,
      borderRadius: theme.spacing(0.5),
      border: "1px solid",
      borderColor: theme.palette.primary.main,
    },

    "&$selected $month, &$selected $range": {
      color: theme.palette.text.primary,
    },

    "&$current $month, &$current $range": {
      color: theme.palette.primary.main,
    },
  },

  month: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.secondary,

    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  range: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.secondary,

    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  daysOfWeek: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: theme.spacing(0, 1),
  },

  day: {
    "& > span:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
    },
    margin: theme.spacing(0, -1.5),
  },
  dayOfWeek: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: 12,
    textTransform: "uppercase",
  },
  dayOfMonth: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 14,
    width: theme.spacing(5),
    height: theme.spacing(5),

    "&::before": {
      content: '""',
      display: "block",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      position: "absolute",
      zIndex: -1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },

    "&::after": {
      content: '""',
      display: "block",
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: "50%",
      position: "absolute",
      bottom: 0,
      left: theme.spacing(2),
    },

    "$selected:not($current) &": {
      color: theme.palette.secondary.contrastText,
    },

    "$selected:not($current) &::before": {
      backgroundColor: theme.palette.secondary.main,
    },

    "$current:not($selected) &": {
      color: theme.palette.secondary.contrastText,
    },

    "$current:not($selected) &::before": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },

    "$current$selected &": {
      color: theme.palette.primary.contrastText,
    },

    "$current$selected &::before": {
      backgroundColor: theme.palette.primary.main,
    },

    "$scheduled:not($selected):not($current) &::after": {
      backgroundColor: theme.palette.primary.main,
    },

    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      width: theme.spacing(6),
      height: theme.spacing(6),

      "&::after": {
        left: theme.spacing(2.5),
      },
    },
  },

  todayButton: {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.quote,
    opacity: 0,
    margin: theme.spacing(1.5, -1),

    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      color: theme.palette.primary.main,
      position: "absolute",
      top: theme.spacing(-1.5),
      left: 0,
      backgroundColor: theme.palette.background.default,
    },
  },

  notToday: {
    opacity: 1,
  },
}));

export interface CalendarProps extends Omit<BoxProps, "onChange"> {
  date?: string;
  enrollments: string[];
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{}>, value: string) => void;
  onWeekChange?: (index: number) => void;
  disableTooltip?: boolean;
  weeksCount?: number;
}

export function Calendar(props: CalendarProps) {
  const {
    className,
    enrollments,
    disabled,
    date,
    onChange,
    onWeekChange,
    disableTooltip,
    weeksCount = 52,
    ...other
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const currentDate = new Date();
  const formattedCurrentDate = getISODate(currentDate);
  const [scheduledDates, setScheduledDates] = React.useState<string[]>([]);

  useEffect(() => {
    if (enrollments && enrollments.length > 0) {
      const formattedScheduledDates = enrollments.map((date) =>
        getISODate(date),
      );
      setScheduledDates(formattedScheduledDates);
    }
  }, [enrollments]);

  const startOfCurrentWeek = startOfWeek(currentDate);
  const [selectedDate, setSelectedDate] = React.useState(
    props.date || formattedCurrentDate,
  );

  const [weekIndex, setWeekIndex] = React.useState(
    getWeekDiff(parseDateISOString(selectedDate), currentDate),
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { date, weekIndex } = event.currentTarget.dataset;
      if (weekIndex) {
        setWeekIndex(parseInt(weekIndex));
      }
      if (date) {
        setSelectedDate(date);
        if (onChange) {
          onChange(event, date);
        }
      }
    },
    [onChange],
  );

  const renderWeekLabel = React.useCallback(
    (index: number) => {
      const date = addToDate(startOfCurrentWeek, index, "week");
      const firstDate = date.getDate();
      const end = endOfWeek(date);
      const lastDate = end.getDate();
      const monthName = monthsLabels[end.getMonth()];
      const datesRange = `${firstDate}-${lastDate}`;

      return (
        <Button
          variant="text"
          data-week-index={index}
          onClick={handleClick}
          className={clsx(s.weekLabel, {
            [s.selected]: weekIndex === index,
            [s.current]: index === 0,
          })}
          sx={{ flexDirection: "column" }}
        >
          <Typography className={s.month}>{monthName}</Typography>
          <Typography className={s.range}>{datesRange}</Typography>
        </Button>
      );
    },
    [handleClick, s, startOfCurrentWeek, weekIndex],
  );

  const renderDay = React.useCallback(
    (index: number, startOfWeek: Date, weekIndex: number) => {
      const date = addToDate(startOfWeek, index, "day");
      const dayOfWeek = getDayOfWeek(date);
      const dayOfMonth = date.getDate();
      const formattedDate = getISODate(date);
      const isScheduled = scheduledDates.includes(formattedDate);

      return (
        <Button
          key={index}
          className={clsx(s.day, {
            [s.selected]: formattedDate === selectedDate,
            [s.current]: formattedDate === formattedCurrentDate,
            [s.scheduled]: isScheduled,
          })}
          data-week-index={weekIndex}
          data-date={formattedDate}
          onClick={handleClick}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography className={s.dayOfWeek}>{dayOfWeek}</Typography>
          <Typography component="div" className={s.dayOfMonth}>
            {dayOfMonth}
          </Typography>
        </Button>
      );
    },
    [formattedCurrentDate, handleClick, s, selectedDate, scheduledDates],
  );

  const renderWeek = React.useCallback(
    (weekIndex: number) => {
      const startOfWeek = addToDate(startOfCurrentWeek, weekIndex, "week");
      return (
        <Box className={s.daysOfWeek}>
          {sevenDays.map((_, index) =>
            renderDay(index, startOfWeek, weekIndex),
          )}
        </Box>
      );
    },
    [renderDay, s.daysOfWeek, startOfCurrentWeek],
  );

  useEffect(() => {
    onWeekChange(weekIndex);
  }, [weekIndex]);

  const handleScroll = React.useCallback((index: number) => {
    setWeekIndex(index);
  }, []);

  const notToday = formattedCurrentDate !== selectedDate || weekIndex !== 0;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AutoResizeInfiniteList
        className={s.weeksList}
        itemCount={weeksCount}
        selectedIndex={weekIndex}
        itemsPerView={mdUp ? 5 : 3}
        focusDelay={5000}
        renderItem={renderWeekLabel}
        onClick={handleClick}
        scrollButtons={!mdUp}
      />

      <AutoResizeInfiniteList
        className={s.daysList}
        itemCount={weeksCount}
        selectedIndex={weekIndex}
        onScroll={handleScroll}
        focusDelay={1500}
        renderItem={renderWeek}
        onClick={handleClick}
        scrollButtons={mdUp}
      />

      <Button
        className={clsx(s.todayButton, {
          [s.notToday]: notToday,
        })}
        data-week-index={0}
        data-date={formattedCurrentDate}
        onClick={handleClick}
      >
        Today
      </Button>
    </Box>
  );
}
