import clsx from "clsx";
import React from "react";
import { Card, CardProps, CardActionArea, CardMedia } from "@mui/material";
import { StyledComponentProps } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { CheckCircleOutlineRounded as CheckIcon } from "@mui/icons-material";

import { UnsplashImageRecord } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    margin: "auto",

    "&.MuiPaper-root": {
      padding: 0,
      minWidth: "auto",
    },
  },

  fit: {
    width: "100%",
    height: "100%",
  },

  check: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.common.white,
  },

  author: {
    marginTop: theme.spacing(0.5),
    display: "block",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    fontSize: 12,
    fontWeight: 500,
    textDecoration: "none !important" as "none",
  },

  selected: {},

  selectPending: {
    borderWidth: theme.spacing(0.5),
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    animation: "$wave 1s linear infinite",
  },
  "@keyframes wave": {
    "0%": {
      borderColor: `transparent transparent ${theme.palette.primary.main} ${theme.palette.primary.main}`,
    },
    "25%": {
      borderColor: `${theme.palette.primary.main} transparent transparent ${theme.palette.primary.main}`,
    },
    "50%": {
      borderColor: `${theme.palette.primary.main} ${theme.palette.primary.main} transparent transparent`,
    },
    "75%": {
      borderColor: `transparent ${theme.palette.primary.main} ${theme.palette.primary.main} transparent`,
    },
    "100%": {
      borderColor: `transparent transparent ${theme.palette.primary.main} ${theme.palette.primary.main}`,
    },
  },
}));

export interface UnsplashImageProps
  extends Omit<CardProps, "onSelect" | "classes">,
    StyledComponentProps<"root" | "selected"> {
  imageRecord: UnsplashImageRecord;
  onSelect: (value: UnsplashImageRecord) => void;
  selected?: boolean;
  selectPending?: boolean;
}

export function UnsplashImage(props: UnsplashImageProps) {
  const {
    className,
    imageRecord: image,
    onSelect,
    selected,
    selectPending,
    classes = {},
    ...other
  } = props;
  const s = useStyles();
  const {
    urls: { thumb },
    description,
    author_name: authorName,
    author_url: authorUrl,
  } = image;

  const handleClick = React.useCallback(() => {
    onSelect(image);
  }, [onSelect, image]);

  return (
    <>
      <Card
        className={clsx(
          s.root,
          s.fit,
          classes.root,
          className,
          selected && clsx(s.selected, classes.selected),
          selectPending && s.selectPending,
        )}
        {...other}
      >
        <CardActionArea className={s.fit} onClick={handleClick}>
          <CardMedia className={s.fit} image={thumb} title={description} />
        </CardActionArea>
        {selected && <CheckIcon className={s.check} />}
      </Card>

      <a
        href={authorUrl}
        className={s.author}
        target="_blank"
        rel="noopener noreferrer"
      >
        by {authorName}
      </a>
    </>
  );
}
