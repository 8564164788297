import CustomAssetsService from "../../../services/CustomAssetsService";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { PaginatedListOfCustomAssetDto } from "@growth-machine-llc/stridist-api-client";
import { InfiniteData } from "@tanstack/react-query";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY } from "./useCustomAssets";

export function useDeleteCustomAsset() {
  return useOptimisticUpdateMutation({
    queryKey: [COMPONENT_LIBRARY_ASSET_LIST_QUERY_KEY],
    mutationFn: CustomAssetsService.deleteCustomAsset,
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<PaginatedListOfCustomAssetDto>,
        id: number,
      ) =>
        INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem(
          oldData,
          id,
        ) as InfiniteData<PaginatedListOfCustomAssetDto>,
    },
    invalidateQueryOptions: {
      enabled: true,
      refetchType: "none",
    },
    exactQueryKey: false,
  });
}
