import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";

interface ToastAlertContent {
  details?: string;
}

const ErrorToastAlertContent: React.FC<ToastAlertContent> = ({ details }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box display="flex" flexDirection="column" onClick={toggleExpanded}>
      <Typography display="inline-block" lineHeight={1.1}>
        {"Oops!"}
        {details && (
          <Typography
            sx={{
              display: "inline-block",
              marginLeft: 0.5,
              cursor: "pointer",
            }}
          >
            {expanded ? "Show less" : "Show more..."}
          </Typography>
        )}
      </Typography>

      {details && (
        <Box>
          <Collapse in={expanded}>
            <Typography variant="body2">{details}</Typography>
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

export default ErrorToastAlertContent;
