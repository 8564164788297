import { terraClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class TerraService extends ServiceBase {
  async generateWidgetSession() {
    return terraClient.generateWidgetSession();
  }

  async generateAuthToken() {
    return terraClient.generateAuthToken();
  }
}

export default new TerraService();
