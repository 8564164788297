import { useCallback, useRef } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

export type TInfiniteScrollContainerRef = (node: HTMLDivElement) => void;

/** A custom hook that combines the useInfiniteQuery hook from react-query with a scroll event listener.

 * This hook will fetch more data when the user scrolls to the bottom of the scrollable container.

 * It returns the same object as useInfiniteQuery, with an additional ref property that can be assigned to the scrollable container.

 * P.S. Keep in mind that ref is not a React.Ref object, but a custom typed callback, import its type from the same file.

    * @param args - The same arguments as the useInfiniteQuery hook from react-query.
*/
function useInfiniteScrollQuery<T>(
  ...args: Parameters<typeof useInfiniteQuery<T>>
) {
  const query = useInfiniteQuery(...args);
  const { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = query;

  const observerRef = useRef<IntersectionObserver | null>(null);

  const observeElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading],
  );

  return { ...query, ref: observeElementRef };
}

export default useInfiniteScrollQuery;
