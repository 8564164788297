import React from "react";
import { CoachActivity } from "../../../../components/activity/CoachActivity";
import {
  ActivitySort,
  ActivitySortLocalStorageParameter,
  ActivityStatus,
  ActivityType,
} from "../../../../constants";
import { ClientsAndGroupsSelectDialog } from "../../../../components/groups/ClientsAndGroupsSelectDialog";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import {
  ClientBriefDto,
  EnrollGroupDto,
} from "@growth-machine-llc/stridist-api-client";

export function CoachActivityRoute({
  archived = false,
}: {
  archived?: boolean;
}) {
  const [clientFilterDialogOpen, setClientFilterDialogOpen] =
    React.useState(false);

  const [selectedClients, setSelectedClients] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);

  const selectedClientsIds = selectedClients
    ? selectedClients?.map((client: ClientBriefDto) => client.id)
    : [];
  const selectedGroupsIds = selectedGroups
    ? selectedGroups?.map((group: EnrollGroupDto) => group.id)
    : [];

  const openDialog = React.useCallback(() => {
    setClientFilterDialogOpen(true);
  }, [setClientFilterDialogOpen]);

  const clientFilterCount =
    (selectedClients?.length || 0) +
    (selectedGroups?.reduce(
      (acc: number, group: EnrollGroupDto) => acc + group.size,
      0,
    ) || 0);

  return (
    <>
      {clientFilterDialogOpen && (
        <ClientsAndGroupsSelectDialog
          open
          showChips
          showCount
          onClose={() => setClientFilterDialogOpen(false)}
          dialogTitle="Filter by"
          submitButtonText={"Apply"}
          selectedClients={selectedClients}
          selectedGroups={selectedGroups}
          buttonAlwaysActive
          fetchOnlyActiveAndArchived
          returnAllSelected
          onSelected={(selected) => {
            setSelectedClients(selected.clients);
            setSelectedGroups(selected.groups);
          }}
        />
      )}
      <CoachActivity
        status={archived ? ActivityStatus.ARCHIVED : ActivityStatus.ACTIVE}
        enableArchive
        clientIds={selectedClientsIds}
        groupIds={selectedGroupsIds}
        openClientsDialog={openDialog}
        clientFilterCount={clientFilterCount}
      />
    </>
  );
}

export const CoachActivityInboxRoute = () => {
  return <CoachActivityRoute />;
};

export const CoachActivityArchivedRoute = () => {
  return <CoachActivityRoute archived />;
};
