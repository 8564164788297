import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardProps,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.gray7,
    borderRadius: 12,
    padding: 20,
    cursor: "pointer",
  },
  exercises: {
    padding: `${theme.spacing(0)} !important`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wave: {
    height: 25,
  },
}));

export interface ClientExercisesSkeleton extends CardProps {
  index: number;
}

const ClientExercisesSkeleton = ({
  index,
  ...props
}: ClientExercisesSkeleton) => {
  const s = useStyles();
  const width =
    index === 0 ? 50 : (index % 4) * 5 + (index % 3) * 10 + (index % 2) * 5 + 40;
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={s.container} {...props}>
        <CardContent className={s.exercises}>
          <Skeleton animation="wave" className={s.wave} width={`${width}%`} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ClientExercisesSkeleton;
