import React from "react";
import { Box, BoxProps, Skeleton } from "@mui/material";

export interface CalendarCellSkeletonProps extends BoxProps {}

export function CalendarCellSkeleton(props: CalendarCellSkeletonProps) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 50,
          borderRadius: 1,
          overflow: 'hidden',
          border: '1px solid',
          borderColor: 'divider',
          boxShadow: 1,
          background: 'background.paper',
          padding: 1,
        }}
      >
        <Skeleton variant="text" width="40%" height={12} sx={{ marginBottom: 0 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={24} height={20} sx={{ marginRight: 1 }} />
          <Skeleton variant="text" width="60%" height={16} />
        </Box>
      </Box>
    );
}
  
  