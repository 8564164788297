import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { deleteOperation } from "./operation";

export const deleteWorkoutSectionAction = {
  prepare(componentId: number, workoutSectionId: number) {
    return {
      payload: {
        componentId,
        workoutSectionId,
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      componentId: number;
      workoutSectionId: number;
    }>,
  ) {
    const { componentId, workoutSectionId } = action.payload;

    const component = state.value.components[componentId];
    if (!component) return;

    const updatedContent = deleteOperation(workoutSectionId, component.content);

    state.value.components[componentId] = {
      ...component,
      content: updatedContent,
    };
  },
};
