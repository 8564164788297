import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ProgressLine } from "../progress/ProgressLine";

import { ClientLast7DaysCompletion } from "./ClientLast7DaysCompletion";
import {
  ClientEnrollmentDto,
  ClientStatsDto,
} from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  last7DaysCompletion: {
    marginBottom: theme.spacing(1),
  },

  text: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    width: "100%",
    textAlign: "justify",
    textAlignLast: "justify",
    padding: theme.spacing(0, 0.2),
  },

  progress: {
    marginTop: theme.spacing(1),
  },

  completed: {},
  none: {},
  some: {},
  empty: {},
}));

export interface ClientCardCompletionProps extends BoxProps {
  enrollment?: ClientEnrollmentDto;
  stats?: ClientStatsDto;
}

export function ClientCardCompletion(props: ClientCardCompletionProps) {
  const { className, enrollment, stats, ...other } = props;
  const s = useStyles();

  const { completed, total, rate } = stats
    ? stats.completion30Days
    : enrollment.completion30Days;
  const completion7Days = stats
    ? stats.completionByDate7Days
    : enrollment.completionByDate7Days;
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <ClientLast7DaysCompletion completionByDate={completion7Days} />
      <Typography className={s.text} component="div">
        Last 30 days &bull; {completed} of {total} completed
      </Typography>
      <ProgressLine className={s.progress} value={rate} />
    </Box>
  );
}
