import React, { FC } from "react";

import { ClientAutoNutritionEntryHistoryItem } from "./ClientAutoNutritionEntryHistoryItem";

export interface ClientAutoNutritionEntryHistoryProps {
  entries: readonly any[];
  refresh: any;
  clientInfo: { clientId?: number; username?: string };
}

export const ClientAutoNutritionEntryHistory: FC<
  ClientAutoNutritionEntryHistoryProps
> = (props) => {
  const { entries, refresh, clientInfo } = props;

  return (
    <>
      {entries.map((entry) => (
        <ClientAutoNutritionEntryHistoryItem
          entry={entry}
          key={entry.id}
          refresh={refresh}
          clientInfo={clientInfo}
        />
      ))}
    </>
  );
};
