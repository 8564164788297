import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import dayjs from "dayjs";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@tanstack/react-query";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import MealLoggingService from "../../services/MealLoggingService";

import { ActivityFeedbackButton } from "../activity-feedback/ActivityFeedbackButton";
import { getISODate } from "../../utils/date";

import { ClientMealLoggingEmpty } from "./ClientMealLoggingEmpty";
import { ClientMealLoggingButton } from "./ClientMealLoggingButton";
import { ClientMealLoggingEntryDialog } from "./ClientMealLoggingEntryDialog";
import { ClientMealLoggingEntriesListItem } from "./ClientMealLoggingEntriesListItem";
import ActivityFeedbacksService from "../../services/ActivityFeedbacksService";
import WorkspacesService from "../../services/WorkspacesService";
import { DefaultLoader } from "../loading/DefaultLoader";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingBottom: theme.spacing(10),
  },
}));

export const CLIENT_MEALLOGGING_LIST_PAGE_SIZE = 12;
const AUTO_LOAD_MORE_WHEN_LEFT = 4;
export const CLIENT_MEALLOGGING_LIST_QUERY_KEY = "client-mealloggings";
export const CLIENT_MEALLOGGING_ACTIVITY_QUERY_KEY =
  "client-mealloggings-activity";
export const CLIENT_PORTAL_QUERY_KEY = "client-portal";

export interface ClientMealLoggingEntriesListProps extends BoxProps {
  date: string;
}

export function ClientMealLoggingEntriesList(
  props: ClientMealLoggingEntriesListProps,
) {
  const s = useStyles();
  const { data: workspace } = useQuery({
    queryKey: [CLIENT_PORTAL_QUERY_KEY],
    queryFn: WorkspacesService.getWorkspaceClientPortal,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const { className, date, ...other } = props;

  const {
    data: mealsData,
    ref: fetchNextPageTriggerRef,
    isLoading: isLoadingInitial,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [CLIENT_MEALLOGGING_LIST_QUERY_KEY, { date }],
    queryFn: ({ pageParam = 1 }) =>
      MealLoggingService.getLoggings({
        date: dayjs(new Date(date)).format("YYYY-MM-DD"),
        pageNumber: pageParam as number,
        pageSize: CLIENT_MEALLOGGING_LIST_PAGE_SIZE,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.hasNextPage ? pages.length + 1 : undefined;
    },
  });

  const mealLoggingEntries = React.useMemo(
    () => mealsData?.pages?.flatMap((page) => page.items),
    [mealsData?.pages],
  );

  const { data: activity } = useQuery({
    queryKey: [CLIENT_MEALLOGGING_ACTIVITY_QUERY_KEY, { date }],
    queryFn: () =>
      ActivityFeedbacksService.getMealLoggingActivity(dayjs(new Date(date))),
    enabled: mealsData?.pages?.at(0)?.totalCount > 0,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const today = date === getISODate();
  const future = new Date(date).getTime() > new Date(getISODate()).getTime();

  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedEntryId, setSelectedEntryId] = React.useState<number>();

  const handleMoreClick = React.useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleEditOpen = React.useCallback(() => {
    setOpenEdit(true);
  }, []);

  const handleEditClose = React.useCallback(() => {
    setOpenEdit(false);
    setSelectedEntryId(null);
  }, []);

  const handleEntryEditClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const {
        dataset: { entryId },
      } = event.currentTarget;

      if (entryId) {
        setSelectedEntryId(parseInt(entryId, 10));
        setOpenEdit(true);
      }
    },
    [],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {mealLoggingEntries?.length ? (
        <>
          {mealLoggingEntries.map((entry, index, array) => (
            <>
              <ClientMealLoggingEntriesListItem
                key={entry.id}
                mealLoggingEntry={entry}
                onEditClick={handleEntryEditClick}
              />
              {index == array.length - AUTO_LOAD_MORE_WHEN_LEFT && (
                <div ref={fetchNextPageTriggerRef}></div>
              )}
            </>
          ))}

          {/* Fallback button, will be not visible if no bugs encountered with fetchNextPageTriggerRef.
          Works as loading indicator in all other cases*/}
          {hasNextPage && (
            <LoadMoreButton
              onClick={handleMoreClick}
              disabled={isFetching}
              loading={isFetchingNextPage}
              fullWidth
            >
              Show more
            </LoadMoreButton>
          )}

          {activity && (
            <ActivityFeedbackButton activity={activity} hideWhenEmpty />
          )}
        </>
      ) : isLoadingInitial ? (
        <DefaultLoader fullWidth />
      ) : (
        <ClientMealLoggingEmpty today={today} />
      )}

      {!future && <ClientMealLoggingButton onClick={handleEditOpen} />}

      {openEdit && (
        <ClientMealLoggingEntryDialog
          open
          date={date}
          workspace={workspace}
          mealLoggingEntry={
            mealLoggingEntries.find(({ id }) => id === selectedEntryId) || null
          }
          onClose={handleEditClose}
        />
      )}
    </Box>
  );
}
