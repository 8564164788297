import { workoutsClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class WorkoutResultsService extends ServiceBase {
  async getUserExerciseResults(
    pageNumber: number,
    pageSize: number,
    clientId: number | null | undefined,
  ) {
    return workoutsClient.getUserExerciseResults(
      pageNumber,
      pageSize,
      clientId,
      null,
      null,
    );
  }

  async getUserExerciseHistory(
    clientId: number | null | undefined,
    exerciseId: number | null | undefined,
    exerciseTitle: string | null | undefined,
    clientUsername: string | null | undefined,
  ) {
    return workoutsClient.getUserExerciseResults(
      1,
      9999,
      clientId,
      exerciseId,
      exerciseTitle,
      clientUsername,
    );
  }

  async getUserExercises(
    pageNumber: number,
    pageSize: number,
    clientId: number | null | undefined,
    searchQuery: string | null | undefined,
  ) {
    return workoutsClient.getUserExercises(
      pageNumber,
      pageSize,
      clientId,
      searchQuery,
    );
  }
}

export default new WorkoutResultsService();
