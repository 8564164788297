import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CardAvatar, CardAvatarProps } from "../card/CardAvatar";
import { UserBriefInfoDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid",
    borderColor: theme.palette.grey[50],
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    fontSize: 12,
    marginLeft: theme.spacing(-2),
    fontWeight: "bold",
    backgroundColor: theme.palette.avatar,
  },
}));

export interface AvatarProps extends CardAvatarProps {
  user?: UserBriefInfoDto | null;

  displayName?: string;
  photoURL?: string;
}

export function Avatar(props: AvatarProps) {
  const { className, displayName, photoURL, ...other } = props;
  const s = useStyles();
  const initials = displayName.substring(0, 1);

  return (
    <CardAvatar
      className={clsx(s.root, className)}
      src={photoURL}
      children={initials}
      {...other}
    />
  );
}
