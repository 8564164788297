import clsx from "clsx";
import React, { ReactNode, ReactElement } from "react";
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Typography,
  Container,
  IconButton,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Menu, ChevronLeft, KeyboardBackspace } from "@mui/icons-material";

import { polyfillCSS } from "../../utils/css";
import { getFirstName } from "../../utils/user";
import { UserRole } from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useQueryParam } from "../../hooks/useQueryParam";
import { Link } from "../link/Link";
import { CardMedia } from "../card/CardMedia";

import { Notifications } from "./Notifications";
import { AppBarNav } from "./AppBarNav";
import { AppLogo } from "./AppLogo";
import { AppBarBackButton } from "./AppBarBackButton";
import { useLocation } from "react-router-dom";
import { PREVIEW_ROUTE } from "../../routes/routes";
import { DefaultTheme } from "@mui/styles";

const COVER_HEIGHT = 248;
const COVER_TOP_SPACING = 8;
export const headerHeight = (theme: DefaultTheme) =>
  polyfillCSS(
    `calc(${theme.spacing(
      8,
    )} + max(var(--safe-area-inset-top) - ${theme.spacing(3)}, 0px))`,
  );

export const PADDING_LEFT_MOBILE = (theme: DefaultTheme) =>
  polyfillCSS(`max(var(--safe-area-inset-left), ${theme.spacing(2)})`);
export const PADDING_RIGHT_MOBILE = (theme: DefaultTheme) =>
  polyfillCSS(`max(var(--safe-area-inset-right), ${theme.spacing(2)})`);

export const PADDING_LEFT_DESKTOP = (theme: DefaultTheme) =>
  polyfillCSS(`max(var(--safe-area-inset-left), ${theme.spacing(3)})`);
export const PADDING_RIGHT_DESKTOP = (theme: DefaultTheme) =>
  polyfillCSS(`max(var(--safe-area-inset-right), ${theme.spacing(3)})`);

const useStyles = makeStyles((theme) => {
  const computedHeaderHeight = headerHeight(theme);

  return {
    "@global": {
      "#root > .MuiContainer-root": {
        paddingLeft: PADDING_LEFT_MOBILE(theme),
        paddingRight: PADDING_RIGHT_MOBILE(theme),

        [theme.breakpoints.up("sm")]: {
          paddingLeft: PADDING_LEFT_DESKTOP(theme),
          paddingRight: PADDING_RIGHT_DESKTOP(theme),
        },
      },

      "#root > .MuiContainer-root:last-child": {
        paddingBottom: polyfillCSS("var(--safe-area-inset-bottom)"),
      },
    },

    root: {
      backgroundColor: theme.palette.background.default,

      [theme.breakpoints.down("md")]: {
        display: ({ hideOnMobile }: AppBarProps) =>
          hideOnMobile ? "none" : "inherit",
      },
    },

    barContainer: {
      maxWidth: "unset",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",

      [theme.breakpoints.down("md")]: {
        position: "fixed",
        zIndex: theme.zIndex.drawer,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        height: computedHeaderHeight,
        paddingTop: polyfillCSS(
          `calc(max(var(--safe-area-inset-top) - ${theme.spacing(2)}, 0px))`,
        ),
        backgroundColor: theme.palette.background.paper,

        "&$preview": {
          display: "none",
        },
      },

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1.25, 10, 0, 4),
      },
    },

    headlineContainer: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: polyfillCSS(
        `calc(${theme.spacing(10)} + var(--safe-area-inset-top))`,
      ),
      paddingBottom: theme.spacing(3),

      paddingLeft: polyfillCSS(
        `max(${theme.spacing(2)}, var(--safe-area-inset-left))`,
      ),
      paddingRight: polyfillCSS(
        `max(${theme.spacing(2)}, var(--safe-area-inset-right))`,
      ),
    },

    headlineTitle: {
      fontSize: 19,
      [theme.breakpoints.up("md")]: {
        fontSize: 40,
      },
    },

    headlineSubtitle: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      margin: 0,

      [theme.breakpoints.up("md")]: {
        fontSize: 18,
        marginTop: theme.spacing(0.75),
      },
    },

    titleWrapper: {
      display: "flex",
      flexFlow: "column nowrap",
    },

    titleBreadcrumbs: {
      display: "none",
      marginLeft: theme.spacing(-0.75),

      [theme.breakpoints.up("llg")]: {
        display: "block",
      },
    },

    breadcrumbs: {
      display: "flex",
      flexFlow: "row wrap",
      "& .MuiSvgIcon-root": {
        fill: theme.palette.text.secondary,
      },
    },

    breadcrumb: {
      fontSize: 16,
      color: theme.palette.text.secondary,
    },

    nav: {
      display: "none",

      [theme.breakpoints.up("md")]: {
        display: "flex",
      },

      [theme.breakpoints.up("llg")]: {
        display: "none",
      },
    },

    navBreadcrumbs: {
      marginLeft: theme.spacing(1),
    },

    menu: {
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(2),
    },

    logo: {
      width: theme.spacing(4.5),
      position: "absolute",
      left: "50%",
      height: "100%",
      marginLeft: theme.spacing(-2.25),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    button: {
      marginLeft: theme.spacing(1),
    },

    notifications: {
      marginLeft: theme.spacing(1),
    },

    cover: {
      height: COVER_HEIGHT,

      "& $headlineContainer": {
        height: "100%",
        alignItems: "flex-start",
      },

      "& $titleWrapper": {
        alignSelf: "flex-end",
        marginRight: theme.spacing(5),
      },

      "& $headlineTitle, & $headlineSubtitle": {
        color: theme.palette.common.white,
      },

      "& $iconButton": {
        color: theme.palette.text.primary,
      },

      [theme.breakpoints.down("lg")]: {
        "&$preview": {
          height:
            COVER_HEIGHT -
            Number(theme.spacing(COVER_TOP_SPACING).replace("px", "")),
        },
      },

      [theme.breakpoints.up("md")]: {
        height: 352,

        "& $barContainer": {
          height: "100%",
          alignItems: "flex-start",
        },

        "& $iconButton": {
          opacity: 0.8,
        },

        "& $iconButton:hover": {
          backgroundColor: theme.palette.background.paper,
        },

        "& $backButton": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },

    coverMedia: {
      position: "absolute",
      width: "100%",
      top: theme.spacing(COVER_TOP_SPACING),
      bottom: 0,
      zIndex: 10,

      "&$preview": {
        top: 0,
      },

      [theme.breakpoints.up("md")]: {
        top: 0,
      },

      "&::before": {
        background: "linear-gradient(transparent, hsla(0, 0%, 0%, 0.6))",
        content: '""',
        display: "block",
        zIndex: -1,
        width: "100%",
        height: "100%",
      },
    },

    iconButton: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },

    backButton: {
      backgroundColor: "transparent",
      zIndex: theme.zIndex.appBar,
      "& span": {
        fontSize: 18,
        fontWeight: 500,
        margin: theme.spacing(0, 0.5),
      },
    },

    iconLogo: {
      transform: "translateX(-20%)",
      maxHeight: "42px",
      width: "auto",
    },

    preview: {},
  };
});

const useToolbarStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      minHeight: polyfillCSS(`calc(128px + var(--safe-area-inset-top))`),
    },
  },
}));

type ToolbarProps = {
  className?: string;
  children?: ReactNode;
  actions?: Array<ReactNode>;
};

function Toolbar(props: ToolbarProps) {
  const { className, actions, ...other } = props;
  const s = useToolbarStyles();
  return <MuiToolbar className={clsx(s.toolbar, className)} {...other} />;
}

export interface AppBarProps {
  className?: string;
  children?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  onMenuClick?: () => void;
  actions?: Array<ReactElement>;
  breadcrumbs?: any[];
  hideOnMobile?: boolean;
  cover?: string;
  forceBackButton?: boolean;
}

function AppBar(props: AppBarProps) {
  const {
    className,
    children,
    title,
    subtitle,
    actions,
    breadcrumbs,
    onMenuClick,
    cover,
    hideOnMobile,
    forceBackButton = true,
    ...other
  } = props;
  const user = useCurrentUser();
  const firstName = getFirstName(user);
  const { breakpoints } = useTheme();
  const location = useLocation();
  const [previewQueryParam] = useQueryParam<string>("preview");
  const preview =
    previewQueryParam === "true" || location.pathname.includes(PREVIEW_ROUTE);
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const s = useStyles(props);

  const renderBreadcrumbs = React.useMemo(
    () =>
      breadcrumbs && (
        <Box className={s.breadcrumbs}>
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              {/* hack to preserve top spacing */}
              <Box style={{ opacity: breadcrumb.link ? 1 : 0 }}>
                <ChevronLeft />
              </Box>
              <Link
                href={breadcrumb.link}
                underline="none"
                children={
                  <Typography
                    className={s.breadcrumb}
                    component="span"
                    variant="body1"
                    children={breadcrumb.name}
                  />
                }
              />
            </React.Fragment>
          ))}
        </Box>
      ),
    [breadcrumbs], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const renderTitle = React.useMemo(
    () => (
      <Box className={s.titleWrapper}>
        {!cover && (
          <Box className={s.titleBreadcrumbs}>{renderBreadcrumbs}</Box>
        )}
        <Box sx={{ zIndex: 20 }}>
          <Typography
            className={s.headlineTitle}
            component="h1"
            variant={mdUp ? "h3" : "h5"}
            children={
              location.pathname === "/home"
                ? `Good ${
                    new Date().getHours() < 12 ? "Morning" : "Afternoon"
                  }, ${firstName}!`
                : title
            }
          />
          {subtitle && (
            <Typography variant="h6" className={s.headlineSubtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      firstName,
      location.pathname,
      mdUp,
      renderBreadcrumbs,
      title,
      subtitle,
      cover,
    ],
  );

  const backButton = React.useMemo(() => {
    if (forceBackButton) {
      return <AppBarBackButton />;
    } else {
      const back = !preview && cover && breadcrumbs && breadcrumbs[0];

      return (
        back && (
          <Link href={back.link} underline="none">
            <IconButton
              className={clsx(s.iconButton, s.backButton)}
              size="large"
            >
              <KeyboardBackspace />
              <Typography component="span">Back</Typography>
            </IconButton>
          </Link>
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs, cover]);

  return (
    <MuiAppBar
      className={clsx(s.root, className, {
        [s.cover]: cover,
        [s.preview]: preview,
      })}
      position="relative"
      color="inherit"
      elevation={0}
      {...other}
    >
      {cover ? (
        <CardMedia
          className={clsx(s.coverMedia, preview && s.preview)}
          image={cover}
        />
      ) : (
        <AppBarNav className={s.nav}>
          <Box className={s.navBreadcrumbs}>{renderBreadcrumbs}</Box>
        </AppBarNav>
      )}

      <Container className={clsx(s.barContainer, preview && s.preview)}>
        {((forceBackButton ||
          (!preview && cover && breadcrumbs && breadcrumbs[0])) &&
          backButton) ||
          (!mdUp && (
            <IconButton className={s.menu} onClick={onMenuClick} size="large">
              <Menu />
            </IconButton>
          ))}

        {mdUp ? (
          renderTitle
        ) : (
          <Link
            className={s.logo}
            href={user?.role === UserRole.CLIENT ? "/home" : "/coach/programs"}
            underline="none"
            children={<AppLogo size={36} className={s.iconLogo} />}
          />
        )}

        {mdUp && <span style={{ flexGrow: 1 }} />}

        {mdUp &&
          actions &&
          actions.map((x, i) =>
            React.cloneElement(x, { key: i, className: s.button }),
          )}

        {!preview && <Notifications className={s.notifications} user={user} />}
      </Container>

      {!mdUp && (
        <Container className={s.headlineContainer}>
          {renderTitle}
          <span style={{ flexGrow: 1 }} />
          {actions &&
            actions.map((x, i) =>
              React.cloneElement(x, { key: i, className: s.button }),
            )}
        </Container>
      )}
    </MuiAppBar>
  );
}

export { Toolbar };
export default AppBar;
