import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Portal, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CheckboxPartialIcon } from "../../icons/CheckboxPartial.svg";
import { polyfillCSS } from "../../utils/css";
import { CheckboxField } from "../checkbox/CheckboxField";
import { Tooltip } from "../info/Tooltip";
import { maybePluralize } from "../../utils/text";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 1,
    bottom: 0,
    transform: "translateY(100%)",
    right: 0,
    left: 0,
    minHeight: theme.spacing(10),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(10)} + var(--safe-area-inset-right)) !important`,
    ),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",

    transition: theme.transitions.create(["opacity", "transform"]),

    [theme.breakpoints.up("llg")]: {
      left: polyfillCSS(
        `calc(${theme.drawer.width}px + var(--safe-area-inset-left))`,
      ),
    },

    "& button": {
      height: theme.spacing(6),
    },

    opacity: 0,
  },

  selected: {
    bottom: 0,
    opacity: 1,
    transform: "translateY(0%)",
  },

  selection: {
    display: "flex",
    alignItems: "center",
  },

  checkbox: {
    marginRight: theme.spacing(0),
  },

  label: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 700,
  },

  icon: {
    color: theme.palette.primary.main,
  },

  actions: {
    [theme.breakpoints.down("lg")]: {
      "& > button": {
        width: "100%",
        margin: theme.spacing(0, 0, 1.5, 0),
      },
    },
  },
}));

export interface GroupActionsProps extends BoxProps {
  total: number;
  selectedTotal: number;
  unitLabel?: string;
  selectedAll?: boolean;
  onSelectAll?: () => void;
  onClearSelection?: () => void;
  onSelectAllPages?: () => void;
}

export function GroupActions(props: GroupActionsProps) {
  const {
    className,
    total,
    selectedTotal,
    selectedAll,
    unitLabel,
    children,
    onSelectAll,
    onClearSelection,
    onSelectAllPages,
    ...other
  } = props;
  const s = useStyles();

  const handleCheckboxClick = React.useCallback(() => {
    if (onClearSelection && selectedAll) {
      onClearSelection();
    }

    if (onSelectAll && !selectedAll) {
      onSelectAll();
    }
  }, [onClearSelection, onSelectAll, selectedAll]);

  return (
    <Portal>
      <Box
        className={clsx(s.root, className, selectedTotal > 0 && s.selected)}
        {...other}
      >
        <Box className={s.selection}>
          <Tooltip
            placement="top"
            title={selectedAll ? "Clear selection" : "Select all"}
          >
            <Box>
              <CheckboxField
                className={s.checkbox}
                checked={selectedAll}
                onClick={handleCheckboxClick}
                icon={
                  selectedTotal > 0 ? (
                    <CheckboxPartialIcon className={s.icon} />
                  ) : undefined
                }
              />
            </Box>
          </Tooltip>
          <Typography className={s.label}>
            {maybePluralize(selectedTotal, unitLabel)} selected
          </Typography>

          {selectedTotal < total && (
            <Button variant="text" color="primary" onClick={onSelectAllPages}>
              Select all {maybePluralize(total, unitLabel)} on this page
            </Button>
          )}
        </Box>
        <Box className={s.actions}>{children}</Box>
      </Box>
    </Portal>
  );
}
