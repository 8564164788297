import React from "react";
import {
  Dialog,
  DialogProps,
  Box,
  Typography,
  Stack,
  DialogContent,
} from "@mui/material";
import { AppLogo } from "../../app/AppLogo";
import Lottie from "react-lottie";
import animationData from "../../../lotties/present-confetti.json";
import { ActionButton } from "../../button/ActionButton";
import BillingsService from "../../../services/BillingsService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import {
  REACTIVATE_PROMO_QUERY,
  SOMETHING_WENT_WRONG,
} from "../../../constants";
import { initStripe } from "../../../stripe";
import { COACH_CLIENTS_ACTIVE_ROUTE } from "../../../routes/routes";
import { isMobileApp } from "../../../utils/mobile";
import { useToastAlert } from "../../app/ToastAlert/ToastAlertProvider";

enum UpgradeStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export function FreeLapsedCoachUpgradeDialog(props: DialogProps) {
  const { className, onClose, ...other } = props;
  const currentUser = useCurrentUser();
  const selectedPlan = currentUser?.plan;
  const { showToastAlert } = useToastAlert();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const queryClient = useQueryClient();

  const stripeAction = React.useCallback(
    async (id) => {
      try {
        const stripe = await initStripe();
        const result = await stripe.redirectToCheckout({
          sessionId: id,
        });

        if (result.error) {
          showToastAlert("error", {
            message: result.error.message,
          });
        }
      } catch (e) {
        console.error(e);
        showToastAlert("error", {
          message: SOMETHING_WENT_WRONG,
        });
      }
    },
    [showToastAlert],
  );

  const {
    mutate: createPromoSubscription,
    isPending: createPromoSubscriptionInFlight,
  } = useMutation({
    mutationKey: ["create-promo-subscription"],
    mutationFn: BillingsService.createPromoSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
    },
  });

  const createPromo = React.useCallback(() => {
    if (currentUser?.isImpersonating) {
      showToastAlert("error", {
        message:
          "You cannot activate a subscription while impersonating a user.",
      });
      return;
    }

    createPromoSubscription(
      {
        successUrl: `${window.location.origin}${COACH_CLIENTS_ACTIVE_ROUTE}?${REACTIVATE_PROMO_QUERY}=${UpgradeStatus.SUCCESS}`,
        cancelUrl: `${window.location.href}?${REACTIVATE_PROMO_QUERY}=${UpgradeStatus.ERROR}`,
      },
      {
        onSuccess: ({ sessionId }) => {
          return stripeAction(sessionId);
        },
      },
    );
  }, [createPromoSubscription, showToastAlert, selectedPlan, stripeAction]);

  const handleCloseButtonClick = React.useCallback(() => {}, [onClose]);

  return (
    <Dialog
      onClose={handleCloseButtonClick}
      PaperProps={{ sx: { width: 1, maxWidth: 550 } }}
      componentsProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(3px)",
          },
        },
      }}
      {...other}
    >
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: 3,
          paddingTop: 4,
        }}
      >
        <AppLogo main full size={160} width={160} />
        <Box
          sx={{
            width: "100%",
            maxWidth: "300px",
            height: "auto",
            marginY: 3,
          }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Lottie options={defaultOptions} width={"100%"} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Welcome Back!
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          We’re so glad to see you again
        </Typography>
        <Typography variant="body1" gutterBottom>
          {isMobileApp ? "Switch to WEB version" : "Click below"} to re-activate
          your account and enjoy the newly upgraded Stridist platform for free
          for the rest of 2024
        </Typography>
        {!isMobileApp && (
          <Stack sx={{ py: 2 }}>
            <ActionButton
              size="large"
              children="Activate"
              onClick={createPromo}
              disabled={createPromoSubscriptionInFlight}
            />
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
