export const duplicateWorkoutExercise = (
  workoutSectionId: number,
  exerciseId: number,
  newExerciseId: number,
  inputContent: any,
) => {
  const parsedContent = JSON.parse(inputContent);
  const workoutSection = parsedContent.find(
    (section) => section.id === workoutSectionId,
  );
  if (!workoutSection) return;

  // Find the exercise to duplicate
  const exerciseToDuplicate = workoutSection.workout.exercises.find(
    (exercise) => exercise.id === exerciseId,
  );
  if (!exerciseToDuplicate) return;

  // Create the new duplicated exercise
  const newDuplicatedExercise = {
    ...exerciseToDuplicate,
    id: newExerciseId,
    superset: newExerciseId,
  };

  // Find the index of exercise to duplicate
  const index = workoutSection.workout.exercises.findIndex(
    (exercise) => exercise.id === exerciseId,
  );
  if (index === -1) return;

  // Add duplicated to exercises arr
  const updatedExercises = [
    ...workoutSection.workout.exercises.slice(0, index + 1),
    newDuplicatedExercise,
    ...workoutSection.workout.exercises.slice(index + 1),
  ];

  workoutSection.workout.exercises = updatedExercises;
  return JSON.stringify(parsedContent);
};
