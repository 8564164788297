import { programsClient } from "../api/ApiClients";
import {
  IUpdateProgramCommand,
  ProgramSettingsDto,
  UpdateProgramCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class ProgramsService extends ServiceBase {
  async getProgramSettings(slug: string): Promise<ProgramSettingsDto> {
    return programsClient.getProgramSettings(slug);
  }

  async updateProgram(data: IUpdateProgramCommand) {
    const command = new UpdateProgramCommand(data);
    return programsClient.updateProgram(command);
  }

  async getCurriculum(slug: string) {
    return programsClient.getProgramCurriculum(slug);
  }
}

export default new ProgramsService();
