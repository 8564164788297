import React from "react";
import { Box, BoxProps } from "@mui/material";
import { isOptimisticUpdateTempId } from "../../hooks/useOptimisticUpdateMutation";

interface OptimisticUpdateContainerProps extends Omit<BoxProps, "id"> {
  id: number;
  children: React.ReactNode;
  transition?: boolean;
  transitionDuration?: number;
}

const OptimisticUpdateContainer = ({
  id,
  children,
  transition = true,
  transitionDuration = 500,
  ...props
}: OptimisticUpdateContainerProps) => {
  const transitionStyle = transition
    ? {
        transition: `filter ${transitionDuration}ms ease-in-out`,
      }
    : {};

  return (
    <Box
      sx={{
        ...transitionStyle,
        ...(isOptimisticUpdateTempId(id) && {
          filter: (theme) => theme.filters.disabled,
          pointerEvents: "none",
          transition: "none",
        }),
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default OptimisticUpdateContainer;
