import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { BoxProps, Box } from "@mui/material";
import { PaginationItem, PaginationProps } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
  },
}));

export interface ConnectionPaginationProps
  extends Omit<BoxProps, "onChange">,
    Pick<PaginationProps, "size"> {
  currentPage?: number;
  gotoPage?: (page: number) => void;
  pages?: number;
  onChange?: (cursor: string) => void;
  autoHide?: boolean;
}

export function ConnectionPagination(props: ConnectionPaginationProps) {
  const {
    className,
    size = "large",
    onChange,
    autoHide = false,
    pages,
    currentPage,
    gotoPage,
    ...other
  } = props;
  const s = useStyles();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        dataset: { cursor },
      } = event.currentTarget;

      if (gotoPage) {
        gotoPage(parseInt(cursor));
      }
    },
    [onChange],
  );

  return (
    (!autoHide || pages > 1) && (
      <Box className={clsx(s.root, className)} {...other}>
        <PaginationItem
          size={size}
          selected={currentPage === 1}
          disabled={currentPage === 1}
          type="previous"
          data-cursor={currentPage - 1}
          onClick={handleClick}
        />

        {Array(pages)
          .fill(0)
          .map((_, index) => (
            <PaginationItem
              key={index}
              page={index + 1}
              selected={currentPage === index + 1}
              disabled={currentPage === index + 1}
              size={size}
              data-cursor={index + 1}
              onClick={handleClick}
            />
          ))}

        <PaginationItem
          size={size}
          selected={currentPage === pages}
          disabled={currentPage === pages}
          type="next"
          data-cursor={currentPage + 1}
          onClick={handleClick}
        />
      </Box>
    )
  );
}
