import React from "react";
import { UserRole } from "../constants";
import { UserInfoDto } from "@growth-machine-llc/stridist-api-client";

export type UserInfo = Omit<UserInfoDto, "init" | "toJSON">;

export const CurrentUserContext = React.createContext<UserInfo>(null);

export function useCurrentUser() {
  return React.useContext(CurrentUserContext);
}

export function useUserIsClient() {
  const user = useCurrentUser();

  return user && user.role === UserRole.CLIENT;
}

export function useCurrentUserId() {
  const user = useCurrentUser();

  return user && user.id;
}

export function useCurrentUserExternalId() {
  const user = useCurrentUser();

  return user && user.externalReferenceId;
}

export function useCoachIsImpersonating() {
  const user = useCurrentUser();

  return user && (user.isSample || user.isImpersonating);
}

export function useCurrentUserRole() {
  const user = useCurrentUser();

  return user && user.role;
}
