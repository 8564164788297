import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { CLIENT_NOTES_QUERY_KEY } from "../../screen/CoachClientNotesScreen";
import UserNotesService from "../../../services/UserNotesService";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { useClient } from "../../../hooks/useClient";

const useDeleteNoteMutation = () => {
  const client = useClient();

  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_NOTES_QUERY_KEY, { client: client.id }],
    mutationFn: UserNotesService.deleteUserNote,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: any, id) =>
        INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem(oldData, id),
    },
  });
};

export default useDeleteNoteMutation;
