// TODO move selectors to different files

import type { CurriculumRootState } from "../../store";

export const selectCurriculum = (state: CurriculumRootState) =>
  state.curriculum.present;

export const selectProgramWeeks = (state: CurriculumRootState) => {
  const weeks = state.curriculum.present?.value?.weeks;
  if (!weeks) return [];

  const weeksArr = Object.values(weeks).map((week) => ({
    id: week.id,
    week: week.weekNumber,
  }));

  return weeksArr.sort((a, b) => a.week - b.week);
};

export const selectPastCurriculum = (state: CurriculumRootState) =>
  state.curriculum.past;
export const selectFutureCurriculum = (state: CurriculumRootState) =>
  state.curriculum.future;

export const selectProgram = (state: CurriculumRootState) =>
  state.curriculum.present.value?.program;

export const isProgramEmpty = (state: CurriculumRootState) => {
  const components = state.curriculum.present.value.components;
  return !components || Object.keys(components).length === 0;
};

export const selectProgramImage = (state: CurriculumRootState) =>
  state.curriculum.present.value.program.image;

export const selectFirstWeek = (state: CurriculumRootState) =>
  state.curriculum.present.value.weeks[
    state.curriculum.present.value.program.weeks[0]
  ];

export const selectWeeks = (state: CurriculumRootState) =>
  state.curriculum.present.value.weeks;

export const selectComponents = (state: CurriculumRootState) =>
  state.curriculum.present.value.components;

export const selectWeekById = (id: number) => (state: CurriculumRootState) =>
  state.curriculum.present.value.weeks[id];

export const selectComponentBySlug =
  (slug: string) => (state: CurriculumRootState) => {
    return Object.values(state.curriculum.present.value?.components || {}).find(
      (component) => component.slug === slug,
    );
  };

export const selectComponentBySlugId =
  (slugId: string) => (state: CurriculumRootState) => {
    return Object.values(state.curriculum.present.value?.components || {}).find(
      (component) => component.slugId === slugId,
    ) || 
    Object.values(state.curriculum.present.value?.components || {}).find(
      (component) => component.slug === `${slugId}-tempslug`,
    );
  };

export const selectComponentExistsBySlugId =
  (slugId: string) => (state: CurriculumRootState) => {
    return Object.values(state.curriculum.present.value?.components || {}).some(
      (component) => component.slugId === slugId,
    ) || 
    Object.values(state.curriculum.present.value?.components || {}).some(
      (component) => component.slug === `${slugId}-tempslug`,
    );
  };

export const selectComponentById =
  (id: number) => (state: CurriculumRootState) => {
    return state.curriculum.present.value?.components[id];
  };

export const selectPresentCurriculumId = (state: CurriculumRootState) =>
  state.curriculum.present.value.program.id;

export const selectCantMoveWeeksExceptSelf =
  (self: number) => (state: CurriculumRootState) => {
    return Object.values(state.curriculum.present?.value?.weeks || {})
      .filter((week) => week.id !== self && week.waitingForApiResponse)
      .map((week) => week.weekNumber);
  };
