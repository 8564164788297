import clsx from "clsx";
import React from "react";
import { Divider, Box, Typography, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { BaseDialog, BaseDialogProps } from "../dialog/BaseDialog";
import { colorSystem } from "../../theme";
import { ActionButton } from "../button/ActionButton";
import { polyfillCSS, hexToRgbA } from "../../utils/css";
import { maybePluralize } from "../../utils/text";
import { createTextMessage } from "../../utils/slate";
import MessageEditor from "../new-editor/MessageEditor";
import { ReactComponent as QuestionIcon } from "../../icons/QuestionCircleFilled.svg";
import { Tooltip } from "../info/Tooltip";

import { ClientDto } from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import MessagesService from "../../services/MessagesService";
import { MESSAGES_THREAD_QUERY_KEY } from "../screen/MessagingScreen";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: 650,
    height: 600,
    borderRadius: theme.spacing(1.5),
  },

  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  divider: {
    width: polyfillCSS(`calc(100% + calc(${theme.spacing(3)} * 2))`),
    margin: theme.spacing(3, 0, 3, -3),
    backgroundColor: colorSystem.secondaryGray,

    "&:nth-child(1)": {
      marginTop: 0,
    },
  },

  recipients: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    padding: theme.spacing(1.75, 2),
    display: "flex",
    alignItems: "center",
  },

  recipient: {
    display: "flex",
    alignItems: "center",
    background: hexToRgbA(theme.palette.quote, 0.2),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(0.5),

    maxWidth: theme.spacing(20),
    marginRight: theme.spacing(2),
    height: theme.spacing(5.25),
    paddingLeft: theme.spacing(0.5),
  },

  username: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 500,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(0.75),
    overflow: "hidden",
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: theme.palette.text.primary,
  },

  avatar: {
    borderRadius: 2.6,

    width: `${theme.spacing(4)} !important`,
    height: `${theme.spacing(4)} !important`,
  },

  editor: {
    height: theme.spacing(36),
    marginBottom: theme.spacing(2),
    outlineColor: theme.palette.quote,
  },

  buttons: {
    display: "flex",
    marginTop: "auto",
  },

  sendMessage: {
    padding: theme.spacing(1.5),
  },

  helpIcon: {
    marginLeft: "auto",
  },

  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[7],
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.5, 2.25),
  },

  tooltipArrow: {
    color: theme.palette.background.paper,
  },
}));

export interface CoachMessageClientsDialogProps extends BaseDialogProps {
  clients: ClientDto[];
  showMaxClients?: number;
  showMaxRestClients?: number;
  onMessagesSent?: () => void;
}

export function CoachMessageClientsDialog(
  props: CoachMessageClientsDialogProps,
) {
  const s = useStyles();
  const {
    className,
    clients,
    showMaxClients = 2,
    showMaxRestClients = 25,
    onMessagesSent,
    ...other
  } = props;
  const queryClient = useQueryClient();
  const { mutate: createMessages, isPending: createMessagesInFlight } =
    useMutation({
      mutationFn: MessagesService.CreateManyMessages,
      onSuccess: (_date, { recipients }) => {
        recipients.forEach((recipient) => {
          queryClient.invalidateQueries({
            queryKey: [MESSAGES_THREAD_QUERY_KEY, { recipient }],
          });
        });
      },
    });
  const buttonRef = React.useRef<HTMLDivElement>();

  const handleMessageSubmit = React.useCallback(
    (messageContent, callback) => {
      createMessages(
        {
          recipients: clients.map(({ username }) => username),
          content: JSON.stringify(messageContent),
        },
        {
          onSuccess: () => {
            onMessagesSent?.();
            callback?.();
          },
        },
      );
    },
    [clients, createMessages, onMessagesSent],
  );

  const showClients = clients.filter((_, index) => index < showMaxClients);
  const restClients = clients.filter((_, index) => index >= showMaxClients);

  const initialMessage = [createTextMessage("Hi {{First Name}}, ")];

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      classes={{
        paper: s.paper,
        container: s.container,
      }}
      title="New message"
      {...other}
    >
      <Box className={s.body}>
        <Divider className={s.divider} />
        <Box className={s.recipients}>
          {showClients.map((client) => (
            <Box key={client.id} className={s.recipient}>
              {client.photoUrl && (
                <Avatar className={s.avatar} src={client.photoUrl} alt="" />
              )}
              <Typography component="div" className={clsx(s.username, s.text)}>
                {client.displayName || client.username}
              </Typography>
            </Box>
          ))}

          {restClients.length > 0 && (
            <Tooltip
              placement="top"
              classes={{ tooltip: s.tooltip, arrow: s.tooltipArrow }}
              title={
                <>
                  {restClients
                    .filter((_, index) => index < showMaxRestClients)
                    .map((client) => (
                      <Typography key={client.id} className={s.text}>
                        {client.displayName || client.username}
                      </Typography>
                    ))}

                  {restClients.length > showMaxRestClients && (
                    <Typography className={s.text}>
                      and {restClients.length - showMaxRestClients} more
                    </Typography>
                  )}
                </>
              }
            >
              <Typography className={s.text}>
                and {maybePluralize(restClients.length, "other")}
              </Typography>
            </Tooltip>
          )}

          <Tooltip
            title="Each message will be sent separately. Recipients will not see each other."
            placement="top"
          >
            <QuestionIcon className={s.helpIcon} />
          </Tooltip>
        </Box>

        <Divider className={s.divider} />
        <MessageEditor
          classes={{
            container: s.editor,
          }}
          buttonRef={buttonRef}
          backupKeyType="message-clients"
          placeholder="Type your message"
          message={initialMessage}
          onMessageSubmit={handleMessageSubmit}
          disabled={createMessagesInFlight}
          rowsMax={null}
          hideSendButton
          expanded
        />

        <Box className={s.buttons}>
          <div className="w-full" ref={buttonRef}>
            <ActionButton
              className={s.sendMessage}
              disabled={createMessagesInFlight}
              fullWidth
            >
              Send message
            </ActionButton>
          </div>
        </Box>
      </Box>
    </BaseDialog>
  );
}
