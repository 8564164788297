import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { RefreshSlug } from "../routes/RefreshSlug";

import { GroupPostsList } from "./GroupPostsList";
import { GroupPostsSidebar } from "./GroupPostsSidebar";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(3),
    flexDirection: "column",
    justifyContent: "space-evenly",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  posts: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      maxWidth: 730,
    },

    [theme.breakpoints.up("lg")]: {
      maxWidth: 960,
    },
  },

  sidebar: {
    flexShrink: 0,

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(2),
      width: 300,
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(10),
    },
  },
}));

export interface GroupPostsScreenProps
  extends Omit<ContainerProps, "children"> {
  group: GroupDto2;
}

export function GroupPostsScreen(props: GroupPostsScreenProps) {
  const { className, group, ...other } = props;
  const s = useStyles();
  const { membersCanNotSeeSidebar } = group;

  return (
    <Container maxWidth="xl" className={clsx(s.root, className)} {...other}>
      <RefreshSlug
        slugInfo={{
          slug: group?.slug,
          slugId: group?.slugId,
        }}
      />
      <GroupPostsList className={s.posts} groupId={group.id} />
      {!membersCanNotSeeSidebar && (
        <GroupPostsSidebar className={s.sidebar} group={group} />
      )}
    </Container>
  );
}
