import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  TextField,
  Button,
  TextFieldProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  useCreateProgramFolderMutation,
  useUpdateProgramFolderMutation,
} from "./mutations/UpsertProgramFolder";
import { BaseDialog } from "../dialog/BaseDialog";

const COMMON_SPACING = 7;

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
  },

  input: {
    height: theme.spacing(COMMON_SPACING),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

export interface ProgramFolderUpsertDialogProps extends DialogProps {
  folderId?: number;
  folderName?: string;
  onUpserted?: () => void;
}

export function ProgramFolderUpsertDialog(
  props: ProgramFolderUpsertDialogProps,
) {
  const {
    className,
    onClose,
    folderId,
    folderName = "",
    onUpserted,
    ...other
  } = props;
  const s = useStyles();
  const [name, setName] = React.useState(folderName);
  const { mutate: updateFolder, isPending: updating } =
    useUpdateProgramFolderMutation();
  const { mutate: createFolder, isPending: creating } =
    useCreateProgramFolderMutation();
  const inFlight = updating || creating;

  const handleNameChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => setName(event.target.value),
    [],
  );

  const resetState = React.useCallback(() => {
    setName("");
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleCreate = React.useCallback(() => {
    createFolder(
      {
        name,
      },
      {
        onSuccess: () => {
          handleClose();
          onUpserted?.();
        },
      },
    );
  }, [handleClose, name, onUpserted, createFolder]);

  const handleUpdate = React.useCallback(() => {
    updateFolder(
      {
        id: folderId,
        name,
      },
      {
        onSuccess: () => {
          handleClose();
          onUpserted?.();
        },
      },
    );
  }, [folderId, handleClose, name, onUpserted, updateFolder]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`${folderId ? "Rename" : "Create"} folder`}
      closeDisabled={inFlight}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <Box className={s.wrapper}>
        <TextField
          className={s.input}
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleNameChange}
          label="Folder name"
        />
      </Box>
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={`${folderId ? "Save" : "Create folder"}`}
        onClick={folderId ? handleUpdate : handleCreate}
        disabled={!name || inFlight}
      />
    </BaseDialog>
  );
}
