import React from "react";
import { useSearchParams } from "react-router-dom";
import { AppLayout } from "../../../../components/app/AppLayout";
import { AppThemeProvider } from "../../../../components/app/ThemeProvider";
import { ClientAcceptInviteScreen } from "../../../../components/auth/ClientAcceptInviteScreen";
import { InviteInfoDto } from "@growth-machine-llc/stridist-api-client";
import { useQuery } from "@tanstack/react-query";
import InvitesService from "../../../../services/InvitesService";
import { LoadingPage } from "../../../../components/loading/LoadingPage";

export function SignUpClientRoute() {
  const [searchParams] = useSearchParams();

  const oobCode = searchParams.get("oobCode");
  const id = searchParams.get("id");
  const email = searchParams.get("email");
  const requestWorkspace = searchParams.get("workspace");

  const { data: invite, isLoading } = useQuery({
    queryKey: ["signup-client-resolve-invite"],
    queryFn: () => InvitesService.resolveInvite(id ?? "", requestWorkspace),
    enabled: !!id && !!requestWorkspace,
  });
  const { workspaceInfo: workspace } = invite || {};

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <AppLayout title="Sign Up" appBar={false}>
          <AppThemeProvider workspace={workspace}>
            <ClientAcceptInviteScreen
              invite={invite}
              code={oobCode}
              id={id}
              email={email}
            />
          </AppThemeProvider>
        </AppLayout>
      )}
    </>
  );
}
