import React from "react";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import AppLayout from "../../../components/app/AppLayout";
import { ArchivedClientScreen } from "../../../components/auth/ArchivedClientScreen";

export function ClientArchivedRoute() {
  const me = useCurrentUser();

  return (
    me && (
      <AppLayout appBar={false}>
        <ArchivedClientScreen />
      </AppLayout>
    )
  );
}
