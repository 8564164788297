import clsx from "clsx";
import React, { useContext } from "react";
import { Container, ContainerProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CompactDateDialog } from "../dialog/CompactDateDialog";

import { ClientMealLoggingEntriesList } from "./ClientMealLoggingEntriesList";
import { useNavigate } from "react-router-dom";
import { CLIENT_MEAL_LOGGING_ROUTE } from "../../routes/routes";
import CompactDateDialogContext from "../../contexts/CompactDateDialogContext";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
  },

  date: {
    cursor: "pointer",
  },
}));

export interface ClientMealLoggingScreenProps
  extends Omit<ContainerProps, "children"> {
  date: string;
}

export function ClientMealLoggingScreen(props: ClientMealLoggingScreenProps) {
  const s = useStyles();
  const navigate = useNavigate();
  const { className, date, ...other } = props;

  const context = useContext(CompactDateDialogContext);

  const handleDateDialogClose = React.useCallback(() => {
    context.setDateDialogOpen(false);
  }, []);

  const handleDateChange = React.useCallback((date: string) => {
    context.setDateDialogOpen(false);
    navigate(CLIENT_MEAL_LOGGING_ROUTE.replace(":date", date));
  }, []);

  return (
    <>
      <Container className={clsx(s.root, className)} maxWidth="xs" {...other}>
        <ClientMealLoggingEntriesList
          date={date}
        />
      </Container>
      {context.dateDialogOpen && (
        <CompactDateDialog
          open
          value={date}
          onClose={handleDateDialogClose}
          onDateChange={handleDateChange}
        />
      )}
    </>
  );
}
