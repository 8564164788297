import React from "react";

import { deepCompare } from "../components/editor/utils/common";

export type LocalStorageObjectType =
  | "feedback"
  | "group-post-title"
  | "group-post-message"
  | "group-post-comment"
  | "activity"
  | "message"
  | "message-clients"
  | "scheduled-message"
  | "user-notes"
  | "client-form"
  | "nutrition-tracking";

const typesRequireId: LocalStorageObjectType[] = [
  "feedback",
  "group-post-comment",
  "activity",
  "message",
];

export function getLocalStorageKey(
  type: LocalStorageObjectType,
  id?: string,
): string | null {
  if (!id && typesRequireId.includes(type)) {
    console.warn(
      "getLocalStorageKey:",
      `id argument not given, but required to return local storage key for type ${type}`,
    );

    return null;
  }

  return `stridist:${btoa(`${type}${id || "new"}`)}`;
}

export const getFromLocalStorage = <T>(key: string, initialValue: T) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (err) {
    console.error(err);
    return initialValue;
  }
};

export const getLocalStoreValue = <T>(key: string, initialValue?: T): T => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (err) {
    console.error(err);
    return initialValue;
  }
};

export const containsKeyInLocalStorage = (key: string) => {
  try {
    const item = window.localStorage.getItem(key);
    return item !== null;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedKey, setStoredKey] = React.useState(key);
  const [storedValue, setStoredValue] = React.useState<T>(
    getLocalStoreValue(key, initialValue),
  );

  const setValue: typeof setStoredValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      if (storedValue !== valueToStore) {
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    if (storedKey !== key) {
      setStoredKey(key);
      setStoredValue(getLocalStoreValue(key, initialValue));
    }
  }, [initialValue, key, storedKey]);

  return [storedValue || initialValue, setValue] as const;
};

const nope = () => null;

export function useBackupState<T = any>(
  type: LocalStorageObjectType,
  id?: string | number,
  initialValue?: T,
  enabled = true,
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
  const lsKey = getLocalStorageKey(
    type,
    typeof id === "number" ? id.toString() : id,
  );
  const [lsValue, setLsValue] = useLocalStorage(lsKey, initialValue);
  const [stateValue, setStateValue] = React.useState(initialValue);
  const value = enabled ? lsValue : stateValue;
  const setValue = enabled ? setLsValue : setStateValue;

  const removeLsBackup = React.useCallback(() => {
    setValue(null);
  }, [setValue]);

  const removeBackup = enabled ? removeLsBackup : nope;

  React.useEffect(() => {
    if (!enabled || !value || deepCompare(value, initialValue)) {
      removeBackup();
    }
  }, [value, enabled, initialValue, removeBackup, id]);

  return [value, setValue, removeBackup];
}
