import { useCurrentUser } from "../../../hooks/useCurrentUser";
import MessagesService from "../../../services/MessagesService";
import {
  IThreadDto,
  MessageDirection,
  MessageDto,
} from "@growth-machine-llc/stridist-api-client";
import { MESSAGES_THREAD_QUERY_KEY } from "../../screen/MessagingScreen";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import dayjs from "dayjs";

/**
 * Custom hook for creating a new message mutation.
 * This hook performs optimistic updates to the cache by adding the new message to the thread before the mutation is complete.
 * If the mutation fails, the cache is rolled back to the previous state.
 *
 * @param recipient - The recipient of the message to generate the query key for caching.
 */
export default function useCreateMessageMutation(recipient?: string) {
  const { email, displayName, photoUrl } = useCurrentUser() || {};

  return useOptimisticUpdateMutation({
    mutationFn: MessagesService.createMessage,
    queryKey: [MESSAGES_THREAD_QUERY_KEY, { recipient }],
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: IThreadDto, { content }, tempId) => {
        oldData.messages.push(
          MessageDto.fromJS({
            id: tempId,
            content,
            direction: MessageDirection.OUTGOING,
            formattedDate: dayjs().format("dddd DD MMM YYYY"),
            messageAuthor: {
              email,
              displayName,
              photoUrl,
            },
          }),
        );
        return oldData;
      },
    },
    successUpdater: {
      updateFn: (oldData: IThreadDto, newMessage, tempId) => {
        const messageIndex = oldData.messages.findIndex(
          (message) => message.id === tempId,
        );
        if (messageIndex !== -1) {
          oldData.messages[messageIndex] = newMessage;
        }
        return oldData;
      },
    },
  });
}
