import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  Card,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { sortBy } from "lodash";

import { useQueryParam } from "../../hooks/useQueryParam";

import {
  getWeekLabel,
  getWeekISODates,
  ISO_DATE_FORMAT,
} from "../../utils/date";
import { ClientCircularProgress } from "../progress/ClientCircularProgress";
import { ActivityByTypesList } from "../activity/ActivityByTypesList";
import { EnrollmentStatus } from "../../constants";
import { DropdownMenuItem, DropdownMenu } from "../menu/DropdownMenu";
import { EditorValue } from "./EditorValue";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { ProgramWeeksDto } from "@growth-machine-llc/stridist-api-client";
import { useClient } from "../../hooks/useClient";
import { useCoachClientPrograms } from "../../routes/coach/client/programs/CoachClientProgramsRouteWrapper";
import { ActivityByTypesListSkeleton } from "../activity/ActivityByTypesListSkeleton";
import { HabitCheckInProgress } from "./HabitCheckInProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },

  label: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },

  description: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(4),
    },
  },

  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 0),
    },
  },

  progress: {
    position: "absolute",
    top: 0,
    left: 5,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  circularProgress: {
    marginLeft: theme.spacing(3.75),
    alignSelf: "start",
  },

  card: {
    padding: theme.spacing(0, 3.25, 0, 2.75),
  },
}));

export interface CoachClientProgramProps
  extends Omit<ContainerProps, "children"> {
  userName?: string;
  programIsLoading: boolean;
  program?: ProgramWeeksDto;
}

export const COACH_CLIENT_ENROLLMENT_WEEK_QUERY_KEY =
  "coach-client-enrollment-week";

// TODO_API_V2_COACH_CLIENT_PROGRAM_COMPONENT STR-1359: Consider on returning Overview option (related & dropped from here component: HabitCheckInProgressTable)
export function CoachClientProgram(props: CoachClientProgramProps) {
  const { className, userName, program, programIsLoading, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [weekQueryParam, setWeekQueryParam] = useQueryParam("week", null);
  const selectedWeek = weekQueryParam ? parseInt(weekQueryParam) : null;
  const { id } = useClient();

  const { enrollments, isLoading: programsQueryIsFetching } =
    useCoachClientPrograms();

  const { data: enrollment, isLoading: loadingWeek } = useQuery({
    queryKey: [
      COACH_CLIENT_ENROLLMENT_WEEK_QUERY_KEY,
      { selectedWeek, program: program?.slug, client: id },
    ],
    queryFn: () =>
      EnrollmentsClientService.getClientEnrollmentWeek(
        program.slug,
        selectedWeek,
        id,
      ),
    enabled: program !== undefined,
  });

  const selectedProgramWeek = program?.weeks[selectedWeek];

  const weekDates = React.useMemo(() => {
    if (selectedWeek) {
      return getWeekISODates(
        enrollment?.startDate.format(ISO_DATE_FORMAT),
        selectedWeek,
      );
    }
  }, [enrollment?.startDate, selectedWeek]);

  const progress = enrollment?.completionRate;

  const handleProgramDropdownClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const { slug } = event.currentTarget.dataset;

      navigate(
        location.pathname.replace(/\/programs\/.*/, "/programs/" + slug),
      );
    },
    [],
  );

  const handleWeekDropdownClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      setWeekQueryParam(event.currentTarget.dataset?.week);
    },
    [setWeekQueryParam],
  );

  const getItemsByStatus = (status: EnrollmentStatus) => {
    return enrollments.filter((en) => en.status === status);
  };

  const items = React.useMemo(() => {
    if (enrollments)
      return {
        [EnrollmentStatus.ACTIVE]: getItemsByStatus(EnrollmentStatus.ACTIVE),
        [EnrollmentStatus.UPCOMING]: getItemsByStatus(
          EnrollmentStatus.UPCOMING,
        ),
        [EnrollmentStatus.PAST]: getItemsByStatus(EnrollmentStatus.PAST),
      };
  }, [enrollments]);

  const programItems: DropdownMenuItem[] = React.useMemo(() => {
    if (enrollments)
      return Object.entries(items)
        .filter(([, enrollments]) => enrollments.length)
        .map(([group, enrollments]) => {
          const header = {
            variant: "header",
            children: formatEnrollmentStatus(group as EnrollmentStatus),
          } as const;

          const programs = enrollments.map((enrollment) => ({
            children: enrollment.program?.name || "Unnamed Program",
            onClick: handleProgramDropdownClick,
            selected: program?.slug === enrollment.program?.slug,
            data: { slug: enrollment.program?.slug },
          }));

          return [header, ...programs];
        })
        .flat();
  }, [handleProgramDropdownClick, items, program?.slug]);

  const weekItems: DropdownMenuItem[] = React.useMemo(() => {
    const overview = {
      children: "Overview",
      onClick: handleWeekDropdownClick,
      selected: !weekQueryParam,
    };
    const _weeks = [...Array(program?.weeks?.length)]
      .map((_, index) => String(index + 1))
      .map((week) => ({
        children: `Week ${week}`,
        onClick: handleWeekDropdownClick,
        selected: week === weekQueryParam,
        data: { week },
      }));

    return [overview, ..._weeks];
  }, [handleWeekDropdownClick, program?.weeks?.length, weekQueryParam]);

  const weekActivities = React.useMemo(
    () => sortBy(enrollment?.activities, (it) => it.date),
    [enrollment?.activities],
  );

  const loadingHeader = programsQueryIsFetching || programIsLoading;
  const isLoading = loadingWeek || loadingHeader;

  return (
    <>
      <Container className={clsx(s.root, className)} {...other}>
        {!loadingHeader ? (
          <DropdownMenu
            menuId="select-program"
            header={program?.name}
            variant="big"
            items={programItems}
          />
        ) : (
          <Skeleton variant="text" width={200} height={55} />
        )}

        <Box className={s.header}>
          <Box>
            {!loadingHeader ? (
              <DropdownMenu
                menuId="select-week"
                header={weekQueryParam ? `Week ${weekQueryParam}` : "Overview"}
                subHeader="Go to"
                variant="normal"
                items={weekItems}
              />
            ) : (
              <Skeleton variant="text" width={250} height={40} />
            )}
            {(!weekQueryParam ? loadingHeader : isLoading) ? (
              <Skeleton variant="text" width={200} height={30} sx={{ mt: 1 }} />
            ) : (
              <Typography variant="body1" className={s.label}>
                {weekDates
                  ? getWeekLabel(weekDates[0], weekDates[6], true)
                  : `${program.length} week program`}
              </Typography>
            )}
          </Box>

          {selectedProgramWeek && (
            <Typography
              variant="body1"
              component="div"
              className={s.description}
            >
              <EditorValue value={selectedProgramWeek.description} />
            </Typography>
          )}

          {selectedWeek && !isLoading && (
            <ClientCircularProgress
              className={s.circularProgress}
              value={progress}
              size="normal"
            >
              <Typography
                className={s.progress}
                variant="h5"
                component="div"
                children={`${progress}%`}
              />
            </ClientCircularProgress>
          )}
        </Box>
        {selectedWeek ? (
          <Card className={s.card}>
            {loadingWeek || isLoading ? (
              <ActivityByTypesListSkeleton />
            ) : (
              <ActivityByTypesList
                userName={userName}
                activities={weekActivities}
                variant="compact"
                clientsScreen
                idProgram={String(program?.id)}
              />
            )}
          </Card>
        ) : (
          <HabitCheckInProgress programSlug={program?.slug} clientId={id} />
        )}
      </Container>
    </>
  );
}

export const formatEnrollmentStatus = (status: EnrollmentStatus): string => {
  switch (status) {
    case EnrollmentStatus.ACTIVE:
      return "Active";
    case EnrollmentStatus.UPCOMING:
      return "Up Next";
    case EnrollmentStatus.PAST:
      return "Past";
  }
};
