import { createContext } from "react";
import { IPaginatedListOfGroupDto } from "@growth-machine-llc/stridist-api-client";

export interface IGroupsContextProps {
  coachGroups: IPaginatedListOfGroupDto;
  setCoachGroups: (coachGroups: IPaginatedListOfGroupDto) => void;
}

const GroupsContext = createContext<IGroupsContextProps | undefined>(undefined);

export default GroupsContext;
