import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChevronLeft } from "@mui/icons-material";

import { NavigationItem } from "../../constants";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2.5),

    "& .MuiSvgIcon-root": {
      fill: theme.palette.common.black,
    },
  },

  breadcrumb: {
    cursor: "pointer",
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.common.black,
  },
}));

export interface AdminBreadcrumbsProps extends BoxProps {
  breadcrumbs?: NavigationItem[];
}

export function AdminBreadcrumbs(props: AdminBreadcrumbsProps) {
  const { className, breadcrumbs, ...other } = props;
  const s = useStyles();
  const navigate = useNavigate();

  return (
    Boolean(breadcrumbs?.length) && (
      <Box className={clsx(s.root, className)} {...other}>
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            <ChevronLeft />
            <Link
              underline="none"
              children={
                <Typography
                  className={s.breadcrumb}
                  component="span"
                  variant="body1"
                  children={breadcrumb.name}
                />
              }
              onClick={() => navigate(breadcrumb.link)}
            />
          </React.Fragment>
        ))}
      </Box>
    )
  );
}
