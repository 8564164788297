import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { createEmptyExercise } from "../../workout/utils";
import { ElementType } from "../../editor/types/elements";
import { isEqual, omit } from "lodash";
import MinimizedTooltip, { TooltipLine } from "../../tooltip/MinimizedTooltip";
import { useCurriculumDispatch } from "../../../redux/hooks";
import { publishComponent as publishComponentAction } from "../../../redux/curriculum/curriculum-slice";
interface IPublishButtonProps {
  componentId: number;
  componentContent: any;
  exercise: string;
  className?: string;
}

const PublishButton = (props: IPublishButtonProps) => {
  const { componentId, componentContent, exercise, className } = props;
  const dispatch = useCurriculumDispatch();

  const publishComponent = (
    componentId: number,
    componentContent: any,
    componentTitle: string,
  ) => {
    dispatch(
      publishComponentAction({
        componentId,
        title: componentTitle,
        content: JSON.stringify(componentContent),
      }),
    );
  };

  const handlePublish = (
    componentId: number,
    componentContent: any,
    componentTitle: string,
  ) => {
    const emptyExerciseTemplate = omit(createEmptyExercise(), [
      "id",
      "superset",
    ]);

    const filteredComponentContent = componentContent?.map((element) => {
      if (element.type === ElementType.WORKOUT_SECTION) {
        const filteredExerciseArr = element?.workout?.exercises
          .map((e) => {
            const filteredExercise = omit(e, ["id", "superset"]);
            if (!isEqual(emptyExerciseTemplate, filteredExercise)) return e;
            return null;
          })
          .filter((e) => e !== null);

        const resultElement = {
          ...element,
          workout: {
            ...element.workout,
            exercises: filteredExerciseArr,
          },
        };
        return resultElement;
      }
      return element;
    });

    publishComponent(componentId, filteredComponentContent, componentTitle);
  };

  return (
    <>
      <Box
        component={"span"}
        sx={{
          width: "100%",
          display: "flex",
        }}
      >
        <Button
          className={className}
          variant="contained"
          size="small"
          fullWidth
          onClick={() => {
            handlePublish(componentId, componentContent, exercise);
          }}
          sx={{
            marginBlock: 1,
            borderRadius: 1,
            fontSize: "12px",
          }}
        >
          Publish
        </Button>
      </Box>
    </>
  );
};

export default PublishButton;
