import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { usePreview } from "../../hooks/usePreview";
import { colorSystem } from "../../theme";
import { PREVIEW_BAR_SPACING } from "./PreviewBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colorSystem.secondaryGray,
    borderRadius: theme.spacing(3),
    width: "max-content",
    margin: 0,
    position: "relative",
    marginBlock: "auto",

    "& iframe": {
      border: 0,
      backgroundColor: theme.palette.background.paper,
    },

    "&$mobile": {
      padding: theme.spacing(5.5, 1),

      "& iframe": {
        width: 270,
        height: 475,
      },
    },

    "&$tablet": {
      padding: theme.spacing(3),

      "&::after": {
        width: 980,
        left: -60,
      },

      "& iframe": {
        width: 540,
        height: 720,
      },
    },

    "&$web": {
      backgroundColor: "initial",
      borderRadius: 0,
      width: "100%",
      height: `calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`,
      transform: "none",

      "&::after": {
        display: "none",
      },

      "& iframe": {
        width: "100%",
        height: "100%",
      },
    },
  },

  mobile: {},
  tablet: {},
  web: {},
}));

export interface PreviewBoxProps extends BoxProps {
  src: string;
}

export function PreviewBox(props: PreviewBoxProps) {
  const { className, src, ...other } = props;
  const s = useStyles();
  const [preview] = usePreview();

  return (
    <Box
      className={clsx(s.root, s[preview.toLowerCase()], className)}
      children={<iframe src={src} title="Preview" />}
      {...other}
    />
  );
}
