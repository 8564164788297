import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useUploadFile } from "../../hooks/useUploadFile";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { AssetType, SOMETHING_WENT_WRONG } from "../../constants";
import { CardMedia } from "../card/CardMedia";
import { ReactComponent as UploadIcon } from "../../icons/TakingPicturesCircle.svg";
import { useNativeDropzone } from "../../utils/device";
import { hexToRgbA } from "../../utils/css";
import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(24),
  },

  empty: {},

  filled: {
    overflow: "hidden",
    borderWidth: 1,
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.quote,
    borderStyle: "dashed",
  },

  button: {
    height: "100%",

    borderWidth: 1,
    borderRadius: theme.spacing(1),
    backgroundColor: hexToRgbA(theme.palette.primary.main, 0.125),
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: hexToRgbA(theme.palette.primary.main, 0.5),
    },
  },

  label: {
    display: "block",
  },

  buttonText: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
  },
}));

export interface ClientMealLoggingUploadPhotoProps
  extends Omit<ButtonProps, "onChange"> {
  value?: string;
  onChange?: (value: string) => void;
}

export function ClientMealLoggingUploadPhoto(
  props: ClientMealLoggingUploadPhotoProps,
) {
  const { className, onChange, value, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const [uploadFile, _, isUploading] = useUploadFile({
    id: user.id,
    refType: "User",
    getAssetType: () => AssetType.MEAL_PHOTO,
    onUpload: ({ url }) => {
      if (onChange) {
        onChange(url);
      }
    },
  });

  const onUpload = React.useCallback(
    (file) => {
      uploadFile(file);
    },
    [onChange, uploadFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useNativeDropzone({
    onDrop: onUpload,
    accept: getMimeTypes(ElementType.IMAGE),
  });
  return (
    <div
      {...(getRootProps() as any)}
      className={clsx(s.root, className, {
        [s.filled]: Boolean(value),
      })}
    >
      <input type="file" name="logoURL" {...getInputProps()} />
      {value ? (
        <CardMedia image={value} />
      ) : (
        <Button
          variant="contained"
          color="primary"
          classes={{ root: s.button }}
          fullWidth
          {...other}
        >
          <Typography component="span" className={s.buttonText}>
            {isUploading ? (
              "Uploading..."
            ) : isDragActive ? (
              "Drop the file here..."
            ) : (
              <UploadIcon />
            )}
          </Typography>
        </Button>
      )}
    </div>
  );
}
