import { call, put, select } from "redux-saga/effects";
import { TempIdsMap, updateTempIdMap } from "../../../../api/api-slice";
import ComponentsService from "../../../../../services/ComponentsService";
import {
  selectComponentTempIdsMap,
  selectWeekTempIdsMap,
} from "../../../../api/selectors";
import { CreatedComponentResult } from "@growth-machine-llc/stridist-api-client";
import { setComponentSlug } from "../../../curriculum-slice";

export default function* pasteComponentWorker(payload: {
  weekId: number;
  componentId: number;
  day: number;
  newId: number;
}) {
  const { weekId, componentId, day, newId: tempId } = payload;
  const weekMap: TempIdsMap = yield select(selectWeekTempIdsMap);
  const componentMap: TempIdsMap = yield select(selectComponentTempIdsMap);
  const checkedWeekId = weekMap?.[weekId] || weekId;
  const checkedComponentId = componentMap?.[componentId] || componentId;
  try {
    const res: CreatedComponentResult = yield call(
      ComponentsService.paste,
      checkedComponentId,
      checkedWeekId,
      day,
    );
    const realId = res.id;
    yield put(
      updateTempIdMap({
        type: "components",
        tempId: tempId.toString(),
        realId,
      }),
    );
    yield put(setComponentSlug([{ id: tempId, slug: res.slug }]));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
