import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const updateComponentWeekAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    newWeekId: number;
  }>,
) => {
  const { componentId, newWeekId } = action.payload;
  const component = state.value.components[componentId];
  if (!component) return;

  state.value.weeks[newWeekId].components.push(componentId);
  state.value.weeks[newWeekId].positions.push(componentId.toString());
  state.value.weeks[component.weekId].components = state.value.weeks[
    component.weekId
  ].components.filter((id) => id !== componentId);
  state.value.weeks[component.weekId].positions = state.value.weeks[
    component.weekId
  ].positions.filter((id) => id !== componentId.toString());

  state.value.components[componentId] = {
    ...component,
    weekId: newWeekId,
  };
};
