import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, ContainerProps } from "@mui/material";

import LoadingActionButton, { LoadingButtonActionProps } from "../button/LoadingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.sm,
  },

  wrapper: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.up("llg")]: {
      left: 260,
    },

    '[role="dialog"] &': {
      left: 0,
    },
  },
}));

export interface ClientMealLoggingButtonProps
  extends LoadingButtonActionProps,
    Pick<ContainerProps, "maxWidth"> {
  wrapperClass?: string;
}

export function ClientMealLoggingButton(props: ClientMealLoggingButtonProps) {
  const { className, maxWidth = "xs", wrapperClass, ...other } = props;
  const s = useStyles();

  return (
    <Container maxWidth={maxWidth} className={clsx(s.wrapper, wrapperClass)}>
      <LoadingActionButton
        className={clsx(s.root, className)}
        size="large"
        fullWidth
        {...other}
      >
        Log a meal
      </LoadingActionButton>
    </Container>
  );
}
