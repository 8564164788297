import React from "react";
import { AppLayout } from "../../../../components/app/AppLayout";
import { SignupClientCompleteScreen } from "../../../../components/screen/SignupClientCompleteScreen";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_ROUTE } from "../../../routes";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useCurrentWorkspace } from "../../../../hooks/useCurrentWorkspace";

export function SignUpCompleteRoute() {
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");

  const me = useCurrentUser();
  const workspace = useCurrentWorkspace();

  return (
    me && (
      <AppLayout title="Tell us about yourself" appBar={false}>
        <SignupClientCompleteScreen
          returnUrl={returnUrl || DEFAULT_ROUTE}
          user={me}
          workspace={workspace}
        />
      </AppLayout>
    )
  );
}
