import { invitesClient } from "../api/ApiClients";
import {
  BatchInviteClientsCommand,
  ClientLinkInviteCommand,
  IBatchInviteClientsCommand,
  IClientLinkInviteCommand,
  IInviteAddedClientsCommand,
  InviteAddedClientsCommand,
  IResendInviteCommand,
  ResendInviteCommand,
  ValidateInviteCodeCommand,
} from "@growth-machine-llc/stridist-api-client";

import ServiceBase from "./ServiceBase";

class InvitesService extends ServiceBase {
  async resolveInvite(fbUserId: string, workspaceSlug: string) {
    return invitesClient.resolveInvite(fbUserId, workspaceSlug);
  }

  async verifyInviteCode(code: string) {
    const command = ValidateInviteCodeCommand.fromJS({ code });
    return invitesClient.verifyInviteCode(command);
  }

  async inviteClientByCode(variables: IClientLinkInviteCommand) {
    const command = ClientLinkInviteCommand.fromJS(variables);
    return invitesClient.clientLinkInvite(command);
  }

  async batchInviteClientsByCode(variables: IBatchInviteClientsCommand) {
    const command = BatchInviteClientsCommand.fromJS(variables);
    return invitesClient.batchInviteClients(command);
  }

  async resendInvite(variables: IResendInviteCommand) {
    const command = ResendInviteCommand.fromJS(variables);
    return invitesClient.resendInvite(command);
  }

  async revokeInvite(id: number) {
    return invitesClient.revokeInvite(id);
  }

  async inviteAddedClients(data: IInviteAddedClientsCommand) {
    const command = InviteAddedClientsCommand.fromJS(data);
    return invitesClient.inviteAddedClients(command);
  }
}

export default new InvitesService();
