import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProgressLine } from "../progress/ProgressLine";
import { ClientSmallProgramCard } from "./ClientSmallProgramCard";
import { ClientEnrollmentDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  content: {
    margin: theme.spacing(0.25, 0, 1),
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.75),
  },
}));

export interface ClientPastProgramCardProps {
  enrollment: ClientEnrollmentDto;
}

export function ClientPastProgramCard(props: ClientPastProgramCardProps) {
  const { enrollment, ...other } = props;
  const s = useStyles();
  const {
    totalDays,
    completion: { completed, total, rate },
  } = enrollment;

  const endDateLong = enrollment.endDate.format("MMMM D, YYYY");

  const subtitle = React.useMemo(() => {
    const length = `${totalDays} day${totalDays > 1 ? "s" : ""}`;
    return (
      <>
        {length} &bull; Ended {endDateLong}
      </>
    );
  }, [totalDays, endDateLong]);

  const content = React.useMemo(
    () => (
      <Box className={s.content}>
        <Typography className={s.text}>
          {completed} of {total} items completed
        </Typography>
        <ProgressLine value={rate} />
      </Box>
    ),
    [completed, total, rate], // eslint-disable-line
  );

  return (
    <ClientSmallProgramCard
      enrollment={enrollment}
      subtitle={subtitle}
      content={content}
      {...other}
    />
  );
}
