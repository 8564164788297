import clsx from "clsx";
import React, { useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CardProps,
  Select,
  SelectProps,
  Typography,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  billingPlanPrice,
  Plan,
  planClients,
  planStatus,
  PlanTier,
  SOMETHING_WENT_WRONG,
  tierIndex,
} from "../../constants";

import { useQueryParam } from "../../hooks/useQueryParam";
import { initStripe } from "../../stripe";
import { LinkButton } from "../button/LinkButton";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../hooks/useCurrentUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import BillingsService from "../../services/BillingsService";
import { COACH_PLAN_ROUTE } from "../../routes/routes";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3),

    [theme.breakpoints.up("md")]: {
      width: 562,
      padding: theme.spacing(6, 5),
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },

  field: {
    marginBottom: theme.spacing(3),

    "& > $label": {
      marginBottom: theme.spacing(2),
    },
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },

  billingPlans: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
  },

  billingPlan: {
    display: "none",
  },

  save: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: 0.75,
    lineHeight: "16px",
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.progress.green,
    backgroundColor: "#E5F6EC", // since alpha makes it transparent
    textAlign: "center",
    width: 114,
    height: 30,
    position: "absolute",
    top: -15,
    left: theme.spacing(3),
  },

  price: {
    marginTop: theme.spacing(2),
  },

  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  amount: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  unit: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    letterSpacing: 0.75,
  },

  totalWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  totalDescription: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },

  changeCard: {
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "underline",
  },

  changeCardBox: {
    position: "relative",
    top: 20,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },

  button: {
    height: 56,
    padding: theme.spacing(2),
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.common.white,
      opacity: 0.5,
    },

    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  titleModal: {
    lineBreak: "anywhere",
  },
}));

export interface ProUpgradeCardProps extends CardProps {
  user: UserInfo;
  onPlanChange?: (plan: Plan) => void;
  setShowTrialingDialogOpen?: (value) => void;
}

enum UpgradeStatus {
  SUCCESS = "success",
  ERROR = "error",
}
export function ProUpgradeCard(props: ProUpgradeCardProps) {
  const { className, user, onPlanChange, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [newPlan] = useQueryParam("plan", "");
  const [planUpdateStatus] = useQueryParam<UpgradeStatus>("upgrade");
  const { showToastAlert } = useToastAlert();

  const [selectedPlan, setSelectedPlan] = React.useState<Plan>(
    Plan[user.plan] === Plan.FREE ||
      Plan[user.plan] === Plan.PRO ||
      Plan[user.plan] === Plan.STARTER ||
      Plan[user.plan] === Plan.UP_TO_5 ||
      Plan[user.plan] === Plan.UP_TO_15 ||
      Plan[user.plan] === Plan.UP_TO_30 ||
      Plan[user.plan] === Plan.UP_TO_75 ||
      Plan[user.plan] === Plan.UP_TO_INFINITY
      ? Plan[newPlan || Plan.UP_TO_UNLIMITED]
      : Plan[newPlan || user.plan || Plan.UP_TO_UNLIMITED],
  );

  const [billingCycle, setBillingCycle] = React.useState<"monthly" | "annual">(
    user.plan.includes("YEARLY") ? "annual" : "monthly",
  );

  const handleBillingCycleToggle = () => {
    if (!user.plan.includes("YEARLY")) {
      const newBillingCycle = billingCycle === "monthly" ? "annual" : "monthly";
      setBillingCycle(newBillingCycle);

      // Map the selected plan to the corresponding plan in the other list
      let newPlan;
      if (selectedPlan === Plan.UP_TO_UNLIMITED) {
        newPlan =
          newBillingCycle === "annual"
            ? Plan.YEARLY_UNLIMITED
            : Plan.UP_TO_UNLIMITED;
      } else if (selectedPlan === Plan.YEARLY_UNLIMITED) {
        newPlan =
          newBillingCycle === "annual"
            ? Plan.YEARLY_UNLIMITED
            : Plan.UP_TO_UNLIMITED;
      } else {
        newPlan =
          newBillingCycle === "annual"
            ? selectedPlan.replace("UP_TO_", "YEARLY_UP_TO_")
            : selectedPlan.replace("YEARLY_UP_TO_", "UP_TO_");
      }
      setSelectedPlan(newPlan as Plan);
    }
  };

  const [showTrialingDialogOpen, setShowTrialingDialogOpen] =
    React.useState(false);

  const [showMoreClientsDialogOpen, setShowMoreClientsDialogOpen] =
    React.useState(false);

  const [showUpsellYearlyPlan, setShowUpsellYearlyPlan] = React.useState(false);
  const [upsellYearlyFee, setUpsellYearlyFee] = React.useState(0);
  React.useEffect(() => {
    // should we show this modal?
    const shouldShow =
      window.location.href.toLowerCase().includes("promoyearly") &&
      user?.plan.includes("YEARLY") === false &&
      user?.planTier == PlanTier.DEFAULT;
    if (shouldShow) {
      setShowUpsellYearlyPlan(shouldShow);
      // get anual cost
      // Map the selected plan to the corresponding plan in the other list
      let newPlan;
      if (selectedPlan === Plan.UP_TO_UNLIMITED) {
        newPlan = Plan.YEARLY_UNLIMITED;
      } else {
        newPlan = selectedPlan.replace("UP_TO_", "YEARLY_UP_TO_");
      }

      setUpsellYearlyFee(
        billingPlanPrice[newPlan][tierIndex[PlanTier.DEFAULT]],
      );
      setSelectedPlan(newPlan as Plan);
    }
  }, []);

  const cancelYearlyUpsell = () => {
    setSelectedPlan(user?.plan as Plan);
    setShowUpsellYearlyPlan(false);
  };

  const queryClient = useQueryClient();

  const { mutate: createCheckoutSession, isPending: creatingCheckoutSession } =
    useMutation({
      mutationKey: ["create-checkout-session"],
      mutationFn: BillingsService.createCheckoutSession,
    });
  const {
    mutate: updateUserSubscription,
    isPending: updateUserSubscriptionIn,
  } = useMutation({
    mutationKey: ["update-user-subscription"],
    mutationFn: BillingsService.updateUserSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
    },
  });

  const { mutate: getHostedInvoiceUrl, isPending: getHostedInvoiceUrlIn } =
    useMutation({
      mutationKey: ["get-hosted-invoice-url"],
      mutationFn: BillingsService.getHostedInvoiceUrl,
    });

  const { mutate: continueOldPlan, isPending: continueOldPlanIn } = useMutation(
    {
      mutationKey: ["continue-old-plan"],
      mutationFn: BillingsService.continueOldPlan,
    },
  );

  React.useEffect(() => {
    if (planUpdateStatus === UpgradeStatus.SUCCESS) {
      showToastAlert("success", {
        message: "Plan updated successfully",
      });
      const choosePlan =
        user?.subscription?.status === planStatus.TRIALING
          ? ""
          : `&plan=${newPlan}`;
      const path = COACH_PLAN_ROUTE + "?upgraded=true" + choosePlan;
      navigate(path);
    } else if (planUpdateStatus === UpgradeStatus.ERROR) {
      showToastAlert("error", {
        message: "Something went wrong while updating the plan",
      });
    }
  }, [planUpdateStatus]);

  const returnDataMutation = React.useCallback(
    (status: string) => {
      if (user?.planTier === PlanTier.DEFAULT) {
        return status === planStatus.ACTIVE || status === planStatus.TRIALING;
      } else return status === planStatus.ACTIVE;
    },
    [user],
  );

  const checkStatus = React.useCallback((status: string) => {
    return (
      status === planStatus.INCOMPLETE ||
      status === planStatus.INCOMPLETE_EXPIRED ||
      status === planStatus.PAST_DUE ||
      status === planStatus.UNPAID ||
      status === planStatus.INCOMPLETE ||
      status === planStatus.INCOMPLETE_EXPIRED ||
      status === planStatus.PAST_DUE ||
      status === planStatus.UNPAID
    );
  }, []);

  React.useEffect(() => {
    if (onPlanChange) {
      onPlanChange(selectedPlan);
    }
  }, [onPlanChange, selectedPlan]);

  const checkPlanStatus = useCallback(
    (plan) => {
      return user.clientsCountNoSample > Number(planClients(plan, true));
    },
    [user.clientsCountNoSample],
  );
  const handlePlanChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setSelectedPlan(event.target.value as Plan);
    },
    [],
  );

  const stripeAction = React.useCallback(
    async (id) => {
      try {
        const stripe = await initStripe();
        const result = await stripe.redirectToCheckout({
          sessionId: id,
        });

        if (result.error) {
          showToastAlert("error", {
            message: result.error.message,
          });
        }
      } catch (e) {
        console.error(e);
        showToastAlert("error", {
          message: SOMETHING_WENT_WRONG,
        });
      }
    },
    [showToastAlert],
  );

  const createCheckout = React.useCallback(() => {
    createCheckoutSession(
      {
        plan: selectedPlan,
        successUrl: `${window.location.href}?upgrade=${UpgradeStatus.SUCCESS}&plan=${selectedPlan}`,
        cancelUrl: `${window.location.href}?upgrade=${UpgradeStatus.ERROR}`,
      },
      {
        onSuccess: ({ sessionId }) => {
          return stripeAction(sessionId);
        },
      },
    );
  }, [createCheckoutSession, showToastAlert, selectedPlan, stripeAction]);

  const doContinue = React.useCallback(() => {
    if (
      user?.subscription?.status === planStatus.CANCELED ||
      user?.subscription?.status === planStatus.INCOMPLETE_EXPIRED ||
      !user?.subscription?.status
    ) {
      createCheckout();
    } else if (
      user?.subscription?.status === planStatus.INCOMPLETE ||
      user?.subscription?.status === planStatus.PAST_DUE ||
      user?.subscription?.status === planStatus.UNPAID
    ) {
      getHostedInvoiceUrl(undefined, {
        onSuccess: ({ hostedInvoiceUrl }) => {
          window.location.href = `${hostedInvoiceUrl}`;
        },
      });
    } else {
      updateUserSubscription(
        {
          plan: selectedPlan,
        },
        {
          onSuccess: ({ status }) => {
            if (returnDataMutation(status)) {
              const choosePlan =
                user?.subscription?.status === planStatus.TRIALING
                  ? ""
                  : `&plan=${selectedPlan}`;
              const path = COACH_PLAN_ROUTE + "?upgraded=true" + choosePlan;
              showToastAlert("success", {
                message: "Success",
              });
              navigate(path);
            } else if (checkStatus(status)) {
              getHostedInvoiceUrl(undefined, {
                onSuccess: ({ hostedInvoiceUrl }) => {
                  window.location.href = `${hostedInvoiceUrl}`;
                },
              });
            } else {
              createCheckout();
            }
          },
          // TODO_API_V2 STR-1341: Consider handler specific stripe errors
          // onError(err) {
          //   if (errors && errors[0].message.includes("no attached payment")) {
          //     navigate(COACH_CARD_INFORMATION_ROUTE);
          //   }
          //   if (
          //     errors &&
          //     errors[0].message.includes("change subscription because you have")
          //   ) {
          //     setShowTrialingDialogOpen(false);
          //     setShowMoreClientsDialogOpen(true);
          //   }
          // },
        },
      );
    }
  }, [
    showToastAlert,
    selectedPlan,
    updateUserSubscription,
    returnDataMutation,
    user,
    createCheckout,
    getHostedInvoiceUrl,
    checkStatus,
  ]);

  const handleContinueClick = React.useCallback(() => {
    if (
      user?.subscription?.status === planStatus.TRIALING &&
      !showTrialingDialogOpen
    ) {
      setShowTrialingDialogOpen(true);
    } else if (
      selectedPlan !== Plan.UP_TO_UNLIMITED &&
      user.clientsCountNoSample > Number(planClients(selectedPlan, true))
    ) {
      setShowMoreClientsDialogOpen(true);
    } else {
      doContinue();
    }
  }, [
    doContinue,
    selectedPlan,
    user,
    setShowTrialingDialogOpen,
    showTrialingDialogOpen,
  ]);

  const monthlyPlanList = React.useMemo(() => {
    const monthlyPlans = Object.values(Plan);

    return monthlyPlans
      .filter((plan) => {
        return ![
          Plan.PRO,
          Plan.STARTER,
          Plan.UP_TO_5,
          Plan.UP_TO_15,
          Plan.UP_TO_30,
          Plan.UP_TO_75,
          Plan.UP_TO_INFINITY,
          Plan.PROMO,
          Plan.YEARLY_UP_TO_50,
          Plan.YEARLY_UP_TO_100,
          Plan.YEARLY_UP_TO_200,
          Plan.YEARLY_UNLIMITED,
        ].includes(plan);
      })
      .map((plan) => {
        return {
          value: plan !== Plan.FREE && plan,
          disabled: checkPlanStatus(plan),
        };
      });
  }, [checkPlanStatus]);

  const yearlyPlanList = React.useMemo(() => {
    const yearlyPlans = Object.values(Plan);

    return yearlyPlans
      .filter((plan) => {
        return ![
          Plan.PRO,
          Plan.STARTER,
          Plan.UP_TO_5,
          Plan.UP_TO_15,
          Plan.UP_TO_30,
          Plan.UP_TO_75,
          Plan.UP_TO_INFINITY,
          Plan.PROMO,
          Plan.UP_TO_50,
          Plan.UP_TO_100,
          Plan.UP_TO_200,
          Plan.UP_TO_UNLIMITED,
        ].includes(plan);
      })
      .map((plan) => {
        return {
          value: plan !== Plan.FREE && plan,
          disabled: checkPlanStatus(plan),
        };
      });
  }, [checkPlanStatus]);

  const isActiveContinueCurrentPlanButton = React.useMemo(() => {
    switch (user?.subscription?.status) {
      case planStatus.PAST_DUE:
      case planStatus.INCOMPLETE:
      case planStatus.CANCELED:
      case planStatus.INCOMPLETE_EXPIRED:
      case planStatus.UNPAID:
        return true;
      default:
        return;
    }
  }, [user]);

  const pending =
    creatingCheckoutSession ||
    updateUserSubscriptionIn ||
    continueOldPlanIn ||
    getHostedInvoiceUrlIn;

  const originalPrice = (upsellYearlyFee / 10) * 12;
  const savings = originalPrice - upsellYearlyFee;

  const DialogDescription = () => (
    <div>
      Upgrade to an annual subscription today and get 12 months for the price of
      10
      <br />
      <br />
      <strong>AND</strong>
      <br />
      <br />
      Get upgraded to a custom branded app at no extra cost for the year saving
      over $500!!
      <br />
      <br />
      <strong>
        Upgrade now for ${upsellYearlyFee} amount and save ${savings} on your
        subscription and save upto £500 for the custom branded app upgrade
      </strong>
    </div>
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h3">
        {Plan[user.plan] === Plan.FREE
          ? "Upgrade your plan to"
          : "Change your plan to"}
      </Typography>
      <Typography className={s.subtitle} variant="body1">
        {Plan[user.plan] === Plan.FREE ||
        user?.subscription?.status === "trialing" ? (
          <>
            You can choose a plan without waiting for the end of the trial
            period
          </>
        ) : (
          <>
            Change the plan to the one that suits you best <br />
            If you decide this plan isn't right for you, it's easy to cancel.
          </>
        )}
      </Typography>
      <Box className={s.field}>
        <Typography className={s.label}>Choose your plan</Typography>
        <Box display="flex" alignItems="center">
          {!user.plan.includes("YEARLY") && (
            <>
              <Typography>Monthly</Typography>
              <Switch
                checked={billingCycle === "annual"}
                onChange={handleBillingCycleToggle}
                color="primary"
              />
            </>
          )}
          <Typography>Annually</Typography>
        </Box>
        {!user.plan.includes("YEARLY") && billingCycle === "monthly" && (
          <Select
            native
            variant="outlined"
            fullWidth
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            {monthlyPlanList.map(
              ({ value }) =>
                value && (
                  <option key={value} value={value}>
                    Up to {planClients(value)} clients
                  </option>
                ),
            )}
          </Select>
        )}

        {(user.plan.includes("YEARLY") || billingCycle === "annual") && (
          <Select
            native
            variant="outlined"
            fullWidth
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            {yearlyPlanList.map(
              ({ value }) =>
                value && (
                  <option key={value} value={value}>
                    Up to {planClients(value)} clients
                  </option>
                ),
            )}
          </Select>
        )}
      </Box>

      <Box className={s.bottom}>
        <Box className={s.totalWrapper}>
          <Typography
            className={s.amount}
            children={`$${
              billingPlanPrice[selectedPlan]
                ? billingPlanPrice[selectedPlan][tierIndex[PlanTier.DEFAULT]]
                : 0 // Fallback value
            }`}
          />
          <Typography className={s.totalDescription} children="Billed today" />
        </Box>
        <Button
          className={s.button}
          onClick={handleContinueClick}
          children="Continue"
          disabled={pending}
        />
      </Box>

      <Box className={s.changeCardBox}>
        {/* 
        
        This button is sending existing users to stripe checkout and creating a new / duplicate subscription which we don't want.

        <Box>
          {isActiveContinueCurrentPlanButton &&
            user?.planTier !== PlanTier.DEFAULT && (
              <LinkButton
                href={null}
                onClick={handleContinueOldPlan}
                className={clsx(s.changeCard)}
              >
                Continue current plan
              </LinkButton>
            )}
        </Box> */}

        <Box>
          <LinkButton href="/coach/card-information" className={s.changeCard}>
            Change card details
          </LinkButton>
        </Box>
      </Box>
      <ConfirmActionDialog
        title={user?.displayName}
        description={
          showTrialingDialogOpen
            ? `Hey, you want to upgrade your plan. You will still have a free trial, but with a new plan. After your trial ends, you will automatically be upgraded to the plan you currently want to choose. Do you want to upgrade the plan!`
            : `We can not downgrade your plan because you currently have more clients than the plan you have chosen. Please reduce the number of clients and try again.`
        }
        onCancel={() =>
          showTrialingDialogOpen
            ? setShowTrialingDialogOpen(false)
            : setShowMoreClientsDialogOpen(false)
        }
        onConfirm={handleContinueClick}
        activity={!showTrialingDialogOpen}
        open={showTrialingDialogOpen || showMoreClientsDialogOpen}
        classNameTitle={s.titleModal}
      />
      {/* <CancelButton userId={user} />  */}

      {/* Upsell Anual Plans */}
      <ConfirmActionDialog
        title={`STRIDIST BLACK FRIDAY DEAL!!`}
        description={<DialogDescription />}
        onCancel={() => cancelYearlyUpsell()}
        onConfirm={handleContinueClick}
        confirmButtonText="Upgrade to Annual"
        activity={showTrialingDialogOpen}
        open={showUpsellYearlyPlan}
        classNameTitle={s.titleModal}
      />

      {/* Upsell Anual Plans END */}
    </Card>
  );
}
