import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  Container,
  ContainerProps,
  Grid,
  Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CoachCardInfoForm } from "./CoachCardInfoForm";
import { initStripe } from "../../stripe";
import TrackInfoTool from "../tools/TrackInfoTool";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    maxWidth: theme.breakpoints.values.slg,

    [theme.breakpoints.down("lg")]: {
      height: "auto",
    },
  },

  container: {
    display: "flex",
    alignItems: "center",
  },

  rootCard: {
    padding: theme.spacing(3),
    overflow: "visible",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: 0,
      borderRadius: 0,
      padding: theme.spacing(2.5, 2, 2, 2),
    },
  },
  logo: {
    width: 200,
    height: "auto",
  },
  logoContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(1.5),
  },
  description: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.8,
  },

  leftContainer: {
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(1.5),
    },
  },

  content: {
    maxWidth: 400,
    margin: "0 auto",
  },

  title: {
    marginBottom: theme.spacing(1),
  },
}));

export interface CoachCardInfoProps extends Omit<ContainerProps, "children"> {}

export const CoachCardInfo = (props: CoachCardInfoProps) => {
  const { className, ...other } = props;
  const s = useStyles();

  const promise = initStripe();

  return (
    <>
      <Container className={clsx(s.root)} {...other}>
        <Grid container spacing={3} className={s.container}>
          <Grid item xs={12} lg={7} className={s.leftContainer}>
            <div className={s.content}>
              <Typography variant="h4" className={s.title}>
                Change card details
              </Typography>
              <Typography variant="body1" className={s.description}>
                Here you can change the current card details to others for
                further work and payment plans
              </Typography>
              <Typography variant="body1" className={s.description}>
                <b>Note:</b> Changing the card does not affect the payment
                schedule in any way, and your current plan
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Card className={s.rootCard}>
              <Elements stripe={promise}>
                <CoachCardInfoForm />
              </Elements>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Card Information",
        }}
      />
    </>
  );
};
