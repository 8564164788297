import clsx from "clsx";
import React, { ReactNode, ReactElement, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import {
  UserRole,
  RequiresUpgradeReason,
  Plan,
  planClients,
  talkToConfig,
  PlanTier,
  LocalStorageKeys,
  IsMobileAppModalShown,
  REACTIVATE_PROMO_CANCEL_BEFORE_DATE,
  REACTIVATE_PROMO_QUERY,
} from "../../constants";
import { AppSpeedDial } from "../speed-dial/AppSpeedDial";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useQueryParam } from "../../hooks/useQueryParam";
import { useCurrentUser, useUserIsClient } from "../../hooks/useCurrentUser";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { getRequiresUpgrade } from "../../utils/user";
import { PreviewType, PreviewContext } from "../../hooks/usePreview";
import { getTodayDaysDiff } from "../../utils/date";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "./AppBar";
import AppDrawer from "./AppDrawer";
import { AppTabsNavigation } from "./AppTabsNavigation";
import { ImpersonationBanner } from "./ImpersonationBanner";
import { UpgradeBanner } from "./UpgradeBanner";
import { polyfillCSS } from "../../utils/css";
import {
  CLIENT_SUBSCRIPTION_CANCELED_ROUTE,
  CLIENT_TRIAL_EXPIRED_ROUTE,
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_PLAN_ROUTE,
  LOGOUT_ROUTE,
  PREVIEW_ROUTE,
} from "../../routes/routes";
import { AppDrawerContext } from "../../hooks/useDrawer";
import MinimizedDrawerContext, {
  getLocalStorageMinimizedDrawerValue,
} from "../../contexts/MinimizedDrawerContext";
import BannersContext, { BannerType } from "../../contexts/BannersContext";
import { DownloadMobileAppDialog } from "../dialog/app/DownloadMobileAppDialog";
import { useMobileAppModalContextData } from "../../contexts/MobileAppModalContext";
import { isMobileApp } from "../../utils/mobile";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { isLocalEnvironment } from "../../utils";
import { UpdateMobileAppDialog } from "../dialog/app/UpdateMobileAppDialog";
import { useCurrentAppVersion } from "../../hooks/useAppVersion";
import { useMainenanceMode } from "../../hooks/useMaintenanceMode";
import { MaintenanceModeDialog } from "../dialog/app/MaintenanceModeDialog";
import { FreeLapsedCoachUpgradeDialog } from "../dialog/app/FreeLapsedCoachUpgradeDialog";

const useStyles = (appDrawer: boolean, minimizedDrawer: boolean) =>
  makeStyles((theme) => ({
    "@global": {
      "#root": {
        [theme.breakpoints.up("llg")]: {
          paddingLeft: polyfillCSS(
            appDrawer
              ? `calc(${minimizedDrawer ? theme.drawer.minimizedWidth : theme.drawer.width}px + var(--safe-area-inset-left))`
              : "0px",
          ),
          transition: theme.transitions.create("padding-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
    },
    white: {
      backgroundColor: theme.palette.common.white,
    },
    divider: {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05);",
      [theme.breakpoints.up("md")]: {
        paddingBottom: 24,
      },
    },
  }));

type Props = {
  appBar?: boolean;
  appDrawer?: boolean;
  appBarHideOnMobile?: boolean;
  children?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: Array<ReactElement>;
  breadcrumbs?: any[];
  tabsNavigation?: any[];
  filters?: any;
  trackInfo?: {
    name?: string;
    properties?: Object;
  };
  cover?: string;
  disableSpeedDial?: boolean;
  forceBackButton?: boolean;
  hideUpgradeBanner?: boolean;
};

export function AppLayout(props: Props) {
  const {
    appDrawer = true,
    appBar = true,
    appBarHideOnMobile,
    title,
    subtitle,
    actions,
    breadcrumbs,
    tabsNavigation,
    filters,
    trackInfo,
    cover,
    disableSpeedDial = true,
    forceBackButton = false,
    hideUpgradeBanner = false,
    children,
  } = props;
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [preview, setPreview] = React.useState<PreviewType>(null);

  const [minimizedDrawer, setMinimizedDrawer] = useState(
    getLocalStorageMinimizedDrawerValue(),
  );

  const [, trackPage] = useAnalytics();
  const [queryPreview] = useQueryParam("preview");
  const [queryReactivated] = useQueryParam(REACTIVATE_PROMO_QUERY);

  const user = useCurrentUser();
  const location = useLocation();
  const isPreviewRoute =
    queryPreview === "true" || location.pathname.includes(PREVIEW_ROUTE);
  const isReactivatedSubscriptionRoute =
    queryReactivated === "success" ||
    location.pathname.includes(COACH_CLIENTS_ACTIVE_ROUTE);

  const { isBrandedApp, isBrandedAppPublished } = useCurrentBrand();

  const s = useStyles(
    !isPreviewRoute && appBar ? appDrawer : false,
    minimizedDrawer,
  )();
  const isMobile = useMediaMobile();
  const trialDaysDiff = React.useMemo(
    () =>
      user?.trialExpiryDate
        ? getTodayDaysDiff(user?.trialExpiryDate?.toString())
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.trialExpiryDate],
  );

  const trialExpired = user?.trialExpired ?? false;

  const [requiresUpgrade, reason] = getRequiresUpgrade(
    trialExpired,
    user?.plan as Plan,
    user?.subscription?.status,
  );
  const [visibleUpgradeBanner, setVisibleUpgradeBanner] = React.useState(true);

  React.useEffect(() => {
    if (!user?.admin) {
      if (
        user &&
        user?.subscription?.status !== "active" &&
        user?.subscription?.status !== "trialing" &&
        user.role === UserRole.COACH
      ) {
        if (
          location.pathname !== LOGOUT_ROUTE &&
          !/\/coach\/card-information/.test(location.pathname) &&
          !isReactivatedSubscriptionRoute
        ) {
          navigate(COACH_PLAN_ROUTE);
        }
      }
      if (user && !user.isImpersonating) {
        if (requiresUpgrade) {
          if (user.role === UserRole.COACH) {
            if (
              location.pathname !== LOGOUT_ROUTE &&
              !/\/coach\/card-information/.test(location.pathname) &&
              !isReactivatedSubscriptionRoute
            ) {
              navigate(COACH_PLAN_ROUTE);
            }
          }

          if (
            user.role === UserRole.CLIENT &&
            !/\/signup\/client/.test(location.pathname)
          ) {
            navigate(
              reason === RequiresUpgradeReason.TRAIL_EXPIRED
                ? CLIENT_TRIAL_EXPIRED_ROUTE
                : CLIENT_SUBSCRIPTION_CANCELED_ROUTE,
              { replace: true },
            );
          }
        } else if (
          user.role === UserRole.COACH &&
          user.clientsCountNoSample >
            Number(planClients((user?.plan as Plan) ?? Plan.FREE)) &&
          !isReactivatedSubscriptionRoute
        ) {
          navigate(COACH_PLAN_ROUTE, { replace: true });
        }
      }
    }
  }, [location.pathname, reason, requiresUpgrade, user]);

  React.useEffect(() => {
    // Ensures preview state is turned off on history change
    if (preview) {
      const listener = () => {
        setPreview(null);
      };

      window.addEventListener("popstate", listener);

      return () => {
        window.removeEventListener("popstate", listener);
      };
    }
  }, [preview]);

  if (typeof title === "string") {
    window.document.title = title;
  }

  if (trackInfo) {
    trackPage(trackInfo.name, trackInfo.properties);
  }

  const toggleDrawer = React.useCallback(() => {
    setDrawerOpen((x) => !x);
  }, []);

  const closeDrawer = React.useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const showUpgradeBanner = React.useMemo(
    () =>
      (user?.role === UserRole.COACH &&
        !user?.isImpersonating &&
        !user?.subscription &&
        !hideUpgradeBanner &&
        !preview &&
        !queryPreview) ||
      (user?.subscription?.status === "canceled" &&
        user?.role === UserRole.COACH),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideUpgradeBanner, preview, queryPreview, trialDaysDiff, user],
  );

  useEffect(() => {
    if (user) {
      window.Appcues?.identify(user.externalReferenceId);
    }
  }, [user]);

  const isImpersonatingBannerShown = user?.isImpersonating;
  const isUpgradeBannerShown =
    !isPreviewRoute && showUpgradeBanner && visibleUpgradeBanner;

  const isAnyBannerShown = isImpersonatingBannerShown || isUpgradeBannerShown;

  const getBannerType = () => {
    if (isImpersonatingBannerShown) return BannerType.IMPERSONATION;
    if (isUpgradeBannerShown) return BannerType.UPGRADE;
  };

  const [isDownloadAppDialogOpen, setIsDownloadAppDialogOpen] =
    useMobileAppModalContextData();
  const onCloseDownloadAppDialog = () => {
    setIsDownloadAppDialogOpen(false);
    window.localStorage.setItem(IsMobileAppModalShown, "false");
  };
  const maintenanceMode = useMainenanceMode();
  const { requiresUpdate, requiresForceUpdate } = useCurrentAppVersion();

  const [isMaintenanceEnabled, setIsMaintenanceEnabled] = useState<boolean>(
    maintenanceMode.enabled,
  );

  const [isUpdateAppDialogOpen, setIsUpdateAppDialogOpen] = useState<boolean>(
    requiresUpdate || requiresForceUpdate,
  );

  const onCloseUpdateAppDialog = () => {
    setIsUpdateAppDialogOpen(false);
  };

  useEffect(() => {
    setIsUpdateAppDialogOpen(requiresUpdate);
  }, [requiresUpdate]);

  useEffect(() => {
    setIsUpdateAppDialogOpen(requiresForceUpdate);
  }, [requiresForceUpdate]);

  useEffect(() => {
    setIsMaintenanceEnabled(maintenanceMode.enabled);
  }, [maintenanceMode]);

  const isClient = useUserIsClient();
  const isStridistMobileApp = !isBrandedApp && isMobileApp;
  const forceClientToDownloadBrandedApp =
    isStridistMobileApp && isBrandedAppPublished;

  return (
    <BannersContext.Provider
      value={{ bannerType: getBannerType(), isBannerShown: isAnyBannerShown }}
    >
      <AppDrawerContext.Provider value={[drawerOpen, setDrawerOpen]}>
        <MinimizedDrawerContext.Provider
          value={{ minimizedDrawer, setMinimizedDrawer }}
        >
          <PreviewContext.Provider value={[preview, setPreview]}>
            {!preview && (
              <>
                <DownloadMobileAppDialog
                  force={forceClientToDownloadBrandedApp}
                  open={
                    ((isDownloadAppDialogOpen && !isMobileApp) ||
                      forceClientToDownloadBrandedApp) &&
                    isClient
                  }
                  onClose={onCloseDownloadAppDialog}
                />
                <UpdateMobileAppDialog
                  force={requiresForceUpdate}
                  open={!isMaintenanceEnabled && isUpdateAppDialogOpen}
                  onClose={onCloseUpdateAppDialog}
                />
                <MaintenanceModeDialog open={isMaintenanceEnabled} />
                <FreeLapsedCoachUpgradeDialog
                  open={
                    !isReactivatedSubscriptionRoute &&
                    ((isUpgradeBannerShown &&
                      user?.subscription?.canceledDate &&
                      new Date(user?.subscription?.canceledDate) <
                        REACTIVATE_PROMO_CANCEL_BEFORE_DATE) ||
                      (user != null && user?.subscription == null))
                  }
                />
                {!disableSpeedDial && <AppSpeedDial />}
                {isImpersonatingBannerShown && <ImpersonationBanner />}
                {isUpgradeBannerShown && (
                  <UpgradeBanner
                    status={user?.subscription?.status}
                    trialEndsInDays={trialDaysDiff}
                    setVisibleUpgradeBanner={setVisibleUpgradeBanner}
                  />
                )}
                {appBar && (
                  <>
                    {appDrawer && (
                      <AppDrawer open={drawerOpen} onClose={closeDrawer} />
                    )}
                    <AppBar
                      title={title}
                      subtitle={subtitle}
                      actions={actions}
                      breadcrumbs={breadcrumbs}
                      onMenuClick={toggleDrawer}
                      hideOnMobile={appBarHideOnMobile}
                      forceBackButton={forceBackButton}
                      // TODO: We'll need to refactor AppBar and normalize it's across all screens
                      className={clsx({
                        [s.white]: tabsNavigation || subtitle,
                        [s.divider]: subtitle && !tabsNavigation,
                      })}
                      cover={cover}
                    />
                  </>
                )}
                {tabsNavigation && <AppTabsNavigation items={tabsNavigation} />}
                {filters && filters}
              </>
            )}
            {children}
            {!isPreviewRoute &&
              user?.role === UserRole.COACH &&
              !isMobile &&
              !isLocalEnvironment() && (
                <TawkMessengerReact
                  propertyId={talkToConfig.propertyId}
                  widgetId={talkToConfig.widgetId}
                />
              )}
          </PreviewContext.Provider>
        </MinimizedDrawerContext.Provider>
      </AppDrawerContext.Provider>
    </BannersContext.Provider>
  );
}

export default AppLayout;
