import clsx from "clsx";
import React from "react";
import { Drawer, DrawerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useClient } from "../../hooks/useClient";
import { colorSystem } from "../../theme";

import { ClientGoalsDrawerEmptyView } from "./ClientGoalsDrawerEmptyView";
import {
  ClientGoalsDrawerListView,
  ClientGoalsDrawerListViewProps,
} from "./ClientGoalsDrawerListView";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ClientGoalsDrawerEditView } from "./ClientGoalsDrawerEditView";
import { ClientGoalsDrawerSetDateView } from "./ClientGoalsDrawerSetDateView";
import { ClientGoalListItemProps } from "./ClientGoalListItem";
import useDeleteClientGoalMutation from "./mutations/useDeleteClientGoalMutation";
import { useChangeClientGoalOrdinalMutation } from "./mutations/useChangeClientGoalOrdinal";
import {
  ClientGoalDto,
  PaginatedListOfClientGoalDto,
} from "@growth-machine-llc/stridist-api-client";
import useCreateClientGoalMutation from "./mutations/useCreateClientGoalMutation";
import dayjs from "dayjs";
import useUpdateClientGoalMutation from "./mutations/useUpdateClientGoalMutation";
import { ISO_DATE_FORMAT } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  overlay: {},
}));

export enum ClientGoalsDrawerView {
  EMPTY = "EMPTY",
  LIST = "LIST",
  EDIT = "EDIT",
  SET_DATE = "SET_DATE",
}

function getInitialView(goalsCount: number): ClientGoalsDrawerView {
  return goalsCount ? ClientGoalsDrawerView.LIST : ClientGoalsDrawerView.EMPTY;
}

export interface ClientGoalsDrawerProps extends DrawerProps {
  goalsData: PaginatedListOfClientGoalDto;
  clientId: number;
}

export function ClientGoalsDrawer(props: ClientGoalsDrawerProps) {
  const { className, onClose, open, clientId, goalsData } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const initialView = getInitialView(goalsData.totalCount);
  const [view, setView] = React.useState<ClientGoalsDrawerView>(initialView);
  const [viewHistory, setViewHistory] = React.useState<ClientGoalsDrawerView[]>(
    [view],
  );
  const [name, setName] = React.useState("");
  const [target, setTarget] = React.useState("");
  const [date, setDate] = React.useState("");
  const [selectedGoal, setSelectedGoal] =
    React.useState<ClientGoalListItemProps["option"]>(null);
  const client = useClient();
  const { mutate: createGoal, isPending: isCreating } =
    useCreateClientGoalMutation(clientId);
  const { mutate: updateGoal } = useUpdateClientGoalMutation(
    Number(selectedGoal?.id),
    clientId,
  );
  const { mutate: deleteGoal, isPending: isDeleting } =
    useDeleteClientGoalMutation(clientId);
  const { mutate: changeOrdinal } =
    useChangeClientGoalOrdinalMutation(clientId);

  React.useEffect(() => {
    setViewHistory((viewHistory) => [initialView, ...viewHistory.slice(1)]);
  }, [initialView]);

  React.useEffect(() => {
    setName(selectedGoal?.name || "");
    setTarget(selectedGoal?.targetValue || "");
    setDate(
      selectedGoal?.date ? selectedGoal.date.format(ISO_DATE_FORMAT) : "",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal]);

  const resetState = React.useCallback(
    (view: ClientGoalsDrawerView = initialView) => {
      setName("");
      setDate("");
      setTarget("");
      setSelectedGoal(null);
      setView(view);
      setViewHistory([view]);
    },
    [initialView],
  );
  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  React.useEffect(() => {
    const lastView = viewHistory[viewHistory.length - 1];

    if (!lastView) {
      handleClose();
    } else if (lastView !== view) {
      setView(lastView);
    }
  }, [handleClose, view, viewHistory]);

  const pushView = React.useCallback((view: ClientGoalsDrawerView) => {
    setViewHistory((viewHistory) => [...viewHistory, view]);
  }, []);

  const popView = React.useCallback(() => {
    setViewHistory((viewHistory) => viewHistory.slice(0, -1));
  }, []);

  const handleBackClick = React.useCallback(() => {
    setViewHistory((viewHistory) =>
      viewHistory.slice(0, viewHistory.length - 1),
    );
  }, []);

  const userId = React.useMemo(() => client?.id || user.id, [user, client]);
  const handleDeleteClick = React.useCallback(() => {
    if (selectedGoal) {
      deleteGoal(selectedGoal.id, {
        onSuccess: () => {
          resetState(
            goalsData?.totalCount === 0
              ? ClientGoalsDrawerView.EMPTY
              : ClientGoalsDrawerView.LIST,
          );
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGoal, client?.id, user, selectedGoal]);

  const handleCreateClick = React.useCallback(() => {
    setSelectedGoal(null);
    pushView(ClientGoalsDrawerView.EDIT);
  }, [pushView]);

  const handleItemActionClick: ClientGoalsDrawerListViewProps["onItemActionClick"] =
    React.useCallback(
      (event, goal) => {
        setSelectedGoal(goal);
        pushView(ClientGoalsDrawerView.EDIT);
      },
      [pushView],
    );
  const handleListUpdate: ClientGoalsDrawerListViewProps["onListUpdate"] =
    React.useCallback(
      (goals) => {
        const goalsDictionary = goals.reduce(
          (acc, goal, index) => {
            acc[String(goal.id)] = index + 1;
            return acc;
          },
          {} as { [key: string]: number },
        );
        changeOrdinal({ clientId, idsToOrdinals: goalsDictionary });
      },
      [changeOrdinal],
    );
  const save = React.useCallback(
    (date?) => {
      const dirty = Boolean(
        (name && target && (!selectedGoal || selectedGoal.name !== name)) ||
          (name &&
            target &&
            (!selectedGoal || selectedGoal.targetValue !== target)) ||
          (date &&
            name &&
            target &&
            (!selectedGoal ||
              selectedGoal.date?.format(ISO_DATE_FORMAT) !== date)),
      );

      if (dirty) {
        if (!selectedGoal?.id && !isCreating) {
          createGoal(
            {
              clientId: Number(userId),
              name,
              targetValue: Number(target),
              date: date ? dayjs(date) : null,
            },
            {
              onSuccess(response) {
                const insertClientGoal = ClientGoalDto.fromJS({
                  name,
                  targetValue: target,
                  date: date ? dayjs(date) : null,
                  id: response,
                  currentValue: "",
                  lastModified: dayjs(),
                  ordinal: 0,
                });
                setSelectedGoal(insertClientGoal);
              },
            },
          );
        } else {
          const updateClientGoal = {
            ...selectedGoal,
            goalId: Number(selectedGoal.id),
            name,
            targetValue: Number(target),
            date: date ? dayjs(date) : null,
            lastModified: dayjs(),
          };
          updateGoal(updateClientGoal, {
            onSuccess: () => {
              setSelectedGoal(ClientGoalDto.fromJS(updateClientGoal));
            },
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [userId, user, date, name, target, selectedGoal, createGoal, updateGoal],
  );
  const handleTargetChange = React.useCallback((event) => {
    const pattern = /^[0-9]+$/;
    const maxInt = 2147483647;
    ((pattern.test(event.target.value) && event.target.value <= maxInt) ||
      event.target.value === "") &&
      setTarget(event.target.value);
  }, []);

  const handleTargetBlur = React.useCallback(() => {
    save();
  }, [save]);

  const handleNameChange = React.useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleNameBlur = React.useCallback(() => {
    save();
  }, [save]);

  const handleEditDateClick = React.useCallback(() => {
    pushView(ClientGoalsDrawerView.SET_DATE);
  }, [pushView]);

  const handleDateChange = React.useCallback(
    (date: string) => {
      setDate(date);
      save(date);
      popView();
    },
    [popView, save],
  );

  const children = React.useMemo(() => {
    switch (view) {
      case ClientGoalsDrawerView.EMPTY:
        return (
          <ClientGoalsDrawerEmptyView
            onClose={handleClose}
            onCreateClick={handleCreateClick}
          />
        );
      case ClientGoalsDrawerView.LIST:
        return (
          <ClientGoalsDrawerListView
            goals={goalsData.items}
            onClose={handleClose}
            onCreateClick={handleCreateClick}
            onItemActionClick={handleItemActionClick}
            onListUpdate={handleListUpdate}
            disabledReorder={isCreating || isDeleting}
          />
        );
      case ClientGoalsDrawerView.EDIT:
        return (
          <ClientGoalsDrawerEditView
            name={name}
            target={target}
            date={date}
            onBack={handleBackClick}
            onDelete={handleDeleteClick}
            onClose={handleClose}
            onNameChange={handleNameChange}
            onTargetChange={handleTargetChange}
            onNameBlur={handleNameBlur}
            onTargetBlur={handleTargetBlur}
            onEditDateClick={handleEditDateClick}
            hideDelete={!selectedGoal?.id || (selectedGoal?.id as number) < 0}
            isLoadingDelete={isDeleting}
            isLoadingCreate={isCreating}
          />
        );
      case ClientGoalsDrawerView.SET_DATE:
        return (
          <ClientGoalsDrawerSetDateView
            date={date}
            onBack={handleBackClick}
            onDelete={handleDeleteClick}
            isLoadingDelete={isDeleting}
            onClose={handleClose}
            onDateChange={handleDateChange}
            isLoadingCreate={isCreating}
          />
        );
      default:
        console.error("Invalid client drawer view");
        return null;
    }
  }, [
    view,
    handleClose,
    handleCreateClick,
    goalsData.items,
    handleItemActionClick,
    handleListUpdate,
    name,
    target,
    date,
    handleBackClick,
    handleDeleteClick,
    handleNameChange,
    handleTargetChange,
    handleNameBlur,
    handleTargetBlur,
    handleEditDateClick,
    handleDateChange,
    selectedGoal,
  ]);

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: clsx(
          s.paper,
          view === ClientGoalsDrawerView.SET_DATE && s.overlay,
        ),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={children}
    />
  );
}
