import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Message } from "./Message";
import { MessageDto, ThreadDto } from "@growth-machine-llc/stridist-api-client";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 0,
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(2.5),
    },
  },

  wrapper: {
    marginTop: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    alignContent: "flex-end",
  },
}));

const renderMessage = (
  message: MessageDto,
  index: number,
  messages: MessageDto[],
) => {
  const nextNode = index > 0 && messages[index - 1];
  const prevNode = messages.length > index + 1 && messages[index + 1];
  const displayDate =
    !prevNode || prevNode.formattedDate !== message.formattedDate;
  const displayAvatar =
    !nextNode ||
    nextNode.messageAuthor.username !== message.messageAuthor.username;
  const displayUserName =
    !prevNode ||
    prevNode.messageAuthor.username !== message.messageAuthor.username;
  return (
    <OptimisticUpdateContainer id={message.id} transition={false}>
      <Message
        key={message.id ? `${message.id}-message` : index}
        message={message}
        displayDate={displayDate}
        displayUserName={displayUserName}
        displayAvatar={displayAvatar}
      />
    </OptimisticUpdateContainer>
  );
};

export interface MessagesListProps extends BoxProps {
  messages?: ThreadDto["messages"];
}

export function MessagesList(props: MessagesListProps) {
  const { className, messages } = props;
  const s = useStyles();
  const endScrollRef = React.useRef<HTMLInputElement>();
  const renderedMessages =
    messages && messages.slice(0).reverse().map(renderMessage);
  const hasMessages = renderedMessages && renderedMessages.length > 0;
  const scrollState = React.useRef({
    scrollHeight: 0,
    height: 0,
  });

  React.useLayoutEffect(() => {
    const interval = setInterval(() => {
      const endScrollEl = endScrollRef.current;
      const scrollEl = endScrollEl?.parentElement;
      const containerEl = scrollEl?.parentElement?.parentElement;
      const scroll = scrollState.current;

      if (containerEl && scroll) {
        const newScrollHeight = scrollEl.scrollHeight;
        const newContainerHeight = containerEl.getBoundingClientRect().height;

        if (newScrollHeight > scroll.scrollHeight) {
          endScrollEl.scrollIntoView({
            behavior: "smooth",
            inline: "nearest",
          });
        } else if (newContainerHeight !== scroll.height) {
          endScrollEl.scrollIntoView();
        }

        Object.assign(scroll, {
          scrollHeight: newScrollHeight,
          height: newContainerHeight,
        });
      }
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={clsx(s.root, className)}>
      {hasMessages ? (
        <Box className={s.wrapper}>
          <div ref={endScrollRef} />
          {renderedMessages}
        </Box>
      ) : null}
    </Box>
  );
}
