import { Card, CardProps, CardHeader, Box, CardContent } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

export const SkeletonUpdcomingPastProgramCartHeader = () => (
  <CardHeader
    sx={{ padding: "35px 20px 5px !important" }}
    subheader={
      <>
        <Box width="60%" mt={4}>
          <Skeleton animation="wave" height={20} />
        </Box>
        <Box width="50%">
          <Skeleton animation="wave" height={30} />
        </Box>
      </>
    }
  />
);

export interface UpcomingProgramSkeletonCardProps extends CardProps {}

export function UpcomingProgramSkeletonCard(
  props: UpcomingProgramSkeletonCardProps,
) {
  return (
    <Card {...props}>
      <SkeletonUpdcomingPastProgramCartHeader />
      <CardContent sx={{ pl: "20px !important" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            gap: 2,
          }}
        >
          <Skeleton animation="wave" height={20} width={100} />
          <Skeleton animation="wave" height={20} width={100} />
        </Box>
      </CardContent>
    </Card>
  );
}
