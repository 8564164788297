import clsx from "clsx";
import React from "react";
import { Box, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import {
  nutritionComments,
  nutritionCommentsLabels,
  nutritionTargetsLabels,
} from "../../constants";
import { tableStyles } from "./tableStyle";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useClient } from "../../hooks/useClient";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  target: {
    display: "flex",
    padding: theme.spacing(1.2, 0, 0, 1),
    minHeight: 61,
    boxShadow: "0px -1px 0px rgba(208, 216, 220, 0.3)",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      flexWrap: "wrap",
      padding: theme.spacing(0, 0, 5, 0),
      margin: theme.spacing(2.5, 0, 0, 0),
      justifyContent: "space-between",
    },
  },

  itemMobile: {
    width: "48.5%",
    margin: theme.spacing(1, 0),
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    padding: theme.spacing(1.3, 1.3, 2, 1.3),
  },

  targetText: {
    color: colorSystem.gray,
    fontSize: 14,
    fontWeight: 700,
  },

  targetTextBold: {
    color: colorSystem.black,
    fontWeight: 700,
    fontSize: 16,
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    "& span": {
      fontSize: 14,
      color: colorSystem.gray,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },

  progress: {
    width: "100%",
    maxWidth: "90%",
    marginTop: theme.spacing(2),
  },

  progressTrack: {
    height: 4,
    backgroundColor: colorSystem.secondaryGrayOpacity5,
  },

  targetTextLabel: {
    color: colorSystem.gray,
    fontSize: 14,
    fontWeight: 500,
  },

  button: {
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      bottom: -10,
    },
  },
  comments: {
    paddingLeft: theme.spacing(1),
  },
}));

export interface ClientNutritionTargetListItemProps {
  target: any;
  refresh: () => void;
  handleAddNutrition?: () => void;
  isProgram?: boolean;
}

export function ClientNutritionTargetListItem(
  props: ClientNutritionTargetListItemProps,
) {
  const {
    target: defaultTarget,
    refresh,
    isProgram,
    handleAddNutrition,
  } = props;
  const [dirty, setDirty] = React.useState(false);
  const [target, setTarget] = React.useState(defaultTarget);
  const [nutritionEntry, setNutritionEntry] = React.useState({
    carbsGrams: "0.0",
    proteinGrams: "0.0",
    calories: "0",
    fatGrams: "0",
    date: dayjs().format("YYYY-MM-DD"),
  });

  const user = useCurrentUser();
  const client: any = useClient();

  const { breakpoints } = useTheme();

  const mdUp = useMediaQuery(breakpoints.up("md"));

  const userId = React.useMemo(() => client?.id || user.id, [user, client]);

  const [queryArgs, setQueryArgs] = React.useState({
    options: { fetchKey: 0 },
    variables: { clientId: userId, targetId: target.id },
  });

  const t = tableStyles();
  const s = useStyles();

  const drawerState = usePopupState({
    variant: "popover",
    popupId: "nutrition-current",
  });

  const refetchEntries = React.useCallback(() => {
    setQueryArgs((prev) => ({
      options: {
        fetchKey: (prev?.options.fetchKey ?? 0) + 1,
        fetchPolicy: "store-and-network",
      },
      variables: { clientId: userId, targetId: target.id },
    }));
    if (refresh) {
      refresh();
    }
  }, [refresh, target.id, userId]);

  // const setDefaultNutritionEntry = React.useCallback(() => {
  //   setNutritionEntry({
  //     carbsGrams: "0.0",
  //     proteinGrams: "0.0",
  //     calories: "0",
  //     fatGrams: "0",
  //     date: moment().format("YYYY-MM-DD")
  //   })
  // }, []);

  const validateInputNumber = React.useCallback(
    (value, prevValue, defaultValue = "0") => {
      if (+value < 0) {
        return defaultValue;
      } else if (value.length >= 6) {
        return prevValue;
      } else return value;
    },
    [],
  );

  const handleChangeCarbohydrate = React.useCallback(
    (value) => {
      setDirty(true);
      setNutritionEntry({
        ...nutritionEntry,
        carbsGrams: validateInputNumber(
          value,
          nutritionEntry.carbsGrams,
          "0.0",
        ),
      });
    },
    [setNutritionEntry, nutritionEntry, validateInputNumber],
  );

  const handleChangeFat = React.useCallback(
    (value) => {
      setDirty(true);
      setNutritionEntry({
        ...nutritionEntry,
        fatGrams: validateInputNumber(value, nutritionEntry.fatGrams),
      });
    },
    [setNutritionEntry, nutritionEntry, validateInputNumber],
  );

  const handleChangeCalories = React.useCallback(
    (value) => {
      setDirty(true);
      setNutritionEntry({
        ...nutritionEntry,
        calories: validateInputNumber(value, nutritionEntry.calories),
      });
    },
    [setNutritionEntry, nutritionEntry, validateInputNumber],
  );

  const handleChangeProtein = React.useCallback(
    (value) => {
      setDirty(true);
      setNutritionEntry({
        ...nutritionEntry,
        proteinGrams: validateInputNumber(
          value,
          nutritionEntry.proteinGrams,
          "0.0",
        ),
      });
    },
    [setNutritionEntry, nutritionEntry, validateInputNumber],
  );

  const handleDateChange = React.useCallback(
    (date) => {
      setDirty(true);
      setNutritionEntry({
        ...nutritionEntry,
        date,
      });
    },
    [setNutritionEntry, nutritionEntry],
  );

  React.useEffect(() => {
    if (target?.commentType === nutritionComments.FULL_MACROS) {
      const calories =
        +nutritionEntry.fatGrams * 9 +
        +nutritionEntry.proteinGrams * 4 +
        +nutritionEntry.carbsGrams * 4;
      setNutritionEntry({
        ...nutritionEntry,
        calories: calories.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNutritionEntry,
    nutritionEntry.fatGrams,
    nutritionEntry.proteinGrams,
    nutritionEntry.carbsGrams,
  ]);

  React.useEffect(() => {
    setTarget(defaultTarget);
  }, [defaultTarget]);

  const calculateProgress = React.useCallback((currentValue, targetValue) => {
    const value = (100 / +targetValue) * +currentValue;
    return value > 100 ? 100 : value;
  }, []);

  return (
    <>
      <Box className={clsx(s.target)}>
        {mdUp ? (
          <>
            <Box className={t.tableTarget}>
              <Typography
                variant="body1"
                children={nutritionTargetsLabels[target.targetType]}
                className={s.targetText}
              />
            </Box>
            <Box className={t.tableDate}>
              <Typography
                variant="body1"
                children={dayjs(target.date, "YYYY-MM-DD").format(
                  "MMM DD YYYY",
                )}
                className={s.targetTextBold}
              />
            </Box>
            <Box className={t.tableProtein}>
              <div className={s.targetTextBold}>
                {/*parseInt(target.clientNutritionEntry?.proteinGrams) || 0*/}
                {parseInt(target.protein) || 0}
              </div>
              {/* {target.commentType !== nutritionComments["TRACKING_ONLY"] &&
                target.commentType !== nutritionComments["CALORIES_ONLY"] && (
                  <ProgressLine
                    value={calculateProgress(
                      target.clientNutritionEntry?.proteinGrams,
                      target.proteinGrams,
                    )}
                    className={s.progress}
                    classNameTrack={s.progressTrack}
                  />
                )} */}
            </Box>
            <Box className={t.tableCarbs}>
              <div className={s.targetTextBold}>
                {/*parseInt(target.clientNutritionEntry?.carbsGrams) || 0*/}
                {parseInt(target.carbs) || 0}
              </div>
              {/*target.commentType === nutritionComments["FULL_MACROS"] && (
                <ProgressLine
                  value={calculateProgress(
                    target.clientNutritionEntry?.carbsGrams,
                    target.carbsGrams,
                  )}
                  className={s.progress}
                  classNameTrack={s.progressTrack}
                />
                  ) */}
            </Box>
            <Box className={t.tableFat}>
              <div className={s.targetTextBold}>
                {/*parseInt(target.clientNutritionEntry?.fatGrams) || 0*/}
                {parseInt(target.fat) || 0}
              </div>
              {/* target.commentType === nutritionComments["FULL_MACROS"] && (
                <ProgressLine
                  value={calculateProgress(
                    target.clientNutritionEntry?.fatGrams,
                    target.fatGrams,
                  )}
                  className={s.progress}
                  classNameTrack={s.progressTrack}
                />
                  ) */}
            </Box>
            <Box className={t.tableCalories}>
              <div className={s.targetTextBold}>
                {/*parseInt(target.clientNutritionEntry?.calories) || 0*/}
                {parseInt(target.calories) || 0}
              </div>
              {/*(target.commentType === nutritionComments["FULL_MACROS"] ||
                target.commentType === nutritionComments["PROTEIN_CALORIES"] ||
                target.commentType === nutritionComments["CALORIES_ONLY"]) && (
                <ProgressLine
                  value={calculateProgress(
                    target.clientNutritionEntry?.calories,
                    target.calories,
                  )}
                  className={s.progress}
                  classNameTrack={s.progressTrack}
                />
                  )*/}
            </Box>
            <Box className={clsx(t.tableComments, s.comments)}>
              <Typography
                variant="body1"
                children={nutritionCommentsLabels[target.commentType]}
                className={s.targetTextBold}
              />
            </Box>
            {/* <Box className={t.tableAction}>
              <IconButton
                className={s.button}
                children={!Boolean(client) ? <PlusIcon /> : <HistoryIcon />}
                {...bindToggle(drawerState)}
              />
            </Box> */}
          </>
        ) : (
          <>
            <Box className={s.itemMobile}>
              <Typography
                variant="body1"
                children="Start date"
                className={s.targetTextLabel}
              />
              <Typography
                variant="body1"
                children={dayjs(target.date, "YYYY-MM-DD").format(
                  "MMM DD YYYY",
                )}
                className={s.targetTextBold}
              />
            </Box>
            <Box className={s.itemMobile}>
              <Typography
                variant="body1"
                children="Protein"
                className={s.targetTextLabel}
              />
              <div className={s.targetTextBold}>
                {/* {parseInt(target.clientNutritionEntry?.proteinGrams) || 0} */}
                {parseInt(target.proteinGrams) || 0}
              </div>
            </Box>
            <Box className={s.itemMobile}>
              <Typography
                variant="body1"
                children="Carbs"
                className={s.targetTextLabel}
              />
              <div className={s.targetTextBold}>
                {/* {parseInt(target.clientNutritionEntry?.carbsGrams) || 0} */}
                {parseInt(target.carbsGrams) || 0}
              </div>
            </Box>
            <Box className={s.itemMobile}>
              <Typography
                variant="body1"
                children="Fat"
                className={s.targetTextLabel}
              />
              <div className={s.targetTextBold}>
                {/* {parseInt(target.clientNutritionEntry?.fatGrams) || 0} */}
                {parseInt(target.fatGrams) || 0}
              </div>
            </Box>
            <Box className={s.itemMobile}>
              <Typography
                variant="body1"
                children="Kcal"
                className={s.targetTextLabel}
              />
              <div className={s.targetTextBold}>
                {/* {parseInt(target.clientNutritionEntry?.calories) || 0} */}
                {parseInt(target.calories) || 0}
              </div>
            </Box>
            <Box className={clsx(s.itemMobile)}>
              <Typography
                variant="body1"
                children="Comments"
                className={s.targetTextLabel}
              />
              <Typography
                variant="body1"
                children={nutritionCommentsLabels[target.commentType]}
                className={s.targetTextBold}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
