import { SOMETHING_WENT_WRONG } from "../../../constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import WorkspacesService from "../../../services/WorkspacesService";
import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import { COACH_SETTINGS_THEME_QUERY_KEY } from "../../../routes/coach/settings/theme/CoachSettingsThemeRoute";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { useCurrentUserId, UserInfo } from "../../../hooks/useCurrentUser";

export const useUpdateThemeMutation = (props?: UseReactQueryPropsBase) => {
  const queryClient = useQueryClient();
  const userId = useCurrentUserId();
  return useMutation({
    mutationKey: ["update-coach-workspace-theme"],
    mutationFn: WorkspacesService.updateCoachWorkspaceTheme,
    onSuccess: (result, variables) => {
      // TODO_API_V2: use optimistic update
      queryClient.setQueryData([COACH_SETTINGS_THEME_QUERY_KEY], variables);
      queryClient.setQueryData([CURRENT_USER_QUERY_KEY], (prev: UserInfo) => {
        return {
          ...prev,
          workspace: {
            ...prev.workspace,
            primaryColor: variables.primaryColor,
          } as any,
        };
      });
      props?.onSuccess && props.onSuccess(result);
    },
  });
};
