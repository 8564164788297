import clsx from "clsx";
import React from "react";
import { Menu, MenuProps, MenuItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ThreadsDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "9999 !important" as any,
  },
  listItem: {
    margin: theme.spacing(0, 0.5),
    borderRadius: 2,
    "&:hover": {
      background: theme.palette.selected.main,
    },
    minWidth: 220,
  },

  listItemText: {
    fontWeight: 500,
  },
}));

export interface ThreadMenuProps extends MenuProps {
  thread: ThreadsDto;
  handleToggleArchive: (event: any) => void;
  handleToggleRead: (event: any) => void;
  disabled?: boolean;
}

export function ThreadMenu(props: ThreadMenuProps) {
  const {
    className,
    thread,
    onClose,
    handleToggleArchive,
    handleToggleRead,
    disabled,
    ...other
  } = props;
  const { id, read } = thread;
  const s = useStyles();

  return (
    <Menu
      id={id.toString()}
      MenuListProps={{ dense: true }}
      onClose={onClose}
      className={clsx(s.root, className)}
      {...other}
    >
      <MenuItem
        className={s.listItem}
        onClick={handleToggleArchive}
        disabled={disabled}
        dense
      >
        <ListItemText
          primary="Archive"
          primaryTypographyProps={{
            className: s.listItemText,
          }}
        />
      </MenuItem>

      <MenuItem
        className={s.listItem}
        onClick={handleToggleRead}
        disabled={disabled}
        dense
      >
        <ListItemText
          primary={read ? "Mark as Unread" : "Mark as Read"}
          primaryTypographyProps={{
            className: s.listItemText,
          }}
        />
      </MenuItem>
    </Menu>
  );
}
