import clsx from "clsx";
import React from "react";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDebounce } from "../../hooks/useDebounce";
import { ExerciseAsset } from "../exercise-library/types";
import CustomAssetsService from "../../services/CustomAssetsService";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import { keepPreviousData } from "@tanstack/react-query";
import ExerciseAutocompleteOption from "./ExerciseAutocompleteOption";
import { useCurrentUserId } from "../../hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    margin: 0,
  },

  popper: {
    zIndex: "99999 !important" as any,
    borderRadius: 12,
    boxShadow: theme.shadows[5],
  },
}));

export interface ExerciseAutocompleteProps
  extends Omit<
    AutocompleteProps<ExerciseAsset, true, false, any, any>,
    "options" | "onChange" | "onInputChange" | "renderInput"
  > {
  pageSize?: number;
  onChange?: (value: ExerciseAsset) => void;
  onInputChange?: (
    e: any,
    value: string,
    options: {
      assetId: number;
      userId: number;
      exercise: any;
    }[],
  ) => void;
  renderCustomInput?: (
    params: AutocompleteRenderInputParams,
    isLoading: boolean,
  ) => React.ReactNode;
}

export const getExerciseAssetLabel = (option: string | ExerciseAsset) => {
  if (typeof option === "string") {
    return option;
  }
  const {
    exercise: { title },
  } = option;
  return title;
};

export const EXERCISE_AUTOCOMPLETE_QUERY_KEY = "exercise-autocomplete";
const EXERCISE_AUTOCOMPLETE_PAGE_SIZE = 20;

export function ExerciseAutocomplete(props: ExerciseAutocompleteProps) {
  const {
    className,
    pageSize = 50,
    inputValue,
    onChange,
    onInputChange,
    classes,
    ...other
  } = props;

  const delayedQuery = useDebounce(inputValue, 250);
  const userId = useCurrentUserId();
  const {
    data: customAssetsData,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [EXERCISE_AUTOCOMPLETE_QUERY_KEY, { query: delayedQuery }],
    queryFn: ({ pageParam = 1 }) =>
      CustomAssetsService.getWorkoutCustomAssets(
        pageParam as number,
        EXERCISE_AUTOCOMPLETE_PAGE_SIZE,
        inputValue === "" ? undefined : inputValue,
      ),
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const exerciseAssets =
    customAssetsData?.pages.flatMap((page) => page.items) ?? [];

  const options = React.useMemo(
    () =>
      exerciseAssets?.map((a) => ({
        assetId: a.id,
        userId: a.coachId,
        exercise: JSON.parse(a.content),
      })),
    [exerciseAssets],
  );

  const s = useStyles();

  const handleChange = React.useCallback(
    (_, value: ExerciseAsset) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );
  return (
    <Autocomplete
      classes={{
        root: clsx(s.root, className),
        paper: s.paper,
        popper: s.popper,
        ...classes,
      }}
      forcePopupIcon={false}
      filterOptions={(options) => options}
      inputValue={inputValue}
      clearOnBlur={false}
      options={options}
      renderOption={(props, option) => {
        const canEdit = option.userId === userId;
        return ExerciseAutocompleteOption(
          props,
          option,
          {
            option: clsx(classes?.option),
          },
          canEdit,
        );
      }}
      noOptionsText={"No exercises found."}
      getOptionLabel={getExerciseAssetLabel}
      onChange={handleChange as any}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (
            Math.round(listboxNode.scrollTop) + listboxNode.clientHeight >
            listboxNode.scrollHeight - 100
          ) {
            if (!isFetching && hasNextPage) fetchNextPage();
          }
        },
      }}
      onInputChange={(_, value) => onInputChange(_, value, options)}
      disableClearable={false}
      renderInput={(params) => props.renderCustomInput(params, isFetching)}
      {...other}
    />
  );
}
