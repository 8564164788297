import clsx from "clsx";
import React from "react";
import { List, ListProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SortableListItemProps } from "./SortableListItem";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface SortableListProps<
  T,
  P extends SortableListItemProps<T> = SortableListItemProps<T>,
> extends ListProps {
  options: T[];
  itemType: string;
  onUpdate?: (options: T[], move?: Move) => void;
  ListItem: React.FC<P>;
  ListItemProps?: Partial<P>;
  getKey?: (it: T, index: number) => React.ReactText;
  disabled?: boolean;
}

type Move = [number, number];

function sortOptions<T>(options: T[], move?: Move): T[] {
  const result = [...options];

  if (move) {
    result.splice(move[1], 0, result.splice(move[0], 1)[0]);
  }

  return result;
}

const getKeyDefault = (it: any, index: number) => it.id || index;

export function SortableList<T>(props: SortableListProps<T>) {
  const {
    className,
    itemType,
    options: _options,
    onUpdate,
    ListItem,
    ListItemProps,
    getKey = getKeyDefault,
    disabled,
    ...other
  } = props;
  const s = useStyles();
  const [localOptions, setLocalOptions] = React.useState<T[]>(_options);
  const [move, setMove] = React.useState<Move>();
  const options = sortOptions(localOptions, move);

  React.useEffect(() => {
    setLocalOptions(_options);
  }, [_options]);

  const handleMove: SortableListItemProps<T>["onMove"] = React.useCallback(
    (dragPosition, hoverPosition) => {
      if (!disabled) {
        setMove([dragPosition, hoverPosition]);
      }
    },
    [disabled],
  );

  const handleMoveEnd = React.useCallback(() => {
    if (move) {
      const [fromIndex, toIndex] = move;

      if (fromIndex !== toIndex) {
        const updatedOptions = sortOptions(localOptions, move);
        setLocalOptions(updatedOptions);
        if (onUpdate) {
          onUpdate(updatedOptions, move);
        }
      }

      setMove(null);
    }
  }, [move, onUpdate, localOptions]);

  return (
    <List className={clsx(s.root, className)} {...other}>
      {options.map((option, index) => (
        <React.Fragment key={getKey(option, index)}>
          <ListItem
            itemType={itemType}
            position={index}
            option={option}
            divider={index < options.length - 1 || undefined}
            onMove={handleMove}
            onMoveEnd={handleMoveEnd}
            {...ListItemProps}
          />
        </React.Fragment>
      ))}
    </List>
  );
}
