import React, { ReactNode, ReactElement } from "react";
import { Container, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { NavigationItem } from "../../constants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { AdminBreadcrumbs } from "../admin/AdminBreadcrumbs";

import { AdminBar } from "./AdminBar";
import { AdminDrawer } from "./AdminDrawer";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#root": {
      paddingLeft: "0px !important",
    },
  },

  container: {
    marginTop: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(11),
      marginTop: theme.spacing(7),
    },
  },

  header: {
    fontWeight: "normal",
    fontSize: 28,
    margin: theme.spacing(2, 0),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 0),
      fontSize: 32,
    },
  },
}));

type Props = {
  appBar?: boolean;
  appBarHideOnMobile?: boolean;
  children: ReactNode;
  title?: string;
  actions?: Array<ReactElement>;
  breadcrumbs?: NavigationItem[];
  trackInfo?: {
    name?: string;
    properties?: Object;
  };
};

export function AdminLayout(props: Props) {
  const {
    appBar = true,
    appBarHideOnMobile,
    title,
    actions,
    breadcrumbs,
    trackInfo,
    children,
  } = props;
  const [, trackPage] = useAnalytics();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();
  const s = useStyles();

  window.document.title = title ?? "Stridist";

  if (trackInfo) {
    trackPage(trackInfo.name, trackInfo.properties);
  }

  const toggleDrawer = React.useCallback(() => {
    setDrawerOpen((x) => !x);
  }, []);

  const closeDrawer = React.useCallback(() => {
    setDrawerOpen(false);
  }, []);

  return (
    <React.Fragment>
      {appBar && (
        <AdminBar
          title={title}
          actions={actions}
          onMenuClick={toggleDrawer}
          hideOnMobile={appBarHideOnMobile}
        />
      )}
      {appBar && (
        <AdminDrawer
          open={drawerOpen}
          onClose={closeDrawer}
          location={location}
        />
      )}

      <Container className={s.container}>
        <AdminBreadcrumbs breadcrumbs={breadcrumbs} />

        {children}
      </Container>
    </React.Fragment>
  );
}

export default AdminLayout;
