import { groupsClient } from "../api/ApiClients";
import {
  ArchiveGroupCommand,
  CreateGroupCommand,
  GroupOrderBy,
  IArchiveGroupCommand,
  ICreateGroupCommand,
  IPaginatedListOfGroupDto,
  IUpdateGroupCommand,
  UpdateGroupCommand,
} from "@growth-machine-llc/stridist-api-client";
import { GroupSort, GroupStatus } from "../constants";
import ServiceBase from "./ServiceBase";

class GroupsService extends ServiceBase {
  async getGroups(
    status: GroupStatus,
    orderBy: GroupSort,
    pageNumber: number,
    pageSize: number,
    searchQueryName?: string | null | undefined,
  ): Promise<IPaginatedListOfGroupDto> {
    return groupsClient.getGroups(
      status,
      orderBy as unknown as GroupOrderBy,
      pageNumber,
      pageSize,
      searchQueryName,
    );
  }

  async getGroup(slug: string, membersToTake?: number) {
    return groupsClient.getGroupBySlug(slug, membersToTake ?? 10);
  }

  async getGroupSettings(groupId: number) {
    return groupsClient.getGroupSettings(groupId);
  }

  async getGroupMembers(
    groupId: number,
    pageNumber: number,
    pageSize: number,
    searchQueryName?: string | null | undefined,
  ) {
    return groupsClient.getGroupMembers(
      groupId,
      pageNumber,
      pageSize,
      searchQueryName,
    );
  }

  async deleteGroup(id: number) {
    return groupsClient.deleteGroup(id)
  }

  async archiveGroup(data: IArchiveGroupCommand) {
    const command = ArchiveGroupCommand.fromJS(data);
    return groupsClient.archiveGroup(command.id, command);
  }

  async createGroup(data: ICreateGroupCommand) {
    const command = CreateGroupCommand.fromJS(data);
    return groupsClient.createGroup(command)
  }

  async updateGroup(data: IUpdateGroupCommand) {
    const command = UpdateGroupCommand.fromJS(data);
    return groupsClient.updateGroup(command.id, command);
  }
}

export default new GroupsService();
