import {
  IReorderClientGoalsCommand,
  PaginatedListOfClientGoalDto,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { CLIENT_GOALS_LIST_QUERY_KEY } from "../../card/ClientGoalsCard";
import ClientGoalsClientService from "../../../services/ClientGoalsClientService";

export const useChangeClientGoalOrdinalMutation = (clientId: number) => {
  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_GOALS_LIST_QUERY_KEY, { clientId }],
    mutationFn: ClientGoalsClientService.changeGoalOrder,
    optimisticUpdater: {
      updateFn: (
        store: PaginatedListOfClientGoalDto,
        variables: IReorderClientGoalsCommand,
      ) => {
        for (const id in variables.idsToOrdinals) {
          const goal = store.items?.find(
            (clientGoal) => clientGoal.id === parseInt(id),
          );
          if (goal) {
            goal.ordinal = variables.idsToOrdinals[id];
          }
        }

        store.items?.sort((a, b) => a.ordinal - b.ordinal);
        return store;
      },
    },
  });
};
