import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { RefreshSlug } from "../../../../../components/routes/RefreshSlug";
import { Activity } from "../../../../../components/activity/Activity";
import AppLayout from "../../../../../components/app/AppLayout";
import { DefaultLoader } from "../../../../../components/loading/DefaultLoader";
import { useActivityComponent } from "../../../../../hooks/activities/useActivity";

export function CoachClientProgramComponentRoute() {
  const { username, program, date, component } = useParams();

  const [searchParams] = useSearchParams();
  const summary = searchParams.get("summary");

  const {
    activity,
    isLoadingInitial,
    isSecondaryLoading: isFetching,
  } = useActivityComponent({
    username,
    date,
    componentSlug: component,
  });

  return !isLoadingInitial ? (
    <AppLayout appBar={false}>
      <RefreshSlug
        slugInfo={[
          {
            slug: activity?.component?.slug,
            slugId: activity?.component?.slugId,
          },
          // TODO_API_V2_REFRESH_SLUG: Consider retrieving slug from program.
          // Currently not urgent since user can not trigger slug change on this page.
          // {
          //   slug: program?.slug,
          //   slugId: program?.slugId,
          // },
        ]}
      />
      <Activity
        activity={activity}
        coach
        client={{ username: username }}
        isLoadingActivity={isFetching}
        shouldFetchSummary={summary === "yes"}
        program={program}
      />
    </AppLayout>
  ) : (
    <DefaultLoader fillScreen />
  );
}
