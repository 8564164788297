import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import {
  predicateTwoPartQueryKey,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import { ACTIVITY_LIST_QUERY_KEY } from "../CoachActivity";
import { IActivitiesCompositeCursorPaginatedList } from "@growth-machine-llc/stridist-api-client";
import ActivitiesService from "../../../services/ActivitiesService";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { UserInfo } from "../../../hooks/useCurrentUser";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";

export const useCoachReadAnswerMutation = () => {
  const queryClient = useQueryClient();
  const updateItemPropertiesUpdater =
    INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties;
  return useOptimisticUpdateMutation({
    disableToastAlerts: true,
    queryKey: [ACTIVITY_LIST_QUERY_KEY],
    exactQueryKey: false,
    mutationFn: ActivitiesService.coachReadActivity,
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<IActivitiesCompositeCursorPaginatedList>,
        activityId,
      ) => {
        return oldData
          ? updateItemPropertiesUpdater(oldData, activityId, {
              coachReadAnswers: true,
            })
          : oldData;
      },
    },
    options: {
      onSuccess: () => {
        queryClient.setQueryData(
          [CURRENT_USER_QUERY_KEY],
          (oldData: UserInfo) => ({
            ...oldData,
            unreadActivitiesCount: Math.max(
              0,
              oldData.unreadActivitiesCount - 1,
            ),
          }),
        );
      },
    },
  });
};
