import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import {
  createEmptyExercise,
  DEFAULT_NUMBER_OF_EXERCISE_SETS,
  DEFAULT_VALUE_OF_EXERCISE_REPS,
} from "../../../../../components/workout/utils";
import { addWorkoutExercise } from "./operation";

export const addWorkoutExerciseAction = {
  prepare(componentId: number, workoutSectionId: number, exerciseId?: number) {
    return {
      payload: {
        componentId,
        workoutSectionId,
        exerciseId,
        newExercise: createEmptyExercise(
          DEFAULT_NUMBER_OF_EXERCISE_SETS,
          DEFAULT_VALUE_OF_EXERCISE_REPS,
        ),
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      componentId: number;
      workoutSectionId: number;
      exerciseId: number;
      newExercise;
    }>,
  ) {
    const { componentId, workoutSectionId, exerciseId, newExercise } =
      action.payload;
    const component = state.value.components[componentId];
    if (!component) return;

    const updatedContent = addWorkoutExercise(
      workoutSectionId,
      exerciseId,
      component.content,
      newExercise,
    );

    state.value.components[componentId] = {
      ...component,
      content: updatedContent,
    };
  },
};
