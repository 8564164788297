import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Divider, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useDebounce } from "../../hooks/useDebounce";

import {
  ExerciseLibraryExercise,
  ExerciseLibraryExerciseProps,
} from "./ExerciseLibraryExercise";

import { ExerciseLibraryEmpty } from "./ExerciseLibraryEmpty";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import CustomAssetsService from "../../services/CustomAssetsService";
import { CustomAssetType } from "../../constants";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { DefaultLoader } from "../loading/DefaultLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(-4),
  },

  divider: {
    backgroundColor: theme.palette.quote,
  },

  subHeader: {
    textTransform: "uppercase",
    fontWeight: 700,
  },

  moreButton: {
    marginTop: theme.spacing(1.5),
  },

  loading: {
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

// TODO_API_V2 separate this and autocomplete query from asset library
export const EXERCISE_LIBRARY_ASSET_LIST_QUERY_KEY =
  "exercise-library-asset-list";
const EXERCISE_LIBRARY_ASSET_LIST_PAGE_SIZE = 20;
const FETCH_NEXT_PAGE_WHEN_REMAINING = 4;

export interface ExerciseLibraryExercisesListProps
  extends Omit<BoxProps, "onClick">,
    Pick<ExerciseLibraryExerciseProps, "onClick"> {
  pageSize?: number;
  query?: string;
}

export function ExerciseLibraryExercisesList(
  props: ExerciseLibraryExercisesListProps,
) {
  const { className, pageSize = 12, query = "", onClick, ...other } = props;
  const s = useStyles();

  const delayedQuery = useDebounce(query, 250);
  const {
    data: customAssetsData,
    ref: componentListElementRef,
    isLoading: isLoadingInitial,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [
      EXERCISE_LIBRARY_ASSET_LIST_QUERY_KEY,
      { query: delayedQuery, assetTypes: [CustomAssetType.WORKOUT_EXERCISE] },
    ],
    queryFn: ({ pageParam = 1 }) =>
      CustomAssetsService.getWorkoutCustomAssets(
        pageParam as number,
        EXERCISE_LIBRARY_ASSET_LIST_PAGE_SIZE,
        query === "" ? undefined : query,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });
  const assets = customAssetsData?.pages.flatMap((page) => page.items) ?? [];

  if (isLoadingInitial) {
    return <DefaultLoader />;
  }
  if (!assets.length) {
    return <ExerciseLibraryEmpty searchResults={!!query} />;
  }

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {assets ? (
        <>
          {assets.map((asset, index) => (
            <div key={asset.id}>
              {index !== 0 && <Divider className={s.divider} />}
              <ExerciseLibraryExercise
                asset={{
                  id: asset.id!, // TODO_V2_API why response is nullable
                  name: asset.name!,
                  content: asset.content!,
                  coachId: asset.coachId!,
                }}
                onClick={onClick}
              />
              {index === assets.length - FETCH_NEXT_PAGE_WHEN_REMAINING && (
                <div ref={componentListElementRef} />
              )}
            </div>
          ))}

          {hasNextPage && (
            <LoadMoreButton
              onClick={() => fetchNextPage()}
              loading={isFetchingNextPage}
              disabled={isLoadingInitial}
            />
          )}
        </>
      ) : (
        <Box className={s.loading}>
          <DefaultLoader fillParent size="large" />
        </Box>
      )}
    </Box>
  );
}
