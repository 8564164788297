import clsx from "clsx";
import React, { useMemo } from "react";
import {
  Card,
  CardProps,
  Typography,
  CardMedia,
  CardContent,
  Grid,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";
import { ReactComponent as ProgressPhotosIcon } from "../../icons/ProgressPhotos.svg";

import { ConfirmActionProgressPhotos } from "./ConfirmActionProgressPhotos";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { WorkoutMediaGallery } from "../dialog/WorkoutMediaGallery";
import AddCircle from "../../icons/AddCircle";
import ProgressPhotoService from "../../services/ProgressPhotoService";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { CardPagination } from "../pagination/CardPagination";
import {
  IPaginatedProgressPhotoDto,
  ICreateProgressPhotoSetCommand,
  PaginatedListOfPaginatedProgressPhotoDto,
  PaginatedProgressPhotoDto,
  CreatedSetIds,
} from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";
import { ActiveProgramSkeletonCard } from "../loading/ActiveProgramSkeletonCard";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { predicateTwoPartQueryKey } from "../../hooks/useOptimisticUpdateMutation";
import { capitalize, startCase } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  button: {
    color: colorSystem.black,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },

  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(4),
    textAlign: "center",
  },

  image: {
    height: 264,
    cursor: "pointer",
  },

  content: {
    padding: `${theme.spacing(0.75, 1.5)} !important`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  date: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "26px",
    color: theme.palette.common.black,
  },

  label: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3.4),
  },
  paginationItem: {
    "& .Mui-selected:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-selected": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
  },
  imageContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
      overflow: "scroll",
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  images: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "70%",
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
}));

const ProgressPhotoSkeleton = () => {
  const s = useStyles();
  return (
    <>
      <Card>
        <CardMedia className={s.image} />
        <Box padding={(theme) => theme.spacing(1.1, 1.5, 0.75, 1.5)}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={16}
            width="38%"
          />
        </Box>
        <Box padding={(theme) => theme.spacing(0.1, 1.5, 1.75, 1.5)}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={16}
            width="14%"
          />
        </Box>
      </Card>
    </>
  );
};

export interface ClientProgressPhotosCardProps extends CardProps {
  clientId: number;
}

export const CLIENT_PROGRESS_PHOTOS_PAGE_QUERY_KEY =
  "client-progressphotos-page";
const CLIENT_PROGRESS_PHOTOS_PAGE_SIZE = 6;

export function ClientProgressPhotosCard(props: ClientProgressPhotosCardProps) {
  const { className, clientId, ...other } = props;
  const s = useStyles();
  const queryClient = useQueryClient();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const user = useCurrentUser();
  const id = clientId;
  const [currentPage, setCurrentPage] = React.useState(1);

  const {
    data,
    isFetching,
    isLoading: isFirstPageFirstFetch,
    isPlaceholderData: isPrevPageData,
  } = useQuery({
    queryKey: [
      CLIENT_PROGRESS_PHOTOS_PAGE_QUERY_KEY,
      { clientId: id, currentPage },
    ],
    queryFn: () =>
      ProgressPhotoService.getPhotos(
        currentPage,
        CLIENT_PROGRESS_PHOTOS_PAGE_SIZE,
        id,
      ),
    placeholderData: keepPreviousData,
    select: (data) => ({
      ...data,
      items: data.items.map((p) => ({
        ...p,
        takenOn: dayjs(p.takenOn).format("MMM DD, YYYY"),
      })),
    }),
  });

  const photosList = data?.items ?? [];

  const { mutate: deletePhotoFromList, isPending: deleting } = useMutation({
    mutationKey: ["delete-signle-progress-photo"],
    mutationFn: ProgressPhotoService.deleteSinglePhoto,
    onSuccess: () => {
      // invalidate all pages after the current page - no refetch
      queryClient.invalidateQueries({
        predicate: (query) =>
          predicateTwoPartQueryKey(
            query.queryKey,
            CLIENT_PROGRESS_PHOTOS_PAGE_QUERY_KEY,
            (params) => {
              return (
                params?.clientId === id && params?.currentPage > currentPage
              );
            },
          ),
        queryKey: [CLIENT_PROGRESS_PHOTOS_PAGE_QUERY_KEY],
        refetchType: "none",
      });

      // invalidate current page - with refetch
      queryClient.invalidateQueries({
        queryKey: [
          CLIENT_PROGRESS_PHOTOS_PAGE_QUERY_KEY,
          { clientId: id, currentPage },
        ],
      });
    },
  });

  const [visibleAction, setVisibleAction] = React.useState(false);
  const [visibleActionDelete, setVisibleActionDelete] = React.useState(false);
  const [chooseImage, setChooseImage] = React.useState<number>(null);
  const [selectedMediaIndex, setSelectedMediaIndex] = React.useState(0);
  const [openMedia, setOpenMedia] = React.useState(false);
  const [isOpenContent, setIsOpenContent] = React.useState(true);

  const updatePhotosList = React.useCallback(
    (ids: CreatedSetIds, vars: ICreateProgressPhotoSetCommand) => {
      // construct the list of new photos
      const queryUpdateInterface: IPaginatedProgressPhotoDto[] =
        vars.photos.map((p) => ({
          id: ids.photoIds[p.title],
          title: p.title,
          url: p.url,
          fileName: p.fileName,
          size: p.size,
          progressPhotoSetId: ids.setId,
          takenOn: dayjs().format("MMM DD, YYYY"),
        }));
      const queryUpdateData: PaginatedProgressPhotoDto[] =
        queryUpdateInterface.map((i) => PaginatedProgressPhotoDto.fromJS(i));

      // upsert the new photos into the first page
      queryClient.setQueryData(
        [CLIENT_PROGRESS_PHOTOS_PAGE_QUERY_KEY, { clientId, currentPage: 1 }],
        (
          prev: PaginatedListOfPaginatedProgressPhotoDto,
        ): PaginatedListOfPaginatedProgressPhotoDto => {
          const remains = prev.items.slice(0, -queryUpdateData.length);
          return {
            ...prev,
            init: prev.init,
            toJSON: prev.toJSON,
            items: [...queryUpdateData, ...remains],
          };
        },
      );

      // redirect to the first page
      setCurrentPage(1);

      // invalidate the cache for all pages without refetching
      queryClient.invalidateQueries({
        queryKey: [CLIENT_PROGRESS_PHOTOS_PAGE_QUERY_KEY],
        refetchType: "none",
      });
    },
    [queryClient, clientId],
  );

  const theme = useTheme();

  const handleRemove = React.useCallback(() => {
    deletePhotoFromList(chooseImage, {
      onSuccess: () => {
        setVisibleActionDelete(false);
      },
    });
  }, [deletePhotoFromList, chooseImage]);

  const handleRemoveAction = React.useCallback(
    (id: number) => {
      setVisibleActionDelete(true);
      setChooseImage(id);
    },
    [setChooseImage],
  );

  const handleMediaClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const {
        dataset: { mediaIndex },
      } = event.currentTarget;

      setOpenMedia(true);
      setSelectedMediaIndex(parseInt(mediaIndex));
    },
    [],
  );

  const handleMediaDialogClose = React.useCallback(() => {
    setOpenMedia(false);
  }, []);

  // TODO_API_V2_TYPES: rename fields to match API and drop this mapping
  const galleryImages = useMemo(
    () =>
      photosList.map((photo) => ({
        url: photo.url,
        name: photo.title,
        createdAt: photo.takenOn,
        label: photo.title,
        idProgram: photo.progressPhotoSetId.toString(),
      })),
    [photosList],
  );

  const showCards = photosList?.length && !isPrevPageData;
  const showSkeleton = isFirstPageFirstFetch || (isFetching && isPrevPageData);

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Box className={s.headerContainer}>
          <ProgressPhotosIcon className={s.icon} />
          <Box>
            <Typography
              variant="h6"
              className={s.title}
              children="Progress photos"
            />
            {photosList.length ? (
              <Typography variant="body1" className={s.subtitle}>
                Last recorded {photosList[0].takenOn}
              </Typography>
            ) : null}
          </Box>
        </Box>
        {user.role !== UserRole.COACH && smUp ? (
          <Button
            className={s.button}
            startIcon={<AddCircle fill={theme.palette.primary.main} />}
            children="Add new photo"
            onClick={() => setVisibleAction(true)}
          />
        ) : (
          !smUp && (
            <ArrowIcon
              className={isOpenContent ? "" : s.arrowIconDown}
              onClick={() => setIsOpenContent(!isOpenContent)}
            />
          )
        )}
      </Box>
      {isOpenContent && showCards ? (
        <Grid container spacing={2} className={s.imageContainer}>
          {photosList.map(
            ({ id, url, title, takenOn, progressPhotoSetId }, index) => (
              <>
                <Grid key={url} item xs={12} sm={6} md={4} className={s.images}>
                  <Card>
                    <CardMedia
                      className={s.image}
                      image={url}
                      title={`${capitalize(title)}, ${takenOn}`}
                      onClick={handleMediaClick}
                      data-media-index={index}
                    />
                    <CardContent className={s.content}>
                      <Box>
                        <Typography
                          className={s.date}
                          variant="body1"
                          children={takenOn}
                        />
                        <Typography
                          className={s.label}
                          variant="body1"
                          children={capitalize(title)}
                        />
                      </Box>
                      {user.role !== UserRole.COACH && (
                        <IconButton
                          children={<DeleteIcon width={20} height={26} />}
                          onClick={() => handleRemoveAction(id)}
                          disabled={false}
                          size="large"
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ),
          )}
        </Grid>
      ) : showSkeleton ? (
        <Grid container spacing={2} className={s.imageContainer}>
          {Array.from({ length: CLIENT_PROGRESS_PHOTOS_PAGE_SIZE }).map(
            (_, i) => (
              <Grid key={i} item xs={12} sm={6} md={4} className={s.images}>
                <ProgressPhotoSkeleton />
              </Grid>
            ),
          )}
        </Grid>
      ) : (
        isOpenContent && (
          <Typography className={s.noText}>
            No progress photos to show.
          </Typography>
        )
      )}
      {!smUp && (
        <Box className={s.pagination}>
          {data?.totalPages > 1 && (
            <CardPagination
              page={currentPage}
              count={data?.totalPages}
              onChange={(_, page) => setCurrentPage(page)}
            />
          )}
        </Box>
      )}
      {smUp ? (
        <Box className={s.pagination}>
          {data?.totalPages > 1 && (
            <CardPagination
              page={currentPage}
              count={data?.totalPages}
              onChange={(_, page) => setCurrentPage(page)}
            />
          )}
        </Box>
      ) : (
        user.role !== UserRole.COACH &&
        !smUp &&
        isOpenContent && (
          <Button
            className={s.button}
            startIcon={<AddCircle fill={theme.palette.primary.main} />}
            children="Add new photo"
            onClick={() => setVisibleAction(true)}
          />
        )
      )}
      <ConfirmActionProgressPhotos
        title={"Progress photos"}
        onCancel={() => setVisibleAction(false)}
        onConfirm={() => {}}
        open={visibleAction}
        id={id}
        updatePhotosList={updatePhotosList}
      />
      <ConfirmActionDialog
        title="Are you sure you want to remove this result?"
        onCancel={() => setVisibleActionDelete(false)}
        onConfirm={handleRemove}
        open={visibleActionDelete}
        disabled={deleting}
      />
      {openMedia && (
        <WorkoutMediaGallery
          open
          defaultIndex={selectedMediaIndex}
          onClose={handleMediaDialogClose}
          images={galleryImages}
        />
      )}
    </Card>
  );
}
