import { createContext } from "react";

export interface IClientContextProps {
  coachClientQuery: any;
  setCoachClientQuery: (coachClientQuery: any) => void;
}

export const ClientContext = createContext<IClientContextProps | undefined>(
  undefined,
);
