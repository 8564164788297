import React from "react";
import clsx from "clsx";
import {
  Box,
  Typography,
  DialogProps,
  TextField,
  Divider,
  Button,
  Dialog,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton } from "../button/ActionButton";
import { colorSystem } from "../../theme";
import { useAnalytics } from "../../hooks/useAnalytics";
import { PendingInvitesList } from "../list/PendingInvitesList";
import { MultipleEmailsField } from "../fields/MultipleEmailsField";
import { AppError, ClientStatus, Plan, planClients } from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { InviteLinkSettings } from "../coach-clients/InviteLinkSettings";

import { BaseDialog } from "./BaseDialog";
import { InviteCodePreviewDialog } from "./InviteCodePreviewDialog";
import {
  BaseInviteCode,
  PaginatedListOfClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import InvitesService from "../../services/InvitesService";
import ClientsService from "../../services/ClientsService";
import InviteCodesService from "../../services/InviteCodesService";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import {
  COACH_CLIENTS_LIST_QUERY_KEY,
  INVITED_ADDED_CLIENTS_QUERY_KEY,
} from "../coach-clients/CoachClientsListScreen";
import LoadingActionButton from "../button/LoadingActionButton";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { CircularLoader } from "../loading/CircularLoader";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: 650,
    width: 650,
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.common.black,
  },

  inviteByLink: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "&$withInviteLink": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  inviteByLinkHeader: {
    "&:not($withInviteLink)": {
      width: "45%",
    },
  },

  divider: {
    width: `calc(100% + calc(${theme.spacing(3)} * 2))`,
    margin: theme.spacing(3, 0, 3, -3),
    backgroundColor: colorSystem.secondaryGray,
  },

  pendingInvitesList: {
    maxHeight: 264,
    overflowY: "auto",
  },

  messageInput: {
    cursor: "text",
    height: 240,

    "& > textarea": {
      height: "100% !important",
    },
  },

  buttons: {
    display: "flex",
    marginTop: theme.spacing(3),

    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },

  cancelButton: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    borderRadius: 4,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
    width: "30%",
    height: theme.spacing(7),

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginRight: theme.spacing(0),
    },

    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },

  titleActive: {
    textDecoration: "underline",
  },

  inviteButton: {
    flexGrow: 1,
    height: theme.spacing(7),

    [theme.breakpoints.down("lg")]: {
      width: "49%",
      margin: theme.spacing(1, 0, 1, 0),
      flexGrow: "initial",
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  inviteCode: {
    cursor: "pointer",
    textDecoration: "underline",
  },

  addButton: {
    margin: theme.spacing(0, 3, 0, 0),

    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1, 0, 1, 0),
    },
  },

  tabUsersStatus: {
    display: "flex",
  },

  titleAdded: {
    marginLeft: theme.spacing(2),
  },

  withInviteLink: {},
}));

const DEFAULT_MESSAGE =
  "Hello!\n\nI’d like to invite you to be my client on Stridist. It’s a coaching platform that will help us stay organized, chat with each other, and track your progress.\n\nIt takes less than a minute to join – see you there!";

export interface InviteDialogProps extends DialogProps {
  onInvite: () => void;
}

const INVITE_DIALOG_INVITE_CODE_QUERY_KEY = "invite-code";
const inviteDialogPageSize = 10;

export function InviteDialog(props: InviteDialogProps) {
  const { className, onInvite, open, ...other } = props;
  const queryClient = useQueryClient();

  const { brandName } = useCurrentBrand();
  const { showToastAlert } = useToastAlert();

  const {
    data: addedUsersData,
    ref: addedUsersRef,
    fetchNextPage: fetchAdded,
    isLoading: loadingAdded,
    isFetchingNextPage: fetchingNextAdded,
    hasNextPage: hasMoreAdded,
  } = useInfiniteScroll({
    queryKey: [
      COACH_CLIENTS_LIST_QUERY_KEY,
      {
        status: [UserInviteStatus.ADDED],
        isDialog: true,
      },
    ],
    queryFn: ({ pageParam }) =>
      ClientsService.getClientsFiltered(
        pageParam as number,
        inviteDialogPageSize,
        [UserInviteStatus.ADDED],
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage: PaginatedListOfClientInfoDto) =>
      lastPage.hasNextPage ? lastPage.pageNumber + 1 : undefined,
    enabled: open,
  });

  const {
    data: invitedUsersData,
    ref: invitedUsersRef,
    fetchNextPage: fetchInvited,
    isLoading: loadingInvited,
    isFetchingNextPage: fetchingNextInvited,
    hasNextPage: hasMoreInvited,
  } = useInfiniteScroll({
    queryKey: [
      COACH_CLIENTS_LIST_QUERY_KEY,
      {
        status: [UserInviteStatus.PENDING],
        isDialog: true,
      },
    ],
    queryFn: ({ pageParam }) =>
      ClientsService.getClientsFiltered(
        pageParam as number,
        inviteDialogPageSize,
        [UserInviteStatus.PENDING],
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage: PaginatedListOfClientInfoDto) =>
      lastPage.hasNextPage ? lastPage.pageNumber + 1 : undefined,
    enabled: open,
  });

  const addedUsers = addedUsersData?.pages.flatMap((page) => page.items);

  const invitedUsers = invitedUsersData?.pages.flatMap((page) => page.items);

  const s = useStyles();
  const [trackEvent] = useAnalytics();
  const [emails, setEmails] = React.useState<string[]>([]);
  const [message, setMessage] = React.useState<string>(DEFAULT_MESSAGE);
  const [tabUserStatus, setTabUserStatus] = React.useState<string>(
    ClientStatus.PENDING,
  );
  const [previewInviteCode, setPreviewInviteCode] = React.useState(false);
  const totalCount = {
    added: addedUsersData?.pages[0]?.totalCount || 0,
    invited: invitedUsersData?.pages[0]?.totalCount || 0,
  };

  const { data: inviteCode, isLoading: inviteCodeLoading } = useQuery({
    queryKey: [INVITE_DIALOG_INVITE_CODE_QUERY_KEY],
    queryFn: InviteCodesService.getNotAssignedCode,
  });

  const { mutate: generateCode, isPending: creatingCode } = useMutation({
    mutationFn: InviteCodesService.createNotAssignedCode,
    onSuccess: (data) => {
      queryClient.setQueriesData(
        {
          queryKey: [INVITE_DIALOG_INVITE_CODE_QUERY_KEY],
        },
        (oldData: BaseInviteCode) =>
          BaseInviteCode.fromJS({
            ...oldData,
            ...data,
          }),
      );
    },
  });

  const { mutate: deleteCode, isPending: deletingCode } =
    useOptimisticUpdateMutation({
      queryKey: [INVITE_DIALOG_INVITE_CODE_QUERY_KEY],
      mutationFn: InviteCodesService.deleteCode,
      optimisticUpdater: {
        updateFn: () => null,
      },
      disableToastAlerts: true,
    });

  const handleDeleteCode = React.useCallback(() => {
    if (inviteCode?.id) {
      deleteCode(inviteCode.id);
    }
  }, [deleteCode, inviteCode]);

  const user = useCurrentUser();

  const { mutate: inviteClients, isPending: inviting } = useMutation({
    mutationFn: InvitesService.batchInviteClientsByCode,
  });

  const { mutate: addClients, isPending: adding } = useMutation({
    mutationFn: ClientsService.addClients,
  });

  const handleEmailsChange = React.useCallback((values) => {
    setEmails(values);
  }, []);

  const handleMessageChange = React.useCallback(({ target: { value } }) => {
    setMessage(value);
  }, []);

  const handleCancel = React.useCallback(() => {
    setEmails([]);
  }, []);

  const handleInvite = React.useCallback(() => {
    if (
      user.clientsCountNoSample +
        emails.length +
        totalCount.invited +
        totalCount.added >
      Number(planClients(user.plan as Plan, true))
    ) {
      showToastAlert("error", {
        message: `Max ${planClients(user.plan as Plan)} clients`,
      });
    } else {
      inviteClients(
        {
          emails,
          message: message || DEFAULT_MESSAGE,
        },
        {
          onSuccess: (inviteClients) => {
            for (const email of emails) {
              trackEvent("Coach - Invite Client", { email });
            }

            setEmails([]);

            const { dialogInvitesInfos: invites, problems } = inviteClients;
            const severity = problems.length ? "error" : "success";
            const messages = [
              invites.length && `${invites.length} client successfully added.`,
              ...problems.map(({ message }) => message),
            ];

            onInvite();

            showToastAlert(severity, {
              message: messages.filter(Boolean).join("\n"),
            });
          },
        },
      );
    }
  }, [
    emails,
    inviteClients,
    message,
    showToastAlert,
    trackEvent,
    user,
    totalCount,
  ]);

  const handleAdd = React.useCallback(() => {
    if (
      user.clientsCountNoSample +
        emails.length +
        totalCount.invited +
        totalCount.added >
      Number(planClients(user.plan as Plan, true))
    ) {
      showToastAlert("error", {
        message: `Max ${planClients(user.plan as Plan)} clients`,
      });
    } else {
      addClients(
        {
          emails,
        },
        {
          onSuccess: (addClients) => {
            for (const email of emails) {
              trackEvent("Coach - Add Client", { email });
            }

            setEmails([]);

            const { dialogInvitesInfos, problems } = addClients;
            const severity = problems.length ? "error" : "success";
            const messages = [
              dialogInvitesInfos.length &&
                `${dialogInvitesInfos.length} client successfully added.`,
              ...problems.map(({ message }) => message),
            ];
            onInvite();
            showToastAlert(severity, {
              message: messages.filter(Boolean).join("\n"),
            });
          },
        },
      );
    }
  }, [emails, showToastAlert, trackEvent, user, addClients, totalCount]);

  const handlePreviewInviteCodeOpen = React.useCallback(
    () => setPreviewInviteCode(true),
    [],
  );

  const handlePreviewInviteCodeClose = React.useCallback(
    () => setPreviewInviteCode(false),
    [],
  );

  const hasInviteLink = Boolean(inviteCode?.code);
  const disabled = inviting || adding;

  return (
    <>
      {previewInviteCode ? (
        <InviteCodePreviewDialog
          open
          inviteCode={inviteCode}
          onClose={handlePreviewInviteCodeClose}
        />
      ) : (
        <BaseDialog
          className={clsx(s.root, className)}
          title={emails.length === 0 ? "Invite clients" : "Invite by email"}
          open={open}
          classes={{
            paper: s.paper,
          }}
          {...other}
        >
          <Box>
            {emails.length === 0 ? (
              <Typography className={s.title} variant="h3">
                Invite by Email
              </Typography>
            ) : (
              <Divider className={s.divider} />
            )}
            <MultipleEmailsField
              values={emails}
              onChange={handleEmailsChange}
              disabled={disabled}
            />
          </Box>

          <Divider className={s.divider} />

          {emails.length === 0 ? (
            <>
              <Box
                className={clsx(
                  s.inviteByLink,
                  hasInviteLink && s.withInviteLink,
                )}
              >
                <Box
                  className={clsx(
                    s.inviteByLinkHeader,
                    hasInviteLink && s.withInviteLink,
                  )}
                >
                  <Typography className={s.title} variant="h3">
                    Invite by Link
                  </Typography>
                  <Typography className={s.text} variant="body1">
                    Use this link to invite your clients.
                    {inviteCode?.link && (
                      <>
                        {" "}
                        You can also share your{" "}
                        <span
                          className={s.inviteCode}
                          onClick={handlePreviewInviteCodeOpen}
                        >
                          invite code
                        </span>
                        .
                      </>
                    )}
                  </Typography>
                </Box>

                <InviteLinkSettings
                  inviteCode={inviteCode}
                  generateCode={() => generateCode()}
                  generatingCode={creatingCode}
                  deleteCode={handleDeleteCode}
                  deletingCode={deletingCode}
                />
              </Box>

              <Divider className={s.divider} />

              <Box>
                <Box className={s.tabUsersStatus}>
                  <Typography
                    className={clsx(
                      s.title,
                      tabUserStatus === ClientStatus.PENDING && s.titleActive,
                    )}
                    variant="h3"
                    onClick={() => setTabUserStatus(ClientStatus.PENDING)}
                  >
                    Pending invites ({totalCount.invited})
                  </Typography>
                  <Typography
                    className={clsx(
                      s.title,
                      s.titleAdded,
                      tabUserStatus === ClientStatus.ADDED && s.titleActive,
                    )}
                    variant="h3"
                    onClick={() => setTabUserStatus(ClientStatus.ADDED)}
                  >
                    Added users ({totalCount.added})
                  </Typography>
                </Box>
                {tabUserStatus === ClientStatus.PENDING ? (
                  loadingAdded ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      padding={1}
                    >
                      <CircularLoader />
                    </Box>
                  ) : (
                    <PendingInvitesList
                      className={s.pendingInvitesList}
                      invites={invitedUsers}
                      loadMore={fetchInvited}
                      loadMoreRef={invitedUsersRef}
                      totalCount={totalCount}
                      fetching={fetchingNextInvited}
                      hasMore={hasMoreInvited}
                    />
                  )
                ) : loadingInvited ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    padding={1}
                  >
                    <CircularLoader />
                  </Box>
                ) : (
                  <PendingInvitesList
                    className={s.pendingInvitesList}
                    invites={addedUsers}
                    loadMore={fetchAdded}
                    loadMoreRef={addedUsersRef}
                    totalCount={totalCount}
                    fetching={fetchingNextAdded}
                    hasMore={hasMoreAdded}
                  />
                )}
              </Box>
            </>
          ) : (
            <Box>
              <TextField
                fullWidth
                multiline
                value={message}
                onChange={handleMessageChange}
                InputProps={{
                  disableUnderline: true,
                  className: s.messageInput,
                }}
              />
              <Box className={s.buttons}>
                <Button
                  className={s.cancelButton}
                  variant="outlined"
                  children="Cancel"
                  onClick={handleCancel}
                  disabled={disabled}
                />
                <LoadingActionButton
                  className={clsx(s.inviteButton, s.addButton)}
                  children="Add without invite"
                  onClick={handleAdd}
                  disabled={emails.length === 0 || disabled}
                  loading={adding}
                />
                <LoadingActionButton
                  className={s.inviteButton}
                  children={`Invite client${emails.length === 1 ? "" : "s"}`}
                  onClick={handleInvite}
                  disabled={emails.length === 0 || disabled}
                  loading={inviting}
                />
              </Box>
            </Box>
          )}
        </BaseDialog>
      )}
    </>
  );
}
