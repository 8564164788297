import clsx from "clsx";
import React from "react";
import { Button, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDropDown } from "@mui/icons-material";

import { ActivitySort, activitySortOptions } from "../../constants";
import { DropdownMenu } from "../menu/DropdownMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
  },

  active: {
    color: theme.palette.primary.main,
  },

  inactive: {
    color: theme.palette.text.secondary,
  },
}));

export interface ActivitySortFilterProps extends Omit<BoxProps, "onChange"> {
  sortedBy: ActivitySort;
  active?: boolean;
  onChange: (sortedBy: ActivitySort) => void;
}

export function ActivitySortFilter(props: ActivitySortFilterProps) {
  const { className, sortedBy, active, onChange, ...other } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLElement>();

  const triggerGenerator = React.useMemo(
    () => (bindTrigger, menuState) => (
      <Button
        className={clsx(s.root, active ? s.active : s.inactive, className)}
        ref={ref}
        {...bindTrigger(menuState)}
        {...other}
      >
        Sort by: {activitySortOptions[sortedBy]}
        <ArrowDropDown />
      </Button>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedBy],
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const {
        currentTarget: {
          dataset: { sortBy },
        },
      } = event;

      onChange(sortBy as ActivitySort);
    },
    [onChange],
  );

  const items = React.useMemo(
    () =>
      Object.entries(activitySortOptions).map(([sortBy, label]) => ({
        children: label,
        onClick: handleClick,
        data: { "sort-by": sortBy },
        selected: sortBy === sortedBy,
      })),
    [handleClick, sortedBy],
  );

  return (
    <DropdownMenu
      menuId="select-progress-period"
      items={items}
      triggerGenerator={triggerGenerator}
    />
  );
}
