import React from "react";
import { ProgramStatus } from "../../../../constants";
import { CoachProgramsListScreen } from "../../../../components/coach-programs/CoachProgramsListScreen";

export const CoachProgramsList = ({ status }: { status: ProgramStatus }) => {
  return <CoachProgramsListScreen status={status} />;
};

export const CoachProgramsPublishedRoute = () => {
  return <CoachProgramsList status={ProgramStatus.PUBLISHED} />;
};
export const CoachProgramsDraftRoute = () => {
  return <CoachProgramsList status={ProgramStatus.DRAFT} />;
};
export const CoachProgramsArchivedRoute = () => {
  return <CoachProgramsList status={ProgramStatus.ARCHIVED} />;
};
