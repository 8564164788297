import React, { useContext } from "react";
import EndWorkout from "../../../../../components/end-workout/EndWorkout";
import { useCurrentUser } from "../../../../../hooks/useCurrentUser";
import { useProgramComponentRoute } from "../../../../../wrappers/router/common/ProgramsComponentRouteWrapper";

export function ClientProgramActivityEndRoute() {
  const { username } = useCurrentUser();

  const { activity } = useProgramComponentRoute();

  return <EndWorkout activity={activity} username={username} />;
}
