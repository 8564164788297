import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import {
  ClientEnrollmentDto,
  EnrollmentStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useClient } from "../../../../hooks/useClient";
import EnrollmentsClientService from "../../../../services/EnrollmentsClientService";

interface CoachClientProgramsContextProps {
  enrollments: ClientEnrollmentDto[];
  isLoading: boolean;
}

const CoachClientProgramsContext = createContext<
  CoachClientProgramsContextProps | undefined
>(undefined);

const useCoachClientPrograms = () => {
  const context = useContext(CoachClientProgramsContext);
  if (context === undefined) {
    throw new Error("useGroup must be used within a GroupProvider");
  }
  return context;
};

export const COACH_CLIENT_PROGRAMS_QUERY_KEY = "coach-client-programs";
const CoachClientProgramsRouteWrapper = () => {
  const client = useClient();

  const { data: enrollments, isLoading } = useQuery({
    queryKey: [COACH_CLIENT_PROGRAMS_QUERY_KEY, { client: client?.id }],
    queryFn: () =>
      EnrollmentsClientService.getClientEnrolments({
        status: EnrollmentStatus.ALL,
        pageNumber: 1,
        pageSize: 9999,
        clientId: client?.id,
      }),
  });

  return (
    <CoachClientProgramsContext.Provider
      value={{ enrollments: enrollments?.items, isLoading }}
    >
      <Outlet />
    </CoachClientProgramsContext.Provider>
  );
};

export { CoachClientProgramsRouteWrapper, useCoachClientPrograms };
