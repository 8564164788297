import { nutritionEntriesClient } from "../api/ApiClients";
import {
    ICreateNutritionEntryCommand,
    CreateNutritionEntryCommand,
    IUpdateNutritionEntryCommand,
    UpdateNutritionEntryCommand,    
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class NutritionEntriesService extends ServiceBase {
  async getNutritionEntries(clientId: number){
    return nutritionEntriesClient.getNutritions(clientId);
  }

  async createNutritionEntry(
    variables: ICreateNutritionEntryCommand,
  ) {
    const command = CreateNutritionEntryCommand.fromJS(variables);

    return nutritionEntriesClient.createNutrition(command);
  }

  async updateNutritionEntry(
    variables: IUpdateNutritionEntryCommand,
  ) {
    const command = UpdateNutritionEntryCommand.fromJS(variables);

    return nutritionEntriesClient.updateNutritionEntry(command.id, command);
  }

  async deleteNutritionEntry(nutritionEntryId: number) {
    return nutritionEntriesClient.deleteNutritionEntry(nutritionEntryId);
  }
}

export default new NutritionEntriesService();
