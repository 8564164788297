import clsx from "clsx";
import React, { useRef, useState } from "react";
import {
  Container,
  ContainerProps,
  FormLabel,
  TextField,
  FormControl,
  Box,
  Typography,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SettingsCard } from "../card/SettingsCard";
import { ActionButton } from "../button/ActionButton";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  CropImageDialog,
  CropImageDialogCrop,
} from "../dialog/CropImageDialog";

import { CardMedia } from "../card/CardMedia";
import { parseThumbnailUrl, generateThumbnailUrl } from "../../utils/thumbnail";
import { slugify } from "../../utils/slug";
import { CropScaled } from "../editor/types/legacy";
import { MealLoggingSettings } from "../meal-logging-settings/MealLoggingSettings";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";

import { useUpdateClientPortalMutation } from "./mutations/UpdateClientPortalMutation";
import { ClientPortalSettingsUploadLogoDialog } from "./ClientPortalSettingsUploadLogoDialog";
import { useNavigate } from "react-router-dom";
import { CLIENT_HOME_ROUTE } from "../../routes/routes";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import ImageCropDialog from "../new-editor/elements/CropDialog";
import { Crop, PixelCrop } from "react-image-crop";
import {
  GetWorkspaceClientPortalDto,
  IUpdateWorkspaceCommand,
} from "@growth-machine-llc/stridist-api-client";
import { useUploadFile } from "../../hooks/useUploadFile";
import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";
import { AssetType } from "../../constants";
import { Tooltip } from "../info/Tooltip";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { cropImageFromFile, imageToBlob } from "../../imageCropUtil";

const useStyles = makeStyles((theme) => ({
  root: {},

  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },

  logo: {
    width: 90,
    height: 90,
    marginRight: theme.spacing(4.5),
    cursor: "pointer",
  },

  fields: {
    display: "flex",
    flexWrap: "wrap",

    "& > fieldset": {
      width: "100%",
      marginBottom: theme.spacing(3),
    },

    [theme.breakpoints.up("md")]: {
      "& > fieldset": {
        width: `calc(50% - ${theme.spacing(2)})`,
      },

      "& > fieldset:nth-child(1), & > fieldset:nth-child(4)": {
        width: "100%",
      },

      "& > fieldset:nth-child(2)": {
        marginRight: theme.spacing(3),
      },
    },
  },

  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },

  footer: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2),

    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    borderRadius: 6,
    backgroundColor: `${theme.palette.quote}7F`,

    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },

  footerText: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    opacity: 0.6,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },

  urlAdornment: {
    marginRight: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    fontWeight: 500,
    lineHeight: "19px",
  },

  viewAsClient: {
    fontSize: 16,
    whiteSpace: "nowrap",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "inherit",
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(3),
    },
  },

  mealLogging: {
    width: "100%",
  },

  uploadButtons: {
    display: "flex",
    flexDirection: "column",
  },
}));

const reactPropProps = {
  minWidth: 90,
  minHeight: 90,
  keepSelection: false,
};

const cropOptions = {
  aspect: 1,
};

const getThumbnailCrop = (url: string): CropScaled => {
  const info = url && parseThumbnailUrl(url);
  const crop = info?.crop;

  return (
    crop && {
      unit: "px",
      x: crop.x,
      y: crop.y,
      width: crop.w,
      height: crop.h,
      scaleX: 0,
      scaleY: 0,
    }
  );
};

export interface ClientPortalSettingsProps
  extends Omit<ContainerProps, "children"> {
  workspace: GetWorkspaceClientPortalDto;
}

export function ClientPortalSettings(props: ClientPortalSettingsProps) {
  const { className, workspace: workspace, ...other } = props;
  const { name, logoUrl: logoURL, message, slug, mealLogging } = workspace;
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();
  const [dirty, setDirty] = React.useState(false);
  const [settings, setSettings] = React.useState({
    name,
    logoURL,
    message,
    slug,
    mealLogging,
  });
  const {
    mutate: updateClientPortal,
    isPending: pendingUpdateClientPortal,
    status: updateClientPortalStatus,
  } = useUpdateClientPortalMutation();

  const [uploadFile, _, isUploading] = useUploadFile({
    id: user.id,
    refType: "User",
    getAssetType: () => AssetType.PORTAL_LOGO,
  });

  const handleCrop = async (image: HTMLImageElement, crop: PixelCrop) => {
    const croppedImageFile = await cropImageFromFile(image, crop);
    const { url: croppedImageUrl } = await uploadFile(croppedImageFile);
    setSettings(({ logoURL, ...settings }) => ({
      ...settings,
      logoURL: croppedImageUrl,
    }));
    setDirty(true);
  };

  const completed = Boolean(settings.slug);
  const disabled =
    !dirty || pendingUpdateClientPortal || isUploading || !completed;
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [cropOpen, setCropOpen] = React.useState(false);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [preview, setPreview] = useState(false);
  const [isCroppedImage, setIsCroppedImage] = useState(false);
  const [logo, setLogo] = useState<string>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const { isBranded, isBrandedAppPublished } = useCurrentBrand();

  const test = useRef<HTMLCanvasElement>(null);

  const crop: CropScaled = getThumbnailCrop(settings.logoURL);
  const switchUser = useSwitchUser();
  const { showToastAlert } = useToastAlert();

  const handleChange = React.useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      checked?: boolean,
    ) => {
      const { id, value } = event.currentTarget;

      setSettings((settings) => ({
        ...settings,
        [id]:
          typeof checked === "boolean"
            ? checked
            : id === "slug"
              ? slugify(value)
              : value,
      }));
      setDirty(true);
    },
    [],
  );

  const handleSave = React.useCallback(
    () =>
      updateClientPortal(
        {
          id: workspace.id,
          logoUrl: settings.logoURL,
          ...settings,
        } as IUpdateWorkspaceCommand,
        {
          onSuccess: () => {
            setDirty(false);
          },
        },
      ),
    [settings, updateClientPortal],
  );

  const handleCropOpen = React.useCallback(() => {
    setCropOpen(true);
  }, []);

  const handleCropClose = React.useCallback(() => {
    setCropOpen(false);
  }, []);

  const handleChangeImage = React.useCallback(() => {
    setCropOpen(false);
    setUploadOpen(true);
  }, []);

  const handleUploadOpen = React.useCallback(() => {
    setUploadOpen(true);
  }, []);

  const handleUploadClose = React.useCallback(() => {
    setUploadOpen(false);
  }, []);

  const handleViewAsClientClick = React.useCallback(() => {
    const sampleAccount = user.accounts.find((a) => a.isSample);
    if (sampleAccount) {
      switchUser(sampleAccount.id);
    } else {
      showToastAlert("error", { message: "Sample account not found" });
    }
  }, []);

  const handleLogoChange = React.useCallback(
    async (image: HTMLImageElement) => {
      const blob = await imageToBlob(image);
      const blobUrl = URL.createObjectURL(blob);
      setLogo(blobUrl);
      setDirty(true);
      setUploadOpen(false);
      setCropOpen(true);
    },
    [settings],
  );

  const handleRemoveLogo = React.useCallback(() => {
    setSettings({
      ...settings,
      logoURL: "",
    });
    setIsCroppedImage(false);
    setDirty(true);
  }, [settings]);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={dirty} />
      <SettingsCard
        header="Client portal settings"
        ButtonProps={{
          disabled,
          onClick: handleSave,
          status: updateClientPortalStatus,
        }}
      >
        {isBrandedAppPublished && (
          <Alert
            className={s.footer}
            severity="info"
            sx={{
              alignItems: "flex-start !important",
              "& .MuiAlert-icon": {
                fontSize: 35,
                color: (theme) => theme.palette.text.secondary,
              },
            }}
          >
            <AlertTitle>
              <Typography component="div" className={s.footerText}>
                Great news! Your custom-branded app has been successfully
                published to the stores! 🎉
              </Typography>
            </AlertTitle>
            <Typography component="div" className={s.footerText}>
              While the Portal URL can no longer be changed at this point, you
              can still update the workspace logo, name, and everything else
              really. Your clients will experience these changes immediately,
              though if you’d like to make updates to the branded app’s name,
              logo, or Portal URL, feel free to reach out to our customer
              support team.
            </Typography>
          </Alert>
        )}
        <Box className={s.fields}>
          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="logo"
              component="label"
              children="Logo"
            />

            <Box className={s.logoWrapper}>
              {isCroppedImage ? (
                <Box className={s.logo}>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: 90,
                      height: 90,
                    }}
                  />
                </Box>
              ) : (
                settings.logoURL && (
                  <CardMedia
                    className={s.logo}
                    image={settings.logoURL?.replace(
                      "//stridist.com/img2/users",
                      "//s.stridist.com/users",
                    )}
                    scale={{
                      w: 90,
                      h: 90,
                    }}
                  />
                )
              )}
              <Box className={s.uploadButtons}>
                <ActionButton onClick={handleUploadOpen}>
                  Upload new logo
                </ActionButton>
                {settings.logoURL && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleRemoveLogo}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Box>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="name"
              component="label"
              children="Name"
            />
            <TextField
              variant="outlined"
              helperText="You can use your name or the name of your business. Keep it simple."
              fullWidth
              id="name"
              value={settings.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="slug"
              component="label"
              children="Portal URL"
            />
            <Tooltip
              title={
                isBranded &&
                `You cannot change this field ${isBrandedAppPublished ? "when your branded app is published to the stores" : "while your branded app is in progress"}. Contact customer support to request the changes.`
              }
            >
              <TextField
                disabled={isBranded}
                variant="outlined"
                fullWidth
                id="slug"
                error={!settings.slug}
                helperText={
                  !settings.slug && "Please choose URL for your client portal"
                }
                InputProps={{
                  startAdornment: (
                    <Typography component="span" className={s.urlAdornment}>
                      www.app.stridist.com/
                    </Typography>
                  ),
                }}
                value={settings.slug}
                onChange={handleChange}
              />
            </Tooltip>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="message"
              component="label"
              children="Motivational message"
            />
            <TextField
              variant="outlined"
              fullWidth
              id="message"
              value={settings.message}
              onChange={handleChange}
              required
            />
          </FormControl>
        </Box>

        <Box className={s.footer}>
          <Typography component="div" className={s.footerText}>
            You can use your sample client ({user.email}) to see what your
            clients will experience once they log in to your client portal
          </Typography>
          <ActionButton
            children="View as sample client"
            onClick={handleViewAsClientClick}
          />
        </Box>

        <FormControl component="fieldset" className={s.mealLogging}>
          <FormLabel
            className={s.label}
            component="label"
            children="Meal logging"
          />
          <MealLoggingSettings workspace={workspace} onChange={handleChange} />
        </FormControl>
      </SettingsCard>

      <ClientPortalSettingsUploadLogoDialog
        open={uploadOpen}
        onClose={handleUploadClose}
        onChange={handleLogoChange}
        upload={false}
      />
      <ImageCropDialog
        open={cropOpen}
        onSave={handleCrop}
        onClose={handleCropClose}
        imageSrc={
          logo &&
          generateThumbnailUrl({
            src: logo,
            crop: null,
          })
            ?.replace("//test.stridist.com/img2", "//test-s.stridist.com")
            ?.replace("//stridist.com/img2", "//s.stridist.com")
        }
        previewCanvasRef={previewCanvasRef}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        preview={preview}
        setPreview={setPreview}
        setIsCroppedImage={setIsCroppedImage}
        children={
          <Button variant="text" color="primary" onClick={handleChangeImage}>
            Change image
          </Button>
        }
      ></ImageCropDialog>
    </Container>
  );
}
