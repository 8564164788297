import React from "react";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import { createTheme, GLOBAL_STYLES } from "../../theme";
import { CurrentWorkspaceContext } from "../../hooks/useCurrentWorkspace";

import { LocalStorageKeys } from "../../constants";
import { generateThumbnailUrl } from "../../utils/thumbnail";
import {
  BrandedAppStatus,
  IWorkspaceInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { GlobalStyles } from "@mui/material";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface ThemeProviderProps {
  children?: React.ReactNode;
  workspace?: IWorkspaceInfoDto;
}

export function AppThemeProvider(props: ThemeProviderProps) {
  const { children, workspace } = props;
  const { primaryColor, logoUrl: logoURL, name, brandedApp } = workspace;

  const theme = React.useMemo(
    () =>
      createTheme({
        primary: primaryColor,
      }),
    [primaryColor],
  );

  React.useMemo(() => {
    const isBrandedApp =
      brandedApp?.status === BrandedAppStatus.IN_PROGRESS ||
      brandedApp?.status === BrandedAppStatus.PUBLISHED;
    const generatedLogoUrl = logoURL
      ? generateThumbnailUrl({
          src: logoURL,
          scale: {
            w: 60,
            h: 60,
          },
        })
      : "";
    // TODO_Branded_Apps: Replace with useCurrentBrand hook
    const brandName =
      isBrandedApp && workspace.name ? workspace.name : "Stridist";
    window.document.title = brandName;
    (window.document.getElementById("favicon") as any).href =
      isBrandedApp && logoURL
        ? generatedLogoUrl
        : `${process.env.PUBLIC_URL}/favicon.png`;

    if (isBrandedApp && logoURL) {
      localStorage.setItem(LocalStorageKeys.FAVICON_LOGO_URL, generatedLogoUrl);
    } else {
      localStorage.setItem(
        LocalStorageKeys.FAVICON_LOGO_URL,
        `${process.env.PUBLIC_URL}/favicon.png`,
      );
    }

    localStorage.setItem(LocalStorageKeys.TITLE, brandName);
  }, [logoURL, name]);

  return (
    <CurrentWorkspaceContext.Provider value={workspace}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={GLOBAL_STYLES} />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CurrentWorkspaceContext.Provider>
  );
}
