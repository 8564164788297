import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  CardMedia,
  CardContent,
  CardActionArea,
  Box,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { ClientProgramActivity } from "./ClientProgramActivity";
import { ClientLast7Days } from "./ClientLast7Days";
import { useLocation, useNavigate } from "react-router-dom";
import { ClientEnrollmentDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  media: {
    height: 100,
    position: "relative",

    [theme.breakpoints.up("md")]: {
      height: "auto",
      width: "30%",
      minHeight: "100%",
    },
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  imageTextWrapper: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    background: "linear-gradient(transparent, hsla(0, 0%, 0%, 0.6))",
    overflow: "hidden",
  },

  imageTextSmall: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
  },

  imageTextBig: {
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: "26px",
  },

  content: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "space-around",
    },
  },

  last7Days: {
    marginTop: theme.spacing(3),

    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginLeft: theme.spacing(3.5),
    },
  },
}));

export interface ClientProgramProgressCardProps extends CardProps {
  enrollment: ClientEnrollmentDto;
}

export function ClientProgramProgressCard(
  props: ClientProgramProgressCardProps,
) {
  const { className, enrollment, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const location = useLocation();
  const user = useCurrentUser();
  const { program, currentDay, totalDays, endDate } = enrollment;
  const { slug } = program;

  const link = React.useMemo(() => {
    const path = `/programs/${slug}`;
    return user.role === UserRole.COACH
      ? `${location.pathname.replace("/overview", "")}${path}`
      : path;
  }, [slug, user.role, location.pathname]);

  const handleClick = React.useCallback(() => {
    navigate(link);
  }, [link]);

  return (
    <Card className={clsx(s.root, className)} onClick={handleClick} {...other}>
      <CardActionArea className={s.media}>
        <CardMedia
          className={s.image}
          image={program.image}
          title={program.name}
        />
        <Box className={s.imageTextWrapper}>
          <Typography className={s.imageTextSmall}>
            {currentDay
              ? `Day ${currentDay} of ${totalDays}`
              : `Ended ${endDate.format("MMM DD, YYYY")}`}
          </Typography>
          <Typography className={s.imageTextBig}>{program.name}</Typography>
        </Box>
      </CardActionArea>
      <CardContent className={s.content}>
        <ClientProgramActivity enrollment={enrollment} />
        <ClientLast7Days className={s.last7Days} enrollment={enrollment} />
      </CardContent>
    </Card>
  );
}
