import { CurriculumRootState } from "../store";

export const selectComponentTempIdsMap = (state: CurriculumRootState) =>
  state.api.tempIds.components;

export const selectWeekTempIdsMap = (state: CurriculumRootState) =>
  state.api.tempIds.weeks;

export const selectGeneralLoading = (state: CurriculumRootState) =>
  state.api.loading;

export const selectApiErrorState = (state: CurriculumRootState) =>
  state.api.errors.hasError;

export const selectWeekTempIdByReal =
  (realId: number) => (state: CurriculumRootState) => {
    return Object.keys(state.api.tempIds.weeks).find(
      (key) => state.api.tempIds.weeks[key] === realId,
    );
  };

export const selectShouldRefetchCurriculum = (state: CurriculumRootState) =>
  state.api.shouldRefetchCurriculum;
