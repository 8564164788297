import React, { useContext, useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import {
  CALENDAR_SIDEBAR_WIDTH,
  ProgramCalendarWeeksList,
} from "../program-calendar/ProgramCalendarWeeksList";
import { ProgramWeeksList } from "../program/ProgramWeeksList";
import { useAnalytics } from "../../hooks/useAnalytics";
import { CopyCalendarComponentContext } from "../../hooks/useCopyCalendarComponent";
import { ReactComponent as AddWeekIcon } from "../../icons/AddCircleOutline.svg";
import { Filters } from "./ProgramDetailsFilters";
import MinimizedDrawerContext, {
  getLocalStorageMinimizedDrawerValue,
} from "../../contexts/MinimizedDrawerContext";
import { ProgramSpreadsheet } from "../program-workout-spreadsheet/ProgramSpreadsheet";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import { NormalizedCurriculumProgram } from "../../redux/types";
import { WeekMenu } from "../program-week/WeekMenu";
import { selectWeeks } from "../../redux/curriculum/selectors/curriculum";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  calendar: {},
  list: {
    maxWidth: 1116,
  },
  addWeekButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: "none",
    fontWeight: 600,
    fontSize: 18,
    margin: theme.spacing(3, 0),
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      height: theme.spacing(8.75),
      fontSize: 20,
      "& svg": {
        width: 32,
        height: 32,
      },
    },

    "& svg": {
      width: 24,
      height: 24,
    },

    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}0F`,
    },

    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },
}));

interface ProgramDetailsProps {
  program: NormalizedCurriculumProgram;
  viewMode: ProgramDetailsViewMode;
  filters: Filters;
  weeksFilter: { start: number; end: number };
  startDate?: string;
  length?: number;
  weeks?: number[];
  id?: number;
  slug?: string;
  handleAddWeek?: () => void;
  addWeekInFlight?: boolean;
  handleDeleteWeek?: (event, setMoreMenuEl) => void;
  handleDuplicateWeek?: (event, closeMoreMenu) => void;
  dublicateWeekInFlight?: boolean;
  handleMove?: (event, closeMoreMenu) => void;
  handlePrevClick?: () => void;
  handleNextClick?: () => void;
  loading?: boolean;
}

export function ProgramDetails(props: ProgramDetailsProps) {
  const {
    program,
    filters,
    weeksFilter,
    viewMode,
    startDate,
    id,
    weeks: allWeeks,
    slug,
    handleAddWeek,
    addWeekInFlight,
    handleDeleteWeek,
    handleDuplicateWeek,
    dublicateWeekInFlight: duplicateWeekInFlight,
    handleMove,
    handlePrevClick,
    handleNextClick,
    loading,
  } = props;
  const weeksList = useCurriculumSelector(selectWeeks);

  const theme = useTheme();
  const [moreMenuEl, setMoreMenuEl] = React.useState(null);
  const [weekId, setWeekId] = React.useState(null);
  const s = useStyles();
  const [copiedCalendarComponentId, setCopiedCalendarComponentId] =
    React.useState<number>();

  const hasPrev = weeksFilter.start > 1;
  const hasNext = weeksFilter.end < program.length;

  const weeks = allWeeks || [];

  const [trackEvent] = useAnalytics();
  const weekIndex = program.weeks.findIndex((id) => id == weekId);
  const weekData = weeksList[weekId];

  React.useEffect(() => {
    if (
      copiedCalendarComponentId === undefined &&
      viewMode !== ProgramDetailsViewMode.CALENDAR
    ) {
      setCopiedCalendarComponentId(undefined);
    }
  }, [copiedCalendarComponentId, viewMode]);

  const openMoreMenu = React.useCallback((event) => {
    setWeekId(event.currentTarget.dataset.id);
    setMoreMenuEl(event.currentTarget);
  }, []);

  const closeMoreMenu = React.useCallback(() => {
    setMoreMenuEl(null);
  }, []);

  const canMoveUp = weekIndex > 0;
  const canMoveDown = weekIndex < weeks.length - 1;

  const WeeksList = React.useMemo(() => {
    switch (viewMode) {
      case ProgramDetailsViewMode.LIST:
        return ProgramWeeksList;
      case ProgramDetailsViewMode.CALENDAR:
        return ProgramCalendarWeeksList;
      case ProgramDetailsViewMode.SPREADSHEET:
        return ProgramSpreadsheet;
      default:
        throw new Error(`Not implemented ${viewMode} program view.`);
    }
  }, [viewMode]);

  const { setMinimizedDrawer } = useContext(MinimizedDrawerContext);

  const [isCalendarSidebarOpen, setIsCalendarSidebarOpen] = useState(false);

  useEffect(() => {
    setMinimizedDrawer(
      isCalendarSidebarOpen ? true : getLocalStorageMinimizedDrawerValue(),
    );
  }, [isCalendarSidebarOpen]);

  const PAGINATED_WEEKS = program.weeks.slice(
    weeksFilter.start - 1,
    weeksFilter.end,
  );
  return (
    <>
      <CopyCalendarComponentContext.Provider
        value={[copiedCalendarComponentId, setCopiedCalendarComponentId]}
      >
        <WeeksList
          filters={filters}
          onOpenMenu={openMoreMenu}
          program={program}
          startDate={startDate}
          weeks={PAGINATED_WEEKS}
          weeksLength={program.weeks.length}
          programId={viewMode !== ProgramDetailsViewMode.CALENDAR ? id : null}
          activityPreview={isCalendarSidebarOpen}
          setActivityPreview={setIsCalendarSidebarOpen}
          duplicateWeekInFlight={duplicateWeekInFlight}
          weeksActions={{
            onClickDelete: (e) => handleDeleteWeek(e, setMoreMenuEl),
            onClickMove: (e) => handleMove(e, closeMoreMenu),
            onClickDuplicate: (e) => handleDuplicateWeek(e, closeMoreMenu),
          }}
        />
      </CopyCalendarComponentContext.Provider>

      <Box
        sx={{
          width: isCalendarSidebarOpen
            ? `calc(100% - ${CALENDAR_SIDEBAR_WIDTH}px - ${theme.spacing(2)})`
            : "100%",

          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        {/* TODO move weeks logic to each view or refactor to a sidebar */}
        {!hasNext && (
          <Button
            className={s.addWeekButton}
            fullWidth
            variant="outlined"
            startIcon={<AddWeekIcon />}
            children="Add new week"
            onClick={handleAddWeek}
            disabled={addWeekInFlight}
          />
        )}

        <Box display="flex" justifyContent="center" gap={2} pt={1} pb={2}>
          <Button onClick={handlePrevClick} disabled={loading || !hasPrev}>
            Load previous
          </Button>
          <Button onClick={handleNextClick} disabled={loading || !hasNext}>
            Load next
          </Button>
        </Box>
      </Box>

      {Boolean(moreMenuEl) && (
        <WeekMenu
          id="week-menu"
          weekId={weekId}
          open={true}
          anchorEl={moreMenuEl}
          onClose={closeMoreMenu}
          onClickDelete={(e) => handleDeleteWeek(e, setMoreMenuEl)}
          onClickMove={(e) => handleMove(e, closeMoreMenu)}
          canMoveUp={canMoveUp}
          canMoveDown={canMoveDown}
          onClickDuplicate={(e) => handleDuplicateWeek(e, closeMoreMenu)}
          canDuplicate={
            duplicateWeekInFlight || weekData?.components?.length === 0
          }
          canDelete={weeks.length > 1}
        />
      )}
    </>
  );
}
