import clsx from "clsx";
import React from "react";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Switch,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { usePopupState } from "material-ui-popup-state/hooks";
import { Editor } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";
import { ReactComponent as HeartIcon } from "../../icons/HeartOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/ArchiveOutline.svg";
import { ReactComponent as TemplateIcon } from "../../icons/BookmarkOutline.svg";
import { ReactComponent as CalendarLockIcon } from "../../icons/calendar-lock.svg";
import { ReactComponent as TextInputAreaIcon } from "../../icons/text-input-area.svg";
import { ReactComponent as UndoIcon } from "../../icons/Undo.svg";
import { ComponentStatus, ComponentType } from "../../constants";
import { getContentType } from "../../utils/component";
import { ChangeComponentCoverDialog } from "../dialog/ChangeComponentCoverDialog";
import { useEditorCoverImage } from "../../hooks/useEditorCoverImage";
import { useCurrentUser, UserInfo } from "../../hooks/useCurrentUser";
import { Menu, MenuProps } from "./Menu";
import { useNavigate } from "react-router-dom";
import { useEditorRef } from "@udecode/plate-common";
import { CurriculumComponent } from "../../redux/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import ComponentsService from "../../services/ComponentsService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { COACH_PROGRAM_COMPONENT_QUERY_KEY } from "../../routes/coach/programs/preview/component/CoachProgramComponentPreviewRoute";
import { ComponentDto2 } from "@growth-machine-llc/stridist-api-client";
import { COMPONENT_TEMPLATES_LIST_QUERY_KEY } from "../component-template/ComponentTemplateDialog";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import { syncComponentData } from "../../redux/curriculum/curriculum-slice";
import { isDirty } from "zod";
import { extractSlugId } from "../../utils/slug";
import { selectComponentTempIdsMap } from "../../redux/api/selectors";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
    "& svg": {
      color: theme.palette.text.primary,
    },
  },

  primaryText: {
    fontWeight: 500,
  },

  divider: {
    backgroundColor: theme.palette.quote,
  },

  switchText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
  },

  switch: {
    margin: 0,
  },
}));

export interface EditorMenuProps extends MenuProps {
  componentData: CurriculumComponent;
  isEmpty?: boolean;
  isDirty?: boolean;
  editor: Editor & ReactEditor & HistoryEditor;
}

export function EditorMenu(props: EditorMenuProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const {
    className,
    componentData,
    onClose,
    isEmpty = false,
    isDirty = false,
    ...other
  } = props;
  const component = componentData;
  const componentSlugId = extractSlugId(component.slug);
  const user = useCurrentUser();
  const queryClient = useQueryClient();
  const dispatch = useCurriculumDispatch();
  const tempIdsMap = useCurriculumSelector(selectComponentTempIdsMap);

  const { showToastAlert, updateToastAlert } = useToastAlert();

  const { mutate: archiveComponent } = useOptimisticUpdateMutation({
    successToastMessage: "Component archived",
    queryKey: [COACH_PROGRAM_COMPONENT_QUERY_KEY, { slugId: componentSlugId }],
    mutationFn: ComponentsService.archiveComponent,
    optimisticUpdater: {
      updateFn: (oldData: ComponentDto2) =>
        new ComponentDto2({
          ...oldData,
          status: ComponentStatus.ARCHIVED,
        }),
    },
    options: {
      onSuccess() {
        // Find temp id by real
        const tempId = Number(
          Object.keys(tempIdsMap).find((k) => tempIdsMap[k] === component.id),
        );

        // sync curriculum store
        dispatch(
          syncComponentData({
            componentId: component.id,
            data: {
              status: ComponentStatus.ARCHIVED,
            },
            tempId,
          }),
        );
      },
    },
  });

  const { mutate: toggleLocked } = useOptimisticUpdateMutation({
    successToastMessage: "Component updated",
    queryKey: [COACH_PROGRAM_COMPONENT_QUERY_KEY, { slugId: componentSlugId }],
    mutationFn: ComponentsService.toggleComponentsLock,
    optimisticUpdater: {
      updateFn: (oldData: ComponentDto2, { locked }) =>
        new ComponentDto2({
          ...oldData,
          locked: locked,
        }),
    },
    options: {
      onSuccess(_, variables) {
        // Find temp id by real
        const tempId = Number(
          Object.keys(tempIdsMap).find((k) => tempIdsMap[k] === component.id),
        );

        // sync curriculum store
        dispatch(
          syncComponentData({
            componentId: component.id,
            data: {
              locked: variables.locked,
            },
            tempId,
          }),
        );
      },
    },
  });

  const { mutate: toggleToolbar } = useOptimisticUpdateMutation({
    successToastMessage: "Settings updated",
    queryKey: [CURRENT_USER_QUERY_KEY],
    mutationFn: FbUsersService.updateUser,
    optimisticUpdater: {
      updateFn: (oldData: UserInfo, { topToolBar }) => ({
        ...oldData,
        topToolbar: topToolBar,
      }),
    },
  });

  const { mutate: createTemplate } = useMutation({
    mutationFn: ComponentsService.createComponentTemplate,
    onMutate: () => {
      const toastId = showToastAlert("loading", {
        message: "Creating template...",
      });

      return { toastId };
    },
    onSuccess: (_, variables, context) => {
      updateToastAlert(context.toastId, {
        severity: "success",
        message: "Template created.",
      });

      queryClient.invalidateQueries({
        queryKey: [COMPONENT_TEMPLATES_LIST_QUERY_KEY],
        exact: false,
        refetchType: "none",
      });
    },
  });
  const editor = useEditorRef();
  const handleArchiveClick = React.useCallback(() => {
    archiveComponent(component.id, {
      onSuccess() {
        // TODO: Improve by replacing current path with previous one
        // so the user can't go forward again to the component editor
        if (onClose) {
          navigate(-1);
        }
      },
    });
  }, [component.id, onClose, archiveComponent]);

  const changeCoverDialogState = usePopupState({
    variant: "popover",
    popupId: "change-cover-dialog",
  });

  const elementTypeName = getContentType(component.type as ComponentType);
  const onUpdateCover = useEditorCoverImage();

  const handleCoverChangeClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
      changeCoverDialogState.open(event);
    },
    [changeCoverDialogState, onClose],
  );

  const handleCoverChange = React.useCallback(
    (value: string) => {
      changeCoverDialogState.close();
      onUpdateCover(value);
    },
    [changeCoverDialogState, onUpdateCover],
  );

  const handleLockedToggle = React.useCallback(() => {
    toggleLocked({
      ids: [component.id],
      locked: !component.locked,
    });
  }, [component.id, component.locked, toggleLocked]);

  const handleTopToolbarToggle = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      toggleToolbar({
        id: +user.id,
        topToolBar: checked,
      });
    },
    [toggleToolbar, user.id],
  );
  const handleSaveAsTemplate = React.useCallback(
    (event) => {
      const input = {
        title: component.title,
        content: component.content,
        habitPrompt:
          component.habitPrompt?.trim() === "" ? null : component.habitPrompt,
        image: component.image?.trim() === "" ? null : component.image,
        componentType: component.type,
      };

      createTemplate(input, {
        onSuccess: () => {
          onClose(event, "backdropClick");
        },
      });
    },
    [
      component.content,
      component.habitPrompt,
      component.image,
      component.title,
      component.type,
      createTemplate,
      onClose,
    ],
  );

  const handleUndoClick = React.useCallback(() => editor.undo(), [editor]);

  return (
    <>
      <Menu
        className={clsx(s.root, className)}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...other}
      >
        <MenuItem
          onClick={handleUndoClick}
          disabled={editor.history.undos.length === 0}
        >
          <ListItemIcon children={<UndoIcon />} />
          <ListItemText classes={{ primary: s.primaryText }} primary="Undo" />
        </MenuItem>

        {component.type !== ComponentType.MESSAGE && (
          <MenuItem onClick={handleCoverChangeClick}>
            <ListItemIcon children={<HeartIcon />} />
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Set cover image"
            />
          </MenuItem>
        )}

        <MenuItem onClick={handleArchiveClick}>
          <ListItemIcon children={<ArchiveIcon />} />
          <ListItemText
            classes={{ primary: s.primaryText }}
            primary={`Archive ${elementTypeName}`}
          />
        </MenuItem>

        {!isEmpty && (
          <MenuItem onClick={handleSaveAsTemplate} disabled={isDirty}>
            <ListItemIcon children={<TemplateIcon />} />
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Save as template"
            />
          </MenuItem>
        )}

        <Divider className={s.divider} />

        {component.type !== ComponentType.MESSAGE && (
          <Tooltip
            arrow
            placement="left"
            title="Prevent clients from viewing this until the scheduled date."
          >
            <MenuItem>
              <ListItemIcon children={<CalendarLockIcon />} />
              <ListItemText
                classes={{ root: s.switchText, primary: s.primaryText }}
                primary="Content locking"
                secondary={
                  <Switch
                    className={s.switch}
                    value="fixed"
                    checked={component.locked}
                    onChange={handleLockedToggle}
                  />
                }
              />
            </MenuItem>
          </Tooltip>
        )}

        <Tooltip
          arrow
          placement="left"
          title="Include a handy toolbar for quick access to components."
        >
          <MenuItem>
            <ListItemIcon children={<TextInputAreaIcon />} />
            <ListItemText
              classes={{ root: s.switchText, primary: s.primaryText }}
              primary="Top toolbar"
              secondary={
                <Switch
                  className={s.switch}
                  value="fixed"
                  checked={user.topToolbar}
                  onChange={handleTopToolbarToggle}
                />
              }
            />
          </MenuItem>
        </Tooltip>
      </Menu>

      {changeCoverDialogState.isOpen && (
        <ChangeComponentCoverDialog
          open
          onClose={changeCoverDialogState.close}
          componentData={component}
          onCoverChange={handleCoverChange}
        />
      )}
    </>
  );
}
