import clsx from "clsx";
import React from "react";
import { MenuItem, MenuItemProps, ListItemText, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Check as CheckIcon } from "@mui/icons-material";

import { CardAvatar } from "../card/CardAvatar";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useSwitchUser } from "../../hooks/useSwitchUser";

import { UserAccountInfoDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 270,
  },

  divider: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.selected.main,
  },

  avatar: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },

  displayName: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    fontSize: 14,
  },

  info: {
    color: theme.palette.avatar,
    fontSize: 13,
  },

  currentIcon: {
    color: theme.palette.secondary.main,
    height: 22,
  },
}));

export interface UserAccountMenuItemProps
  extends Omit<MenuItemProps, "button" | "children" | "onClose"> {
  user: UserAccountInfoDto;
  onClose: (event: React.MouseEvent) => void;
}

export const UserAccountMenuItem = React.forwardRef<
  HTMLLIElement,
  UserAccountMenuItemProps
>(function (props: UserAccountMenuItemProps, ref) {
  const { className, user, onClose, ...other } = props;
  const s = useStyles();
  const me = useCurrentUser();
  const userId = user.id;
  const isCurrent = me?.id === userId;
  const switchUser = useSwitchUser();

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      onClose(event);
      if (!isCurrent) {
        switchUser(userId);
      }
    },
    [onClose, switchUser, userId],
  );

  return (
    <div>
      <MenuItem
        onClick={handleClick}
        className={clsx(s.root, className)}
        ref={ref}
        dense
        {...other}
      >
        <CardAvatar className={s.avatar} src={user.photoUrl} />
        <ListItemText
          classes={{
            primary: s.displayName,
            secondary: s.info,
          }}
          primary={user.displayName}
          secondary={
            user.role === "COACH"
              ? "Coach"
              : user.isSample
                ? "Sample Client"
                : "Client"
          }
        />
        {isCurrent && <CheckIcon className={s.currentIcon} />}
      </MenuItem>
      <Divider className={s.divider} />
    </div>
  );
});
