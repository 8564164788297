import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  useTheme,
  Skeleton,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandMore } from "@mui/icons-material";
import { capitalize } from "lodash";

import { EnrollmentStatus } from "../../constants";
import { InfoBox } from "../../components/info/InfoBox";
import { ClientUpcomingProgramCard } from "../card/ClientUpcomingProgramCard";
import { ClientPastProgramCard } from "../card/ClientPastProgramCard";
import { ClientActiveProgramCard } from "../card/ClientActiveProgramCard";

import WorkingOut from "../../icons/WorkingOut";
import { useCoachClientPrograms } from "../../routes/coach/client/programs/CoachClientProgramsRouteWrapper";
import { LoadingDots } from "../loading/LoadingDots";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },

  panel: {
    background: "transparent !important",
    boxShadow: "none !important",
    border: "none !important",
    padding: 0,
  },

  panelSummary: {
    padding: 0,
    marginLeft: theme.spacing(-1),
    minHeight: `${theme.spacing(6)} !important`,
    "& .MuiExpansionPanelSummary-content": {
      alignItems: "center",
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2, 0),
      },
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(-2),
    },
  },

  panelTitle: {
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },

  panelDetails: {
    padding: 0,
    flexDirection: "column",
    "& .MuiPaper-root": {
      margin: 0,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(3),
      },
    },
  },

  expand: {
    fontSize: 24,
    color: theme.palette.primary.main,
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
    "&$open": {
      transform: "rotate(0)",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
    },
  },

  empty: {
    margin: theme.spacing(12, "auto", 0),
  },

  open: {},
}));

export interface ClientProgramsProps extends Omit<ContainerProps, "children"> {}

export function ClientPrograms(props: ClientProgramsProps) {
  const { className, ...other } = props;
  const s = useStyles();

  const { enrollments, isLoading } = useCoachClientPrograms();

  const [expanded, setExpanded] = React.useState<EnrollmentStatus>(
    EnrollmentStatus.ACTIVE,
  );

  const EnrollmentCard = React.useMemo(() => {
    switch (expanded) {
      case EnrollmentStatus.UPCOMING:
        return ClientUpcomingProgramCard;
      case EnrollmentStatus.PAST:
        return ClientPastProgramCard;
      case EnrollmentStatus.ACTIVE:
        return ClientActiveProgramCard;
    }
  }, [expanded]);

  const handlePanelSummaryClick = (panel: EnrollmentStatus) => () => {
    setExpanded((expanded) => (expanded === panel ? null : panel));
  };
  const theme = useTheme();

  const openEnrollments = enrollments?.filter((e) => e.status === expanded);

  const getEnrollentStatusName = (status: EnrollmentStatus) => {
    return (status as string).toLowerCase();
  };

  return (
    <Container className={clsx(s.root, className)}>
      {enrollments?.length === 0 ? (
        <InfoBox
          className={s.empty}
          image={<WorkingOut fill={theme.palette.primary.main} />}
          title="Not enrolled in a program yet"
          subtitle="Your client’s programs will appear here so you can check their progress."
        />
      ) : (
        Object.values(EnrollmentStatus)
          .filter((status) => status !== EnrollmentStatus.ALL)
          .map((panel) => (
            <Accordion
              className={s.panel}
              expanded={expanded === panel}
              key={panel}
              disabled={isLoading}
            >
              <AccordionSummary
                className={s.panelSummary}
                onClick={handlePanelSummaryClick(panel)}
              >
                <ExpandMore
                  className={clsx(s.expand, expanded === panel && s.open)}
                />
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Typography component="h2" className={s.panelTitle}>
                    {capitalize(panel)}
                    {!isLoading &&
                      ` (${enrollments.filter((e) => e.status === panel).length})`}
                  </Typography>
                  {isLoading && (
                    <Skeleton animation="wave" width={30} height={25} />
                  )}
                </Box>
              </AccordionSummary>
              {!isLoading && (
                <AccordionDetails className={s.panelDetails}>
                  <Grid container spacing={3}>
                    {expanded === panel && openEnrollments.length > 0 ? (
                      openEnrollments.map((enrollment) => (
                        <Grid key={enrollment.id} item xs={12} sm={6} md={4}>
                          <EnrollmentCard enrollment={enrollment} />
                        </Grid>
                      ))
                    ) : (
                      <Grid key={0} item xs={12} sm={6} md={4}>
                        <Typography
                          variant="body1"
                          children={`No ${getEnrollentStatusName(panel)} programs for now`}
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              )}
            </Accordion>
          ))
      )}
    </Container>
  );
}
