import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { maxEnrollments } from "../../constants";

import { CardAvatar } from "../card/CardAvatar";
import { EnrolledAvatar } from "../avatar/EnrolledAvatar";

import { useNavigate } from "react-router-dom";
import { COACH_PROGRAM_ENROLLMENTS_ROUTE } from "../../routes/routes";
import { AvatarsDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row-reverse",
    cursor: "pointer",
  },
  avatar: {
    border: "2px solid",
    borderColor: theme.palette.grey[50],
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    fontSize: 12,
    marginLeft: theme.spacing(-2),
    fontWeight: "bold",
    backgroundColor: theme.palette.avatar,
  },

  more: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
  },

  tooltip: {
    padding: theme.spacing(1, 2),
  },

  tooltipPopper: {
    marginLeft: theme.spacing(-4),
  },
}));

export interface EnrolledAvatarsProps extends BoxProps {
  enrollments: AvatarsDto[];
  totalEnrollmentsCount: number;
  programSlug: string;
}

export function EnrolledAvatars(props: EnrolledAvatarsProps) {
  const {
    className,
    enrollments,
    totalEnrollmentsCount,
    programSlug,
    ...other
  } = props;
  const navigate = useNavigate();
  const s = useStyles();

  const handleClick = React.useCallback(() => {
    navigate(COACH_PROGRAM_ENROLLMENTS_ROUTE.replace(":slug", programSlug));
  }, [programSlug]);

  if (!enrollments) {
    return null;
  }

  const totalCount = totalEnrollmentsCount;

  const hiddenCount = Math.max(totalCount - maxEnrollments, 0);
  const hasMore = hiddenCount > 0;

  if (!totalCount) {
    return null;
  }

  return (
    <Box className={clsx(s.root, className)} {...other} onClick={handleClick}>
      {hasMore && (
        <Tooltip
          arrow
          placement="bottom"
          title={`${hiddenCount} other${hiddenCount === 1 ? "" : "s"}`}
          classes={{ popper: s.tooltipPopper, tooltip: s.tooltip }}
        >
          <Box>
            <CardAvatar className={clsx(s.avatar, s.more)}>
              {hiddenCount}
            </CardAvatar>
          </Box>
        </Tooltip>
      )}

      {enrollments.map((enrollment) => (
        <EnrolledAvatar key={enrollment.id} enrollment={enrollment} />
      ))}
    </Box>
  );
}
