import { call, put, select } from "redux-saga/effects";
import { TempIdsMap, updateTempIdMap } from "../../../../api/api-slice";
import ComponentsService from "../../../../../services/ComponentsService";
import {
  CreateComponentCommand,
  CreatedComponentResult,
} from "@growth-machine-llc/stridist-api-client";
import { CurriculumComponent } from "../../../../types";
import { selectWeekTempIdsMap } from "../../../../api/selectors";
import { setComponentSlug } from "../../../curriculum-slice";

export default function* addComponentWorker(payload: {
  weekId: number;
  newComponent: CurriculumComponent;
}) {
  const { weekId: actionWeekId, newComponent } = payload;
  const map: TempIdsMap = yield select(selectWeekTempIdsMap);
  const weekId = map?.[actionWeekId] || actionWeekId;
  try {
    const command = new CreateComponentCommand({
      weekId: weekId,
      type: newComponent?.type,
      title: newComponent?.title,
      days: newComponent?.days,
      repeat: newComponent?.repeat,
      duration: newComponent?.duration,
      locked: newComponent?.locked,
      reminderType: newComponent?.reminderType,
      reminderTimeString: newComponent?.reminderTime,
      messageTimeString: newComponent?.messageTime,
      content: newComponent?.content,
      iconName: newComponent?.iconName,
      image: newComponent?.image,
    });

    const res: CreatedComponentResult = yield call(
      ComponentsService.addComponent,
      command,
    );
    const realId = res.id;
    const tempId = newComponent.id.toString();
    yield put(updateTempIdMap({ type: "components", tempId, realId }));
    yield put(setComponentSlug([{ id: newComponent.id, slug: res.slug }]));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
