import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { SortableList, SortableListProps } from "../sortable-list/SortableList";

import {
  ClientGoalListItem,
  ClientGoalListItemProps,
} from "./ClientGoalListItem";
import { ClientGoalDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ClientGoalsListProps<T = ClientGoalDto>
  extends Omit<SortableListProps<T>, "options" | "ListItem" | "itemType"> {
  goals: ClientGoalDto[];
  onItemActionClick: ClientGoalListItemProps["onAction"];
  onUpdate: SortableListProps<T>["onUpdate"];
  disabled?: boolean;
}

export function ClientGoalsList(props: ClientGoalsListProps) {
  const {
    className,
    goals,
    onItemActionClick,
    disabled = false,
    ...other
  } = props;
  const s = useStyles();

  return (
    <SortableList
      className={clsx(s.root, className)}
      itemType="client_goal"
      ListItem={ClientGoalListItem as any}
      ListItemProps={{ onAction: onItemActionClick, disabled: disabled }}
      options={goals}
      {...other}
    ></SortableList>
  );
}
