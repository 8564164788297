import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, ContainerProps, Typography } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { colorSystem } from "../../theme";
import { SOMETHING_WENT_WRONG } from "../../constants";
import { useMutation } from "@tanstack/react-query";
import BillingsService from "../../services/BillingsService";
import { useNavigate } from "react-router-dom";
import { COACH_PLAN_ROUTE } from "../../routes/routes";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    marginTop: theme.spacing(2),
  },

  cardTitle: {
    margin: theme.spacing(2, 0),
  },

  separator: {
    margin: theme.spacing(0, 0.5),
  },

  flexInput: {
    display: "flex",
  },

  input: {
    margin: theme.spacing(0.5, 0),
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.border2,
    padding: theme.spacing(2.3, 1.9),
    fontSize: 16,
  },

  subtext: {
    marginTop: theme.spacing(1.5),
    textAlign: "center",
    color: colorSystem.gray2,
    fontSize: 12,
  },
}));

const cardStyle = {
  placeholder: "Card Number",
  style: {
    base: {
      color: colorSystem.black,
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: colorSystem.gray,
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export interface CoachCardInfoFormProps
  extends Omit<ContainerProps, "children"> {
  isNewUser?: boolean;
}

export const CoachCardInfoForm = (props: CoachCardInfoFormProps) => {
  const s = useStyles();
  const [processing, setProcessing] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { showToastAlert } = useToastAlert();

  const navigate = useNavigate();
  const { mutate: createClientSecret, isPending: creatingClientSecret } =
    useMutation({
      mutationKey: ["change-coach-credit-card"],
      mutationFn: BillingsService.changeCoachCreditCard,
    });

  const handleSubmit = React.useCallback(() => {
    createClientSecret(undefined, {
      onSuccess: async ({ clientSecret }) => {
        try {
          setProcessing(true);
          const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          });

          if (result.error) {
            setProcessing(false);
            showToastAlert("error", {
              message: result.error.message,
            });
          } else {
            setProcessing(false);
            showToastAlert("success", {
              message: "Card details saved",
            });
            setTimeout(() => {
              navigate(COACH_PLAN_ROUTE);
            }, 1000);
          }
        } catch (e) {
          console.error(e);
          showToastAlert("error", {
            message: SOMETHING_WENT_WRONG,
          });
        }
      },
    });
  }, [stripe, elements, createClientSecret, showToastAlert]);

  return (
    <form id="payment-form">
      <Typography className={s.cardTitle} variant="h3">
        Enter card details
      </Typography>
      <CardNumberElement className={s.input} options={cardStyle} />
      <div className={s.flexInput}>
        <CardExpiryElement
          className={s.input}
          options={{ ...cardStyle, placeholder: "MM / YY" }}
        />
        <div className={s.separator} />
        <CardCvcElement
          className={s.input}
          options={{ ...cardStyle, placeholder: "CVV" }}
        />
      </div>
      <Button
        className={s.submit}
        variant="contained"
        children="Confirm"
        fullWidth
        onClick={handleSubmit}
        disabled={creatingClientSecret || processing}
      />
      <Typography className={s.subtext} variant="subtitle2">
        By confirming, you allow Growth Machine to charge your card for this
        payment and future payments in accordance with their terms.
      </Typography>
    </form>
  );
};
