import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { CLIENT_NOTES_QUERY_KEY } from "../../screen/CoachClientNotesScreen";
import UserNotesService from "../../../services/UserNotesService";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { PaginatedListOfUserNoteContentDto } from "@growth-machine-llc/stridist-api-client";
import { InfiniteData } from "@tanstack/react-query";
import { useClient } from "../../../hooks/useClient";

const useUpdateNoteMutation = () => {
  const client = useClient();
  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_NOTES_QUERY_KEY, { client: client.id }],
    mutationFn: UserNotesService.updateUserNote,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: any, newData) => {
        return INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
          oldData as InfiniteData<PaginatedListOfUserNoteContentDto>,
          newData.id,
          { content: newData.content },
        );
      },
    },
  });
};

export default useUpdateNoteMutation;
