import clsx from "clsx";
import React from "react";
import { Card, CardProps, Typography, Box, IconButton } from "@mui/material";
import { MailOutline, MoreVert } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientStatus, Units, UserRole } from "../../constants";
import { colorSystem } from "../../theme";
import {
  formatAgeAndGender,
  formatWeight,
  formatHeight,
} from "../../utils/units";

import { useCurrentUser, UserInfo } from "../../hooks/useCurrentUser";
import { CardAvatar } from "../card/CardAvatar";
import { ListMenu } from "../menu/ListMenu";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  COACH_CLIENT_SETTINGS_ROUTE,
  COACH_CLIENTS_ARCHIVED_ROUTE,
  COACH_MESSAGE_ROUTE,
} from "../../routes/routes";
import {
  IBriefClientInfo,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import ClientsService from "../../services/ClientsService";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "./CoachClientsListScreen";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../wrappers/router/coach/CoachClientProfileWrapper";
import ClientContextMenu from "./ClientContextMenu";
import useDeleteClientsMutation from "./mutations/useDeleteClientsMutation";
import { useInvalidateCoachProgramAndGroupQueries } from "../../hooks/useInvalidateCoachProgramAndGroupQueries";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 0,
    borderRadius: 0,
    marginTop: 20,
  },

  content: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },

  avatar: {
    width: 100,
    height: 100,
    margin: 0,
    borderRadius: 4,
  },

  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: theme.spacing(0, 3),
  },

  header: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    color: theme.palette.common.black,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "29px",
    },
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },

  topMargin: {
    marginTop: theme.spacing(0.5),
  },

  metrics: {
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: theme.spacing(0.5),
    backgroundColor: colorSystem.blue4,
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
      width: "100%",
      marginTop: theme.spacing(0),
    },
  },

  metricWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },

  metricValue: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  more: {
    color: theme.palette.text.secondary,
    position: "absolute",
    right: theme.spacing(-2),
    top: theme.spacing(-2),
    height: theme.spacing(6),
    width: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(0),
      right: theme.spacing(0),
    },
  },

  userMenuItem: {
    padding: theme.spacing(0),
    margin: theme.spacing(1, 0),
    cursor: "pointer",
    "&:hover *": {
      color: theme.palette.common.black,
    },
  },

  userMenuText: {
    "& > span": {
      fontWeight: "bold",
      fontSize: 15,
    },
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  infoUser: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
}));

export interface ClientSummaryProps extends CardProps {
  client:
    | (Omit<UserInfo, "phoneCode"> & Omit<IBriefClientInfo, "id">)
    | IBriefClientInfo;
}

export function ClientSummary(props: ClientSummaryProps) {
  const { className, client, ...other } = props;
  const s = useStyles();
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const {
    id: clientId,
    displayName,
    photoUrl: photoURL,
    email,
    age,
    gender,
    weight,
    height,
    units,
    location,
    status,
    lastLoginAt,
  } = client;
  const clientStatus = status as unknown as ClientStatus;

  const initials = displayName.substring(0, 1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { showToastAlert } = useToastAlert();
  const queryClient = useQueryClient();
  const invalidateCoachProgramAndGroupQueries =
    useInvalidateCoachProgramAndGroupQueries();
  const { mutate: toggleArchiveClient, isPending: archivingClient } =
    useMutation({
      mutationFn: ClientsService.archiveClient,
      onSuccess: (_, { archived }) => {
        queryClient.setQueryData<IBriefClientInfo>(
          [COACH_CLIENT_GENERIC_QUERY_KEY, { username }],
          (prev) => ({
            ...prev,
            status: archived
              ? UserInviteStatus.ARCHIVED
              : UserInviteStatus.ACTIVE,
          }),
        );
        queryClient.resetQueries({
          queryKey: [COACH_CLIENTS_LIST_QUERY_KEY],
          exact: false,
        });

        invalidateCoachProgramAndGroupQueries();
      },
    });

  const { mutate: deleteArchivedClient, isPending: deletingClient } =
    useDeleteClientsMutation();

  const handleDelete = React.useCallback(() => {
    deleteArchivedClient(
      { clientIds: [clientId] },
      {
        onSuccess() {
          navigate(COACH_CLIENTS_ARCHIVED_ROUTE);
        },
      },
    );
  }, [deleteArchivedClient, clientId]);

  const { username } = useParams();

  const details = React.useMemo(
    () => [location || "No location to show", email],
    [location, email],
  );

  const metrics = React.useMemo(
    () => [
      { name: "Age", value: formatAgeAndGender(age ?? "-", gender) },
      { name: "Weight", value: formatWeight(weight, units as Units) },
      { name: "Height", value: formatHeight(height, units as Units) },
    ],
    [age, gender, weight, height, units],
  );

  const handleMoreClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl],
  );

  const handleMoreClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onArchivedChange = React.useCallback(
    (archived: boolean) => {
      toggleArchiveClient(
        {
          clientId: +clientId,
          archived,
        },
        {
          onSuccess: () => {
            const message = archived ? "Client archived" : "Client restored";
            showToastAlert("success", { message });
          },
        },
      );
    },
    [clientId, showToastAlert, toggleArchiveClient],
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.content}>
        <CardAvatar className={s.avatar} src={photoURL} variant="rounded">
          {initials}
        </CardAvatar>
        <Box className={s.info}>
          <Box>
            <Box className={s.infoUser}>
              <Box className={s.circle} />
              <Typography
                variant="body1"
                className={clsx(s.text)}
                children={
                  lastLoginAt ? `Active ${lastLoginAt}` : "No activity to show"
                }
              />
            </Box>
            <Typography variant="h6" className={s.header}>
              {displayName}
            </Typography>
            {details.map((detail, index) => (
              <Typography
                key={index}
                variant="body1"
                className={clsx(s.text, s.topMargin)}
                children={detail}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <Box className={s.metrics}>
        {metrics.map((metric, index) => (
          <Box key={index} className={s.metricWrapper}>
            <Typography
              variant="body1"
              className={s.text}
              children={metric.name}
            />
            <Typography
              variant="body1"
              className={s.metricValue}
              children={metric.value}
            />
          </Box>
        ))}
      </Box>

      {user.role === UserRole.COACH && (
        <>
          <IconButton
            aria-label="settings"
            className={s.more}
            onClick={handleMoreClick}
            children={<MoreVert />}
            size="large"
          />

          <ClientContextMenu
            anchorEl={anchorEl}
            handleClose={handleMoreClose}
            archivingClient={archivingClient}
            onArchivedChange={onArchivedChange}
            deletingClient={deletingClient}
            onDelete={handleDelete}
            user={client}
            isSample={user.accounts.some(
              (account) => client.id === account.id && account.isSample,
            )}
          />
        </>
      )}
    </Card>
  );
}
