import clsx from "clsx";
import React from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";
import { VisibilityOutlined as PreviewIcon } from "@mui/icons-material";

import { ClientFormEditor } from "../new-editor/ClientFormEditor";
import { useBackupState } from "../../hooks/useLocalStorage";
import { ReactComponent as SidebarIcon } from "../../icons/AddSquare.svg";
import { useHistoryBlock } from "../history-block/hooks";

import { usePreview, PreviewType } from "../../hooks/usePreview";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { ActionButton } from "../button/ActionButton";
import { ComponentToolbar } from "../coach-client-forms/ComponentToolbar";
import { OnAnswerUpdateContext } from "../../hooks/useOnAnswerUpdate";
import {
  useCurrentUserId,
  useCurrentUserRole,
} from "../../hooks/useCurrentUser";
import { checkinQuestionsAreValid } from "../../utils/component";
import { UserRole } from "../../constants";
import { ReactComponent as BackIcon } from "../../icons/ArrowBack.svg";
import { polyfillCSS } from "../../utils/css";

import { ClientFormData } from "./types";
import { defaultClientFormName } from "./constants";
import { ClientFormCoverImage } from "./ClientFormCoverImage";
import { ClientFormHeader, ClientFormHeaderData } from "./ClientFormHeader";
import { ClientFormSidebar } from "./ClientFormSidebar";
import { deepCompare, isEmptyContent } from "../editor/utils/common";
import { useNavigate, useParams } from "react-router-dom";
import { COACH_CLIENTS_FORMS_ROUTE } from "../../routes/routes";
import dayjs from "dayjs";
import {
  CheckInComponentContext,
  EditorComponentItemDisabledContext,
} from "../new-editor/hooks";
import {
  getCheckInComponentStatus,
  getCheckInComponentView,
} from "../new-editor/utils/editorUtils";
import { useQueryParam } from "../../hooks/useQueryParam";
import { TElement } from "@udecode/plate-common";
import { CheckInTypes } from "../new-editor/utils/menuItemUtil";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ClientFormClientInfoDto,
  ClientFormCoachInfoDto,
  PaginatedListOfClientFormCoachListDto,
} from "@growth-machine-llc/stridist-api-client";
import ClientFormsCoachService from "../../services/ClientFormsCoachService";
import { ActivityFeedbackButton } from "../activity-feedback/ActivityFeedbackButton";
import { COACH_CLIENTS_FORM_QUERY_KEY } from "../coach-client-forms/CoachClientsFormScreen";
import { CLIENT_FORMS_LIST_QUERY_KEY } from "../coach-client-forms/CoachClientsFormsListScreen";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { CLIENT_FORMS_CARD_QUERY_KEY } from "./ClientFormsCard";
import { CHECKIN_ELEMENT_TYPES } from "../editor/types/elements";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { CLIENT_FORM_QUERY_KEY } from "./ClientFormScreen";
import { ACTIVITY_LIST_QUERY_KEY } from "../activity/CoachActivity";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
    width: "100%",
    minHeight: "100vh",

    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(106.25),
    },
  },

  withoutImage: {},
  disabled: {
    pointerEvents: "none",
  },
  readOnly: {
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",

    "& $coverImage": {
      borderRadius: 0,
    },
  },

  coverImage: {
    position: "absolute",
    left: 0,
    right: 0,
  },

  header: {
    position: "relative",
    margin: theme.spacing(16, 5, 3, 5),

    "$withoutImage &": {
      marginTop: theme.spacing(5),
    },
  },

  editor: {
    margin: theme.spacing(0, 5),
    backgroundColor: theme.palette.background.paper,

    "$readOnly &": {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  },

  preview: {
    width: "100%",
    height: `calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(PREVIEW_BAR_SPACING),
  },

  content: {
    marginBottom: "auto",
  },

  submit: {
    margin: theme.spacing(4, 0),
  },

  buttons: {
    position: "absolute",
    top: polyfillCSS(`calc(${theme.spacing(1)} + var(--safe-area-inset-top))`),
    left: polyfillCSS(
      `calc(${theme.spacing(1)} + var(--safe-area-inset-left))`,
    ),
  },

  backButton: {
    width: theme.spacing(6),
    minWidth: theme.spacing(6),
    height: theme.spacing(6),

    "$buttons &": {
      backgroundColor: theme.palette.quote,
      borderRadius: theme.spacing(0.5),
      width: theme.spacing(7),
      height: theme.spacing(5.5),
    },
  },

  feedbackButton: {
    "$buttons &": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
      height: theme.spacing(5.5),
    },
  },
}));

type ClientFormInfo = ClientFormCoachInfoDto & ClientFormClientInfoDto;

export interface ClientFormProps {
  clientForm: ClientFormInfo;
  readOnly?: boolean;
  autoSaveDelay?: number;
  returnUrl?: string;
  onSendForm?: () => void;
  disabled?: boolean;
  className?: string;
  refreshPageNumber?: string;
}

export function ClientForm(props: ClientFormProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const s = useStyles();
  const {
    className,
    clientForm,
    onSendForm,
    refreshPageNumber,
    autoSaveDelay = 2000,
    returnUrl = COACH_CLIENTS_FORMS_ROUTE,
    readOnly = false,
    disabled = false,
  } = props;
  const {
    id: clientFormId,
    slug,
    slugId,
    name,
    description,
    content,
    image,
    activity,
    submittedAt,
    lastModified,
  } = clientForm;
  const role = useCurrentUserRole();
  const userId = useCurrentUserId();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [unsafe, setDirty, dirty] = useHistoryBlock();
  const backupEnabled = !(role === UserRole.COACH && readOnly);
  const [data, setData, clearCacheData] = useBackupState<ClientFormData>(
    "client-form",
    [clientFormId, slugId].filter(Boolean).join(":"),
    {
      name,
      description,
      image,
      content: JSON.parse(content),
    },
    backupEnabled,
  );

  const { showToastAlert } = useToastAlert();

  const { mutate: submitForm, isPending: submitMutationInFlight } = useMutation(
    {
      mutationFn: ClientFormsCoachService.submitForm,
      onSuccess: () => {
        [
          [CLIENT_FORMS_CARD_QUERY_KEY],
          [CLIENT_FORM_QUERY_KEY, slug],
          [ACTIVITY_LIST_QUERY_KEY],
        ].forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey, exact: false });
        });
      },
    },
  );
  const { mutate: updateForm, isPending: updateMutationInFlight } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_FORMS_LIST_QUERY_KEY],
      mutationFn: ClientFormsCoachService.updateForm,
      disableToastAlerts: true,
      optimisticUpdater: {
        updateFn: (oldData: PaginatedListOfClientFormCoachListDto) => {
          const form = oldData.items.find((item) => item.id === clientFormId);
          form.name = data.name;
          form.slug = data.name ? `${data.name}-${slugId}` : slugId;
          form.totalQuestions = data.content.filter((node) =>
            // TODO customize Slate editor typing for Node to include element type
            CHECKIN_ELEMENT_TYPES.includes((node as any)?.type as any),
          ).length;
          return oldData;
        },
      },
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [COACH_CLIENTS_FORM_QUERY_KEY, slugId],
          });
        },
      },
    });

  const [preview, setPreview] = usePreview();
  const updateData = React.useCallback(
    (update: Partial<ClientFormData>) => {
      setData({ ...data, ...update });
      setDirty(true);
    },
    [data, setData, setDirty],
  );

  const handleContentChange = React.useCallback(
    (content: Node[]) => {
      if (!deepCompare(data.content, content)) {
        updateData({ content });
      }
    },
    [data.content, updateData],
  );

  const handleHeaderChange = React.useCallback(
    (header: ClientFormHeaderData) => updateData({ ...header }),
    [updateData],
  );

  const handleImageChange = React.useCallback(
    (image: string) => updateData({ image }),
    [updateData],
  );

  const handleToggleSidebar = React.useCallback(
    () => setSidebarOpen((value) => !value),
    [],
  );

  const handleTogglePreview = React.useCallback(
    () => setPreview(PreviewType.MOBILE),
    [setPreview],
  );

  const handleBack = React.useCallback(
    () => unsafe(() => navigate(returnUrl)),
    [returnUrl, unsafe],
  );

  const submitted = Boolean(submittedAt);
  const [editingEl, setEditingEl] = React.useState<TElement | null>(null);

  const save = React.useCallback(() => {
    updateForm(
      {
        id: clientFormId,
        name: data.name,
        description: data.description,
        content: JSON.stringify(data.content),
        image: data.image,
      },
      {
        onSuccess: () => {
          setSaved(true);
          setDirty(false);
        },
      },
    );
  }, [
    clientFormId,
    data.content,
    data.description,
    data.image,
    data.name,
    updateForm,
    setDirty,
  ]);

  const handleFormSubmit = React.useCallback(
    () =>
      submitForm(
        {
          id: clientForm.formRequestId,
          content: JSON.stringify(data.content),
        },
        {
          onSuccess: () => {
            setDirty(false);
            clearCacheData();

            showToastAlert("success", {
              message: submitted
                ? "Response updated"
                : "Form results submitted",
            });

            if (!submitted) {
              navigate(returnUrl);
            }
          },
        },
      ),
    [
      clearCacheData,
      clientFormId,
      data.content,
      returnUrl,
      setDirty,
      submitForm,
      submitted,
    ],
  );

  const status = React.useMemo(() => {
    const statusText = updateMutationInFlight
      ? "Saving..."
      : saved && !dirty
        ? "Changes saved"
        : "Last update";
    const statusUpdatedAt = dayjs(lastModified?.toString()).fromNow();

    return `${statusText} ${statusUpdatedAt}`;
  }, [dirty, saved, updateMutationInFlight, lastModified]);

  const incomplete = !data.name || isEmptyContent(data.content);
  const withoutImage = readOnly && !data.image;

  React.useEffect(() => {
    if (dirty && !readOnly && !updateMutationInFlight) {
      const timer = setTimeout(save, autoSaveDelay);
      return () => clearTimeout(timer);
    }
  }, [dirty, data, updateMutationInFlight, save, autoSaveDelay, readOnly]);

  const handleBackClick = React.useCallback(
    () => navigate(returnUrl),
    [returnUrl],
  );

  const [previewQueryParam] = useQueryParam("preview", false);
  const { username: clientName } = useParams();

  const buttons = (
    <>
      {!previewQueryParam && (
        <Button onClick={handleBackClick} className={s.backButton}>
          <BackIcon />
        </Button>
      )}

      {activity && (
        <ActivityFeedbackButton
          className={s.feedbackButton}
          activity={activity}
        />
      )}
    </>
  );

  const isPreviewOrClientView = previewQueryParam || role === UserRole.CLIENT;
  const isCoachReview = role === UserRole.COACH && Boolean(clientName);

  const isDisabledMenuItem = (type: string) => {
    // Progress photo disabled in forms
    return type === CheckInTypes.PROGRESS_PHOTO;
  };

  return (
    <EditorComponentItemDisabledContext.Provider value={isDisabledMenuItem}>
      <CheckInComponentContext.Provider
        value={{
          view: getCheckInComponentView(
            role,
            Boolean(previewQueryParam),
            submitted || isCoachReview,
          ),
          status: getCheckInComponentStatus(role, submitted),
          editingEl: editingEl,
          setEditingEl: setEditingEl,
        }}
      >
        {preview ? (
          <Box className={s.preview}>
            <PreviewBar />
            <PreviewBox src={window.location + "?preview=true"} />
          </Box>
        ) : (
          <Box
            className={clsx(s.root, className, {
              [s.readOnly]: readOnly,
              [s.withoutImage]: withoutImage,
            })}
          >
            {!withoutImage && (
              <ClientFormCoverImage
                clientFormId={clientFormId}
                className={s.coverImage}
                image={data.image}
                onImageChange={handleImageChange}
                disabled={readOnly}
              />
            )}

            {!withoutImage && buttons && (
              <Box className={s.buttons}>{buttons}</Box>
            )}

            <ClientFormHeader
              className={s.header}
              header={{
                name: data.name,
                description: data.description,
              }}
              onHeaderChange={handleHeaderChange}
              disabled={readOnly}
              withImage={!withoutImage}
              buttons={withoutImage && buttons}
            />

            <Box className={clsx(s.editor, isCoachReview && s.disabled)}>
              <OnAnswerUpdateContext.Provider
                value={
                  role === UserRole.CLIENT && submittedAt
                    ? handleFormSubmit
                    : undefined
                }
              >
                <ClientFormEditor
                  className={s.content}
                  value={data.content}
                  onChange={handleContentChange}
                  readOnly={readOnly || isPreviewOrClientView}
                  disabled={disabled || isPreviewOrClientView}
                />
              </OnAnswerUpdateContext.Provider>
              {readOnly && !submitted && !isCoachReview && (
                <ActionButton
                  className={s.submit}
                  fullWidth
                  disabled={
                    disabled ||
                    !checkinQuestionsAreValid(data.content) ||
                    submitMutationInFlight
                  }
                  onClick={handleFormSubmit}
                >
                  Submit form
                </ActionButton>
              )}
            </Box>

            {!readOnly && (
              <ComponentToolbar
                header={data.name || defaultClientFormName}
                headerMuted={!data.name}
                onBack={handleBack}
                status={status}
                BackButtonProps={{
                  disabled: updateMutationInFlight,
                }}
                SubmitButtonProps={{
                  disabled: incomplete || dirty,
                  onClick: onSendForm,
                }}
                buttons={[
                  {
                    title: "Add content",
                    active: sidebarOpen,
                    icon: <SidebarIcon />,
                    onClick: handleToggleSidebar,
                  },

                  {
                    title: "Preview",
                    icon: <PreviewIcon />,
                    onClick: handleTogglePreview,
                  },
                ]}
              />
            )}

            {!readOnly && (
              <ClientFormSidebar
                open={sidebarOpen}
                onClose={handleToggleSidebar}
              />
            )}
          </Box>
        )}
      </CheckInComponentContext.Provider>
    </EditorComponentItemDisabledContext.Provider>
  );
}
