import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Checkbox, TextField } from "@mui/material";
import { colorSystem } from "../../../theme";
import { useQueryParam } from "../../../hooks/useQueryParam";
import {
  getSetFieldMaxLength,
  hasExtraColumn,
  isPositiveNumber,
  getRange,
} from "../utils";
import {
  floatNumberInputProps,
  wholeNumberInputProps,
  ExerciseTypeReps,
  repsTypeRelativeOptions,
  ExerciseSetNumberType,
  ExerciseTypeExtra,
  ExerciseTypeSet,
} from "../../../constants";

import { useWorkoutTableStyles } from "../tableStyles";
import WorkoutSetNumber from "./WorkoutSetNumber";
import { Sets } from "../types";

const useStyles = makeStyles((theme) => {
  const bodyStyles = {
    fontSize: 18,
    color: colorSystem.black,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  };

  return {
    body: {
      display: "flex",
      maxHeight: 42,
      alignItems: "center",
      backgroundColor: colorSystem.secondaryGrayOpacity,
      borderRadius: 4,
      marginBottom: 4,
    },
    bodySet: {
      fontSize: 16,
      color: colorSystem.gray,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    bodyExtraColumn: bodyStyles,
    bodyReps: bodyStyles,
    bodyWeight: bodyStyles,
    bodySettings: {
      marginRight: 20,
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(5),
    },
    fieldContainer: {
      display: "flex",
      justifyContent: "center",
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },

      "& input::placeholder": {
        color: theme.palette.text.disabled,
      },

      "& input:focus::placeholder": {
        color: "transparent",
      },

      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
    },
    rangeDivider: {
      color: theme.palette.text.disabled,
    },
    bodyCheckbox: {
      margin: 0,
      padding: 0,
      width: 24,
      height: 24,
      border: 0.1,
      color: colorSystem.gray,
      "&.Mui-checked": {
        color: colorSystem.green2,
      },
    },
    disableCheckboxBox: {
      width: 18,
      height: 18,
      borderColor: colorSystem.greyOpacity,
      borderWidth: 2,
      borderStyle: "solid",
      backgroundColor: colorSystem.greyOpacity2,
      borderRadius: 3,
    },
    history: {
      backgroundColor: colorSystem.green2,
      textAlign: "center",
      color: colorSystem.white,
      borderRadius: 4,
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
    checked: {
      backgroundColor: colorSystem.green4,
    },
    field: {
      padding: 0,
      height: 42,
      [theme.breakpoints.down("md")]: {
        height: 38,
      },
    },
    root: {
      borderWidth: 0,
      "&::before": {
        display: "none",
      },
      "&.Mui-error:after": {
        transform: "scaleX(1) translateX(0)",
      },
    },
    rootErrorColor: {
      "&:after": {
        borderBottomColor: theme.palette.error.main,
      },
    },
  };
});

type WorkoutSetProps = {
  set: Sets;
  setNumber: number;
  onChange?: (set: Sets) => void;
  history?: boolean;
  isCoach?: boolean;
  programId?: number;
  typeSet: ExerciseTypeSet;
  typeReps: ExerciseTypeReps;
  typeExtraMeasurement: ExerciseTypeExtra;
  readOnly?: boolean;
};

const WorkoutSet = (props: WorkoutSetProps) => {
  const {
    set,
    setNumber,
    onChange,
    history,
    isCoach,
    typeSet,
    typeReps,
    typeExtraMeasurement,
    readOnly,
  } = props;

  const {
    extraMeasurement,
    reps,
    weight,
    checked = false,
    type = ExerciseSetNumberType.REGULAR_SET,
    // TODO videoUrl = "",
    completedReps = "",
  } = set;

  const [repsError, setRepsError] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [delayedErrorColor, setDelayedErrorColor] = useState(false);

  const s = useStyles();
  const tableStyles = useWorkoutTableStyles();

  const [disabledAction] = useQueryParam("notification", "");

  const extraFieldRef = useRef(null);
  const repsFieldRef = useRef(null);
  const weightFieldRef = useRef(null);

  const handleSetNumberChange = (value: ExerciseSetNumberType) => {
    onChange({ ...set, type: value });
  };

  const showCompletedReps =
    history || (!isCoach && typeReps === ExerciseTypeReps.RANGE);

  const handleInputContainerClick = (ref: React.MutableRefObject<any>) => {
    if (ref.current) {
      ref.current.focus();
    }
  };
  const inputRange = reps.toString().split("-");
  const rangeStart = inputRange[0]?.trim();
  const rangeEnd = inputRange[1]?.trim();

  const handleRangeChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.currentTarget;

    if (value.length < 4) {
      const rangeEvent = {
        ...event,
        currentTarget: {
          name: "reps",
          value: getRange(name, value, rangeStart, rangeEnd),
        },
      };
      handleFieldChange(rangeEvent as any);
    }
  };

  const getCompletedRepsRangePlaceholder = () => {
    const inputRange = reps?.toString()?.split("-");

    let rangeStart = inputRange[0]?.trim();
    if (!rangeStart) {
      rangeStart = "0";
    }

    let rangeEnd = inputRange[1]?.trim() ?? "0";
    if (!rangeEnd) {
      rangeEnd = "0";
    }

    return `${rangeStart} - ${rangeEnd}`;
  };

  const isRepsInputValid = (
    reps: number | string,
    completedReps: number | string,
  ) => {
    if (repsTypeRelativeOptions.includes(typeReps)) {
      return isPositiveNumber(completedReps);
    }

    return isPositiveNumber(showCompletedReps ? completedReps : reps);
  };

  const isWeightInputValid = (weight: number | string) => {
    return isPositiveNumber(weight) || weight == "0";
  };

  const isSetInputValid = (
    reps: number | string,
    completedReps: number | string,
    weight: number | string,
  ) => {
    return isRepsInputValid(reps, completedReps) && isWeightInputValid(weight);
  };

  const handleFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.currentTarget;

      const maxLength = getSetFieldMaxLength(name, typeReps, typeSet);
      if (value.replace(".", "").length <= maxLength || value === "") {
        const newSet = { ...set };
        newSet[name] = value;

        if (newSet.checked) {
          newSet.checked = isSetInputValid(
            newSet.reps,
            newSet.completedReps,
            newSet.weight,
          );
        }

        if (onChange) {
          onChange(newSet);
        }
      }
    },
    [set, onChange],
  );

  const flashError = (
    setErrorState: (value: React.SetStateAction<boolean>) => void,
  ) => {
    setErrorState(true);
    setDelayedErrorColor(true);

    setTimeout(() => {
      setErrorState(false);
      setTimeout(() => {
        if (!repsError && !weightError) setDelayedErrorColor(false);
      }, 150);
    }, 500);
  };

  const handleCheckBoxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;

      if (
        checked &&
        !isSetInputValid(set.reps, set.completedReps, set.weight)
      ) {
        if (!isRepsInputValid(reps, completedReps)) {
          flashError(setRepsError);
        }

        if (!isWeightInputValid(weight)) {
          flashError(setWeightError);
        }

        return;
      }

      if (onChange) {
        onChange({ ...set, checked });
      }
    },
    [set, onChange],
  );

  return (
    <div
      className={clsx(
        s.body,
        (checked || history) && s.checked,
        hasExtraColumn(typeExtraMeasurement) && tableStyles.rowWithExtraColumn,
      )}
    >
      <div className={clsx(tableStyles.cell, tableStyles.set)}>
        <WorkoutSetNumber
          setNumber={setNumber + 1}
          type={type}
          onChange={handleSetNumberChange}
          disabled={history || Boolean(disabledAction) || readOnly}
        />
      </div>

      <div
        className={clsx(tableStyles.cell, tableStyles.reps, s.bodyReps)}
        onClick={() => handleInputContainerClick(repsFieldRef)}
      >
        {typeReps === ExerciseTypeReps.RANGE && !showCompletedReps ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
            className={s.fieldContainer}
          >
            <TextField
              ref={repsFieldRef}
              value={rangeStart}
              onChange={handleRangeChange}
              name="rangeStart"
              placeholder="0"
              InputProps={{
                classes: {
                  root: s.root,
                  input: s.field,
                },
                inputProps: wholeNumberInputProps,
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              type={history ? "text" : "number"}
              variant="standard"
              disabled={readOnly}
            />
            <Box className={s.rangeDivider}>-</Box>
            <TextField
              value={rangeEnd}
              onChange={handleRangeChange}
              name="rangeEnd"
              placeholder="0"
              InputProps={{
                classes: {
                  root: s.root,
                  input: s.field,
                },
                inputProps: wholeNumberInputProps,
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              type={history ? "text" : "number"}
              variant="standard"
              disabled={readOnly}
            />
          </Box>
        ) : (
          <TextField
            inputRef={repsFieldRef}
            name={showCompletedReps ? "completedReps" : "reps"}
            value={
              showCompletedReps
                ? history
                  ? completedReps || reps
                  : completedReps
                : reps
            }
            InputProps={{
              classes: {
                root: clsx(s.root, delayedErrorColor && s.rootErrorColor),
                input: s.field,
              },
              inputProps: wholeNumberInputProps,
            }}
            className={s.fieldContainer}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            onChange={handleFieldChange}
            type={history ? "text" : "number"}
            autoComplete="off"
            placeholder={
              typeReps === ExerciseTypeReps.RANGE
                ? getCompletedRepsRangePlaceholder()
                : "0"
            }
            error={repsError}
            disabled={history || Boolean(disabledAction) || readOnly}
            variant="standard"
          />
        )}
      </div>

      <div
        className={clsx(tableStyles.cell, tableStyles.weight, s.bodyWeight)}
        onClick={() => handleInputContainerClick(weightFieldRef)}
      >
        <TextField
          inputRef={weightFieldRef}
          name="weight"
          value={weight}
          InputProps={{
            classes: {
              root: clsx(s.root, delayedErrorColor && s.rootErrorColor),
              input: s.field,
            },
            inputProps: {
              ...floatNumberInputProps,
            },
          }}
          className={s.fieldContainer}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          onChange={handleFieldChange}
          type="number"
          autoComplete="off"
          placeholder="0"
          error={weightError}
          disabled={history || Boolean(disabledAction) || readOnly}
          variant="standard"
        />
      </div>

      {hasExtraColumn(typeExtraMeasurement) && (
        <div
          className={clsx(
            tableStyles.cell,
            tableStyles.extraColumn,
            s.bodyExtraColumn,
          )}
          onClick={() => handleInputContainerClick(extraFieldRef)}
        >
          <TextField
            inputRef={extraFieldRef}
            name="extraMeasurement"
            value={extraMeasurement}
            InputProps={{
              classes: {
                root: s.root,
                input: s.field,
              },
              inputProps: {
                ...floatNumberInputProps,
              },
            }}
            className={s.fieldContainer}
            placeholder="0"
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            onChange={handleFieldChange}
            type={history ? "text" : "number"}
            autoComplete="off"
            disabled={history || Boolean(disabledAction) || readOnly}
            variant="standard"
          />
        </div>
      )}

      <div
        className={clsx(
          tableStyles.cell,
          tableStyles.result,
          history && tableStyles.resultHistory,
        )}
      >
        <Checkbox
          color="secondary"
          className={clsx(s.bodyCheckbox)}
          onChange={handleCheckBoxChange}
          checked={checked}
          icon={history ? <div className={s.disableCheckboxBox} /> : undefined}
          disabled={history || isCoach || Boolean(disabledAction)}
        />
      </div>
    </div>
  );
};

export default WorkoutSet;
