import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { deleteWorkoutExercise } from "./operation";

export const deleteWorkoutExerciseAction = {
  prepare(componentId: number, workoutSectionId: number, exerciseId: number) {
    return {
      payload: {
        componentId,
        workoutSectionId,
        exerciseId,
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      componentId: number;
      workoutSectionId: number;
      exerciseId: number;
    }>,
  ) {
    const { componentId, workoutSectionId, exerciseId } = action.payload;
    if (!componentId || !workoutSectionId || !exerciseId) return;

    const component = state.value.components[componentId];
    if (!component) return;

    const updatedContent = deleteWorkoutExercise(
      workoutSectionId,
      exerciseId,
      component.content,
    );

    state.value.components[componentId] = {
      ...component,
      content: updatedContent,
    };
  },
};
