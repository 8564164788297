import clsx from "clsx";
import React from "react";
import { DialogProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "../fields/DatePicker";
import { getISODate } from "../../utils/date";
import { EnrollmentSelected } from "../groups/ClientsAndGroupsSelectDialog";

import { BaseDialog } from "./BaseDialog";
import { useParams, useSearchParams } from "react-router-dom";
import {
  ClientBriefDto,
  EnrollGroupDto,
  EnrollmentProgramDto,
  EnrollmentsVm,
} from "@growth-machine-llc/stridist-api-client";
import { useEnrollMutation } from "./mutations/EnrollMutation";
import { extractSlugId } from "../../utils/slug";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

export interface EnrollDateDialogProps extends DialogProps {
  program: EnrollmentProgramDto;
  selected: EnrollmentSelected;
}

export function EnrollDateDialog(props: EnrollDateDialogProps) {
  const { className, onClose, program, selected, ...other } = props;
  const s = useStyles();
  const { slug } = useParams();
  const slugId = extractSlugId(slug);
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("sort");
  const [startDate, setStartDate] = React.useState(new Date());

  const { mutate: enrollClients, isPending: enrollClientsInFlight } =
    useEnrollMutation("clients", slugId, selected.clients?.length);
  const { mutate: enrollGroups, isPending: enrollGroupsInFlight } =
    useEnrollMutation("group", slugId, selected.groups?.length);

  const items = React.useMemo(
    () => selected.clients || selected.groups,
    [selected],
  );
  const label = React.useMemo(
    () =>
      `${selected.clients ? "client" : "group"}${
        items.length === 1 ? "" : "s"
      }`,
    [items.length, selected.clients],
  );

  const handleDateChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setStartDate(new Date());
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const mutationConfigForClients = React.useMemo(
    () => ({
      programId: program.id,
      startDate: getISODate(startDate),
      emails: (items as ClientBriefDto[]).map(({ email }) => email),
    }),
    [items, program.id, startDate],
  );

  const mutationConfigForGroups = React.useMemo(
    () => ({
      programId: program.id,
      startDate: getISODate(startDate),
      groupIds: (items as EnrollGroupDto[]).map(({ id }) => id),
    }),
    [items, program.id, startDate],
  );

  const handleEnrollClick = React.useCallback(() => {
    if (selected.clients) {
      enrollClients(mutationConfigForClients);
    } else {
      enrollGroups(mutationConfigForGroups);
    }
    handleClose();
  }, [
    enrollClients,
    enrollGroups,
    mutationConfigForClients,
    mutationConfigForGroups,
    handleClose,
    selected.clients,
  ]);

  const disabled =
    !items.length || enrollClientsInFlight || enrollGroupsInFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Choose start time"
      subtitle={`Select when ${label} should start on the program.`}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleDateChange}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={`Enroll ${items.length} ${label}`}
        onClick={handleEnrollClick}
        disabled={disabled}
      />
    </BaseDialog>
  );
}
