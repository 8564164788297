import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { MoreMenuButton } from "../button/MoreMenuButton";
import { LibraryExerciseMenu } from "../menu/LibraryExerciseMenu";
import { ExerciseAsset } from "../exercise-library/types";
import { useCurrentUser } from "../../hooks/useCurrentUser";

import { WorkoutExercise } from "../workout/types";
import { DraggableSnippet } from "../editor/components/utils/DraggableSnippet";
import { WORKOUT_SECTION } from "../new-editor/components/workout/WorkoutSectionElement";
import { ComponentLibraryAssetSource } from "../component-library/ComponentLibraryAsset";
import MinimizedTooltip, { TooltipLine } from "../tooltip/MinimizedTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: theme.spacing(8),
    paddingRight: theme.spacing(4),
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.selected.light,
    },
  },

  name: {
    fontWeight: 500,
  },

  moreButton: {
    position: "absolute",
    right: theme.spacing(4),
    opacity: 0,

    "$root:hover &": {
      opacity: 1,
    },
  },
}));

export interface Asset {
  id: number;
  name: string;
  content: string;
  coachId: number;
}

export const useMinimizedExerciseTooltipStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "&::before": {
      content: "''",
      display: "inline-block",
      width: 8,
      height: 8,
      borderRadius: "50%",
    },
  },

  custom: {
    "&::before": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  public: {
    "&::before": {
      visibility: "hidden",
    },
  },
}));

export interface ExerciseLibraryExerciseProps
  extends Omit<BoxProps, "onClick"> {
  asset: Asset;
  onClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ExerciseAsset,
  ) => void;
}
export interface IMinimizedExerciseTooltipProps {
  canEdit: boolean;
}
export const MinimizedExerciseTooltip = (
  props: IMinimizedExerciseTooltipProps,
) => {
  const { canEdit } = props;
  const s = useMinimizedExerciseTooltipStyles();

  return (
    <MinimizedTooltip
      title={
        canEdit && (
          <Stack direction="column">
            <TooltipLine>
              Uploaded <br /> by <b>me</b>
            </TooltipLine>
          </Stack>
        )
      }
      placement={"left"}
      opacity={0.7}
      isExerciseLibrary={true}
    >
      <Typography
        className={clsx(s.info, canEdit ? s.custom : s.public)}
      ></Typography>
    </MinimizedTooltip>
  );
};

export function ExerciseLibraryExercise(props: ExerciseLibraryExerciseProps) {
  const { className, asset, onClick, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const { id: assetId, name, content, coachId } = asset;
  const menuState = usePopupState({
    popupId: "library-exercise-menu",
    variant: "popper",
  });

  const canEdit = user.id === coachId;
  const handleClick = React.useCallback(
    (event) => {
      const exercise = JSON.parse(content) as WorkoutExercise;
      const value: ExerciseAsset = {
        assetId,
        exercise,
      };

      if (onClick) {
        onClick(event, value);
      }
    },
    [assetId, content, onClick],
  );

  const handleMenuClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      menuState.open(event);
    },
    [menuState],
  );

  return (
    <Box className={clsx(s.root, className)}>
      <DraggableSnippet
        content={{
          element: {
            type: WORKOUT_SECTION,
            children: [{ text: "" }],
            workout: {
              exercises: [JSON.parse(content) as WorkoutExercise],
              title: "",
            },
          },
          source: ComponentLibraryAssetSource,
        }}
        draggableContent
      >
        <Box
          className={clsx(s.root, className)}
          onClick={handleClick}
          {...other}
        >
          <MinimizedExerciseTooltip canEdit={canEdit} />
          <Typography className={clsx(s.name)}>{name}</Typography>

          {canEdit && (
            <>
              <MoreMenuButton
                {...bindTrigger(menuState)}
                className={s.moreButton}
                onClick={handleMenuClick}
              />
              {menuState.isOpen && (
                <LibraryExerciseMenu
                  {...bindMenu(menuState)}
                  assetId={assetId}
                />
              )}
            </>
          )}
        </Box>
      </DraggableSnippet>
    </Box>
  );
}
