import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Button,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ComponentIcons } from "../program/icons";
import { getContentType } from "../../utils/component";
import { ComponentType } from "../../constants";
import { useEditorProgram } from "../new-editor/hooks";
import { useCurriculumDispatch } from "../../redux/hooks";
import {
  updateComponentDefaultIcon,
  updateComponentIcon,
} from "../../redux/curriculum/curriculum-slice";
import { CurriculumComponent } from "../../redux/types";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    outline: "none",
  },

  icons: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
  },

  icon: {
    cursor: "pointer",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    textAlign: "center",

    "& svg": {
      width: 32,
      height: 32,
    },

    "&:hover": {
      backgroundColor: theme.palette.selected.main,
    },

    "&$selected": {
      backgroundColor: theme.palette.quote,
    },
  },

  selected: {},

  checkbox: {
    "& .MuiFormControlLabel-label": {
      color: theme.palette.text.secondary,
    },
  },

  button: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(0.5),
  },
}));

export interface ISelectComponentIconComponent {
  type: any;
  iconName: any;
  id: any;
}

export interface SelectComponentIconsPropsBase
  extends Omit<BoxProps, "onSubmit"> {
  onSubmit?: (iconName: string, applyToAll: boolean) => void;
  disableApplyToAll?: boolean;
}

export interface SelectComponentIconsProps
  extends SelectComponentIconsPropsBase {
  componentData: CurriculumComponent;
}
export const SelectComponentIcons = React.forwardRef(function (
  props: SelectComponentIconsProps,
  ref,
) {
  const { className, onSubmit, componentData, disableApplyToAll, ...other } =
    props;
  const dispatch = useCurriculumDispatch();
  const s = useStyles();
  const { programId } = useEditorProgram();
  const component = componentData;
  const componentTypeName = getContentType(component.type as ComponentType);
  const [selected, setSelected] = React.useState(component.iconName);
  const [applyToAll, setApplyToAll] = React.useState(false);

  const handleClick = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setSelected(event.currentTarget.dataset.name);

    return false;
  }, []);

  const handleApplyToAllChange: CheckboxProps["onChange"] = React.useCallback(
    (_, checked) => {
      setApplyToAll(checked);
    },
    [],
  );

  const componentId = component.id;
  const componentType = component.type;

  const handleSubmit = React.useCallback(() => {
    const iconName = selected;

    if (applyToAll) {
      dispatch(updateComponentDefaultIcon({ componentType, iconName }));
    } else {
      dispatch(updateComponentIcon({ componentId, iconName }));
    }

    if (onSubmit) {
      onSubmit(selected, applyToAll);
    }
  }, [
    applyToAll,
    component.id,
    component.type,
    componentTypeName,
    onSubmit,
    programId,
    selected,
  ]);

  return (
    <Box className={clsx(s.root, className)} {...{ ref }} {...other}>
      <Box className={s.icons}>
        {Object.entries(ComponentIcons).map(([name, Icon]) => (
          <Box
            key={name}
            data-name={name}
            onClick={handleClick}
            className={clsx(s.icon, selected === name && s.selected)}
          >
            <Icon />
          </Box>
        ))}
      </Box>

      {1 && (
        <FormControlLabel
          className={s.checkbox}
          control={
            <Checkbox
              name="apply-to-all"
              checked={applyToAll}
              onChange={handleApplyToAllChange}
              color="primary"
            />
          }
          label={`Apply icon to all ${componentTypeName}s`}
        />
      )}

      <Button
        className={s.button}
        fullWidth
        variant="contained"
        onClick={handleSubmit}
      >
        Apply changes
      </Button>
    </Box>
  );
});
