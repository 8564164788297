import React, { FC, useCallback, useState, useTransition } from "react";
import { Drawer, DrawerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientAutoNutritionEntryHistory } from "./ClientAutoNutritionEntryHistory";
import { ClientNutritionDrawerHeader } from "./ClientNutritionDrawerHeader";
import AddAutoNutritionEntryForm from "./AddAutoNutritionEntryForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  header: {
    marginBottom: theme.spacing(4),
  },
}));

export interface ClientAutoNutritionDrawerProps extends DrawerProps {
  refresh: any;
  entries: any[];
  clientInfo: { clientId?: number; username?: string };
}

export const ClientAutoNutritionDrawer: FC<ClientAutoNutritionDrawerProps> = (
  props,
) => {
  const { onClose, refresh, entries, clientInfo, ...restProps } = props;
  const s = useStyles();
  const { clientId, username } = clientInfo;
  const [isPending, startTransition] = useTransition();
  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
    variables: {},
  });

  const refetch = useCallback(() => {
    startTransition(() => {
      setQueryArgs((prev) => ({
        ...prev,
        options: {
          fetchKey: (prev?.options.fetchKey ?? 0) + 1,
          fetchPolicy: "store-and-network",
        },
      }));
      refresh?.();
    });
  }, [refresh]);

  const handleClose = useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  return (
    <Drawer
      classes={{
        paper: s.paper,
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      {...restProps}
    >
      <ClientNutritionDrawerHeader
        className={s.header}
        onClose={handleClose}
        title="Synced nutrition entries"
      />
      <AddAutoNutritionEntryForm
        entries={entries}
        refresh={refetch}
        clientInfo={clientInfo}
      />
      <ClientAutoNutritionEntryHistory
        entries={entries}
        refresh={refetch}
        clientInfo={clientInfo}
      />
    </Drawer>
  );
};
