import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  CardMedia,
  Button,
  darken,
  Dialog,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";

import { colorSystem } from "../../theme";
import { AppLogo } from "../app/AppLogo";
import { GroupDescriptionEditor } from "../group-posts/GroupDescriptionEditor";
import { Avatar } from "../avatar/Avatar";

import { ValidatedInviteCodeDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    minHeight: "100vh",
    backgroundColor: colorSystem.white2,
  },

  cover: {
    width: "100%",
    backgroundColor: theme.palette.primary.light,
  },

  coverBody: {
    width: "100%",
    height: theme.spacing(47.5),
    padding: theme.spacing(12, 0),
    display: "flex",
    justifyContent: "center",
    background: "linear-gradient(transparent, hsla(0, 0%, 0%, 0.6))",
  },

  footer: {
    padding: theme.spacing(5, 0),
    display: "flex",
    justifyContent: "center",
  },

  wrapper: {
    width: "100%",
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.breakpoints.values.sm,
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md,
    },

    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },

  coach: {
    display: "flex",
    alignItems: "center",
  },

  coachName: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 16,
  },

  coachAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(0, 2, 0, 0),
  },

  targetName: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 44,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },

  joinButton: {
    fontWeight: 700,
    fontSize: 16,
    borderRadius: theme.spacing(3.5),
    padding: theme.spacing(1.5, 7.75),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],

    "&:hover": {
      backgroundColor: darken(theme.palette.common.white, 0.25),
    },
  },

  logo: {
    marginBottom: theme.spacing(3),
  },

  descriptionTitle: {
    fontSize: 16,
    fontWeight: 700,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.quote,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },

  description: {
    fontSize: 16,
    fontWeight: 500,
  },

  close: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),

    "&:hover": {
      color: theme.palette.text.primary,
    },
  },

  shadowText: {
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
}));

export interface ClientDetailedLinkInviteScreenProps extends BoxProps {
  invite: ValidatedInviteCodeDto;
  enabled?: boolean;
}

export function ClientDetailedLinkInviteScreen(
  props: ClientDetailedLinkInviteScreenProps,
) {
  const { className, invite, children, enabled, ...other } = props;
  const s = useStyles();
  const [open, setOpen] = React.useState(false);
  const { groupInfo: group, programInfo, coachInfo } = invite;
  const target = group || programInfo;
  const description = React.useMemo(
    () =>
      group
        ? group.description
          ? JSON.parse(group.description)
          : null
        : programInfo?.description,
    [group, programInfo],
  );

  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  return enabled ? (
    <Box className={clsx(s.root, className)} {...other}>
      <CardMedia className={s.cover} image={target.image}>
        <Box className={s.coverBody}>
          <Box className={s.wrapper}>
            <Box className={s.coach}>
              <Avatar
                className={s.coachAvatar}
                displayName={coachInfo.displayName}
                photoURL={coachInfo.photoURL}
              />
              <Typography className={clsx(s.coachName, s.shadowText)}>
                {coachInfo.displayName}
              </Typography>
            </Box>
            <Typography className={clsx(s.targetName, s.shadowText)}>
              {target.name}
            </Typography>
            <Button className={s.joinButton} onClick={handleOpen}>
              {programInfo ? "Get Started" : "Join Group"}
            </Button>
          </Box>
        </Box>
      </CardMedia>

      <Box className={s.footer}>
        <Box className={s.wrapper}>
          <AppLogo className={s.logo} />
          {description && (
            <>
              <Typography className={s.descriptionTitle}>
                Description
              </Typography>
              <Typography className={s.description} component="div">
                {programInfo ? (
                  description
                ) : (
                  <GroupDescriptionEditor value={description} readOnly />
                )}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <IconButton
          className={s.close}
          color="primary"
          onClick={handleClose}
          children={<Close />}
          size="large"
        />
        {children}
      </Dialog>
    </Box>
  ) : (
    <>{children}</>
  );
}
