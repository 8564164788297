import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    marginBottom: theme.spacing(2),
    alignItems: "center",

    "& > :nth-child(1)": {
      marginRight: theme.spacing(1),
    },

    "& > div": {
      width: "100%",
    },
  },
}));

export interface ThreadSkeletonProps extends BoxProps {}

export function ThreadSkeleton(props: ThreadSkeletonProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Skeleton variant="circular" width={50} height={40} />
      <Box>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Box>
    </Box>
  );
}
