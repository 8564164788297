import React, { useContext } from "react";

import { useParams } from "react-router-dom";
import { TrainingSummary } from "../../../../components/training-summary/TrainingSummary";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useProgramComponentRoute } from "../../../../wrappers/router/common/ProgramsComponentRouteWrapper";

export function CoachComponentClientSummaryRoute() {
  const params = useParams();
  const client = params.client;

  const { activity } = useProgramComponentRoute();
  return (
    <>
      <TrainingSummary activity={activity} clientUsername={client} />
      <TrackInfoTool
        trackInfo={{
          name: "Client - Program",
        }}
      />
    </>
  );
}
