import clsx from "clsx";
import React from "react";
import { BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CalendarComponentCard } from "../card/CalendarComponentCard";
import {
  ComponentForCalendarDto,
  IComponentForCalendarDto,
} from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: "100%",
    height: "100%",
    boxSizing: "content-box",
  },
}));

export interface ProgramCalendarComponentProps
  extends Omit<BoxProps, "component"> {
  component: IComponentForCalendarDto;
  onTitleClick: (slug?: string, typeName?: string) => void;
  activeComponentSlug?: string;
}

export function ProgramCalendarComponent(props: ProgramCalendarComponentProps) {
  const { className, component, onTitleClick, activeComponentSlug } = props;
  const s = useStyles();

  return (
    <CalendarComponentCard
      className={clsx(s.root, className)}
      component={new ComponentForCalendarDto(component)}
      onTitleClick={() => onTitleClick(component.slug, component.type)}
      isHighlighted={component.slug === activeComponentSlug}
    />
  );
}
