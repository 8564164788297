import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientSmallProgramCard } from "./ClientSmallProgramCard";
import { ClientEnrollmentDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(0, 2),
  },

  textWrapper: {
    textAlign: "center",
  },

  bigText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
  },

  smallText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },
}));

export interface ClientUpcomingProgramCardProps {
  enrollment: ClientEnrollmentDto;
}

export function ClientUpcomingProgramCard(
  props: ClientUpcomingProgramCardProps,
) {
  const { enrollment, ...other } = props;
  const s = useStyles();
  const { totalDays, startFromNow, endDate } = enrollment;
  const endDateShort = endDate.format("MMM D");

  const content = React.useMemo(
    () => (
      <Box className={s.content}>
        <Box className={s.textWrapper}>
          <Typography
            className={s.bigText}
            children={`${totalDays} day${totalDays > 1 ? "s" : ""}`}
          />
          <Typography className={s.smallText} children="Program length" />
        </Box>
        <Box className={s.textWrapper}>
          <Typography className={s.bigText} children={endDateShort} />
          <Typography className={s.smallText} children="End date" />
        </Box>
      </Box>
    ),
    [totalDays, endDateShort], // eslint-disable-line
  );

  return (
    <ClientSmallProgramCard
      enrollment={enrollment}
      subtitle={`Starts ${startFromNow}`}
      content={content}
      {...other}
    />
  );
}
