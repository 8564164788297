export const slugify = (identifier: string, id?: string) => {
  // Normalize the string
  let slug = identifier.normalize("NFKD");

  // Remove quotes
  slug = slug.replace(/['"]+/g, "");

  // Replace non-alphanumeric characters (except '-' and '_') with '-'
  slug = slug.replace(/[^a-zA-Z0-9-_]+/g, "-");

  // Convert to lowercase
  slug = slug.toLowerCase();

  // Trim leading and trailing dashes
  slug = slug.replace(/^-+|-+$/g, "");

  return slug + (id ? `-${id}` : "");
};

export const extractSlugId = (slug: string) => {
  try {
    if (slug.endsWith("-tempslug")) {
      return slug.split("-tempslug")[0];
    }
    const parts = slug.split("-");
    return parts.pop();
  } catch (e) {
    console.warn("Failed to extract slug id", e);
    return "";
  }
};

export const refreshSlug = (slug: string, newIdentifier: string) => {
  const id = extractSlugId(slug);
  return slugify(newIdentifier, id);
};
