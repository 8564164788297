import React from "react";
import {
  Dialog,
  DialogProps,
  Box,
  Typography,
  Button,
  Stack,
  DialogContent,
  useTheme,
} from "@mui/material";

export interface DownloadMobileAppDialogProps extends DialogProps {}

export function CurriculumErrorAppDialog(props) {
  const theme = useTheme();

  const forceMobileDialogContent = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Whoops!
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          Something went wrong.
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          spacing={1}
          sx={{ my: 2, maxWidth: "160px" }}
        >
          <Button
            sx={{
              fontSize: 13,
              fontWeight: "bold",
              borderRadius: theme.spacing(1),
              padding: theme.spacing(1.125, 4),
              margin: theme.spacing(1),

              borderColor: theme.palette.common.black,
              borderWidth: 2,
              borderStyle: "solid",
              minWidth: theme.spacing(20),
            }}
            variant="contained"
            onClick={() => {
              window.location.reload();
            }}
            children="Reload"
          />
        </Stack>
      </>
    );
  };

  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: { width: 1, maxWidth: 500, top: 0, position: "absolute" },
      }}
      componentsProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: 1,
        }}
      >
        {forceMobileDialogContent()}
      </DialogContent>
    </Dialog>
  );
}
