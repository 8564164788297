import React from "react";
import {
  InfiniteData,
  QueryKey,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import NotificationsService from "../../../services/NotificationsService";
import { useUpdateNotificationsCounter } from "../../../hooks/useUpdateCurrentUser";
import {
  ICursorPaginatedListOfNotificationBriefDto,
  IReadNotificationCommand,
  NotificationBriefDto,
} from "@growth-machine-llc/stridist-api-client";
import { on } from "events";
import { NOTIFICATIONS_MENU_LIST_QUERY_KEY } from "../../menu/NotificationsMenu";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";

type UseReadNotificationMutationProps<T> = {
  queryKey: QueryKey;
  exactQueryKey?: boolean;
  readUpdater: (prev: T, variables: IReadNotificationCommand) => T;
  onSuccess?: (data: void, variables: IReadNotificationCommand) => void;
};

export const useReadNotificationMutation = <T>({
  queryKey,
  exactQueryKey,
  readUpdater,
  onSuccess,
}: UseReadNotificationMutationProps<T>) => {
  const queryClient = useQueryClient();
  const updateNotificationsCount = useUpdateNotificationsCounter();
  return useMutation({
    mutationKey: ["notification-read"],
    mutationFn: NotificationsService.readNotification,
    onSuccess(_, variables) {
      queryClient.setQueriesData<T>(
        { queryKey, exact: exactQueryKey },
        (prev) => readUpdater(prev, variables),
      );

      queryClient.setQueryData<
        InfiniteData<ICursorPaginatedListOfNotificationBriefDto>
      >([NOTIFICATIONS_MENU_LIST_QUERY_KEY], (prev) => {
        const notificationId =
          variables.id ??
          prev?.pages
            .flatMap((page) => page.items)
            .find((n) =>
              Object.keys(n).some((key) => n[key] === variables[key]),
            )?.id;

        return prev
          ? INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
              prev,
              notificationId,
              { read: true },
            )
          : prev;
      });

      updateNotificationsCount("decrement", getCounterName(variables));

      onSuccess && onSuccess(_, variables);
    },
  });
};

const getCounterName = (variables: IReadNotificationCommand) => {
  if (variables.activityFeedbackId) {
    return "unreadActivitiesCount";
  } else if (variables.messageId) {
    return "unreadMessagesCount";
  } else {
    return "notificationUnreadCount";
  }
};
