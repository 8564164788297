import { useMutation } from "@tanstack/react-query";
import InvitesService from "../../../services/InvitesService";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { useToastAlert } from "../../app/ToastAlert/ToastAlertProvider";

export default function useResendInviteMutation() {
  const dialogDataQueryKey = COACH_CLIENTS_LIST_QUERY_KEY;
  const { showToastAlert } = useToastAlert();
  return useMutation({
    mutationFn: InvitesService.resendInvite,
    onSuccess: () => {
      showToastAlert("success", {
        message: "Invitation has been resent",
      });
    },
  });
}
