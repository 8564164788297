import clsx from "clsx";
import React from "react";
import { Dialog, DialogProps, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";
import { usePreview, PreviewType } from "../../hooks/usePreview";
import { useEditorProgram } from "../new-editor/hooks";
import { useSearchParams } from "react-router-dom";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { useMutation } from "@tanstack/react-query";
import { ISO_DATE_FORMAT } from "../../utils/date";
import dayjs from "dayjs";
import LoadingActionButton from "../button/LoadingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(4),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: theme.spacing(1),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(4),
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },
}));

export interface PreviewProgramStartDateDialogProps extends DialogProps {}

export function PreviewProgramStartDateDialog(
  props: PreviewProgramStartDateDialogProps,
) {
  const [_, setSearchParams] = useSearchParams();
  const { className, onClose, ...other } = props;
  const s = useStyles();
  const { programId } = useEditorProgram();
  const [, setPreview] = usePreview();

  const [startDate, setStartDate] = React.useState(new Date());
  const { mutate: enroll, isPending } = useMutation({
    mutationKey: ["preview-program-start-date"],
    mutationFn: EnrollmentsClientService.createEnrollmentPreview,
  });

  const handleChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const handleConfirm = React.useCallback(() => {
    setSearchParams((searchParams) => {
      searchParams.delete("component");
      searchParams.set("preview", "true");
      return searchParams;
    });
    enroll(
      { programId, startDate: dayjs(startDate).format(ISO_DATE_FORMAT) },
      {
        onSuccess: () => {
          onClose({}, "backdropClick");
          setPreview(PreviewType.MOBILE);
        },
      },
    );
  }, [enroll, onClose, programId, setPreview, startDate]);

  return (
    <Dialog
      className={clsx(s.root, className)}
      PaperProps={{ className: s.paper }}
      onClose={onClose}
      {...other}
    >
      <Typography
        className={s.title}
        children="When would the program begin?"
      />
      <Typography
        className={s.subtitle}
        children="Add a program start time to preview the program."
      />
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleChange}
      />
      <LoadingActionButton
        variant="contained"
        fullWidth
        children="Preview program"
        disabled={!startDate}
        loading={isPending}
        onClick={handleConfirm}
      />
    </Dialog>
  );
}
