import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import GroupsService from "../../../services/GroupsService";
import { COACH_GROUPS_LIST_QUERY_KEY } from "../../../routes/coach/groups/CoachGroupsListRoute";
import {
  GroupStatus,
  PaginatedListOfGroupDto,
} from "@growth-machine-llc/stridist-api-client";
import { useQueryClient } from "@tanstack/react-query";
import { NAVBAR_GROUP_LIST_QUERY_KEY } from "../GroupNavItemList";

interface UpdateGroupArchivedMutationProps {
  disableToastAlerts?: boolean;
}

export const useUpdateGroupArchivedMutation = (
  options: UpdateGroupArchivedMutationProps = {},
) => {
  const queryClient = useQueryClient();

  return useOptimisticUpdateMutation({
    queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
    mutationFn: GroupsService.archiveGroup,
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfGroupDto, variables) => {
        const newData = PaginatedListOfGroupDto.fromJS(oldData);
        newData.items = newData?.items?.filter(
          (group) => group.id !== variables.id,
        );
        newData.totalCount = oldData?.totalCount - 1;
        return newData;
      },
      predicateFn: (queryKey: any, variables) =>
        predicateQueryKeyByParams(queryKey, {
          status: variables.archived
            ? GroupStatus.ACTIVE
            : GroupStatus.ARCHIVED,
        }),
    },
    ...options,
    options: {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: [NAVBAR_GROUP_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [
            COACH_GROUPS_LIST_QUERY_KEY,
            {
              status: variables.archived
                ? GroupStatus.ARCHIVED
                : GroupStatus.ACTIVE,
            },
          ],
          refetchType: "none",
        });
        queryClient.invalidateQueries({
          queryKey: [
            COACH_GROUPS_LIST_QUERY_KEY,
            {
              status: variables.archived
                ? GroupStatus.ACTIVE
                : GroupStatus.ARCHIVED,
            },
          ],
          refetchType: "all",
        });
      },
    },
  });
};
