import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { ActivityBriefDto } from "@growth-machine-llc/stridist-api-client";
import { getOrdinalSuffix } from "../../utils/date";

const alpha = "30";

type StyleProps = {
  completed: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    margin: theme.spacing(3.5, 0, 4),
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "left",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: "calc((100vw - 100%) / -2)",
      right: "calc((100vw - 100%) / -2)",
      height: "100%",
      zIndex: -1,
      backgroundColor: ({ completed }) =>
        completed
          ? `${theme.palette.activity.checkin}${alpha}`
          : `${theme.palette.text.secondary}${alpha}`,
    },
  },
  lozenge: {
    width: 128,
    height: 30,
    borderRadius: 25,
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ completed }) =>
      completed
        ? `${theme.palette.activity.checkin}`
        : `${theme.palette.text.secondary}`,
  },
  lozengeText: {
    fontSize: 11,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.common.white,
  },
  text: {
    fontSize: 18,
    color: theme.palette.common.black,
  },
}));

const getFormattedDate = (date) => {
  const day = date.date();
  const month = date.format("MMM");
  const ordinalSuffix = getOrdinalSuffix(day);
  return `${month} ${day}${ordinalSuffix}`;
};

function getText(activity: ActivityBriefDto): string {
  const clientName = activity?.displayName;

  if (activity.completedAt) {
    return `${clientName} completed on ${getFormattedDate(activity.completedAt)}`;
  }

  const future = activity.date.toDate() > new Date();

  return `${clientName} ${future ? "will receive" : "received"} on ${getFormattedDate(activity.date)}`;
}

export interface ActivityStatusProps extends BoxProps {
  activity: ActivityBriefDto;
}

export function ActivityStatus(props: ActivityStatusProps) {
  const { className, activity, ...other } = props;
  const s = useStyles({
    completed: !!activity.completedAt,
  });
  const text = getText(activity);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.lozenge}>
        <Typography
          className={s.lozengeText}
          variant="body1"
          component="span"
          children={activity.completedAt ? "Completed" : "Sent"}
        />
      </Box>
      <Typography
        className={s.text}
        variant="body1"
        component="span"
        children={text}
      />
    </Box>
  );
}
