import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  Button,
  ButtonProps,
  IconButton,
  TypographyProps,
  IconButtonProps,
  Menu,
  Tooltip,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Switch,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEditorRef } from "@udecode/plate-common";
import { ReactComponent as UndoIcon } from "../../icons/Undo.svg";

import { ReactComponent as BackIcon } from "../../icons/ArrowBackCircleOutline.svg";
import { MoreHoriz } from "@mui/icons-material";
import { ReactComponent as TextInputAreaIcon } from "../../icons/text-input-area.svg";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useMutation } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { UserInfoDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      paddingTop: theme.spacing(9.5),
    },
  },

  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1250, // TODO fix z-index schema
    height: theme.spacing(9.5),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.quote,
    backgroundColor: theme.palette.selected.light,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 3, 2, 1.5),
  },

  backButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },

  info: {
    overflow: "hidden",
  },

  header: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  headerMuted: {
    color: theme.palette.text.secondary,
  },

  status: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  buttons: {
    marginLeft: "auto",
    whiteSpace: "nowrap",

    "& > button": {
      borderRadius: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
    },
  },

  submit: {
    fontSize: 13,
    fontWeight: 700,
    padding: theme.spacing(1.125, 5),
  },

  button: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",

    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),

    backgroundColor: theme.palette.quote,
    color: theme.palette.common.black,
  },

  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  iconButton: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",

    backgroundColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),

    "& svg": {
      color: theme.palette.common.black,
    },
  },
  primaryText: {
    fontWeight: 500,
  },

  switchText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
  },

  switch: {
    margin: 0,
    marginLeft: theme.spacing(3),
  },
}));

export interface ComponentToolbarButton {
  icon: React.ReactNode;
  active?: boolean;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ButtonProps?: IconButtonProps;
}

export interface ComponentToolbarProps extends BoxProps {
  header: React.ReactNode;
  status: React.ReactNode;
  onBack?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttons?: ComponentToolbarButton[];

  SubmitButtonProps?: ButtonProps;
  BackButtonProps?: IconButtonProps;
  HeaderProps?: TypographyProps;
  StatusProps?: TypographyProps;
  headerMuted?: boolean;
  disabled?: boolean;
}

export function ComponentToolbar(props: ComponentToolbarProps) {
  const {
    className,
    SubmitButtonProps,
    BackButtonProps,
    HeaderProps,
    StatusProps,
    header,
    status,
    onBack,
    buttons,
    headerMuted,
    disabled,
    ...other
  } = props;
  const theme = useTheme();
  const s = useStyles();
  const user = useCurrentUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { mutate: updateUser } = useOptimisticUpdateMutation({
    queryKey: [CURRENT_USER_QUERY_KEY],
    mutationFn: async (enabled: boolean) =>
      FbUsersService.updateUser({ id: user.id, topToolBar: enabled }),
    optimisticUpdater: {
      updateFn: (oldData: UserInfoDto, enabled: boolean) => {
        const newData = UserInfoDto.fromJS({ ...oldData });
        newData.topToolbar = enabled;
        return newData;
      },
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTopToolbarToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateUser(checked);
    handleClose();
  };

  const editor = useEditorRef();
  const handleUndoClick = React.useCallback(() => editor.undo(), [editor]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <IconButton
        className={s.backButton}
        children={<BackIcon />}
        onClick={onBack}
        {...BackButtonProps}
        size="large"
      />
      <Box className={s.info}>
        <Typography
          className={clsx(s.header, {
            [s.headerMuted]: headerMuted,
          })}
          children={header}
          {...HeaderProps}
        />
        <Typography className={s.status} children={status} {...StatusProps} />
      </Box>
      <Box className={s.buttons}>
        <Button
          className={s.submit}
          variant="contained"
          color="primary"
          disabled={disabled}
          {...SubmitButtonProps}
        >
          Send Form
        </Button>
        <IconButton
          className={s.iconButton}
          children={<MoreHoriz />}
          aria-haspopup="true"
          size="large"
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& svg": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <MenuItem
            onClick={handleUndoClick}
            disabled={editor.history.undos.length === 0}
          >
            <ListItemIcon children={<UndoIcon />} />
            <ListItemText classes={{ primary: s.primaryText }} primary="Undo" />
          </MenuItem>
          <Tooltip
            arrow
            placement="left"
            title="Include a handy toolbar for quick access to components."
          >
            <MenuItem>
              <ListItemIcon children={<TextInputAreaIcon />} />
              <ListItemText
                classes={{ root: s.switchText, primary: s.primaryText }}
                primary="Top toolbar"
                secondary={
                  <Switch
                    className={s.switch}
                    value="fixed"
                    checked={user.topToolbar}
                    onChange={handleTopToolbarToggle}
                  />
                }
              />
            </MenuItem>
          </Tooltip>
        </Menu>

        {buttons &&
          buttons.map(({ icon, active, ...other }, index) => (
            <IconButton
              key={index}
              className={clsx(s.button, {
                [s.active]: active,
              })}
              disabled={disabled}
              {...other}
              size="large"
            >
              {icon}
            </IconButton>
          ))}
      </Box>
    </Box>
  );
}
