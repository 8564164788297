import { useInfiniteQuery } from "@tanstack/react-query";
import GroupPostsService from "../../services/GroupPostsService";
import React, { useMemo } from "react";
import { CursorPaginatedListOfGroupPostCommentDto } from "@growth-machine-llc/stridist-api-client";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";

export const GROUP_POST_COMMENTS_LIST_QUERY_KEY = "group-post-comments-list";

const GROUP_POST_COMMENTS_LIST_PAGE_SIZE = 5;

type UseGroupPostCommentsProps = {
  postId: number;
  enabled?: boolean;
};
export function useGroupPostComments({
  postId,
  enabled,
}: UseGroupPostCommentsProps) {
  const [fetchComments, setFetchComments] = React.useState(false);
  const {
    data: commentsData,
    isLoading: isLoadingInitial,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [GROUP_POST_COMMENTS_LIST_QUERY_KEY, { postId }],
    queryFn: ({ pageParam }) =>
      GroupPostsService.getGroupPostComments(
        postId,
        pageParam ? pageParam.toString() : null,
        GROUP_POST_COMMENTS_LIST_PAGE_SIZE,
      ),
    initialPageParam: null,
    enabled: enabled && fetchComments,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
  });

  const isLoading = isLoadingInitial || (isFetching && !isFetchingNextPage);
  const comments = commentsData?.pages.flatMap((page) => page.items) ?? [];

  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const observeElementRef = React.useCallback(
    (node: HTMLDivElement) => {
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && enabled) {
          setFetchComments(true);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [fetchNextPage, enabled],
  );

  const { data: pendingCommentsData } = useInfiniteQuery({
    queryKey: [GROUP_POST_COMMENTS_LIST_QUERY_KEY, { postId, pending: true }],
    queryFn: () =>
      Promise.resolve(new CursorPaginatedListOfGroupPostCommentDto()),
    initialPageParam: null,
    enabled: false,
    initialData: {
      pageParams: [],
      pages: [
        new CursorPaginatedListOfGroupPostCommentDto({
          items: [],
          nextCursor: null,
          totalPages: 1,
        }),
      ],
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const pendingComments = useMemo(
    () =>
      (pendingCommentsData?.pages.flatMap((page) => page.items) ?? []).filter(
        (c) => comments.some((comment) => comment.id === c.id) === false,
      ),
    [pendingCommentsData, comments],
  );

  return {
    comments,
    pendingComments,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    observeElementRef,
  };
}
