import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreVert } from "@mui/icons-material";
import {
  usePopupState,
  bindMenu,
  bindTrigger,
} from "material-ui-popup-state/hooks";

import { ReactComponent as Bin } from "../../icons/Bin.svg";
import { ConfirmRemoveFromProgramDialog } from "../dialog/ConfirmRemoveFromProgramDialog";
import { ChangeEnrollmentStartDateDialog } from "../dialog/ChangeEnrollmentStartDateDialog";
import { ChooseProgramEnrollDialog } from "../dialog/ChooseProgramEnrollDialog";
import { ListMenu } from "../menu/ListMenu";
import { colorSystem } from "../../theme";

import { ClientSmallProgramCard } from "./ClientSmallProgramCard";
import { EnrollmentsForCalendarDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
  },

  iconButton: {
    padding: theme.spacing(0.5),
    color: theme.palette.common.white,
    backgroundColor: `${colorSystem.black}B3`,
    borderRadius: theme.spacing(0.5),

    "&:hover": {
      backgroundColor: `${colorSystem.black}B3`,
    },

    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
    },

    "& .MuiIconButton-label": {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },

  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(0, 2),
  },

  textWrapper: {
    textAlign: "center",
  },

  bigText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
  },

  smallText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },
}));

export interface ClientCalendarProgramCardProps {
  enrollment: EnrollmentsForCalendarDto;
  enrollments: EnrollmentsForCalendarDto[];
  onRemove?: () => void;
  onChangeStartDate?: () => void;
  onChangeProgram?: () => void;
}

export function ClientCalendarProgramCard(
  props: ClientCalendarProgramCardProps,
) {
  const {
    enrollment,
    enrollments,
    onRemove,
    onChangeStartDate,
    onChangeProgram,
    ...other
  } = props;
  const s = useStyles();
  const { startFromNow, startDateShort, endDateShort } = enrollment;
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] =
    React.useState(false);
  const [changeStartDateDialogOpen, setChangeStartDateDialogOpen] =
    React.useState(false);
  const [chooseProgramDialogOpen, setChooseProgramDialogOpen] =
    React.useState(false);
  const menuState = usePopupState({
    variant: "popover",
    popupId: "moreMenu",
  });
  const menuTriggerProps = React.useMemo(
    () => bindTrigger(menuState),
    [menuState],
  );
  const menuProps = React.useMemo(() => bindMenu(menuState), [menuState]);

  const handleRemoveClick = React.useCallback(() => {
    setConfirmRemoveDialogOpen(true);
  }, []);

  const actions = React.useMemo(
    () => (
      <>
        <IconButton
          className={s.iconButton}
          children={<Bin />}
          onClick={handleRemoveClick}
          size="large"
        />
        <IconButton
          className={s.iconButton}
          children={<MoreVert />}
          {...menuTriggerProps}
          size="large"
        />
      </>
    ),
    [handleRemoveClick, menuTriggerProps, s.iconButton],
  );

  const content = React.useMemo(
    () => (
      <Box className={s.content}>
        <Box className={s.textWrapper}>
          <Typography className={s.bigText} children={startDateShort} />
          <Typography className={s.smallText} children="Start date" />
        </Box>
        <Box className={s.textWrapper}>
          <Typography className={s.bigText} children={endDateShort} />
          <Typography className={s.smallText} children="End date" />
        </Box>
      </Box>
    ),
    [endDateShort], // eslint-disable-line
  );

  const handleCloseConfirmRemoveDialog = React.useCallback(() => {
    setConfirmRemoveDialogOpen(false);
  }, []);

  const handleCloseChangeStartDateDialog = React.useCallback(() => {
    setChangeStartDateDialogOpen(false);
  }, []);

  const handleCloseChooseProgramDialog = React.useCallback(() => {
    setChooseProgramDialogOpen(false);
  }, []);

  const handleChangeStartDate = React.useCallback(() => {
    menuState.close();
    setChangeStartDateDialogOpen(true);
  }, [menuState]);

  const handleChangeProgram = React.useCallback(() => {
    menuState.close();
    setChooseProgramDialogOpen(true);
  }, [menuState]);

  const menuOptions = React.useMemo(
    () => [
      {
        label: "Change start date",
        onClick: handleChangeStartDate,
      },
      {
        label: "Change program",
        onClick: handleChangeProgram,
      },
    ],
    [handleChangeProgram, handleChangeStartDate],
  );

  return (
    <>
      <ClientSmallProgramCard
        className={s.root}
        enrollment={enrollment}
        subtitle={`Starts ${startFromNow}`}
        actions={actions}
        content={content}
        {...other}
      />

      <ListMenu options={menuOptions} {...menuProps} />

      {confirmRemoveDialogOpen && (
        <ConfirmRemoveFromProgramDialog
          open={confirmRemoveDialogOpen}
          enrollment={enrollment}
          onClose={handleCloseConfirmRemoveDialog}
          onRemove={onRemove}
        />
      )}

      {changeStartDateDialogOpen && (
        <ChangeEnrollmentStartDateDialog
          open={changeStartDateDialogOpen}
          enrollment={enrollment}
          onClose={handleCloseChangeStartDateDialog}
          onSuccess={onChangeStartDate}
        />
      )}

      {chooseProgramDialogOpen && (
        <ChooseProgramEnrollDialog
          open={chooseProgramDialogOpen}
          enrollments={enrollments}
          startDate={enrollment.startDate}
          deleteEnrollmentId={enrollment.id}
          onClose={handleCloseChooseProgramDialog}
          onSuccess={onChangeProgram}
        />
      )}
    </>
  );
}
