import React, { useContext } from "react";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { EditorAddExercise } from "../../../../hooks/useAddExercise";
import { Activity } from "../../../../components/activity/Activity";
import { useParams, useSearchParams } from "react-router-dom";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { DefaultLoader } from "../../../../components/loading/DefaultLoader";
import { useProgramComponentRoute } from "../../../../wrappers/router/common/ProgramsComponentRouteWrapper";

export const CLIENT_PROGRAM_COMPONENT_QUERY_KEY = "client-program-component";

export function ClientProgramsProgramComponentRoute() {
  const { program, component } = useParams();

  const [searchParams] = useSearchParams();
  const summary = searchParams.get("summary");
  const { username } = useCurrentUser();

  const {
    activity,
    isSecondaryLoading: isFetching,
    isPlaceholderData,
  } = useProgramComponentRoute();

  return program ? (
    <>
      <RefreshSlug
        slugInfo={[
          {
            slug: activity?.component?.slug,
            slugId: activity?.component?.slugId,
          },
          // TODO_API_V2_REFRESH_SLUG: Consider retrieving slug from program.
          // Currently not urgent since user can not trigger slug change on this page.
          // {
          //   slug: program?.slug,
          //   slugId: program?.slugId,
          // },
        ]}
      />
      <EditorAddExercise.Provider value={true}>
        <Activity
          activity={activity}
          shouldFetchSummary={summary === "yes"}
          program={program}
          client={{ username: username }}
          isLoadingActivity={isFetching}
          isPlaceholderData={isPlaceholderData}
        />
      </EditorAddExercise.Provider>
    </>
  ) : (
    <DefaultLoader fillScreen />
  );
}
