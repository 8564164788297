import React, { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ClientContext } from "../../../../hooks/useClient";
import { ClientContext as AlternativeClientContext } from "../../../../contexts/ClientContext";
import { CoachClientCalendarScreen } from "../../../../components/screen/CoachClientCalendarScreen";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import EnrollmentsClientService from "../../../../services/EnrollmentsClientService";

export const CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY = "CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY";

export function CoachClientCalendarRoute() {
  const { username } = useParams();

  const { data: enrollmentsCalendarData, isLoading: enrollmentCalendarDataLoading } =
    useQuery({
      queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
      queryFn: () => EnrollmentsClientService.getClientEnrollmentsForCalendar(username),
    });

  return (
    <>
      <CoachClientCalendarScreen enrollments={enrollmentsCalendarData || []} isLoading={enrollmentCalendarDataLoading} />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Client Calendar",
          properties: {
            clientUsername: username,
          },
        }}
      />
    </>
  );
}
