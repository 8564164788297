import {
  MutationOptions,
  Query,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useGenericErrorHandler } from "./useGenericErrorHandler";
import { useToastAlert } from "../components/app/ToastAlert/ToastAlertProvider";

type OptimisticMutationFn<TData, TError, TVariables> = Pick<
  MutationOptions<TData, TError, TVariables>,
  "mutationFn"
>["mutationFn"];

type PredicateQueryFn<R> = (queryKey: QueryKey, context?: R) => boolean;

type UpdaterOptions<T, R> = {
  updateFn: (oldData: T, variables: R, tempId: number) => T;
  predicateFn?: PredicateQueryFn<R>;
};
type QueryTypeFilter = "all" | "active" | "inactive";

type InvalidateQueryOptions<R> = {
  enabled: boolean;
  predicateFn?: PredicateQueryFn<R>;
  refetchType?: QueryTypeFilter | "none";
};

export type OptimisticUpdateMutationOptions<TData, TError, TVariables, TCache> =
  {
    mutationFn: OptimisticMutationFn<TData, TError, TVariables>;
    queryKey: QueryKey;
    exactQueryKey?: boolean;
    optimisticUpdater: UpdaterOptions<TCache, TVariables>;
    successUpdater?: UpdaterOptions<TCache, TData>;
    invalidateQueryOptions?: InvalidateQueryOptions<TVariables>;
    disableToastAlerts?: boolean;
    successToastMessage?: string;
    options?: Omit<UseMutationOptions<TData, TError, TVariables>, "mutationFn">;
  };

// Generate a new unique temporary id for optimistic updates to prevent from cache update conflicts.
export const getOptimisticUpdateTempId = (): number => {
  const timestamp = Date.now();
  const randomOffset = Math.floor(Math.random() * 1000);
  return -(timestamp + randomOffset);
};

// Temporary id for optimistic updates must be negative.
export const isOptimisticUpdateTempId = (tempId: number) => tempId < 0;

export const useOptimisticUpdateMutation = <TData, TError, TVariables, TCache>({
  mutationFn,
  queryKey,
  exactQueryKey,
  optimisticUpdater,
  successUpdater,
  invalidateQueryOptions = { enabled: false },
  disableToastAlerts = false,
  successToastMessage = null,
  options,
}: OptimisticUpdateMutationOptions<
  TData,
  TError,
  TVariables,
  TCache
>): UseMutationResult<TData, TError, TVariables, TCache> => {
  const { closeToastAlert, showToastAlert, updateToastAlert } = useToastAlert();

  const showLoadingToast = () => {
    return showToastAlert("loading", {
      message: "Applying...",
    });
  };

  const updateToastAlertToSuccess = (id: string, message?: string) => {
    updateToastAlert(id, {
      message: message || "Done!",
      severity: "success",
    });
  };

  const queryClient = useQueryClient();
  const handleGenericError = useGenericErrorHandler({
    disableDefaultSnackbar: disableToastAlerts,
  });

  return useMutation({
    mutationFn,
    ...options,
    onMutate: async (variables) => {
      let toastId = undefined;

      if (!disableToastAlerts) {
        toastId = showLoadingToast();
      }

      await queryClient.cancelQueries({ queryKey, exact: exactQueryKey });

      const { updateFn, predicateFn } = optimisticUpdater;

      const filters = {
        queryKey,
        exact: exactQueryKey,
        predicate: ({ queryKey }: Query) =>
          predicateFn?.(queryKey, variables) ?? true,
      };

      const previousDataArr = queryClient.getQueriesData<TCache>(filters);
      const tempId = getOptimisticUpdateTempId();

      queryClient.setQueriesData<TCache>(filters, (oldData) =>
        updateFn(oldData, variables, tempId),
      );

      options?.onMutate?.(variables);

      return { previousDataArr, tempId, toastId };
    },
    onError: (error, variables, context: any) => {
      context?.previousDataArr?.forEach((pair) => {
        const key = pair[0] as QueryKey;
        const data = pair[1] as TCache;
        queryClient.setQueryData(key, data);
      });

      if (options?.onError) {
        // Close loading toast if custom error handler is provided.
        // Otherwise, it will be updated in the generic error handler.
        context?.toastId && closeToastAlert(context.toastId);

        options.onError(error, variables, context);
      } else {
        handleGenericError(error, variables, context, context?.toastId);
      }
    },
    onSuccess: (response, variables, context) => {
      if (context?.toastId) {
        updateToastAlertToSuccess(context.toastId, successToastMessage);
      }

      if (successUpdater?.updateFn) {
        const { updateFn, predicateFn } = successUpdater;

        queryClient.setQueriesData<TCache>(
          {
            queryKey,
            exact: exactQueryKey,
            predicate: ({ queryKey }: Query) =>
              predicateFn?.(queryKey, response) ?? true,
          },
          (oldData) => updateFn(oldData, response, context.tempId),
        );
      }

      if (invalidateQueryOptions?.enabled) {
        queryClient.invalidateQueries({
          queryKey,
          exact: exactQueryKey,
          predicate: ({ queryKey }: Query) =>
            invalidateQueryOptions.predicateFn?.(queryKey, variables) ?? true,
          refetchType: invalidateQueryOptions.refetchType,
        });
      }

      options?.onSuccess?.(response, variables, context);
    },
  });
};

export const predicateQueryKeyByParams = (
  queryKey: QueryKey,
  params: object | ((object: any) => boolean),
) => {
  try {
    return (
      typeof queryKey[1] === "object" &&
      (typeof params === "function"
        ? params(queryKey[1])
        : Object.entries(params).every(([key, value]) =>
            compareValues(queryKey[1][key], value),
          ))
    );
  } catch (error) {
    return false;
  }
};

export const predicateTwoPartQueryKey = (
  queryKey: QueryKey,
  identifier: string,
  params: object | ((object: any) => boolean),
) => {
  try {
    return (
      queryKey[0] === identifier && predicateQueryKeyByParams(queryKey, params)
    );
  } catch (error) {
    return false;
  }
};

const compareValues = (a: any, b: any) => {
  if (!a && !b) return true;
  else if (a === b) return true;
  else if (String(a) === String(b)) return true;

  return false;
};
