import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { extractSlugId } from "../../../../../utils/slug";

export const setComponentSlugAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<
    {
      id: number;
      slug: string;
    }[]
  >,
) => {
  action.payload.forEach(({ id, slug }) => {
    const component = state.value.components[id];
    if (!component) return;
    const slugId = extractSlugId(slug);

    state.value.components[id] = {
      ...component,
      slug,
      slugId,
    };
  });
};
