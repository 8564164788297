import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { ButtonProps } from "./types";
import LoadingActionButton from "../button/LoadingActionButton";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(5.5),
      marginBottom: theme.spacing(5.5),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(7.5),
      },
    },
    button: {
      cursor: "pointer",
      width: 346,
      height: 56,
      borderRadius: 4,
      color: colorSystem.white,
      backgroundColor: colorSystem.black,
      borderColor: colorSystem.black,
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "20px",
    },
  };
});

const Button = (props: ButtonProps) => {
  const s = useStyles();
  const { nextStep, activeDot, isDisabled, isLoading } = props;
  return (
    <div className={clsx(s.container)}>
      <LoadingActionButton
        className={clsx(s.button)}
        onClick={nextStep}
        disabled={!isDisabled}
        loading={isLoading}
      >
        {activeDot ? "Start workout" : "Next"}
      </LoadingActionButton>
    </div>
  );
};

export default Button;
