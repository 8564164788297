import clsx from "clsx";
import React, { useState } from "react";
import {
  DialogProps,
  Box,
  Avatar,
  TextField,
  Button,
  TextFieldProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";

import {
  GROUP_NAME_MAX_LENGTH,
  useUpdateGroupMutation,
} from "./mutations/UpdateGroup";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";
import { useCreateGroupMutation } from "./mutations/CreateGroup";
import { COACH_GROUPS_LIST_QUERY_KEY } from "../../routes/coach/groups/CoachGroupsListRoute";

const COMMON_SPACING = 7;

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
  },

  avatar: {
    width: theme.spacing(COMMON_SPACING),
    height: theme.spacing(COMMON_SPACING),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },

  input: {
    height: theme.spacing(COMMON_SPACING),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(3),
  },
}));

export interface GroupNameDialogProps extends DialogProps {
  group?: GroupDto2;
  onCreated?: (group: GroupDto2) => void;
}

export function GroupNameDialog(props: GroupNameDialogProps) {
  const { className, onClose, group, onCreated, ...other } = props;
  const s = useStyles();
  const [name, setName] = React.useState(group?.name);
  const [error, setError] = useState<string>("");
  const { updateListMutation } = useUpdateGroupMutation({
    currentSlug: group?.slug || "",
  });
  const { mutate: updateGroup, isPending: updateInFlight } = updateListMutation;

  const { mutate: createGroup, isPending: createInFlight } =
    useCreateGroupMutation();

  const inFlight = updateInFlight || createInFlight;

  const handleNameChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      if (event.target.value.length > GROUP_NAME_MAX_LENGTH) {
        setError("Max length of group name is 200 characters");
      } else {
        setError("");
      }
      setName(event.target.value);
    },
    [],
  );

  const resetState = React.useCallback(() => {
    setName("");
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleCreate = React.useCallback(() => {
    createGroup(
      { name },
      {
        onSuccess(_, upsertGroup) {
          if (onCreated) {
            onCreated(GroupDto2.fromJS(upsertGroup));
          }
        },
      },
    );
    handleClose();
  }, [handleClose, name, onCreated]);

  const handleUpdate = React.useCallback(() => {
    updateGroup({ ...group, id: group?.id, name });
    handleClose();
  }, [group?.id, handleClose, name]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`${group?.id ? "Rename" : "Create"} group`}
      closeDisabled={inFlight}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <Box className={s.wrapper}>
        <Avatar className={s.avatar} variant="square">
          {name?.length ? name[0].toUpperCase() : "G"}
        </Avatar>
        <TextField
          className={s.input}
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleNameChange}
          label="Group name"
          helperText={error}
          error={!!error}
        />
      </Box>
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={`${group?.id ? "Save" : "Create group"}`}
        onClick={group?.id ? handleUpdate : handleCreate}
        disabled={!name || inFlight || !!error}
      />
    </BaseDialog>
  );
}
