import clsx from "clsx";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React from "react";
import {
  ChevronRight as ChevronRightIcon,
  FolderOpen as FolderOpenIcon,
} from "@mui/icons-material";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ProgramStatus } from "../../constants";
import { ReactComponent as RestoreIcon } from "../../icons/ArchiveOutline.svg";
import { Menu, MenuProps } from "../menu/Menu";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/ArchiveOutline.svg";

import { CoachProgramFoldersMenu } from "./CoachProgramFoldersMenu";
import { useArchiveProgramMutation } from "./mutations/ArchiveProgram";
import { useRestoreProgramMutation } from "./mutations/RestoreProgram";
import { useDuplicateProgramMutation } from "./mutations/DuplicateProgram";
import { ProgramDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },

  primaryText: {
    fontWeight: 500,
  },

  secondaryAction: {
    display: "flex",
    alignItems: "center",
  },
}));

export interface CoachProgramMenuProps extends MenuProps {
  program: ProgramDto;
  onFolderUpdated?: () => void;
}

export function CoachProgramMenu(props: CoachProgramMenuProps) {
  const s = useStyles();
  const { program, onClose, className, onFolderUpdated, ...other } = props;
  const { mutate: archiveProgram } = useArchiveProgramMutation();
  const { mutate: restoreProgram } = useRestoreProgramMutation();
  const { mutate: duplicateProgram } = useDuplicateProgramMutation({
    status: program?.status,
  });
  const { id: programId } = program;

  const handleArchiveProgram = React.useCallback(
    (event) => {
      archiveProgram({
        programId,
      });

      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [archiveProgram, programId, onClose],
  );

  const handleRestoreProgram = React.useCallback(
    (event) => {
      restoreProgram({
        programId,
      });

      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [restoreProgram, programId, onClose],
  );

  const handleDuplicateProgram = React.useCallback(
    (event) => {
      duplicateProgram({
        programId,
      });
      onClose(event, "backdropClick");
    },
    [duplicateProgram, programId, onClose],
  );

  const foldersMenuState = usePopupState({
    variant: "popover",
    popupId: "program-set-folder",
  });

  return (
    <>
      <Menu
        className={clsx(s.root, className)}
        MenuListProps={{ dense: true }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={onClose}
        {...other}
      >
        {program.status === ProgramStatus.ARCHIVED ? (
          <MenuItem onClick={handleRestoreProgram} dense>
            <ListItemIcon children={<RestoreIcon />} />
            <ListItemText
              primary="Restore"
              classes={{ primary: s.primaryText }}
            />
          </MenuItem>
        ) : (
          [
            <MenuItem key="duplicate" onClick={handleDuplicateProgram} dense>
              <ListItemIcon children={<DuplicateIcon />} />
              <ListItemText
                primary="Duplicate"
                classes={{ primary: s.primaryText }}
              />
            </MenuItem>,

            <MenuItem key="archive" onClick={handleArchiveProgram} dense>
              <ListItemIcon children={<ArchiveIcon />} />
              <ListItemText
                primary="Archive"
                classes={{ primary: s.primaryText }}
              />
            </MenuItem>,

            <MenuItem key="set-folder" {...bindTrigger(foldersMenuState)} dense>
              <ListItemIcon children={<FolderOpenIcon />} />
              <ListItemText
                primary="Set folder"
                classes={{ primary: s.primaryText }}
              />
              <ListItemSecondaryAction className={s.secondaryAction}>
                <ChevronRightIcon />
              </ListItemSecondaryAction>
            </MenuItem>,
          ]
        )}
      </Menu>
      <CoachProgramFoldersMenu
        {...bindPopover(foldersMenuState)}
        program={program}
        onUpdated={() => {
          onClose({}, "backdropClick");
          onFolderUpdated?.();
        }}
      />
    </>
  );
}
