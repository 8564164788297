import React from "react";
import { useMutation } from "@tanstack/react-query";

import { AssetType } from "../constants";
import { uploadFile } from "../utils/file";
import { ErrorWithCode } from "../utils/errors";

import StorageService from "../services/StorageService";

const getUploadedUrl = (signedUrl: string): string => {
  const url = new URL(`https:/${signedUrl.substr(signedUrl.indexOf("/", 8))}`);
  url.search = "";

  return String(url);
};

export interface UploadFileParams {
  id: number;
  getAssetType: (file: File) => AssetType;
  refType?: string;
  maxFileSize?: number;
  onProgress?: (event: ProgressEvent<EventTarget>) => void;
  onUpload?: (params: UploadedFile) => void;
  onError?: (error?: any) => void;
}

export interface UploadedFile {
  file: File;
  url: string;
  assetType: AssetType;
}

type UploadFileFn = (file: File) => Promise<UploadedFile>;
type UploadManyFilesFn = (files: File[]) => Promise<UploadedFile[]>;

export function useUploadFile({
  id,
  getAssetType,
  refType = "User",
  maxFileSize,
  onProgress,
  onUpload,
  onError,
}: UploadFileParams) {
  const { mutate: createUploadUrl, isPending: inFlight } = useMutation({
    mutationKey: ["create-signed-url"],
    mutationFn: StorageService.createSignedUrl,
  });
  const [isUploading, setIsUploading] = React.useState(false);

  const upload: UploadFileFn = React.useCallback(
    (data) => {
      const file = Array.isArray(data) ? data[0] : data;
      const assetType = getAssetType(file);

      if (!assetType) {
        return Promise.reject(new Error("Unsupported file type"));
      }

      if (maxFileSize && file.size > maxFileSize) {
        return Promise.reject(
          new ErrorWithCode(
            "upload/max-size",
            `Maximum file size is ${Math.round(maxFileSize / 1024 / 1024)} MB.`,
          ),
        );
      }

      setIsUploading(true);

      return new Promise((resolve, reject) =>
        createUploadUrl(
          {
            refId: id,
            refType,
            assetType: assetType,
            file: file.name,
          },
          {
            onError: (error, variables, context) => {
              console.error(error);
              setIsUploading(false);
              onError?.(error);
            },
            onSuccess: ({ url }) => {
              uploadFile(url, file, onProgress)
                .then(() => {
                  const uploadUrl = getUploadedUrl(url);
                  const uploadedFile: UploadedFile = {
                    url: uploadUrl,
                    file,
                    assetType,
                  };

                  resolve(uploadedFile);
                  onUpload?.(uploadedFile);
                })
                .catch((err) => {
                  reject(err);
                  console.error(err);
                  onError?.(err);
                })
                .finally(() => {
                  setIsUploading(false);
                });
            },
          },
        ),
      );
    },
    [getAssetType, maxFileSize, createUploadUrl, id],
  );

  const uploadMany: UploadManyFilesFn = React.useCallback(
    async (files) => {
      const errors = [];

      const uploaded = (
        await Promise.all(
          files.map((file) =>
            upload(file).catch((error) => {
              errors.push(error);
            }),
          ),
        )
      ).filter(Boolean);

      if (errors.length && !uploaded.length) {
        throw errors[0];
      }

      return uploaded as UploadedFile[];
    },
    [upload],
  );

  return [upload, uploadMany, isUploading] as const;
}
