import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, TextField, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RefreshSlug } from "../routes/RefreshSlug";
import { GroupMembersList } from "../group-members/GroupMembersList";
import { useInputChange } from "../../hooks/useInputChange";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },

  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 3, 0, 3),
    boxShadow: theme.shadows[5],
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },

  search: {
    marginBottom: theme.spacing(2),

    "& fieldset": {
      borderColor: theme.palette.text.secondary,
      borderRadius: 0,
    },

    "& input": {
      fontSize: 16,
    },
  },
}));

export interface GroupMembersScreenProps
  extends Omit<ContainerProps, "children"> {
  group: GroupDto2;
}

export function GroupMembersScreen(props: GroupMembersScreenProps) {
  const { className, group, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const [query, setQuery] = React.useState("");

  return (
    <Container className={clsx(s.root, className)} maxWidth="lg" {...other}>
      <RefreshSlug
        slugInfo={{
          slug: group?.slug,
          slugId: group?.slugId,
        }}
      />

      <Box className={s.card}>
        <TextField
          className={s.search}
          value={query}
          onChange={useInputChange(setQuery)}
          placeholder={`Filter ${
            user.role === UserRole.COACH ? "clients" : "members"
          }`}
          variant="outlined"
          fullWidth
        />
        <GroupMembersList group={group} query={query} />
      </Box>
    </Container>
  );
}
