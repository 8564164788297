import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ComponentStatus, ComponentType } from "../../constants";
import { CurriculumComponent } from "../../redux/types";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
  },
}));

export interface ComponentPublishButtonProps extends ButtonProps {
  componentData: CurriculumComponent;
  empty: boolean;
  dirty: boolean;
  savingStateMessage?: string;
}

export function ComponentPublishButton(props: ComponentPublishButtonProps) {
  const {
    className,
    componentData,
    empty,
    dirty,
    savingStateMessage = "",
    ...other
  } = props;
  const s = useStyles();
  const theme = useTheme();
  const { status, draftExist, type } = componentData;
  const draft = React.useMemo(() => status === ComponentStatus.DRAFT, [status]);
  const canPublishEmpty = [ComponentType.HABIT, ComponentType.MESSAGE].includes(
    type as ComponentType,
  );
  const disabled = draft ? empty && !canPublishEmpty : !draftExist || dirty;

  return (
    <Button
      className={clsx(s.root, className)}
      variant="contained"
      color="primary"
      sx={{ backgroundColor: theme.palette.primary.main }}
      children={draft ? "Publish" : "Save & Publish"}
      disabled={disabled || dirty || savingStateMessage === "Saving..."}
      {...other}
    />
  );
}
