import React from "react";
import { Box, SxProps, useTheme } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { useState } from "react";
import { DRAG_CELL_WIDTH } from "../../ProgramSpreadsheetGrid";
import { Plus } from "lucide-react";
import { bindMenu, PopupState } from "material-ui-popup-state/hooks";
import { AddComponentMenu } from "../../../program/AddComponentMenu";
import { ComponentType, RowType } from "../../../../constants";
import { Filters } from "../../../program/ProgramDetailsFilters";
import { mapValues } from "lodash";

export const DEFAULT_ADD_MENU_DISABLED_ITEMS: Filters = {
  [ComponentType.LESSON]: false,
  [ComponentType.HABIT]: false,
  [ComponentType.CHECKIN]: false,
  [ComponentType.WORKOUT]: false,
  [RowType.WORKOUT_SECTION]: true,
  [RowType.EXERCISE]: true,
  [ComponentType.MESSAGE]: false,
  archived: false,
};

const getAddMenuDisabledItems = (rowType: RowType, filters: Filters) => {
  switch (rowType) {
    case RowType.EXERCISE:
    case RowType.WORKOUT_SECTION:
      return {
        ...filters,
        [RowType.WORKOUT_SECTION]: false,
        [RowType.EXERCISE]: false,
      };
    case RowType.WORKOUT:
      return {
        ...filters,
        [RowType.WORKOUT_SECTION]: false,
        [RowType.EXERCISE]: true,
      };

    default:
      return {
        ...filters,
        [RowType.WORKOUT_SECTION]: true,
        [RowType.EXERCISE]: true,
      };
  }
};

interface IInsertItemComponentProps {
  weekId: number;
  componentId: number;
  workoutSectionId: number;
  exerciseId: number;
  dayOfWeek: number;
  firstLine?: boolean;
  iconsHoverStyling?: SxProps;
  menuState: PopupState;
  firstLineMenuState: PopupState;
  currentRowType: RowType;
  filters: Filters;
}
export const InsertItemComponent = ({
  weekId,
  componentId,
  workoutSectionId,
  exerciseId,
  dayOfWeek,
  firstLine,
  iconsHoverStyling,
  menuState,
  firstLineMenuState,
  currentRowType,
  filters,
}: IInsertItemComponentProps) => {
  const theme = useTheme();
  const [showAddLine, setShowAddLine] = useState(false);

  return (
    <>
      <Box
        className="opacity-0 group-hover:opacity-100"
        sx={{
          position: "absolute",
          p: 0.3,
          borderRadius: 2,
          bottom: firstLine ? 30 : -12,
          zIndex: theme.zIndex.appBar,
          cursor: "pointer",

          ...iconsHoverStyling,
        }}
        onMouseEnter={() => setShowAddLine(true)}
        onMouseLeave={() => setShowAddLine(false)}
        {...bindTrigger(firstLine ? firstLineMenuState : menuState)}
      >
        <Plus size={16} strokeWidth={3} />
      </Box>
      <Box
        className="!transition-opacity !duration-500"
        sx={{
          opacity: showAddLine ? 1 : 0,
          position: "absolute",
          bottom: firstLine ? "unset" : -1,
          top: firstLine ? -1 : "unset",
          zIndex: theme.zIndex.mobileStepper,
          left: DRAG_CELL_WIDTH,
          width: "100vw",
          borderBottom: "2px dashed !important",
        }}
      />

      {menuState.isOpen && (
        <AddComponentMenu
          withInitData
          weekId={weekId}
          componentId={componentId}
          workoutSectionId={workoutSectionId}
          exerciseId={exerciseId}
          dayOfWeek={dayOfWeek}
          enableDetailedWorkout
          disabledItems={getAddMenuDisabledItems(
            currentRowType,
            mapValues(filters, (value) => !value) as Filters,
          )}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          {...bindMenu(menuState)}
        />
      )}

      {/* separate insert menu for zero row */}
      {firstLineMenuState.isOpen && (
        <AddComponentMenu
          withInitData
          weekId={weekId}
          workoutSectionId={workoutSectionId}
          componentId={componentId}
          dayOfWeek={dayOfWeek}
          enableDetailedWorkout
          disabledItems={{
            ...(mapValues(filters, (value) => !value) as Filters),
            [RowType.WORKOUT_SECTION]: true,
            [RowType.EXERCISE]: true,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          {...bindMenu(firstLineMenuState)}
        />
      )}
    </>
  );
};
