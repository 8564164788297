import React from "react";
import { CurrentUserProvider } from "../../components/routes/CurrentUserProvider";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { UserInfo } from "../../hooks/useCurrentUser";
import useAuth from "../../hooks/auth/useAuth";
import FbUsersService from "../../services/FbUsersService";
import {
  ACTIVITY_ROUTE,
  CLIENT_PORTAL_ROUTE,
  CLIENT_PROFILE_ROUTE,
  GROUPS_ROUTE,
  HOME_ROUTE,
  MESSAGES_ROUTE,
  PROGRAMS_ROUTE,
} from "../../routes/routes";
import { Outlet, useLocation, matchRoutes } from "react-router-dom";
import { WorkspaceDto } from "@growth-machine-llc/stridist-api-client";
import { colorSystem } from "../../theme";
import WorkspacesService from "../../services/WorkspacesService";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { brandedAppWorkspaceKey } from "../../utils/mobile";
import { DefaultLoader } from "../../components/loading/DefaultLoader";

interface CurrentUserWrapperProps {
  authorized: boolean;
  children?: React.JSX.Element;
}

export const CURRENT_USER_QUERY_KEY = "current-user";

const ignoreWorkspaceRoutes = [
  HOME_ROUTE,
  PROGRAMS_ROUTE,
  GROUPS_ROUTE,
  ACTIVITY_ROUTE,
  MESSAGES_ROUTE,
  CLIENT_PROFILE_ROUTE,
];

// TODO_API_V2: Consider about default workspace
const defaultWorkspace = new WorkspaceDto({
  primaryColor: colorSystem.primary,
  slug: "",
  name: "",
  logoUrl: "",
});

interface CurrentUserWrapperProps extends React.PropsWithChildren<{}> {
  authorized: boolean;
}

export const CurrentUserWrapper = ({
  authorized,
  children,
}: CurrentUserWrapperProps) => {
  const location = useLocation();
  const matches = matchRoutes([{ path: CLIENT_PORTAL_ROUTE }], location);
  const { isBrandedApp } = useCurrentBrand();
  const workspaceSlug = isBrandedApp
    ? brandedAppWorkspaceKey
    : matches && matches[0]?.params?.workspace;

  // To distinguish :workspace param from just one word route
  // we forced to add all of them to`ignoreWorkspaceRoutes`.
  // This is needed to retrieve slug from coach workspace URL when user
  // is not logged and the only source of custom theme and logo is data in URL
  const isWorkspace = isBrandedApp
    ? isBrandedApp
    : !ignoreWorkspaceRoutes.includes("/" + workspaceSlug);

  const { userId } = useAuth();

  const { data: user, isLoading: currentUserLoading } = useQuery({
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: FbUsersService.getCurrentUser,
    // NOTE: `get-current-user` enabled only for authorized users to get user data and workspace
    enabled: authorized,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  });

  const { data: workspaceData, isLoading: currentWorkspaceLoading } = useQuery({
    queryKey: ["get-workspace"],
    queryFn: () => WorkspacesService.getWorkspace(workspaceSlug),
    // NOTE: `get-workspace` query enabled only for unauthorized users to get custom workspace
    enabled: !authorized && isWorkspace && !!workspaceSlug,
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
    select: (data) => data?.workspace,
  });

  const workspace = workspaceData || defaultWorkspace;

  const myWorkspace = user?.workspace || workspace;

  return (
    <CurrentUserProvider me={user} workspace={myWorkspace}>
      {currentUserLoading || currentWorkspaceLoading ? (
        <DefaultLoader fillScreen />
      ) : (
        children || <Outlet />
      )}
    </CurrentUserProvider>
  );
};
