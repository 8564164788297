import React from "react";
import { BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { ActivityType } from "../../constants";

import {
  AuthorDto,
  NotificationActivityDto,
  NotificationClientFormDto,
  NotificationComponentDto,
} from "@growth-machine-llc/stridist-api-client";

const DOT_SIZE = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    height: 120,
    width: "100%",
    position: "relative",

    "&:hover": {
      backgroundColor: `${colorSystem.secondaryGray}66`,
      cursor: "pointer",

      "& $close": {
        display: "block",
      },
    },

    "&::after": {
      display: "block",
      content: "''",
      width: DOT_SIZE,
      height: DOT_SIZE,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      position: "absolute",
      top: `calc(50% - ${DOT_SIZE / 2}px)`,
      right: theme.spacing(3),
    },

    "&$read": {
      "&::after": {
        display: "none",
      },
    },
  },

  close: {
    display: "none",
    position: "absolute",
    top: 5,
    right: 5,
    padding: theme.spacing(0.95, 0),
  },

  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },

  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    paddingRight: theme.spacing(2),
    overflow: "hidden",
  },

  title: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  subtitle: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },

  text: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "17px",
    color: theme.palette.common.black,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  bold: {
    fontWeight: 700,
  },

  closeIcon: {
    height: 20,
  },

  read: {},

  inFlight: {
    opacity: 0.7,
  },

  deleted: {
    display: "none!important",
  },
}));

export interface NotificationBoxProps {
  clientForm: NotificationClientFormDto;
  author: AuthorDto;
  activity: NotificationActivityDto;
  component: NotificationComponentDto;
  message: string;
  title: string;
}

export const NotificationBoxTitle = (props: NotificationBoxProps) => {
  const { clientForm, author, activity, title, component, message } = props;
  const s = useStyles();

  return (
    <Typography className={s.title}>
      {clientForm ? (
        <>
          <span className={s.bold}>{author.displayName}</span> sent you{" "}
          <span className={s.bold}>{clientForm.name}</span>
        </>
      ) : (
        <>
          <span className={s.bold}>{author.displayName}</span>
          {activity ? " left feedback" : " left comment"}
          {activity?.type === ActivityType.PROGRAM_COMPONENT ? (
            <>
              {" in "}
              <span className={s.bold}>{component?.title}</span>
            </>
          ) : activity?.type === ActivityType.MEAL_LOGGING ? (
            <>
              {" to "}
              <span className={s.bold}>meal log</span>
            </>
          ) : activity?.type === ActivityType.CLIENT_FORM ? (
            <>
              {" to "}
              <span className={s.bold}>form</span>
            </>
          ) : (
            <>
              {" in "}
              <span className={s.bold}>
                {message ? "Messages" : "Group"} {title && title}
              </span>
            </>
          )}
        </>
      )}
    </Typography>
  );
};
