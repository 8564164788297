import React from "react";
import { sortBy } from "lodash";

import { ComponentStatus, ComponentRepeat, ComponentType } from "../constants";
import { Filters } from "../components/program/ProgramDetailsFilters";
import {
  componentComparator,
  canScheduleComponentAtWeek,
} from "../components/program-calendar/utils";
import { useDeepCompareMemoize } from "../utils/memo";

export type ProgramWeekSchedule = Array<{
  weeks: number[];
  days: boolean[];
  component: any;
}>;

export type ScheduleUpdate = {
  id: number;
  weekId: number;
  days: boolean[];
  originDay: number;
  originWeek: number;
};

export function useProgramSchedule(
  program: any,
  filters: Filters,
  scheduleUpdate?: ScheduleUpdate,
) {
  const memoWeeks = useDeepCompareMemoize(program.weeks);

  const components = React.useMemo(() => {
    const acc = [];

    for (const week of program.weeks) {
      const _components = week.components
        .filter(Boolean)
        .filter(({ type }) => !filters || filters[type])
        .filter(
          ({ status }) =>
            status !== ComponentStatus.ARCHIVED ||
            (filters && filters.archived),
        );
      const components = sortBy(_components, ({ id }) => {
        const index = week.positions.indexOf(String(id));

        return index === -1 ? Date.now() : index;
      });

      for (const component of components) {
        if (!acc.some((it) => it.id === component.id)) {
          acc.push(component);
        }
      }
    }

    return sortBy(acc, componentComparator);
  }, [filters, memoWeeks]);

  const schedule: ProgramWeekSchedule = React.useMemo(
    () =>
      components.map((component) => {
        const { type, duration, repeat } = component;
        const update = scheduleUpdate?.id === component.id && scheduleUpdate;
        const weekId = update?.weekId || component.weekId;
        const days = update?.days || (JSON.parse(component.days) as boolean[]);
        const startingWeek = program.weeks.find(({ id }) => id === weekId);
        const startingWeekNumber = startingWeek ? startingWeek.weekNumber : 1;
        const weeks = program.weeks
          .map(({ weekNumber }) => weekNumber)
          .filter((weekNumber) =>
            canScheduleComponentAtWeek(
              startingWeekNumber,
              weekNumber,
              type as ComponentType,
              repeat as ComponentRepeat,
              duration,
            ),
          );

        return {
          weeks,
          days,
          component,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [components, memoWeeks, scheduleUpdate],
  );

  return [components, schedule] as const;
}
