import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { Menu } from "../menu/Menu";
import { ReactComponent as AddIcon } from "../../icons/ImageFileAdd.svg";
import { ReactComponent as UploadIcon } from "../../icons/NavigationUpCircle.svg";
import { ReactComponent as EmbedIcon } from "../../icons/PlaylistUpload.svg";

import { WorkoutExerciseImage } from "../workout/types";
import { EmbedUrlDialog } from "../dialog/EmbedUrlDialog";
import { useEditorProgram } from "../new-editor/hooks";
import {
  uploadFile,
  getMimeTypes,
  humanReadableFileSize,
} from "../../utils/file";
import { useNativeDropzone } from "../../utils/device";
import {
  AssetType,
  workoutMaxUploadFileSize,
  SOMETHING_WENT_WRONG,
} from "../../constants";
import { useTranscodeVideo } from "../../hooks/useTranscodeVideo";
import { isVideoUrl } from "../../utils/component";
import { ElementType } from "../editor/types/elements";
import { useUploadFile } from "../../hooks/useUploadFile";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",

    color: theme.palette.text.secondary,
    cursor: "pointer",

    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(1, 1, 1, 3),
  },

  text: {
    margin: theme.spacing(0, 0, 0, 1),
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },

  menu: {
    zIndex: "99999 !important" as any,
  },

  dropzone: {
    width: "100%",
  },

  addIcon: {
    width: 30,
  },
}));

export interface WorkoutUploadMediaProps extends BoxProps {
  onUpload?: (media?: WorkoutExerciseImage[] | WorkoutExerciseImage) => void;
}

export function WorkoutUploadMedia(props: WorkoutUploadMediaProps) {
  const { className, onUpload, ...other } = props;
  const s = useStyles();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "workout-add-media",
  });

  const { programId } = useEditorProgram();
  const [embed, setEmbed] = React.useState(false);
  const [uploadFile, _, isUploading] = useUploadFile({
    id: programId,
    refType: "Program",
    getAssetType: () => AssetType.WORKOUT_MEDIA,
  });

  const [transcodeVideo, isTranscoding] = useTranscodeVideo();
  const { showToastAlert } = useToastAlert();

  const handleUploadClick = React.useCallback(() => {
    menuState.close();
  }, [menuState]);

  const handleEmbedClick = React.useCallback(() => {
    menuState.close();
    setEmbed(true);
  }, [menuState]);

  const handleCloseEmbed = React.useCallback(() => {
    setEmbed(false);
  }, []);

  const handleUpload = React.useCallback(
    (media: WorkoutExerciseImage[] | WorkoutExerciseImage) => {
      if (onUpload) {
        onUpload(media);
      }
    },
    [onUpload],
  );

  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: (files, errors) => {
      menuState.close();

      if (errors.length) {
        showToastAlert("error", {
          message: SOMETHING_WENT_WRONG,
        });
        return false;
      } else if (!files[0]) {
        return false;
      }

      const uploadedFiles = [];

      files.forEach((file, index) => {
        const maxFileSize = workoutMaxUploadFileSize;
        const isVideo = /^video\//.test(file.type);

        if (file.size > maxFileSize) {
          showToastAlert("error", {
            message: `File size is too big. Maximum file size is ${humanReadableFileSize(
              workoutMaxUploadFileSize,
            )}`,
          });
          return false;
        }

        uploadFile(file)
          .then(async (uploadedFile) => {
            return isVideo
              ? { ...uploadedFile, url: await transcodeVideo(uploadedFile.url) }
              : uploadedFile;
          })
          .then(({ url, file }) => {
            uploadedFiles.push({
              url,
              type: isVideo ? "video" : "image",
              name: file.name,
            });
            if (index === files.length - 1) {
              handleUpload(uploadedFiles);
            }
          });
      });
    },
    multiple: true,
    accept: {
      ...getMimeTypes(ElementType.IMAGE),
      ...getMimeTypes(ElementType.VIDEO),
    },
  });

  const handleEmbedUrl = React.useCallback(
    (url: string) => {
      handleUpload({
        type: isVideoUrl(url) ? "video" : "image",
        url,
      });
    },
    [handleUpload],
  );

  return (
    <div
      {...(getRootProps({
        className: s.dropzone,
        onClick: (event) => event.stopPropagation(),
      }) as any)}
    >
      <Box
        className={clsx(s.root, className)}
        {...bindTrigger(menuState)}
        {...other}
      >
        <AddIcon className={s.addIcon} />
        <Typography className={s.text}>
          {isUploading
            ? "Uploading..."
            : isTranscoding
              ? "Processing..."
              : "Add media"}
        </Typography>
      </Box>

      <Menu
        className={s.menu}
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleUploadClick} {...(getRootProps() as any)}>
          <ListItemIcon>
            <UploadIcon />
          </ListItemIcon>
          <ListItemText>Upload file</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleEmbedClick}>
          <ListItemIcon>
            <EmbedIcon />
          </ListItemIcon>
          <ListItemText>Embed video link</ListItemText>
          <input type="file" {...getInputProps()} />
        </MenuItem>
      </Menu>
      {embed && (
        <EmbedUrlDialog
          onUpdate={handleEmbedUrl}
          open
          onClose={handleCloseEmbed}
        />
      )}
    </div>
  );
}
