import React from "react";
import "./LinearLoader.css"; // Ensure this CSS file is imported
import { Box, useTheme } from "@mui/material";

interface LinearLoaderProps {
  color?: string;
  height?: number;
  duration?: number;
}

const LinearLoader = ({
  color,
  height = 2,
  duration = 1,
}: LinearLoaderProps) => {
  const theme = useTheme();
  return (
    <Box className="loading-container" style={{ height }}>
      <Box
        className="loading-line"
        style={{
          backgroundColor: color || theme.palette.primary.main,
          animationDuration: `${duration}s`,
        }}
      ></Box>
    </Box>
  );
};

export default LinearLoader;
