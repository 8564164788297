import clsx from "clsx";
import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RenderElementProps } from "slate-react";
import { GetApp } from "@mui/icons-material";
import { humanReadableFileSize } from "../../../../utils/file";
import { PlateElement, withRef } from "@udecode/plate-common";

export const ELEMENT_ATTACHMENT = "attachment";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    margin: theme.spacing(0, 2, 2, 0),
  },

  image: {
    maxHeight: theme.spacing(15),
  },

  file: {
    display: "flex",
    whiteSpace: "nowrap",

    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: theme.spacing(0.5),
  },

  fileIcon: {
    color: theme.palette.secondary.main,
  },

  fileName: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    marginLeft: 21,
    marginRight: "auto",
    maxWidth: "80%",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },

  fileSize: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1.5),
  },
}));

function MessageAttachment(props: any) {
  const { element, children } = props;
  const s = useStyles();
  const mimeType = element.mimeType as string;
  const fileName = element.name as string;
  const isImage =
    (mimeType && /^image\//.test(mimeType as string)) ||
    (fileName && /\.(jpe?g|png)/.test(fileName));

  const handleFileButtonClick = () => {
    window.open(element.url as string, "_blank");
  };

  return (
    <>
      {isImage ? (
        <img
          className={clsx(s.root, s.image)}
          onClick={handleFileButtonClick}
          src={element.url as string}
          alt=""
        />
      ) : (
        <Box className={clsx(s.root, s.file)} onClick={handleFileButtonClick}>
          <GetApp className={s.fileIcon} />
          <Typography
            className={s.fileName}
            component="span"
            children={element.name || element.url}
          />
          {element.size && (
            <Typography
              className={s.fileSize}
              component="span"
              children={humanReadableFileSize(element.size as number)}
            />
          )}
        </Box>
      )}
      {children}
    </>
  );
}

export const MessageAttachmentElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <MessageAttachment children={children} {...props} />
      </PlateElement>
    );
  },
);
