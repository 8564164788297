import clsx from "clsx";
import React from "react";
import { BoxProps, Theme, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";

import { polyfillCSS } from "../../utils/css";
import { MessageEditor } from "../new-editor/MessageEditor";
import useCreateMessageMutation from "./mutations/useCreateMessageMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: polyfillCSS("var(--safe-area-inset-bottom)"),
  },
}));

export interface NewMessageFormProps extends BoxProps {
  threadId?: number;
  recipient?: string;
}

export function NewMessageForm(props: NewMessageFormProps) {
  const s = useStyles();
  const { className, recipient, threadId } = props;
  const { mutate: createMessage, isPending: createMessageInFlight } =
    useCreateMessageMutation(recipient);

  const disabled = !recipient || createMessageInFlight;

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const handleSendMessage = React.useCallback(
    (fragment?: Node[], callback?: () => void) => {
      const content = JSON.stringify(fragment);
      createMessage(
        {
          recipient,
          content,
        },
        {
          onSuccess: () => {
            callback();
          },
        },
      );
    },

    [createMessage, recipient],
  );

  return (
    <MessageEditor
      className={clsx(s.root, className)}
      {...(isLargeScreen ? { rootMinHeight: 140 } : {})}
      backupKeyType="message"
      backupKeyId={threadId}
      onMessageSubmit={handleSendMessage}
      placeholder="Type your message"
      sendButtonVariant="icon"
      softBreak={false}
      disabled={disabled}
      creatingInFlight={createMessageInFlight}
    />
  );
}
