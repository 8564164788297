import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  useTheme,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActivityResponseCard } from "../../components/activity/ActivityResponseCard";
import { LoadMoreButton } from "../button/LoadMoreButton";

import {
  NavigationItem,
  ActivityStatus,
  ActivityType,
  ActivitySort,
  defaultCoachActivityFilters,
  ActivitySortLocalStorageParameter,
} from "../../constants";
import { PageSkeleton } from "../loading/PageSkeleton";
import {
  ActivityTypesFilter,
  ActivityTypesFilterProps,
} from "../filters/ActivityTypesFilter";
import { polyfillCSS } from "../../utils/css";
import {
  ActivitySortFilter,
  ActivitySortFilterProps,
} from "../filters/ActivitySortFilter";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";

import {
  COACH_ACTIVITY_ARCHIVED_ROUTE,
  COACH_ACTIVITY_INBOX_ROUTE,
} from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import EmptyActivitiesArt from "../../icons/EmptyActivitiesArt";
import { types } from "util";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import ActivitiesService from "../../services/ActivitiesService";
import { ClientsAndGroupsSelectDialog } from "../groups/ClientsAndGroupsSelectDialog";
import { ArrowDropDown } from "@mui/icons-material";
import { maybePluralize } from "../../utils/text";
import { deepCompare } from "../editor/utils/common";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  filterButton: {
    fontSize: 15,
  },

  inactiveFilterButton: {
    color: theme.palette.text.secondary,
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },

  empty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(10, 0),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(15, 0),
      "& svg": {
        width: "60%",
        height: "60%",
      },
    },
  },
}));

export const coachActivityNavigation: NavigationItem[] = [
  {
    name: "Inbox",
    link: COACH_ACTIVITY_INBOX_ROUTE,
  },
  {
    name: "Archived",
    link: COACH_ACTIVITY_ARCHIVED_ROUTE,
  },
];

export const getCoachActivityTypes = (
  filters: typeof defaultCoachActivityFilters,
) =>
  Object.entries(filters)
    .filter(([, enabled]) => enabled)
    .map(([type]) => type) as ActivityType[];

export interface CoachActivityProps extends Omit<ContainerProps, "children"> {
  status: ActivityStatus;
  clientIds?: number[];
  groupIds?: number[];
  enableArchive?: boolean;
  openClientsDialog?: () => void;
  clientFilterCount?: number;
}

const COACH_ACTIVITY_LIST_PAGE_SIZE = 10;
export const ACTIVITY_LIST_QUERY_KEY = "activity-list";

export function CoachActivity(props: CoachActivityProps) {
  const {
    className,
    status,
    clientIds = [],
    groupIds = [],
    enableArchive = false,
    openClientsDialog,
    clientFilterCount,
    ...other
  } = props;

  const s = useStyles();
  const theme = useTheme();

  const [visibleModalVideo, setVisibleModalVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);

  const [sortedBy, setSortedBy] = useLocalStorage(
    ActivitySortLocalStorageParameter.SORT,
    ActivitySort.COMPLETED_AT_DESC,
  );

  const [filters, setFilters] = useLocalStorage<
    ActivityTypesFilterProps["filters"]
  >(ActivitySortLocalStorageParameter.FILTER, defaultCoachActivityFilters);

  const activityTypes = getCoachActivityTypes(filters);

  const {
    data: activitiesData,
    ref: activityElementRef,
    isLoading: isLoadingInitial,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [
      ACTIVITY_LIST_QUERY_KEY,
      {
        status,
        orderBy: sortedBy,
        activityTypes,
        clientIds: clientIds,
        groupIds: groupIds,
      },
    ],
    queryFn: ({ pageParam }) =>
      ActivitiesService.getActivities(
        pageParam as string | null,
        COACH_ACTIVITY_LIST_PAGE_SIZE,
        activityTypes,
        status === ActivityStatus.ARCHIVED,
        sortedBy,
        clientIds,
        groupIds,
      ),
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
  });

  const activities = activitiesData?.pages.flatMap((page) => page.items) ?? [];

  const handleFiltersChange: ActivityTypesFilterProps["onChange"] =
    React.useCallback(
      (filters) => {
        setFilters(filters);
      },
      [setFilters],
    );

  const handleSortChange: ActivitySortFilterProps["onChange"] =
    React.useCallback(
      (sortedBy) => {
        setSortedBy(sortedBy);
      },
      [setSortedBy],
    );

  const handleMoreClick = React.useCallback(() => {
    if (isFetchingNextPage || !hasNextPage) return;
    fetchNextPage();
  }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

  const handleOpenVideo = React.useCallback((url) => {
    setVisibleModalVideo(true);
    setVideoUrl(url);
  }, []);

  const handleCloseVideo = React.useCallback(() => {
    setVisibleModalVideo(false);
    setVideoUrl(null);
  }, []);

  const isLoading = isLoadingInitial || (isFetching && !isFetchingNextPage);

  return (
    <>
      <Container className={clsx(s.root, className)} {...other}>
        <Box className={s.actions}>
          <ActivitySortFilter
            active={sortedBy !== ActivitySort.COMPLETED_AT_DESC}
            sortedBy={sortedBy}
            onChange={handleSortChange}
          />
          {openClientsDialog && (
            <Button
              className={clsx(
                s.filterButton,
                clientFilterCount === 0 && s.inactiveFilterButton,
                className,
              )}
              onClick={openClientsDialog}
            >
              Filter by:{" "}
              {clientFilterCount === 0
                ? "All clients"
                : (clientIds.length
                    ? maybePluralize(clientIds.length, "client")
                    : "") +
                  (clientIds.length && groupIds.length ? " & " : "") +
                  (groupIds.length
                    ? maybePluralize(groupIds.length, "group")
                    : "")}
              <ArrowDropDown />
            </Button>
          )}
          <ActivityTypesFilter
            active={!deepCompare(filters, defaultCoachActivityFilters)}
            filters={filters}
            onChange={handleFiltersChange}
          />
        </Box>

        {isLoading ? (
          <PageSkeleton fullWidth />
        ) : activities.length ? (
          <>
            {activities.map((activity) => (
              <>
                <div ref={activityElementRef}></div>
                <ActivityResponseCard
                  enableArchive={enableArchive}
                  key={activity.id}
                  activity={activity}
                  handleOpenVideo={handleOpenVideo}
                />
              </>
            ))}

            {hasNextPage && !isLoading && (
              <LoadMoreButton
                onClick={handleMoreClick}
                loading={isFetchingNextPage}
              />
            )}
          </>
        ) : (
          <>
            <Typography>There are no new activities</Typography>
            {status === ActivityStatus.ACTIVE && (
              <Box className={s.empty}>
                <EmptyActivitiesArt fill={theme.palette.primary.main} />
              </Box>
            )}
          </>
        )}
      </Container>
      <ConfirmActionDialog
        title=""
        onCancel={handleCloseVideo}
        onConfirm={() => {}}
        open={visibleModalVideo}
        activity
        videoUrl={videoUrl}
      />
      <TrackInfoTool
        trackInfo={{
          name: clientIds
            ? "Coach - Client Activities"
            : status === ActivityStatus.ACTIVE
              ? "Coach - Activity"
              : "Coach - Archived Activity",
        }}
      />
    </>
  );
}
