import { ICursorPaginatedListOfGroupPostDto } from "@growth-machine-llc/stridist-api-client";
import { InfiniteData } from "@tanstack/react-query";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import GroupPostsService from "../../../services/GroupPostsService";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { GROUP_POSTS_LIST_QUERY_KEY } from "../GroupPostsList";

interface RemoveGroupPostMutationVariables {
  groupId: number;
}
export const useRemoveGroupPostMutation = ({
  groupId,
}: RemoveGroupPostMutationVariables) => {
  {
    const updater = INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem;
    return useOptimisticUpdateMutation({
      successToastMessage: "Post deleted",

      mutationFn: GroupPostsService.deleteGroupPost,
      queryKey: [GROUP_POSTS_LIST_QUERY_KEY, { groupId }],
      optimisticUpdater: {
        updateFn: (
          prev: InfiniteData<ICursorPaginatedListOfGroupPostDto>,
          postToDeleteId,
        ) => {
          return updater(prev, postToDeleteId);
        },
      },
    });
  }
};
