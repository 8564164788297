import ClientGoalsClientService from "../../../services/ClientGoalsClientService";
import {
  ClientGoalDto,
  ICreateClientGoalCommand,
  PaginatedListOfClientGoalDto,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { CLIENT_GOALS_LIST_QUERY_KEY } from "../../../components/card/ClientGoalsCard";
import dayjs from "dayjs";

export default function useCreateClientGoalMutation(clientId: number) {
  const optimisticUpdater = (
    data: PaginatedListOfClientGoalDto,
    variables: ICreateClientGoalCommand,
    tempId: number,
  ) => {
    const newGoal = ClientGoalDto.fromJS({
      id: tempId,
      name: variables.name,
      clientId: clientId,
      targetValue: variables.targetValue.toString(),
      currentValue: "",
      date: variables.date,
      lastModified: dayjs(),
      ordinal: 1,
    });
    const updatedItems = (data.items ?? []).map((goal) => ({
      ...goal,
      ordinal: goal.ordinal + 1,
    }));
    return {
      ...data,
      items: [newGoal, ...updatedItems],
      totalCount: data.totalCount + 1,
    } as PaginatedListOfClientGoalDto;
  };

  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_GOALS_LIST_QUERY_KEY, { clientId }],
    mutationFn: ClientGoalsClientService.createClientGoal,
    optimisticUpdater: {
      updateFn: optimisticUpdater,
    },
    successUpdater: {
      updateFn: (oldData, variables, tempId) => {
        oldData.items = oldData.items?.map((goal) => {
          if (goal.id === tempId) {
            goal.id = variables;
          }
          return goal;
        });
        return oldData;
      },
    },
  });
}
