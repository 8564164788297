import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
} from "@mui/material";
import React, { ReactNode, useRef, useState } from "react";

import Cropper, { Crop, PixelCrop, ReactCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import { Media } from "./Image";
export interface CropScaled extends Crop {
  scaleX: number;
  scaleY: number;
}
type ImageCropProps = {
  open: boolean;
  onClose: () => void;
  onSave?: (image: HTMLImageElement, crop: PixelCrop) => void;
  imageSrc: string;
  previewCanvasRef?: React.MutableRefObject<HTMLCanvasElement>;
  completedCrop?: PixelCrop;
  setCompletedCrop?: React.Dispatch<React.SetStateAction<PixelCrop>>;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement>>;
  preview?: boolean;
  setPreview?: React.Dispatch<React.SetStateAction<boolean>>;
  setMedia?: React.Dispatch<React.SetStateAction<Media>>;
  media?: Media;
  setIsCroppedImage?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: ReactNode;
};
const ImageCropDialog = ({
  open,
  onSave,
  onClose,
  imageSrc,
  previewCanvasRef,
  completedCrop,
  setCompletedCrop,
  setAnchorEl,
  preview,
  setPreview,
  setMedia,
  media,
  setIsCroppedImage,
  children,
}: ImageCropProps) => {
  const defaultCrop: PixelCrop = {
    unit: "px",
    width: 400,
    height: 400,
    x: 0,
    y: 0,
  };
  const [crop, setCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const handleChange = (c) => {
    setCrop(c);
  };

  useDebounceEffect(
    async () => {
      if (
        crop?.width &&
        crop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          crop,
          scale,
          rotate,
        );
      }
    },
    100,
    [preview, handleChange],
  );

  const handleImageLoad = () => {
    if (imgRef.current) {
      setCrop({
        unit: "px",
        width: 400,
        height: 400,
        x: imgRef.current.width / 2 - 200,
        y: imgRef.current.height / 2 - 200,
      });
    }
  };

  const handleSave = async () => {
    setPreview && setPreview(true);
    onSave && onSave(imgRef.current, crop);
    onClose();
    setIsCroppedImage(true);
    setAnchorEl && setAnchorEl(null);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiPaper-root": {
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
          maxWidth: "initial",
          minWidth: "25vw",
          minHeight: "25vh",
        },
      }}
    >
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            overflow: "hidden",
            marginBottom: "20px",
          }}
        >
          <ReactCrop
            crop={crop}
            keepSelection
            aspect={1}
            onChange={(c, p) => {
              handleChange(c);
            }}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img
              ref={imgRef}
              onLoad={handleImageLoad}
              alt="Crop me"
              src={imageSrc}
              style={{ maxHeight: "70vh" }}
            />
          </ReactCrop>
        </Box>
        <DialogActions sx={{ width: "100%", p: 0 }}>
          <Box display={"flex"} width={"100%"} flexDirection={"column"}>
            {children && children}
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                handleSave();
              }}
              color="primary"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCropDialog;
