class ServiceBase {
  protected static parseCommandVariables<T>(variables: object, command: T): T {
    for (const [key, value] of Object.entries(variables)) {
      command[key] = value;
    }

    return command;
  }
}

export default ServiceBase;
