import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Box,
  ListItemIcon,
  Typography,
  ListItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "lodash";

import { ProUpgradeCard, ProUpgradeCardProps } from "../card/ProUpgradeCard";
import { RequiresUpgradeDialog } from "../dialog/RequiresUpgradeDialog";
import { UpgradedDialog } from "../dialog/payments/UpgradedDialog";
import { getRequiresUpgrade } from "../../utils/user";
import { useQueryParam } from "../../hooks/useQueryParam";
import { CoachSettingsPlanTextSubtitle } from "./CoachSettingsPlanTextSubtitle";
import {
  SelectClientsDialog,
  SelectClientsDialogProps,
} from "../dialog/SelectClientsDialog";
import {
  Plan,
  REACTIVATE_PROMO_CANCEL_BEFORE_DATE,
  planClients,
} from "../../constants";
import { ReactComponent as CheckMarkCircle } from "../../icons/CheckMarkCircle.svg";
import { CoachSettingsPlanStatusText } from "./CoachSettingsPlanStatusText";

import { UserInfo } from "../../hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    padding: theme.spacing(3, 2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 4),
    },
  },

  header: {
    marginBottom: theme.spacing(2.5),
  },

  content: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginBottom: 0,
    },
  },

  features: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: theme.spacing(4.5),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(7),
      marginBottom: 0,
    },
  },

  feature: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(3.5),
    },

    [theme.breakpoints.up("md")]: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(6),
      },
    },
  },

  check: {
    marginRight: theme.spacing(1.25),

    "& svg": {
      width: 30,
      height: 30,
      color: theme.palette.primary.main,
    },
  },

  text: {
    fontSize: 16,

    fontWeight: "bold",
    color: theme.palette.common.black,
  },

  card: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(-12),
    },
  },

  downgrade: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
  titleModal: {
    lineBreak: "anywhere",
  },
}));

type GetFeaturesProps = {
  text: string;
};

function getFeatures(plan: Plan): GetFeaturesProps[] {
  if (!plan) {
    return [];
  }

  const clientsCount = planClients(plan) as string;

  return [
    {
      text: "Stridist Coaching Portal",
    },
    {
      text: "Stridist Marketing Suite",
    },
    {
      text: "Stridist Business Incubator",
    },
    {
      text: `${plan !== Plan.UP_TO_UNLIMITED ? "Up to " : ""}${capitalize(
        clientsCount,
      )} clients`,
    },
  ];
}

export interface CoachSettingsPlanProps
  extends Omit<ContainerProps, "children"> {
  user: UserInfo;
}

export function CoachSettingsPlan(props: CoachSettingsPlanProps) {
  const { className, user, ...other } = props;
  const s = useStyles();
  const [requiresUpgrade, reason] = getRequiresUpgrade(
    user.trialExpired,
    user.plan as Plan,
    user.subscription?.status,
  );
  const [requiresUpgradeDialogOpen, setRequiresUpgradeDialogOpen] =
    React.useState(requiresUpgrade);
  const [upgraded, setUpgraded] = useQueryParam("upgraded", undefined);
  const [plan] = useQueryParam("plan", undefined);
  const [
    downgradeSelectClientsDialogOpen,
    setDowngradeSelectClientsDialogOpen,
  ] = React.useState(false);
  const [keepClientsDialogOpen, setKeepClientsDialogOpen] = React.useState(
    user.clientsCountNoSample > Number(planClients(user.plan as Plan)),
  );
  const [selectedPlan, setSelectedPlan] = React.useState<Plan>(
    Plan[user.plan] || Plan.UP_TO_INFINITY,
  );

  const handleCloseRequiresUpgradeDialog = React.useCallback(() => {
    setRequiresUpgradeDialogOpen(false);
  }, []);

  const handleCloseUpgradedDialog = React.useCallback(() => {
    setUpgraded(undefined);
  }, [setUpgraded]);

  const handleDowngradeClick = React.useCallback(() => {
    if (
      !user.plan &&
      user.clientsCountNoSample > Number(planClients(selectedPlan, true))
    ) {
      setDowngradeSelectClientsDialogOpen(true);
    }
  }, [user.clientsCountNoSample, user.plan, selectedPlan]);

  const handleCloseDowngradeSelectClientsDialog = React.useCallback(() => {
    setDowngradeSelectClientsDialogOpen(false);
  }, []);

  const handleCloseKeepClientsDialog = React.useCallback(() => {
    setKeepClientsDialogOpen(false);
  }, []);

  const handlePlanChange: ProUpgradeCardProps["onPlanChange"] =
    React.useCallback((plan) => {
      setSelectedPlan(plan);
    }, []);

  const features = React.useMemo(
    () => getFeatures(selectedPlan),
    [selectedPlan],
  );

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <CoachSettingsPlanStatusText
          plan={user.plan}
          subscription={user.subscription}
        />
        <CoachSettingsPlanTextSubtitle
          plan={user.plan}
          trialExpired={user.trialExpired}
          trialExpiredDate={user.trialExpiryDate?.toString()}
          subscription={user.subscription}
        />
      </Box>

      <Box className={s.content}>
        <Box className={s.features}>
          {features.map(({ text }, index) => (
            <ListItem key={index} className={s.feature}>
              <ListItemIcon
                className={s.check}
                children={<CheckMarkCircle />}
              />
              <Typography children={text} className={s.text} />
            </ListItem>
          ))}
        </Box>

        <Box>
          <ProUpgradeCard
            className={s.card}
            user={user}
            onPlanChange={handlePlanChange}
          />
        </Box>
      </Box>

      {!user.isImpersonating &&
        new Date(user?.subscription?.canceledDate) >
          REACTIVATE_PROMO_CANCEL_BEFORE_DATE && (
          <RequiresUpgradeDialog
            open={requiresUpgradeDialogOpen}
            onClose={handleCloseRequiresUpgradeDialog}
            reason={reason}
            onDowngradeClick={handleDowngradeClick}
          />
        )}

      {selectedPlan && (
        <SelectClientsDialog
          open={downgradeSelectClientsDialogOpen}
          onClose={handleCloseDowngradeSelectClientsDialog}
          title={`Downgrade to ${capitalize(selectedPlan)} plan`}
          description={`The ${capitalize(
            selectedPlan,
          )} plan includes ${planClients(
            selectedPlan,
          )} clients. Select which client you would like to keep – the others will be archived.`}
          onConfirm={() => {}}
          maxSelected={planClients(selectedPlan, true) as number}
        />
      )}

      <UpgradedDialog
        open={Boolean(upgraded)}
        onClose={handleCloseUpgradedDialog}
        plan={plan}
      />
    </Container>
  );
}
