import { useCreateCustomAsset } from "../../component-library/hooks/useCreateCustomAsset";
import { ExerciseAsset } from "../../exercise-library/types";
import { CustomAssetType } from "../../../constants";
import { useUpdateCustomAsset } from "../../component-library/hooks/useUpdateCustomAsset";

type Options = {
  onCompleted?: () => void;
};

type SaveExerciseOptions = {
  successMessage?: string;
};

export function useSaveExercise({ successMessage }: SaveExerciseOptions = {}) {
  const { mutate: createAsset } = useCreateCustomAsset({ successMessage });
  const { mutate: updateAsset } = useUpdateCustomAsset({ successMessage });

  return ({ assetId, exercise }: ExerciseAsset, options?: Options) => {
    const content = JSON.stringify(exercise);

    return assetId
      ? updateAsset(
          {
            id: assetId,
            name: exercise.title,
            content,
            assetType: CustomAssetType.WORKOUT_EXERCISE,
          },
          {
            onSuccess: () => {
              if (options.onCompleted) {
                options.onCompleted();
              }
            },
          },
        )
      : createAsset(
          {
            name: exercise.title,
            content,
            assetType: CustomAssetType.WORKOUT_EXERCISE,
          },
          {
            onSuccess: () => {
              if (options.onCompleted) {
                options.onCompleted();
              }
            },
          },
        );
  };
}
