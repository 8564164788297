import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Avatar, AvatarProps } from "../avatar/Avatar";
import { LikeDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface LikeListItemProps extends Omit<AvatarProps, "userRef"> {
  like: LikeDto;
}

export function LikeListItem(props: LikeListItemProps) {
  const { className, like, ...other } = props;
  const s = useStyles();
  return (
    <Avatar
      className={clsx(s.root, className)}
      displayName={like.author.displayName}
      photoURL={like.author.photoUrl}
      {...other}
    />
  );
}
