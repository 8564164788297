import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { BaseWeekDto } from "@growth-machine-llc/stridist-api-client";
import LinearLoader from "../loading/LinearLoader/LinearLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },

  tabs: {
    "& .Mui-disabled": {
      display: "none",
    },
  },

  tab: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
    flexGrow: 1,

    minWidth: 156,
    maxWidth: "100%",

    [theme.breakpoints.up("md")]: {
      minWidth: 253,
    },
  },

  selected: {
    color: theme.palette.primary.main,
  },

  current: {
    "&:not($selected)": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.selected.main,
    },
  },

  indicator: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },

  "@keyframes runLine": {
    " 0%": {
      left: "0",
    },
    " 50%": {
      left: "50%",
    },
    " 100%": {
      left: "100%",
    },
  },

  loadingIndicator: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
    animation: "$runLine 1s linear infinite",
  },
}));

export interface ClientProgramTimelineProps extends Omit<BoxProps, "onChange"> {
  weeks: BaseWeekDto[];
  currentWeek: number;
  selected: number;
  setSelected: (x: string) => void;
  isLoading?: boolean;
}

export function ClientProgramTimeline(props: ClientProgramTimelineProps) {
  const { className, weeks, currentWeek, selected, setSelected, isLoading } =
    props;
  const s = useStyles();

  const handleChange = React.useCallback(
    (_, value: any) => {
      setSelected(value);
    },
    [setSelected],
  );

  return (
    <Box className={clsx(s.root, className)}>
      <Tabs
        className={s.tabs}
        value={selected}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        aria-label="scrollable force tabs example"
        allowScrollButtonsMobile
        classes={{ indicator: isLoading ? s.loadingIndicator : s.indicator }}
      >
        {weeks.map(({ weekNumber }, index) => {
          return (
            <Tab
              key={index}
              label={`Week ${weekNumber}`}
              value={weekNumber}
              className={clsx(s.tab, {
                [s.selected]: weekNumber === selected,
                [s.current]: weekNumber === currentWeek,
              })}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
