import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NotificationsNone } from "@mui/icons-material";

import { NotificationsMenu } from "../menu/NotificationsMenu";

import { useCurrentUser, UserInfo } from "../../hooks/useCurrentUser";
import { useMutation } from "@tanstack/react-query";
import NotificationsService from "../../services/NotificationsService";
import { useUpdateCurrentUserCache } from "../../hooks/useUpdateCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    padding: 8,

    "&$unread": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    [theme.breakpoints.up("md")]: {
      padding: 10,
    },
  },

  unreadCount: {
    width: 18,
    height: 18,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: -5,
    right: -5,
    fontSize: 11,
    fontWeight: "bold",
  },

  unread: {},
}));

export interface NotificationsProps {
  user: UserInfo;
  className?: string;
}

export function Notifications(props: NotificationsProps) {
  const { user, className } = props;
  const { notificationsActive, notificationUnreadCount } =
    useCurrentUser() ?? {};
  const s = useStyles();
  const [menuEl, setMenuEl] = React.useState<Element | null>(null);

  const updateUserCache = useUpdateCurrentUserCache();
  const { mutate: notificationsClicked } = useMutation({
    mutationFn: NotificationsService.clickNotifications,
    onSuccess: () => {
      updateUserCache({ notificationsActive: false });
    },
  });

  const handleClick: IconButtonProps["onClick"] = React.useCallback(
    (event) => {
      setMenuEl(event.currentTarget);
      notificationsClicked(undefined);
    },
    [notificationsClicked, user?.id],
  );

  const closeMenu = React.useCallback(() => {
    setMenuEl(null);
  }, []);

  const active = Boolean(notificationsActive && notificationUnreadCount);

  return (
    <>
      <IconButton
        className={clsx(s.button, className, active && s.unread)}
        onClick={handleClick}
        aria-owns={menuEl ? "notifications-menu" : undefined}
        aria-haspopup="true"
        size="large"
      >
        <NotificationsNone />
        {active && (
          <Box className={s.unreadCount}>{notificationUnreadCount}</Box>
        )}
      </IconButton>
      {
        <NotificationsMenu
          id="notifications-menu"
          role="menu"
          anchorEl={menuEl}
          open={Boolean(menuEl)}
          onClose={closeMenu}
          unreadNotificationsCount={notificationUnreadCount}
          userId={+user?.id}
        />
      }
    </>
  );
}
