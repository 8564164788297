import clsx from "clsx";
import React from "react";
import { BoxProps, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";

import { SelectableItem } from "./SelectableItem";
import {
  ClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";

import { formatDateOrdinal } from "../../utils/date";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface PendingInviteItemProps extends BoxProps {
  user: ClientInfoDto;
  totalCount: any;
  menuTriggerProps: any;
  selectInvite: (invite: ClientInfoDto) => void;
}

export function PendingInviteItem(props: PendingInviteItemProps) {
  const {
    className,
    user,
    totalCount,
    menuTriggerProps,
    selectInvite,
    ...other
  } = props;
  const s = useStyles();

  return (
    <>
      <SelectableItem
        className={clsx(s.root, className)}
        header={user.email}
        subheader={`${user.status === UserInviteStatus.ADDED ? "Added by" : "Invited by"} ${
          user?.invite?.source ? "link" : "email"
        } on ${formatDateOrdinal(dayjs(user.invite.inivitedAt.toString()))}`}
        action={
          <IconButton
            children={<MoreHoriz />}
            {...menuTriggerProps}
            onClick={(e) => {
              selectInvite(user);
              menuTriggerProps.onClick(e);
            }}
            size="large"
          />
        }
        {...other}
      />
    </>
  );
}
