import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { ProgressLine } from "../progress/ProgressLine";

const useStyles = makeStyles((theme) => ({
  targetTextBold: {
    color: colorSystem.black,
    fontWeight: 700,
    fontSize: 16,
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },

  progress: {
    width: "100%",
    marginTop: theme.spacing(2),
  },

  progressTrack: {
    height: 4,
    backgroundColor: colorSystem.secondaryGrayOpacity5,
  },

  name: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },
}));

export interface ClientAutoNutritionItemProps {
  value: number | null;
  targetValue?: number | null;
  label: string;
  unit?: string | null;
}

const ClientAutoNutritionItem: FC<ClientAutoNutritionItemProps> = (props) => {
  const { value, targetValue, label, unit } = props;

  const s = useStyles();

  const calculateProgress = React.useCallback(() => {
    const progress = (100 / +targetValue) * +value;
    return progress > 100 ? 100 : progress;
  }, [value, targetValue]);

  return (
    <Box>
      <Typography className={s.name}>{label}</Typography>
      <div className={s.targetTextBold}>
        {value !== null ? `${Math.floor(value) || 0}${unit || ""}` : "-"}
        {targetValue !== undefined && targetValue !== null
          ? ` / ${Math.floor(targetValue) || 0}${unit || ""}`
          : ""}
      </div>
      {!!targetValue && (
        <ProgressLine
          value={calculateProgress()}
          className={s.progress}
          classNameTrack={s.progressTrack}
        />
      )}
    </Box>
  );
};

export default ClientAutoNutritionItem;
