import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Tabs, Tab, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "lodash";

import { UserDetails } from "./UserDetails";
import TrackInfoTool from "../tools/TrackInfoTool";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: theme.spacing(2.5),
  },

  tabs: {
    "& span": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
    },
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.selected.main,
    marginBottom: theme.spacing(2.5),
  },
}));

enum UserTab {
  DETAILS = "DETAILS",
}

export interface UserPageProps extends BoxProps {}

export const ADMIN_USER_QUERY_KEY = "admin-user";

export function UserPage(props: UserPageProps) {
  const { className, ...other } = props;
  const { userId: userIdParam } = useParams();
  const userId = parseInt(userIdParam);
  const { data: user, isPending } = useQuery({
    queryKey: [ADMIN_USER_QUERY_KEY, { userId }],
    queryFn: () => FbUsersService.getAdminUser(userId),
    enabled: Boolean(userId),
  });
  const s = useStyles();
  const [selectedTab, setSelectedTab] = React.useState<UserTab>(
    UserTab.DETAILS,
  );

  React.useEffect(() => {
    if (user) {
      window.document.title = user.displayName;
    }
  }, [user]);

  const handleTabChange = React.useCallback((_, value: UserTab) => {
    setSelectedTab(value);
  }, []);

  return (
    <>
      {isPending ? (
        <>
          <Box className={clsx(s.root, className)} {...other}>
            <Skeleton animation="wave" width={500} height={40} />
            <Skeleton
              sx={{ marginTop: 6 }}
              animation="wave"
              width={100}
              height={30}
            />
            {Array.from({ length: 4 }).map((_, i) => (
              <Skeleton key={i} animation="wave" width={700} height={50} />
            ))}
            <Box marginTop={2}>
              {Array.from({ length: 3 }).map((_, i) => (
                <Skeleton key={i} animation="wave" width={200} height={40} />
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className={clsx(s.root, className)} {...other}>
            <Typography
              className={s.title}
              variant="h1"
              children={user.displayName}
            />

            <Tabs
              className={s.tabs}
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              {Object.values(UserTab).map((tab) => (
                <Tab key={tab} label={capitalize(tab)} value={tab} />
              ))}
            </Tabs>

            {selectedTab === UserTab.DETAILS && <UserDetails user={user} />}
          </Box>
          <TrackInfoTool
            trackInfo={{
              name: `Admin - User - ${user.email} (${user.role})`,
            }}
          />
        </>
      )}
    </>
  );
}
