import React from "react";
import { BoxProps } from "@mui/material";
import {
  GroupPostCommentsListItem,
  GroupPostCommentsListItemProps,
} from "./GroupPostCommentsListItem";
import { GroupPostCommentDto } from "@growth-machine-llc/stridist-api-client";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";

export interface GroupPostCommentsListItemWithRepliesProps
  extends BoxProps,
    Pick<GroupPostCommentsListItemProps, "first" | "onReplyClick"> {
  groupId: number;
  postId: number;
  comment: GroupPostCommentDto;
}

export function GroupPostCommentsListItemWithReplies(
  props: GroupPostCommentsListItemWithRepliesProps,
) {
  const { className, comment, groupId, ...other } = props;

  return (
    <OptimisticUpdateContainer id={comment.id}>
      <GroupPostCommentsListItem
        groupId={groupId}
        comment={comment}
        {...other}
      />
    </OptimisticUpdateContainer>
  );
}
