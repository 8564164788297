import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BasicSkeletonCard } from "./BasicSkeletonCard";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  lg: {},

  card: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },

  fullWidth: {
    width: "100%",
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
}));

export interface PageSkeletonProps extends Omit<ContainerProps, "children"> {
  cardsCount?: number;
  ItemComponent?: React.FC;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  itemProps?: Record<string, any>;
}

export function PageSkeleton(props: PageSkeletonProps) {
  const {
    className,
    cardsCount = 3,
    ItemComponent = BasicSkeletonCard,
    maxWidth = "md",
    fullWidth = false,
    style,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Container
      maxWidth={maxWidth}
      className={clsx(
        s.root,
        className,
        maxWidth === "lg" && s.lg,
        fullWidth && s.fullWidth,
      )}
      style={style}
      {...other}
    >
      {Array.from({ length: cardsCount }).map((_, i) => (
        <ItemComponent key={i} className={s.card} {...props.itemProps} />
      ))}
    </Container>
  );
}
