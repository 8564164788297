import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TrainingSummaryExercise } from "./TrainingSummaryExercise";
import { TrainingSummaryWellBegin } from "./TrainingSummaryWellBegin";
import { Node } from "slate";
import { ActivityBriefDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => {
  return {
    root: {
      paddingTop: spacing(7),
      paddingBottom: spacing(2),
      maxWidth: breakpoints.values.slg,
    },
  };
});

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activity: ActivityBriefDto;
  clientUsername: string;
  coach?: boolean;
}

export function TrainingSummary(props: ActivityProps) {
  const { activity, clientUsername } = props;
  const s = useStyles();
  const originalContent: Node[] = React.useMemo(
    () => JSON.parse(activity?.content) || [],
    [activity.content],
  );

  const startWorkout: Node[] = React.useMemo(
    () => JSON.parse(activity?.startWorkout) || [],
    [activity.startWorkout],
  );
  const endWorkout: Node[] = React.useMemo(
    () => JSON.parse(activity?.endWorkout) || [],
    [activity.endWorkout],
  );

  const workoutSection = React.useMemo(() => {
    return originalContent?.filter((i: any) => i.type === "workout_section");
  }, [originalContent]);

  return (
    <Container className={clsx(s.root)}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {workoutSection.map(({ workout }: any) =>
            workout.exercises.map((exercise, index) => (
              <TrainingSummaryExercise
                key={index}
                exercise={exercise}
                clientUsername={clientUsername}
              />
            )),
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TrainingSummaryWellBegin
            startWorkout={startWorkout}
            endWorkout={endWorkout}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
