import React from "react";

import { SelectableItem, SelectableItemProps } from "./SelectableItem";
import { UserBriefInfoDto } from "@growth-machine-llc/stridist-api-client";

export interface ClientAddableItemProps
  extends Pick<
    SelectableItemProps,
    "className" | "added" | "disabled" | "disabledText"
  > {
  client: UserBriefInfoDto;
  onToggle: (added: boolean, client: UserBriefInfoDto) => void;
}

export function ClientAddableItem(props: ClientAddableItemProps) {
  const { className, client, onToggle, ...other } = props;
  const { email, displayName, photoUrl } = client;

  const handleToggle: SelectableItemProps["onToggle"] = React.useCallback(
    (added) => {
      onToggle(added, client);
    },
    [client, onToggle],
  );

  return (
    <SelectableItem
      className={className}
      avatarSrc={photoUrl}
      header={displayName}
      subheader={email}
      onToggle={handleToggle}
      {...other}
    />
  );
}
