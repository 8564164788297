import clsx from "clsx";
import React from "react";
import { List, ListProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { RecipientListItem } from "./RecipientListItem";
import {
  MessageGroupDto,
  RecipientsDto,
} from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  empty: {
    marginTop: theme.spacing(3),
  },
}));

export interface RecipientListProps extends Omit<ListProps, "onSelect"> {
  recipients: RecipientsDto[];
  onLoadMore: () => void;
  loading: boolean;
  hasNext: boolean;
  onSelect?: (event: React.MouseEvent, username?: string) => void;
}

export function RecipientList(props: RecipientListProps) {
  const {
    className,
    onSelect,
    onLoadMore,
    recipients: recipientsFromProps,
    loading,
    hasNext,
    ...other
  } = props;
  const s = useStyles();

  const handleMoreClick = React.useCallback(() => {
    !loading && onLoadMore();
  }, [loading, onLoadMore]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const {
        dataset: { username },
      } = event.currentTarget;

      if (username && onSelect) {
        onSelect(event, username);
      }
    },
    [onSelect],
  );

  const edges = (recipientsFromProps || []).filter((x) => x.group || x.user);

  return edges.length > 0 ? (
    <>
      <List className={clsx(s.root, className)} {...other}>
        {edges.map((recipient, index) => (
          <RecipientListItem
            key={index}
            recipient={recipient}
            divider={index < edges.length - 1}
            data-username={recipient.group?.slug || recipient.user?.username}
            onClick={handleClick}
          />
        ))}
      </List>
      {hasNext && (
        <Button onClick={handleMoreClick} fullWidth disabled={loading}>
          Load more
        </Button>
      )}
    </>
  ) : (
    <Typography component="div" className={s.empty}>
      Nothing found
    </Typography>
  );
}
