import clsx from "clsx";
import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { ManageGroupDialog } from "../groups/ManageGroupDialog";

import { ActionButton, ActionButtonProps } from "../button/ActionButton";
import ManageGroupDialogContext from "../../contexts/ManageGroupDialogContext";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupManageMembersButtonProps extends ActionButtonProps {
  group: GroupDto2;
}

export function GroupManageMembersButton(props: GroupManageMembersButtonProps) {
  const { className, children, group, ...other } = props;
  const s = useStyles();

  const context = useContext(ManageGroupDialogContext);
  const { manageGroupDialogOpen, setManageGroupDialogOpen } = context;

  const handleCloseDialog = React.useCallback(() => {
    setManageGroupDialogOpen(false);
  }, []);

  return (
    <>
      {group && manageGroupDialogOpen && (
        <ManageGroupDialog
          open={true}
          onClose={handleCloseDialog}
          group={group}
        />
      )}
    </>
  );
}
