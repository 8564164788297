import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserRole } from "../constants";
import {
  NotFoundPage,
  loggedInClientRoutes,
  loggedInCoachRoutes,
  loggedOutRoutes,
  publicRoutes,
  adminRoutes,
} from "./groupedRoutes";
import { CurrentUserWrapper } from "../wrappers/current-user/CurrentUserWrapper";
import { useCurrentBrand } from "../hooks/useCurrentWorkspace";
import useAuth from "../hooks/auth/useAuth";
import ErrorBoundary from "../components/app/ErrorBoundary";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "../theme";

interface AppRoutesProps {
  role: UserRole;
}
const AppRoutes = ({ role: userRole }: AppRoutesProps) => {
  const { isBrandedApp, isBrandedWorkspace } = useCurrentBrand();
  const user = useCurrentUser();
  const isClientArchived = user?.archived;

  const isCoach = userRole === UserRole.COACH;
  const isClient = userRole === UserRole.CLIENT;
  const isAdmin = user?.admin;

  return (
    <Routes>
      {publicRoutes}
      {!userRole && loggedOutRoutes(isBrandedApp)}
      {isCoach && loggedInCoachRoutes(isBrandedWorkspace)}
      {isClient && loggedInClientRoutes(isClientArchived)}
      {isAdmin && adminRoutes}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const AppRouter = () => {
  const { userRole } = useAuth();
  const defaultTheme = createTheme();
  return (
    // Fallback to the default theme. Required to make styles works on components outside of the `CurrentUserWrapper`.
    // Will be overridden by workspace theme in `CurrentUserProvider` if exists.
    <ThemeProvider theme={defaultTheme}>
      <Router>
        <ErrorBoundary>
          <Routes>
            <Route element={<CurrentUserWrapper authorized={!!userRole} />}>
              <Route path="/*" element={<AppRoutes role={userRole} />} />
            </Route>
          </Routes>
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
