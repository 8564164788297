import React from "react";
import { ActivityStatus } from "../../../../constants";
import { CoachActivity } from "../../../../components/activity/CoachActivity";
import { useClient } from "../../../../hooks/useClient";

export function CoachClientResponsesRoute() {
  // TODO_PERFORMANCE_2_0 STR-1406: Rethink and return Responses

  const { id } = useClient();

  return <CoachActivity status={ActivityStatus.ACTIVE} clientIds={[id]} />;
}
