import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as LikeIcon } from "../../icons/Like.svg";
import { ReactComponent as UnlikeIcon } from "../../icons/Liked.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
}));

export interface LikesButtonProps extends IconButtonProps {
  likedByMe: boolean;
  onLikeToggle: (liked: boolean) => void;
  disabled?: boolean;
}

export function LikesButton(props: LikesButtonProps) {
  const { className, onLikeToggle, disabled, ...other } = props;
  const s = useStyles();
  const { likedByMe } = props;

  const handleClick = React.useCallback(
    () => onLikeToggle(!likedByMe),
    [likedByMe, onLikeToggle],
  );

  return (
    <IconButton
      className={clsx(s.root, className)}
      onClick={handleClick}
      disabled={disabled}
      {...other}
      size="large"
    >
      {likedByMe ? <UnlikeIcon /> : <LikeIcon />}
    </IconButton>
  );
}
