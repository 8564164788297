import React from "react";
import { AppLayout } from "../../../../components/app/AppLayout";
import { SignupClientCompletePhotoScreen } from "../../../../components/screen/SignupClientCompletePhotoScreen";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";

export function SignUpCompletePhotoRoute() {
  const me = useCurrentUser();

  return (
    me && (
      <AppLayout appBar={false}>
        <SignupClientCompletePhotoScreen user={me} />
      </AppLayout>
    )
  );
}
