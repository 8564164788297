import { unsplashClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class UnsplashImagesService extends ServiceBase {
  async getUnsplashImagesPaginated(
    page: number,
    pageSize: number,
    query: string,
  ) {
    return unsplashClient.getUnsplashImages(page, pageSize, query);
  }

  async trackImage(unsplashImageId: string) {
    return unsplashClient.trackUnsplashPhoto(unsplashImageId);
  }
}

export default new UnsplashImagesService();
