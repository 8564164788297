import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { generateEmptyWorkoutSection } from "../../../../../components/workout/utils";
import { addOperation } from "./operation";

export const addWorkoutSectionAction = {
  prepare(componentId: number, workoutSectionId: number) {
    return {
      payload: {
        componentId,
        workoutSectionId,
        newWorkoutSection: generateEmptyWorkoutSection(2),
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      componentId: number;
      workoutSectionId: number;
      newWorkoutSection;
    }>,
  ) {
    const { componentId, workoutSectionId, newWorkoutSection } = action.payload;

    const component = state.value.components[componentId];
    if (!component) return;

    const updatedContent = addOperation(
      workoutSectionId,
      component.content,
      newWorkoutSection,
    );

    state.value.components[componentId] = {
      ...component,
      content: updatedContent,
    };
  },
};
