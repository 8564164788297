import React from "react";
import { CoachClientsFormsListScreen } from "../../../../components/coach-client-forms/CoachClientsFormsListScreen";

export function CoachClientsFormsRoute() {
  return (
    <>
      <CoachClientsFormsListScreen />
    </>
  );
}
