import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { CardMedia } from "../card/CardMedia";
import { CustomAssetMenu } from "../menu/CustomAssetMenu";
import { useThumbnail } from "../../hooks/useThumbnail";
import { DraggableSnippet } from "../editor/components/utils/DraggableSnippet";
import { MoreMenuButton } from "../button/MoreMenuButton";

import { LibraryAssetType } from "./constants";
import { AssetFileIcon } from "./AssetFileIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "grab",
    position: "relative",
  },

  listItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: theme.spacing(8),
    marginRight: theme.spacing(-4),
    paddingRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.selected.light,
    },
  },

  gridItem: {
    display: "flex",
    flexDirection: "column",
    width: 204,
    height: 210,
    borderRadius: theme.spacing(0.5),
    overflow: "hidden",
  },

  preview: {
    "$gridItem &": {
      width: "100%",
      height: "100%",
    },

    "$listItem &": {
      minWidth: 32,
      width: 32,
      height: 32,
      marginRight: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",

    "$gridItem &": {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.quote,
      whiteSpace: "nowrap",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),
      backgroundColor: theme.palette.background.paper,
    },

    "$listItem &": {
      width: "100%",
      alignItems: "center",
    },
  },

  name: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",

    "$listItem &": {
      maxWidth: 320,
    },
  },
  ext: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.quote,
    textTransform: "uppercase",
    marginLeft: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: "100%",

    "$listItem &": {
      borderRadius: theme.spacing(0.5),
    },
  },
  icon: {
    "$gridItem &": {
      background: theme.palette.selected.light,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 0,
      borderStyle: "solid",
      borderColor: theme.palette.quote,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    },
  },

  moreButton: {
    position: "absolute",
    opacity: 0,

    "$listItem &": {
      right: theme.spacing(4),
    },

    "$gridItem &": {
      top: theme.spacing(1),
      right: theme.spacing(1.25),
    },

    "$root:hover &": {
      opacity: 1,
    },
  },
}));

export enum ComponentLibraryAssetListMode {
  LIST = "list",
  GRID = "grid",
}

export const getAssetInfo = ({
  assetType,
  content,
}: {
  assetType: LibraryAssetType;
  content: string;
}) => {
  const element = JSON.parse(content);
  const [, name, ext] = element.name?.match(/(.*)\.([a-zA-Z0-9]+)$/) || [
    element.name,
    null,
  ];
  const image = [LibraryAssetType.IMAGE, LibraryAssetType.VIDEO].includes(
    assetType as LibraryAssetType,
  )
    ? element.url
    : null;

  return {
    name,
    image,
    ext,
    element,
  };
};

export interface Asset {
  id: number;
  assetType: LibraryAssetType;
  content: string;
}

export interface ComponentLibraryAssetProps extends BoxProps {
  asset: Asset;
  mode?: ComponentLibraryAssetListMode;
}

export const ComponentLibraryAssetSource = "ComponentLibraryAsset";

export function ComponentLibraryAsset(props: ComponentLibraryAssetProps) {
  const { className, asset, mode, ...other } = props;
  const s = useStyles();

  const { name, image, ext, element } = getAssetInfo(asset);
  const isGrid = mode === ComponentLibraryAssetListMode.GRID;
  const menuState = usePopupState({
    popupId: "custom-asset-menu",
    variant: "popper",
  });
  const thumbnail = useThumbnail(image);

  return (
    <DraggableSnippet
      content={{ source: ComponentLibraryAssetSource, element }}
      draggableContent
      dragButton={isGrid ? null : undefined}
    >
      <Box
        className={clsx(s.root, className, isGrid ? s.gridItem : s.listItem)}
        title={name}
        {...other}
      >
        <Box className={s.preview}>
          {image ? (
            <CardMedia
              image={thumbnail}
              className={s.image}
              size={isGrid ? "normal" : "tiny"}
            />
          ) : (
            ext && (
              <AssetFileIcon
                extension={ext}
                className={s.icon}
                size={isGrid ? 80 : 28}
              />
            )
          )}
        </Box>
        <Box className={s.label}>
          <Typography className={s.name}>{name}</Typography>
          {ext && <Typography className={s.ext}>{ext}</Typography>}
        </Box>

        <MoreMenuButton className={s.moreButton} {...bindTrigger(menuState)} />
        {menuState.isOpen && (
          <CustomAssetMenu assetId={asset.id} {...bindMenu(menuState)} />
        )}
      </Box>
    </DraggableSnippet>
  );
}
