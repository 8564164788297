import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientNutritionCard } from "../client-nutrition-targets/ClientNutritionCard";
import { useParams } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface NutritionTargetProps extends Omit<BoxProps, "onSelect"> {
  handleAddNutrition?: any;
  readonly?: boolean;
}

export function NutritionTargetScreen(props: NutritionTargetProps) {
  const { username } = useParams();
  const currentUser: any = useCurrentUser();
  const { handleAddNutrition, readonly, ...other } = props;
  const s = useStyles();
  // NOTE: If there is no username in parameters, the element is used in new created component.
  // Then, display the current user's nutrition target
  const targetUserName = username || currentUser?.username;
  const clientId = currentUser?.role === UserRole[UserRole.CLIENT] && !username ? currentUser?.id : undefined;

  return (
    <Box className={clsx(s.root)} {...other}>
      <ClientNutritionCard
        client={{ username: targetUserName, clientId }}
        handleAddNutrition={handleAddNutrition}
        isProgram={true}
        readonly={readonly}
      />
    </Box>
  );
}
