import React from "react";
import { useParams } from "react-router-dom";
import { AppLayout } from "../../../components/app/AppLayout";
import { ClientLinkInviteScreen } from "../../../components/auth/ClientLinkInviteScreen";
import { AppThemeProvider } from "../../../components/app/ThemeProvider";
import ErrorPage from "../../../components/app/ErrorPage";
import { HttpError } from "found";
import InvitesService from "../../../services/InvitesService";
import { useMutation } from "@tanstack/react-query";
import { LoadingPage } from "../../../components/loading/LoadingPage";
import { DefaultLoader } from "../../../components/loading/DefaultLoader";

export function InviteRoute() {
  const { code } = useParams();

  const {
    mutate: verifyCode,
    data: invite,
    isSuccess,
    isError,
  } = useMutation({
    mutationKey: ["verify-invite-code"],
    mutationFn: () => InvitesService.verifyInviteCode(code),
  });

  React.useEffect(() => {
    verifyCode();
  }, [verifyCode]);

  return isSuccess ? (
    <AppLayout title="Sign Up" appBar={false}>
      <AppThemeProvider workspace={invite.workspaceInfo}>
        <ClientLinkInviteScreen
          invite={invite}
          resetCode={() => verifyCode()}
        />
      </AppThemeProvider>
    </AppLayout>
  ) : isError ? (
    <ErrorPage error={new HttpError(404)} />
  ) : (
    <DefaultLoader fillScreen />
  );
}
