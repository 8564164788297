import {
  CreateBodyWeightCommand,
  ICreateBodyMeasurementCommand,
  IUpdateBodyMeasurementCommand,
  MeasurementUnit,
  UpdateBodyMeasurementCommand,
} from "@growth-machine-llc/stridist-api-client";
import { bodyWeightMeasurementsClient } from "../api/ApiClients";

import ServiceBase from "./ServiceBase";

class BodyWeightMeasurementsService extends ServiceBase {
  async getBodyWeightMeasurements(clientId: number) {
    return bodyWeightMeasurementsClient.getAllWeightMeasurements(clientId);
  }

  async createBodyWeightMeasurement(data: ICreateBodyMeasurementCommand) {
    const command = CreateBodyWeightCommand.fromJS(data);
    return bodyWeightMeasurementsClient.createBodyWeightMeasurement(command);
  }

  async updateBodyWeightMeasurement(data: IUpdateBodyMeasurementCommand) {
    const command = UpdateBodyMeasurementCommand.fromJS(data);
    return bodyWeightMeasurementsClient.updateWeightMeasurement(data.id, command);
  }

  async deleteBodyWeightMeasurement(id: number) {
    return bodyWeightMeasurementsClient.deleteWeightMeasurement(id);
  }
}

export default new BodyWeightMeasurementsService();
