import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, TextField, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppLogo } from "../app/AppLogo";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  AcceptClientInviteInvalidReason,
  planClients,
  Plan,
} from "../../constants";
import { colorSystem } from "../../theme";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { deviceSupports } from "../../utils/device";
import {
  AuthTokenClientSignUpResponse,
  useAuthTokenClientSignUp,
} from "../../hooks/useAuthTokenClientSignUp";
import { iOSMobileApp } from "../../utils/mobile";

import { ClientAcceptInviteInvalid } from "./ClientAcceptInviteInvalid";
import { GoogleButton } from "./GoogleButton";
import { OrDivider } from "./OrDivider";
import { AuthButton } from "./AuthButton";
import { VerifyEmailDialog } from "./VerifyEmailDialog";
import { useSignInMutation } from "./mutations/SignIn";
import { AppleButton } from "./AppleButton";
import { ClientDetailedLinkInviteScreen } from "./ClientDetailedLinkInviteScreen";
import { SIGN_UP_CLIENT_COMPLETE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { urlConstants } from "../../constants/urlConstants";
import { ConsentBanner } from "../app/ConsentBanner";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import {
  EmailLinkResult,
  ValidatedInviteCodeDto,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation } from "@tanstack/react-query";
import InvitesService from "../../services/InvitesService";
import { useInviteClientByCodeMutation } from "./mutations/SendClientInvite";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  groupScreen: {
    width: "100%",
    height: "100%",

    "& $logo, & $poweredBy": {
      display: "none",
    },
  },

  logo: {
    marginBottom: theme.spacing(4),
  },

  content: {
    maxWidth: 457,
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    marginTop: theme.spacing(1.5),
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  divider: {
    marginTop: theme.spacing(3),
  },

  email: {
    marginTop: theme.spacing(3),
  },

  conditions: {
    margin: theme.spacing(2, "auto", 0),
    width: 274,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
}));

export interface ClientLinkInviteScreenProps extends BoxProps {
  invite: ValidatedInviteCodeDto;
  resetCode: () => void;
}

export function ClientLinkInviteScreen(props: ClientLinkInviteScreenProps) {
  const { className, invite, resetCode, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();
  const { brandName } = useCurrentBrand();
  const [email, setEmail] = React.useState("");
  const [verifyEmailDialogOpen, setVerifyEmailDialogOpen] =
    React.useState(false);
  const onError = useGenericErrorHandler({
    disableDefaultSnackbar: iOSMobileApp,
    disableBadRequestSnackbar: false,
  });
  const { showToastAlert } = useToastAlert();

  const {
    sendInvite: inviteClientByCode,
    isPending: inviting,
    nextAttemptIn,
    canSend,
  } = useInviteClientByCodeMutation();

  const { mutate: signIn, isPending: signingIn } = useSignInMutation();

  const clientLimitReached =
    invite?.coachInfo?.clientsCountNoSample >
    Number(planClients(invite?.coachInfo?.plan as Plan, true));

  React.useEffect(() => {
    if (clientLimitReached) {
      showToastAlert("error", {
        message: "Sorry, this coach can't add new clients.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUpWithIdToken = React.useCallback(
    (error, response: AuthTokenClientSignUpResponse) => {
      if (error) {
        if (error.code) {
          showToastAlert("error", {
            message: error.message,
          });
        } else {
          onError(error);
        }
      } else {
        signIn(
          { idToken: response.fireBaseToken },

          {
            onSuccess: () => {
              navigate(SIGN_UP_CLIENT_COMPLETE);
            },
          },
        );
      }
    },
    [onError, signIn, showToastAlert],
  );

  const [handleSignIn, signInInFlight] = useAuthTokenClientSignUp(
    invite.code,
    signUpWithIdToken,
  );

  const pending = inviting || signingIn || signInInFlight;

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [],
  );

  const sendInvite = React.useCallback(
    (callback?: (response?: EmailLinkResult) => void, showAlert?: boolean) => {
      inviteClientByCode(
        {
          email,
          code: invite.code,
        },
        {
          onSuccess: (response, errors) => {
            {
              callback && callback(response);
              if (!showAlert) return;

              if (response.sent) {
                showToastAlert("success", {
                  message: "Email sent successfully",
                });
              } else {
                showToastAlert("error", { message: "Email sending failed" });
              }
            }
          },
        },
      );
    },
    [email, inviteClientByCode, invite.code, onError],
  );

  const resendInvite = React.useCallback(() => {
    sendInvite(() => {}, true);
  }, [sendInvite]);

  const handleEmailSignUp = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      sendInvite(() => {
        setVerifyEmailDialogOpen(true);
      });
    },
    [sendInvite],
  );

  if (user) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.ANOTHER_USER_LOGGED}
        onLogout={resetCode}
      />
    );
  }

  const applyGroupScreen = Boolean(invite?.groupInfo || invite?.programInfo);

  return (
    <ClientDetailedLinkInviteScreen invite={invite} enabled={applyGroupScreen}>
      <Box
        className={clsx(s.root, className, applyGroupScreen && s.groupScreen)}
        {...other}
      >
        <AppLogo className={s.logo} />

        <Box className={s.content}>
          <Typography className={s.title} variant="h1">
            {invite?.coachInfo.displayName} on {brandName}
          </Typography>
          <Typography className={s.subtitle} variant="h5">
            Create a free {brandName} account to join your coach.
          </Typography>

          {(deviceSupports.googleAuthClientSignUpEnabled ||
            deviceSupports.appleAuthClientSignUpEnabled) && (
            <>
              {deviceSupports.appleAuthClientSignUpEnabled && (
                <AppleButton
                  onSuccess={handleSignIn}
                  disabled={pending || clientLimitReached}
                  children="Sign up with Apple"
                />
              )}

              {deviceSupports.googleAuthClientSignUpEnabled && (
                <GoogleButton
                  onSuccess={handleSignIn}
                  disabled={pending || clientLimitReached}
                  children="Sign up with Google"
                />
              )}

              <OrDivider className={s.divider} />
            </>
          )}

          <form onSubmit={handleEmailSignUp}>
            <TextField
              className={s.email}
              name="email"
              type="email"
              label="Email address"
              placeholder="Email address"
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              fullWidth
            />

            <AuthButton
              type="submit"
              children="Sign up with email"
              disabled={!email || pending || clientLimitReached}
            />
          </form>

          <Typography className={s.conditions}>
            By signing up, you agree to our{" "}
            <Link
              href={urlConstants.terms}
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service
            </Link>{" "}
            and{" "}
            <Link
              href={urlConstants.privacy}
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </Link>
            .
          </Typography>
        </Box>
        <ConsentBanner />
      </Box>

      {
        <VerifyEmailDialog
          open={verifyEmailDialogOpen}
          nextAttemptIn={canSend ? undefined : nextAttemptIn}
          disabled={pending || !canSend}
          onResendClick={resendInvite}
        />
      }
    </ClientDetailedLinkInviteScreen>
  );
}
