import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as AttachIcon } from "../../icons/Attachment.svg";
import { useCurrentUserId } from "../../hooks/useCurrentUser";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useUploadFile, UploadedFile } from "../../hooks/useUploadFile";
import { AssetType } from "../../constants";
import { useNativeDropzone } from "../../utils/device";
import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";

const useStyles = makeStyles((theme) => ({
  root: {},

  uploading: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.6),
  },
}));

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_B = MAX_FILE_SIZE_MB * 1024 * 1024;
const getAssetType = ({ type }: File) =>
  /^image\//.test(type) ? AssetType.MESSAGE_IMAGE : AssetType.MESSAGE_FILE;

export interface MessageEditorAttachFileButtonProps extends IconButtonProps {
  onFileUpload?: (file: UploadedFile) => void;
}

export function MessageEditorAttachFileButton(
  props: MessageEditorAttachFileButtonProps,
) {
  const { className, onFileUpload, ...other } = props;
  const s = useStyles();
  const userId = useCurrentUserId();
  const onError = useGenericErrorHandler({});
  const [, uploadManyFiles] = useUploadFile({
    id: userId,
    getAssetType,
    maxFileSize: MAX_FILE_SIZE_B,
  });
  const [uploading, setUploading] = React.useState(false);

  const handleFileUpload = React.useCallback(
    (files) => {
      if (onFileUpload) {
        setUploading(true);
        uploadManyFiles(files)
          .then((uploaded) => uploaded.forEach(onFileUpload))
          .catch(onError)
          .finally(() => setUploading(false));
      }
    },
    [onError, onFileUpload, uploadManyFiles],
  );

  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: handleFileUpload,
    accept: {
      ...getMimeTypes(ElementType.IMAGE),
      ...getMimeTypes(ElementType.VIDEO),
      ...getMimeTypes(ElementType.FILE),
      ...getMimeTypes(ElementType.AUDIO),
    },
  });
  const rootProps = getRootProps();

  return uploading ? (
    <CircularProgress className={clsx(s.uploading, className)} size={34} />
  ) : (
    <IconButton
      className={clsx(s.root, className)}
      onClick={rootProps.onClick}
      disabled={uploading}
      {...other}
      size="large"
    >
      <input type="file" {...getInputProps()} onClick={undefined} />
      <AttachIcon />
    </IconButton>
  );
}
