import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";

import { ActionButton } from "../button/ActionButton";

import { UnsplashImages, UnsplashImagesProps } from "./UnsplashImages";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "9999 !important" as any,
  },

  paper: {
    maxWidth: theme.spacing(108),
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
  },

  subtitle: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: 16,
  },

  titleText: {
    fontSize: 24,
    fontWeight: 600,
  },

  close: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),

    "&:hover": {
      color: theme.palette.text.primary,
    },
  },

  images: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },

  image: {
    height: theme.spacing(18.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  imageSelected: {
    borderWidth: theme.spacing(0.5),
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,

    "& svg": {
      display: "none",
    },
  },

  button: {
    fontWeight: 700,
    fontSize: 16,
  },

  actions: {
    padding: theme.spacing(3),
  },
}));

export interface UnsplashDialogProps
  extends Omit<DialogProps, "onSelect">,
    Pick<UnsplashImagesProps, "onSelect"> {}

export function UnsplashDialog(props: UnsplashDialogProps) {
  const { className, onSelect, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const [image, setImage] = React.useState<string>();

  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const handleSubmit = React.useCallback(
    (event) => {
      if (onSelect) {
        onSelect(image);
      }

      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [image, onClose, onSelect],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      scroll="paper"
      fullWidth
      {...other}
    >
      <DialogTitle>
        <Box className={s.title}>
          <Typography variant="h1" className={s.titleText}>
            Unsplash
          </Typography>
          <IconButton
            className={s.close}
            color="primary"
            children={<Close />}
            onClick={handleClose}
            size="large"
          />
        </Box>
        <Typography className={s.subtitle}>
          Discover beautiful royalty-free images.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <UnsplashImages
          pageSize={30}
          classes={{
            root: s.images,
            image: s.image,
            imageSelected: s.imageSelected,
          }}
          searchInputVariant="outlined"
          searchInputProps={{
            placeholder: "Search free high-resolution images",
          }}
          onSelect={setImage}
        />
      </DialogContent>
      <DialogActions className={s.actions}>
        <ActionButton fullWidth onClick={handleSubmit} disabled={!image}>
          Choose image
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
