import { notificationsSettingsClient } from "../api/ApiClients";
import {
  IUpdateNotificationsSettingsCommand,
  UpdateNotificationsSettingsCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class NotificationsSettingsService extends ServiceBase {
  async getCurrentUserNotificationsSettings() {
    return notificationsSettingsClient.getSettings();
  }

  async updateNotificationsSettings(
    variables: IUpdateNotificationsSettingsCommand,
  ) {
    const command = UpdateNotificationsSettingsCommand.fromJS(variables);

    return notificationsSettingsClient.updateSettings(command);
  }
}

export default new NotificationsSettingsService();
