import { InfiniteData } from "@tanstack/react-query";
import CoachProgramsService from "../../../services/CoachProgramsService";
import {
  COACH_PROGRAMS_LIST_PAGE_SIZE,
  COACH_PROGRAMS_LIST_QUERY_KEY,
} from "../CoachProgramsListScreen";
import {
  DuplicateProgramResponse,
  IDuplicateProgramCommand,
  IPaginatedListOfProgramDto,
  ProgramDto,
} from "@growth-machine-llc/stridist-api-client";
import { useProgramListFilterContext } from "../../../contexts/ProgramListFilterContext";
import { ProgramSort, ProgramStatus } from "../../../constants";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import { createInfinitePaginatedDataUpdater } from "../../../utils/optimisticUpdate";
import { ProgramUpdaterArgs } from "./programUtil";

interface useDuplicateProgramParams {
  status: ProgramStatus;
}
export const useDuplicateProgramMutation = ({
  status,
}: useDuplicateProgramParams) => {
  const { sortKey } = useProgramListFilterContext();

  const optimisticUpdater = (
    data: InfiniteData<IPaginatedListOfProgramDto>,
    variables: IDuplicateProgramCommand,
    tempId: number,
  ) => {
    return createInfinitePaginatedDataUpdater(optimisticItemsUpdater)(
      data,
      {
        programId: variables.programId,
        sortKey: sortKey,
      },
      tempId,
    );
  };
  const succeedUpdater = (
    data: InfiniteData<IPaginatedListOfProgramDto>,
    response: DuplicateProgramResponse,
    tempId: number,
  ) => {
    return createInfinitePaginatedDataUpdater(succeedItemsUpdate)(
      data,
      {
        programId: response.id,
        slug: response.slug,
      },
      tempId,
    );
  };

  return useOptimisticUpdateMutation({
    mutationFn: CoachProgramsService.duplicateProgram,
    queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
    optimisticUpdater: {
      updateFn: optimisticUpdater,
      predicateFn: (queryKey) =>
        predicateQueryKeyByParams(queryKey, {
          status,
        }),
    },
    successUpdater: {
      updateFn: succeedUpdater,
      predicateFn: (queryKey) =>
        predicateQueryKeyByParams(queryKey, {
          status,
        }),
    },
  });
};

const optimisticItemsUpdater = (
  items: ProgramDto[],
  { programId, sortKey: orderBy }: ProgramUpdaterArgs,
  tempId: number,
) => {
  const programIndex = items.findIndex((program) => program.id === programId);
  // Do not update cache if it's last page element index, since it will be fetched on next page
  // TODO_API_V2_PROGRAMS: Handle case when there is no next page, but it will be created after duplication
  const isLastPageElement =
    programIndex === items.length - 1 &&
    items.length % COACH_PROGRAMS_LIST_PAGE_SIZE === 0;

  const programToDuplicate = isLastPageElement ? null : items[programIndex];

  let newDuplicateIndex: number;
  if (orderBy === ProgramSort.DATE_ADDED_ASC) {
    newDuplicateIndex = 0;
  } else if (orderBy === ProgramSort.DATE_ADDED_DESC) {
    newDuplicateIndex = items.length;
  } else {
    newDuplicateIndex = programIndex + 1;
  }

  const newPrograms = programToDuplicate
    ? [
        ...items.slice(0, newDuplicateIndex),
        new ProgramDto({
          ...programToDuplicate,
          id: tempId,
          clients: [],
          enrolledTotal: 0,
        }),
        ...items.slice(newDuplicateIndex),
      ]
    : items;

  return newPrograms;
};

const succeedItemsUpdate = (
  items: ProgramDto[],
  { programId: id, slug }: ProgramUpdaterArgs,
  tempId: number,
) => {
  return items.map((item) => {
    if (item.id === tempId) {
      return ProgramDto.fromJS({ ...item, id: id, slug: slug });
    }
    return item;
  });
};
