import { CustomCellRendererProps } from "@ag-grid-community/react";
import React from "react";
import { RowType } from "../../../constants";
import { Box, IconButton } from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { ComponentIcons, ComponentTypeDefaultIcons } from "../../program/icons";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { SelectComponentIconsMenu } from "../../menu/SelectComponentIconsMenu";
import { useTheme } from "@mui/styles";
import { Link, X } from "lucide-react";
import { colorSystem } from "../../../theme";
import { WorkoutSection } from "../../workout/types";
import MinimizedTooltip, { TooltipLine } from "../../tooltip/MinimizedTooltip";
import { ComponentType } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  bullet: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    flexShrink: 0,

    borderRadius: "50%",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.common.black,
  },

  iconsMenu: {
    width: theme.spacing(40),
  },
}));

const IconCellRenderer = (
  props: CustomCellRendererProps & {
    handleExerciseUpdate: (
      workout: WorkoutSection,
      content?: any,
      wsId?: any,
      id?: any,
    ) => void;
  },
) => {
  const s = useStyles();
  const theme = useTheme();

  const iconsMenuState = usePopupState({
    variant: "popover",
    popupId: "iconsMenu",
  });

  if (props.data.rowType === RowType.WORKOUT_SECTION) {
    return null;
  }

  const isSuperSet = props.data.exerciseActive;
  const isFirst = props.data.index === 0;
  const exercises = props.data.exercises;
  const section = props.data.workoutSectionData;
  const active = props.data.exerciseActive;
  const index = props.data.index;

  const toggleSuperset = () => {
    const left = exercises[index - 1];
    const right = exercises[index];

    props.handleExerciseUpdate(
      {
        ...section,
        exercises: active
          ? exercises.map((it, i) => ({
              ...it,
              superset:
                it.superset === left.superset && i < index
                  ? left.id
                  : it.superset === right.superset && i >= index
                    ? right.id
                    : it.superset,
            }))
          : exercises.map((it) => ({
              ...it,
              superset:
                it.superset === left.superset || it.superset === right.superset
                  ? left.superset
                  : it.superset,
            })),
      },
      props.data.componentContent,
      props.data.workoutSectionData.id,
      props.data.componentId,
    );
  };

  if (props.data.rowType === RowType.EXERCISE) {
    return (
      <Box className="flex h-full flex-col justify-center">
        <Box className={clsx(s.bullet)} />
        <>
          {!isFirst && (
            <Box
              sx={{
                position: "absolute",
                display: "block",
                width: 2,
                height: 28,
                top: -15,
                left: "50%",
                transform: "translate(-50%, 0)",
                backgroundColor: isSuperSet
                  ? theme.palette.common.black
                  : theme.palette.divider,
                zIndex: theme.zIndex.mobileStepper,
              }}
            />
          )}
          {!isFirst && (
            <Box
              sx={{
                position: "absolute",
                top: -40,
                left: "50%",
                transform: "translate(-50%, 0)",
                marginBlock: "18px",
                opacity: 0,
                ":hover": {
                  opacity: 1,
                },
                transition: `${theme.transitions.create(
                  ["border", "color", "background", "opacity"],
                  {
                    duration: theme.transitions.duration.standard,
                    easing: theme.transitions.easing.easeOut,
                  },
                )} !important`,
                zIndex: theme.zIndex.speedDial,
              }}
            >
              <MinimizedTooltip
                title={
                  <TooltipLine>
                    <b>{isSuperSet ? "Remove " : "Create "}</b>superset
                  </TooltipLine>
                }
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleSuperset();
                  }}
                >
                  {isSuperSet ? (
                    <X color={colorSystem.primary} size={14} />
                  ) : (
                    <Link color={colorSystem.primary} size={14} />
                  )}
                </IconButton>
              </MinimizedTooltip>
            </Box>
          )}
        </>
      </Box>
    );
  }

  const Icon =
    ComponentIcons[props.data.iconName] ||
    ComponentTypeDefaultIcons[props.data.type];

  return (
    <>
      <Box
        sx={{
          color: theme.palette.common.black,
          height: 1,
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        {...(props.data.type !== ComponentType.MESSAGE
          ? bindTrigger(iconsMenuState)
          : {})}
      >
        <Icon width={24} />
      </Box>
      <SelectComponentIconsMenu
        {...bindMenu(iconsMenuState)}
        className={s.iconsMenu}
        onSubmit={iconsMenuState.close}
        componentData={{
          id: props.data.componentId,
          type: props.data.type,
          iconName: props.data.iconName,
        }}
      />
    </>
  );
};
export default IconCellRenderer;
