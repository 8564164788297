import { componentsClient } from "../api/ApiClients";
import {
  AddComponentTemplateCommand,
  ArchiveComponentCommand,
  ComponentType,
  CreateComponentCommand,
  DuplicateComponentCommand,
  IAddComponentTemplateCommand,
  IUpdateComponentCommand,
  IUpdateComponentTemplateCommand,
  PasteComponentCommand,
  UpdateComponentCommand,
  UpdateComponentsLockedStatusCommand,
  UpdateComponentTemplateCommand,
  UpdateDefaultIconCommand,
  UpdateComponentsLockedStatusByProgramIdCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";
import { updateComponentDefaultIcon } from "../redux/curriculum/curriculum-slice";

class ComponentsService extends ServiceBase {
  async addComponent(command: CreateComponentCommand) {
    return componentsClient.createComponent(command);
  }

  async archiveComponent(componentId: number) {
    const command = new ArchiveComponentCommand({ id: componentId });
    return componentsClient.archiveComponent(command);
  }

  async getBySlug(slug: string) {
    return componentsClient.getComponent(slug);
  }

  async update(variables: IUpdateComponentCommand) {
    const command = new UpdateComponentCommand(variables);
    return componentsClient.updateComponent(command);
  }

  async updateComponentTemplate(variables: IUpdateComponentTemplateCommand) {
    const command = new UpdateComponentTemplateCommand(variables);
    return componentsClient.updateComponentTemplate(command);
  }

  async getComponentTemplates(type: ComponentType) {
    const pageNumber = 1;
    const pageSize = 999;
    return componentsClient.getComponentTemplatesWithPagination(
      pageNumber,
      pageSize,
      type,
    );
  }

  async removeComponentTemplate(id: string) {
    return componentsClient.removeComponentTemplate(id);
  }

  async getComponentTemplate(id: string) {
    return componentsClient.getComponentTemplate(id);
  }

  async createComponentTemplate(variables: IAddComponentTemplateCommand) {
    const command = AddComponentTemplateCommand.fromJS(variables);
    return componentsClient.addComponentTemplate(command);
  }

  async toggleComponentsLock({
    ids,
    locked,
  }: {
    ids: number[];
    locked: boolean;
  }) {
    const command = new UpdateComponentsLockedStatusCommand({
      idsToLockedStatus: ids.reduce(
        (acc, id) => ({ ...acc, [id]: locked }),
        {},
      ),
    });
    componentsClient.updateComponentsLockedStatusByProgramId
    return componentsClient.updateComponentsLockedStatus(command);
  }

  async togleLockAll(programId: number, locked: boolean) {
    const command = new UpdateComponentsLockedStatusByProgramIdCommand({
      programId,
      locked,
    });
    return componentsClient.updateComponentsLockedStatusByProgramId(command);
  }

  async duplicate(id: number, newWeekId?: number) {
    const command = new DuplicateComponentCommand({ id, newWeekId });
    return componentsClient.duplicateComponent(command);
  }

  async paste(componentId: number, weekId: number, day: number) {
    const command = new PasteComponentCommand({ componentId, weekId, day });
    return componentsClient.pasteComponent(command);
  }

  async remove(id: number) {
    return componentsClient.removeComponent(id);
  }

  async updateDefaultIcon(
    programId: number,
    componentType: ComponentType,
    iconName: string,
  ) {
    const command = new UpdateDefaultIconCommand({
      programId,
      componentType,
      iconName,
    });
    return componentsClient.updateDefaultIcon(command);
  }
}

export default new ComponentsService();
