import React from "react";
import { GroupManageMembersButton } from "../../../../components/group-members/GroupManageMembersButton";
import { GroupMembersScreen } from "../../../../components/group-members/GroupMembersScreen";
import { useCurrentUserRole } from "../../../../hooks/useCurrentUser";
import { UserRole } from "../../../../constants";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useGroupRoute } from "../CoachGroupRouteWrapper";

export function CoachGroupMembersRoute() {
  const USER_ROLE = useCurrentUserRole();
  const isCoach = USER_ROLE === UserRole.COACH;

  const { group } = useGroupRoute();

  return (
    <>
      {isCoach && (
        <GroupManageMembersButton group={group} children="Manage members" />
      )}
      <GroupMembersScreen group={group} />
      <TrackInfoTool
        trackInfo={{
          name: `${isCoach ? "Coach" : "Client"} - Group Members`,
          properties: {
            groupSlug: group.slug,
            groupName: group.name,
          },
        }}
      />
    </>
  );
}
