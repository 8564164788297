import React, { MouseEventHandler } from "react";
import { ChevronUp, ChevronDown, CopyPlus, Trash2 } from "lucide-react";
import { Box, IconButton, useTheme } from "@mui/material";
import MinimizedTooltip, { TooltipLine } from "../tooltip/MinimizedTooltip";

export interface WeekActionItems {
  weekId: number;
  onClickDelete: MouseEventHandler<HTMLElement>;
  onClickMove: MouseEventHandler<HTMLElement>;
  onClickDuplicate: MouseEventHandler<HTMLElement>;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
  canDuplicate?: boolean;
  canDelete?: boolean;
}

const WeekActionItems = (props: WeekActionItems) => {
  const {
    weekId,
    onClickDelete,
    onClickMove,
    onClickDuplicate,
    canMoveUp,
    canMoveDown,
    canDuplicate,
    canDelete,
  } = props;

  const theme = useTheme();

  const assets = [
    {
      icon: <ChevronUp size={18} />,
      onClick: onClickMove,
      disabled: !canMoveUp,
      dataDirection: "up",
      tooltip: (
        <>
          <b>Move</b> week <b>up</b>
        </>
      ),
    },
    {
      icon: <ChevronDown size={18} />,
      onClick: onClickMove,
      disabled: !canMoveDown,
      dataDirection: "down",
      tooltip: (
        <>
          <b>Move</b> week <b>down</b>
        </>
      ),
    },
    {
      icon: <CopyPlus size={18} />,
      onClick: onClickDuplicate,
      disabled: !canDuplicate,
      tooltip: (
        <>
          <b>Duplicate</b> week
        </>
      ),
    },
    {
      icon: <Trash2 size={18} />,
      disabled: !canDelete,
      onClick: onClickDelete,
      tooltip: (
        <>
          <b>Delete</b> week
        </>
      ),
    },
  ];
  return (
    <Box
      display="flex"
      color={theme.palette.primary.main}
      sx={{ paddingInline: 1 }}
    >
      {assets.map((item, index) => (
        <MinimizedTooltip
          key={index}
          title={<TooltipLine>{item.tooltip}</TooltipLine>}
        >
          <IconButton
            color="inherit"
            disabled={item.disabled}
            onClick={item.onClick}
            data-id={weekId}
            data-direction={item.dataDirection}
          >
            {item.icon}
          </IconButton>
        </MinimizedTooltip>
      ))}
    </Box>
  );
};

export default WeekActionItems;
