import clsx from "clsx";
import React, { useEffect } from "react";
import {
  BoxProps,
  Button,
  Typography,
  Box,
  SelectProps,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize, startCase } from "lodash";
import omit from "lodash.omit";

import {
  ComponentRepeat,
  ComponentType,
  OnDays,
  ReminderType,
  MessageTimeType,
  messageTimeByType,
} from "../../constants";
import { getDaysInfo } from "../../utils/component";
import { Schedule } from "../schedule/types";
import { colorSystem } from "../../theme";
import { TimeInput } from "../fields/TimeInput";

import {
  ComponentScheduleLayoutExpansion,
  ComponentScheduleLayoutExpansionProps,
} from "./ComponentScheduleLayoutExpansion";
import { ComponentScheduleLayoutFlat } from "./ComponentScheduleLayoutFlat";
import { ProgramWeek } from "../../hooks/useProgramWeeks";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectWeekTempIdByReal } from "../../redux/api/selectors";

interface IUseStylesProps {
  isSidebar: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},

  dayButtons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1.25),
  },

  dayButton: {
    height: 44,
    minWidth: 84,
    borderColor: "transparent",
    borderRadius: theme.spacing(0.5),
    backgroundColor: colorSystem.secondaryGray,
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,

    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      marginBottom: 0,
    },

    "&:hover": {
      backgroundColor: colorSystem.secondaryGray,
    },

    "&$selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: "transparent",

      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  selection: {
    alignItems: "center",
  },

  durationIcon: {
    marginRight: theme.spacing(1),
  },

  repeatIcon: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.5),
  },

  field: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginTop: theme.spacing(3.5),
  },

  label: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },

  unit: {
    marginLeft: theme.spacing(1),
  },

  expandedHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },

  wrapContent: (props: IUseStylesProps) => ({
    display: "flex",
    flexWrap: "wrap",

    [theme.breakpoints.up("md")]: {
      flexWrap: props.isSidebar ? "wrap-reverse" : "nowrap",
      gap: props.isSidebar ? theme.spacing(2) : 0,
    },
  }),

  reminderButton: (props: IUseStylesProps) => ({
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    flexGrow: 1,
    height: 56,
    borderWidth: 2,
    borderColor: theme.palette.common.black,
    borderRadius: theme.spacing(0.5),

    "&:first-child": {
      marginRight: props.isSidebar ? 0 : theme.spacing(2),
    },

    [theme.breakpoints.up("md")]: {
      width: props.isSidebar ? "unset" : 120,
      flexGrow: props.isSidebar ? 1 : 0,
      marginRight: props.isSidebar ? 0 : theme.spacing(2),
    },
  }),

  timeType: {
    width: "100%",
    flexGrow: 1,
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      width: "initial",
      marginTop: 0,
    },
  },

  timeInput: (props: IUseStylesProps) => ({
    flexGrow: 1,
    marginTop: theme.spacing(2),
    width: props.isSidebar ? "100%" : "unset",

    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginLeft: props.isSidebar ? 0 : theme.spacing(2),
    },
  }),

  switchBox: {
    alignItems: "center",
    marginLeft: theme.spacing(-0.5),
  },

  switchText: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: 500,
  },

  selected: {},
}));

const DEFAULT_REMINDER_TIME = "12:00";

export interface ComponentScheduleLayoutProps {
  header: React.ReactNode;
  daysSelection: React.ReactNode;
  customDaysSelection?: React.ReactNode;
  durationSelection?: React.ReactNode;
  repeatSelection?: React.ReactNode;
  weekSelection?: React.ReactNode;
  onSubmit?: BoxProps["onSubmit"];
  subHeader?: React.ReactNode;
  expandedHeader?: React.ReactNode;
  reminderSelection?: React.ReactNode;
  messageTimeSelection?: React.ReactNode;
}

type Layout =
  | "flat"
  | "expansion"
  | "sidebar"
  | React.FC<ComponentScheduleLayoutProps>;

function getInitialOnDays(days: boolean[]): OnDays {
  const count = days.filter(Boolean).length;

  if (count === 7) {
    return OnDays.EVERY_DAY;
  }

  if (count === 1) {
    return OnDays[`DAY${days.findIndex(Boolean) + 1}`];
  }

  return OnDays.CUSTOM;
}

function getInitialMessageTimeType(
  componentType: ComponentType,
  time: string,
): MessageTimeType {
  if (componentType !== ComponentType.MESSAGE) {
    return undefined;
  }

  if (!time) {
    return MessageTimeType.CUSTOM;
  }

  const key = (Object.entries(messageTimeByType).find(
    ([, value]) => value === time,
  ) || [])[0];

  return key ? (key as MessageTimeType) : MessageTimeType.CUSTOM;
}

function getInitialMessageTime(
  time: string,
  messageTimeType: MessageTimeType,
): string {
  return time
    ? time
    : messageTimeType
      ? messageTimeByType[messageTimeType]
      : undefined;
}

export interface ComponentScheduleProps
  extends Omit<BoxProps, "children" | "onChange"> {
  componentType: ComponentType;
  schedule: Schedule;
  onChange: (schedule: Schedule) => void;
  layout: Layout;
  panelOpen?: boolean;
  onPanelChange?: ComponentScheduleLayoutExpansionProps["onChange"];
  disableClickAwayListener?: boolean;
  programWeeks: Array<ProgramWeek>;
}

export function ComponentSchedule(props: ComponentScheduleProps) {
  const {
    componentType,
    schedule,
    onChange,
    onSubmit,
    panelOpen,
    onPanelChange,
    disableClickAwayListener,
    programWeeks,
  } = props;

  const useStylesProps = {
    isSidebar: props.layout === "sidebar",
  };

  const s = useStyles(useStylesProps);
  const [dirty, setDirty] = React.useState(false);
  const [days, setDays] = React.useState<boolean[]>(schedule.days as boolean[]);
  const [onDays, setOnDays] = React.useState<OnDays>(
    getInitialOnDays(schedule.days as boolean[]),
  );
  const [repeat, setRepeat] = React.useState<ComponentRepeat>(
    schedule.repeat as ComponentRepeat,
  );
  const tempWeekId = useCurriculumSelector(
    selectWeekTempIdByReal(schedule.weekId),
  );

  const [weekId, setWeekId] = React.useState<number>(
    tempWeekId ? Number(tempWeekId) : schedule.weekId,
  );
  const [duration, setDuration] = React.useState<number>(schedule.duration);
  const [reminderType, setReminderType] = React.useState<ReminderType>(
    (schedule.reminderType as ReminderType) || undefined,
  );
  const [reminderTime, setReminderTime] = React.useState<string>(
    schedule.reminderTime || "",
  );
  const [messageTimeType, setMessageTimeType] = React.useState<MessageTimeType>(
    getInitialMessageTimeType(componentType, schedule.messageTime),
  );
  const [messageTime, setMessageTime] = React.useState<string>(
    getInitialMessageTime(schedule.messageTime, messageTimeType),
  );

  useEffect(() => {
    setDays(schedule.days);
    setOnDays(getInitialOnDays(schedule.days as boolean[]));
    setRepeat(schedule.repeat as ComponentRepeat);
    setWeekId(tempWeekId ? Number(tempWeekId) : schedule.weekId);
    setDuration(schedule.duration);
    setReminderType((schedule.reminderType as ReminderType) || undefined);
    setReminderTime(schedule.reminderTime || "");
    // setMessageTimeType() and setMessageTime() is ignored to avoid CUSTOM reset
  }, [schedule]);

  const reminder = Boolean(reminderType);

  const headerText = React.useMemo(() => {
    switch (componentType) {
      case ComponentType.LESSON:
        return "When is this lesson due this week?";
      case ComponentType.WORKOUT:
        return "When is this workout due this week?";
      case ComponentType.HABIT:
        return "When should clients track this habit?";
      case ComponentType.CHECKIN:
        return "When is this check-in due?";
      case ComponentType.MESSAGE:
        return "When should this message be sent?";
    }
  }, [componentType]);

  const selectedWeek = programWeeks.find(({ id }) => id === weekId)?.week || 1;
  const selectedDaysText = React.useMemo(() => {
    const daysInfo = getDaysInfo(componentType, days).replace(/-/gi, " to ");

    return `${
      componentType === ComponentType.HABIT ? "Asking" : "Due"
    } Week ${selectedWeek} - ${daysInfo}`;
  }, [componentType, days, selectedWeek]);

  React.useEffect(() => {
    const newReminderTime =
      reminderType === ReminderType.CUSTOM
        ? reminderTime || DEFAULT_REMINDER_TIME
        : "";

    if (newReminderTime !== reminderTime) {
      setReminderTime(newReminderTime);
      setDirty(true);
    }
  }, [reminderTime, reminderType]);

  React.useEffect(() => {
    if (dirty) {
      onChange({
        days,
        duration,
        repeat,
        reminderType,
        reminderTime,
        weekId,
        messageTime,
      });
      setDirty(false);
    }
  }, [
    onChange,
    days,
    duration,
    repeat,
    reminderType,
    reminderTime,
    weekId,
    dirty,
    messageTime,
  ]);

  const handleDurationChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setDuration(parseInt(event.target.value as string));
      setDirty(true);
    },
    [],
  );

  const durationSelection = React.useMemo(
    () => (
      <Box className={s.field}>
        <Typography
          className={s.label}
          variant="body1"
          component="label"
          children="# of weeks"
          htmlFor="duration"
        />
        <FormControl variant="outlined">
          <Select
            id="duration"
            value={duration}
            onChange={handleDurationChange}
            inputProps={{ "aria-label": "duration" }}
            MenuProps={{ disablePortal: true }}
          >
            {Array.from(
              { length: programWeeks.length },
              (x, i: number) => i + 1,
            ).map((value) => (
              <MenuItem
                key={value}
                value={value}
                children={value === 1 ? "This week only" : `${value} weeks`}
              />
            ))}
          </Select>
        </FormControl>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duration, handleDurationChange, programWeeks],
  );

  const handleRepeatChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setRepeat(event.target.value as ComponentRepeat);
      setDirty(true);
    },
    [],
  );

  const repeatSelection = React.useMemo(
    () => (
      <Box className={s.field}>
        <Typography
          className={s.label}
          variant="body1"
          component="label"
          children="Frequency"
          htmlFor="repeat"
        />
        <FormControl variant="outlined">
          <Select
            id="repeat"
            value={repeat}
            onChange={handleRepeatChange}
            inputProps={{ "aria-label": "repeat" }}
            MenuProps={{ disablePortal: true }}
          >
            {Object.values(ComponentRepeat).map((value) => {
              const text =
                value === ComponentRepeat.NONE
                  ? "This week only"
                  : value === ComponentRepeat.BIWEEKLY
                    ? "Every 2 weeks"
                    : capitalize(value.replaceAll("_", " "));

              return <MenuItem key={value} value={value} children={text} />;
            })}
          </Select>
        </FormControl>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleRepeatChange, repeat],
  );

  const handleOnDaysChange: SelectProps["onChange"] = React.useCallback(
    ({ target: { value } }) => {
      setOnDays(value as OnDays);

      switch (value) {
        case OnDays.EVERY_DAY:
          setDays(Array.from({ length: 7 }).map(() => true));
          setDirty(true);
          break;
        case OnDays.CUSTOM:
          break;
        default: {
          const newDays = Array.from({ length: 7 }).map(() => false);
          const index = parseInt((value as string).split(" ")[1]) - 1;
          newDays[index] = true;

          setDays(newDays);
          setDirty(true);
        }
      }
    },
    [],
  );

  const daysSelection = React.useMemo(
    () => (
      <Box className={s.field}>
        <Typography
          className={s.label}
          variant="body1"
          component="label"
          children={
            [OnDays.EVERY_DAY, OnDays.CUSTOM].includes(onDays)
              ? "On days"
              : "On day"
          }
          htmlFor="onDays"
        />
        <FormControl variant="outlined">
          <Select
            id="onDays"
            value={onDays}
            onChange={handleOnDaysChange}
            inputProps={{ "aria-label": "onDays" }}
            MenuProps={{ disablePortal: true }}
          >
            {Object.values(OnDays)
              .filter((value) => {
                switch (value) {
                  case OnDays.EVERY_DAY:
                  case OnDays.CUSTOM:
                    return componentType !== ComponentType.LESSON;
                  default:
                    return true;
                }
              })
              .map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  children={startCase(value.toLowerCase())}
                />
              ))}
          </Select>
        </FormControl>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [componentType, handleOnDaysChange, onDays],
  );

  const handleDayClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, i: number) => {
      // Prevent having no days selected
      if (days[i] && days.filter((day) => Boolean(day)).length === 1) {
        return;
      }

      if (componentType === ComponentType.LESSON) {
        setDays((days) => days.map((day, idx) => (idx === i ? true : false)));
      } else {
        setDays((days) => days.map((day, idx) => (idx === i ? !day : day)));
      }
      setDirty(true);
    },
    [days, componentType],
  );

  const customDaysSelection = React.useMemo(
    () => (
      <Box className={s.field}>
        <Typography
          className={s.label}
          variant="body1"
          component="label"
          children="Custom days"
        />
        <Box className={s.dayButtons}>
          {Array.from({ length: 7 }).map((_, i) => (
            <Button
              key={i}
              className={clsx(s.dayButton, days[i] && s.selected)}
              variant="outlined"
              onClick={(event) => handleDayClick(event, i)}
              children={`Day ${i + 1}`}
            />
          ))}
        </Box>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [days, handleDayClick],
  );

  const handleWeekChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setWeekId(event.target.value as number);
      setDirty(true);
    },
    [],
  );

  const weekSelection = React.useMemo(
    () => (
      <Box className={s.field}>
        <Typography
          className={s.label}
          variant="body1"
          component="label"
          children={
            duration > 1 || repeat !== ComponentRepeat.NONE
              ? "Starting"
              : "On week"
          }
          htmlFor="week"
        />
        <FormControl variant="outlined">
          <Select
            id="week"
            value={weekId}
            onChange={handleWeekChange}
            inputProps={{ "aria-label": "week" }}
            MenuProps={{ disablePortal: true }}
          >
            {programWeeks.map(({ id, week }) => (
              <MenuItem key={id} value={id} children={`Week ${week}`} />
            ))}
          </Select>
        </FormControl>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duration, handleWeekChange, programWeeks, repeat, weekId],
  );

  const header = selectedDaysText;
  const expandedHeader = React.useMemo(
    () => <Typography className={s.expandedHeader}>{headerText}</Typography>,
    [s.expandedHeader, headerText],
  );
  const subHeader =
    componentType === ComponentType.CHECKIN &&
    "You can select one or more days.";

  const handleToggleReminder = React.useCallback(() => {
    setReminderType((reminderType) =>
      reminderType ? undefined : ReminderType.AUTOMATIC,
    );
    setDirty(true);
  }, []);

  const handleReminderTypeChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setReminderType(event.target.value as ReminderType);
      setDirty(true);
    },
    [],
  );

  const handleReminderTimeChange = React.useCallback((event) => {
    setReminderTime(typeof event === "string" ? event : event.target.value);
    setDirty(true);
  }, []);

  const reminderSelection = React.useMemo(
    () => (
      <Box className={s.field}>
        <Typography
          className={s.label}
          variant="body1"
          component="label"
          children="Reminder"
        />
        <Box className={s.wrapContent}>
          <Button
            className={s.reminderButton}
            variant={reminder ? "outlined" : "contained"}
            children="No"
            onClick={reminder ? handleToggleReminder : undefined}
          />
          <Button
            className={s.reminderButton}
            variant={reminder ? "contained" : "outlined"}
            children="Yes"
            onClick={reminder ? undefined : handleToggleReminder}
          />

          <FormControl className={s.timeType} variant="outlined">
            <Select
              id="reminderType"
              value={reminderType}
              onChange={handleReminderTypeChange}
              inputProps={{ "aria-label": "reminderType" }}
              disabled={!reminder}
              MenuProps={{ disablePortal: true }}
            >
              {!reminder && (
                <MenuItem value={undefined} children="No reminder time" />
              )}
              {reminder &&
                Object.values(ReminderType).map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    children={capitalize(value)}
                  />
                ))}
            </Select>
          </FormControl>

          {reminder && reminderType === ReminderType.CUSTOM && (
            <TimeInput
              className={s.timeInput}
              value={reminderTime}
              onChange={handleReminderTimeChange}
              disabled={!reminder}
            />
          )}
        </Box>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      reminder,
      handleToggleReminder,
      reminderType,
      handleReminderTypeChange,
      reminderTime,
      handleReminderTimeChange,
    ],
  );

  const handleMessageTimeTypeChange: SelectProps["onChange"] =
    React.useCallback((event) => {
      const type = event.target.value as MessageTimeType;

      setMessageTimeType(type);
      setMessageTime(messageTimeByType[type]);
      setDirty(true);
    }, []);

  const handleMessageTimeChange = React.useCallback((event) => {
    setMessageTime(typeof event === "string" ? event : event.target.value);
    setDirty(true);
  }, []);

  const messageTimeSelection = React.useMemo(
    () => (
      <Box className={s.field}>
        <Typography
          className={s.label}
          variant="body1"
          component="label"
          children="Time of day"
        />
        <Box className={s.wrapContent}>
          <FormControl className={s.timeType} variant="outlined">
            <Select
              id="messageTimeType"
              value={messageTimeType}
              onChange={handleMessageTimeTypeChange}
              inputProps={{ "aria-label": "messageTimeType" }}
              MenuProps={{ disablePortal: true }}
            >
              {Object.values(MessageTimeType).map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  children={capitalize(value)}
                />
              ))}
            </Select>
          </FormControl>

          {messageTimeType === MessageTimeType.CUSTOM && (
            <TimeInput
              className={s.timeInput}
              value={messageTime}
              onChange={handleMessageTimeChange}
            />
          )}
        </Box>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      messageTimeType,
      handleMessageTimeTypeChange,
      messageTime,
      handleMessageTimeChange,
    ],
  );

  const layoutProps = {
    header,
    expandedHeader,
    daysSelection,
    customDaysSelection:
      onDays === OnDays.CUSTOM ? customDaysSelection : undefined,
    durationSelection:
      [ComponentType.HABIT, ComponentType.WORKOUT].includes(componentType) &&
      // TODO sidebar doesn't support saving with "publish" status, so we hide the picker
      // until component scheduling refactoring without component duplication
      props.layout !== "sidebar" &&
      durationSelection,
    repeatSelection:
      [ComponentType.CHECKIN, ComponentType.MESSAGE].includes(componentType) &&
      repeatSelection,
    weekSelection,
    onSubmit,
    subHeader,
    reminderSelection:
      [
        ComponentType.HABIT,
        ComponentType.CHECKIN,
        ComponentType.WORKOUT,
      ].includes(componentType) && reminderSelection,
    messageTimeSelection:
      componentType === ComponentType.MESSAGE && messageTimeSelection,
    componentType,
  };

  switch (props.layout) {
    case "flat":
      return (
        <ComponentScheduleLayoutFlat
          {...omit(layoutProps, [
            "expandedHeader",
            "subHeader",
            "componentType",
            "reminderSelection",
            "messageTimeSelection",
          ])}
        />
      );
    case "sidebar":
    case "expansion":
      return (
        <ComponentScheduleLayoutExpansion
          {...layoutProps}
          open={panelOpen}
          onChange={onPanelChange}
          layout={props.layout}
          disableClickAwayListener={disableClickAwayListener}
        />
      );
    default:
      return <props.layout {...layoutProps} />;
  }
}
