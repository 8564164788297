import clsx from "clsx";
import React from "react";
import {
  TableRow,
  TableRowProps,
  TableCell,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardRounded as ArrowForwardIcon } from "@mui/icons-material";
import {
  usePopupState,
  bindMenu,
  bindTrigger,
} from "material-ui-popup-state/hooks";

import { ReactComponent as ClientFormIcon } from "../../icons/TaskListEdit.svg";
import { maybePluralize } from "../../utils/text";
import { defaultClientFormName } from "../client-forms/constants";
import { MoreMenuButton } from "../button/MoreMenuButton";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/ArchiveOutline.svg";
import { Menu } from "../menu/Menu";

import { useNavigate } from "react-router-dom";
import {
  ClientFormCoachListDto,
  PaginatedListOfClientFormCoachListDto,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ClientFormsCoachService from "../../services/ClientFormsCoachService";
import {
  isOptimisticUpdateTempId,
  useOptimisticUpdateMutation,
} from "../../hooks/useOptimisticUpdateMutation";
import { CLIENT_FORMS_LIST_QUERY_KEY } from "./CoachClientsFormsListScreen";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",

    "& td": {
      fontSize: 14,
      fontWeight: 500,
    },
  },

  nameWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(3),

    "& svg": {
      marginRight: theme.spacing(1),
    },
  },

  createdAtWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.spacing(2),

    "& svg": {
      color: theme.palette.text.secondary,
    },
  },

  buttons: {
    display: "flex",
    alignItems: "center",
  },

  open: {},

  more: {
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
    opacity: 0,

    "$root:hover &, &$open": {
      opacity: 1,
    },

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}));

export interface CoachClientsFormsListItemProps extends TableRowProps {
  clientForm: ClientFormCoachListDto;
  currentPage: number;
}

export function CoachClientsFormsListItem(
  props: CoachClientsFormsListItemProps,
) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const s = useStyles();
  const { className, clientForm, currentPage, ...other } = props;
  const { id, slug, name, totalQuestions, created } = clientForm;
  const url = `/coach/clients/forms/${slug}`;
  const { isPending: duplicateClientFormInFlight, mutate: duplicateForm } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_FORMS_LIST_QUERY_KEY],
      mutationFn: ClientFormsCoachService.duplicateForm,
      optimisticUpdater: {
        updateFn: (
          oldData: PaginatedListOfClientFormCoachListDto,
          id,
          tempId,
        ) => {
          const item = { ...oldData.items.find((item) => item.id === id) };
          item.id = tempId;
          item.created = dayjs();
          oldData.items.unshift(ClientFormCoachListDto.fromJS(item));
          return oldData;
        },
      },
      options: {
        onSuccess: (data, _, context: any) => {
          queryClient.setQueriesData(
            {
              queryKey: [CLIENT_FORMS_LIST_QUERY_KEY],
            },
            (oldData: PaginatedListOfClientFormCoachListDto) => {
              const item = oldData.items.find(
                (item) => item.id === context.tempId,
              );
              item.id = data.id;
              item.slug = data.slug;
              return oldData;
            },
          );
        },
      },
    });
  const { isPending: deleteClientFormInFlight, mutate: deleteForm } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_FORMS_LIST_QUERY_KEY],
      mutationFn: ClientFormsCoachService.deleteForm,
      optimisticUpdater: {
        updateFn: (oldData: PaginatedListOfClientFormCoachListDto, id) => {
          oldData.items = oldData.items.filter((item) => item.id !== id);
          return oldData;
        },
      },
    });
  const menuState = usePopupState({
    variant: "popover",
    popupId: "moreMenu",
  });

  const handleClick = React.useCallback(() => {
    navigate(url);
  }, [url]);

  const handleMoreClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      menuState.open(event);
    },
    [menuState],
  );

  const handleAfterMenuAction = React.useCallback(() => {
    menuState.close();
  }, [queryClient, menuState]);

  const handleDuplicateClientForm = React.useCallback(
    () => duplicateForm(id, { onSuccess: handleAfterMenuAction }),
    [duplicateForm, id, menuState],
  );

  const handleRemoveClientForm = React.useCallback(
    () => deleteForm(id, { onSuccess: handleAfterMenuAction }),
    [id, menuState, deleteForm],
  );

  const inFlight = deleteClientFormInFlight || duplicateClientFormInFlight;

  return (
    <>
      <OptimisticUpdateContainer
        component="tr"
        className={clsx(s.root, className)}
        onClick={handleClick}
        {...other}
        id={id}
      >
        <TableCell>
          <Box className={s.nameWrapper}>
            <ClientFormIcon />
            {name || defaultClientFormName}
          </Box>
        </TableCell>
        <TableCell>{maybePluralize(totalQuestions, "question")}</TableCell>
        <TableCell>
          <Box className={s.createdAtWrapper}>
            {created.format("MMM DD, YYYY")}

            <Box className={s.buttons}>
              <MoreMenuButton
                styleVariant="inverse"
                className={clsx(s.more, menuState.isOpen && s.open)}
                {...bindTrigger(menuState)}
                onClick={handleMoreClick}
              />
              <ArrowForwardIcon />
            </Box>
          </Box>
        </TableCell>
      </OptimisticUpdateContainer>
      {menuState.isOpen && (
        <OptimisticUpdateContainer id={id}>
          <Menu {...bindMenu(menuState)}>
            <MenuItem disabled={inFlight} onClick={handleDuplicateClientForm}>
              <ListItemIcon children={<DuplicateIcon />} />
              <ListItemText primary="Duplicate" />
            </MenuItem>

            <MenuItem disabled={inFlight} onClick={handleRemoveClientForm}>
              <ListItemIcon children={<ArchiveIcon />} />
              <ListItemText primary="Delete" />
            </MenuItem>
          </Menu>
        </OptimisticUpdateContainer>
      )}
    </>
  );
}
