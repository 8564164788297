import React, { useContext, useEffect } from "react";
import { GroupSort, GroupStatus, NavigationItem } from "../../../constants";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_GROUPS_ARCHIVED_ROUTE,
} from "../../routes";
import { CreateGroupAction } from "../../../components/groups/CreateGroupAction";
import { GroupList } from "../../../components/groups/GroupList";
import GroupsContext from "../../../contexts/GroupsContext";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { useQuery } from "@tanstack/react-query";
import GroupsService from "../../../services/GroupsService";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { BasicSkeletonCard } from "../../../components/loading/BasicSkeletonCard";

export const groupsNavigation: NavigationItem[] = [
  {
    name: "Active",
    link: COACH_GROUPS_ACTIVE_ROUTE,
  },

  {
    name: "Archived",
    link: COACH_GROUPS_ARCHIVED_ROUTE,
  },
];

const getStatusFromPath = (path: string) => {
  switch (path) {
    case COACH_GROUPS_ACTIVE_ROUTE:
      return GroupStatus.ACTIVE;
    case COACH_GROUPS_ARCHIVED_ROUTE:
      return GroupStatus.ARCHIVED;

    default:
      return GroupStatus.ACTIVE;
  }
};

export const COACH_GROUPS_LIST_QUERY_KEY = "coach-groups";
const pageSize = 5;

export function CoachGroupsListRoute() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const sortKey = searchParams.get("sort");
  const page = (searchParams.get("page") as unknown as number) ?? 1;
  const orderBy = sortKey ?? (GroupSort.NAME_ASC as GroupSort);
  const status = getStatusFromPath(location.pathname);

  const context = useContext(GroupsContext);
  const { data, isLoading } = useQuery({
    queryKey: [COACH_GROUPS_LIST_QUERY_KEY, { page, status, orderBy }],
    queryFn: () =>
      GroupsService.getGroups(status, orderBy as GroupSort, page, pageSize),
  });

  React.useEffect(() => {
    data && context.setCoachGroups(data);
  }, [data]);

  return (
    <>
      {!isLoading && (
        <>
          <GroupList groups={data} />
          <TrackInfoTool
            trackInfo={{
              name: "Coach - Groups",
            }}
          />
        </>
      )}
      {isLoading && (
        <PageSkeleton fullWidth ItemComponent={BasicSkeletonCard} />
      )}
    </>
  );
}
