import React from "react";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Slide,
  Divider,
  useTheme,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack } from "@mui/icons-material";

import { ComponentStatus, ComponentType } from "../../constants";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/ArchiveOutline.svg";
import { ReactComponent as ChangeIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as MoveIcon } from "../../icons/DataTransferVertical.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as CopyIcon } from "../../icons/CopyOutline.svg";
import { ReactComponent as ImageIcon } from "../../icons/streamline-icon-common-file-horizontal-image.svg";
import { ReactComponent as FlashIcon } from "../../icons/streamline-icon-flash.svg";
import { Menu, MenuProps } from "../menu/Menu";
import { SelectComponentIcons } from "../program-component/SelectComponentIcons";
import { SelectComponentWeek } from "../program-component/SelectComponentWeek";
import { CopyComponentDialog } from "../dialog/CopyComponentDialog";
import { ChangeComponentCoverDialog } from "../dialog/ChangeComponentCoverDialog";
import { useCopyCalendarComponent } from "../../hooks/useCopyCalendarComponent";

import { LockOpen, Lock } from "lucide-react";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import { useCurriculumDispatch } from "../../redux/hooks";
import {
  archiveComponent,
  duplicateComponent,
  removeComponent,
  updateComponentWeek,
  updateComponentCover,
  lockComponent,
} from "../../redux/curriculum/curriculum-slice";
import { CurriculumComponent } from "../../redux/types";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {},
  back: {
    "& .MuiListItemText-primary": {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 500,
    },
  },
}));

export type MenuState = null | "icons" | "copy" | "move";

export interface WeekComponentMenuProps extends MenuProps {
  componentData: CurriculumComponent;
  view: ProgramDetailsViewMode;
}

export function WeekComponentMenu(props: WeekComponentMenuProps) {
  const dispatch = useCurriculumDispatch();
  const theme = useTheme();
  const { componentData, onClose, view, ...other } = props;
  const component = componentData;
  const s = useStyles();
  const { showToastAlert } = useToastAlert();

  const [menu, setMenu] = React.useState<MenuState>(null);
  const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
  const [openChangeCoverDialog, setOpenChangeCoverDialog] =
    React.useState(false);

  const [, setCopyCalendarComponentId] = useCopyCalendarComponent();

  const componentId = component.id;
  const weekId = component.weekId;

  const componentStatusData = {
    status: component.status,
    previousStatus: component.previousStatus,
  };

  //
  // MENU ACTIONS

  const closeMenu = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleChangeMenu = React.useCallback((event) => {
    const {
      dataset: { menu },
    } = event.currentTarget;

    setMenu(menu);
  }, []);

  //
  // DIALOGS ACTIONS

  const handleCopyToProgramDialogOpen = React.useCallback(() => {
    setOpenCopyDialog(true);
  }, []);

  const handleCopyDialogClose = React.useCallback(
    (event) => {
      setOpenCopyDialog(false);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleChangeCoverDialogOpen = React.useCallback(() => {
    setOpenChangeCoverDialog(true);
  }, []);

  const handleChangeCoverDialogClose = React.useCallback(
    (event) => {
      setOpenChangeCoverDialog(false);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  //
  // COMPONENT ACTIONS

  const handleDuplicateClick = () => {
    closeMenu();
    dispatch(duplicateComponent({ weekId, componentId }));
  };

  const handleToggleArchivedClick = () => {
    closeMenu();
    dispatch(archiveComponent({ weekId, componentId, componentStatusData }));
  };

  const handleRemoveClick = () => {
    closeMenu();
    dispatch(removeComponent({ weekId, componentId }));
  };

  const handleWeekChange = (newWeekId: number) => {
    closeMenu();
    dispatch(updateComponentWeek({ componentId, newWeekId }));
  };

  const handleCopy = (event) => {
    showToastAlert("info", {
      message: "Hold shift to paste to multiple days",
    });

    setCopyCalendarComponentId(
      // Only workout components can be copied at the moment
      component.type === ComponentType.WORKOUT ? component.id : undefined,
    );
    onClose(event, "backdropClick");
  };

  const handleSaveChangeCover = (image: string) => {
    dispatch(updateComponentCover({ componentId, newImage: image }));
  };

  const handleLockClick = () => {
    dispatch(lockComponent({ componentId, currentState: component.locked }));
  };

  return openCopyDialog ? (
    <CopyComponentDialog
      componentData={component}
      open
      onClose={handleCopyDialogClose}
    />
  ) : openChangeCoverDialog ? (
    <ChangeComponentCoverDialog
      componentData={component}
      open
      onClose={handleChangeCoverDialogClose}
      onCoverChange={handleSaveChangeCover}
    />
  ) : (
    <Menu
      className={s.root}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      variant="menu"
      onClose={onClose}
      {...other}
    >
      {!menu ? (
        <Box>
          <MenuItem onClick={handleDuplicateClick}>
            <ListItemIcon children={<DuplicateIcon />} />
            <ListItemText primary="Duplicate" />
          </MenuItem>

          <MenuItem onClick={handleToggleArchivedClick}>
            <ListItemIcon children={<ArchiveIcon />} />
            <ListItemText
              primary={component.status === "ARCHIVED" ? "Restore" : "Archive"}
            />
          </MenuItem>

          {component.status === "ARCHIVED" && (
            <MenuItem onClick={handleRemoveClick}>
              <ListItemIcon children={<BinIcon />} />
              <ListItemText primary="Delete permanently" />
            </MenuItem>
          )}

          {component.type !== ComponentType.MESSAGE && (
            <MenuItem onClick={handleChangeMenu} data-menu="icons">
              <ListItemIcon
                sx={{ color: theme.palette.common.black }}
                children={<ChangeIcon />}
              />
              <ListItemText primary="Change icon" />
            </MenuItem>
          )}

          {view === ProgramDetailsViewMode.CALENDAR &&
            component.type === ComponentType.WORKOUT && (
              <MenuItem onClick={handleCopy}>
                <ListItemIcon children={<FlashIcon />} />
                <ListItemText primary="Copy" />
              </MenuItem>
            )}

          {/* TODO_API_V2_CURRICULUM handle copy to program (CopyComponentDialog migration) */}
          <MenuItem onClick={handleCopyToProgramDialogOpen}>
            <ListItemIcon children={<CopyIcon />} />
            <ListItemText primary="Copy to another program" />
          </MenuItem>

          <MenuItem onClick={handleChangeMenu} data-menu="move">
            <ListItemIcon children={<MoveIcon />} />
            <ListItemText primary="Change start week" />
          </MenuItem>

          {component.type !== ComponentType.MESSAGE && (
            <MenuItem onClick={handleChangeCoverDialogOpen}>
              <ListItemIcon
                sx={{ color: theme.palette.common.black }}
                children={<ImageIcon />}
              />
              <ListItemText primary="Change cover image" />
            </MenuItem>
          )}
          <Tooltip
            arrow
            placement="left"
            title="Prevent clients from viewing this until the scheduled date."
          >
            <MenuItem onClick={handleLockClick}>
              <ListItemIcon
                sx={{ color: theme.palette.common.black }}
                children={component.locked ? <LockOpen /> : <Lock />}
              />
              <ListItemText
                primary={component.locked ? "Unlock content" : "Lock content"}
              />
            </MenuItem>
          </Tooltip>
        </Box>
      ) : (
        <Slide in={true} direction="left" mountOnEnter unmountOnExit>
          <Box>
            <MenuItem className={s.back} onClick={handleChangeMenu}>
              <ListItemIcon children={<ArrowBack />} />
              <ListItemText
                primary={
                  menu === "icons"
                    ? "Select icon"
                    : menu === "move"
                      ? "Select week"
                      : "Select course"
                }
              />
            </MenuItem>
            <Divider />
            {menu === "icons" ? (
              <SelectComponentIcons
                componentData={component}
                onSubmit={closeMenu}
              />
            ) : menu === "move" ? (
              <SelectComponentWeek
                weekId={component.weekId}
                onChange={handleWeekChange}
              />
            ) : (
              "TODO: course menu (ui needed)"
            )}
          </Box>
        </Slide>
      )}
    </Menu>
  );
}
