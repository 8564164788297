import {
  AssignTagsToClientCommand,
  CreateTagCommand,
  IAssignTagsToClientCommand,
  ICreateTagCommand,
} from "@growth-machine-llc/stridist-api-client";
import { tagsClient, clientTagsClient } from "../api/ApiClients";

import ServiceBase from "./ServiceBase";

class TagsService extends ServiceBase {
  async getTags() {
    return tagsClient.getTags();
  }
  async createTag(variables: ICreateTagCommand) {
    const command = new CreateTagCommand(variables);
    return tagsClient.createTag(command);
  }
  async deleteTag(id: number) {
    return tagsClient.deleteTag(id);
  }

  async assignTagsToClient(variables: IAssignTagsToClientCommand) {
    const command = new AssignTagsToClientCommand(variables);
    return clientTagsClient.assignTagsToClient(command);
  }
}

export default new TagsService();
