import React from "react";
import { CircularProgress, CircularProgressProps } from "@mui/material";

export const CircularLoader = ({
  color = "secondary",
  size = 16,
  ...other
}: CircularProgressProps) => {
  return (
    <CircularProgress
      color={color}
      size={size}
      sx={{
        opacity: 0.25,
      }}
      {...other}
    />
  );
};
