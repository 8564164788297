import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { GroupDescription } from "./GroupDescription";
import { GroupSidebarMembers } from "./GroupSidebarMembers";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  description: {
    marginBottom: theme.spacing(5),
  },
}));

export interface GroupPostsSidebarProps extends BoxProps {
  group: GroupDto2;
}

export function GroupPostsSidebar(props: GroupPostsSidebarProps) {
  const { className, group, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <GroupDescription className={s.description} group={group} />
      <GroupSidebarMembers
        members={group.members}
        totalMembersCount={group.totalMembersCount}
      />
    </Box>
  );
}
