import React from "react";
import ReactTimeago from "react-timeago";

export const TimeAgo = ({ date }) => {
  const customFormatter = (
    value,
    unit,
    suffix,
    epochMilliseconds,
    nextFormatter,
  ) => {
    if (unit === "second" && value < 60) {
      return "just now";
    }
    if (unit === "minute" && value < 5) {
      return "a few minutes ago";
    }

    return nextFormatter();
  };

  return <ReactTimeago date={date} formatter={customFormatter} />;
};
