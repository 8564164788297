import React from "react";

import { UpdateLocation } from "./UpdateLocation";
import { useLocation } from "react-router-dom";

type RefreshSlugInfo = {
  slugId: string;
  slug: string;
};
export interface RefreshSlugProps {
  slugInfo: RefreshSlugInfo | RefreshSlugInfo[];
}

export function RefreshSlug({ slugInfo }: RefreshSlugProps) {
  const location = useLocation();
  const nodes = Array.isArray(slugInfo) ? slugInfo : [slugInfo];
  const pathname = location.pathname
    .split("/")
    .map(
      (chunk) =>
        nodes.find(({ slugId }) => chunk.endsWith(slugId))?.slug || chunk,
    )
    .join("/");

  return (
    pathname !== location.pathname && <UpdateLocation pathname={pathname} />
  );
}
