import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CoachClientCalendar } from "../coach-client-calendar/CoachClientCalendar";
import {
  ProgramDetailsFilters,
  defaultFilters,
} from "../program/ProgramDetailsFilters";
import { MonthSelect } from "../fields/MonthSelect";
import { getISODate } from "../../utils/date";
import { EnrollmentsForCalendarDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },

  filters: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(2.5, 0),

    "& > :nth-child(1)": {
      marginLeft: theme.spacing(-2),
    },
  },
}));

export interface CoachClientCalendarScreenProps
  extends Omit<ContainerProps, "children"> {
  enrollments: EnrollmentsForCalendarDto[];
  isLoading: boolean;
}

export function CoachClientCalendarScreen(
  props: CoachClientCalendarScreenProps,
) {
  const { className, enrollments, isLoading, ...other } = props;
  const s = useStyles();
  const [date, setDate] = React.useState(getISODate());
  const [filters, setFilters] = React.useState(defaultFilters);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.filters}>
        <MonthSelect onSelect={setDate} date={date} />
        <ProgramDetailsFilters
          filters={filters}
          onChange={setFilters}
          disabledArchived
        />
      </Box>
      <CoachClientCalendar
        enrollments={enrollments}
        date={date}
        filters={filters}
        isLoading={isLoading}
      />
    </Container>
  );
}
