export const isLocalEnvironment = () => {
  const { hostname } = window.location;
  return (
    hostname === "local.stridist.com" ||
    hostname === "localhost" ||
    hostname.startsWith("dev.") ||
    hostname.match(/^\d+\.\d+\.\d+\.\d+/)
  );
};

export const isStagingEnvironment = () => {
  const { hostname } = window.location;
  return (
    hostname.startsWith("app.stage.") ||
    hostname.startsWith("new.app.stage.") ||
    hostname.match(/^\d+stage\./) ||
    hostname === "app.stage.stridist.com" ||
    hostname === "test.127.0.0.1.nip.io"
  );
};
