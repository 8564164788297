import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  Typography,
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  Divider,
  Portal,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as MeasurementsChartIcon } from "../../icons/measurementsChart.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";

import { Units } from "../../constants";
import { colorSystem } from "../../theme";

import { ClientMeasurementItem } from "./ClientMeasurementItem";
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { ClientMeasurementsCartDrawer } from "./ClientMeasurementsCartDrawer";
import omit from "lodash.omit";
import { ConfirmActionMeasurements } from "../dialog/ConfirmActionMeasurements";

import { useQuery } from "@tanstack/react-query";
import BodyMeasurementService from "../../services/BodyMeasurementService";
import { BodyMeasurementRecordDto } from "@growth-machine-llc/stridist-api-client";
import { BodyMeasurementSkeletonCard } from "../loading/BodyMeasurementsSkeletonCard";
import { useCurrentUser } from "../../hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    [theme.breakpoints.down("lg")]: {
      fontSize: 22,
    },
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  button: {
    color: colorSystem.black,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(7),
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(3.5),
    },
  },

  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(4),
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  headerIcon: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  content: {
    marginTop: theme.spacing(3.299),
  },
}));

type GroupedMeasurements = {
  [key: string]: BodyMeasurementRecordDto[];
};
interface MetricItem {
  label: string;
  metrics: BodyMeasurementRecordDto[];
}

export interface ClientMeasurementsCardProps extends CardProps {
  clientId: number;
  units?: string;
}

export const CLIENT_BODY_MEASUREMENT_LIST_QUERY_KEY =
  "client-body-measurement-list";

export function ClientMeasurementsCard(props: ClientMeasurementsCardProps) {
  const { className, clientId, units, ...other } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const { data: bodyMeasurementData, isPending: isLoading } = useQuery({
    queryKey: [CLIENT_BODY_MEASUREMENT_LIST_QUERY_KEY, { clientId }],
    queryFn: () => BodyMeasurementService.getBodyMeasurements(clientId),
  });

  const groupedMeasurements: GroupedMeasurements =
    bodyMeasurementData?.measurements?.reduce(
      (acc, { measurementType, records }) => {
        acc[measurementType.toLowerCase()] =
          records as BodyMeasurementRecordDto[];
        return acc;
      },
      {} as GroupedMeasurements,
    );

  const hasRecords = groupedMeasurements
    ? Object.values(groupedMeasurements).some(
        (records) => records && records.length > 0,
      )
    : false;

  const { bodyfat, shoulder, chest, waist, hip, thigh, upper_arm, calf } =
    groupedMeasurements || {};

  const [measurementsModal, setMeasurementsModal] = React.useState(false);
  const [selectItemIndex, setSelectItemIndex] = React.useState(null);
  const [isOpenContent, setIsOpenContent] = React.useState(true);

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  const handleOpenCharts = React.useCallback((index) => {
    setSelectItemIndex(index);
    setMeasurementsModal(true);
  }, []);

  const handleCloseCharts = React.useCallback((index) => {
    setMeasurementsModal(false);
    setSelectItemIndex(null);
  }, []);

  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-measurements",
  });
  const items: MetricItem[] = React.useMemo(() => {
    return [
      { label: "BF (%)", metrics: bodyfat },
      { label: "Shoulder", metrics: shoulder },
      { label: "Chest", metrics: chest },
      { label: "Waist", metrics: waist },
      { label: "Hips", metrics: hip },
      { label: "Thighs", metrics: thigh },
      { label: "Upper arm", metrics: upper_arm },
      { label: "Calfs", metrics: calf },
    ];
  }, [groupedMeasurements]);
  return (
    <>
      <Card className={clsx(s.root, className)} {...other}>
        <Box className={s.header}>
          <Box className={s.headerIcon}>
            <MeasurementsChartIcon className={s.icon} />
            <Box>
              <Typography
                variant="h6"
                className={s.title}
                children="Measurements"
              />
              {isLoading && !smUp ? (
                <Skeleton width={150} height={20} />
              ) : hasRecords ? (
                <Typography variant="body1" className={s.subtitle}>
                  Last recorded{" "}
                  {bodyMeasurementData?.lastUpdatedAt?.format("MMM DD, YYYY")}
                </Typography>
              ) : null}
            </Box>
          </Box>
          {!isLoading &&
            (smUp ? (
              <Button
                className={s.button}
                children="Add new check-in"
                {...bindToggle(drawerState)}
              />
            ) : (
              <ArrowIcon
                className={isOpenContent ? "" : s.arrowIconDown}
                onClick={() => setIsOpenContent(!isOpenContent)}
              />
            ))}
        </Box>
        {isLoading ? (
          <BodyMeasurementSkeletonCard />
        ) : (
          <>
            {hasRecords && isOpenContent ? (
              <Grid className={s.content}>
                {items.map(({ label, metrics }, index) => (
                  <Grid item key={label}>
                    <ClientMeasurementItem
                      metrics={metrics}
                      label={label}
                      units={units as Units}
                      onClick={() => handleOpenCharts(index)}
                    />
                    {index < items.length - 1 && (
                      <Divider className={s.divider} />
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              isOpenContent && (
                <Typography className={s.noText}>
                  No measurements to show.
                </Typography>
              )
            )}
            {!smUp && isOpenContent && (
              <Button
                className={s.button}
                children="Add new check-in"
                {...bindToggle(drawerState)}
              />
            )}
          </>
        )}
      </Card>

      <ConfirmActionMeasurements
        title="Measurements"
        onCancel={handleCloseCharts}
        onConfirm={() => {}}
        open={measurementsModal}
        disabled={false}
        items={items}
        units={units}
        selectItemIndex={selectItemIndex}
        setSelectItemIndex={setSelectItemIndex}
      />
      {drawerState.isOpen && (
        <Portal>
          <ClientMeasurementsCartDrawer
            {...omit(bindPopper(drawerState))}
            onClose={drawerState.close}
            bodyMeasurementData={bodyMeasurementData.measurements}
            units={units as Units}
            clientId={clientId}
          />
        </Portal>
      )}
    </>
  );
}
