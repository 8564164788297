import { clientsClient } from "../api/ApiClients";
import {
  AddClientsWithoutInviteCommand,
  ArchiveClientCommand,
  BatchArchiveClientsCommand,
  BatchDeleteClientsCommand,
  ClientSort,
  IAddClientsWithoutInviteCommand,
  IArchiveClientCommand,
  IBatchArchiveClientsCommand,
  IBatchDeleteClientsCommand,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";

import ServiceBase from "./ServiceBase";

type TargetClientParams = {
  groupId?: number;
  programId?: number;
  clientFormId?: number;
  searchQuery?: string;
  statuses?: UserInviteStatus[];
};
class ClientsService extends ServiceBase {
  async getAllClients(
    status: UserInviteStatus[],
    sort: ClientSort = ClientSort.NAME_ASC,
    query?: string,
    tag?: number,
  ) {
    return clientsClient.getClientsPaginated(
      1,
      9999,
      status,
      sort,
      undefined,
      undefined,
      query,
      tag,
    );
  }

  async getClientsFiltered(
    page: number,
    pageSize: number,
    status: UserInviteStatus[],
    sort: ClientSort = ClientSort.NAME_ASC,
    query?: string,
    tag?: number,
  ) {
    return clientsClient.getClientsPaginated(
      page,
      pageSize,
      status,
      sort,
      undefined,
      undefined,
      query,
      tag,
    );
  }

  async getStats(ids: number[]) {
    return clientsClient.getClientsStats(ids);
  }

  async getBriefClientInfo(username: string) {
    return clientsClient.getBriefClientInfo(username);
  }

  async archiveClient(data: IArchiveClientCommand) {
    const command = ArchiveClientCommand.fromJS(data);
    return clientsClient.archiveClient(command);
  }

  async batchArchiveClients(data: IBatchArchiveClientsCommand) {
    const command = BatchArchiveClientsCommand.fromJS(data);
    return clientsClient.batchArchiveClients(command);
  }

  async addClients(data: IAddClientsWithoutInviteCommand) {
    const command = AddClientsWithoutInviteCommand.fromJS(data);
    return clientsClient.addClientsWithoutInvite(command);
  }

  async revokeAddedClient(id: number) {
    return clientsClient.revokeAddedClient(id);
  }

  async deleteClient(id?: number) {
    return clientsClient.deleteClient(id);
  }

  async batchDeleteClients(data: IBatchDeleteClientsCommand) {
    const command = BatchDeleteClientsCommand.fromJS(data);
    return clientsClient.batchDeleteClients(command);
  }

  async getTargetClients(
    pageNumber: number,
    pageSize: number,
    params: TargetClientParams,
  ) {
    const { groupId, programId, clientFormId, statuses, searchQuery } = params;
    return clientsClient.getTargetClients(
      pageNumber,
      pageSize,
      groupId,
      programId,
      clientFormId,
      statuses,
      searchQuery,
    );
  }
}

export default new ClientsService();
