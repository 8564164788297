import clsx from "clsx";
import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import {
  SortableListItem,
  SortableListItemProps,
} from "../sortable-list/SortableListItem";

import { ClientGoalDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  name: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.common.black,
  },

  date: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },
}));

export interface ClientGoalListItemProps
  extends SortableListItemProps<ClientGoalDto> {}

export function ClientGoalListItem(props: ClientGoalListItemProps) {
  const { className, ...other } = props;
  const {
    option: { name, date },
  } = other;
  const formattedDate = date?.format("MMM D, YYYY");
  const s = useStyles();

  return (
    <SortableListItem
      className={clsx(s.root, className)}
      actions={[{ name: "edit", Icon: EditIcon }]}
      {...other}
    >
      <Box>
        <Typography className={s.name} variant="body1" children={name} />
        <Typography
          className={s.date}
          variant="body1"
          children={formattedDate}
        />
      </Box>
    </SortableListItem>
  );
}
