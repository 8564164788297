import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, IconButton, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { ReactComponent as AddIcon } from "../../icons/AddCircleOutline.svg";
import { AddComponentMenu } from "../program/AddComponentMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },
  },

  button: {
    color: theme.palette.primary.main,
    height: 50,

    [theme.breakpoints.up("md")]: {
      height: theme.spacing(8.75),
      fontSize: 20,
      "& svg": {
        width: 32,
        height: 32,
      },
    },

    "& svg": {
      width: 24,
      height: 24,
    },

    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}0F`,
    },
  },

  normal: {
    "& $button": {
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      borderWidth: 2,

      fontSize: 18,
      fontWeight: 600,
      padding: theme.spacing(2.5),
    },

    "& svg": {
      width: 30,
      marginRight: theme.spacing(1),
    },
  },

  icon: {
    "& $button": {
      padding: 0,
      margin: theme.spacing(1, 0.5),
      float: "right",
    },
  },

  active: {
    display: "block",
  },
}));

export interface WeekAddComponentProps
  extends Omit<ButtonProps, "variant" | "size"> {
  variant?: "normal" | "icon";
  weekId: number;
  dayOfWeek?: number;
}

export function WeekAddComponent(props: WeekAddComponentProps) {
  const {
    className,
    children,
    variant = "normal",
    weekId,
    dayOfWeek,
    ...other
  } = props;

  const s = useStyles();
  const ref = React.useRef<HTMLButtonElement>();

  const menuState = usePopupState({
    variant: "popover",
    popupId: "add-component-menu",
  });

  return (
    <Box
      className={clsx(s.root, className, {
        [s.normal]: variant === "normal",
        [s.icon]: variant === "icon",
        [s.active]: menuState.isOpen,
      })}
    >
      {variant === "normal" ? (
        <Button
          ref={ref}
          className={s.button}
          fullWidth
          {...bindTrigger(menuState)}
          {...other}
        >
          <AddIcon />
          Add new component
        </Button>
      ) : (
        <IconButton {...bindTrigger(menuState)} {...other} size="large">
          <AddIcon />
        </IconButton>
      )}

      {menuState.isOpen && (
        <AddComponentMenu
          weekId={weekId}
          dayOfWeek={dayOfWeek}
          arrow="bottom"
          anchorOrigin={{
            horizontal: "center",
            vertical: variant === "normal" ? "top" : "bottom",
          }}
          transformOrigin={{
            vertical: variant === "normal" ? "bottom" : "top",
            horizontal: "center",
          }}
          {...bindMenu(menuState)}
        />
      )}
    </Box>
  );
}
