import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { generateId } from "../../../../../components/new-editor/utils/nodeUtil";
import { duplicateOperation } from "./operation";

export const duplicateWorkoutSectionAction = {
  prepare(componentId: number, workoutSectionId: number) {
    return {
      payload: {
        componentId,
        workoutSectionId,
        newExerciseId: generateId(),
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      componentId: number;
      workoutSectionId: number;
      newExerciseId: number;
    }>,
  ) {
    const { componentId, workoutSectionId, newExerciseId } = action.payload;

    const component = state.value.components[componentId];
    if (!component) return;

    const updatedContent = duplicateOperation(
      workoutSectionId,
      component.content,
      newExerciseId,
    );

    state.value.components[componentId] = {
      ...component,
      content: updatedContent,
    };
  },
};
