import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { useDeleteProgramFolderMutation } from "./mutations/DeleteProgramFolder";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: 524,
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  content: {
    fontSize: 16,
    fontWeight: 500,
  },

  actions: {
    padding: theme.spacing(3),
  },

  button: {
    fontSize: 16,
    fontWeight: 700,
    padding: theme.spacing(1.25),
    borderRadius: theme.spacing(0.5),
    borderStyle: "solid",
    borderWidth: 2,
  },

  cancel: {
    borderColor: theme.palette.secondary.main,
  },

  delete: {
    marginLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },

  close: {
    float: "right",
    padding: theme.spacing(1),
    marginRight: theme.spacing(-1),
  },
}));

export interface ProgramFolderDeleteDialogProps extends DialogProps {
  folderId: number;
  onDeleted?: () => void;
}

export const ProgramFolderDeleteDialog: React.FC<
  ProgramFolderDeleteDialogProps
> = (props) => {
  const { folderId, onDeleted, className, onClose, ...other } = props;
  const s = useStyles();
  const { mutate: deleteFolder, isPending: pending } =
    useDeleteProgramFolderMutation();

  const handleCancelClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const handleDeleteClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }

      deleteFolder(folderId, {
        onSuccess: () => {
          onClose?.({}, "backdropClick");
          onDeleted?.();
        },
      });
    },
    [deleteFolder, folderId, onClose, onDeleted],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      {...other}
    >
      <DialogTitle>
        <IconButton
          className={s.close}
          onClick={handleCancelClick}
          size="large"
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" className={s.title}>
          Are you sure you want to delete this folder?
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography className={s.content}>
          Programs assigned to this folder will be ungrouped.
        </Typography>
      </DialogContent>

      <DialogActions className={s.actions}>
        <Button
          className={clsx(s.button, s.cancel)}
          variant="outlined"
          onClick={handleCancelClick}
          disabled={pending}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          className={clsx(s.button, s.delete)}
          variant="contained"
          onClick={handleDeleteClick}
          disabled={pending}
          fullWidth
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
