import React from "react";

import { AuthTokenData } from "../utils/auth";

import { AuthTokenSignInResponse } from "./useAuthTokenSignIn";
import firebase from "firebase";
import dayjs from "dayjs";
import { useMutation } from "@tanstack/react-query";
import SignUpService from "../services/SignUpService";

export type AuthTokenClientSignUpResponse = AuthTokenSignInResponse;

export const useAuthTokenClientSignUp = (
  code: string,
  callback?: (
    error: Error | null,
    response?: AuthTokenClientSignUpResponse,
  ) => void,
) => {
  const { mutate: signUpClientWithAuthToken, isPending: inFlight } =
    useMutation({
      mutationKey: ["sign-up-client-with-auth-token"],
      mutationFn: SignUpService.clientSignUpWithInviteCode,
    });

  const [loading, setLoading] = React.useState(false);
  const disabled = inFlight || loading;

  const signIn = React.useCallback(
    (data: AuthTokenData) =>
      signUpClientWithAuthToken(
        {
          code,
          token: data.id_token,
          timezone: dayjs.tz.guess(),
        },
        {
          onSuccess: ({ customToken }) => {
            setLoading(true);

            firebase
              .auth()
              .signInWithCustomToken(customToken)
              .then((credential) => credential.user.getIdToken())
              .then((fireBaseToken) => {
                setLoading(false);
                callback(null, {
                  fireBaseToken,
                  data,
                });
              })
              .catch((error) => {
                setLoading(false);
                callback(error);
              });
          },
          onError: (error) => {
            console.log("error", error);
            callback(error as any);
          },
        },
      ),
    [callback, code, signUpClientWithAuthToken],
  );

  return [signIn, disabled] as const;
};
