import { call, take, actionChannel, put } from "redux-saga/effects";
import {
  addComponent as addComponentAction,
  archiveComponent as archiveComponentAction,
  deleteWeek as deleteWeekAction,
  addWeek as addWeekAction,
  moveWeek as moveWeekAction,
  duplicateWeek as duplicateWeekAction,
  updatePositions as updatePositionsAction,
  duplicateComponent as duplicateComponentAction,
  pasteComponent as pasteComponentAction,
  removeComponent as removeComponentAction,
  updateComponentCover as updateComponentCoverAction,
  updateComponentIcon as updateComponentIconAction,
  lockComponent as lockComponentAction,
  updateComponentDefaultIcon as updateComponentDefaultIconAction,
  publishComponent as publishComponentAction,
  updateComponentContent as updateComponentContentAction,
  updateComponentPosition as updateComponentPositionAction,
  addWorkoutExercise as addWorkoutExerciseAction,
  deleteWorkoutExercise as deleteWorkoutExerciseAction,
  duplicateWorkoutExercise as duplicateWorkoutExerciseAction,
  addWorkoutSection as addWorkoutSectionAction,
  deleteWorkoutSection as deleteWorkoutSectionAction,
  duplicateWorkoutSection as duplicateWorkoutSectionAction,
  updateComponentWeek as updateComponentWeekAction,
  updateComponentProps as updateComponentPropsAction,
  lockAllComponents as lockAllComponentsActions,
  updateDescription as updateDescriptionAction,
  updateComponentSchedule as updateComponentScheduleAction,
  updateComponentDays as updateComponentDaysAction,
  updateComponentTitle as updateComponentTitleAction,
} from "./curriculum/curriculum-slice";

import addComponentWorker from "./curriculum/actions/component/add/sagaWorker";
import archiveComponentWorker from "./curriculum/actions/component/archive/sagaWorker";
import duplicateComponentWorker from "./curriculum/actions/component/duplicate/sagaWorker";
import pasteComponentWorker from "./curriculum/actions/component/paste/sagaWorker";
import removeComponentWorker from "./curriculum/actions/component/remove/sagaWorker";
import updateComponentCoverWorker from "./curriculum/actions/component/updateCover/sagaWorker";
import publishComponentWorker from "./curriculum/actions/component/publish/sagaWorker";
import updateComponentContentWorker from "./curriculum/actions/component/update-content/sagaWorker";
import updateComponentPositionsWorker from "./curriculum/actions/component/updatePositions/sagaWorker";
import {
  updateComponentIconWorker,
  updateComponentDefaultIconWorker,
} from "./curriculum/actions/component/updateIcon/sagaWorkers";
import lockComponentWorker from "./curriculum/actions/component/updateLock/sagaWokrer";
import {
  addWeekWorker,
  deleteWeekWorker,
  duplicateWeekWorker,
  moveWeekWorker,
  updatePositionsWorker,
  updateDescriptionWorker,
} from "./curriculum/actions/week/sagaWorkers";
import addExerciseWorker from "./curriculum/actions/exercise/add/sagaWorker";
import deleteExerciseWorker from "./curriculum/actions/exercise/delete/sagaWorker";
import duplicateExerciseWorker from "./curriculum/actions/exercise/duplicate/sagaWorker";
import addWorkoutSectionWorker from "./curriculum/actions/workout-sections/add/sagaWorker";
import deleteWorkoutSectionWorker from "./curriculum/actions/workout-sections/delete/sagaWorker";
import duplicateWorkoutSectionWorker from "./curriculum/actions/workout-sections/duplicate/sagaWorker";
import updateComponentWeekWorker from "./curriculum/actions/component/updateWeek/sagaWorker";
import updateComponentPropsWorker from "./curriculum/actions/component/update-prop/sagaWorker";
import lockAllComponentsWorker from "./curriculum/actions/program/lock-all/sagaWorker";
import updateComponentScheduleWorker from "./curriculum/actions/component/update-schedule/sagaWorker";
import updateComponentDaysWorker from "./curriculum/actions/component/updateDays/sagaWorker";
import { setLoading, setError } from "./api/api-slice";
import updateComponentTitleWorker from "./curriculum/actions/component/update-title/sagaWorker";

const workers = {
  [addComponentAction.type]: addComponentWorker,
  [archiveComponentAction.type]: archiveComponentWorker,
  [duplicateComponentAction.type]: duplicateComponentWorker,
  [pasteComponentAction.type]: pasteComponentWorker,
  [removeComponentAction.type]: removeComponentWorker,
  [updateComponentCoverAction.type]: updateComponentCoverWorker,
  [updateComponentIconAction.type]: updateComponentIconWorker,
  [updateComponentDefaultIconAction.type]: updateComponentDefaultIconWorker,
  [lockComponentAction.type]: lockComponentWorker,
  [deleteWeekAction.type]: deleteWeekWorker,
  [addWeekAction.type]: addWeekWorker,
  [moveWeekAction.type]: moveWeekWorker,
  [duplicateWeekAction.type]: duplicateWeekWorker,
  [updatePositionsAction.type]: updatePositionsWorker,
  [publishComponentAction.type]: publishComponentWorker,
  [updateComponentContentAction.type]: updateComponentContentWorker,
  [updateComponentPositionAction.type]: updateComponentPositionsWorker,
  [addWorkoutExerciseAction.type]: addExerciseWorker,
  [deleteWorkoutExerciseAction.type]: deleteExerciseWorker,
  [duplicateWorkoutExerciseAction.type]: duplicateExerciseWorker,
  [addWorkoutSectionAction.type]: addWorkoutSectionWorker,
  [deleteWorkoutSectionAction.type]: deleteWorkoutSectionWorker,
  [duplicateWorkoutSectionAction.type]: duplicateWorkoutSectionWorker,
  [updateComponentWeekAction.type]: updateComponentWeekWorker,
  [updateComponentPropsAction.type]: updateComponentPropsWorker,
  [lockAllComponentsActions.type]: lockAllComponentsWorker,
  [updateDescriptionAction.type]: updateDescriptionWorker,
  [updateComponentScheduleAction.type]: updateComponentScheduleWorker,
  [updateComponentDaysAction.type]: updateComponentDaysWorker,
  [updateComponentTitleAction.type]: updateComponentTitleWorker,
};

const queueTypes = Object.keys(workers);

// put all dispatched actions in a queue (blocking call of workers)
export function* watchRequests() {
  const requestChan = yield actionChannel(queueTypes);
  while (true) {
    const { type, payload } = yield take(requestChan);
    if (payload?.apiHandled) {
      continue;
    }
    const worker = workers[type];
    try {
      yield put(setLoading(true));
      yield call(worker, payload);
    } catch (error: any) {
      yield put(
        setError(error?.message || "unknown error in saga workers queue"),
      );
      break;
    } finally {
      yield put(setLoading(false));
    }
  }
}
