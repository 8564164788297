import clsx from "clsx";
import React from "react";
import { DialogProps, Button, ButtonProps } from "@mui/material";
import { lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "./BaseDialog";
import ReactPlayer from "react-player";
import { getCloudFlareSources, isVideoStream } from "../../utils/component";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  description: {
    color: theme.palette.common.black,
  },

  button: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    width: `calc(50% - ${theme.spacing(1)})`,
    height: theme.spacing(7),
  },

  cancelButton: {
    color: theme.palette.text.secondary,
    borderWidth: 2,
    marginRight: theme.spacing(2),
  },

  okayButton: {
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
  videoClassName: {
    maxWidth: "100%",
    minWidth: "100%",
    maxHeight: 240,
    [theme.breakpoints.down("md")]: {
      maxHeight: 420,
    },

    "& [data-vjs-player]": {
      paddingTop: "unset",
      height: 240,

      [theme.breakpoints.down("md")]: {
        height: 420,
      },
    },

    "& .vjs-big-play-button": {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
}));

export interface ConfirmActionDialogProps extends Omit<DialogProps, "title"> {
  title?: string;
  description?: string | React.ReactNode;
  onCancel: ButtonProps["onClick"];
  onConfirm: ButtonProps["onClick"];
  disabled?: boolean;
  activity?: boolean;
  videoUrl?: string | null;
  classNameTitle?: string;
  confirmButtonText?: string;
}

export function ConfirmActionDialog(props: ConfirmActionDialogProps) {
  const {
    className,
    title,
    description,
    onCancel,
    onConfirm,
    disabled,
    activity,
    videoUrl,
    classNameTitle,
    confirmButtonText = "OK",
    ...other
  } = props;
  const s = useStyles();

  const videoSource = videoUrl
    ? isVideoStream(videoUrl)
      ? getCloudFlareSources(videoUrl)[2].src
      : videoUrl
    : null;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={title}
      description={description}
      onClose={onCancel}
      classNameTitle={classNameTitle}
      classes={{
        paper: s.paper,
        description: s.description,
      }}
      {...other}
    >
      {!activity && (
        <>
          <Button
            className={clsx(s.button, s.cancelButton)}
            variant="outlined"
            children="Cancel"
            onClick={onCancel}
            disabled={disabled}
          />
          <Button
            className={clsx(s.button, s.okayButton)}
            variant="contained"
            children={confirmButtonText}
            onClick={onConfirm}
            disabled={disabled}
          />
        </>
      )}
      {videoSource && (
        <ReactPlayer className={s.videoClassName} url={videoSource} controls />
      )}
    </BaseDialog>
  );
}