import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { sortBy } from "lodash";

import {
  getCalendarDates,
  daysOfWeek,
  toISODateString,
  getISODate,
  parseISODate,
} from "../../utils/date";
import { componentComparator } from "../program-calendar/utils";
import { Filters } from "../program/ProgramDetailsFilters";
import { ReactComponent as PersonAddIcon } from "../../icons/person-add.svg";
import { ChooseProgramEnrollDialog } from "../dialog/ChooseProgramEnrollDialog";
import { sevenDays } from "../../constants";
import { CoachClientCalendarActivity } from "./CoachClientCalendarActivity";
import { CoachClientCalendarEnrollment } from "./CoachClientCalendarEnrollment";

import { ActivitiesForCalendarDto, EnrollmentsForCalendarDto } from "@growth-machine-llc/stridist-api-client";
import { CalendarCellSkeleton } from "../loading/CalendarCellSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    width: "100%",
    background: theme.palette.quote,
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gridGap: 1,
    overflow: "hidden",
    overflowX: "scroll",
    msOverflowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
    "& > *": {
      background: theme.palette.background.paper,
    },
  },

  header: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    padding: theme.spacing(1),
  },

  slot: {
    minHeight: 180,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0.5),
    position: "relative",

    "&:hover $add": {
      display: "block",
    },
  },

  day: {
    fontWeight: 700,
    fontSize: 14,
    margin: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    alignSelf: "flex-end",
    textAlign: "center",
    borderRadius: "50%",
    width: 24,
    height: 24,
    padding: theme.spacing(0.25, 0),
  },

  today: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },

  add: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: theme.palette.primary.main,
    display: "none",
  }
}));

export interface CoachClientCalendarProps extends BoxProps {
  enrollments: EnrollmentsForCalendarDto[];
  filters?: Filters;
  date?: string;
  today?: string;
  isLoading: boolean;
}

export function CoachClientCalendar(props: CoachClientCalendarProps) {
  const {
    className,
    enrollments,
    filters,
    date = getISODate(),
    today = getISODate(),
    isLoading,
    ...other
  } = props;
  const s = useStyles();
  const [chooseProgramEnrollDialogOpen, setChooseProgramEnrollDialogOpen] =
    React.useState(false);
  const [startDate, setStartDate] = React.useState<string>();

  const activities = sortBy(
    enrollments.flatMap((enrollment) => enrollment.activities),
    componentComparator
  ) as ActivitiesForCalendarDto[];

  const [year, month] = parseISODate(date);
  const dates = getCalendarDates(year, month).map((date) => ({
    date,
    rawDate: toISODateString(date),
  }));

  const handleAddClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setStartDate(event.currentTarget.dataset?.date);
      setChooseProgramEnrollDialogOpen(true);
    },
    [],
  );

  const handleCloseChooseProgramEnrollDialog = React.useCallback(() => {
    setChooseProgramEnrollDialogOpen(false);
  }, []);

const startDateIndex = dates.findIndex(dateItem => dateItem.rawDate === date);

return (
  <>
    <Box className={clsx(s.root, className)} {...other}>
      {sevenDays.map((_, day) => (
        <Box key={day} className={s.header}>
          {daysOfWeek[day]}
        </Box>
      ))}

      {dates.map(({ date, rawDate }, index) => (
        <Box key={index} className={s.slot}>
          {date.getMonth() === month && (
            <>
              {(isLoading && index >= startDateIndex && index % 4 === 0) ? (
                <CalendarCellSkeleton />
              ) : (
                <>
                  <Typography
                    component="div"
                    className={clsx(s.day, rawDate === today && s.today)}
                    children={date.getDate()}
                  />

                  {enrollments
                    .filter((enrollment) => enrollment.startDate === rawDate)
                    .map((enrollment) => (
                      <CoachClientCalendarEnrollment
                        key={enrollment.id}
                        enrollment={enrollment}
                        enrollments={enrollments}
                      />
                    ))}

                  {activities
                    .filter(
                      (activity) => activity.date.format("YYYY-MM-DD") === rawDate
                    )
                    .filter(
                      (activity) => !filters || filters[activity.component.type]
                    )
                    .map((activity) => (
                      <CoachClientCalendarActivity
                        key={activity.id}
                        activity={activity}
                      />
                    ))}

                  <IconButton
                    className={s.add}
                    children={<PersonAddIcon />}
                    onClick={handleAddClick}
                    data-date={rawDate}
                    size="large"
                  />
                </>
              )}
            </>
          )}
        </Box>
      ))}
    </Box>

    {chooseProgramEnrollDialogOpen && (
      <ChooseProgramEnrollDialog
        open={chooseProgramEnrollDialogOpen}
        enrollments={enrollments}
        startDate={startDate}
        onClose={handleCloseChooseProgramEnrollDialog}
      />
    )}
  </>
  );
}