import clsx from "clsx";
import React from "react";
import { CircularProgress, CircularProgressProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spinner: {
    pointerEvents: "none",
    width: "20px",
    height: "20px",
    border: "2px solid transparent",
    borderTopColor: "#bdbdbd",
    borderRadius: "50%",
    animation: "$preloadSpin 1s linear infinite",

    ".extraLarge &": {
      width: "80px",
      height: "80px",
    },
    ".large &": {
      width: "24px",
      height: "24px",
    },
    ".small &": {
      width: "16px",
      height: "16px",
    },
  },
  "@keyframes preloadSpin": {
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  fillParent: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  fillScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  fullWidth: {
    width: "100%",
  },
}));

const sizeMap = {
  small: 16,
  medium: 20,
  large: 24,
};

export interface DefaultLoaderProps extends CircularProgressProps {
  fullWidth?: boolean;
  fillParent?: boolean;
  fillScreen?: boolean;
  size?: "small" | "medium" | "large" | "extraLarge";
}

export function DefaultLoader(props: DefaultLoaderProps) {
  const { className, size = "medium", ...other } = props;
  const s = useStyles();

  return (
    <Box
      className={clsx(
        s.root,
        className,
        props.fillParent && s.fillParent,
        props.fullWidth && s.fullWidth,
        props.fillScreen && s.fillScreen,
        size && size !== "medium" && size,
      )}
    >
      <div className={s.spinner}></div>
    </Box>
  );
}
