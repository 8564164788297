import React, { useEffect } from "react";
import {
  EditorProgramContext,
  EditorComponentContext,
} from "../../../../../components/new-editor/hooks";
import { HistoryBlock } from "../../../../../components/history-block/HistoryBlock";
import { CoachComponent } from "../../../../../components/program-component/CoachComponent";
import { RefreshSlug } from "../../../../../components/routes/RefreshSlug";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TrackInfoTool from "../../../../../components/tools/TrackInfoTool";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../../../routes";
import { useCurriculumSelector } from "../../../../../redux/hooks";
import {
  selectComponentById,
  selectProgram,
} from "../../../../../redux/curriculum/selectors/curriculum";
import { useQuery } from "@tanstack/react-query";
import ComponentsService from "../../../../../services/ComponentsService";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../../../../api/ReactQueryConfig";
import CoachComponentBarLoader from "../../../../../components/program-component/CoachComponentBarLoader";
import { Box, CircularProgress } from "@mui/material";
import { grey } from "@mui/material/colors";
import { extractSlugId } from "../../../../../utils/slug";

interface ICoachProgramComponentPreviewRouteProps {
  programSlugProp?: string;
  componentSlugProp?: string;
  handleCloseDialog?: () => void;
  handleClickOpenDialog?: (e?: any, slug?: string) => void;
}
export const COACH_PROGRAM_COMPONENT_QUERY_KEY = "coach-program-component";

// TODO <ReactComponent/> vs <Route/> for components
// TODO_API_V2_CURRICULUM investigate idea to open component with store data to avoid loading if possible
export function CoachProgramComponentPreviewRoute(
  inputProps: ICoachProgramComponentPreviewRouteProps,
) {
  const {
    programSlugProp,
    componentSlugProp,
    handleCloseDialog,
    handleClickOpenDialog,
  } = inputProps;
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const readOnly = searchParams.get("readOnly");
  const preview = searchParams.get("preview");
  const componentSearchParam = searchParams.get("component");

  const componentSlug = params.componentSlug ?? componentSlugProp;
  const programSlug = params.slug ?? programSlugProp;
  const componentSlugId = extractSlugId(componentSlug);

  const program = useCurriculumSelector(selectProgram);

  // currently, no program id is fetched for preview (not a problem since it readonly)
  // should be fixed after refactoring
  const PROGRAM_ID = program?.id ?? -1;

  // enable query only when componentSlug is present and not a temp slug
  const enabled =
    Boolean(componentSlug) && !componentSlug?.includes("-tempslug");

  const {
    data: componentData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [COACH_PROGRAM_COMPONENT_QUERY_KEY, { slugId: componentSlugId }],
    queryFn: () => {
      return ComponentsService.getBySlug(componentSlug);
    },
    enabled,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  useEffect(() => {
    if (componentSlug?.includes("-tempslug")) return;
    // force refetch dialog data on open
    refetch();
  }, [componentSearchParam]);

  let id = null;
  if (componentSlug?.endsWith("-tempslug")) {
    id = parseInt(componentSlug?.split("-tempslug")[0]);
  }

  // if slug was changed in store, selector with 'componentSlug' no longer works - use id to select component
  const componentByTempSlug = useCurriculumSelector(selectComponentById(id));

  React.useEffect(() => {
    // if componentSlug differs from slug at store and slug at store is no longer a temp slug
    // reopen dialog with new slug
    if (
      componentByTempSlug &&
      componentByTempSlug.slug &&
      componentSlug &&
      componentByTempSlug.slug != componentSlug &&
      !componentByTempSlug.slug.endsWith("-tempslug")
    ) {
      handleClickOpenDialog(undefined, componentByTempSlug.slug);
    }
  }, [componentByTempSlug, componentSlug, handleClickOpenDialog]);

  const defaultCloseDialogHandler = () => {
    navigate(COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", programSlug));
  };

  return enabled && !isLoading ? (
    <>
      <RefreshSlug
        slugInfo={[
          {
            slug: componentData?.slug,
            slugId: componentData?.slugId,
          },
          // TODO_API_V2_REFRESH_SLUG: Consider retrieving slug from program.
          // Currently not urgent since user can not trigger slug change on this page.
          // {
          //   slug: program?.slug,
          //   slugId: program?.slugId,
          // },
        ]}
      />
      <EditorProgramContext.Provider value={{ programId: PROGRAM_ID }}>
        <EditorComponentContext.Provider value={componentData}>
          <HistoryBlock>
            <CoachComponent
              componentData={componentData}
              readOnly={Boolean(readOnly)}
              handleCloseDialog={handleCloseDialog ?? defaultCloseDialogHandler}
              programName={program?.name}
            />
          </HistoryBlock>
        </EditorComponentContext.Provider>
      </EditorProgramContext.Provider>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Program Component",
          properties: {
            programId: PROGRAM_ID,
            programName: program?.name,
            programSlug: programSlug,
            componentId: componentData?.id,
            componentTitle: componentData?.title,
            componentSlug: componentData?.slug,
          },
        }}
      />
    </>
  ) : (
    <>
      {!preview && <CoachComponentBarLoader backClick={handleCloseDialog} />}
      <Box display={"flex"} height={1} color={grey[400]}>
        <CircularProgress
          size={20}
          color={"inherit"}
          sx={{
            margin: "auto",
          }}
        />
      </Box>
    </>
  );
}
