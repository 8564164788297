import { PaginatedListOfAdminUserInfoDto } from "@growth-machine-llc/stridist-api-client";
import { ADMIN_USERS_QUERY_KEY } from "../UsersTable";
import FbUsersService from "../../../services/FbUsersService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ADMIN_USERS_ROUTE } from "../../../routes/routes";

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: FbUsersService.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_USERS_QUERY_KEY],
      });
      navigate(ADMIN_USERS_ROUTE);
    },
  });
};
