import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DropdownMenuItem } from "../menu/DropdownMenu";
import { HabitCheckInProgressTable } from "../program/HabitCheckInProgressTable";
import { useQueryParam } from "../../hooks/useQueryParam";
import { CompletionPeriod } from "../../constants";
import { PeriodFilter } from "../filters/PeriodFilter";
import { toEnum } from "../../utils/misc";
import { useQuery } from "@tanstack/react-query";
import HabitCheckInsSkeleton from "../activity/HabitsCheckInsSkeleton";
import ActivitiesService from "../../services/ActivitiesService";

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3.5),
  },

  menu: {
    marginBottom: theme.spacing(3),
  },
}));

export interface HabitCheckInProgressProps extends BoxProps {
  programSlug: string;
  clientId: number;
}

const COACH_CLIENT_ENROLLMENT_OVERVIEW_QUERY_KEY =
  "coach-client-enrollment-overview";

export function HabitCheckInProgress(props: HabitCheckInProgressProps) {
  const { className, programSlug, clientId, ...other } = props;
  const s = useStyles();

  const [periodQueryParam, setPeriodQueryParam] = useQueryParam(
    "period",
    CompletionPeriod.BEFORE_NOW,
    {
      normalize: (param) =>
        toEnum(param, CompletionPeriod, CompletionPeriod.BEFORE_NOW),
    },
  );

  const { data: habitsCheckIns } = useQuery({
    queryKey: [
      COACH_CLIENT_ENROLLMENT_OVERVIEW_QUERY_KEY,
      { program: programSlug, client: clientId, filter: periodQueryParam },
    ],
    queryFn: () =>
      ActivitiesService.getHabitsCheckIns(
        clientId,
        programSlug,
        periodQueryParam,
      ),
  });

  const handlePeriodItemClick: DropdownMenuItem["onClick"] = React.useCallback(
    (event) => {
      const {
        currentTarget: {
          dataset: { period },
        },
      } = event;

      setPeriodQueryParam(period as CompletionPeriod);
    },
    [setPeriodQueryParam],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {!habitsCheckIns ? (
        <HabitCheckInsSkeleton></HabitCheckInsSkeleton>
      ) : (
        <>
          <Typography
            className={s.title}
            variant="h3"
            children="Habit and Check-In progress"
          />
          <PeriodFilter
            className={s.menu}
            selectedPeriod={periodQueryParam}
            onPeriodChange={handlePeriodItemClick}
            itemLabels={{
              [CompletionPeriod.BEFORE_NOW]: "Since program start",
            }}
          />
          <HabitCheckInProgressTable habitsCheckIns={habitsCheckIns} />
        </>
      )}
    </Box>
  );
}
