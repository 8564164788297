import { useMutation } from "@tanstack/react-query";
import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import SignUpService from "../../../services/SignUpService";

export const useSignUpMutation = (props?: UseReactQueryPropsBase) => {
  return useMutation({
    mutationKey: ["client-sign-up"],
    mutationFn: SignUpService.clientSignUp,
    retry: 0,
    ...props,
  });
};
