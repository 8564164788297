import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { humanizeUserPlan } from "../../utils/user";
import { planStatus, planStatusLabel } from "../../constants";
import { SubscriptionBriefDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  status: {
    fontSize: 20,
  },

  statusLabel: {
    padding: theme.spacing(0, 0.5),
    borderRadius: 2,
    color: theme.palette.common.white,
  },
}));

export interface CoachSettingsPlanStatusTextProps extends TypographyProps {
  plan: string;
  subscription: SubscriptionBriefDto;
}

export function CoachSettingsPlanStatusText(
  props: CoachSettingsPlanStatusTextProps,
) {
  const { className, plan, subscription, ...other } = props;
  const s = useStyles();

  const text = React.useMemo(() => {
    if (plan) {
      if (!subscription) {
        return `You are on the ${humanizeUserPlan(plan)} plan`;
      }
      if (
        subscription?.status === planStatus.ACTIVE ||
        subscription?.status === planStatus.TRIALING
      ) {
        return `You are on the ${humanizeUserPlan(plan)} plan`;
      } else {
        return <span className={s.status}>Your updated plan is in </span>;
      }
    } else {
      return "14 days trial plan";
    }
  }, [subscription, plan, s.status]);

  const status = React.useMemo(() => {
    const color = () => {
      switch (subscription?.status) {
        case planStatus.PAST_DUE:
        case planStatus.INCOMPLETE:
          return "#F2994A";
        case planStatus.CANCELED:
        case planStatus.INCOMPLETE_EXPIRED:
        case planStatus.UNPAID:
          return "#EB5757";
        default:
          return "#ffffff";
      }
    };
    return (
      <span
        className={clsx(s.status, s.statusLabel)}
        style={{ backgroundColor: color() }}
      >
        {planStatusLabel[subscription?.status]}
      </span>
    );
  }, [subscription, s.status, s.statusLabel]);

  return (
    <Typography className={clsx(s.title, className)} variant="h2" {...other}>
      {text}
      {subscription?.status !== planStatus.ACTIVE &&
        subscription?.status !== planStatus.TRIALING &&
        status}
    </Typography>
  );
}
