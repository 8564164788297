import clsx from "clsx";
import React from "react";
import { Popover, PopoverProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ComponentSchedule } from "../../components/schedule/ComponentSchedule";
import { ComponentType, ComponentRepeat, ReminderType } from "../../constants";
import { Schedule } from "../schedule/types";
import { useProgramWeeks } from "../../hooks/useProgramWeeks";
import { CurriculumComponent } from "../../redux/types";
import { parseComponentDays } from "../../utils/component";
import { useCurriculumDispatch } from "../../redux/hooks";
import { updateComponentSchedule } from "../../redux/curriculum/curriculum-slice";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.75),
  },

  arrow: {
    width: 50,
    height: 25,
    position: "absolute",

    left: "50%",
    overflow: "hidden",
    zIndex: 100,

    "&::after": {
      content: '""',
      position: "absolute",
      width: 18,
      height: 18,
      background: theme.palette.common.white,
      transform: "translateX(-50%) translateY(-50%) rotate(45deg)",
      top: 0,
      left: "50%",
      boxShadow: theme.shadows[4],
    },
  },

  arrowUp: {
    transform: "translateX(-50%) translateY(-100%) rotate(180deg)",
    top: "0%",
  },

  arrowDown: {
    transform: "translateX(-50%)",
    top: "100%",
  },

  container: {
    padding: theme.spacing(3, 4),
    overflow: "visible",
  },

  topOriented: {
    marginTop: theme.spacing(-1.5),
  },

  bottomOriented: {
    marginTop: theme.spacing(1.5),
  },
}));

export interface WeekComponentScheduleProps extends PopoverProps {
  componentData: CurriculumComponent;
  weekId?: number;
  type?: string;
  title?: string;
  days?: any;
  repeat?: string;
  idItem?: string;
}

export function WeekComponentSchedule(props: WeekComponentScheduleProps) {
  const {
    className,
    componentData,
    onClose,
    anchorEl,
    weekId,
    days,
    type,
    title,
    repeat,
    idItem,
    ...other
  } = props;
  const [bottomOriented, setBottomOriented] = React.useState(true);
  const s = useStyles();
  const dispatch = useCurriculumDispatch();
  const component = componentData;
  const { duration, reminderType, reminderTime, messageTime } = component;
  const [schedule, setSchedule] = React.useState<Schedule>({
    weekId,
    days: parseComponentDays(days) as boolean[],
    duration,
    repeat: repeat as ComponentRepeat,
    reminderType: reminderType as ReminderType,
    reminderTime: reminderTime,
    messageTime: messageTime,
  });
  const arrowRef = React.useRef<HTMLDivElement>(null);

  const programWeeks = useProgramWeeks();

  React.useEffect(() => {
    if (anchorEl) {
      const el = anchorEl as HTMLDivElement;
      const { y } = el.getBoundingClientRect();
      const screenHeight = window.screen.height;

      if (y < screenHeight / 2) {
        setBottomOriented(true);
      } else {
        setBottomOriented(false);
      }
    }
  }, [anchorEl]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (anchorEl && arrowRef.current) {
        const el = anchorEl as HTMLDivElement;
        const arrowEl = arrowRef.current;
        const elRect = el.getBoundingClientRect();
        const arrowRect = arrowEl.getBoundingClientRect();
        const offset = elRect.x - arrowRect.x;

        arrowEl.style.marginLeft = `${offset}px`;
      }
    });

    return () => clearTimeout(timer);
  });

  const handleScheduleChange = React.useCallback((schedule: Schedule) => {
    setSchedule(schedule);
  }, []);

  const handleScheduleSubmit = React.useCallback(
    (event) => {
      dispatch(
        updateComponentSchedule({
          componentId: component.id,
          schedule,
        }),
      );
      onClose(event, "escapeKeyDown");
    },
    [title, schedule, onClose, idItem],
  );

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: bottomOriented ? "bottom" : "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: bottomOriented ? "top" : "bottom",
        horizontal: "center",
      }}
      className={clsx(
        s.root,
        className,
        bottomOriented ? s.bottomOriented : s.topOriented,
      )}
      PaperProps={{ className: s.container }}
      {...other}
    >
      <div
        className={clsx(s.arrow, bottomOriented ? s.arrowUp : s.arrowDown)}
        ref={arrowRef}
      />
      <ComponentSchedule
        layout="flat"
        componentType={type as ComponentType}
        schedule={schedule}
        onChange={handleScheduleChange}
        onSubmit={handleScheduleSubmit}
        programWeeks={programWeeks}
      />
    </Popover>
  );
}
