import clsx from "clsx";
import React, { ComponentProps } from "react";
import {
  Drawer,
  DrawerProps,
  Button,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { polyfillCSS } from "../../utils/css";

import { ActivityFeedbackList } from "./ActivityFeedbackList";
import ActivitiesService from "../../services/ActivitiesService";
import ActivityFeedbacksService from "../../services/ActivityFeedbacksService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DefaultLoader } from "../loading/DefaultLoader";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import {
  ActivityBriefDto,
  ActivityFeedbackDto,
  IClientFormClientInfoDto,
  IMealLoggingActivityDto,
  UserBriefInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ACTIVITY_LIST_QUERY_KEY } from "../activity/CoachActivity";
import { CLIENT_MEALLOGGING_ACTIVITY_QUERY_KEY } from "../client-meal-logging/ClientMealLoggingEntriesList";
import { CLIENT_FORM_QUERY_KEY } from "../client-forms/ClientFormScreen";
import { PROGRAM_COMPONENT_QUERY_KEY } from "../../hooks/activities/useActivity";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    paddingTop: polyfillCSS(
      `max(${theme.spacing(1.5)}, var(--safe-area-inset-top))`,
    ),
    paddingBottom: polyfillCSS(
      `max(${theme.spacing(1.5)}, var(--safe-area-inset-bottom))`,
    ),
    paddingLeft: polyfillCSS(
      `max(${theme.spacing(2)}, var(--safe-area-inset-left))`,
    ),
    paddingRight: polyfillCSS(
      `max(${theme.spacing(2)}, var(--safe-area-inset-right))`,
    ),

    width: "100%",

    "& h2": {
      fontSize: 32,
      margin: theme.spacing(2, 0, 0),
    },

    [theme.breakpoints.up("sm")]: {
      paddingTop: polyfillCSS(
        `max(${theme.spacing(3)}, var(--safe-area-inset-top))`,
      ),
      paddingBottom: polyfillCSS(
        `max(${theme.spacing(3)}, var(--safe-area-inset-bottom))`,
      ),
      paddingLeft: polyfillCSS(
        `max(${theme.spacing(4)}, var(--safe-area-inset-left))`,
      ),
      paddingRight: polyfillCSS(
        `max(${theme.spacing(4)}, var(--safe-area-inset-right))`,
      ),
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    marginLeft: theme.spacing(-1.5),
    "& svg": {
      width: 24,
      height: 24,
      marginRight: theme.spacing(0.5),
    },
  },
}));

export interface ActivityFeedbackDrawerProps extends DrawerProps {
  activity: ComponentProps<typeof ActivityFeedbackList>["activity"];
  anchorEl?: any;
}

export const ACTIVITY_FEEDBACKS_QUERY_KEY = "activity-feedbacks";
export function ActivityFeedbackDrawer(props: ActivityFeedbackDrawerProps) {
  const { className, onClose, anchorEl, activity, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const user = useCurrentUser();
  const queryClient = useQueryClient();

  const { data: feedbacks, isLoading } = useQuery({
    queryKey: [ACTIVITY_FEEDBACKS_QUERY_KEY, { id: activity?.id }],
    queryFn: () => ActivityFeedbacksService.getActivityFeedbacks(activity?.id),
    enabled: !!activity,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const creteFeedbackMutation = useOptimisticUpdateMutation({
    disableToastAlerts: true,
    queryKey: [ACTIVITY_FEEDBACKS_QUERY_KEY, { id: activity?.id }],
    mutationFn: ActivitiesService.createActivityFeedback,
    optimisticUpdater: {
      updateFn: (prev: ActivityFeedbackDto[], variables, tempId: number) => [
        ...prev,
        new ActivityFeedbackDto({
          ...variables,
          id: tempId,
          created: dayjs(),
          formattedFromNow: "a few seconds ago",
          author: new UserBriefInfoDto({
            id: user.id,
            displayName: user.displayName,
            role: user.role,
            photoUrl: user.photoUrl,
          }),
        }),
      ],
    },
    options: {
      onSuccess({ feedbacksCount: newFeedbacksCount }) {
        // updating total feedbacks count in root activity cache
        queryClient.setQueriesData<
          IMealLoggingActivityDto | IClientFormClientInfoDto | ActivityBriefDto
        >(
          {
            predicate: ({ queryKey }) =>
              [
                CLIENT_MEALLOGGING_ACTIVITY_QUERY_KEY,
                CLIENT_FORM_QUERY_KEY,
                PROGRAM_COMPONENT_QUERY_KEY,
              ].includes(queryKey[0] as string),
          },
          (prev) => ({
            ...prev,
            // Updating feedbacks count in the meal logging activity
            ...("feedbacksCount" in prev &&
              prev.id === activity.id && {
                feedbacksCount: newFeedbacksCount,
              }),

            // Updating feedbacks count in the client form activity
            ...("activity" in prev &&
              prev.activity.id === activity.id && {
                activity: {
                  ...prev.activity,
                  feedbacksCount: newFeedbacksCount,
                },
              }),
          }),
        );

        // invalidating `.../activity` route query to refetch the list
        queryClient.invalidateQueries({
          queryKey: [ACTIVITY_LIST_QUERY_KEY],
          exact: false,
          refetchType: "none",
        });
      },
    },
  });

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      anchor="right"
      variant="temporary"
      onClose={(event) => onClose(event, "backdropClick")}
      sx={{
        ".MuiBackdrop-root": { background: "unset" },
        zIndex: "99999 !important",
      }}
      {...other}
    >
      <Box>
        <Button
          variant="text"
          color="primary"
          className={s.button}
          onClick={handleClose}
        >
          <CloseIcon /> Close
        </Button>
      </Box>

      <Typography variant="h2">Feedback</Typography>
      {isLoading ? (
        <DefaultLoader fillParent />
      ) : (
        <ActivityFeedbackList
          activity={activity}
          feedbacks={feedbacks}
          submitMutation={creteFeedbackMutation}
        />
      )}
    </Drawer>
  );
}
