import {
  Units,
  WeightMeasurementUnit,
  LengthMeasurementUnit,
  UnitsTime,
  ExerciseTypeSet,
} from "../constants";

export interface DatedUnitWeight {
  value: number;
  unit: WeightMeasurementUnit;
  date: string;
}

export interface UnitLength {
  value: number;
  unit: LengthMeasurementUnit;
}

export const formatHeight = (value: string | number, units: Units) => {
  let parsed = typeof value === "string" ? parseFloat(value) : value;

  if (Number.isNaN(parsed) || parsed === null) {
    parsed = 0;
  }

  switch (units) {
    case Units.METRIC:
      return parsed + "cm";
    case Units.US:
      const feet = Math.floor(parsed / 12);
      const inch = parsed % 12;

      return `${feet}'${inch}''`;
  }
};

export const formatWeight = (
  value: string | number,
  units: Units,
  space: boolean = false,
) => {
  let parsed = typeof value === "string" ? parseFloat(value) : value;

  if (Number.isNaN(parsed) || parsed === null) {
    parsed = 0;
  }

  const unit = units === Units.METRIC ? "kg" : "lbs";

  return `${parsed.toFixed(1)}${space ? " " : ""}${unit}`;
};

export const convertWeight = (
  value: number,
  valueUnit: WeightMeasurementUnit,
  clientUnits: Units,
): number | null => {
  if (Number.isNaN(value) || value === null) {
    return 0;
  }

  const clientUnit: WeightMeasurementUnit =
    clientUnits === Units.METRIC ? "kg" : "lbs";

  if (clientUnit !== valueUnit) {
    value =
      clientUnit === "lbs"
        ? Math.round(2.205 * value)
        : Math.round(value / 2.205);
  }

  return value;
};

export const convertFormatWeight = (
  value: number,
  valueUnit: WeightMeasurementUnit,
  clientUnits: Units,
  space: boolean = true,
): string | null => {
  const converted = convertWeight(value, valueUnit, clientUnits);

  return converted ? formatWeight(converted, clientUnits, space) : null;
};

export const getWeightDiff = (
  w1: DatedUnitWeight,
  w2: DatedUnitWeight,
  clientUnits: Units,
): number => {
  const w1Value = convertWeight(w1.value, w1.unit, clientUnits);
  const w2Value = convertWeight(w2.value, w2.unit, clientUnits);

  return w1Value - w2Value;
};

export const formatLength = (
  value: string | number,
  units: Units,
  space: boolean = false,
  alternate: boolean = false,
) => {
  const parsed = typeof value === "string" ? parseFloat(value) : value;

  if (Number.isNaN(parsed)) {
    return null;
  }

  const unit = units === Units.METRIC ? "cm" : alternate ? "”" : "in";

  return `${parsed.toFixed(1)}${space ? " " : ""}${unit}`;
};

export const convertLength = (
  value: number,
  valueUnit: LengthMeasurementUnit,
  clientUnits: Units,
): number | null => {
  if (Number.isNaN(value)) {
    return null;
  }

  const clientUnit: LengthMeasurementUnit =
    clientUnits === Units.METRIC ? "cm" : "in";

  if (clientUnit !== valueUnit) {
    value = clientUnit === "in" ? value / 2.54 : value * 2.54;
  }

  return value;
};

export const convertFormatLength = (
  value: number,
  valueUnit: LengthMeasurementUnit,
  clientUnits: Units,
  space: boolean = true,
  alternate: boolean = false,
) => {
  const converted = convertLength(value, valueUnit, clientUnits);

  return converted
    ? formatLength(converted, clientUnits, space, alternate)
    : null;
};

export const getLengthDiff = (
  l1: UnitLength,
  l2: UnitLength,
  clientUnits: Units,
): number => {
  const l1Value = convertLength(l1.value, l1.unit, clientUnits);
  const l2Value = convertLength(l2.value, l2.unit, clientUnits);

  return l1Value - l2Value;
};

export const formatAgeAndGender = (
  age: number | string | null,
  gender: string | null,
) => [age, gender?.substring(0, 1)].filter(Boolean).join("");

export const unitSetLabelCoach = (
  unit: string,
  typeSet: string,
  endText: string = "",
) => {
  if (typeSet === ExerciseTypeSet.DISTANCE) {
    switch (unit) {
      case Units.US:
        return "inc";
      case Units.METRIC:
        return "m";
    }
  } else {
    switch (unit) {
      case Units.US:
        return "lb" + endText;
      case Units.METRIC:
        return "kg" + endText;
      case UnitsTime.MINUTES:
        return "min";
      case UnitsTime.SECONDS:
        return "sec";
    }
  }
};

export const unitSetLabel = (
  unit: string,
  typeSet: string = ExerciseTypeSet.WEIGHT,
) => {
  if (typeSet === ExerciseTypeSet.DISTANCE) {
    switch (unit) {
      case Units.US:
        return "Distance (inc)";
      case Units.METRIC:
        return "Distance (m)";
    }
  } else if (typeSet === ExerciseTypeSet.WEIGHT) {
    switch (unit) {
      case Units.US:
        return "Weight (lb)";
      case Units.METRIC:
        return "Weight (kg)";
    }
  } else {
    switch (unit) {
      case UnitsTime.MINUTES:
        return "Time (min)";
      case UnitsTime.SECONDS:
        return "Time (sec)";
    }
  }
};
