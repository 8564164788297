import { COACH_GROUPS_LIST_QUERY_KEY } from "../../../routes/coach/groups/CoachGroupsListRoute";
import GroupsService from "../../../services/GroupsService";
import { useQueryClient } from "@tanstack/react-query";
import { NAVBAR_GROUP_LIST_QUERY_KEY } from "../GroupNavItemList";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import {
  PaginatedListOfGroupDto,
  GroupDto2,
} from "@growth-machine-llc/stridist-api-client";

export const useCreateGroupMutation = () => {
  const queryClient = useQueryClient();

  return useOptimisticUpdateMutation({
    queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
    mutationFn: GroupsService.createGroup,
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfGroupDto, variables, tempId) => {
        const newData = PaginatedListOfGroupDto.fromJS(oldData);
        newData.totalCount = newData.items.unshift(
          GroupDto2.fromJS({
            name: variables.name,
            id: tempId,
            members: [],
          }),
        );
        return newData;
      },
    },
    successUpdater: {
      updateFn: (oldData: PaginatedListOfGroupDto, variables, tempId) => {
        const groupIndex = oldData.items.findIndex(
          (group) => group.id === tempId,
        );
        Object.entries(variables).forEach(([key, value]) => {
          oldData.items[groupIndex][key] = value;
        });
        return oldData;
      },
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [NAVBAR_GROUP_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
          refetchType: "none",
        });
      },
    },
  });
};
