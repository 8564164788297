import React from "react";
import { RenderElementProps } from "slate-react";
import { giphyUrlById } from "../../../../utils/giphy";
import { PlateElement, withRef } from "@udecode/plate-common";

export const ELEMENT_GIF = "gif";

function MessageGif(props: any) {
  const { element, children } = props;
  return (
    <div>
      <img src={giphyUrlById(element.giphyId as string)} alt="" />
      {children}
    </div>
  );
}

export const MessageGifElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children } = props;

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <MessageGif children={children} {...props} />
      </PlateElement>
    );
  },
);
