import React from "react";
import { MutateOptions } from "@tanstack/react-query";
import {
  ICreateSignedUrlCommand,
  SignedUrlVm,
  AssetType,
} from "@growth-machine-llc/stridist-api-client";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { uploadFile, getMimeTypes } from "../../../../utils/file";
import { useNativeDropzone } from "../../../../utils/device";
import { CloudUpload } from "@mui/icons-material";
import {
  CheckInAnswerProgressPhotoView,
  ProgressImageInfo,
} from "./CheckInAnswerProgressPhotoView";
import { ElementType } from "../../types/elements";
import { useToastAlert } from "../../../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.depressed,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed",
    borderColor: theme.palette.border.primary,
    padding: theme.spacing(2.75),
    borderRadius: theme.spacing(1),
    height: 300,
    width: "100%",
  },

  icon: {
    backgroundColor: theme.palette.avatar,
    color: theme.palette.selected.main,
    borderRadius: "50%",
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    padding: theme.spacing(2.25),
    marginBottom: theme.spacing(1),
  },

  text: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },

  button: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5, 5),
    border: "2px solid",
    borderColor: theme.palette.common.black,
    borderRadius: "6px",
    fontWeight: "bold",
  },
}));

type CreateUrlOptions = MutateOptions<
  SignedUrlVm,
  unknown,
  ICreateSignedUrlCommand,
  unknown
>;

type CheckInUploadClintPhotoProps = {
  name: string;
  value?: ProgressImageInfo;
  onChange: (name: string, value: ProgressImageInfo | null) => void;
  createUrl: (
    variables: ICreateSignedUrlCommand,
    options?: CreateUrlOptions,
  ) => void;
  id: string;
  idEntityType?: string;
  disabled: boolean;
};

const CheckInUploadClientPhoto = (props: CheckInUploadClintPhotoProps) => {
  const { name, value, createUrl, id, onChange } = props;
  const idEntityType = props.idEntityType ?? "User";
  const ref = React.useRef<HTMLDivElement>(null);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const s = useStyles();
  const { showToastAlert } = useToastAlert();
  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: ([file], errors) => {
      if (errors.length) {
        const message = errors
          .map((error) => error?.errors.map((e) => e.message).join(", ") ?? "")
          .join("; ");
        showToastAlert("error", { message });
        console.error(errors);
        return;
      } else if (!file) {
        return;
      }

      setUploading(true);

      createUrl(
        {
          refId: parseInt(id, 10),
          refType: idEntityType,
          file: file.name,
          assetType: AssetType.PROGRAM_CHECKIN_PHOTO,
        },
        {
          onError: (error, variables, context) => {
            console.error(error);
            const message = errors
              .map(
                (error) => error?.errors.map((e) => e.message).join(", ") ?? "",
              )
              .join("; ");
            showToastAlert("error", { message });
          },
          onSuccess: ({ url }) => {
            uploadFile(url, file)
              .then(() => {
                const urlInstance = new URL(
                  `https:/${url.substr(url.indexOf("/", 8))}`,
                );
                urlInstance.search = "";
                const uploadUrl = String(urlInstance);

                onChange(name, {
                  url: uploadUrl,
                  fileName: file.name,
                  fileSize: file.size,
                });
              })
              .catch((err) => {
                console.error(err);
                const message = errors
                  .map(
                    (error) =>
                      error?.errors.map((e) => e.message).join(", ") ?? "",
                  )
                  .join("; ");
                showToastAlert("error", { message });
              })
              .finally(() => {
                setUploading(false);
              });
          },
        },
      );
    },
    accept: getMimeTypes(ElementType.IMAGE),
  });

  const handleRemove = React.useCallback(() => {
    onChange(name, null);
  }, [name, onChange]);

  const renderDND = () => (
    <>
      <Box className={s.icon}>
        <CloudUpload />
      </Box>
      <Typography className={s.text} variant="body2">
        Drag and drop here
      </Typography>
      <Typography className={s.text} variant="body2">
        or
      </Typography>
      <Button className={s.button} variant="outlined" disabled={uploading}>
        {uploading ? "Uploading…" : "Upload"}
      </Button>
      <input disabled={uploading} type="file" {...getInputProps()} />
    </>
  );

  const renderImage = () => (
    <CheckInAnswerProgressPhotoView image={value} onRemove={handleRemove} />
  );

  return (
    <Box className={s.root} {...getRootProps({ ref })}>
      {value ? renderImage() : renderDND()}
    </Box>
  );
};

export default CheckInUploadClientPhoto;
