import { nutritionTargetsClient } from "../api/ApiClients";
import {
    IApplySingleOrdinalNutritionTargetsCommand,
    ApplySingleOrdinalNutritionTargetsCommand,
    ICreateNutritionTargetCommand,
    CreateNutritionTargetCommand,
    IUpdateNutritionTargetCommand,
    UpdateNutritionTargetCommand,
    IReorderNutritionTargetCommand,
    ReorderNutritionTargetCommand,   
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class NutritionEntriesService extends ServiceBase {
  async getNutritionTargets(clientUsername: string) {
    return nutritionTargetsClient.getNutritionTargets(clientUsername);
  }

  async applySingleOrdinalNutritionTarget(
    variables: IApplySingleOrdinalNutritionTargetsCommand,
  ){
    const command = ApplySingleOrdinalNutritionTargetsCommand.fromJS(variables);

    return nutritionTargetsClient.applySingleOrdinalTarget(command);
  }

  async createNutritionTarget(
    variables: ICreateNutritionTargetCommand,
  ) {
    const command = CreateNutritionTargetCommand.fromJS(variables);

    return nutritionTargetsClient.createNutritionsTarget(command);
  }

  async updateNutritionEntry(
    variables: IUpdateNutritionTargetCommand,
  ) {
    const command = UpdateNutritionTargetCommand.fromJS(variables);

    return nutritionTargetsClient.updateNutritionTarget(command.id!, command);
  }

  async reorderNutritionTargets(
    variables: IReorderNutritionTargetCommand,
  ){
    const command = ReorderNutritionTargetCommand.fromJS(variables);

    return nutritionTargetsClient.reorderNutritionTargets(command)
  }

  async deleteNutritionTarget(nutritionTargetId: number) {
    return nutritionTargetsClient.deleteNutritionsTarget(nutritionTargetId);
  }
}

export default new NutritionEntriesService();
