import clsx from "clsx";
import React from "react";
import { Box, BoxProps, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldsGroup } from "../fields/FieldsGroup";
import {
  BodyWeightHeightField,
  BodyWeightHeightValue,
} from "../fields/BodyWeightHeightField";
import { LocationField } from "../fields/LocationField";
import { BirthdayField } from "../fields/BirthdayField";
import { TimezoneField } from "../fields/TimezoneField";
import { GenderField } from "../fields/GenderField";
import { Units, Gender } from "../../constants";

import TagField from "../fields/TagField";
import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import dayjs from "dayjs";
import { UserInfo } from "../../hooks/useCurrentUser";
import {
  BriefClientInfo,
  IBriefClientInfo,
  TagDto,
} from "@growth-machine-llc/stridist-api-client";
import { DefaultLoader } from "../loading/DefaultLoader";

const useStyles = makeStyles((theme) => ({
  root: {},
  caretDownIcon: {
    width: 11,
  },
  textField: {},
  input: {
    paddingRight: 16,
  },
}));

export type ClientSettings = UserInfo | IBriefClientInfo;
export interface ClientSettingsFormProps
  extends Omit<BoxProps, "disabled" | "onChange"> {
  user: ClientSettings;
  onChange?: (settings: ClientSettings) => void;
  disabled?: boolean;
  variant?: "client" | "coach";
  disableTimeZone?: boolean;
  onChangeTag?: (value: string) => void;
  tag?: string;
  allTags?: TagDto[];
  tagsLoading?: boolean;
  onDeleteTag?: (id: number, title: string) => void;
  isClient?: boolean;
}

export function ClientSettingsForm(props: ClientSettingsFormProps) {
  const {
    className,
    user,
    disabled,
    onChange,
    variant = "client",
    disableTimeZone = false,
    onChangeTag,
    tag,
    allTags,
    tagsLoading,
    onDeleteTag,
    isClient = false,
    ...other
  } = props;
  const s = useStyles();
  const [settings, setSettings] = React.useState(user);
  const initialTimeZone = React.useMemo(() => dayjs.tz.guess(), []);
  const {
    displayName,
    email,
    username,
    gender,
    height,
    weight,
    units,
    location,
    birthday,
  } = settings;
  const timeZone = settings.timeZone || initialTimeZone;

  const bodyWeightHeight: BodyWeightHeightValue = {
    units: units as Units,
    height: height?.toString(),
    weight: weight?.toString(),
  };

  const updateSettings = React.useCallback(
    (settings: ClientSettings) => {
      setSettings(settings);
      if (onChange) {
        onChange(settings);
      }
    },
    [onChange],
  );

  const updateTagInput = React.useCallback(
    (event, text: string) => {
      if (event && onChangeTag) {
        onChangeTag(text);
      }
    },
    [onChangeTag],
  );

  const updateTagChange = React.useCallback(
    (text: string) => {
      if (text.length < 15) {
        if (onChangeTag) {
          onChangeTag(text);
        }
      }
    },
    [onChangeTag],
  );

  const handleDisplayNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      updateSettings({
        ...settings,
        displayName: event.target.value,
      });
    },
    [settings, updateSettings],
  );

  const handleBodyWeightHeightChange = React.useCallback(
    ({ height, weight, units }: BodyWeightHeightValue) => {
      updateSettings({
        ...settings,
        height: +height,
        weight: +weight,
        units,
      });
    },
    [settings, updateSettings],
  );

  const handleLocationChange = React.useCallback(
    (location: string) => {
      updateSettings({
        ...settings,
        location,
      });
    },
    [settings, updateSettings],
  );

  const handleBirthdayChange = React.useCallback(
    (birthday: string) => {
      updateSettings({
        ...settings,
        birthday,
      });
    },
    [settings, updateSettings],
  );

  const handleTimeZoneChange = React.useCallback(
    (timeZone: string) => {
      updateSettings({
        ...settings,
        timeZone,
      });
    },
    [settings, updateSettings],
  );

  const handleGenderChange = React.useCallback(
    (gender: Gender) => {
      updateSettings({
        ...settings,
        gender,
      });
    },
    [settings, updateSettings],
  );

  const incomplete = displayName && username !== displayName;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <FieldsGroup label="Full name">
        <TextField
          variant="outlined"
          onChange={handleDisplayNameChange}
          value={displayName}
          disabled={disabled}
          placeholder={
            variant === "client" ? "Please add your name" : "Client's name"
          }
          fullWidth
        />
      </FieldsGroup>

      {variant === "coach" && (
        <FieldsGroup label="Email">
          <TextField
            variant="outlined"
            value={email}
            disabled={true}
            fullWidth
          />
        </FieldsGroup>
      )}

      <BodyWeightHeightField
        onChange={handleBodyWeightHeightChange}
        value={bodyWeightHeight}
        disabled={disabled}
        error={incomplete}
      />

      <LocationField
        value={location}
        onChange={handleLocationChange}
        disabled={disabled}
      />

      <BirthdayField
        onChange={handleBirthdayChange}
        value={birthday}
        disabled={disabled}
        error={incomplete && !birthday}
      />

      {!disableTimeZone && (
        <TimezoneField
          value={timeZone}
          onChange={handleTimeZoneChange}
          disabled={disabled}
          error={incomplete && !timeZone}
        />
      )}

      <GenderField
        onChange={handleGenderChange}
        value={gender as Gender}
        disabled={disabled}
        error={incomplete && !gender}
      />

      {!isClient && (
        <TagField
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              className={s.textField}
              placeholder={"Add client's tag"}
              InputProps={{
                ...params.InputProps,
                disabled: disabled || tagsLoading,
                className: s.input,
                endAdornment: tagsLoading ? (
                  <Box marginRight={2}>
                    <DefaultLoader size="small" />
                  </Box>
                ) : (
                  <CaretDownIcon className={s.caretDownIcon} color="grey" />
                ),
              }}
            />
          )}
          allTags={allTags}
          inputValue={tag}
          onInputChange={updateTagInput}
          onChange={updateTagChange}
          onDeleteTag={onDeleteTag}
          disabled={disabled || tagsLoading}
        />
      )}
    </Box>
  );
}
