import { InfiniteData } from "@tanstack/react-query";
import { GROUP_POST_COMMENTS_LIST_QUERY_KEY } from "../../../hooks/groupPosts/useGroupPostComments";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import {
  createInfinitePaginatedDataUpdater,
  INFINITE_PAGINATED_DATA_UPDATERS,
} from "../../../utils/optimisticUpdate";
import { GROUP_POSTS_LIST_QUERY_KEY } from "../../group-posts/GroupPostsList";
import {
  GroupPostCommentDto,
  IGroupPostCommentDto,
  ICursorPaginatedListOfGroupPostDto,
  ICursorPaginatedListOfGroupPostCommentDto,
  UserBriefInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import GroupPostsService from "../../../services/GroupPostsService";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

type UseLikeMutationParams = {
  groupId?: number;
  postId?: number;
  target: "post" | "comment";
  isReply?: boolean;
};

export const useToggleLikeMutation = ({
  target,
  groupId,
  postId,
  isReply,
}: UseLikeMutationParams) => {
  const TARGET_CACHE_KEYS = {
    post: GROUP_POSTS_LIST_QUERY_KEY,
    comment: GROUP_POST_COMMENTS_LIST_QUERY_KEY,
  };

  const TARGET_LIKE_MUTATION_FN = {
    post: GroupPostsService.likeGroupPost,
    comment: GroupPostsService.likeGroupPostComment,
  };

  const TARGET_UNLIKE_MUTATION_FN = {
    post: GroupPostsService.unLikeGroupPost,
    comment: GroupPostsService.unLikeGroupPostComment,
  };

  const queryKey = [
    TARGET_CACHE_KEYS[target],
    groupId ? { groupId } : { postId },
  ];
  const updater = INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties;

  const { id: currentUserId, email, displayName, photoUrl } = useCurrentUser();

  const author = UserBriefInfoDto.fromJS({
    id: Number(currentUserId),
    email,
    displayName,
    photoUrl,
  });

  const updateReplies = (
    prev: InfiniteData<
      | ICursorPaginatedListOfGroupPostCommentDto
      | ICursorPaginatedListOfGroupPostDto
    >,
    id: number,
    like: boolean,
  ) => {
    const update = createInfinitePaginatedDataUpdater<
      IGroupPostCommentDto,
      void
    >((items) => {
      return items.map((comment) => ({
        ...comment,
        replies: comment.replies.map((reply) =>
          reply.id === id
            ? {
                ...reply,
                likedByMe: like,
                totalLikes: like ? reply.totalLikes + 1 : reply.totalLikes - 1,
                likes: like
                  ? [
                      ...reply.likes,
                      {
                        author: author,
                      },
                    ]
                  : reply.likes.filter(
                      (likeItem) =>
                        likeItem.author.id !== Number(currentUserId),
                    ),
              }
            : reply,
        ),
      })) as GroupPostCommentDto[];
    });
    return update(prev);
  };

  const updateFn = (
    prev: InfiniteData<
      | ICursorPaginatedListOfGroupPostCommentDto
      | ICursorPaginatedListOfGroupPostDto
    >,
    id: number,
    like: boolean,
  ) =>
    !isReply
      ? updater(prev, id, (c) => {
          return {
            ...c,
            likedByMe: like,
            totalLikes: like ? c.totalLikes + 1 : c.totalLikes - 1,
            likes: like
              ? [
                  ...c.likes,
                  {
                    author: author,
                  },
                ]
              : c.likes.filter(
                  (likeItem) => likeItem.author.id !== Number(currentUserId),
                ),
          } as GroupPostCommentDto;
        })
      : updateReplies(prev, id, like);

  const likeUpdate = (prev, id: number) => updateFn(prev, id, true);
  const unlikeUpdate = (prev, id: number) => updateFn(prev, id, false);

  const { mutate: like, isPending: likeLoading } = useOptimisticUpdateMutation({
    queryKey: queryKey,
    mutationFn: TARGET_LIKE_MUTATION_FN[target],
    optimisticUpdater: {
      updateFn: likeUpdate,
    },
    disableToastAlerts: true,
  });

  const { mutate: unlike, isPending: unlikeLoading } =
    useOptimisticUpdateMutation({
      queryKey: queryKey,
      mutationFn: TARGET_UNLIKE_MUTATION_FN[target],
      optimisticUpdater: {
        updateFn: unlikeUpdate,
      },
      disableToastAlerts: true,
    });

  const isLoading = likeLoading || unlikeLoading;
  return { like, unlike, isLoading };
};
