import {
  IUpdateInviteCodeStartDateCommand,
  ProgramInviteCodeDto,
  ProgramSettingsDto,
} from "@growth-machine-llc/stridist-api-client";
import { PROGRAM_SETTINGS_QUERY_KEY } from "../../../../routes/coach/program/settings/CoachProgramSettingsRoute";
import { useQueryClient } from "@tanstack/react-query";
import InviteCodesService from "../../../../services/InviteCodesService";
import { useOptimisticUpdateMutation } from "../../../../hooks/useOptimisticUpdateMutation";

interface UpdateInviteCodeStartDateProps {
  slugId: string;
}

export const useUpdateInviteCodeStartDateMutation = ({
  slugId,
}: UpdateInviteCodeStartDateProps) => {
  const queryClient = useQueryClient();

  return useOptimisticUpdateMutation({
    queryKey: [PROGRAM_SETTINGS_QUERY_KEY, { slugId }],
    mutationFn: InviteCodesService.updateCodeStartDate,
    optimisticUpdater: {
      updateFn: (
        oldData: ProgramSettingsDto,
        variables: IUpdateInviteCodeStartDateCommand,
      ) =>
        new ProgramSettingsDto({
          ...oldData,
          inviteCode: new ProgramInviteCodeDto({
            ...oldData.inviteCode,
            startDate: variables.startDate,
          }),
        }),
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [PROGRAM_SETTINGS_QUERY_KEY, { slugId }],
        });
      },
    },
  });
};
