import React from "react";
import { isEqual } from "lodash";

export function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current as any;
}
