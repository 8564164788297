import React from "react";
import {
  Card,
  CardProps,
  CardHeader,
  Button,
  Paper,
  CardActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { CardAvatar } from "../card/CardAvatar";
import { ListMenu } from "../menu/ListMenu";
import { colorSystem } from "../../theme";

import {
  EnrollmentGroupDto,
  EnrollmentsVm,
  GroupDto,
} from "@growth-machine-llc/stridist-api-client";
import { useQueryClient } from "@tanstack/react-query";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { PROGRAM_ENROLLMENTS_QUERY_KEY } from "../../routes/coach/program/enrollments/CoachProgramEnrollmentsRoute";

import { MANAGE_PROGRAM_GROUPS_DIALOG_QUERY_KEY } from "./ClientsAndGroupsSelectDialog";
import { COACH_PROGRAMS_WRAPPER_QUERY_KEY } from "../../wrappers/router/coach/CoachProgramsWrapper";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { PaginationContext } from "../../utils/paging";
import { useUnenrollMutation } from "../dialog/mutations/UnenrollMutation";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    position: "relative",
  },

  header: {
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },

  cardTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  cardSubheader: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },

  metrics: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
    },
  },

  actions: {
    padding: theme.spacing(4),
  },

  menuButton: {
    minWidth: theme.spacing(5.5),
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    backgroundColor: colorSystem.secondaryGray,
  },
}));

export interface GroupEnrollmentCardProps extends CardProps {
  groupEnrollment: EnrollmentGroupDto;
}

export function GroupEnrollmentCard(props: GroupEnrollmentCardProps) {
  const { groupEnrollment, ...other } = props;
  const s = useStyles();
  const { id, group } = groupEnrollment;
  const { programSlug } = React.useContext(PaginationContext);
  const menuState = usePopupState({
    variant: "popover",
    popupId: "groupMenu",
  });
  const triggerProps = React.useMemo(() => bindTrigger(menuState), [menuState]);
  const menuProps = React.useMemo(() => bindMenu(menuState), [menuState]);

  const { mutate: unenrollGroup, isPending: unenrollGroupInFlight } =
    useUnenrollMutation("group", programSlug);

  const handleUnenrollGroup = React.useCallback(() => {
    menuProps.onClose();

    unenrollGroup(id);
  }, [id, menuProps, unenrollGroup]);

  const menuOptions = [
    {
      label: "Remove from Program",
      onClick: handleUnenrollGroup,
    },
  ];

  return (
    <Card {...other}>
      <Paper className={s.actionArea}>
        <CardHeader
          className={s.header}
          avatar={
            <CardAvatar
              className={s.avatar}
              variant="square"
              children={group.name.substring(0, 1)}
            />
          }
          title={group.name}
          titleTypographyProps={{ variant: "h5", className: s.cardTitle }}
          subheader={`${group.size} member${group.size !== 1 ? "s" : ""}`}
          subheaderTypographyProps={{ className: s.cardSubheader }}
        />
        <CardActions className={s.actions}>
          <Button
            {...triggerProps}
            className={s.menuButton}
            children={<MoreHoriz />}
          />
        </CardActions>
      </Paper>

      <ListMenu
        {...menuProps}
        options={menuOptions}
        disabled={unenrollGroupInFlight}
      />
    </Card>
  );
}
