import clsx from "clsx";
import React, { ComponentProps } from "react";
import { BoxProps, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { MessageEditor } from "../new-editor/MessageEditor";

import {
  ActivityBriefDto,
  ActivityDto,
  ClientFormActivityDto,
  CreateActivityFeedbackResponse,
  ICreateActivityFeedbackCommand,
  MealLoggingActivityDto,
} from "@growth-machine-llc/stridist-api-client";

import { useCreateActivityFeedbackMutation } from "./mutations/useCreateActivityFeedbackMutation";
import { useMutation } from "@tanstack/react-query";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface FeedbackFormProps extends BoxProps {
  activity: (
    | ActivityDto
    | MealLoggingActivityDto
    | ClientFormActivityDto
    | ActivityBriefDto
  ) & {
    displayName?: string;
  };
  formRef?: React.MutableRefObject<HTMLFormElement>;
  createFeedbackMutation: ReturnType<
    typeof useMutation<
      CreateActivityFeedbackResponse,
      unknown,
      ICreateActivityFeedbackCommand
    >
  >;
}

export function FeedbackForm(props: FeedbackFormProps) {
  const s = useStyles();
  const theme = useTheme();
  const {
    className,
    activity,
    formRef,
    createFeedbackMutation: useCreateFeedback,
    ...other
  } = props;
  const { displayName } = "client" in activity ? activity.client : activity;

  const { mutate: submit, isPending: submitInFlight } = useCreateFeedback;

  const user = useCurrentUser();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const placeholder =
    user.role === UserRole.COACH
      ? `Send feedback${smUp ? ` to ${displayName}` : ""}`
      : "Send a comment to your coach";

  const handleMessageSubmit = React.useCallback(
    (fragment?: Node[], callback?: () => void) => {
      submit(
        {
          activityId: activity.id,
          content: JSON.stringify(fragment),
        },
        {
          onSuccess: () => {
            callback();
          },
        },
      );
    },
    [submit, activity.id],
  );

  return (
    <MessageEditor
      className={clsx(s.root, className)}
      fullWidth
      backupKeyType="feedback"
      backupKeyId={activity.id.toString()}
      disabled={submitInFlight}
      creatingInFlight={submitInFlight}
      onMessageSubmit={handleMessageSubmit}
      placeholder={placeholder}
    />
  );
}
