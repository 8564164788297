import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PendingInviteItem } from "../item/PendingInviteItem";
import { colorSystem } from "../../theme";

import {
  ClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { SendEmailDialog } from "../dialog/SendEmailDialog";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

import useRevokeInviteMutation from "../coach-clients/mutations/useRevokeInviteMutation";
import useResendInviteMutation from "../coach-clients/mutations/useResendInviteMutation";
import useRevokeAddedClientMutation from "../coach-clients/mutations/useRevokeAddedClientMutation";
import useInviteAddedClientsMutation from "../coach-clients/mutations/useInviteAddedClientsMutation";
import { PendingInviteMenu } from "../menu/PendingInviteMenu";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { LoadMoreButton } from "../button/LoadMoreButton";

const useStyles = makeStyles((theme) => ({
  root: {},

  item: {
    padding: theme.spacing(3, 0),
  },

  divider: {
    backgroundColor: colorSystem.secondaryGray,
  },

  text: {},
}));

export interface PendingInvitesListProps extends BoxProps {
  invites: ClientInfoDto[];
  loadMore: () => void;
  loadMoreRef: (node: HTMLDivElement) => void;
  fetching: boolean;
  hasMore: boolean;
  totalCount: any;
}

const prefetchDistance = 3;

export function PendingInvitesList(props: PendingInvitesListProps) {
  const {
    className,
    invites,
    totalCount,
    loadMore,
    loadMoreRef,
    fetching,
    hasMore,
  } = props;
  const s = useStyles();

  const { showToastAlert } = useToastAlert();

  const [emailDialogIsOpen, setEmailDialogIsOpen] = React.useState(false);
  const [selectedInvite, setSelectedInvite] = React.useState<ClientInfoDto>();

  const { mutate: resendInvite, isPending: resendInviteInFlight } =
    useResendInviteMutation();

  const { mutate: revokeInvite, isPending: revokeInviteInFlight } =
    useRevokeInviteMutation();

  const { mutate: deleteAddedUser, isPending: deleteAddedUserInFlight } =
    useRevokeAddedClientMutation();

  const { mutate: inviteAddedClient, isPending: invitingAddedClientMutation } =
    useInviteAddedClientsMutation();

  const isAdded = selectedInvite?.status === UserInviteStatus.ADDED;

  const menuState = usePopupState({
    variant: "popover",
    popupId: "pendingInviteMenu",
  });

  const menuTriggerProps = React.useMemo(
    () => bindTrigger(menuState),
    [menuState],
  );

  const menuProps = React.useMemo(() => bindMenu(menuState), [menuState]);

  const getOnCompleted = React.useCallback(
    (message: string) => () => {
      showToastAlert("success", {
        message,
      });
    },
    [showToastAlert],
  );

  const handleResendInvite = React.useCallback(() => {
    menuState.close();
    resendInvite({
      id: selectedInvite.invite.id,
    });
  }, [resendInvite, selectedInvite, getOnCompleted]);

  const handleRevokeInvite = React.useCallback(() => {
    menuState.close();
    revokeInvite(selectedInvite.invite.id);
  }, [revokeInvite, selectedInvite, getOnCompleted]);

  const handleDeleteAddedUser = React.useCallback(() => {
    menuState.close();
    deleteAddedUser(selectedInvite.id);
  }, [getOnCompleted, selectedInvite, deleteAddedUser]);

  const handleOpenEmailDialog = React.useCallback(() => {
    menuState.close();
    setEmailDialogIsOpen(true);
  }, [setEmailDialogIsOpen]);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogIsOpen(false);
  }, [setEmailDialogIsOpen]);

  const handleSendEmail = React.useCallback(
    (message: string) => {
      inviteAddedClient(
        {
          ids: [selectedInvite.id],
          message: message,
        },
        {
          onSuccess: () => {
            handleCloseEmailDialog();
            showToastAlert("success", {
              message: "User Invited",
            });
          },
        },
      );
    },
    [selectedInvite, getOnCompleted, inviteAddedClient, totalCount],
  );

  return (
    <>
      {invites?.length ? (
        <Box className={clsx(s.root, className)}>
          {invites.map((invite, index) => (
            <React.Fragment key={invite.id}>
              <PendingInviteItem
                className={s.item}
                user={invite}
                totalCount={totalCount}
                selectInvite={setSelectedInvite}
                menuTriggerProps={menuTriggerProps}
              />
              {index !== invites.length - 1 && (
                <Divider className={s.divider} />
              )}
              {index === invites.length - prefetchDistance && hasMore && (
                <div ref={loadMoreRef} />
              )}
            </React.Fragment>
          ))}
          {hasMore && <LoadMoreButton onClick={loadMore} loading={fetching} />}
        </Box>
      ) : (
        <Typography className={s.text}>
          There are currently no pending invites.
        </Typography>
      )}
      <SendEmailDialog
        open={emailDialogIsOpen}
        onClose={handleCloseEmailDialog}
        sendingEmail={invitingAddedClientMutation}
        sendEmail={handleSendEmail}
      />
      <PendingInviteMenu
        disabled={
          resendInviteInFlight ||
          revokeInviteInFlight ||
          deleteAddedUserInFlight
        }
        handleResendInvite={
          isAdded ? handleOpenEmailDialog : handleResendInvite
        }
        handleRevokeInvite={
          isAdded ? handleDeleteAddedUser : handleRevokeInvite
        }
        isAdded={isAdded}
        {...menuProps}
      />
    </>
  );
}
