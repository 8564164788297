import React from "react";
import { BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { GroupPostCommentsListItemProps } from "./GroupPostCommentsListItem";
import { GroupPostCommentsListItem } from "./GroupPostCommentsListItem";
import { GroupPostCommentReplyDto } from "@growth-machine-llc/stridist-api-client";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::before": {
      left: "21px !important",
    },
  },

  header: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },

  avatar: {
    marginLeft: "0 !important",
  },

  body: {
    paddingLeft: "56px !important",
  },
}));

export interface GroupPostCommentsListItemNoRepliesProps
  extends BoxProps,
    Pick<GroupPostCommentsListItemProps, "first" | "onReplyClick"> {
  groupId: number;
  postId: number;
  comment: GroupPostCommentReplyDto;
  parentCommentId?: number;
}

export function GroupPostCommentsListItemNoReplies(
  props: GroupPostCommentsListItemNoRepliesProps,
) {
  const { className, comment, groupId, postId, parentCommentId, ...other } =
    props;
  const s = useStyles();

  return (
    <OptimisticUpdateContainer id={comment.id}>
      <GroupPostCommentsListItem
        groupId={groupId}
        postId={postId}
        comment={comment}
        parentCommentId={comment.id}
        className={s.root}
        headerClassName={s.header}
        avatarClassName={s.avatar}
        bodyClassName={s.body}
        {...other}
      />
    </OptimisticUpdateContainer>
  );
}
