import { notificationsClient } from "../api/ApiClients";
import {
  ClickNotificationCommand,
  IClickNotificationCommand,
  IReadNotificationCommand,
  ISetDeleteNotificationCommand,
  ReadAllNotificationsCommand,
  ReadNotificationCommand,
  SetDeleteNotificationCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";
import { Dayjs } from "dayjs";

class NotificationsService extends ServiceBase {
  async getNotifications(afterCursor: number | null, pageSize: number) {
    return notificationsClient.getNotifications(pageSize, afterCursor);
  }
  async getNotification(id: number) {
    return notificationsClient.getNotification(id);
  }
  async readNotification(variables: IReadNotificationCommand) {
    const command = new ReadNotificationCommand(variables);

    return notificationsClient.readNotification(command);
  }
  async readAllNotifications() {
    const command = new ReadAllNotificationsCommand();
    return notificationsClient.readAllNotifications(command);
  }
  async deleteNotification(variables: ISetDeleteNotificationCommand) {
    const command = new SetDeleteNotificationCommand(variables);
    return notificationsClient.deleteNotification(variables.id, command);
  }
  async clickNotifications() {
    const command = new ClickNotificationCommand();
    return notificationsClient.clickNotification(command);
  }
}

export default new NotificationsService();
