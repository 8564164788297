import React from "react";
import { GroupSettingsScreen } from "../../../../components/group-settings/GroupSettingsScreen";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useGroupRoute } from "../CoachGroupRouteWrapper";

export function CoachGroupSettingsRoute() {
  const { group } = useGroupRoute();
  return (
    <>
      {group && <GroupSettingsScreen group={group} />}
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Group Settings",
          properties: {
            groupSlug: group.slug,
            groupName: group.name,
          },
        }}
      />
    </>
  );
}
