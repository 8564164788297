import clsx from "clsx";
import React from "react";
import { Box, BoxProps, CircularProgress, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GroupPostCommentsListItemWithReplies } from "./GroupPostCommentsListItemWithReplies";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { GroupPostCommentDto } from "@growth-machine-llc/stridist-api-client";
import { CircularLoader } from "../loading/CircularLoader";

const useStyles = makeStyles((theme) => ({
  root: {},

  divider: {
    height: 2,
    backgroundColor: theme.palette.quote,
    opacity: 0.4,
  },

  loadMore: {
    borderRadius: 0,
  },
}));

export interface GroupPostCommentsListProps extends BoxProps {
  groupId: number;
  postId: number;
  comments: GroupPostCommentDto[];
  pendingComments: GroupPostCommentDto[];
  totalComments: number;
  hasMoreComments: boolean;
  fetchMoreComments: () => void;
  fetchingMoreComments: boolean;
  fetching: boolean;
}

export function GroupPostCommentsList(props: GroupPostCommentsListProps) {
  const {
    className,
    groupId,
    postId,
    comments,
    pendingComments,
    totalComments,
    hasMoreComments,
    fetchMoreComments,
    fetchingMoreComments,
    fetching,
    ...other
  } = props;

  const s = useStyles();

  const handleMoreClick = React.useCallback(() => {
    if (!fetchingMoreComments) {
      fetchMoreComments();
    }
  }, [fetchingMoreComments, fetchMoreComments]);

  return (
    totalComments > 0 && (
      <Box className={clsx(s.root, className)} {...other}>
        {fetching ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            py={2}
          >
            <CircularLoader />
          </Box>
        ) : (
          comments.map((comment, index) => (
            <React.Fragment key={comment.id}>
              {index === 0 && <Divider />}
              <GroupPostCommentsListItemWithReplies
                groupId={groupId}
                postId={postId}
                comment={comment}
                first={index === 0}
              />
            </React.Fragment>
          ))
        )}
        {hasMoreComments && (
          <React.Fragment>
            <Divider />
            <LoadMoreButton
              className={s.loadMore}
              onClick={handleMoreClick}
              disabled={fetching}
              loading={fetchingMoreComments}
              fullWidth
            >
              {`Show more comments (${totalComments - comments.length - pendingComments.length})`}
            </LoadMoreButton>
          </React.Fragment>
        )}
        {pendingComments.map((comment, index) => (
          <React.Fragment key={comment.id}>
            {hasMoreComments && index === 0 && <Divider />}
            <GroupPostCommentsListItemWithReplies
              groupId={groupId}
              postId={postId}
              comment={comment}
              first={false}
            />
          </React.Fragment>
        ))}
      </Box>
    )
  );
}
