import { ProgramFolderDto } from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import CoachProgramsService from "../../../services/CoachProgramsService";
import { COACH_PROGRAM_FOLDERS_QUERY_KEY } from "../../../wrappers/router/coach/CoachProgramsListWrapper";

export const useDeleteProgramFolderMutation = () => {
  return useOptimisticUpdateMutation({
    mutationFn: CoachProgramsService.deleteProgramFolder,
    queryKey: [COACH_PROGRAM_FOLDERS_QUERY_KEY],
    optimisticUpdater: {
      updateFn: optimisticUpdater,
    },
  });
};

const optimisticUpdater = (oldData: ProgramFolderDto[], id: number) => {
  return oldData.filter((folder) => folder.id !== id);
};
