import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  CardActionArea,
  CardMedia,
  Box,
  Typography,
  CardContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import {
  COACH_CLIENT_PR0GRAM_ROUTE,
  PROGRAMS_ROUTE,
} from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { ClientEnrollmentDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },

  actionArea: {
    height: 133,
  },

  actions: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
  },

  media: {
    height: "100%",
    boxShadow:
      "inset 0px -1px 2px rgba(0, 0, 0, 0.1), inset 0px -48px 16px rgba(0, 0, 0, 0.2), inset 0px -1px 2px rgba(0, 0, 0, 0.05)",
  },

  textBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    background: "linear-gradient(transparent, hsla(0, 0%, 0%, 0.6))",
    overflow: "hidden",

    "& > *": {
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: "26px",
  },

  content: {
    padding: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
}));

export interface ClientSmallProgramCardProps extends CardProps {
  enrollment: ClientEnrollmentDto;
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
}

export function ClientSmallProgramCard(props: any) {
  const navigate = useNavigate();
  const { className, enrollment, subtitle, actions, content, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const {
    program: { name, image, slug },
    client: { username },
  } = enrollment;

  const link = React.useMemo(
    () =>
      user.role === UserRole.COACH
        ? COACH_CLIENT_PR0GRAM_ROUTE.replace(":program", slug)
            .replace(":username", username)
            .replace("/:date?", "")
        : `${PROGRAMS_ROUTE}/${slug}`,
    [slug, user.role, username],
  );

  const handleClick = React.useCallback(() => {
    navigate(link);
  }, [link]);

  return (
    <Card className={clsx(s.root, className)} {...other}>
      {actions && <Box className={s.actions}>{actions}</Box>}

      <CardActionArea
        className={s.actionArea}
        component="div"
        onClick={handleClick}
      >
        <CardMedia className={s.media} image={image} title={name} />

        <Box className={s.textBox}>
          <Typography className={s.subtitle} children={subtitle} />
          <Typography className={s.title} children={name} />
        </Box>
      </CardActionArea>

      <CardContent className={s.content}>{content}</CardContent>
    </Card>
  );
}
