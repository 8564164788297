import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { Container, ContainerProps, Portal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Calendar } from "../activity/Calendar";
import { ActivityByTypesList } from "../activity/ActivityByTypesList";
import { HomePageSkeleton } from "../loading/HomePageSkeleton";
import { useCurrentWorkspace } from "../../hooks/useCurrentWorkspace";
import { useRequestPushNotifications } from "../../utils/device";

import { InfoBox } from "../info/InfoBox";
import { useNavigate } from "react-router-dom";
import { CLIENT_HOME_ROUTE } from "../../routes/routes";
import HomeCalendarContext from "../../contexts/HomeCalendarContext";
import TrackInfoTool from "../tools/TrackInfoTool";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import ActivitiesService from "../../services/ActivitiesService";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
    position: "relative",
  },
  calendar: {
    marginBottom: theme.spacing(-3),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0),
    },
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  sectionHeader: {
    display: "flex",
    alignItems: "bottom",
    justifyContent: "space-between",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  habitQuestion: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  none: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
}));

export interface ClientHomeScreenProps
  extends Omit<ContainerProps, "children"> {
  date?: string;
}

export const CLIENT_HOME_ROUTE_ACTIVITY_DATE_RANGE_QUERY =
  "CLIENT_HOME_ROUTE_ACTIVITY_DATE_RANGE_QUERY";

export const CLIENT_HOME_ROUTE_ACTIVITY_QUERY =
  "CLIENT_HOME_ROUTE_ACTIVITY_QUERY";

export function ClientHomeScreen(props: ClientHomeScreenProps) {
  useRequestPushNotifications();
  const navigate = useNavigate();

  const calculateWeekIndexFromDate = (date?: string): number => {
    const today = dayjs();
    const targetDate = date ? dayjs(date) : today;
    const currentWeekStart = today.startOf("isoWeek");
    const targetWeekStart = targetDate.startOf("isoWeek");

    return targetWeekStart.diff(currentWeekStart, "week");
  };

  const { className, date, ...other } = props;
  const s = useStyles();
  const workspace = useCurrentWorkspace();
  const [weekIndex, setWeekIndex] = React.useState(() =>
    calculateWeekIndexFromDate(date),
  );
  const [enrollments, setEnrollments] = useState<string[]>([]);
  const handleChange = React.useCallback((_, date) => {
    navigate(CLIENT_HOME_ROUTE.replace(":date", date));
  }, []);
  const [loadedRanges, setLoadedRanges] = useState<
    { start: string; end: string }[]
  >([]);

  const weekIntervalSize = 2;

  function getWeekRangeForIndex(weekIndex: number): {
    start: string;
    end: string;
  } {
    const today = dayjs();

    const currentWeekStart = today.startOf("isoWeek");

    const targetWeekStart = currentWeekStart
      .add(weekIndex, "week")
      .startOf("isoWeek");

    const targetEndStart = currentWeekStart
      .add(weekIndex, "week")
      .endOf("isoWeek");

    const rangeStart = targetWeekStart.add(-weekIntervalSize, "week");
    const rangeEnd = targetEndStart.add(weekIntervalSize, "week");

    return {
      start: rangeStart.format("YYYY-MM-DD"),
      end: rangeEnd.format("YYYY-MM-DD"),
    };
  }

  const { data: enrollmentsData, isLoading: isDateRangeLoading } = useQuery({
    queryKey: [CLIENT_HOME_ROUTE_ACTIVITY_DATE_RANGE_QUERY, { weekIndex }],
    queryFn: () => {
      const { start, end } = getWeekRangeForIndex(weekIndex);

      let adjustedStart = start;
      let adjustedEnd = end;

      loadedRanges.forEach((range) => {
        if (start >= range.start && start <= range.end) {
          adjustedStart = dayjs(range.end).add(1, "day").format("YYYY-MM-DD");
        }
        if (end >= range.start && end <= range.end) {
          adjustedEnd = dayjs(range.start)
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        }
      });

      if (adjustedStart > adjustedEnd) {
        return Promise.resolve([]);
      }

      setLoadedRanges((prevRanges) => [...prevRanges, { start, end }]);
      return ActivitiesService.getActivitiesDateRange(
        adjustedStart,
        adjustedEnd,
      );
    },
  });

  useEffect(() => {
    if (enrollmentsData?.length > 0) {
      const uniqueItems = enrollmentsData.filter(
        (item) => !enrollments.some((enrollment) => enrollment === item),
      );

      if (uniqueItems.length > 0) {
        setEnrollments((prev) => [...prev, ...uniqueItems]);
      }
    }
  }, [enrollmentsData, enrollments]);

  const handleWeekChange = React.useCallback((weekIndexFromCalendar) => {
    setWeekIndex(weekIndexFromCalendar);
  }, []);

  const { data: activities, isLoading: isActivitiesLoading } = useQuery({
    queryKey: [CLIENT_HOME_ROUTE_ACTIVITY_QUERY, { date }],
    queryFn: () => ActivitiesService.getAllComponents(date),
  });

  const context = useContext(HomeCalendarContext);
  return (
    <>
      <Container className={clsx(s.root, className)} {...other}>
        <Portal container={() => context.current!}>
          {
            <Calendar
              className={s.calendar}
              date={date}
              enrollments={enrollments}
              onChange={handleChange}
              onWeekChange={handleWeekChange}
              disabled={isDateRangeLoading}
              disableTooltip
            />
          }
        </Portal>

        {isActivitiesLoading ? (
          <HomePageSkeleton className={clsx(s.root, className)} />
        ) : activities?.items.length > 0 ? (
          <>
            <ActivityByTypesList
              activities={activities.items}
              variant="normal"
            />
          </>
        ) : (
          <InfoBox
            className={s.none}
            title={Boolean(enrollments?.length) && "Take the day off!"}
            subtitle={
              enrollments.length
                ? "Your coach hasn't scheduled anything for this day."
                : "Your coach hasn't enrolled you in any program yet."
            }
          />
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client - Home",
        }}
      />
    </>
  );
}
