import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import { selectComponentTempIdsMap } from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";
import { ComponentStatus, IUpdateComponentCommand } from "@growth-machine-llc/stridist-api-client";

export default function* archiveComponentWorker(payload) {
  const { weekId, componentId, componentStatusData } = payload;
  if (!weekId || !componentId || !componentStatusData) return;

  const { status, previousStatus } = componentStatusData;
  if (!status) return;

  const newStatus =
    status === ComponentStatus.ARCHIVED
      ? previousStatus && previousStatus !== ComponentStatus.DRAFT
        ? previousStatus
        : ComponentStatus.DRAFT
      : ComponentStatus.ARCHIVED;

  const map: TempIdsMap = yield select(selectComponentTempIdsMap);
  const realId = map?.[componentId] || componentId;

  try {
    if (newStatus === ComponentStatus.ARCHIVED) {
      yield call(ComponentsService.archiveComponent, realId);
    } else {
      const command: IUpdateComponentCommand = {
        id: realId,
        status: newStatus,
      };
      yield call(ComponentsService.update, command);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
