import React, { useContext, useEffect, useRef } from "react";
import { Typography, Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CreateFirstProgram } from "../program/CreateFirstProgram";
import { UploadProfilePhotoDialog } from "../settings/dialogs/UploadProfilePhotoDialog";
import { polyfillCSS } from "../../utils/css";
import { ProgramSort, ProgramStatus } from "../../constants";

import { CoachProgramCard, CoachProgramCardDisplay } from "./CoachProgramCard";
import { useProgramListFilterContext } from "../../contexts/ProgramListFilterContext";
import { useSearchParams } from "react-router-dom";
import TrackInfoTool from "../tools/TrackInfoTool";
import CoachProgramsService from "../../services/CoachProgramsService";
import { useDebounce } from "../../hooks/useDebounce";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";
import { useCoachProgramsListScreenContext } from "../../contexts/CoachProgramsListScreenContext";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { PageSkeleton } from "../loading/PageSkeleton";
import { ProgramCardSkeleton } from "../loading/ProgramCardSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(5)} + var(--safe-area-inset-right)) !important`,
    ),
  },

  grid: {},
  list: {},

  clients: {
    display: "grid",
    gridGap: theme.spacing(3),
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      gridGap: theme.spacing(3.5),
    },

    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      gridGap: theme.spacing(4),
    },

    "$list &": {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
  },

  filters: {
    marginBottom: theme.spacing(3),
  },

  card: {
    marginBottom: theme.spacing(3),
  },

  moreButton: {
    marginTop: theme.spacing(1.5),
  },
}));

export type CoachProgramsListScreenProps = {
  status: ProgramStatus;
};

export const COACH_PROGRAMS_LIST_PAGE_SIZE = 12;
export const COACH_PROGRAMS_LIST_QUERY_KEY = "coach-programs";
const FETCH_NEXT_PAGE_WHEN_REMAINING = 4;

export function CoachProgramsListScreen(props: CoachProgramsListScreenProps) {
  const s = useStyles();
  const { status } = props;

  const { programFoldersLoading } = useCoachProgramsListScreenContext();
  const { query, sortKey, folderId, display } = useProgramListFilterContext();

  const debouncedQuery = useDebounce(query, 500);

  const {
    data: programsData,
    ref: programElementRef,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: initialLoading,
    isFetching,
  } = useInfiniteScrollQuery({
    queryKey: [
      COACH_PROGRAMS_LIST_QUERY_KEY,
      { status, query: debouncedQuery, orderBy: sortKey, folderId },
    ],
    queryFn: ({ pageParam = 1 }) =>
      CoachProgramsService.getPrograms({
        status: [status],
        orderBy: sortKey ?? ProgramSort.NAME_ASC,
        pageNumber: pageParam as number,
        clientsToTake: 5,
        pageSize: COACH_PROGRAMS_LIST_PAGE_SIZE,
        folderId: folderId ? folderId : null,
        ungrouped: folderId === "" ? true : null,
        query: debouncedQuery ? debouncedQuery : null,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.hasNextPage ? pages.length + 1 : undefined;
    },
  });
  const programs = React.useMemo(
    () => programsData?.pages?.flatMap((page) => page.items),
    [programsData?.pages],
  );

  const totalProgramsCount = React.useMemo(
    () => programsData?.pages?.[0]?.totalCount,
    [programsData?.pages],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const photoPrompt = searchParams.get("photoPrompt");

  const handleMoreClick = React.useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handlePhotoSaved = React.useCallback(() => {
    setSearchParams(undefined);
  }, [setSearchParams]);

  const handleSkipClick = React.useCallback(() => {
    setSearchParams(undefined);
  }, [setSearchParams]);

  const isLoading = initialLoading || (isFetching && !isFetchingNextPage);

  const showCreateFirstProgram =
    status === ProgramStatus.PUBLISHED && !debouncedQuery && !folderId;

  return (
    <>
      {!isLoading &&
        !!programs &&
        (totalProgramsCount === 0 ? (
          showCreateFirstProgram ? (
            <CreateFirstProgram />
          ) : (
            <Typography>Nothing to show here</Typography>
          )
        ) : (
          <Box
            className={s.clients}
            style={{
              display:
                display === CoachProgramCardDisplay.grid ? "grid" : "block",
            }}
          >
            {programs?.map((program, index, arr) => (
              <>
                <OptimisticUpdateContainer
                  id={program.id}
                  key={`optimistic-${program.id}`}
                >
                  <CoachProgramCard
                    key={program.id}
                    display={display}
                    className={s.card}
                    program={program}
                  />
                </OptimisticUpdateContainer>
                {index ===
                  (display === "list"
                    ? arr.length - FETCH_NEXT_PAGE_WHEN_REMAINING
                    : arr.length - 1) && (
                  <div
                    ref={programElementRef}
                    key={`program-${program.id}-element`}
                  ></div>
                )}
              </>
            ))}
          </Box>
        ))}

      {hasNextPage && (
        <LoadMoreButton
          onClick={handleMoreClick}
          fullWidth
          disabled={isLoading}
          loading={isFetchingNextPage}
        />
      )}

      {isLoading && (
        <PageSkeleton
          fullWidth
          ItemComponent={ProgramCardSkeleton}
          className={display === CoachProgramCardDisplay.grid && s.clients}
          itemProps={{ view: display }}
        />
      )}

      {photoPrompt && (
        <UploadProfilePhotoDialog
          open={Boolean(photoPrompt)}
          onSaved={handlePhotoSaved}
          onSkipClick={handleSkipClick}
        />
      )}

      <TrackInfoTool
        trackInfo={{
          name: "Coach - Programs",
          properties: {
            status,
          },
        }}
      />
    </>
  );
}
