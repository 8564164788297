import {
  PaginatedListOfClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import ClientsService from "../../../services/ClientsService";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { useQueryClient } from "@tanstack/react-query";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../../wrappers/router/coach/CoachClientProfileWrapper";

const useDeleteClientsMutation = () => {
  return useOptimisticUpdateMutation({
    queryKey: [
      COACH_CLIENTS_LIST_QUERY_KEY,
      { status: [UserInviteStatus.ARCHIVED] },
    ],
    mutationFn: ClientsService.batchDeleteClients,
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfClientInfoDto, { clientIds }) => {
        oldData.items = oldData.items.filter(
          (client) => !clientIds.some((id) => id === client.id),
        );
        return oldData;
      },
    },
  });
};

export default useDeleteClientsMutation;
