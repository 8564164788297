import clsx from "clsx";
import React, { useContext, useEffect, useRef } from "react";
import {
  AppBar,
  AppBarProps,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz, VisibilityOutlined } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { CoachComponentSavingState, ComponentType } from "../../constants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { usePreview, PreviewType } from "../../hooks/usePreview";
import { EditorMenu } from "../../components/menu/EditorMenu";
import { ComponentLibraryButton } from "../../components/component-library/ComponentLibraryButton";
import { EditorMenuProps } from "../../components/menu/EditorMenu";
import { IMPERSONATION_BANNER_SPACING } from "../app/ImpersonationBanner";
import { PREVIEW_BAR_SPACING } from "../preview/PreviewBar";
import { ComponentPublishButton } from "../program/ComponentPublishButton";

import dayjs from "dayjs";
import ComponentBarHeightContext from "../../contexts/ComponentBarHeightContext";
import { CircleX } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { CurriculumComponent } from "../../redux/types";

interface StyleArgs {
  impersonating: boolean;
  preview: PreviewType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: ({ impersonating, preview }: StyleArgs) =>
      impersonating || preview
        ? theme.spacing(
            impersonating ? IMPERSONATION_BANNER_SPACING : PREVIEW_BAR_SPACING,
          )
        : "initial",
    boxShadow: "none",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.quote,
  },

  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    overflow: "hidden",

    "& > div": {
      overflow: "hidden",
    },
  },

  title: {
    fontWeight: 700,
    fontSize: 16,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  bar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.selected.light,
    zIndex: 1300,
    minHeight: theme.spacing(9.5),
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  closeButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,

    "&:hover": {
      color: theme.palette.text.primary,
    },

    "&:last-child": {
      marginRight: theme.spacing(-1),
    },
  },

  rightGroup: {
    "& button:not(:last-child)": {
      marginRight: theme.spacing(2),
    },

    "& > button:first-child": {
      marginRight: "auto",

      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(2),
      },
    },
    "& button:nth-child(2)": {
      marginLeft: "auto",
    },

    width: "100%",
    display: "flex",
    padding: theme.spacing(0.75, 2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
      display: "inherit",
      width: "auto",
    },
  },

  status: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: 14,
  },

  publishButton: {
    fontWeight: "bold",
    padding: theme.spacing(0.75, 4),

    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
  },

  iconButton: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",

    backgroundColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),

    "& svg": {
      color: theme.palette.common.black,
    },

    "&.Mui-disabled": {
      backgroundColor: theme.palette.quote,
      opacity: 0.4,
    },
  },

  mobileHeader: {
    top: "30px",
  },
}));

const getSavingStateMessage = (
  savingState: CoachComponentSavingState,
  updatedAt?: string,
) => {
  if (savingState === CoachComponentSavingState.SAVING) {
    return "Saving...";
  }

  const preview =
    savingState === CoachComponentSavingState.SAVED
      ? "Changes saved"
      : "Last update";
  const updated = updatedAt ? `• ${dayjs(updatedAt).fromNow()}` : "";

  return `${preview} ${updated}`;
};

export interface CoachComponentBarProps
  extends Omit<AppBarProps, "component">,
    Pick<EditorMenuProps, "editor"> {
  componentData: CurriculumComponent;
  onSaveClick: () => void;
  onCloseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  savingState: CoachComponentSavingState;
  isEmpty: boolean;
  isDirty: boolean;
}

export const CoachComponentBar = (props: CoachComponentBarProps) => {
  const {
    className,
    componentData,
    onSaveClick,
    onCloseClick,
    savingState,
    isEmpty,
    isDirty,
    editor,
    ...other
  } = props;
  const [_, setSearchParams] = useSearchParams();

  const user = useCurrentUser();
  const [preview, setPreview] = usePreview();
  const s = useStyles({ impersonating: user?.isImpersonating, preview });
  const component = componentData;
  const [trackEvent] = useAnalytics();
  const iconsMenuState = usePopupState({
    variant: "popover",
    popupId: "editor-menu",
  });

  const handlePreviewClick = React.useCallback(() => {
    trackEvent("Coach - Preview Component");
    setSearchParams((searchParams) => {
      searchParams.set("preview", "true");
      return searchParams;
    });
    setPreview(PreviewType.MOBILE);
  }, [trackEvent, setPreview]);

  const savingStateMessage = getSavingStateMessage(
    savingState,
    component.updatedAt?.toString(),
  );

  const heightContext = useContext(ComponentBarHeightContext);

  const elementRef = useRef(null);

  useEffect(() => {
    if (!elementRef.current) return;

    const resizeObserver = new ResizeObserver((callback) => {
      heightContext.setComponentBarHeight(
        elementRef.current?.clientHeight ?? 0,
      );
    });

    resizeObserver.observe(elementRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  const previewDisabled =
    savingState === CoachComponentSavingState.SAVING || isDirty;

  return (
    <AppBar className={clsx(s.root, className)} {...other}>
      <Box ref={elementRef as React.Ref<unknown>}>
        <Toolbar className={s.bar}>
          <Box className={s.header}>
            <IconButton
              className={s.closeButton}
              onClick={onCloseClick}
              children={<CircleX size={30} />}
              size="large"
            />
            <Box>
              <Typography className={s.title}>
                {component.title || "New document"}
              </Typography>
              <Typography className={s.status} component="div">
                {savingStateMessage}
              </Typography>
            </Box>
          </Box>

          <div className={s.rightGroup}>
            <ComponentPublishButton
              className={s.publishButton}
              componentData={component}
              onClick={onSaveClick}
              empty={isEmpty}
              dirty={isDirty}
              savingStateMessage={savingStateMessage}
            />

            <IconButton
              children={<MoreHoriz />}
              aria-haspopup="true"
              className={s.iconButton}
              {...bindTrigger(iconsMenuState)}
              size="large"
            />

            {iconsMenuState.open && (
              <EditorMenu
                componentData={component}
                isEmpty={isEmpty}
                isDirty={isDirty}
                editor={editor}
                {...bindMenu(iconsMenuState)}
              />
            )}

            {component.type !== ComponentType.MESSAGE && (
              <>
                <ComponentLibraryButton className={s.iconButton} />

                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    previewDisabled ? (
                      <>
                        Just a sec.
                        <br />
                        Saving...
                      </>
                    ) : (
                      "Preview"
                    )
                  }
                >
                  <span>
                    <IconButton
                      className={s.iconButton}
                      onClick={handlePreviewClick}
                      children={<VisibilityOutlined />}
                      size="large"
                      disabled={previewDisabled}
                    />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
