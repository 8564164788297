import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  ActivityFeedbackDto,
  ICursorPaginatedListOfNotificationBriefDto,
  NotificationBriefDto,
} from "@growth-machine-llc/stridist-api-client";
import { useCurrentUser, UserInfo } from "../../hooks/useCurrentUser";
import { MessageContent } from "../messages/MessageContent";

import dayjs from "dayjs";
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import NotificationsService from "../../services/NotificationsService";
import { ACTIVITY_FEEDBACKS_QUERY_KEY } from "./ActivityFeedbackDrawer";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { NOTIFICATIONS_MENU_LIST_QUERY_KEY } from "../menu/NotificationsMenu";
import {
  createInfinitePaginatedDataUpdater,
  INFINITE_PAGINATED_DATA_UPDATERS,
} from "../../utils/optimisticUpdate";
import { ACTIVITY_LIST_QUERY_KEY } from "../activity/CoachActivity";
import { useReadNotificationMutation } from "../app/mutations/useReadNotificationMutation";
import { TimeAgo } from "../../routes/utils/TimeAgo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "top",
    justifyContent: "left",
    padding: theme.spacing(2.5, 0, 3),
    marginLeft: theme.spacing(-1),
  },

  avatar: {},

  wrapper: {
    maxWidth: "-webkit-fill-available",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "left",
    justifyContent: "center",
    marginRight: theme.spacing(5),
    paddingLeft: theme.spacing(2.25),
  },

  name: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginRight: theme.spacing(1),
  },

  time: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  contentWrapper: {
    marginTop: theme.spacing(1),
  },

  text: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 500,
    color: theme.palette.common.black,
    whiteSpace: "break-spaces",
  },
}));

export interface ActivityFeedbackProps extends BoxProps {
  activityId: number;
  feedback: ActivityFeedbackDto;
}

export function ActivityFeedback(props: ActivityFeedbackProps) {
  const { className, feedback, activityId, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const { id, author, content, read, formattedFromNow, created } = feedback;
  const queryClient = useQueryClient();

  const { mutate: notificationRead, isPending: inFlight } =
    useReadNotificationMutation<ActivityFeedbackDto[]>({
      queryKey: [ACTIVITY_FEEDBACKS_QUERY_KEY, { id: activityId }],
      readUpdater: (prev, variables) =>
        prev.map((feedback) => {
          if (feedback.id === variables.activityFeedbackId) {
            feedback.read = true;
          }
          return feedback;
        }),
      onSuccess() {
        // invalidating `.../activity` route query to refetch the list
        queryClient.invalidateQueries({
          queryKey: [ACTIVITY_LIST_QUERY_KEY],
          exact: false,
          refetchType: "none",
        });
      },
    });

  React.useEffect(() => {
    if (author.id !== user.id && !read && !inFlight) {
      notificationRead({ activityFeedbackId: id });
    }
  }, [id, read, notificationRead, inFlight, author.id, user.id]);

  const name = React.useMemo(
    () => (author.id === user.id ? "You" : author.displayName),
    [author.displayName, author.id, user.id],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Avatar className={s.avatar} alt={name} src={author.photoUrl} />
      <Box className={s.wrapper}>
        <Box>
          <Typography
            className={s.name}
            variant="body1"
            component="p"
            children={name}
          />
          <Typography
            className={s.time}
            variant="body1"
            component="p"
            children={<TimeAgo date={created}></TimeAgo>}
          />
        </Box>
        <MessageContent
          content={content}
          wrapperClassName={s.contentWrapper}
          textClassName={s.text}
        />
      </Box>
    </Box>
  );
}
