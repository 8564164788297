import { keepPreviousData, useQuery } from "@tanstack/react-query";
import ActivitiesService from "../../services/ActivitiesService";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";
import { extractSlugId } from "../../utils/slug";

export const PROGRAM_COMPONENT_QUERY_KEY = "program-component";
type UseActivityParams = {
  username: string;
  date: string;
  componentSlug: string;
};
export const useActivityComponent = ({
  username,
  date,
  componentSlug,
}: UseActivityParams) => {
  const slugId = extractSlugId(componentSlug);
  const {
    data: activity,
    isLoading,
    isPlaceholderData,
    isFetching: isFetching,
  } = useQuery({
    queryKey: [PROGRAM_COMPONENT_QUERY_KEY, { date, slugId: slugId, username }],
    queryFn: () => {
      return ActivitiesService.getActivityWithComponents(
        username,
        date,
        componentSlug,
      );
    },
    placeholderData: keepPreviousData,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const isLoadingInitial = isLoading && !isPlaceholderData;
  const isSecondaryLoading = isFetching && isPlaceholderData;

  return { activity, isLoadingInitial, isSecondaryLoading, isPlaceholderData };
};
