import clsx from "clsx";
import React from "react";
import { Box, Typography, BoxProps, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { sortBy, findLast } from "lodash";
import queryString from "query-string";

import { ComponentType, UserRole } from "../../constants";
import { TimeLockIcon as LockedIcon } from "../../components/icons/TimeLockIcon";
import {
  getTimeDiff,
  getISODate,
  getDaysDiffFromToday,
  ISO_DATE_FORMAT,
} from "../../utils/date";
import { getContentType, getDaysInfo } from "../../utils/component";
import { useQueryParam } from "../../hooks/useQueryParam";
import { CardMedia } from "../card/CardMedia";
import { ComponentIcon } from "../program-component/ComponentIcon";
import { vibrate } from "../../utils/device";

import {
  ActivityCompletionProps,
  ActivityCompletion,
} from "./ActivityCompletion";
import { useCurrentUser } from "../../hooks/useCurrentUser";

import { ReactComponent as ArrowForwardIcon } from "../../icons/ArrowForwardOutline.svg";
import { ReactComponent as CompletedIcon } from "../../icons/CheckMarkCircle.svg";
import { ReactComponent as ThumbUpIcon } from "../../icons/ThumbUp.svg";
import { ReactComponent as ChevronForwardIcon } from "../../icons/ChevronForwardOutline.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ComponentActivityDto,
  PaginatedListOfComponentActivityDto,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import ActivitiesService from "../../services/ActivitiesService";
import { CLIENT_HOME_ROUTE_ACTIVITY_QUERY } from "../screen/ClientHomeScreen";
import { CLIENT_ACTIVITY_SUMMARY_QUERY_KEY } from "./ActivityCalendar";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { extractSlugId } from "../../utils/slug";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    cursor: "pointer",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },

  summary: {
    "& $content": {
      minHeight: 158,
    },

    [theme.breakpoints.up("sm")]: {
      height: 121,

      "& $content": {
        minHeight: "initial",
      },
    },
  },

  normal: {
    "& $content": {
      minHeight: 133,
    },

    "& $header $text": {
      display: "none",
      fontWeight: 400,
    },

    [theme.breakpoints.up("sm")]: {
      height: 140,

      "& $header $text": {
        display: "initial",
      },
    },
  },

  compact: {
    height: "auto",

    "& $lockIcon, & $caption": {
      display: "none",
    },

    "&:not($completed) $completedIcon": {
      opacity: 0.5,
      filter: "grayscale(1)",
    },

    "& $content": {
      border: "1px solid",
      borderColor: theme.palette.quote,
      boxShadow: "none",
      minHeight: 56,
      padding: theme.spacing(2, 2.5),
    },
  },

  completed: {},
  locked: {},
  progress: {},

  cover: {
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    margin: theme.spacing(0, 0, 2.5, 0),
    position: "absolute",

    [theme.breakpoints.up("sm")]: {
      position: "initial",
      width: 224,
      flexGrow: 0,
      flexShrink: 0,
      margin: theme.spacing(0, 2.5, 0, 0),
    },
  },

  content: {
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[2],
    position: "relative",

    display: "flex",
    width: "100%",
    height: "100%",

    flexGrow: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      padding: theme.spacing(3),

      "$image &": {
        maxWidth: `calc(100% - ${theme.spacing(30)})`,
      },
    },
  },

  header: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "100%",
  },

  completion: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  icon: {
    margin: theme.spacing(0, 0, 2, 0),
    '&[class*="special"]': {
      margin: theme.spacing(0, 0, 1.5, 0),
    },
    "$summary$completed &, $summary$locked &": {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      "$completed &, $locked &": {
        display: "initial",
      },

      margin: theme.spacing(0, 1, 0, 0),
      '&[class*="special"]': {
        margin: theme.spacing(0, 0.5, 0, 0),
      },
    },
  },

  title: {
    display: "flex",

    flexDirection: "column",
    alignItems: "flex-start",

    "& h6": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 600,
      fontSize: 24,
      maxWidth: "100%",
    },

    "$summary & h6": {
      fontSize: 20,
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",

      "& h6": {
        fontSize: 24,
      },
    },
  },

  text: {
    fontWeight: 500,
  },

  actions: {
    display: "none",

    "& $completed svg [fill]": {
      fill: theme.palette.progress.red,
    },

    "$completed &": {
      order: -1,
    },

    "$summary &": {
      display: "block",
    },

    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginLeft: theme.spacing(3),
      "$completed &": {
        order: 1,
        marginLeft: theme.spacing(7),
      },
    },
  },

  label: {
    textTransform: "capitalize",
    "&:not(:nth-child(1))::before": {
      content: '" • "',
    },
  },

  forwardIcon: {
    "& [stroke]": {
      stroke: theme.palette.text.secondary,
    },
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "initial",
    },
  },

  lockIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4),

    margin: theme.spacing(-0.5),
    fill: theme.palette.common.black,

    "$summary & path": {
      fill: theme.palette.common.white,
    },

    "$normal & path": {
      fill: theme.palette.text.secondary,
    },

    [theme.breakpoints.up("sm")]: {
      "$summary & path": {
        fill: theme.palette.common.black,
      },

      "$normal & path": {
        fill: theme.palette.common.black,
      },
    },
  },

  completedIcon: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    minWidth: theme.spacing(4.5),
    minHeight: theme.spacing(4.5),

    "&": {
      color: theme.palette.primary.main,
    },
  },

  completeHabitIcon: {
    "&": {
      color: theme.palette.text.secondary,
    },

    "$completed &": {
      color: theme.palette.primary.main,
    },
  },

  image: {
    [theme.breakpoints.down("sm")]: {
      "&": {
        borderRadius: theme.spacing(1.5),
        boxShadow: theme.shadows[2],
        overflow: "hidden",
      },

      "& $cover, & $content": {
        boxShadow: "none",
        borderRadius: "initial",
      },

      "& $content": {
        zIndex: 1,
        position: "relative",
        background: "linear-gradient(transparent, hsla(0, 0%, 0%, 0.6))",
        padding: theme.spacing(2),

        boxShadow: "none",
      },

      "& $content $header": {
        flexGrow: 0,
        marginTop: "auto",
      },

      "& $content *": {
        color: theme.palette.common.white,
        textShadow: `0 0 2px ${theme.palette.avatar}`,
      },

      "& $icon": {
        position: "absolute",
        left: theme.spacing(2),
        top: theme.spacing(2),
      },

      "& $icon [stroke=black]": {
        stroke: theme.palette.common.white,
      },

      "&$summary svg$completedIcon, &$summary svg$lockIcon": {
        position: "absolute",
        left: theme.spacing(2),
        top: theme.spacing(2),
      },
    },
  },

  caption: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,

    "& svg": {
      marginLeft: theme.spacing(1),
    },

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  depressed: {
    "& $cover": {
      filter: "grayscale(1)",
      opacity: 0.5,
    },

    [theme.breakpoints.up("sm")]: {
      "& $content $lockIcon": {
        opacity: 0.5,
      },

      "&:not($compact) $content $title, &:not($compact) $content $text": {
        opacity: 0.5,
      },
    },
  },
}));

export interface ActivityCardProps extends BoxProps {
  activity: ComponentActivityDto;
  completionProgress?: ActivityCompletionProps["progress"];
  variant: "normal" | "summary" | "compact";
  username?: string;
  clientsScreen?: boolean;
  idProgram?: string;
}

export function ActivityCard(props: ActivityCardProps) {
  const {
    className,
    activity,
    variant,
    completionProgress,
    clientsScreen,
    username,
    idProgram,
  } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const location = useLocation();
  const user = useCurrentUser();
  const component = activity?.component;
  const activityDate = activity.date.format(ISO_DATE_FORMAT);
  const timeDiff = getTimeDiff(activityDate);
  const activityFormattedDate = activity.date.format("MMM DD, YYYY");
  const [previewQueryParam] = useQueryParam("preview", "");
  const [weekQueryParam] = useQueryParam("week");
  const daysArray = JSON.parse(component.days);
  const isCoach = user.role === UserRole.COACH;
  const queryClient = useQueryClient();

  const { mutate: completeActivity } = useOptimisticUpdateMutation({
    successToastMessage: "Activity completed",
    queryKey: [
      CLIENT_HOME_ROUTE_ACTIVITY_QUERY,
      {
        date: activityDate,
      },
    ],
    mutationFn: ActivitiesService.upsertActivity,
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfComponentActivityDto) => {
        const updatedData = PaginatedListOfComponentActivityDto.fromJS({
          ...oldData,
          items: oldData.items.map((item) => {
            if (item.id === activity.id) {
              return {
                ...item,
                completedAt: new Date().toISOString(),
              };
            }
            return item;
          }),
        });
        return updatedData;
      },
    },
    options: {
      onSuccess: () => {
        const componentSlugId = extractSlugId(component.slug);

        queryClient.invalidateQueries({
          queryKey: [
            CLIENT_ACTIVITY_SUMMARY_QUERY_KEY,
            { username: username, slugId: componentSlugId },
          ],
          exact: true,
        });
      },
    },
  });

  const isSummary = variant === "summary";
  const locked =
    component.locked &&
    timeDiff > 0 &&
    !previewQueryParam &&
    (!isSummary || component.type === ComponentType.LESSON);

  const completed = React.useMemo(() => {
    if (component.type !== ComponentType.LESSON) {
      return completionProgress
        ? completionProgress.every(({ completed }) => completed)
        : activity.completedAt;
    }

    return activity.completedAt;
  }, [component.type, activity.completedAt, completionProgress]);
  const completable =
    variant === "normal" && !locked && component.type === ComponentType.HABIT;

  const completions = React.useMemo(
    () =>
      (completionProgress &&
        sortBy(completionProgress, ({ completed, date }) => [
          !completed,
          date,
        ])) ||
      [],
    [completionProgress],
  );
  const today = getISODate();
  const date = React.useMemo(() => {
    const completion =
      completions.find(({ completed, date }) => !completed && date === today) ||
      findLast(completions, ({ completed }) => !completed) ||
      findLast(completions, ({ date }) => date < today) ||
      completions.find(({ date }) => date > today);
    return completion
      ? dayjs(completion.date).format(ISO_DATE_FORMAT)
      : activityDate;
  }, [activityDate, completions, today]);

  const currentPath = location.pathname + location.search;
  const pathPrefix = React.useMemo(
    () => {
      if (component.type === ComponentType.WORKOUT && !isCoach) {
        return !activity.submittedAt
          ? `/programs/start/${activity.program.slug}`
          : `/programs/summary/${activity.program.slug}`;
      } else {
        return variant !== "normal"
          ? location.pathname
          : `/programs/${activity.program.slug}`;
      }
    },
    // variant !== "normal"
    //   ? location.pathname
    //   : `/programs/start/${component.program.slug}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variant],
  );
  const queryParams = queryString.stringify({
    summary: completionProgress ? "yes" : undefined,
    preview: previewQueryParam ? "true" : undefined,
    week: weekQueryParam || undefined,
  });
  const qs = queryParams
    ? `?${queryParams}${clientsScreen && idProgram ? `&id=${idProgram}` : ""}`
    : "";

  const getPath = React.useCallback(
    (date: string) => {
      const hasPreviewPrefix = pathPrefix.includes("/preview");
      const updatedPathPrefix = hasPreviewPrefix
        ? pathPrefix.replace("/preview", "")
        : pathPrefix;

      return `${updatedPathPrefix}/${date}/${component.slug}${
        hasPreviewPrefix ? "?preview=true" : "" + qs
      }`;
    },
    [component.slug, pathPrefix, qs],
  );

  const handleActivityClick = React.useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      activityDate?: string,
    ) => {
      event.stopPropagation();

      const path = getPath(activityDate || date.toString());

      if (currentPath !== path) {
        navigate(path);
      }
    },
    [currentPath, date, getPath],
  );

  const handleCompleteHabitClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      const input = {
        id: activity.id,
        completed: true,
      };

      completeActivity(input, {
        onSuccess: () => {
          vibrate();
        },
      });
    },
    [activity.id, completeActivity],
  );

  const componentImage = component.image ?? activity.program?.image;
  const image = !completionProgress && componentImage;
  const typeLabel = getContentType(component.type as ComponentType);

  const scheduleLabel = React.useMemo(() => {
    return getDaysInfo(component.type as ComponentType, daysArray as boolean[]);
  }, [component.days, component.type]);

  const showCaption = locked || !isSummary;

  const renderCompletionIcon = () => (
    <IconButton
      className={s.completeHabitIcon}
      disabled={!!activity.completedAt}
      onClick={handleCompleteHabitClick}
      children={<ThumbUpIcon className={s.completeHabitIcon} />}
      size="large"
    />
  );

  const actions =
    variant === "compact" && component.type === ComponentType.LESSON ? (
      <CompletedIcon className={s.completedIcon} />
    ) : locked ? (
      <LockedIcon className={s.lockIcon} backgroundFill="transparent" />
    ) : completable ? (
      activity.completedAt ? (
        renderCompletionIcon()
      ) : (
        <Tooltip
          arrow
          placement="top"
          title="Yes, I did this today."
          children={renderCompletionIcon()}
        />
      )
    ) : completed ? (
      <CompletedIcon className={s.completedIcon} />
    ) : variant === "summary" ? (
      <ArrowForwardIcon className={s.forwardIcon} />
    ) : (
      variant === "normal" && <ChevronForwardIcon className={s.forwardIcon} />
    );

  return (
    <Box
      className={clsx(s.root, className, {
        [s.completed]: completed,
        [s.locked]: locked,
        [s.image]: !completionProgress && image,
        [s.depressed]: locked || completed,
        [s.summary]: isSummary,
        [s.normal]: !isSummary,
        [s.compact]: variant === "compact",
      })}
      onClick={handleActivityClick}
    >
      {image && <CardMedia className={s.cover} image={image} />}
      <Box className={s.content}>
        <Box className={s.header}>
          <Box className={s.title}>
            <ComponentIcon
              className={s.icon}
              variant="icon"
              size="small"
              componentData={component}
            />
            <Typography variant="h6">{component.title}</Typography>
          </Box>
          <Typography variant="body1" color="textSecondary" className={s.text}>
            {isSummary ? (
              <>
                <span className={s.label}>{typeLabel}</span>
                <span className={s.label}>{scheduleLabel}</span>
              </>
            ) : (
              component.teaser
            )}
          </Typography>
        </Box>

        {completionProgress && (
          <ActivityCompletion
            className={s.completion}
            progress={completionProgress}
            onDateClick={handleActivityClick}
          />
        )}

        {actions && <Box className={s.actions}>{actions}</Box>}
      </Box>

      {showCaption && (
        <Box component="div" className={s.caption}>
          {isSummary && locked ? (
            `Locked until ${activityFormattedDate}`
          ) : (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                className={s.text}
              >
                {component.teaser}
              </Typography>
              {actions}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
