import clsx from "clsx";
import React from "react";
import { Box, BoxProps, IconButton, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { ComponentStatus } from "../../constants";
import { MoreMenuButton } from "../button/MoreMenuButton";
import { WeekComponentMenu } from "../menu/WeekComponentMenu";
import { Trash } from "lucide-react";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import { CurriculumComponent } from "../../redux/types";
import { useCurriculumDispatch } from "../../redux/hooks";
import { updateComponentDays } from "../../redux/curriculum/curriculum-slice";
import { stringifyComponentDays } from "../../utils/component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
  },

  day: {
    position: "relative",
    height: 65,
  },

  button: {
    position: "absolute",
    top: 1,
    right: 0,
    padding: theme.spacing(1),
    opacity: 0,

    "& svg": {
      color: theme.palette.secondary.main,
      width: 16,
      height: 16,
    },

    ":not(.draggable) &": {
      zIndex: 2,
    },

    ":not(.draggable) $day:hover &": {
      opacity: 1,
    },
  },

  menuButton: {
    color: "black",
    position: "absolute",
    right: 0,
    top: `-${theme.spacing(1.125)}`,
    opacity: 0,

    ":not(.draggable) [draggable=true]:hover &": {
      zIndex: 2,
      opacity: 1,
      display: "block",
    },

    transition: theme.transitions.create(["opacity"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },

  menuOpen: {
    display: "block",
    zIndex: 2,
  },
}));

export interface ProgramCalendarComponentButtonsProps extends BoxProps {
  span: number;
  spanOffset: number;
  componentData: CurriculumComponent;
  activeComponentSlug: string;
}

export function ProgramCalendarComponentButtons(
  props: ProgramCalendarComponentButtonsProps,
) {
  const s = useStyles();
  const {
    className,
    spanOffset,
    span,
    componentData,
    activeComponentSlug,
    ...other
  } = props;
  const dispatch = useCurriculumDispatch();
  const theme = useTheme();
  const { id, slug, status, locked } = componentData;
  const days = JSON.parse(componentData.days);

  const menuState = usePopupState({
    variant: "popover",
    popupId: "component-menu",
  });

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const removeDay = parseInt(event.currentTarget.dataset.removeDay);
      const newDays = days.map((scheduled, day) =>
        removeDay === day ? false : scheduled,
      );
      dispatch(
        updateComponentDays({
          componentId: id,
          days: stringifyComponentDays(newDays),
        }),
      );
    },
    [days, id],
  );

  const totalDays = days.filter(Boolean).length;
  const canRemove = status !== ComponentStatus.ARCHIVED || totalDays > 1;

  // 14 is one icon width
  const extendClickArea =
    (locked ? 14 : 0) +
    (status === ComponentStatus.ARCHIVED || status === ComponentStatus.DRAFT
      ? 14
      : 0);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {[...Array(span)].map((_, day) => (
        <Box
          key={day}
          className={s.day}
          style={{ width: `calc(100% / ${span})` }}
        >
          {canRemove && day > 0 && day < span - 1 && (
            <IconButton
              className={s.button}
              data-remove-day={spanOffset + day}
              onClick={handleClick}
              size="large"
            >
              <Trash />
            </IconButton>
          )}
          {day === span - 1 && (
            <MoreMenuButton
              className={clsx(s.menuButton, menuState.isOpen && s.menuOpen)}
              styleVariant="inverse"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                top: 0,
                height: 24,
                // extra width to cover icons from outside
                width: 32 + extendClickArea,
                color:
                  activeComponentSlug === slug
                    ? theme.palette.common.white
                    : theme.palette.common.black,
              }}
              {...bindTrigger(menuState)}
            />
          )}
        </Box>
      ))}

      {menuState.isOpen && (
        <WeekComponentMenu
          {...bindMenu(menuState)}
          componentData={componentData}
          view={ProgramDetailsViewMode.CALENDAR}
        />
      )}
    </Box>
  );
}
