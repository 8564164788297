import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CardMedia } from "../card/CardMedia";
import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";

import { MealLoggingDto } from "@growth-machine-llc/stridist-api-client";
import { formatTimeFromMilitary } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    overflow: "hidden",
    boxShadow: theme.shadows[5],
  },

  cover: {
    position: "absolute",
  },

  overlay: {
    height: theme.spacing(16.75),
    position: "relative",
    padding: theme.spacing(2),
    background: "linear-gradient(transparent, hsla(0, 0%, 0%, 0.6))",
  },

  header: {
    position: "absolute",
    bottom: theme.spacing(2),
    maxWidth: `calc(100% - ${theme.spacing(4)})`,
  },

  time: {
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: "bold",
  },

  description: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  editButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.common.white,
    padding: theme.spacing(2),

    "& svg": {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },

  container: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(10.75),

    "& $header": {
      bottom: "initial",
      top: theme.spacing(2),
      left: theme.spacing(2),
    },

    "& $time, & $editButton": {
      color: theme.palette.text.primary,
    },

    "& $description": {
      color: theme.palette.text.secondary,
    },
  },
}));

export interface ClientMealLoggingEntriesListItemProps extends BoxProps {
  mealLoggingEntry: MealLoggingDto
  onEditClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export function ClientMealLoggingEntriesListItem(
  props: ClientMealLoggingEntriesListItemProps,
) {
  const s = useStyles();
  const { className, mealLoggingEntry, onEditClick, ...other } =
    props;
  const { id, photoUrl: photoURL, description, date, time } = mealLoggingEntry;
  return (
    <Box className={clsx(s.root, className)} {...other}>
      {photoURL && <CardMedia className={s.cover} image={photoURL} fit />}
      <Box className={photoURL ? s.overlay : s.container}>
        <IconButton
          className={s.editButton}
          onClick={onEditClick}
          data-entry-id={id}
          size="large"
        >
          <EditIcon />
        </IconButton>

        <Box className={s.header}>
          <Typography className={s.time} component="div">
            {formatTimeFromMilitary(time)}
          </Typography>
          <Typography className={s.description} component="div">
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
