import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { SelectableItem, SelectableItemProps } from "./SelectableItem";
import { UserBriefInfoDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface SelectableClientProps
  extends Pick<SelectableItemProps, "className" | "onClick"> {
  client: UserBriefInfoDto;
}

export function SelectableClient(props: SelectableClientProps) {
  const { className, client, onClick, ...other } = props;
  const s = useStyles();

  return (
    <SelectableItem
      className={clsx(s.root, className)}
      avatarSrc={client.photoUrl}
      header={client.displayName}
      subheader={client.email}
      onClick={onClick}
      {...other}
    />
  );
}
