import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { darken } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as AppleLogo } from "../../icons/apple-logo.svg";
import { useExternalScript } from "../../hooks/useExternalScript";
import { AuthButtonProps } from "../../utils/auth";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import {
  AuthProvider,
  AppleAuthScriptSrc,
  AppleAuthOptions,
} from "../../constants";
import { iOSMobileApp, isMobileApp } from "../../utils/mobile";
import { nativeAppleAuthSignIn } from "../../utils/device";
import { reportError, ErrorWithCode } from "../../utils/errors";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.black,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2.25),
    borderColor: theme.palette.common.black,
    borderWidth: 1,
    borderStyle: "solid",

    "&:hover": {
      backgroundColor: darken(theme.palette.common.white, 0.1),
    },
  },

  logo: {
    marginRight: theme.spacing(2),
  },
}));

type AppleAuthApiInitOptions = {
  clientId: string;
  scope: string;
  redirectURI: string;
  usePopup?: boolean;
};

type AppleAuthApi = {
  auth: {
    init: (options: AppleAuthApiInitOptions) => void;
    signIn: () => void;
  };
};

type AppleAuthData = {
  authorization: {
    id_token: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};

export type AppleAuthSuccessResponse = {
  detail: AppleAuthData;
};

export interface ApplyButtonProps extends ButtonProps, AuthButtonProps {
  disabled?: boolean;
}

export function AppleButton(props: ApplyButtonProps) {
  const { className, children, onSuccess, onFailure, disabled, ...other } =
    props;
  const s = useStyles();
  const onError = useGenericErrorHandler({});
  const appleAuth = React.useRef<AppleAuthApi>();

  const handleAuthInit = React.useCallback(() => {
    const appleAuthApi = window["AppleID"] as AppleAuthApi;

    if (appleAuthApi) {
      appleAuthApi.auth.init(AppleAuthOptions);

      appleAuth.current = appleAuthApi;
      return true;
    } else {
      return false;
    }
  }, []);

  const loaded = useExternalScript(
    AppleAuthScriptSrc,
    handleAuthInit,
    !isMobileApp,
  );

  const handleSuccess = React.useCallback(
    (response) => {
      if (onSuccess) {
        const {
          detail: {
            authorization: { id_token },
            user,
          },
        } = response as AppleAuthSuccessResponse;
        const displayName = user
          ? [user.name.firstName, user.name.lastName].filter(Boolean).join(" ")
          : null;
        const email = user?.email;

        onSuccess({
          provider: AuthProvider.APPLE,
          id_token,
          displayName,
          email,
        });
      }
    },
    [onSuccess],
  );

  const handleFailure = React.useCallback(
    (error) => {
      const ignoredErrors = ["error 1001.", "error 1000."];

      if (
        error?.message &&
        ignoredErrors.some((seek) => error?.message.includes(seek))
      ) {
        reportError(error);
        return;
      }

      if (error.error === "popup_closed_by_user") {
        // TODO_API_ERROR_HANDLING: Make sure new handler parse these errors correctly
        onError(
          new ErrorWithCode(
            "auth/" + error.error,
            "Authorization popup closed by user",
          ),
        );
        return;
      }

      if (error.error === "popup_blocked_by_browser") {
        onError(
          new ErrorWithCode(
            "auth/" + error.error,
            "Authorization popup blocked by browser",
          ),
        );
        return;
      }

      if (onFailure) {
        onFailure(error);
        console.error(
          "apple button login failure (custom error callback)",
          error,
        );
        reportError(error);
      } else {
        onError(error);
        console.error(
          "apple button login failure (default error callback)",
          error,
        );
        reportError(error);
      }
    },
    [onError, onFailure],
  );

  React.useEffect(() => {
    document.addEventListener("AppleIDSignInOnSuccess", handleSuccess);
    document.addEventListener("AppleIDSignInOnFailure", handleFailure);

    return () => {
      document.removeEventListener("AppleIDSignInOnSuccess", handleSuccess);
      document.removeEventListener("AppleIDSignInOnFailure", handleFailure);
    };
  }, [handleFailure, handleSuccess]);

  const handleClick = React.useCallback(async () => {
    if (iOSMobileApp) {
      nativeAppleAuthSignIn().then(handleSuccess).catch(handleFailure);
    } else if (loaded) {
      try {
        await appleAuth.current.auth.signIn();
      } catch (e) {}
    }
  }, [handleFailure, handleSuccess, loaded]);

  return (
    <Button
      className={clsx(s.root, className)}
      variant="contained"
      fullWidth
      onClick={handleClick}
      disabled={!loaded || disabled}
      {...other}
    >
      <AppleLogo className={s.logo} /> {children}
    </Button>
  );
}
