import dayjs from "dayjs";

export function dateWithoutWeekDay(
  date: dayjs.Dayjs,
  fullMonth = false,
): string {
  const s = date.format("MMM D");

  if (fullMonth) {
    const shortLabel = s.substring(0, 3);
    const longLabel = monthsLabels.find((label) =>
      label.startsWith(shortLabel),
    );

    return s.replace(shortLabel, longLabel);
  } else {
    return s;
  }
}

export const parseAsISODate = (date: string) => dayjs(date + "T00:00:00");

export function getWeekLabel(
  start: string,
  end: string,
  fullMonth = false,
): string {
  if (!start || !end) {
    return "";
  }

  const startDate = parseAsISODate(start);
  const endDate = parseAsISODate(end);
  const startDateString = dateWithoutWeekDay(startDate, fullMonth);
  const endDateString = dateWithoutWeekDay(endDate, fullMonth);

  return getDateRangeString(startDateString, endDateString);
}

export function getDateLabel(
  date?: string,
  useRelativePrefix: boolean = true,
): string {
  if (!date) {
    return "";
  }

  const dateInstance = new Date(date);
  const dateString = dateInstance.toDateString().substring(0, 10);

  if (useRelativePrefix) {
    const relativePrefix = getRelativeDayPrefix(dateInstance);
    if (relativePrefix) {
      return `${relativePrefix} • ${dateString}`;
    }
  }

  return dateString;
}

export function getWeekISODates(startDate: string, week: number): string[] {
  return Array.from({ length: 7 }).map((value, index) => {
    return getISODateInternal(
      parseAsISODate(startDate),
      7 * (week - 1) + index,
    );
  });
}

export const ISO_DATE_FORMAT = "YYYY-MM-DD";

export function getISODate(date?: Date | string, days: number = 0): string {
  return getISODateInternal(dayjs(date), days);
}

function getISODateInternal(date?: dayjs.Dayjs, days: number = 0): string {
  let _date = date;
  _date = _date
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .date(_date.date() + days);

  const year = _date.year();
  const month = _date.month() + 1;
  const day = _date.date();

  return [
    year,
    month.toString().length === 1 ? `0${month}` : month,
    day.toString().length === 1 ? `0${day}` : day,
  ].join("-");
}

function getDateRangeString(
  startDateString: string,
  endDateString: string,
): string {
  const [startDateMonth, startDateDay] = startDateString.split(" ");
  const [endDateMonth, endDateDay] = endDateString.split(" ");

  return startDateMonth === endDateMonth
    ? `${endDateMonth} ${startDateDay} - ${endDateDay}`
    : `${startDateString} - ${endDateString}`;
}

function getRelativeDayPrefix(date: Date): string {
  const today = new Date();
  const yesterday = new Date();
  const tomorrow = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  tomorrow.setDate(tomorrow.getDate() + 1);

  [date, today, yesterday, tomorrow].forEach((d) => d.setHours(0, 0, 0, 0));

  switch (date.getTime()) {
    case today.getTime():
      return "Today";
    case yesterday.getTime():
      return "Yesterday";
    case tomorrow.getTime():
      return "Tomorrow";
    default:
      return "";
  }
}

export function getTimeDiff(date: string) {
  const _date = parseDateISOString(date);
  const currentDate = new Date();
  _date.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  return _date.getTime() - currentDate.getTime();
}

export function getDaysDiffFromToday(date: string) {
  return Math.ceil(getTimeDiff(date) / dayOffset);
}

export function getDayAbbreviation(date: string) {
  const _date = parseDateISOString(date);
  const result = new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(
    _date,
  );
  const endIndex = result.startsWith("T") || result.startsWith("S") ? 2 : 1;

  return result.substring(0, endIndex);
}

export function parseDateISOString(date: Date | string) {
  if (date instanceof Date) {
    return date;
  }

  const [year, month, day] = parseISODate(date);

  return new Date(year, month, day);
}

export function parseISODate(date: string) {
  const b = date
    .split("T")[0]
    .split(/\D/)
    .map((x) => parseInt(x));

  if (b.length !== 3) {
    throw new Error("Not an ISO date");
  }

  return [b[0], b[1] - 1, b[2]];
}

export const toISODateString = (date: Date): string =>
  date.toISOString().split("T")[0];

export const getDaysDiff = (left: Date, right: Date) =>
  Math.ceil(Math.abs(left.getTime() - right.getTime()) / dayOffset);

export const getTodayDaysDiff = (date: string) => {
  const todayDate = new Date().toISOString().split("T")[0];
  const todayMoment = dayjs(todayDate);
  const expiryMoment = dayjs(date);

  return expiryMoment.diff(todayMoment, "days");
};

export const dayOffset = 3600 * 24 * 1000;
export const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const daysOfWeekFull = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const monthsLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getDayFromMon = (date: Date) => {
  const x = date.getDay();

  return x > 0 ? x - 1 : 6;
};
export const getDayOfWeek = (date: Date, full = false) => {
  const names = full ? daysOfWeekFull : daysOfWeek;
  return names[getDayFromMon(date)];
};

export const previousMonth = (month: number) => (month === 0 ? 11 : month - 1);

export const getCalendarMonths = (
  currentDate: Date | string,
  offset = -5,
  limit = 12,
): string[] => {
  const date = parseDateISOString(currentDate);

  date.setMonth((date.getMonth() + offset) % 12);

  return [...Array(limit)].map(() => {
    const year = date.getFullYear();
    const month = date.getMonth();

    if (month === 11) {
      date.setFullYear(year + 1);
    }

    date.setHours(0, 0, 0, 0);
    date.setMonth((month + 1) % 12);
    date.setDate(10);

    return toISODateString(date);
  });
};

export const startOfWeek = (date: Date): Date =>
  new Date(date.getTime() - getMondayDiff(date) * dayOffset);

export const endOfWeek = (date: Date): Date =>
  new Date(date.getTime() + (6 + getMondayDiff(date)) * dayOffset);

export const getCalendarDates = (year: number, month: number): Date[] => {
  const dates = [];
  let date = new Date(year, month, 1);

  date.setHours(12, 0, 0, 0);
  date = startOfWeek(date);

  while (
    date.getMonth() === month ||
    date.getMonth() === previousMonth(month) ||
    dates.length % 7 !== 0
  ) {
    dates.push(date);
    date = new Date(date.getTime() + dayOffset);
  }

  return dates;
};

export function getMondayDiff(date: Date | string): number {
  const _date = parseDateISOString(date);
  _date.setHours(0, 0, 0, 0);
  const day = _date.getDay();
  return day === 0 ? 6 : day - 1;
}

export function addDays(d: string, days: number): Date {
  return new Date(new Date(d).getTime() + days * 3600 * 24 * 1000);
}

export type DateUnit = "week" | "day";

export const addToDate = (date: Date, offset: number, unit: DateUnit): Date =>
  dayjs(date).add(offset, unit).toDate();

export const getWeekDiff = (left: Date, right: Date): number =>
  Math.ceil((left.getTime() - right.getTime()) / (7 * 24 * 3600 * 1000));

export const formatTime = (date: Date | string, format = "h:mma") =>
  date &&
  dayjs(
    date instanceof Date
      ? date
      : new Date(/^\d+$/.test(date) ? parseInt(date) : date),
  ).format(format);

export const getOrdinalSuffix = (number: number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateOrdinal = (date: dayjs.Dayjs) => {
  const number = date.date();

  return `${date.format("MMMM DD")}${getOrdinalSuffix(number)}`;
};

export const formatTimeFromMilitary = (time: string, format = "h:mma") =>
  dayjs(time, "HH:mm").format(format);

export const formatDateFromMilitary = (date: string, includeDate = false) => {
  const formattedTime = dayjs(date).format("HH:mm");
  if (includeDate) {
    const formattedDate = dayjs(date).format("MMM DD, YYYY");
    return `${formattedDate} ${formattedTime}`;
  }
  return formattedTime;
};

export const formatDateToLongString = (date: dayjs.Dayjs) => {
  return date.format("MMMM DD, YYYY");
};

export const formatTimeToMilitary = (date: dayjs.Dayjs) => {
  return dayjs(date).format("hh:mmA");
};

export const formatUtcTimeAgo = (date: dayjs.Dayjs | string) => {
  const parsedDate = typeof date === 'string' ? dayjs(date) : date;
  return parsedDate?.utc().fromNow();
};