import clsx from "clsx";
import React from "react";
import { DialogProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";
import { getISODate } from "../../utils/date";

import { BaseDialog } from "./BaseDialog";
import { EnrollmentsForCalendarDto } from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY } from "../../routes/coach/client/calendar/CoachClientCalendarRoute";
import { useClient } from "../../hooks/useClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MANAGE_PROGRAMS_DIALOG_QUERY_KEY } from "./ChooseProgramEnrollDialog";
import {
  CLIENT_HOME_ROUTE_ACTIVITY_DATE_RANGE_QUERY,
  CLIENT_HOME_ROUTE_ACTIVITY_QUERY,
} from "../screen/ClientHomeScreen";
import {
  COACH_CLIENTS_LIST_QUERY_KEY,
  INVITED_ADDED_CLIENTS_QUERY_KEY,
} from "../coach-clients/CoachClientsListScreen";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

export interface ChangeEnrollmentStartDateDialogProps extends DialogProps {
  enrollment: EnrollmentsForCalendarDto;
  onSuccess?: () => void;
}

export function ChangeEnrollmentStartDateDialog(
  props: ChangeEnrollmentStartDateDialogProps,
) {
  const { className, enrollment, onSuccess, onClose, ...other } = props;
  const s = useStyles();
  const [startDate, setStartDate] = React.useState(new Date());
  const { username } = useClient();
  const queryClient = useQueryClient();

  const { mutate: changeStartDate, isPending: changeStartDateInFlight } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
      mutationFn: EnrollmentsClientService.changeEnrollmentStartDate,
      optimisticUpdater: {
        updateFn: (oldData) => oldData,
      },
      successToastMessage: "Enrollment start date changed successfully!",
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY, { username }],
          });

          queryClient.invalidateQueries({
            queryKey: [CLIENT_HOME_ROUTE_ACTIVITY_DATE_RANGE_QUERY],
            exact: false,
          });

          queryClient.invalidateQueries({
            queryKey: [COACH_CLIENTS_LIST_QUERY_KEY],
            exact: false,
          });

          queryClient.invalidateQueries({
            queryKey: [INVITED_ADDED_CLIENTS_QUERY_KEY],
            exact: false,
          });

          queryClient.invalidateQueries({
            queryKey: [CLIENT_HOME_ROUTE_ACTIVITY_QUERY],
            exact: false,
          });

          queryClient.resetQueries({
            queryKey: [MANAGE_PROGRAMS_DIALOG_QUERY_KEY],
            exact: false,
          });
        },
      },
    });

  const handleDateChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setStartDate(new Date());
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleUpdateClick = React.useCallback(() => {
    changeStartDate({
      id: enrollment.id,
      startDate: getISODate(startDate),
    });
    handleClose();
  }, [changeStartDate, enrollment.id, handleClose, onSuccess, startDate]);

  const disabled = !startDate || changeStartDateInFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Change program start date"
      subtitle="Select date the program should start."
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleDateChange}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="Update start date"
        onClick={handleUpdateClick}
        disabled={disabled}
      />
    </BaseDialog>
  );
}
