import Url from "url";

import { Node } from "slate";
import { uniq } from "lodash";

import { ContentType } from "../types";
import { ComponentType } from "../constants";
import { ComponentTypeDefaultIcons } from "../components/program/icons";
import {
  CHECKIN_ELEMENT_TYPES,
  ElementType,
} from "../components/editor/types/elements";
import { FunctionComponent, SVGProps } from "react";
import { RowType } from "../constants";

// TODO: Find a nicer way to map between `ContentType` and `ComponentType`
// ContentType should be dropped and ComponentType should be used instead
// Getting current ContentType values should be refactored

export function getComponentType(contentType: ContentType): ComponentType {
  switch (contentType) {
    case "lesson":
      return ComponentType.LESSON;
    case "check-in":
      return ComponentType.CHECKIN;
    case "habit":
      return ComponentType.HABIT;
    case "workout":
      return ComponentType.WORKOUT;
    case "message":
      return ComponentType.MESSAGE;
    default:
      console.error("getComponentType:", "Invalid content type given");
  }
}

export const defaultComponentTitles: Record<ComponentType, string> = {
  [ComponentType.CHECKIN]: "Untitled Check-In",
  [ComponentType.HABIT]: "Untitled Habit",
  [ComponentType.LESSON]: "Untitled Lesson",
  [ComponentType.WORKOUT]: "Untitled Workout",
  [ComponentType.MESSAGE]: "Untitled Message",
};

export const getComponentDefaultTitle = (contentType: ComponentType): string =>
  defaultComponentTitles[contentType];

export function getContentType(
  componentType: ComponentType | RowType,
): ContentType {
  switch (componentType) {
    case ComponentType.LESSON:
      return "lesson";
    case ComponentType.CHECKIN:
      return "check-in";
    case ComponentType.HABIT:
      return "habit";
    case ComponentType.WORKOUT:
      return "workout";
    case ComponentType.MESSAGE:
      return "message";
    case RowType.WORKOUT_SECTION:
      return "section";
    case RowType.EXERCISE:
      return "exercise";
    default:
      console.error("getContentType:", "Invalid component type given");
  }
}

export function humanlyEnumerate(
  arr: string[],
  s1 = ", ",
  s2 = " and ",
): string {
  const lastIndex = arr.length - 1;
  const xs = arr.filter((_, index) => index < lastIndex);
  const tail = arr[lastIndex];

  return xs.length ? [xs.join(s1), tail].join(s2) : tail;
}

export function getDaysInfo(
  componentType: ComponentType,
  days: boolean[],
): string {
  if (componentType === ComponentType.LESSON) {
    return `Day ${days.indexOf(true) + 1}`;
  }

  if (days.filter(Boolean).length === 7) {
    return "Every Day";
  }

  const result = [];
  let chainStart = null;

  days.forEach((day, i, days) => {
    if (!day) {
      return; // Skip non-selected day
    }

    const nextDay = i === days.length - 1 ? null : days[i + 1];

    if (nextDay) {
      if (chainStart) {
        return; // Skip mid-chain day
      }

      return (chainStart = i + 1); // Start chain
    }

    if (chainStart) {
      result.push(`Day ${chainStart}-${i + 1}`); // Add chain
      return (chainStart = null); // Reset chain
    }

    return result.push(`Day ${i + 1}`); // Add single selected day
  });

  return result.join(", ");
}

export interface ComponentSpecifics {
  typeName: string;
  typeClassName: string;
  TypeIcon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
}

export function getComponentSpecifics(
  componentType: ComponentType,
): ComponentSpecifics {
  const typeClassName = componentType.toLowerCase();
  const TypeIcon = ComponentTypeDefaultIcons[componentType];

  switch (componentType) {
    case ComponentType.LESSON:
      return { typeName: "Lesson", typeClassName, TypeIcon };
    case ComponentType.HABIT:
      return { typeName: "Habit", typeClassName, TypeIcon };
    case ComponentType.CHECKIN:
      return { typeName: "Check-in", typeClassName, TypeIcon };
    case ComponentType.WORKOUT:
      return { typeName: "Workout", typeClassName, TypeIcon };
    case ComponentType.MESSAGE:
      return { typeName: "Message", typeClassName, TypeIcon };
    default:
      throw new Error("Invalid component type");
  }
}

export async function getVideoThumbnailImage(url: string): Promise<string> {
  const { pathname, query } = Url.parse(url, true);
  const paths = pathname.split("/").filter(Boolean);

  if (/^https:\/\/(www.youtube.com|youtu.be)/.test(url)) {
    const id = query.v || paths[0];

    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
  }

  if (url.startsWith("https://vimeo.com")) {
    const id = paths[0];
    const response = await fetch(
      `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`,
    );
    const parsed = await response.json();

    return parsed.thumbnail_url;
  }

  if (url.startsWith("urn:cloudflare:stream/")) {
    const id = (pathname as string).replace(/.*\//, "");
    const duration = query.duration
      ? Math.floor(parseFloat(query.duration as string) / 2)
      : 30;

    return `https://stridist.com/videos/${id}.jpg?time=${duration}s`;
  }

  return url;
}

export function getComponentImages(
  content: string | Node[],
  programImage?: string,
): string[] {
  if (!content) {
    return [];
  }
  const nodes: any[] =
    typeof content === "string" ? JSON.parse(content) : content;
  const images = nodes
    .filter(({ type, url }) => /^(image|video)/.test(type as string) && url)
    .map(({ url }) => url) as string[];

  images.sort();

  if (programImage) {
    images.unshift(programImage);
  }

  return uniq(images).filter(Boolean);
}

export const videoThumbnailRegexps = [
  /^https:\/\/(img.youtube|i.vimeocdn|videodelivery).(com|net)/,
  /^https:\/\/stridist.com\/videos/,
];

export const videoUrlsRegexps = [
  /^https:\/\/(www\.)?(youtube|youtu|vimeocdn|videodelivery|vimeo).(com|net|be)/,
  /^https:\/\/stridist.com\/videos/,
];

export const isVideoThumbnail = (url: string) =>
  videoThumbnailRegexps.some((r) => r.test(url));

export const isVideoUrl = (url: string) =>
  videoUrlsRegexps.some((r) => r.test(url));

export const formatVideoUrl = (url: string) => {
  if (/youtu\.be\//.test(url)) {
    return (
      "https://www.youtube.com/watch?v=" +
      url.replace(/.*\//, "").replace(/\?.*/, "")
    );
  }

  return url;
};

export const isVideoStream = (url: string) =>
  /^urn:cloudflare:stream\//.test(url);

export const getCloudFlareSources = (
  url: string,
  origin = window.location.origin,
) => {
  const sources = [];
  const {
    pathname: videoId,
    query: { preview },
  } = Url.parse(url.replace(/^urn:.*stream\//, ""), true);

  sources.push({
    src: `https://videodelivery.net/${videoId}/manifest/video.mpd?parentOrigin=${encodeURIComponent(
      origin,
    )}`,
    type: "application/dash+xml",
  });

  sources.push({
    src: `https://videodelivery.net/${videoId}/manifest/video.m3u8?parentOrigin=${encodeURIComponent(
      origin,
    )}`,
    type: "application/x-mpegURL",
  });

  if (preview) {
    sources.push({
      src: preview,
      type: "video/mp4",
    });
  }

  return sources;
};

export const DEFAULT_COMPONENT_DAYS = `[true, false, false, false, false, false, false]`;

export const generateComponentDays = (dayOfWeek: number): string => {
  if (!dayOfWeek) return DEFAULT_COMPONENT_DAYS;
  const days = [...Array(7)].map((_, index) => index === dayOfWeek);
  return `[${days.join(", ")}]`;
};

export const parseComponentDays = (daysString: string): boolean[] => {
  try {
    const parsedArray = JSON.parse(daysString);
    return Array.isArray(parsedArray) &&
      parsedArray.every((day) => typeof day === "boolean")
      ? parsedArray
      : [];
  } catch {
    console.error("Error parsing days string");
    return [];
  }
};

export const stringifyComponentDays = (daysArray: boolean[]): string => {
  return Array.isArray(daysArray) &&
    daysArray.every((day) => typeof day === "boolean")
    ? JSON.stringify(daysArray)
    : "[]";
};

export const isValidUrl = (s: string) => {
  try {
    new URL(s);
    return true;
  } catch (e) {
    return false;
  }
};

export function isTextAnswerValid(answer: any): boolean {
  if (answer.text === undefined) {
    return true;
  }

  return answer.text !== "";
}

export function isPhotosAnswerValid(answer: any): boolean {
  if (answer.photos === undefined) {
    return true;
  }

  const keys = Object.keys(answer.photos);
  return (
    keys.length > 0 &&
    keys.every((key) => ["front", "side", "back"].includes(key))
  );
}

export function isNumberAnswerValid(answer: any): boolean {
  if (answer.number === undefined) {
    return true;
  }

  return Number.isFinite(answer.number);
}

export function isAnswerValid(answer: any) {
  return (
    answer &&
    isTextAnswerValid(answer) &&
    isPhotosAnswerValid(answer) &&
    isNumberAnswerValid(answer)
  );
}

export function checkinQuestionsAreValid(
  content: any,
  checkRequired: boolean = true,
) {
  let invalid = false;

  if (checkRequired) {
    invalid = content.some(
      (node: any) =>
        CHECKIN_ELEMENT_TYPES.includes(node.type) &&
        node.required &&
        !isAnswerValid(node.answer),
    );
  } else {
    // In any case a non-number shouldn't be saved as an answer to a number question
    invalid = content
      .filter((node: any) => node.type === ElementType.CHECKIN_ANSWER_NUMBER)
      .some((node: any) => !Number.isFinite(node.answer?.number));
  }

  return !invalid;
}
