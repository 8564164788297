import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppLogo } from "../app/AppLogo";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { AppError, AcceptClientInviteInvalidReason } from "../../constants";
import { colorSystem } from "../../theme";

import { ClientAcceptInviteInvalid } from "./ClientAcceptInviteInvalid";
import { useSignUpMutation as useClientSignUpMutation } from "./mutations/SignUp";
import { ClientAcceptInviteForm } from "./ClientAcceptInviteForm";
import firebase from "firebase";
import { SIGN_UP_CLIENT_COMPLETE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ConsentBanner } from "../app/ConsentBanner";
import { InviteInfoDto } from "@growth-machine-llc/stridist-api-client";
import useAuth from "../../hooks/auth/useAuth";
import { useMutation } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import { validatePassword } from "../../utils/password";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  logo: {
    marginBottom: theme.spacing(4),
  },
}));

export interface ClientAcceptInviteScreenProps extends BoxProps {
  invite: InviteInfoDto;
  email: string;
  code: string;
  id: string;
}

export function ClientAcceptInviteScreen(props: ClientAcceptInviteScreenProps) {
  const { className, invite, email, code, id, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const onError = useGenericErrorHandler({
    setFieldError: (field, message) => {
      field === "password" && setPasswordError(message);
    },
  });
  const user = useCurrentUser();

  const { loginWithToken } = useAuth();
  const { mutate: signUp, isPending: signingUp } = useClientSignUpMutation({
    onError,
  });
  const { mutate: verifyEmail, isPending: verifyingEmail } = useMutation({
    mutationKey: ["verify-client-email"],
    mutationFn: FbUsersService.verifyUserEmail,
  });

  const handlePasswordChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      !value.length && setPasswordError("");
      setPassword(value);
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      event.preventDefault();
      // TODO: Improve password validation by using zod or react-hook-form
      const passwordError = validatePassword(password);
      if (passwordError) {
        setPasswordError(passwordError);
        return;
      }

      verifyEmail(
        {
          email,
          oobCode: code,
        },
        {
          onSuccess: () => {
            signUp(
              {
                userId: id,
                email,
                password: password,
                timezone: dayjs.tz.guess(),
              },
              {
                onSuccess: async (response) => {
                  const accessToken = response?.accessToken;
                  await loginWithToken(accessToken);
                  navigate(SIGN_UP_CLIENT_COMPLETE);
                },
              },
            );
          },
        },
      );
    },
    [code, email, id, onError, password, signUp, verifyEmail],
  );

  if (user) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.ANOTHER_USER_LOGGED}
      />
    );
  }

  if (!invite) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.INVITATION_REVOKED}
      />
    );
  }

  if (invite?.accepted) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.INVITATION_ACCEPTED}
      />
    );
  }

  const loading = signingUp || verifyingEmail;
  const passwordValid = password?.length >= 8;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo className={s.logo} />

      <ClientAcceptInviteForm
        coachName={invite?.coachInfo?.displayName}
        email={email}
        password={password}
        passwordError={passwordError}
        onPasswordChange={handlePasswordChange}
        onSubmit={handleSubmit}
        submitDisabled={Boolean(!passwordValid || loading)}
      />
      <ConsentBanner />
    </Box>
  );
}
