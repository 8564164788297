import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import WorkspacesService from "../../../services/WorkspacesService";
import { useToastAlert } from "../../app/ToastAlert/ToastAlertProvider";
import {
  IGetWorkspaceClientPortalDto,
  IUpdateMealLoggingPromptsCommand,
} from "@growth-machine-llc/stridist-api-client";
import { COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY } from "../../../routes/coach/settings/client-portal/CoachSettingsClientPortalRoute";

type UpdateMealLoggingQuestionsMutationVariables =
  IUpdateMealLoggingPromptsCommand & {
    mealLoggingQuestionsTotal: number;
  };
export const useUpdateMealLoggingQuestionsMutation = (
  props?: UseReactQueryPropsBase,
) => {
  const queryClient = useQueryClient();
  const { showToastAlert } = useToastAlert();
  return useMutation({
    mutationKey: ["update-workspace"],
    mutationFn: (variables: UpdateMealLoggingQuestionsMutationVariables) =>
      WorkspacesService.updateMealLoggingPrompts({
        id: variables.id,
        mealLoggingQuestions: variables.mealLoggingQuestions,
      }),
    ...props,
    onSuccess: (
      result,
      { mealLoggingQuestions, mealLoggingQuestionsTotal },
    ) => {
      queryClient.setQueryData<IGetWorkspaceClientPortalDto>(
        [COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY],
        (prev) => ({
          ...prev,
          mealLoggingQuestions,
          mealLoggingQuestionsTotal,
        }),
      );

      showToastAlert("success", {
        message: "Meal logging prompts updated.",
      }),
        props?.onSuccess && props.onSuccess(result);
    },
  });
};
