import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  TextField,
  Button,
  TextFieldProps,
  Divider,
  Tabs,
  Tab,
  Autocomplete,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import {
  ClientAddableItemProps,
  ClientAddableItem,
} from "../item/ClientAddableItem";
import {
  GroupAddableItemProps,
  GroupAddableItem,
} from "../item/GroupAddableItem";
import { AddedLozenge } from "../item/AddedLozenge";
import { EnrollDialogEmpty } from "../dialog/EnrollDialogEmpty";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { useDebounce } from "../../hooks/useDebounce";

import {
  ClientBriefDto,
  EnrollGroupDto,
  EnrollmentProgramDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { ProgramDialogSkeleton } from "../loading/ProgramDialogSkeleton";
import ClientsService from "../../services/ClientsService";
import { BadgeCounter } from "../counter/BadgeCounter";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  tabs: {},

  tab: {
    maxWidth: "initial",
    flexGrow: 1,
    fontSize: 14,
    fontWeight: "bold",
    color: `${theme.palette.secondary.main}99`,
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },

  divider: {
    height: 1,
    backgroundColor: theme.palette.border.primary,
    marginTop: -1,
  },

  input: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },

  lozenges: {
    padding: theme.spacing(2, 0),
    whiteSpace: "nowrap",
    overflowX: "auto",
  },

  lozenge: {
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  list: {
    height: 350,
    overflowY: "auto",
  },

  item: {
    padding: theme.spacing(2.5, 0),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },

  selected: {},
}));

const tabs = ["clients", "groups"];

export interface EnrollmentSelected {
  clients?: ClientBriefDto[];
  groups?: EnrollGroupDto[];
}

export interface EnrollDialogProps extends DialogProps {
  program?: EnrollmentProgramDto;
  dialogTitle?: string;
  submitButtonText?: string;
  clientsCount?: number;
  groupsCount?: number;
  selectedClients?: ClientBriefDto[];
  selectedGroups?: EnrollGroupDto[];
  buttonAlwaysActive?: boolean;
  fetchOnlyActiveAndArchived?: boolean;
  onSelected: (selected: EnrollmentSelected) => void;
  showChips?: boolean;
  showCount?: boolean;
  returnAllSelected?: boolean;
}

export const MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY =
  "manage-program-clients-dialog";
export const MANAGE_PROGRAM_GROUPS_DIALOG_QUERY_KEY =
  "manage-program-groups-dialog";
const MANAGE_PROGRAMS_DIALOG_PAGE_SIZE = 10;
const FETCH_NEXT_PAGE_WHEN_REMAINING = 2;

export function ClientsAndGroupsSelectDialog(props: EnrollDialogProps) {
  const {
    className,
    onClose,
    program,
    clientsCount,
    dialogTitle = "Enroll",
    submitButtonText = "Next",
    groupsCount,
    selectedClients = [],
    selectedGroups = [],
    buttonAlwaysActive,
    fetchOnlyActiveAndArchived,
    showChips,
    showCount,
    onSelected,
    returnAllSelected,
    ...other
  } = props;
  const s = useStyles();
  const [filter, setFilter] = React.useState("");
  const delayedFilter = useDebounce(filter, 250);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [addedClients, setAddedClients] = React.useState<ClientBriefDto[]>(
    selectedClients || [],
  );
  const [addedGroups, setAddedGroups] = React.useState<EnrollGroupDto[]>(
    selectedGroups || [],
  );

  const debouncedQuery = useDebounce(filter, 300);

  const {
    data: enrollmentsData,
    isLoading: isLoadingEnrollments,
    ref: enrollmentElementRef,
    isFetching: isFetchingEnrollments,
    isFetchingNextPage: isFetchingNextPageEnrollments,
    hasNextPage: hasNextPageEnrollments,
    fetchNextPage: fetchNextPageEnrollments,
  } = useInfiniteScrollQuery({
    queryKey: [
      MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY,
      { query: debouncedQuery, programId: program?.id },
    ],
    queryFn: ({ pageParam = 1 }) =>
      ClientsService.getTargetClients(
        pageParam as number,
        MANAGE_PROGRAMS_DIALOG_PAGE_SIZE,
        {
          programId: program?.id,
          searchQuery: debouncedQuery === "" ? undefined : debouncedQuery,
          statuses: [
            UserInviteStatus.ACTIVE,
            ...(fetchOnlyActiveAndArchived
              ? [UserInviteStatus.ARCHIVED]
              : [UserInviteStatus.ADDED, UserInviteStatus.PENDING]),
          ],
        },
      ),
    enabled: selectedTab === 0,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const enrollments =
    enrollmentsData?.pages.flatMap((page) => page.items) ?? [];

  const {
    data: groupEnrollmentsData,
    isLoading: isLoadingGroupEnrollments,
    ref: groupEnrollmentRef,
    isFetching: isFetchingGroupEnrollments,
    isFetchingNextPage: isFetchingNextPageGroupEnrollments,
    hasNextPage: hasNextPageGroupEnrollments,
    fetchNextPage: fetchNextPageGroupEnrollments,
  } = useInfiniteScrollQuery({
    queryKey: [
      MANAGE_PROGRAM_GROUPS_DIALOG_QUERY_KEY,
      { query: debouncedQuery },
    ],
    queryFn: ({ pageParam = 1 }) =>
      EnrollmentsClientService.getGroupsForEnrollment(
        pageParam as number,
        MANAGE_PROGRAMS_DIALOG_PAGE_SIZE,
        debouncedQuery === "" ? undefined : debouncedQuery,
      ),
    enabled: selectedTab === 1,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const groupEnrollments =
    groupEnrollmentsData?.pages.flatMap((page) => page.items) ?? [];

  const handleFilterChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setFilter(event.target.value);
    },
    [],
  );

  const getTabClickHandler = React.useCallback(
    (index) => () => {
      setFilter("");
      setSelectedTab(index);
    },
    [setSelectedTab, setFilter],
  );

  const removeClient = React.useCallback(
    (email: string) => {
      const i = addedClients.findIndex(
        (addedClient) => addedClient.email === email,
      );

      if (i !== -1) {
        const updated = [...addedClients];
        updated.splice(i, 1);
        setAddedClients(updated);
      }
    },
    [addedClients],
  );

  const removeGroup = React.useCallback(
    (id: number) => {
      const i = addedGroups.findIndex((addedGroup) => addedGroup.id === id);

      if (i !== -1) {
        const updated = [...addedGroups];
        updated.splice(i, 1);
        setAddedGroups(updated);
      }
    },
    [addedGroups],
  );

  const handleToggleClient: ClientAddableItemProps["onToggle"] =
    React.useCallback(
      (added, client) => {
        if (added) {
          setAddedClients((v) => [...v, client]);
        } else {
          removeClient(client.email);
        }
      },
      [removeClient],
    );

  const handleToggleGroup: GroupAddableItemProps["onToggle"] =
    React.useCallback(
      (added, group) => {
        if (added) {
          setAddedGroups((v) => [...v, group]);
        } else {
          removeGroup(group.id);
        }
      },
      [removeGroup],
    );

  const getRemoveClientHandler = React.useCallback(
    (email: string) => () => removeClient(email),
    [removeClient],
  );

  const getRemoveGroupHandler = React.useCallback(
    (id: number) => () => removeGroup(id),
    [removeGroup],
  );

  const resetState = React.useCallback(() => {
    setFilter("");
    setAddedClients([]);
    setAddedGroups([]);
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const entity = React.useMemo(
    () => tabs[selectedTab].substring(0, tabs[selectedTab].length - 1),
    [selectedTab],
  );

  const handleAutocompleteUpdate = React.useCallback(
    (_, newValue, reason) => {
      if (reason === "clear") {
        setFilter("");
        selectedTab === 0 ? setAddedClients([]) : setAddedGroups([]);
      }
    },
    [selectedTab, setAddedClients, setAddedGroups],
  );

  const count = React.useMemo(
    () => (selectedTab === 0 ? clientsCount : groupsCount),
    [clientsCount, groupsCount, selectedTab],
  );

  const handleNextClick = React.useCallback(() => {
    let selected = {};
    if (returnAllSelected) {
      selected = {
        clients: addedClients,
        groups: addedGroups,
      };
    } else {
      selected = {
        ...(selectedTab === 0
          ? {
              clients: addedClients,
            }
          : {
              groups: addedGroups,
            }),
      };
    }
    handleClose();
    onSelected(selected);
  }, [addedClients, addedGroups, handleClose, onSelected, selectedTab]);

  const nextButton = (
    <>
      <Divider />

      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={submitButtonText}
        onClick={handleNextClick}
        disabled={
          !buttonAlwaysActive &&
          (selectedTab === 0 ? !addedClients.length : !addedGroups.length)
        }
      />
    </>
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`${dialogTitle} ${entity}s`}
      subtitle={program && `Select ${entity}s to enroll into ${program.name}`}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <Tabs
        className={s.tabs}
        value={selectedTab}
        variant="scrollable"
        indicatorColor="primary"
      >
        {tabs.map((name, index) => (
          <Tab
            key={index}
            label={
              <Box display="flex" alignItems="flex-start">
                {name}
                {showCount &&
                  (index === 0 ? addedClients.length : addedGroups.length) >
                    0 && (
                    <BadgeCounter
                      sx={{ mt: -0.7 }}
                      counter={
                        index === 0 ? addedClients.length : addedGroups.length
                      }
                    />
                  )}
              </Box>
            }
            value={index}
            className={clsx(s.tab, selectedTab === index && s.selected)}
            onClick={getTabClickHandler(index)}
          />
        ))}
      </Tabs>
      <Divider className={s.divider} orientation="horizontal" />

      {showChips ? (
        <Autocomplete
          className={s.input}
          onInputChange={handleAutocompleteUpdate}
          fullWidth
          multiple
          freeSolo
          inputValue={filter}
          value={selectedTab === 0 ? addedClients : addedGroups}
          renderInput={(params) => (
            <TextField
              {...params}
              value={filter}
              onChange={handleFilterChange}
              placeholder={`Filter ${count || "by"} ${entity}${count === 1 ? "" : "s"}`}
            />
          )}
          options={[]}
          renderTags={(records: any[], _1) => {
            return (
              <>
                {records
                  .toSpliced(3)
                  .map(({ email, id, displayName, name }) => (
                    <AddedLozenge
                      key={email || id}
                      className={s.lozenge}
                      text={displayName || name}
                      onRemoveClick={
                        email
                          ? getRemoveClientHandler(email)
                          : getRemoveGroupHandler(id)
                      }
                    />
                  ))}
                {records.length > 3 && `+${records.length - 3}`}
              </>
            );
          }}
        />
      ) : (
        <TextField
          className={s.input}
          variant="outlined"
          fullWidth
          value={filter}
          onChange={handleFilterChange}
          label={`Filter ${count || ""} ${entity}${count === 1 ? "" : "s"}`}
        />
      )}

      {selectedTab === 0 ? (
        <Box className={s.list}>
          {isLoadingEnrollments ? (
            Array.from({ length: 3 }).map((_, i) => (
              <ProgramDialogSkeleton key={`client-skeleton-${i}`} />
            ))
          ) : (
            <>
              {enrollments.length > 0
                ? enrollments.map((enrollment, index, arr) => (
                    <React.Fragment key={enrollment.id}>
                      {index ===
                        arr.length - FETCH_NEXT_PAGE_WHEN_REMAINING && (
                        <div ref={enrollmentElementRef} />
                      )}
                      <ClientAddableItem
                        className={s.item}
                        client={enrollment}
                        added={Boolean(
                          addedClients.find(
                            ({ email }) => email === enrollment.email,
                          ),
                        )}
                        disabled={enrollment.targetBool}
                        disabledText="This client is already enrolled in the program."
                        onToggle={handleToggleClient}
                      />
                      {index < arr.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                : !isLoadingEnrollments && (
                    <EnrollDialogEmpty
                      header={
                        debouncedQuery
                          ? "No clients match your search"
                          : "No clients"
                      }
                      filter={filter}
                    />
                  )}
            </>
          )}

          {hasNextPageEnrollments && (
            <LoadMoreButton
              onClick={() =>
                !isFetchingNextPageEnrollments && fetchNextPageEnrollments()
              }
              disabled={isFetchingEnrollments && !isFetchingNextPageEnrollments}
              loading={isFetchingNextPageEnrollments}
            />
          )}
        </Box>
      ) : (
        <Box className={s.list} ref={groupEnrollmentRef}>
          {isLoadingGroupEnrollments ? (
            Array.from({ length: 3 }).map((_, i) => (
              <ProgramDialogSkeleton key={`group-skeleton-${i}`} />
            ))
          ) : (
            <>
              {groupEnrollments.length > 0
                ? groupEnrollments.map((group, index, arr) => (
                    <React.Fragment key={group.id}>
                      {index ===
                        arr.length - FETCH_NEXT_PAGE_WHEN_REMAINING && (
                        <div ref={groupEnrollmentRef} />
                      )}
                      <GroupAddableItem
                        className={s.item}
                        group={group}
                        added={Boolean(
                          addedGroups.find(({ id }) => id === group.id),
                        )}
                        disabled={group.programs.some(
                          (programItem) => programItem.id === program?.id,
                        )}
                        disabledText="This group is already enrolled in the program."
                        onToggle={handleToggleGroup}
                      />
                      {index < arr.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                : !isLoadingGroupEnrollments && (
                    <EnrollDialogEmpty
                      header={
                        debouncedQuery
                          ? "No groups match your search"
                          : "No groups"
                      }
                      filter={delayedFilter}
                    />
                  )}
            </>
          )}

          {hasNextPageGroupEnrollments && (
            <LoadMoreButton
              onClick={() =>
                !isFetchingNextPageGroupEnrollments &&
                fetchNextPageGroupEnrollments()
              }
              disabled={
                isFetchingGroupEnrollments &&
                !isFetchingNextPageGroupEnrollments
              }
              loading={isFetchingNextPageGroupEnrollments}
            />
          )}
        </Box>
      )}
      {(enrollments.length > 0 || groupEnrollments.length > 0) && nextButton}
    </BaseDialog>
  );
}
