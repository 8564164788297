import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import GroupPostsService from "../../../services/GroupPostsService";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { GROUP_POSTS_LIST_QUERY_KEY } from "../GroupPostsList";
import {
  GroupPostDto,
  ICursorPaginatedListOfGroupPostDto,
} from "@growth-machine-llc/stridist-api-client";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

interface UpsertGroupPostMutationParams {
  groupId: number;
}

export const useUpsertGroupPostMutation = (
  params: UpsertGroupPostMutationParams,
) => {
  return {
    createGroupPost: useCreateGroupPostMutation(params),
    updateGroupPost: useUpdateGroupPostMutation(params),
  };
};

const useUpdateGroupPostMutation = ({
  groupId,
}: UpsertGroupPostMutationParams) => {
  const updater = INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties;
  return useOptimisticUpdateMutation({
    disableToastAlerts: true,
    mutationFn: GroupPostsService.updateGroupPost,
    queryKey: [GROUP_POSTS_LIST_QUERY_KEY, { groupId }],
    optimisticUpdater: {
      updateFn: (
        prev: InfiniteData<ICursorPaginatedListOfGroupPostDto>,
        newPost,
      ) => {
        return updater(prev, newPost.id, { ...newPost });
      },
    },
  });
};

const useCreateGroupPostMutation = ({
  groupId,
}: UpsertGroupPostMutationParams) => {
  const queryCLient = useQueryClient();
  const successUpdate = INFINITE_PAGINATED_DATA_UPDATERS.addItem;
  return useMutation({
    mutationFn: GroupPostsService.createGroupPost,
    onSuccess: (newPost) => {
      queryCLient.setQueryData<
        InfiniteData<ICursorPaginatedListOfGroupPostDto>
      >([GROUP_POSTS_LIST_QUERY_KEY, { groupId }], (prev) =>
        successUpdate(prev, newPost),
      );
    },
  });
};
