import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import { selectComponentTempIdsMap } from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";
import {
  ComponentStatus,
  IUpdateComponentCommand,
} from "@growth-machine-llc/stridist-api-client";

export default function* publishComponentWorker(payload: {
  componentId: number;
  title: string;
  content: string;
}) {
  const { componentId, content, title } = payload;
  const map: TempIdsMap = yield select(selectComponentTempIdsMap);
  const checkedComponentId = map?.[componentId] || componentId;

  const command: IUpdateComponentCommand = {
    id: checkedComponentId,
    content,
    title,
    status: ComponentStatus.PUBLISHED,
  };

  try {
    yield call(ComponentsService.update, command);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
