import { createContext } from "react";
import { ClientsVm } from "@growth-machine-llc/stridist-api-client";

export interface ICoachClientListContextProps {
  coachClientsList: ClientsVm;
  setCoachClientsList: (programsData: ClientsVm) => void;
}

const CoachClientListContext = createContext<
  ICoachClientListContextProps | undefined
>(undefined);

export default CoachClientListContext;
