import { userNotesClient } from "../api/ApiClients";

import {
  CreateUserNoteCommand,
  ICreateUserNoteCommand,
  IUpdateUserNoteCommand,
  UpdateUserNoteCommand,
} from "@growth-machine-llc/stridist-api-client";

import ServiceBase from "./ServiceBase";

class UserNotesService extends ServiceBase {
  async getUserNotes(userId: number, page: number, pageSize: number) {
    return userNotesClient.getNotesOnUser(userId, page, pageSize);
  }

  async createUserNote(data: ICreateUserNoteCommand) {
    const command = CreateUserNoteCommand.fromJS(data);
    return userNotesClient.createUserNote(command);
  }

  async updateUserNote(data: IUpdateUserNoteCommand) {
    const command = UpdateUserNoteCommand.fromJS(data);
    return userNotesClient.updateUserNote(data.id, command);
  }

  async deleteUserNote(noteId: number) {
    return userNotesClient.deleteUserNote(noteId);
  }
}

export default new UserNotesService();
