import clsx from "clsx";
import React, { MouseEventHandler, Suspense, useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  Collapse,
  Skeleton,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  useProgramSchedule,
  ScheduleUpdate,
} from "../../hooks/useProgramSchedule";
import {
  ProgramCalendarWeek,
  ProgramCalendarWeekProps,
} from "../program-calendar/ProgramCalendarWeek";
import { WEEK_DRAG_ITEM_TYPE } from "./types";
import { getDaysSpan, setSpan } from "./utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProgramCalendarSidebar from "./ProgramCalendarSidebar";
import { grey } from "@mui/material/colors";
import { TransitionProps } from "@mui/material/transitions";
import CalendarSidebarTitle from "./components/CalendarSidebarTitle";
import { getBannerHeight } from "../../contexts/BannersContext";
import ComponentDialog from "../program/ComponentDialog";
import { NormalizedCurriculumProgram } from "../../redux/types";
import {
  parseComponentDays,
  stringifyComponentDays,
} from "../../utils/component";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import { updateComponentPosition } from "../../redux/curriculum/curriculum-slice";
import { denormalizeCurriculum } from "../../redux/normalize";
import { selectComponentExistsBySlugId, selectCurriculum } from "../../redux/curriculum/selectors/curriculum";
import { extractSlugId } from "../../utils/slug";

export const CALENDAR_SIDEBAR_WIDTH = 320;

const ProgramCalendarSidebarFallback = () => {
  const theme = useTheme();
  return (
  <>
    <Box
      display={"flex"}
      gap={1}
      sx={{
        justifyContent: "end",
        position: "absolute",
        top: "-40px",
        right: "0px",
        width: "80%",
        height: "40px",
      }}
    >
      {/* TODO reuse <Tab/> instead */}
      <Skeleton
        width={"20%"}
        sx={{ background: theme.palette.selected.main }}
      />
      <Skeleton
        width={"20%"}
        sx={{ background: theme.palette.selected.main }}
      />
      <Skeleton
        width={"20%"}
        sx={{ background: theme.palette.selected.main }}
      />
    </Box>
    <CalendarSidebarTitle
      title={""}
      color={theme.palette.selected.main}
      componentSlug={"slug"}
      setActivityPreview={() => {}}
      openDialog={false}
      handleClickOpenDialog={() => {}}
      handleCloseDialog={() => {}}
    />
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      color={grey[400]}
      sx={{ height: "500px" }}
    >
      <CircularProgress size={20} color={"inherit"} />
    </Box>
  </>
);
}

export const ComponentDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  card: {
    margin: theme.spacing(3, 0, 4, 0),
  },
}));

export interface ProgramCalendarWeeksListProps extends BoxProps {
  filters?: ProgramCalendarWeekProps["filters"];
  startDate?: string;
  onOpenMenu?: ProgramCalendarWeekProps["onOpenMenu"];
  program: NormalizedCurriculumProgram;
  weeks?: number[];
  programId?: number;
  activityPreview: boolean;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  duplicateWeekInFlight: boolean;
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickMove: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
  weeksLength: number;
}

export function ProgramCalendarWeeksList(props: ProgramCalendarWeeksListProps) {
  const {
    activityPreview,
    setActivityPreview,
    className,
    program,
    filters,
    startDate,
    onOpenMenu,
    weeks,
    duplicateWeekInFlight,
    weeksActions,
    weeksLength,
  } = props;
  const dispatch = useCurriculumDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const programSlug = params.slug;
  const componentSlugSearchParams = searchParams.get("component");

  const s = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [scheduleUpdate, setScheduleUpdate] = React.useState<ScheduleUpdate>();

  const scheduleAssets = denormalizeCurriculum(
    useCurriculumSelector(selectCurriculum).value,
  );
  const [components, schedule] = useProgramSchedule(
    scheduleAssets,
    filters,
    scheduleUpdate,
  );

  const handleMove: ProgramCalendarWeekProps["onMove"] = React.useCallback(
    (update) => {
      const { type, componentId, day, week } = update;
      const originDay = scheduleUpdate
        ? scheduleUpdate.originDay
        : update.originDay;
      const originWeek = update.originWeek;
      const component = components.find(({ id }) => id === componentId);
      const days = [...parseComponentDays(component.days)];
      const originSpan = getDaysSpan(days, originDay);
      let weekId = scheduleUpdate ? scheduleUpdate.weekId : component.weekId,
        offset = 0;

      setSpan(days, originDay, originSpan, false);

      switch (type) {
        case WEEK_DRAG_ITEM_TYPE.COMPONENT:
          const weekOffset = week - originWeek;
          const currentWeekIndex = program.weeks.findIndex(
            (id) => id === weekId,
          );
          const newWeekIndex = Math.min(
            Math.max(currentWeekIndex + weekOffset, 0),
            weeksLength - 1,
          );

          weekId = program.weeks[newWeekIndex];
          setSpan(days, day, originSpan, true);
          break;

        case WEEK_DRAG_ITEM_TYPE.RESIZE_LEFT:
          offset = originDay - day;
          offset = Math.max(offset, 1 - originSpan);

          setSpan(days, originDay - offset, originSpan + offset, true);
          break;

        case WEEK_DRAG_ITEM_TYPE.RESIZE_RIGHT:
          offset = originDay + originSpan - day - 1;

          setSpan(days, originDay, Math.max(originSpan - offset, 1), true);
          break;
      }

      setScheduleUpdate({
        id: update.componentId,
        weekId,
        days,
        originDay,
        originWeek,
      });
    },
    [components, weeks, scheduleUpdate],
  );

  const handleMoveEnd = React.useCallback(() => {
    if (!scheduleUpdate) return;

    dispatch(
      updateComponentPosition({
        originWeek: scheduleUpdate.originWeek,
        newWeekId: scheduleUpdate.weekId,
        componentId: scheduleUpdate.id,
        days: stringifyComponentDays(scheduleUpdate.days),
      }),
    );

    setScheduleUpdate(null);
  }, [scheduleUpdate]);

  const [activityPreviewItem, setActivityPreviewItem] = useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const slugId = extractSlugId(activityPreviewItem);
  const showSidebar = useCurriculumSelector(selectComponentExistsBySlugId(slugId));

  const handleClickOpenDialog = (e?: any, slug?: string) => {
    slug && setActivityPreviewItem(slug);
    navigate(
      `?component=${slug ?? componentSlugSearchParams ?? activityPreviewItem}`,
      { replace: true },
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    // update search params
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("component");
    setSearchParams(updatedParams);
    setOpenDialog(false);
  };

  useEffect(() => {
    if (componentSlugSearchParams && programSlug) {
      setActivityPreviewItem(componentSlugSearchParams);
      handleClickOpenDialog();
    }
  }, [componentSlugSearchParams]);

  useEffect(() => {
    isMd && setActivityPreview(false);
  }, [isMd]);

  useEffect(() => {
    searchParams.size === 0 && handleCloseDialog();
  }, [searchParams]);

  const bannerSpacing = getBannerHeight();

  return (
    <Box
      sx={{
        margin: theme.spacing(1, 0),
        display: "flex",
        gap: 2,
      }}
    >
      <Box className={clsx(s.root, className)}>
        {weeks.map((week, i) => (
          <ProgramCalendarWeek
            className={s.card}
            key={week}
            weekId={week}
            schedule={schedule}
            filters={filters}
            startDate={startDate}
            onOpenMenu={onOpenMenu}
            onMove={handleMove}
            onMoveEnd={handleMoveEnd}
            setActivityPreview={setActivityPreview}
            activityPreviewItem={activityPreview && activityPreviewItem}
            setActivityPreviewItem={setActivityPreviewItem}
            handleClickOpenDialog={handleClickOpenDialog}
            activityPreview={activityPreview}
            duplicateWeekInFlight={duplicateWeekInFlight}
            weeksActions={weeksActions}
          />
        ))}
      </Box>

      <Collapse in={activityPreview && showSidebar} orientation="horizontal" unmountOnExit>
        <Box
          sx={{
            marginTop: 8.5,
            marginBottom: 4,
            backgroundColor: theme.palette.background.paper,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.palette.quote,
            borderRadius: theme.spacing(1),
            minWidth: CALENDAR_SIDEBAR_WIDTH,
            maxWidth: CALENDAR_SIDEBAR_WIDTH,
            position: "sticky",
            top: `calc(44px + ${theme.spacing(bannerSpacing)})`,
          }}
        >
          {activityPreviewItem && (
            <Suspense
              fallback={<ProgramCalendarSidebarFallback />}
            >
              <ProgramCalendarSidebar
                slug={activityPreviewItem}
                fallback={<ProgramCalendarSidebarFallback />}
                setActivityPreview={setActivityPreview}
                openDialog={openDialog}
                handleClickOpenDialog={handleClickOpenDialog}
                handleCloseDialog={handleCloseDialog}
              />
            </Suspense>
          )}
        </Box>
      </Collapse>

      <ComponentDialog
        componentSlug={activityPreviewItem}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleClickOpenDialog={handleClickOpenDialog}
      />
    </Box>
  );
}
