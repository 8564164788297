import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Accordion, AccordionProps } from "../accordion/Accordion";
import { isEmptyContent } from "../editor/utils/common";
import { getContentTeaser } from "../../utils/slate";
import { MessageEditor } from "../new-editor/MessageEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },

  editor: {
    marginTop: theme.spacing(2.75),
    marginBottom: theme.spacing(0.5),
  },

  input: {
    border: "1px solid",
    borderColor: theme.palette.border.secondary,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(3, 0),

    "& input": {
      padding: theme.spacing(2, 1.5),
      fontWeight: 600,
      fontSize: 18,
    },
  },

  buttons: {
    position: "absolute",
    bottom: theme.spacing(3.25),
    right: theme.spacing(3.25),
    paddingBottom: theme.spacing(1),
  },
}));

const placeholder = "What message do you want to send?";

export interface MessageComponentPanelProps
  extends Omit<AccordionProps, "onChange"> {
  componentId: number;
  content: any;
  onChange: any;
  onPanelChange?: AccordionProps["onChange"];
}

export function MessageComponentPanel(props: MessageComponentPanelProps) {
  const { className, onChange, componentId, content, onPanelChange, ...other } =
    props;
  const s = useStyles();
  const empty = isEmptyContent(content);

  return (
    <Accordion
      label="Message"
      header={empty ? placeholder : getContentTeaser(content)}
      className={clsx(s.root, className)}
      onChange={onPanelChange}
      {...other}
    >
      {props.open && (
        <MessageEditor
          className={s.editor}
          backupKeyType="scheduled-message"
          backupKeyId={componentId}
          placeholder={placeholder}
          message={content as any}
          onMessageChange={onChange}
          hideSendButton
          classes={{
            input: "!overflow-y-auto",
          }}
        />
      )}
    </Accordion>
  );
}
