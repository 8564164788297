import clsx from "clsx";
import React from "react";
import { Box, Grow, ListItem, Typography } from "@mui/material";
import { ExerciseAsset } from "./types";
import { MinimizedExerciseTooltip } from "./ExerciseLibraryExercise";

const ExerciseAutocompleteOption = (
  props: React.HTMLAttributes<HTMLElement>,
  option: ExerciseAsset,
  classes?: {
    option?: string;
  },
  canEdit?: boolean,
) => {
  return (
    <Grow in>
      <ListItem {...props} key={option.assetId}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <MinimizedExerciseTooltip canEdit={canEdit} />
          <Typography
            className={clsx(classes?.option)}
            textAlign={"start"}
            variant="subtitle1"
            lineHeight={1}
            fontSize={16}
            sx={{
              padding: (theme) => theme.spacing(0.5, 0.5),
              fontWeight: 500,
            }}
          >
            {option.exercise.title}
          </Typography>
        </Box>
      </ListItem>
    </Grow>
  );
};

export default ExerciseAutocompleteOption;
