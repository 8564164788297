import { Box, BoxProps, Skeleton } from "@mui/material";
import React from "react";

export interface ProgramDialogSkeletonProps extends BoxProps {}

export function ProgramDialogSkeleton(props: ProgramDialogSkeletonProps) {
  return (
    <Box display="flex" alignItems="center" gap={3} py={2} {...props}>
      <Skeleton variant="rounded" width={56} height={56} />
      <Box>
        <Skeleton width={200} height={18} />
        <Skeleton width={75} height={18} />
      </Box>
    </Box>
  );
}
