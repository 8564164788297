import React from "react";
import { CoachThemeSettingsScreen } from "../../../../components/theme-settings/CoachThemeSettingsScreen";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import WorkspacesService from "../../../../services/WorkspacesService";
import { useQuery } from "@tanstack/react-query";

export const COACH_SETTINGS_THEME_QUERY_KEY = "get-coach-settings-theme";

export function CoachSettingsThemeRoute() {
  const { data: workspace } = useQuery({
    queryKey: [COACH_SETTINGS_THEME_QUERY_KEY],
    queryFn: WorkspacesService.getCoachWorkspaceTheme,
  });
  return (
    <>
      {workspace && <CoachThemeSettingsScreen workspace={workspace} />}
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Theme",
        }}
      />
    </>
  );
}
