import ClientGoalsClientService from "../../../services/ClientGoalsClientService";
import {
  ClientGoalDto,
  IUpdateClientGoalCommand,
  PaginatedListOfClientGoalDto,
} from "@growth-machine-llc/stridist-api-client";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import { CLIENT_GOALS_LIST_QUERY_KEY } from "../../card/ClientGoalsCard";

export default function useUpdateGoalMutation(
  goalId: number,
  clientId: number,
) {
  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_GOALS_LIST_QUERY_KEY, { clientId }],
    mutationFn: (variables: IUpdateClientGoalCommand) => {
      return ClientGoalsClientService.updateClientGoal(goalId, variables);
    },
    optimisticUpdater: {
      updateFn: (
        store: PaginatedListOfClientGoalDto,
        variables: IUpdateClientGoalCommand,
      ) => {
        const goalIndex = store.items?.findIndex(
          (clientGoal) => clientGoal.id === goalId,
        );
        if (goalIndex !== undefined && goalIndex !== -1) {
          store.items[goalIndex] = ClientGoalDto.fromJS(variables);
          return store;
        }
      },
    },
  });
}
