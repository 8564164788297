import {
  IActivitiesCompositeCursorPaginatedList,
  ActivityFeedbackDto,
  ActivityDto,
  UserBriefInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { InfiniteData } from "@tanstack/react-query";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import ActivitiesService from "../../../services/ActivitiesService";
import { ACTIVITY_LIST_QUERY_KEY } from "../../activity/CoachActivity";
import { createInfinitePaginatedDataUpdater } from "../../../utils/optimisticUpdate";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import dayjs from "dayjs";
import { UserRole } from "../../../constants";

export const useCreateActivityFeedbackMutation = () => {
  const user = useCurrentUser();
  return useOptimisticUpdateMutation({
    disableToastAlerts: true,
    queryKey: [ACTIVITY_LIST_QUERY_KEY],
    mutationFn: ActivitiesService.createActivityFeedback,
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<IActivitiesCompositeCursorPaginatedList>,
        variables,
        tempId,
      ) => {
        const { activityId, content } = variables;

        return createInfinitePaginatedDataUpdater<ActivityDto, void>((items) =>
          items.map((item) => {
            if (item.id === activityId) {
              item.activityFeedbacks.push(
                new ActivityFeedbackDto({
                  id: tempId,
                  content: content,
                  created: dayjs(),
                  formattedFromNow: "a few seconds ago",
                  author: new UserBriefInfoDto({
                    id: user.id,
                    displayName: user.displayName,
                    role: user.role,
                    photoUrl: user.photoUrl,
                  }),
                }),
              );
            }
            return item;
          }),
        )(oldData);
      },
    },
  });
};
