import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { Menu, MenuProps } from "../menu/Menu";
import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

import { GroupPostsEditDialog } from "./GroupPostsEditDialog";
import { useRemoveGroupPostMutation } from "./mutations/RemoveGroupPost";
import { GroupPostDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupPostMenuProps extends MenuProps {
  post: GroupPostDto;
}

export function GroupPostMenu(props: GroupPostMenuProps) {
  const { className, post, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const { mutate: removeGroupPost } = useRemoveGroupPostMutation({
    groupId: post.groupId,
  });

  const { id, groupId } = post;

  const handleEdit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
      setOpenEditDialog(true);
    },
    [onClose],
  );

  const handleEditClose = React.useCallback(() => {
    setOpenEditDialog(false);
  }, []);

  const handleDelete = React.useCallback(() => {
    removeGroupPost(id);
  }, [groupId, id, removeGroupPost]);

  return (
    <>
      <Menu className={clsx(s.root, className)} {...other}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit post</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText>Delete post</ListItemText>
        </MenuItem>
      </Menu>
      {openEditDialog && (
        <GroupPostsEditDialog
          open
          groupId={groupId}
          post={post}
          onClose={handleEditClose}
        />
      )}
    </>
  );
}
