import React from "react";
import { Check as CheckIcon } from "@mui/icons-material";
import { MenuItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Menu, MenuProps } from "../menu/Menu";

import { ProgramDto } from "@growth-machine-llc/stridist-api-client";
import { useCoachProgramsListScreenContext } from "../../contexts/CoachProgramsListScreenContext";
import { useChangeProgramFolderMutation } from "./mutations/ChangeProgramFolder";

const useStyles = makeStyles((theme) => ({
  primaryText: {
    fontWeight: 500,
  },
  secondaryAction: {
    display: "flex",
    alignItems: "center",
  },
}));

export interface CoachProgramFoldersMenuProps extends MenuProps {
  program: ProgramDto;
  onUpdated?: (folderId: number | null) => void;
}

export const CoachProgramFoldersMenu: React.FC<CoachProgramFoldersMenuProps> = (
  props,
) => {
  const s = useStyles();
  const { program, onUpdated, onClose, ...other } = props;
  const { id: programId, folderId } = program;
  const { mutate: updateProgram } = useChangeProgramFolderMutation();

  const { programFolders } = useCoachProgramsListScreenContext();

  const handleSetProgramFolder = React.useCallback(
    (event, folderId: number | null) => {
      updateProgram(
        {
          programId,
          folderId,
        },
        {
          onSuccess: () => {
            onClose?.(event, "backdropClick");
            onUpdated(folderId);
          },
        },
      );
    },
    [updateProgram, programId, onClose, onUpdated],
  );

  return (
    <Menu
      MenuListProps={{ dense: true }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      {...other}
    >
      {[
        {
          id: null,
          name: "Ungrouped",
        },
        ...programFolders,
      ].map((programFolder) => (
        <MenuItem
          key={programFolder.id}
          onClick={(event) => {
            handleSetProgramFolder(event, programFolder.id);
          }}
          dense
        >
          <ListItemText
            primary={programFolder.name}
            classes={{ primary: s.primaryText }}
          />
          {(folderId || null) === programFolder.id && (
            <ListItemSecondaryAction className={s.secondaryAction}>
              <CheckIcon />
            </ListItemSecondaryAction>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};
