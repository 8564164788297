import clsx from "clsx";
import React, { ComponentProps } from "react";
import { IconButton, IconButtonProps, Portal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindPopper,
  bindToggle,
} from "material-ui-popup-state/hooks";

import {
  ActivityBriefDto,
  ClientFormActivityDto,
  MealLoggingActivityDto,
} from "@growth-machine-llc/stridist-api-client";

import { ReactComponent as MessageIcon } from "../../icons/Message.svg";
import { ActivityFeedbackDrawer } from "./ActivityFeedbackDrawer";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),

    "& svg": {
      color: theme.palette.primary.main,
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
  },
}));

export interface ActivityFeedbackButtonProps extends IconButtonProps {
  activity: MealLoggingActivityDto | ClientFormActivityDto | ActivityBriefDto;
  hideWhenEmpty?: boolean;
}

export function ActivityFeedbackButton(props: ActivityFeedbackButtonProps) {
  const { className, activity, hideWhenEmpty = false, ...other } = props;
  const s = useStyles();
  const toggleState = usePopupState({
    variant: "popover",
    popupId: "feedbacks",
  });

  const [searchParams] = useSearchParams();
  const feedbackDrawerOpen = searchParams.get("feedback") === "true";

  React.useEffect(() => {
    if (feedbackDrawerOpen) {
      toggleState.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    if (toggleState.isOpen) {
      newParams.set("feedback", "true");
    } else {
      newParams.delete("feedback");
    }

    const newUrl = newParams.toString()
      ? `${window.location.pathname}?${newParams.toString()}`
      : window.location.pathname;

    window.history.replaceState(null, "", newUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState.isOpen]);

  return hideWhenEmpty && !activity.feedbacksCount ? null : (
    <>
      <IconButton
        className={clsx(s.root, className)}
        {...bindToggle(toggleState)}
        {...other}
        size="large"
      >
        <MessageIcon /> {activity.feedbacksCount}
      </IconButton>

      {toggleState.isOpen && (
        <Portal>
          <ActivityFeedbackDrawer
            {...bindPopper(toggleState)}
            activity={activity}
            onClose={toggleState.close}
          />
        </Portal>
      )}
    </>
  );
}
