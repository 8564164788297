import clsx from "clsx";
import React from "react";
import { List, ListProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActivityResponseMealsListItem } from "./ActivityResponseMealsListItem";
import { MealActivityContent } from "./types";
import { ActivityDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ActivityResponseMealsListProps extends ListProps {
  activity: ActivityDto;
}

export function ActivityResponseMealsList(
  props: ActivityResponseMealsListProps,
) {
  const s = useStyles();
  const { className, activity, ...other } = props;
  const meals = JSON.parse(activity.content) as MealActivityContent[];
  const hasPhotos = meals?.some(({ photoURL }) => photoURL);

  return (
    <List className={clsx(s.root, className)} {...other}>
      {meals?.map((meal, index) => (
        <ActivityResponseMealsListItem
          key={index}
          meal={meal}
          divider={index < meals.length - 1}
          hasPhotos={hasPhotos}
        />
      ))}
    </List>
  );
}
