import {
  ICreateProgramFolderCommand,
  IUpdateProgramFolderCommand,
  ProgramFolderDto,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import CoachProgramsService from "../../../services/CoachProgramsService";
import { COACH_PROGRAM_FOLDERS_QUERY_KEY } from "../../../wrappers/router/coach/CoachProgramsListWrapper";

export const useCreateProgramFolderMutation = () => {
  return useUpsertProgramFolderMutation(
    CoachProgramsService.createProgramFolder,
    succeedUpdater,
  );
};

export const useUpdateProgramFolderMutation = () => {
  return useUpsertProgramFolderMutation(
    CoachProgramsService.updateProgramFolder,
  );
};

const useUpsertProgramFolderMutation = (mutationFn, succeedUpdater?) => {
  return useOptimisticUpdateMutation({
    mutationFn,
    queryKey: [COACH_PROGRAM_FOLDERS_QUERY_KEY],
    optimisticUpdater: {
      updateFn: optimisticUpdater,
    },
    successUpdater: {
      updateFn: succeedUpdater,
    },
  });
};

const optimisticUpdater = (
  oldData: ProgramFolderDto[],
  variables: IUpdateProgramFolderCommand | ICreateProgramFolderCommand,
  tempId: number,
) => {
  if ("id" in variables) {
    return oldData.map((folder) => {
      if (folder.id === variables.id) {
        return ProgramFolderDto.fromJS({ ...folder, ...variables });
      }
      return folder;
    });
  }
  return [ProgramFolderDto.fromJS({ ...variables, id: tempId }), ...oldData];
};

const succeedUpdater = (
  oldData: ProgramFolderDto[],
  response: number,
  tempId: number,
) => {
  return oldData.map((folder) => {
    if (folder.id === tempId) {
      return { ...folder, id: response };
    }
    return folder;
  });
};
