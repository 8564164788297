import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, Typography, Box, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getMimeTypes } from "../../utils/file";
import { useNativeDropzone } from "../../utils/device";
import { AssetType } from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ElementType } from "../editor/types/elements";
import { useUploadFile } from "../../hooks/useUploadFile";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "flex-start",
    background: theme.palette.depressed,
    borderColor: theme.palette.border.primary,
    padding: theme.spacing(2, 2.75),
  },

  avatar: {
    margin: 0,
  },

  label: {
    fontWeight: 500,
    marginLeft: theme.spacing(3),
  },

  large: {
    display: "inherit",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  small: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "inherit",
    },
  },

  pseudoLink: {
    textDecoration: "underline",
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
}));

export interface UploadAvatarProps
  extends Omit<ButtonProps, "onChange" | "onError"> {
  value: string;
  onChange: (value: string) => void;
  onError: (error: string) => void;
  onUploading?: (isUploading: boolean) => void;
}

export function UploadAvatar(props: UploadAvatarProps) {
  const {
    className,
    value,
    onChange,
    onError,
    children,
    onUploading,
    ...other
  } = props;
  const user = useCurrentUser();
  const s = useStyles();
  const [uploadFile, _, isUploading] = useUploadFile({
    id: user.id,
    refType: "User",
    getAssetType: () => AssetType.USER_PHOTO,
    onUpload: ({ url }) => {
      if (onChange) {
        onChange(url);
      }
    },
  });

  React.useEffect(() => {
    if (onUploading) {
      onUploading(isUploading);
    }
  }, [isUploading]);

  const onUpload = React.useCallback(
    (file) => {
      uploadFile(file[0]);
    },
    [uploadFile, onError, onChange, user],
  );

  const { getRootProps, getInputProps, isDragActive } = useNativeDropzone({
    onDrop: onUpload,
    accept: getMimeTypes(ElementType.IMAGE),
  });

  return children ? (
    <Box className={clsx(className)} {...(getRootProps() as any)}>
      <input type="file" name="photoURL" {...getInputProps()} />
      {children}
    </Box>
  ) : (
    <Button
      {...(getRootProps() as any)}
      className={clsx(s.root, className)}
      variant="outlined"
      fullWidth
      color="primary"
      {...other}
    >
      <input type="file" name="photoURL" {...getInputProps()} />
      <Avatar className={s.avatar} src={value} />
      <Typography className={s.label} color="textSecondary">
        {isUploading ? (
          "Uploading..."
        ) : isDragActive ? (
          "Drop the file here ..."
        ) : (
          <>
            <Box component="span" className={s.large}>
              Drag a profile photo or{" "}
              <Typography className={s.pseudoLink} component="span">
                upload
              </Typography>
            </Box>

            <Box component="span" className={s.small}>
              <Typography className={s.pseudoLink} component="span">
                Upload profile photo
              </Typography>
            </Box>
          </>
        )}
      </Typography>
    </Button>
  );
}
