import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { isPdfUrl, isValidUrl } from "../utils/validationUtil";
import { humanReadableFileSize } from "../../../utils/file";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { EmbedElementType } from "../elements/MoreMenu";
import FileUpload from "../elements/FileUpload";
import { useTranscodeVideo } from "../../../hooks/useTranscodeVideo";
import { UnsplashForm } from "../../unsplash/UnsplashForm";
import { useEditorProgram } from "../hooks";
import { useUploadFile } from "../../../hooks/useUploadFile";
import { AssetType } from "../../../constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IEmbedFileDialogProps {
  url: string;
  size: number;
  name: string;
  mimeType: string;
  open: boolean;
  type: EmbedElementType;
  title: string;
  accept: string;
  onSubmit: (
    url: string,
    name: string,
    size: number | null,
    mimeType: string,
  ) => void;
  onClose: () => void;
}

export const EmbedFileDialog = ({
  url,
  size,
  name,
  mimeType,
  open,
  type,
  title,
  accept,
  onSubmit,
  onClose,
}: IEmbedFileDialogProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [fileUrl, setFileUrl] = useState<string>(url);
  const [fileName, setFileName] = useState<string>(name);
  const [fileSize, setFileSize] = useState<number | null>(size);
  const [fileMimeType, setFileMimeType] = useState<string>(mimeType);
  const [progress, setProgress] = useState<number>(0);
  const [progressType, setProgressType] = useState<
    "indeterminate" | "determinate"
  >("determinate");

  const [transcodeVideo] = useTranscodeVideo();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };
  const user = useCurrentUser();

  const editorProgram = useEditorProgram();
  const programId = editorProgram?.programId;

  const [fileUploadButtonTitle, setFileUploadButtonTitle] =
    useState<string>(title);

  const handleUrlSubmit = useCallback(
    (event) => {
      event && event.stopPropagation();
      event && event.preventDefault();
      onSubmit && onSubmit(fileUrl, fileName, fileSize, fileMimeType);
      onClose && onClose();
    },
    [onSubmit, onClose, fileUrl, fileName, fileSize, fileMimeType],
  );

  const handleFileSubmit = useCallback(
    (url: string, name: string, size: number | null, type: string) => {
      onSubmit && onSubmit(url, name, size, type);
      onClose && onClose();
    },
    [onSubmit, onClose, fileUrl, fileName, fileSize, fileMimeType],
  );

  const [uploadFile, , isFileUploading] = useUploadFile({
    id: programId || user.id,
    refType: programId ? "Program" : "User",
    getAssetType: () => AssetType.PROGRAM_FILE,
    onProgress: (progress) => {
      setProgress(Math.round((progress.loaded / progress.total) * 100));
    },
  });

  useEffect(() => {
    setFileUploadButtonTitle(isFileUploading ? "Uploading..." : null);
  }, [isFileUploading]);

  const handleFileChange = (event) => {
    setFileUrl(null);
    setFileName(null);
    setFileSize(null);
    setFileMimeType(null);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      setProgress(0);
      reader.onloadend = () => {
        uploadFile(file)
          .then(({ url }) => {
            if (type === EmbedElementType.VIDEO) {
              setProgressType("indeterminate");
              setFileUploadButtonTitle("Processing...");
              return transcodeVideo(url);
            }

            return url;
          })
          .then((url) => {
            handleFileSubmit(url, file.name, file.size, file.type);
          });

        setFileName(file.name);
        setFileSize(file.size);
        setFileMimeType(file.type);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUnsplashSelect = React.useCallback(
    (url: string) => {
      onSubmit && onSubmit(url, null, null, null);
      onClose && onClose();
    },
    [onSubmit, onClose, fileUrl, fileName, fileSize, fileMimeType],
  );

  const embed = (
    <React.Fragment>
      <TextField
        variant="outlined"
        label="URL"
        value={fileUrl}
        helperText={
          fileUrl && !isValidUrl(fileUrl) && !isPdfUrl(fileUrl)
            ? "Paste valid link"
            : "Paste any link to embed it into your component."
        }
        onChange={(e) => {
          const url = e.target.value;
          setFileUrl(url);
          setFileName(nameFromUrl(e.target.value));
          setFileSize(null);
          setFileMimeType(null);
        }}
        autoFocus
        fullWidth
        error={fileUrl && !isValidUrl(fileUrl) && !isPdfUrl(fileUrl)}
      />
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        disabled={!fileUrl || (!isValidUrl(fileUrl) && !isPdfUrl(fileUrl))}
        onClick={(e) => {
          handleUrlSubmit(e);
          onClose();
        }}
        fullWidth
      >
        Embed
      </Button>
    </React.Fragment>
  );
  return (
    <Dialog
      onClose={onClose}
      open={open}
      disableRestoreFocus
      sx={{
        ".MuiPaper-root": {
          minWidth: type === EmbedElementType.IMAGE ? 600 : 480, // TODO_Editor make responsive
          padding: 3,
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
        },
      }}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          handleUrlSubmit(e);
        }
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Upload" {...a11yProps(0)} />
            <Tab label="Embed" {...a11yProps(1)} />
            {type === EmbedElementType.IMAGE && (
              <Tab label="Unsplash" {...a11yProps(2)} />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={selectedTabIndex} index={0}>
          <Box display={"flex"} flexDirection={"column"}>
            {fileName && (
              <Typography
                color={(theme) => theme.palette.text.secondary}
                variant="caption"
              >
                {isFileUploading ? "File" : "Uploaded file:"} {fileName}
              </Typography>
            )}
            {fileSize && (
              <Typography
                color={(theme) => theme.palette.text.secondary}
                variant="caption"
              >
                File size: {humanReadableFileSize(fileSize)}
              </Typography>
            )}
            <Typography
              color={(theme) => theme.palette.text.secondary}
              variant="caption"
            >
              Accepted files: {accept}
            </Typography>
          </Box>
          <FileUpload
            accept={accept}
            handleFileChange={handleFileChange}
            previewUrl={url}
            buttonTitle={fileUploadButtonTitle}
            disabled={isFileUploading}
          ></FileUpload>
          {isFileUploading && progress > 0 && (
            <LinearProgress
              variant={progressType}
              value={progress}
              sx={{ mt: 1 }}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTabIndex} index={1}>
          {embed}
        </CustomTabPanel>
        {type === EmbedElementType.IMAGE && (
          <CustomTabPanel value={selectedTabIndex} index={2}>
            <UnsplashForm onSelect={handleUnsplashSelect} />
          </CustomTabPanel>
        )}
      </Box>
    </Dialog>
  );
};

export function nameFromUrl(url: string) {
  return url.replace(/\?.*/, "").replace(/^.*\//, "");
}
