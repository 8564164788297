import React from "react";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { colorSystem } from "../../theme";
import { useAnalytics } from "../../hooks/useAnalytics";
import { AppError, SOMETHING_WENT_WRONG } from "../../constants";
import { useCreateBillingAddressMutation } from "../settings/mutations/UpsertBillingAddress";
import { useSignInMutation } from "./mutations/SignIn";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useCompleteCoachRegistrationMutation } from "./mutations/RegisterCoachWithFreeLaunch";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  card: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginTop: theme.spacing(3.5),
  },
  input: {
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.border2,
    padding: theme.spacing(2.17, 1.9),
    fontSize: 16,
    fontWeight: 500,
    backgroundColor: "white",
    fontFamily: "Montserrat, sans-serif",
    marginTop: theme.spacing(3),
  },
  flexInput: {
    display: "flex",
  },
  separator: {
    margin: theme.spacing(0, 0.5),
  },
}));

const cardStyle = {
  placeholder: "Card Number",
  style: {
    base: {
      color: colorSystem.black,
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "500",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: colorSystem.gray,
        fontSize: "16px",
        fontWeight: "500",
      },
    },
    invalid: {
      fontFamily: "Montserrat, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
      fontSize: "16px",
      fontWeight: "500",
    },
  },
};

const SignUpCardDetails = ({
  setSavingCard,
  onSaveCard,
  clientSecret,
  plan,
  email,
  fullName,
  password,
  addressLine1,
  country,
  city,
  postalCode,
  setLoading,
  closePreloader,
}: any) => {
  const navigate = useNavigate();
  const s = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const { mutate: completeRegisterCoach } =
    useCompleteCoachRegistrationMutation();
  const { mutate: signIn } = useSignInMutation({
    onSuccess: () => {
      navigate(COACH_PROGRAMS_PUBLISHED_ROUTE + "?photoPrompt=true");
    },
  });
  const [trackEvent] = useAnalytics();
  const { showToastAlert } = useToastAlert();

  const { mutate: upsertBillingAddress } = useCreateBillingAddressMutation();

  const handleUpdate = React.useCallback(() => {
    upsertBillingAddress(
      {
        street: addressLine1,
        city,
        postalCode,
        country,
        password,
      },
      {
        onSuccess() {
          setLoading(false);
        },
        onSettled: () => {
          closePreloader();
          setLoading(false);
        },
      },
    );
  }, [
    upsertBillingAddress,
    addressLine1,
    city,
    postalCode,
    country,
    setLoading,
    password,
  ]);

  const handleSaveCard = React.useCallback(async () => {
    try {
      setLoading(true);
      setSavingCard(false);
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });
      if (result.error) {
        showToastAlert("error", {
          message: result.error.message,
        });
        setLoading(false);
        closePreloader();
      } else {
        showToastAlert("success", {
          message: "Card details saved",
        });
        completeRegisterCoach(
          {
            email,
            displayName: fullName,
            password,
            timezone: dayjs.tz.guess(),
            plan,
          },
          {
            // TODO_API_V2: Consider about separately handling stripe errors
            onError: () => {
              closePreloader();
              setLoading(false);
            },
            onSuccess: () => {
              firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((x) => x.user.getIdToken())
                .then((idToken) => {
                  // Using user's ID token, authenticate in the API
                  signIn(
                    { idToken },
                    {
                      onSuccess: () => {
                        trackEvent("SignUp");
                        handleUpdate();
                      },
                      // This onError is executed within a global error handler
                      onError: () => {
                        closePreloader();
                        setLoading(false);
                      },
                    },
                  );
                })
                .catch();
            },
          },
        );
        setSavingCard(false);
      }
    } catch (e) {
      setSavingCard(false);
      setLoading(false);
      closePreloader();
      console.error(e);
      showToastAlert("error", {
        message: SOMETHING_WENT_WRONG,
      });
    }
  }, [
    email,
    password,
    plan,
    fullName,
    handleUpdate,
    clientSecret,
    elements,
    showToastAlert,
    stripe,
    setSavingCard,
    trackEvent,
    signIn,
    completeRegisterCoach,
    setLoading,
    closePreloader,
  ]);

  React.useEffect(() => {
    if (onSaveCard) {
      handleSaveCard();
    }
  }, [onSaveCard, handleSaveCard]);

  return (
    <>
      <Typography className={s.card} variant="h1">
        Card details
      </Typography>
      <Box className={s.input}>
        <CardNumberElement options={cardStyle} />
      </Box>
      <Box className={s.flexInput}>
        <Box className={s.input}>
          <CardExpiryElement
            options={{ ...cardStyle, placeholder: "MM / YY" }}
          />
        </Box>
        <div className={s.separator} />
        <Box className={s.input}>
          <CardCvcElement options={{ ...cardStyle, placeholder: "CVV" }} />
        </Box>
      </Box>
    </>
  );
};

export default SignUpCardDetails;
