import clsx from "clsx";
import React from "react";
import { Box, BoxProps, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { ReactComponent as BackIcon } from "../../icons/ArrowBack.svg";
import { polyfillCSS } from "../../utils/css";

import { useUserIsClient } from "../../hooks/useCurrentUser";

import { ThreadHeader } from "./ThreadHeader";
import { MessagesList } from "./MessagesList";
import { NewMessageForm } from "./NewMessageForm";
import { useNavigate } from "react-router-dom";
import { COACH_MESSAGES_ROUTE, MESSAGES_ROUTE } from "../../routes/routes";
import { MessageDto, ThreadDto } from "@growth-machine-llc/stridist-api-client";
import useUpdateThreadMutation from "./mutations/useUpdateThreadMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("lg")]: {
      "& > div": {
        paddingLeft: polyfillCSS(
          `max(var(--safe-area-inset-left), ${theme.spacing(2)})`,
        ),
        paddingRight: polyfillCSS(
          `max(var(--safe-area-inset-right), ${theme.spacing(2)})`,
        ),
      },
    },
  },

  header: {
    display: "flex",

    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.quote,

    padding: theme.spacing(1, 2, 1, 1),
    paddingTop: polyfillCSS(
      `max(${theme.spacing(1)}, var(--safe-area-inset-top))`,
    ),

    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.paper,

      padding: theme.spacing(2, 2, 2, 1),
      paddingTop: polyfillCSS(
        `max(${theme.spacing(2)}, var(--safe-area-inset-top))`,
      ),

      "& button": {
        display: "none",
      },
    },
  },

  messages: {
    height: "100%",
    overflowY: "scroll",
    paddingRight: theme.spacing(2),
  },

  form: {
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: colorSystem.gray7,
    padding: theme.spacing(3, 2, 2, 2),

    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(2),
      borderTop: "none",
    },
  },

  withDialUp: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(8.5),
    },
  },

  threadHeader: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
}));

export interface MessagesProps extends BoxProps {
  thread?: ThreadDto;
}

export function Messages(props: MessagesProps) {
  const { className, thread, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const isClient = useUserIsClient();
  const withDialUp = !isClient;
  const { mutate: updateThread } = useUpdateThreadMutation(thread.slug);

  const handleBackClick = React.useCallback(() => {
    navigate(isClient ? MESSAGES_ROUTE : COACH_MESSAGES_ROUTE);
  }, []);

  React.useEffect(() => {
    if (thread && !thread.read) {
      updateThread({
        threadId: thread.id,
        read: true,
      });
    }
  }, [thread.id, thread.read, updateThread]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {thread && (
        <Box className={s.header}>
          <IconButton color="secondary" onClick={handleBackClick} size="large">
            <BackIcon />
          </IconButton>
          <ThreadHeader className={s.threadHeader} thread={thread} />
        </Box>
      )}

      <Box className={s.messages}>
        {thread && <MessagesList messages={thread.messages} />}
      </Box>

      {thread && (
        <NewMessageForm
          className={clsx(s.form, withDialUp && s.withDialUp)}
          threadId={thread.id}
          recipient={thread.slug}
        />
      )}
    </Box>
  );
}
