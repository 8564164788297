import clsx from "clsx";
import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  ContainerProps,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMediaDnDSupported } from "../../hooks/useMediaDnDSupported";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { usePreview } from "../../hooks/usePreview";
import { useLocation, useSearchParams } from "react-router-dom";
import { ShowCoversContext } from "../../hooks/useShowCovers";
import { EnableColorsContext } from "../../hooks/useEnableColors";
import { ProgramDetails } from "../program/ProgramDetails";
import {
  DEFAULT_PROGRAM_DETAILS_VIEW_MODE,
  ProgramDetailsViewButton,
  ProgramDetailsViewMode,
  defaultCalendarViewOptions,
} from "../program/ProgramDetailsViewButton";
import {
  ProgramDetailsFilters,
  defaultFilters,
  Filters,
} from "../program/ProgramDetailsFilters";
import {
  ProgramStartDateViewButton,
  ProgramStartDateViewButtonProps,
} from "../program/ProgramStartDateViewButton";
import { EditorProgramContext } from "../new-editor/hooks";
import {
  ProgramStartDateView,
  ProgramStartDateViewDetails,
} from "../program/ProgramStartDateViewButton";
import { ToggleCoversButton } from "../button/ToggleCoversButton";
import { ToggleColorsButton } from "../button/ToggleColorsButton";
import { toEnum } from "../../utils/misc";
import { isMobileApp } from "../../utils/mobile";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { LockOpen, Lock } from "lucide-react";
import { LoadingButton } from "@mui/lab";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";
import {
  ProgramWeekRangeDetails,
  ProgramWeekRangeSelector,
  ProgramWeekRangeSelectorProps,
} from "../program/ProgramWeekRangeSelector";
import {
  INITIAL_WEEK_DETAILS,
  getCurriculumViewStorageKey,
  getLimitedWeekMaxNumber,
} from "../../routes/coach/program/curriculum/CoachProgramCurriculumRoute";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import {
  addComponent,
  addWeek,
  deleteWeek,
  duplicateWeek,
  lockAllComponents,
  moveWeek,
} from "../../redux/curriculum/curriculum-slice";
import { WeekMoveDirectionType } from "../../constants";
import {
  TEMPLATE_WORKOUT_TITLE,
  useGenerateTemplateWorkoutSection,
} from "../workout/utils";
import { ComponentRepeat, ComponentType } from "../../constants";
import { DEFAULT_COMPONENT_DAYS } from "../../utils/component";
import { NormalizedCurriculumProgram } from "../../redux/types";
import {
  isProgramEmpty,
  selectFirstWeek,
} from "../../redux/curriculum/selectors/curriculum";
import { ComponentStatus } from "@growth-machine-llc/stridist-api-client";
import { useHistoryBlock } from "../history-block/hooks";
import { selectGeneralLoading } from "../../redux/api/selectors";
import { isEqual } from "lodash";
import { ProgramViewContext } from "../../hooks/useProgramView";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    maxWidth: 1500,
  },

  calendar: {},
  spreadsheet: {
    maxWidth: "unset",
  },
  list: {
    maxWidth: 1116,
  },

  filters: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginLeft: "unset",
    flexDirection: "column",
    gap: theme.spacing(4),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },

  filtersRight: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
}));

export interface CurriculumViewOptions {
  view: ProgramDetailsViewMode;
  dateDetails: ProgramStartDateViewDetails;
  weekDetails: ProgramWeekRangeDetails;
  showCovers: boolean;
  enableColors: boolean;
}

export interface CurriculumEditScreenProps
  extends Omit<ContainerProps, "children"> {
  program: NormalizedCurriculumProgram;
}

export function CurriculumEditScreen({ program }: CurriculumEditScreenProps) {
  const dispatch = useCurriculumDispatch();
  const isEmpty = useCurriculumSelector(isProgramEmpty);
  const firstWeek = useCurriculumSelector(selectFirstWeek);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const s = useStyles();
  const [searchParams] = useSearchParams();
  const component = searchParams.get("component");
  const location = useLocation();
  const [preview] = usePreview();
  const [filters, setFilters] = React.useState(defaultFilters);
  const canUseCalendar = useMediaDnDSupported() && !isSm;
  const { defaultView } = program;
  // TODO_API_V2_CURRICULUM handle loading
  const [loading, _] = React.useState(false);
  const templateWorkoutSection = useGenerateTemplateWorkoutSection();
  const [unsafe, setDirty, dirty] = useHistoryBlock();
  const apiLoading = useCurriculumSelector(selectGeneralLoading);
  useEffect(() => setDirty(apiLoading), [apiLoading]);

  const CURRICULUM_VIEW_STORAGE_KEY = getCurriculumViewStorageKey(
    program.slug,
    program.id.toString(),
  );

  const INITIAL_VIEW_OPTIONS: CurriculumViewOptions = React.useMemo(
    () => ({
      view: toEnum(
        defaultView,
        ProgramDetailsViewMode,
        ProgramDetailsViewMode.CALENDAR,
      ),
      dateDetails: {
        view: ProgramStartDateView.ANY,
      },
      weekDetails: INITIAL_WEEK_DETAILS,
      showCovers: false,
      enableColors: true,
    }),
    [defaultView],
  );

  // LOCAL STORAGE
  const [savedViewOptions, setSavedViewOptions] =
    useLocalStorage<CurriculumViewOptions>(
      CURRICULUM_VIEW_STORAGE_KEY,
      INITIAL_VIEW_OPTIONS,
    );

  // CURRICULUM MAIN VIEW STATE
  const [curriculumView, setCurriculumView] = useState<CurriculumViewOptions>({
    ...INITIAL_VIEW_OPTIONS,
    ...savedViewOptions,
  });

  const VIEW_OPTIONS = curriculumView;

  const VIEW_MODE: ProgramDetailsViewMode =
    !canUseCalendar && VIEW_OPTIONS?.view === ProgramDetailsViewMode.CALENDAR
      ? DEFAULT_PROGRAM_DETAILS_VIEW_MODE
      : VIEW_OPTIONS.view;

  const WEEKS_PAGINATION = {
    start: VIEW_OPTIONS.weekDetails.startNumber,
    end: VIEW_OPTIONS.weekDetails.endNumber,
  };

  const START_DATE_VIEW_DETAILS: ProgramStartDateViewDetails = canUseCalendar
    ? VIEW_OPTIONS.dateDetails
    : { view: ProgramStartDateView.ANY };

  // WEEK PAGINATION
  const handleWeekRangeLoad = (
    weeksStartNumber: number,
    weeksEndNumber: number,
  ) => {
    const sanitizedStartNumber = Math.min(
      Math.max(1, Math.min(weeksStartNumber, weeksEndNumber)),
      program.length,
    );
    const sanitizedEndNumber = getLimitedWeekMaxNumber(
      sanitizedStartNumber,
      Math.min(Math.max(weeksStartNumber, weeksEndNumber), program.length),
    );
    setCurriculumView({
      ...VIEW_OPTIONS,
      weekDetails: {
        startNumber: sanitizedStartNumber,
        endNumber: sanitizedEndNumber,
      },
    });
  };

  // Load prev
  const handlePrevClick = () => {
    const pageSize =
      Math.abs(
        VIEW_OPTIONS.weekDetails.endNumber -
          VIEW_OPTIONS.weekDetails.startNumber,
      ) + 1;
    const newWeeksStart = VIEW_OPTIONS.weekDetails.startNumber - pageSize;
    const newWeeksEnd = VIEW_OPTIONS.weekDetails.endNumber - pageSize;

    handleWeekRangeLoad(newWeeksStart, newWeeksEnd);
  };

  // Load next
  const handleNextClick = () => {
    const pageSize =
      Math.abs(
        VIEW_OPTIONS.weekDetails.endNumber -
          VIEW_OPTIONS.weekDetails.startNumber,
      ) + 1;
    const newWeeksStart = VIEW_OPTIONS.weekDetails.startNumber + pageSize;
    const newWeeksEnd = VIEW_OPTIONS.weekDetails.endNumber + pageSize;

    handleWeekRangeLoad(newWeeksStart, newWeeksEnd);
  };

  // WEEK CONTROLS
  // Week filter
  const handleFiltersChange = React.useCallback((filters: Filters) => {
    setFilters(filters);
  }, []);

  // View mode
  const handleViewModeChange = (_, view) => {
    setCurriculumView({ ...VIEW_OPTIONS, view });
  };

  // Start date
  const handleStartDateViewDetailsChange: ProgramStartDateViewButtonProps["onChange"] =
    (dateDetails) => {
      setCurriculumView({ ...VIEW_OPTIONS, dateDetails });
    };

  // Weeks range
  const handleWeeksDateRangeChange: ProgramWeekRangeSelectorProps["onChange"] =
    (weekDetails) => {
      handleWeekRangeLoad(weekDetails.startNumber, weekDetails.endNumber);
    };

  // Show covers
  const handleShowCoversToggle = (value: boolean) => {
    setCurriculumView({
      ...VIEW_OPTIONS,
      showCovers: !VIEW_OPTIONS.showCovers,
    });
  };

  // Enable colors
  const handleEnableColorsToggle = (value: boolean) => {
    setCurriculumView({
      ...VIEW_OPTIONS,
      enableColors: !VIEW_OPTIONS.enableColors,
    });
  };

  // WEEK ACTIONS
  const handleAddWeek = () => {
    dispatch(addWeek());
  };

  const handleDeleteWeek = (event, setMoreMenuEl) => {
    const { id } = event.currentTarget.dataset;
    dispatch(deleteWeek(Number(id)));
    setMoreMenuEl(null);
  };

  const handleDuplicateWeek = (event) => {
    const { id } = event.currentTarget.dataset;
    dispatch(duplicateWeek(Number(id)));
  };

  const handleMove = (event, closeMoreMenu) => {
    const { id, direction: _direction } = event.currentTarget.dataset;
    const direction: WeekMoveDirectionType = _direction.toUpperCase();
    const input = {
      id: Number(id),
      direction,
    };
    dispatch(moveWeek(input));
    closeMoreMenu();
  };

  // LOCK ALL
  const handleLockAll = React.useCallback(
    (mode: boolean) => {
      dispatch(lockAllComponents({ programId: program.id, locked: mode }));
    },
    [program.weeks],
  );

  // EMPTY SPREADSHEET INIT TEMPLATE
  useEffect(() => {
    if (VIEW_MODE === ProgramDetailsViewMode.SPREADSHEET && isEmpty) {
      const input = {
        type: ComponentType.WORKOUT,
        title: TEMPLATE_WORKOUT_TITLE,
        status: ComponentStatus.DRAFT,
        days: DEFAULT_COMPONENT_DAYS,
        repeat: ComponentRepeat.NONE,
        duration: 1,
        content: JSON.stringify([templateWorkoutSection]),
      };

      dispatch(
        addComponent({
          weekId: firstWeek.id,
          componentType: ComponentType.WORKOUT,
          initialComponentData: input,
        }),
      );
    }
  }, [VIEW_MODE, program]);

  // SYNC LOCAL STORAGE
  useEffect(() => {
    if (!isEqual(savedViewOptions, VIEW_OPTIONS)) {
      setSavedViewOptions(VIEW_OPTIONS);
    }
  }, [curriculumView]);

  return (
    <>
      <LinearProgress
        sx={{
          marginInline: "auto",
          mt: -0.25,
          maxWidth: 1180,
          height: "2px",
          opacity: apiLoading ? 1 : 0,
          transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.complex,
            easing: theme.transitions.easing.easeOut,
          }),
        }}
      />
      {preview && !component ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: theme.spacing(PREVIEW_BAR_SPACING),
            height: `calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`,
            width: "100vw",
          }}
        >
          <PreviewBar />
          <PreviewBox src={`${location.pathname}/preview?week=1`} />
        </Box>
      ) : (
        <Container
          className={clsx(s.root, {
            [s.calendar]: VIEW_MODE === ProgramDetailsViewMode.CALENDAR,
            [s.list]: VIEW_MODE === ProgramDetailsViewMode.LIST,
            [s.spreadsheet]: VIEW_MODE === ProgramDetailsViewMode.SPREADSHEET,
          })}
        >
          <Box className={clsx(s.filters)}>
            <ProgramDetailsViewButton
              value={VIEW_MODE}
              onChange={handleViewModeChange}
              options={{
                ...defaultCalendarViewOptions,
                [ProgramDetailsViewMode.CALENDAR]: {
                  ...defaultCalendarViewOptions.calendar,
                  disabled: !canUseCalendar,
                },
              }}
              disabled={loading}
            />
            <Box className={s.filtersRight}>
              <ProgramWeekRangeSelector
                value={VIEW_OPTIONS.weekDetails}
                onChange={handleWeeksDateRangeChange}
                programLength={program.length}
                disabled={loading || program.length === 0}
              />

              <ProgramDetailsFilters
                filters={filters}
                onChange={handleFiltersChange}
                disabled={loading}
              />

              <ProgramStartDateViewButton
                value={START_DATE_VIEW_DETAILS}
                onChange={handleStartDateViewDetailsChange}
                fullWidth={isSm}
                slug={program.slug}
              />

              {VIEW_MODE === ProgramDetailsViewMode.LIST && !isMobileApp && (
                <ToggleCoversButton
                  toggleFlag={VIEW_OPTIONS.showCovers}
                  onToggle={handleShowCoversToggle}
                  fullWidth={isSm}
                />
              )}

              {[
                ProgramDetailsViewMode.CALENDAR,
                ProgramDetailsViewMode.SPREADSHEET,
              ].includes(VIEW_MODE) && (
                <ToggleColorsButton
                  toggleFlag={VIEW_OPTIONS.enableColors}
                  onToggle={handleEnableColorsToggle}
                  fullWidth={isSm}
                />
              )}

              <Box sx={{ marginLeft: "auto" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <MinimizedTooltip
                    placement="bottom"
                    title={"Unlock all content"}
                    delayNextEnter={false}
                  >
                    <Button
                      onClick={() => handleLockAll(false)}
                      sx={{ gap: 0.5 }}
                    >
                      <LockOpen />
                    </Button>
                  </MinimizedTooltip>
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                  >
                    All content
                  </Typography>
                  <MinimizedTooltip
                    placement="bottom"
                    title={"Lock all content"}
                    delayNextEnter={false}
                  >
                    <Button
                      onClick={() => handleLockAll(true)}
                      sx={{ gap: 0.5 }}
                    >
                      <Lock />
                    </Button>
                  </MinimizedTooltip>
                </Box>
              </Box>
            </Box>
          </Box>

          <EditorProgramContext.Provider value={{ programId: program.id }}>
            <ShowCoversContext.Provider value={VIEW_OPTIONS.showCovers}>
              <EnableColorsContext.Provider value={VIEW_OPTIONS.enableColors}>
                <ProgramViewContext.Provider value={VIEW_OPTIONS.view}>
                  <ProgramDetails
                    program={program}
                    viewMode={VIEW_MODE}
                    filters={filters}
                    weeksFilter={WEEKS_PAGINATION}
                    startDate={START_DATE_VIEW_DETAILS.date}
                    length={program.length}
                    weeks={program.weeks}
                    id={program.id}
                    slug={program.slug}
                    handleAddWeek={handleAddWeek}
                    handleDeleteWeek={handleDeleteWeek}
                    handleDuplicateWeek={handleDuplicateWeek}
                    handleMove={handleMove}
                    // TODO_API_V2_CURRICULUM handle loading
                    // addWeekInFlight={addWeekInFlight}
                    // dublicateWeekInFlight={dublicateWeekInFlight}
                    handlePrevClick={handlePrevClick}
                    handleNextClick={handleNextClick}
                    loading={loading}
                  />
                </ProgramViewContext.Provider>
              </EnableColorsContext.Provider>
            </ShowCoversContext.Provider>
          </EditorProgramContext.Provider>
        </Container>
      )}
    </>
  );
}
