import React, { useMemo, useEffect } from "react";
import {
  Grid,
  ContainerProps,
  TextField,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ClientExerciseChoose from "./ClientExerciseChoose";
import ClientExercisesItem from "./ClientExercisesItem";
import { useDebounce } from "../../hooks/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import WorkoutResultsService from "../../services/WorkoutResultsService";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { CardPagination } from "../pagination/CardPagination";
import ClientExercisesSkeleton from "../loading/ClientExercisesSkeleton";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  search: {
    maxWidth: 550,
    marginBottom: theme.spacing(3),
    "& fieldset": {
      borderRadius: 0,
      borderColor: theme.palette.border.primary,
      borderWidth: 1,
      borderStyle: "solid",
    },
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    width: "100%",
    textAlign: "center",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

export const CLIENT_EXERCISES_LIST_QUERY_KEY = "client-exercises-list";
const CLIENT_EXERCISES_PAGE_SIZE_SM_UP = 12;
const CLIENT_EXERCISES_PAGE_SIZE_SM = 6;

export interface ClientExercisesProps extends Omit<ContainerProps, "children"> {
  pageSize?: number;
  selectExercise: any;
  handleCloseExercise: any;
  chooseExerciseItem: any;
  clientId: any;
  setLastRecorded: any;
  lastRecorded: any;
  query: string;
  setQuery: (text) => void;
}

function ClientExercises(props: ClientExercisesProps) {
  const {
    selectExercise,
    handleCloseExercise,
    chooseExerciseItem,
    clientId,
    setLastRecorded,
    lastRecorded,
    query,
    setQuery,
  } = props;

  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const pageSize = smUp ? CLIENT_EXERCISES_PAGE_SIZE_SM_UP : CLIENT_EXERCISES_PAGE_SIZE_SM;

  const handleQueryChange = React.useCallback(
    (query: string) => {
      setQuery(query);
    },
    [setQuery],
  );

  const delayedQuery = useDebounce(query, 500);

  useEffect(() => {
    setCurrentPage(1);
  }, [delayedQuery]);

  const [currentPage, setCurrentPage] = React.useState(1);

  const {
    data,
    isFetching,
    isPlaceholderData: isPrevPageData,
    isLoading: isFirstPageFirstFetch,
  } = useQuery({
    // keep old data to display pagination
    placeholderData: keepPreviousData,
    queryKey: [
      CLIENT_EXERCISES_LIST_QUERY_KEY,
      { clientId, query: delayedQuery, currentPage },
    ],
    queryFn: () =>
      WorkoutResultsService.getUserExercises(
        currentPage,
        pageSize,
        clientId,
        delayedQuery,
      ),
  });

  const userExercises = useMemo(
    () =>
      data?.items.map(({ completedAt, ...dto }) => ({
        ...dto,
        completedAt: completedAt.format("DD-MM-YYYY"),
      })) ?? [],
    [data],
  );

  React.useEffect(() => {
    if (!lastRecorded) {
      if (userExercises.length) setLastRecorded(data[0]?.completedAt);
    }
  }, [userExercises, lastRecorded, setLastRecorded]);

  const s = useStyles();

  const showCards = userExercises?.length && !isPrevPageData;
  const showSkeleton = isFirstPageFirstFetch || (isFetching && isPrevPageData);

  return (
    <Grid container spacing={2} className={s.container}>
      {selectExercise ? (
        <ClientExerciseChoose
          onClose={handleCloseExercise}
          selectExercise={selectExercise}
          id={clientId}
        />
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              className={s.search}
              InputProps={{
                className: s.searchInput,
                endAdornment: <SearchIcon className={s.icon} />,
              }}
              value={query}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleQueryChange(event.currentTarget.value);
              }}
              placeholder="Search"
              variant="outlined"
              fullWidth
            />
          </Grid>
          {showCards ? (
            userExercises.map((node, index) => (
              <ClientExercisesItem
                key={index}
                name={node.title || node.exercise.name}
                onClick={() =>
                  chooseExerciseItem({
                    name: node.title || node.exercise.name,
                  })
                }
              />
            ))
          ) : showSkeleton ? (
            Array.from({ length: pageSize }).map((_, i) => (
              <ClientExercisesSkeleton key={i} index={i} />
            ))
          ) : (
            <Typography className={s.noText}>No exercises to show.</Typography>
          )}
          {data?.totalPages > 1 && (
            <Box className={s.pagination}>
              <CardPagination
                page={currentPage}
                count={data?.totalPages}
                onChange={(_, page) => setCurrentPage(page)}
              />
            </Box>
          )}
        </>
      )}
    </Grid>
  );
}

export default React.memo(ClientExercises);
