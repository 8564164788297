import clsx from "clsx";
import React from "react";
import { ListItem } from "@mui/material";
import { ActivityCard, ActivityCardProps } from "./ActivityCard";

export interface ActivityListItemProps
  extends Pick<
    ActivityCardProps,
    "activity" | "completionProgress" | "variant"
  > {
  userName?: string;
  clientsScreen?: boolean;
  idProgram?: string;
  className?: string;
  preview?: boolean;
}

export function ActivityListItem(props: ActivityListItemProps) {
  const {
    className,
    variant,
    userName,
    clientsScreen,
    idProgram,
    preview,
    ...other
  } = props;
  return (
    <ListItem className={className}>
      <ActivityCard
        {...other}
        variant={variant}
        username={userName}
        clientsScreen={clientsScreen}
        idProgram={idProgram}
      />
    </ListItem>
  );
}
