import React from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { RowType } from "../../../constants";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Video, Pencil } from "lucide-react";
import { ExerciseDrawerData, getDefaultExerciseCellTitle } from "../utils";
import makeStyles from "@mui/styles/makeStyles";
import { useSpreadsheetInfoDrawer } from "../../../hooks/useSpreadsheetInfoDrawer";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover $icon": {
      stroke: theme.palette.common.black,
    },
  },
  icon: {
    transition: theme.transitions.create(["color, fill"]),
  },
}));

const ExerciseCellRenderer = ({
  value,
  data,
  exerciseDrawerData,
  setExerciseDrawerData,
  handleClickOpenDialog,
}: CustomCellRendererProps & {
  exerciseDrawerData: ExerciseDrawerData;
  setExerciseDrawerData: React.Dispatch<
    React.SetStateAction<ExerciseDrawerData>
  >;
  handleClickOpenDialog: (event?: any, slug?: string) => void;
}) => {
  const s = useStyles();
  const theme = useTheme();

  const [_, setInfoDrawerColumnField] = useSpreadsheetInfoDrawer();

  const isCurrentExerciseOpenedInDrawer =
    data.exerciseData?.id &&
    data.exerciseData?.id === exerciseDrawerData?.exercise?.id;

  const handleIconClick = (e) => {
    e.stopPropagation();

    setInfoDrawerColumnField(undefined);
    if (isCurrentExerciseOpenedInDrawer) {
      setExerciseDrawerData(undefined);
      return;
    }

    setExerciseDrawerData({
      componentId: data.componentId,
      componentContent: data.componentContent,
      workoutSection: data.workoutSectionData,
      exercise: data.exerciseData,
    });
  };

  const handleCalendarComponentClick = (slug?: string) => {
    setInfoDrawerColumnField(undefined);
    handleClickOpenDialog(undefined, slug);
  };

  const placeholderValue =
    value === getDefaultExerciseCellTitle(RowType.EXERCISE) ||
    value === getDefaultExerciseCellTitle(RowType.WORKOUT_SECTION) ||
    value === getDefaultExerciseCellTitle(RowType.WORKOUT)
      ? value.replace("Untitled", "Enter") + " title..."
      : value;

  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {placeholderValue}
      </Box>
      {data.rowType === RowType.EXERCISE &&
        (!data.exerciseData?.video || isCurrentExerciseOpenedInDrawer) && (
          <Box sx={{ marginLeft: 2, marginRight: -1 }}>
            <IconButton
              size="small"
              color="inherit"
              onClick={handleIconClick}
              sx={{ marginBottom: 0.5 }}
              className={s.button}
            >
              <Video
                size={22}
                strokeWidth={1.2}
                color={
                  isCurrentExerciseOpenedInDrawer
                    ? theme.palette.common.black
                    : isCurrentExerciseOpenedInDrawer
                      ? "transparent"
                      : theme.palette.divider
                }
                className={s.icon}
              />
            </IconButton>
          </Box>
        )}
      {![RowType.EXERCISE, RowType.WORKOUT_SECTION].includes(data.rowType) && (
        <Box sx={{ marginLeft: 2, marginRight: -1.4 }}>
          <IconButton
            onClick={() => handleCalendarComponentClick(data.slug)}
            sx={{ marginBottom: 0.5 }}
          >
            <Pencil
              size={20}
              strokeWidth={1.2}
              color={theme.palette.common.black}
            />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ExerciseCellRenderer;
