import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { getTrialEndsText } from "../../utils/text";

export const UPGRADE_BANNER_SPACING = 6.5;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#root": {
      marginTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    "#app-drawer-minimize-icon-button": {
      marginTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    ".MuiDrawer-docked > div": {
      paddingTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    ".MuiAppBar-positionFixed": {
      marginTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    '[data-role="messages-list-container"]': {
      top: `${theme.spacing(UPGRADE_BANNER_SPACING)} !important`,
    },

    ".mobile-toast .Toastify__toast": {
      top: `${theme.spacing(UPGRADE_BANNER_SPACING)} !important`,
    },
  },

  root: {
    backgroundColor: theme.palette.common.black,
    height: theme.spacing(UPGRADE_BANNER_SPACING),
    [theme.breakpoints.up("llg")]: {
      zIndex: 1201,
    },
    zIndex: 1199,
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.common.white,
  },

  link: {
    color: colorSystem.primary,
  },
}));

export interface UpgradeBannerProps extends BoxProps {
  trialEndsInDays: number;
  status?: string;
  setVisibleUpgradeBanner?: (value) => void;
}

export function UpgradeBanner(props: UpgradeBannerProps) {
  const {
    className,
    trialEndsInDays,
    setVisibleUpgradeBanner,
    status,
    ...other
  } = props;
  const s = useStyles();

  const text = getTrialEndsText(trialEndsInDays);

  return (
    <Box
      className={clsx(s.root, className)}
      {...other}
      onClick={() => setVisibleUpgradeBanner(false)}
    >
      {status === "trialing" ? (
        <Typography className={s.text}>
          Your trial ends {text}. The new plan is activated after the trial
          period.
        </Typography>
      ) : (
        status !== "active" && (
          <Typography className={s.text}>
            To continue working with the platform, you need to buy a
            subscription
          </Typography>
        )
      )}
    </Box>
  );
}
