import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";
import { MessageEditor } from "../new-editor/MessageEditor";
import {
  GroupPostCommentDto,
  GroupPostCommentReplyDto,
  GroupPostDto,
} from "@growth-machine-llc/stridist-api-client";
import { useCreateGroupPostCommentMutation } from "./mutations/UpsertGroupPostComment";
import { useCreateGroupPostCommentReplyMutation } from "./mutations/UpsertReply";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.selected.main,
    padding: theme.spacing(3),
  },

  form: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },

  editor: {
    flexGrow: 1,
  },

  helperText: {
    fontSize: 12,
    fontWeight: "normal",
    color: theme.palette.secondary.main,
    opacity: 0.6,
    margin: theme.spacing(0.5, 2),
  },

  formButtons: {
    right: theme.spacing(1),
    top: theme.spacing(1.5),
  },

  submit: {
    height: theme.spacing(6),
    width: "100%",
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      width: "auto",
      marginTop: 0,
      marginLeft: theme.spacing(2),
    },
  },
}));

export interface GroupPostsCommentFormProps extends BoxProps {
  post: GroupPostDto;
  postId?: number;
  comment?: GroupPostCommentDto & GroupPostCommentReplyDto;
  helperText?: string;
  onSubmit?: () => void;
  focusOnInit?: boolean;
  placeholder?: string;
}

export function GroupPostsCommentForm(props: GroupPostsCommentFormProps) {
  const {
    className,
    post,
    postId,
    comment,
    placeholder = "Post your thoughts...",
    helperText = "Remember to be kind when posting comments to the community.",
    onSubmit,
    focusOnInit = false,
    ...other
  } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const { mutate: createComment, isPending: createCommentInFlight } =
    useCreateGroupPostCommentMutation(
      post?.id || comment?.parentCommentId || comment?.id,
      post?.groupId,
    );
  const { mutate: createReply, isPending: createReplyInFlight } =
    useCreateGroupPostCommentReplyMutation(postId, comment?.id);

  React.useEffect(() => {
    if (focusOnInit && ref?.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!post && !comment) {
      throw new Error(
        "GroupPostsCommentForm: Either post or comment have to be referenced via a prop.",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessageSubmit = React.useCallback(
    (fragment?: Node[], callback?: () => void) => {
      const payload = {
        postId: postId || post?.id,
        ...(comment && {
          parentCommentId: comment.id,
        }),
        content: JSON.stringify(fragment),
      };

      const onSuccessHandler = {
        onSuccess: () => {
          callback();

          if (onSubmit) {
            onSubmit();
          }
        },
      };

      comment
        ? createReply(payload, onSuccessHandler)
        : createComment(payload, onSuccessHandler);
    },
    [comment, createComment, createReply, post, onSubmit],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.form}>
        <MessageEditor
          className={s.editor}
          placeholder={placeholder}
          fullWidth
          inputRef={ref}
          backupKeyType="group-post-comment"
          backupKeyId={post?.id || comment?.id}
          onMessageSubmit={handleMessageSubmit}
          disabled={createCommentInFlight || createReplyInFlight}
          creatingInFlight={createCommentInFlight || createReplyInFlight}
        />
      </Box>

      {helperText && (
        <Typography className={s.helperText} variant="body2">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}
