import clsx from "clsx";
import React, { useEffect } from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "lodash";
import { EnrollmentSort, EnrollmentsView } from "../../constants";
import { DropdownMenu } from "../menu/DropdownMenu";
import { EnrollmentList } from "../list/EnrollmentList";
import { GroupEnrollmentList } from "../list/GroupEnrollmentList";

import { useQueryParams } from "../../hooks/useQueryParams";
import {
  EnrollmentDto,
  EnrollmentGroupDto,
} from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
}));

export interface ProgramEnrollmentsProps
  extends Omit<ContainerProps, "children"> {
  enrollments: EnrollmentDto[];
  groupEnrollments: EnrollmentGroupDto[];
  programEnrollmentDataLoading: boolean;
}

export function ProgramEnrollments(props: ProgramEnrollmentsProps) {
  const {
    className,
    enrollments,
    groupEnrollments,
    programEnrollmentDataLoading,
    ...other
  } = props;
  const s = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    view: EnrollmentsView.CLIENTS,
    sort: EnrollmentSort.CREATED_AT_DESC,
  });

  const enrollmentsCount = enrollments.length;
  const groupEnrollmentsCount = groupEnrollments.length;

  const items = React.useMemo(
    () => [
      {
        children: `Enrolled ${EnrollmentsView.CLIENTS} (${enrollmentsCount})`,
        onClick: () => setQueryParams({ view: EnrollmentsView.CLIENTS }),
      },
      {
        children: `Enrolled ${EnrollmentsView.GROUPS} (${groupEnrollmentsCount})`,
        onClick: () =>
          setQueryParams({
            view: EnrollmentsView.GROUPS,
            sort: EnrollmentSort.NAME_ASC,
          }),
      },
    ],
    [enrollmentsCount, groupEnrollmentsCount, setQueryParams],
  );

  const header = React.useMemo(() => {
    const count =
      queryParams.view === EnrollmentsView.CLIENTS ||
      queryParams.view === undefined
        ? enrollmentsCount
        : groupEnrollmentsCount;

    return `${capitalize(queryParams.view ?? EnrollmentsView.CLIENTS)} (${count})`;
  }, [enrollmentsCount, groupEnrollmentsCount, queryParams]);

  if (
    !Object.values(EnrollmentsView).includes(
      (queryParams.view ?? EnrollmentsView.CLIENTS) as EnrollmentsView,
    )
  ) {
    return null;
  }

  return (
    <Container className={clsx(s.root, className)} maxWidth="md" {...other}>
      <DropdownMenu header={header} variant="normal" items={items} />

      {queryParams.view === EnrollmentsView.CLIENTS ||
      queryParams.view === undefined ? (
        <EnrollmentList
          enrollments={enrollments}
          programEnrollmentDataLoading={programEnrollmentDataLoading}
          queryParams={queryParams}
        />
      ) : (
        <GroupEnrollmentList
          groupEnrollments={groupEnrollments}
          programEnrollmentDataLoading={programEnrollmentDataLoading}
          queryParams={queryParams}
        />
      )}
    </Container>
  );
}
