import React from "react";
import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps,
} from "@mui/lab";
import { Box } from "@mui/material";

export interface LoadingButtonProps extends MuiLoadingButtonProps {}

const LoadingButton = ({
  children,
  variant = "text",
  ...props
}: LoadingButtonProps) => {
  return (
    <MuiLoadingButton variant={variant} {...props}>
      <span>{children}</span>
    </MuiLoadingButton>
  );
};

export default LoadingButton;
