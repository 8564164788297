import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ProgramEnrollDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2.75, 0),
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.hover.primary,
    },
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },

  header: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
  },

  subheader: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
}));

export interface ProgramSelectableItemProps extends Omit<BoxProps, "onClick"> {
  program: ProgramEnrollDto;
  onClick?: (program: ProgramEnrollDto) => void;
  disabled?: boolean;
}

export function ProgramSelectableItem(props: ProgramSelectableItemProps) {
  const { className, program, onClick, disabled = false, ...other } = props;
  const s = useStyles();
  const { name, length, image } = program;

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick(program);
    }
  }, [onClick, program]);

  return (
    <Box
      className={clsx(s.root, className)}
      onClick={disabled ? undefined : handleClick}
      {...other}
    >
      <Avatar className={s.avatar} src={image} alt="" />
      <Box>
        <Typography className={s.header} children={name} />
        <Typography
          className={s.subheader}
          children={`${length} week${length > 1 ? "s" : ""}`}
        />
      </Box>
    </Box>
  );
}
