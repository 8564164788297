import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import GroupPostsService from "../../../services/GroupPostsService";
import { GROUP_POSTS_LIST_QUERY_KEY } from "../GroupPostsList";
import {
  GroupPostCommentDto,
  ICursorPaginatedListOfGroupPostCommentDto,
  ICursorPaginatedListOfGroupPostDto,
  CursorPaginatedListOfGroupPostCommentDto,
  UserBriefInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { GROUP_POST_COMMENTS_LIST_QUERY_KEY } from "../../../hooks/groupPosts/useGroupPostComments";
import dayjs from "dayjs";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { createNewGroupPostComment } from "../utils/GroupUtil";

export const useCreateGroupPostCommentMutation = (
  postId: number,
  groupId: number,
) => {
  const { id: currentUserId, email, displayName, photoUrl } = useCurrentUser();
  const successUpdater = INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties;
  const queryClient = useQueryClient();
  const author = UserBriefInfoDto.fromJS({
    id: Number(currentUserId),
    email,
    displayName,
    photoUrl,
  });
  return useOptimisticUpdateMutation({
    disableToastAlerts: true,
    mutationFn: GroupPostsService.createGroupPostComment,
    queryKey: [GROUP_POST_COMMENTS_LIST_QUERY_KEY, { postId, pending: true }],
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<ICursorPaginatedListOfGroupPostCommentDto>,
        variables,
        tempId,
      ) => ({
        ...oldData,
        pages: oldData?.pages
          ? oldData.pages.map((page, index, pages) => ({
              ...page,
              items:
                index === pages.length - 1
                  ? [
                      ...page.items,
                      createNewGroupPostComment(
                        tempId,
                        author,
                        variables.content,
                      ),
                    ]
                  : page.items,
            }))
          : [
              new CursorPaginatedListOfGroupPostCommentDto({
                items: [
                  createNewGroupPostComment(tempId, author, variables.content),
                ],
                nextCursor: null,
                totalPages: 1,
                hasNextPage: false,
              }),
            ],
        pageParams: oldData?.pageParams ?? [1],
      }),
    },
    successUpdater: {
      updateFn: (oldData, variables, tempId) =>
        successUpdater(oldData, tempId, { id: variables }),
    },

    options: {
      onSuccess: () => {
        queryClient.setQueryData(
          [GROUP_POSTS_LIST_QUERY_KEY, { groupId }],
          (prev: InfiniteData<ICursorPaginatedListOfGroupPostDto>) =>
            INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
              prev,
              postId,
              (prev) => ({
                ...prev,
                totalComments: prev.totalComments + 1,
              }),
            ),
        );
      },
    },
  });
};
