import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { CurriculumComponent } from "../../../../types";

export const syncComponentDataAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    data: Partial<CurriculumComponent>;
    tempId: number;
  }>,
) => {
  const { componentId, tempId, data } = action.payload;
  const realComponent = state.value.components[componentId];
  const tempComponent = state.value.components[tempId];

  if (realComponent) {
    state.value.components[componentId] = {
      ...realComponent,
      ...data,
    };
  }

  if (tempComponent) {
    state.value.components[tempId] = {
      ...tempComponent,
      ...data,
    };
  }
};
