import React, { useEffect } from "react";
import { ComponentSchedule } from "../../schedule/ComponentSchedule";
import {
  ComponentRepeat,
  ComponentType,
  ReminderType,
} from "../../../constants";
import { Schedule } from "../../schedule/types";
import { debounce } from "@mui/material";
import { useProgramWeeks } from "../../../hooks/useProgramWeeks";
import { CurriculumComponent } from "../../../redux/types";
import { useCurriculumDispatch } from "../../../redux/hooks";
import { updateComponentSchedule } from "../../../redux/curriculum/curriculum-slice";

// TODO_API_V2_CURRICULUM decide debounce solution if needed
const AUTO_SAVE_DELAY = 0;

interface ISidebarScheduleTabProps {
  componentData: CurriculumComponent;
  componentTypeColor: string;
}

const SidebarScheduleTab = (props: ISidebarScheduleTabProps) => {
  const { componentData, componentTypeColor } = props;
  const component = componentData;
  const dispatch = useCurriculumDispatch();
  const toSchedule = (component: CurriculumComponent): Schedule => ({
    weekId: component.weekId,
    days: JSON.parse(component.days),
    duration: component.duration,
    repeat: component.repeat as ComponentRepeat,
    reminderType: component.reminderType as ReminderType,
    reminderTime: component.reminderTime,
    messageTime: component.messageTime,
  });

  const [schedule, setSchedule] = React.useState<Schedule>(
    toSchedule(componentData),
  );
  const programWeeks = useProgramWeeks();

  useEffect(() => {
    setSchedule(toSchedule(componentData));
  }, [componentData]);

  const handleScheduleChange = (schedule: Schedule) => {
    setSchedule(schedule);
    handleSave(schedule);
  };

  const save = (schedule: Schedule) => {
    dispatch(
      updateComponentSchedule({
        componentId: component.id,
        schedule,
      }),
    );
  };

  const handleSave = React.useMemo(() => debounce(save, AUTO_SAVE_DELAY), []);

  return (
    <>
      <ComponentSchedule
        layout="sidebar"
        componentType={component.type as ComponentType}
        schedule={schedule}
        onChange={handleScheduleChange}
        panelOpen
        disableClickAwayListener
        programWeeks={programWeeks}
      />
    </>
  );
};

export default SidebarScheduleTab;
