import {
  AddLikePostCommand,
  AddLikePostCommentCommand,
  CreateGroupPostCommand,
  CreateGroupPostCommentCommand,
  CreateReplyCommentCommand,
  ICreateGroupPostCommand,
  ICreateGroupPostCommentCommand,
  ICreateReplyCommentCommand,
  IUpdateGroupPostCommand,
  RemoveLikePostCommand,
  RemoveLikePostCommentCommand,
  UpdateGroupPostCommand,
} from "@growth-machine-llc/stridist-api-client";
import { groupPostsClient, groupPostCommentsClient } from "../api/ApiClients";

import ServiceBase from "./ServiceBase";

class GroupPostsService extends ServiceBase {
  async getGroupPosts(
    groupId: number,
    pageNumber: number,
    afterCursor: number | null,
    likesToTake?: number,
  ) {
    return groupPostsClient.getGroupPosts(
      groupId,
      likesToTake ?? 5,
      pageNumber,
      afterCursor,
    );
  }

  async getGroupPostComments(
    postId: number,
    afterCursor: number | null,
    pageSize: number,
    likesToTake?: number,
  ) {
    return groupPostCommentsClient.getCommentsWithReplies(
      postId,
      likesToTake ?? 10,
      pageSize,
      afterCursor,
    );
  }

  async createGroupPostComment(variables: ICreateGroupPostCommentCommand) {
    const command = CreateGroupPostCommentCommand.fromJS(variables);
    return groupPostCommentsClient.createGroupPostComment(command);
  }

  async createGroupPost(variables: ICreateGroupPostCommand) {
    const command = CreateGroupPostCommand.fromJS(variables);
    return groupPostsClient.createGroupPost(command);
  }

  async updateGroupPost(variables: IUpdateGroupPostCommand) {
    const command = UpdateGroupPostCommand.fromJS(variables);
    return groupPostsClient.updateGroupPost(command.id, command);
  }

  async deleteGroupPost(postId: number) {
    return groupPostsClient.deleteGroupPost(postId);
  }

  async likeGroupPost(groupPostId: number) {
    const command = AddLikePostCommand.fromJS({ groupPostId });
    return groupPostsClient.addPostLike(command);
  }

  async unLikeGroupPost(groupPostId: number) {
    const command = RemoveLikePostCommand.fromJS({ groupPostId });
    return groupPostsClient.removePostLike(command);
  }

  async likeGroupPostComment(commentId: number) {
    const command = AddLikePostCommentCommand.fromJS({ commentId });
    return groupPostCommentsClient.addCommentLike(command);
  }

  async unLikeGroupPostComment(commentId: number) {
    const command = RemoveLikePostCommentCommand.fromJS({ commentId });
    return groupPostCommentsClient.removeCommentLike(command);
  }

  async createReplyComment(variables: ICreateReplyCommentCommand) {
    const command = CreateReplyCommentCommand.fromJS(variables);
    return groupPostCommentsClient.createReplyComment(command);
  }
}

export default new GroupPostsService();
