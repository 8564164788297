import React from "react";
import { useCurrentUser } from "./useCurrentUser";

import { useSignInAsMutation } from "../components/auth/mutations/SignIn";

export function useSwitchUser(redirect: boolean = true) {
  const me = useCurrentUser();
  const wasImpersonating = me?.isImpersonating;
  const { mutate: signInAs } = useSignInAsMutation({
    redirect,
    wasImpersonating,
  });

  const switchUser = React.useCallback(
    (userId?: number) => {
      // If no userId is provided, switch back to the original user, or throw error if not impersonating
      signInAs({
        desiredUserId: userId,
      });
    },
    [signInAs],
  );

  return switchUser;
}
