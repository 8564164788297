import React, { MouseEventHandler, useMemo } from "react";
import { Box, BoxProps, Typography, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import { Filters } from "../program/ProgramDetailsFilters";
import { ProgramWeekDateRange } from "../program/ProgramWeekDateRange";
import { ProgramSpreadsheetGrid } from "./ProgramSpreadsheetGrid";
import WeekActionItems from "../program-week/WeekActionItems";
import { useCurriculumSelector } from "../../redux/hooks";
import {
  selectCantMoveWeeksExceptSelf,
  selectProgram,
  selectWeekById,
} from "../../redux/curriculum/selectors/curriculum";
import { DefaultLoader } from "../loading/DefaultLoader";
import { selectGeneralLoading } from "../../redux/api/selectors";

const useStyles = makeStyles((theme) => ({
  root: {},

  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    flexFlow: "row nowrap",
    top: 0,
    background: "white",
    gridColumn: "span 8",
    width: "100%",
    marginLeft: theme.spacing(0.75),
    marginTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    width: "max-content",
    position: "sticky",
    top: theme.spacing(3.5),
    left: theme.spacing(3.5),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 0,
  },

  dateRange: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,
  },

  moreButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "none",
    },
    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },

  grid: {
    display: "flex",
    gap: 60,
  },
}));

export interface ProgramSpreadsheetWeekProps extends BoxProps {
  weekId: number;
  filters?: Filters;
  startDate?: string;
  onOpenMenu?: ButtonProps["onClick"];
  programId: number;
  schedule: ProgramWeekSchedule;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  activityPreviewItem?: any;
  setActivityPreviewItem: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpenDialog: () => void;
  duplicateWeekInFlight: boolean;
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickMove: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
}

export function ProgramSpreadsheetWeek(props: ProgramSpreadsheetWeekProps) {
  const {
    weekId,
    schedule,
    filters,
    startDate,
    setActivityPreview,
    activityPreviewItem,
    setActivityPreviewItem,
    handleClickOpenDialog,
    weeksActions,
    zIndex,
    duplicateWeekInFlight,
  } = props;
  const s = useStyles();
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => i);
  const program = useCurriculumSelector(selectProgram);
  const week = useCurriculumSelector(selectWeekById(weekId));
  const cantMove = useCurriculumSelector(selectCantMoveWeeksExceptSelf(weekId));
  const loading = useCurriculumSelector(selectGeneralLoading);
  const weekNumber = week.weekNumber;

  // PER ROW DUPLICATION LOGIC
  function findClosestDayIndex(
    weekItems: ProgramWeekSchedule,
    inputDayOfWeekIndex: number,
  ): number | null {
    let closestDayIndex: number | null = null;
    let minDistance = Infinity;

    for (const item of weekItems) {
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        if (item.days[dayIndex]) {
          const distance = Math.abs(dayIndex - inputDayOfWeekIndex);
          if (distance < minDistance) {
            minDistance = distance;
            closestDayIndex = dayIndex;
          }
        }
      }
    }

    return closestDayIndex;
  }

  function findItemsWithDay(
    weekItems: ProgramWeekSchedule,
    dayIndex: number,
  ): ProgramWeekSchedule {
    return weekItems.filter((item) => item.days[dayIndex]);
  }

  function getItemsForPerRowDuplication(
    arr: ProgramWeekSchedule,
    dayOfWeek: number,
  ): ProgramWeekSchedule {
    const closestDayIndex = findClosestDayIndex(arr, dayOfWeek);

    if (closestDayIndex !== null) {
      const itemsWithClosestDay = findItemsWithDay(arr, closestDayIndex);
      return itemsWithClosestDay;
    }
    return null;
  }

  // PER COLUMN DUPLICATION LOGIC
  function findClosestWeekIndex(
    weekItems: ProgramWeekSchedule,
    inputWeek: number,
  ): number | null {
    let closestWeek: number | null = null;
    let minDistance = Infinity;

    for (const item of weekItems) {
      for (const week of item.weeks) {
        const distance = Math.abs(week - inputWeek);
        if (distance < minDistance) {
          minDistance = distance;
          closestWeek = week;
        }
      }
    }

    return closestWeek;
  }

  function findItemsWithWeek(
    weekItems: ProgramWeekSchedule,
    closestWeek: number,
  ): ProgramWeekSchedule {
    return weekItems.filter((item) => item.weeks.includes(closestWeek));
  }

  function getItemsForPerColumnDuplication(
    arr: ProgramWeekSchedule,
    weekNumber: number,
  ): ProgramWeekSchedule {
    const closestWeek = findClosestWeekIndex(arr, weekNumber);
    if (closestWeek !== null) {
      const itemsWithClosestWeek = findItemsWithWeek(arr, closestWeek);
      return itemsWithClosestWeek;
    }
    return null;
  }

  const upperIsDisabled = useMemo(
    () => cantMove.includes(week.weekNumber - 1),
    [cantMove, week.weekNumber],
  );

  const lowerIsDisabled = useMemo(
    () => cantMove.includes(week.weekNumber + 1),
    [cantMove, week.weekNumber],
  );

  const weeksData = {
    canMoveUp:
      week.weekNumber > 1 && !week.waitingForApiResponse && !upperIsDisabled,
    canMoveDown:
      week.weekNumber < program.length &&
      !week.waitingForApiResponse &&
      !lowerIsDisabled,
    canDuplicate: !duplicateWeekInFlight && !week.waitingForApiResponse,
    canDelete: program.length > 1 && !week.waitingForApiResponse,
  };

  return (
    <>
      <Box className={s.headerWrapper}>
        <Box className={s.header}>
          <Typography variant="h5" className={s.title}>
            Week {week.weekNumber}
            {week.waitingForApiResponse ? " - creating..." : ""}
          </Typography>
          <WeekActionItems
            weekId={week.id}
            onClickDelete={weeksActions.onClickDelete}
            onClickMove={weeksActions.onClickMove}
            onClickDuplicate={weeksActions.onClickDuplicate}
            canMoveUp={weeksData.canMoveUp}
            canMoveDown={weeksData.canMoveDown}
            canDuplicate={weeksData.canDuplicate}
            canDelete={weeksData.canDelete}
          />
          {loading && <DefaultLoader />}
        </Box>
        {startDate && (
          <ProgramWeekDateRange
            variant="h6"
            className={s.dateRange}
            startDate={startDate}
            week={week.weekNumber}
          />
        )}
      </Box>
      {daysOfWeek.map((dayOfWeek, i) => {
        const currentWeek = schedule.filter((s) =>
          s.weeks.includes(week.weekNumber),
        );

        const currentDaySchedule = schedule.filter(
          (s) => s.weeks.includes(week.weekNumber) && !!s.days[dayOfWeek],
        );

        const itemsToDuplicateFromCurrentWeek = currentWeek.filter((item) =>
          item.days.some((value, index) => index !== dayOfWeek && value),
        );

        const itemsToDuplicateFromLeft = itemsToDuplicateFromCurrentWeek.filter(
          (item) =>
            item.days.some((value, index) => index > dayOfWeek && value),
        );

        const itemsToDuplicateFromRight =
          itemsToDuplicateFromCurrentWeek.filter((item) =>
            item.days.some((value, index) => index < dayOfWeek && value),
          );

        const currentDayOfWeekArr = schedule.filter(
          (s) => s.days[dayOfWeek] === true,
        );

        const itemsToDuplicateFromAbove = currentDayOfWeekArr.filter((item) =>
          item.weeks.some((w) => w > weekNumber),
        );

        const itemsToDuplicateFromBelow = currentDayOfWeekArr.filter((item) =>
          item.weeks.some((w) => w < weekNumber),
        );

        const duplicateByDirection = {
          down: getItemsForPerColumnDuplication(
            itemsToDuplicateFromAbove,
            weekNumber,
          ),
          up: getItemsForPerColumnDuplication(
            itemsToDuplicateFromBelow,
            weekNumber,
          ),
          right: getItemsForPerRowDuplication(
            itemsToDuplicateFromLeft,
            dayOfWeek,
          ),
          left: getItemsForPerRowDuplication(
            itemsToDuplicateFromRight,
            dayOfWeek,
          ),
        };

        return (
          // for every grid item set zIndex starting from last from 1 to *n
          <Box key={dayOfWeek} zIndex={Number(zIndex) - i}>
            <ProgramSpreadsheetGrid
              filters={filters}
              schedule={currentDaySchedule}
              dayOfWeek={dayOfWeek}
              positions={[...week.positions]}
              week={week.weekNumber}
              showComponents={!week.waitingForApiResponse}
              weekId={week.id}
              programId={props.programId}
              setActivityPreview={setActivityPreview}
              activityPreviewItem={activityPreviewItem}
              setActivityPreviewItem={setActivityPreviewItem}
              handleClickOpenDialog={handleClickOpenDialog}
              duplicateByDirection={duplicateByDirection}
            />
          </Box>
        );
      })}
      {/* Extra column to get free spacing on the right in overflow-x parent container */}
      <Box sx={{ minWidth: "1px", flexShrink: 0 }} />
    </>
  );
}
