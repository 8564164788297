import clsx from "clsx";
import React from "react";
import { DialogProps, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { uniq } from "lodash";

import {
  SelectableClient,
  SelectableClientProps,
} from "../item/SelectableClient";
import { colorSystem } from "../../theme";

import { BaseDialog } from "./BaseDialog";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { PROGRAM_ENROLLMENTS_QUERY_KEY } from "../../routes/coach/program/enrollments/CoachProgramEnrollmentsRoute";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { ProgramDialogSkeleton } from "../loading/ProgramDialogSkeleton";
import { extractSlugId } from "../../utils/slug";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 490,
    height: 524,
  },

  date: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: 0.75,
    color: theme.palette.text.secondary,
  },

  client: {
    cursor: "pointer",
    padding: theme.spacing(2.75, 0),

    "&:hover": {
      backgroundColor: `${colorSystem.black}0A`,
    },

    "&:not(:first-child)": {
      borderTopWidth: 1,
      borderTopColor: theme.palette.border.primary,
      borderTopStyle: "solid",
    },
  },
}));

export interface ProgramEnrollmentStartDateViewDialogProps
  extends Omit<DialogProps, "onSelect"> {
  onSelect: (date: string, clientName: string, clientEmail: string) => void;
  slug: string;
}

export const CURRICULUM_PROGRAMS_QUERY_KEY = "curriculum-programs";
export function ProgramEnrollmentStartDateViewDialog(
  props: ProgramEnrollmentStartDateViewDialogProps,
) {
  const { className, onSelect, onClose, slug, open, ...other } = props;
  const s = useStyles();
  const slugId = extractSlugId(slug);
  const { data: enrollments, isLoading: programEnrollmentDataLoading } =
    useQuery({
      queryKey: [CURRICULUM_PROGRAMS_QUERY_KEY, { slugId }],
      queryFn: () =>
        EnrollmentsClientService.getEnrollments({
          programSlug: slug,
        }),
      enabled: open,
    });

  const dates = React.useMemo(
    () =>
      uniq(
        enrollments?.enrollments.flatMap(({ isoStartDate }) => isoStartDate),
      ).sort((a, b) =>
        new Date(a).getTime() < new Date(b).getTime() ? -1 : 1,
      ),
    [enrollments?.enrollments],
  );

  const handleClientClick: SelectableClientProps["onClick"] = React.useCallback(
    (event) => {
      const {
        currentTarget: {
          dataset: { startDate, clientName, clientEmail },
        },
      } = event;

      if (startDate && (clientName || clientEmail)) {
        onSelect(startDate, clientName, clientEmail);
        onClose(event, "backdropClick");
      }
    },
    [onClose, onSelect],
  );
  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="View using client start date"
      onClose={onClose}
      open={open}
      PaperProps={{
        className: s.paper,
      }}
      {...other}
    >
      {programEnrollmentDataLoading
        ? Array.from({ length: 3 }).map((_, i) => (
            <ProgramDialogSkeleton key={`client-skeleton-${i}`} />
          ))
        : dates.map((date, index) => (
            <React.Fragment key={index}>
              <Typography className={s.date} variant="h6">
                {new Date(date).getTime() < new Date().getTime()
                  ? "Started"
                  : "Starts"}{" "}
                {dayjs(date).format("MMM D, YYYY")}
              </Typography>
              <Box>
                {enrollments.enrollments
                  .filter(({ isoStartDate }) => isoStartDate === date)
                  .map(({ id, isoStartDate, user: client }) => (
                    <SelectableClient
                      key={id}
                      className={s.client}
                      client={client}
                      data-start-date={isoStartDate}
                      data-client-name={client.displayName}
                      data-client-email={client.email}
                      onClick={handleClientClick}
                    />
                  ))}
              </Box>
            </React.Fragment>
          ))}
    </BaseDialog>
  );
}
