import React, { ReactElement } from "react";
import { IUserInfoDto } from "@growth-machine-llc/stridist-api-client";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  PRIVATE = "PRIVATE",
}

export enum Units {
  US = "US",
  METRIC = "METRIC",
}

export enum UnitsTime {
  MINUTES = "MINUTES",
  SECONDS = "SECONDS",
}

export enum UnitsLabels {
  US = "Imperial/US",
  METRIC = "Metric",
}

export enum UserRole {
  CLIENT = "CLIENT",
  COACH = "COACH",
}

export enum ProgramStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}

export enum EnrollmentStatus {
  ACTIVE = "ACTIVE",
  UPCOMING = "UPCOMING",
  PAST = "PAST",
  ALL = "ALL",
}

export enum ClientStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  ADDED = "ADDED",
}

export enum ComponentType {
  LESSON = "LESSON",
  HABIT = "HABIT",
  CHECKIN = "CHECKIN",
  WORKOUT = "WORKOUT",
  MESSAGE = "MESSAGE",
}

export enum ComponentStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}

export enum AssetType {
  USER_PHOTO = "USER_PHOTO",
  PROGRAM_FILE = "PROGRAM_FILE",
  PROGRAM_IMAGE = "PROGRAM_IMAGE",
  PROGRAM_AUDIO = "PROGRAM_AUDIO",
  PROGRAM_VIDEO = "PROGRAM_VIDEO",
  PROGRAM_CHECKIN_PHOTO = "PROGRAM_CHECKIN_PHOTO",
  PROGRAM_CHECKIN_FILE = "PROGRAM_CHECKIN_FILE",
  PROGRAM_COVER = "PROGRAM_COVER",
  MESSAGE_IMAGE = "MESSAGE_IMAGE",
  MESSAGE_FILE = "MESSAGE_FILE",
  LIBRARY_FILE = "LIBRARY_FILE",
  LIBRARY_IMAGE = "LIBRARY_IMAGE",
  LIBRARY_AUDIO = "LIBRARY_AUDIO",
  LIBRARY_VIDEO = "LIBRARY_VIDEO",
  WORKOUT_MEDIA = "WORKOUT_MEDIA",
  FEEDBACK_IMAGE = "FEEDBACK_IMAGE",
  FEEDBACK_FILE = "FEEDBACK_FILE",
  PORTAL_LOGO = "PORTAL_LOGO",
  MEAL_PHOTO = "MEAL_PHOTO",
  CLIENT_FORM_IMAGE = "CLIENT_FORM_IMAGE",
  GROUP_IMAGE = "GROUP_IMAGE",
}

export enum ComponentRepeat {
  NONE = "NONE",
  WEEKLY = "WEEKLY",
  BIWEEKLY = "BIWEEKLY",
  EVERY_3_WEEKS = "EVERY_3_WEEKS",
  EVERY_4_WEEKS = "EVERY_4_WEEKS",
}

export enum InviteSource {
  EMAIL = "EMAIL",
  LINK = "LINK",
}

export const UPLOADED_FILE_NAME_REGEX =
  /^(https:\/\/.+\.stridist\.com\/programs\/).+(\.[a-z0-9]{1,5})$/;

export const maxEnrollments = 4;

export const maxUploadFileSize = 1024 * 1024 * 10;
export const workoutMaxUploadFileSize = 1024 * 1024 * 200;
export const maxVideoUploadFileSize = 1024 * 1024 * 1024 * 2;

export const programGalleryImages = [
  "https://s.stridist.com/gallery/barbell-220x150.jpg",
  "https://s.stridist.com/gallery/crunch-220x150.jpg",
  "https://s.stridist.com/gallery/fruit-bowl-220x150.jpg",
  "https://s.stridist.com/gallery/harvest-salad-220x150.jpg",
  "https://s.stridist.com/gallery/pink-smoothie-220x150.jpg",
  "https://s.stridist.com/gallery/pullup-bw-220x150.jpg",
  "https://s.stridist.com/gallery/salmon-edamame-220x150.jpg",
  "https://s.stridist.com/gallery/stairs-220x150.jpg",
  "https://s.stridist.com/gallery/sunset-runner-220x150.jpg",
  "https://s.stridist.com/gallery/vintage-weights-220x150.jpg",
];

export const getRandomProgramGalleryImage = (): string =>
  programGalleryImages[Math.floor(Math.random() * programGalleryImages.length)];

export enum UnitType {
  WEIGHT = "WEIGHT",
  LENGTH = "LENGTH",
  PERCENTAGE = "PERCENTAGE",
  STEPS = "STEPS",
}

export enum WeeklyProgressPeriod {
  ALL_TIME = "ALL_TIME",
  LAST_WEEK = "LAST_WEEK",
  THIS_WEEK = "THIS_WEEK",
}

export type LengthMeasurementUnit = "in" | "cm";
export type WeightMeasurementUnit = "lbs" | "kg";
export type MeasurementUnit =
  | WeightMeasurementUnit
  | LengthMeasurementUnit
  | "STEPS"
  | "PERCENTAGE";

export const getClientUnits = (
  user: Pick<IUserInfoDto, "units" | "role">,
): Units => {
  if (!user || user.role !== UserRole.CLIENT) {
    return Units.US;
  }

  return user.units as Units;
};

export const getUnitLabel = (unit: MeasurementUnit) =>
  unit.toLocaleUpperCase() === "PERCENTAGE" ? "%" : unit;

export const getDefaultUnit = (
  unitType: UnitType,
  units: Units = Units.US,
): MeasurementUnit => {
  switch (unitType) {
    case UnitType.LENGTH:
      return units === Units.US ? "in" : "cm";
    case UnitType.WEIGHT:
      return units === Units.US ? "lbs" : "kg";
    case UnitType.PERCENTAGE:
      return "PERCENTAGE";
    case UnitType.STEPS:
      return "STEPS";
    default:
      throw new Error("Unsupported unit type");
  }
};

export enum TypeMeasurement {
  BODYWEIGHT = "bodyweight",
  BODYFAT = "bodyfat",
  SHOULDER = "shoulder",
  CHEST = "chest",
  UPPER_ARM = "upper_arm",
  WAIST = "waist",
  HIP = "hip",
  THIGH = "thigh",
  CALF = "calf",
  STEP_COUNT = "step_count",
}

export interface MeasurementTypeInfo {
  name: TypeMeasurement;
  label: string;
  unitType: UnitType;
}

export const units = {
  [UnitType.WEIGHT]: {
    lbs: "lbs",
    kg: "kg",
  },

  [UnitType.LENGTH]: {
    in: "in",
    cm: "cm",
  },
};

export const measurementTypes: MeasurementTypeInfo[] = [
  {
    name: TypeMeasurement.BODYWEIGHT,
    label: "Bodyweight",
    unitType: UnitType.WEIGHT,
  },

  {
    name: TypeMeasurement.BODYFAT,
    label: "Body fat %",
    unitType: UnitType.PERCENTAGE,
  },

  {
    name: TypeMeasurement.SHOULDER,
    label: "Shoulder",
    unitType: UnitType.LENGTH,
  },

  {
    name: TypeMeasurement.CHEST,
    label: "Chest",
    unitType: UnitType.LENGTH,
  },

  {
    name: TypeMeasurement.UPPER_ARM,
    label: "Upper Arm",
    unitType: UnitType.LENGTH,
  },

  {
    name: TypeMeasurement.WAIST,
    label: "Waist",
    unitType: UnitType.LENGTH,
  },

  {
    name: TypeMeasurement.HIP,
    label: "Hip",
    unitType: UnitType.LENGTH,
  },

  {
    name: TypeMeasurement.THIGH,
    label: "Thigh",
    unitType: UnitType.LENGTH,
  },

  {
    name: TypeMeasurement.CALF,
    label: "Calf",
    unitType: UnitType.LENGTH,
  },
];

export interface NotificationsSetting {
  weeklyUpdate?: boolean;
  dailyUpdate?: boolean;
  newMessageEmail?: boolean;
  newMessagePush?: boolean;
  newResponseEmail?: boolean;
  newResponsePush?: boolean;
  activityCompletion?: boolean;
  remindersEmail?: boolean;
  remindersSms?: boolean;
  remindersPush?: boolean;
}

export const weeklyPeriods = {
  [WeeklyProgressPeriod.ALL_TIME]: "All time",
  [WeeklyProgressPeriod.THIS_WEEK]: "This week",
  [WeeklyProgressPeriod.LAST_WEEK]: "Last week",
};

export enum MetricType {
  CHECKIN_ANSWER_BINARY = "CHECKIN_ANSWER_BINARY",
  CHECKIN_ANSWER_MEASUREMENT = "CHECKIN_ANSWER_MEASUREMENT",
  CHECKIN_ANSWER_RATING_SCALE = "CHECKIN_ANSWER_RATING_SCALE",
  CHECKIN_ANSWER_PROGRESS_PHOTO = "CHECKIN_ANSWER_PROGRESS_PHOTO",
  CHECKIN_ANSWER_LONG_TEXT = "CHECKIN_ANSWER_LONG_TEXT",
  CHECKIN_ANSWER_SHORT_TEXT = "CHECKIN_ANSWER_SHORT_TEXT",
  CHECKIN_ANSWER_NUMBER = "CHECKIN_ANSWER_NUMBER",
  CHECKIN_ANSWER_FILE = "CHECKIN_ANSWER_FILE",
  CHECKIN_ANSWER_MULTIPLE_CHOICE = "CHECKIN_ANSWER_MULTIPLE_CHOICE",
  CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX = "CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX",
  CHECKIN_ANSWER_DATE = "CHECKIN_ANSWER_DATE",
  CHECKIN_ANSWER_EMOJI = "CHECKIN_ANSWER_EMOJI",
  CHECKIN_ANSWER_STAR_RATING = "CHECKIN_ANSWER_STAR_RATING",
  CHECKIN_ANSWER_NUTRITION_TARGET = "CHECKIN_ANSWER_NUTRITION_TARGET",
}

export enum CompletionPeriod {
  ALL_TIME = "ALL_TIME",
  BEFORE_NOW = "BEFORE_NOW",
  BEFORE_NEXT_WEEK = "BEFORE_NEXT_WEEK",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
}

type NavigationItemQuery = {
  [key: string]: string;
};
export interface NavigationItem {
  name: string;
  link: string;
  selected?: boolean;
  query?: NavigationItemQuery | (() => NavigationItemQuery);
}

export enum AuthTypeEnum {
  NOBODY = "NOBODY",
  USER = "USER",
  CLIENT = "CLIENT",
  COACH = "COACH",
  ADMIN = "ADMIN",
}

export const GIPHY_API_KEY = "WFPkOXJmkguoG5ghLeHgfPLfoUD644hS";

export enum CoachComponentSavingState {
  EMPTY,
  SAVING,
  SAVED,
}

export const componentTypes = [
  ComponentType.LESSON,
  ComponentType.HABIT,
  ComponentType.CHECKIN,
  ComponentType.WORKOUT,
  ComponentType.MESSAGE,
];

export const genderOptions = [
  { label: "Male", value: Gender.MALE },
  { label: "Female", value: Gender.FEMALE },
  { label: "I would rather not say", value: Gender.PRIVATE },
];

export enum ExerciseTypeExtra {
  NONE = "None",
  RATE_OF_PERCEIVED_EXERTION = "RPE",
  REPS_IN_RESERVE = "RIR",
}

export const DefaultExerciseTypeExtra =
  ExerciseTypeExtra.RATE_OF_PERCEIVED_EXERTION;

export enum ExerciseTypeSet {
  DISTANCE = "DISTANCE",
  TIME = "TIME",
  WEIGHT = "WEIGHT",
}

export enum ExerciseTypeReps {
  WHOLE = "WHOLE",
  RANGE = "RANGE",
}

export enum ExerciseSetNumberType {
  REGULAR_SET = "set",
  WARM_UP = "warm-up",
  DROP_SET = "drop set",
  FAILURE = "failure",
}

export const repsTypeRelativeOptions = [ExerciseTypeReps.RANGE];

export const setUnitTimeTypeOptions = [
  { label: "Minutes", labelShortened: "min", value: UnitsTime.MINUTES },
  { label: "Seconds", labelShortened: "sec", value: UnitsTime.SECONDS },
];

export const setUnitsTypeOptions = [
  { label: "Metric", value: Units.METRIC },
  { label: "Imperial", value: Units.US },
];
export interface ISetNumberOptions {
  label: string;
  value: ExerciseSetNumberType;
  info?: string | ReactElement;
}

export const setSetNumberOptions: ISetNumberOptions[] = [
  {
    label: "Warm-up",
    value: ExerciseSetNumberType.WARM_UP,
    info: (
      <>
        Warm-up sets - lighter sets done before the main workout sets to prepare
        muscles, joints, and nervous system for the heavier work to come.
      </>
    ),
  },
  {
    label: "Regular",
    value: ExerciseSetNumberType.REGULAR_SET,
  },
  {
    label: "Drop set",
    value: ExerciseSetNumberType.DROP_SET,
    info: (
      <>
        Drop set - technique to perform exercises until failure (or near
        failure) with a given weight.
        <br /> <br />
        Then immediately 'drop' the weight and continue performing more reps
        until failure again.
        <br /> <br />
        This can be repeated multiple times.
      </>
    ),
  },
  {
    label: "Failure",
    value: ExerciseSetNumberType.FAILURE,
    info: (
      <>
        Failure set - set performed until you cannot complete another repetition
        with proper form.
        <br /> <br />
        This technique is used to push muscles to their maximum capacity,
        encouraging growth and strength gains.
      </>
    ),
  },
];

export const getExerciseUnits = (
  type: ExerciseTypeSet,
  unit: Units | UnitsTime,
) => {
  switch (type) {
    case ExerciseTypeSet.DISTANCE:
      return unit === Units.METRIC ? "m" : "inc";
    case ExerciseTypeSet.WEIGHT:
      return unit === Units.METRIC ? "kg" : "lb";
    case ExerciseTypeSet.TIME:
      return unit === UnitsTime.MINUTES ? "min" : "sec";
    default:
      return "";
  }
};

export enum VerificationStatus {
  PENDING = "pending",
  APPROVED = "approved",
  CANCELLED = "cancelled",
}

export enum GroupSort {
  NAME_ASC = "NAME_ASC",
  RECENTLY_ACTIVE = "RECENTLY_ACTIVE",
}

export const groupSortLabels: Record<GroupSort, string> = {
  [GroupSort.NAME_ASC]: "Name",
  [GroupSort.RECENTLY_ACTIVE]: "Recently active",
};

export enum GroupStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum EnrollmentSort {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",

  EMAIL_ASC = "EMAIL_ASC",
  EMAIL_DESC = "EMAIL_DESC",

  WEEK_ASC = "WEEK_ASC",
  WEEK_DESC = "WEEK_DESC",

  SCORE_ASC = "SCORE_ASC",
  SCORE_DESC = "SCORE_DESC",

  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
}

export const enrollmentSortLabels: Record<EnrollmentSort, string> = {
  [EnrollmentSort.CREATED_AT_ASC]: "",
  [EnrollmentSort.NAME_ASC]: "Name",
  [EnrollmentSort.EMAIL_ASC]: "Email",
  [EnrollmentSort.SCORE_ASC]: "Progress",
  [EnrollmentSort.NAME_DESC]: "",
  [EnrollmentSort.EMAIL_DESC]: "",
  [EnrollmentSort.SCORE_DESC]: "",
  [EnrollmentSort.CREATED_AT_DESC]: "Recent",
  [EnrollmentSort.WEEK_ASC]: "",
  [EnrollmentSort.WEEK_DESC]: "",
};

export enum GroupEnrollmentSort {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",

  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
}

export enum EnrollmentsView {
  CLIENTS = "clients",
  GROUPS = "groups",
}

export enum RequiresUpgradeReason {
  TRAIL_EXPIRED = "TRAIL_EXPIRED",
  SUBSCRIPTION_CANCELED = "SUBSCRIPTION_CANCELED",
}

export enum PlanTier {
  LAUNCH = "LAUNCH",
  BUILD = "BUILD",
  SCALE = "SCALE",
  DEFAULT = "DEFAULT",
}

export const tierIndex: { [key in PlanTier]: 0 | 1 | 2 | 3 } = {
  [PlanTier.LAUNCH]: 0,
  [PlanTier.BUILD]: 1,
  [PlanTier.SCALE]: 2,
  [PlanTier.DEFAULT]: 3,
};

export enum BillingPlan {
  UP_TO_5 = "UP_TO_5",
  UP_TO_15 = "UP_TO_15",
  UP_TO_30 = "UP_TO_30",
  UP_TO_50 = "UP_TO_50",
  UP_TO_75 = "UP_TO_75",
  UP_TO_100 = "UP_TO_100",
  UP_TO_200 = "UP_TO_200",
  UP_TO_INFINITY = "UP_TO_INFINITY",
  UP_TO_UNLIMITED = "UP_TO_UNLIMITED",
  PROMO = "PROMO",
  YEARLY_UP_TO_50 = "YEARLY_UP_TO_50",
  YEARLY_UP_TO_100 = "YEARLY_UP_TO_100",
  YEARLY_UP_TO_200 = "YEARLY_UP_TO_200",
  YEARLY_UNLIMITED = "YEARLY_UNLIMITED",
  // TODO remove
  STARTER = "STARTER",
  PRO = "PRO",
}

export const billingPlanPrice: Record<BillingPlan, number[]> = {
  [BillingPlan.UP_TO_5]: [20, 35, 115],
  [BillingPlan.UP_TO_15]: [40, 55, 135],
  [BillingPlan.UP_TO_30]: [60, 75, 155],
  [BillingPlan.UP_TO_50]: [95, 110, 190, 95],
  [BillingPlan.UP_TO_75]: [125, 140, 220],
  [BillingPlan.UP_TO_100]: [150, 165, 245, 180],
  [BillingPlan.UP_TO_200]: [195, 180, 290, 250],
  [BillingPlan.UP_TO_INFINITY]: [295, 310, 390],
  [BillingPlan.UP_TO_UNLIMITED]: [1, 1, 1, 310],
  [BillingPlan.PROMO]: [95, 110, 190, 95],
  [BillingPlan.YEARLY_UP_TO_50]: [1, 1, 1, 950],
  [BillingPlan.YEARLY_UP_TO_100]: [1, 1, 1, 1800],
  [BillingPlan.YEARLY_UP_TO_200]: [1, 1, 1, 2500],
  [BillingPlan.YEARLY_UNLIMITED]: [1, 1, 1, 3100],
  // TODO remove
  [BillingPlan.STARTER]: [1, 1, 1],
  [BillingPlan.PRO]: [1, 1, 1],
};

export const talkToConfig = {
  propertyId: "668274b7eaf3bd8d4d16bf17",
  widgetId: "1i1mpfrb2",
};

export const CONTACT_EMAIL = "hello@stridist.com";

export const PHONE_REGEX = /^\+[1-9]\d{7,14}$/;

export const SOMETHING_WENT_WRONG = "Something went wrong.";
export const PHONE_NUMBER_VERIFICATION_CODE_SENT =
  "Phone number verification code sent.";
export const PHONE_NUMBER_VERIFICATION_SUCCESS =
  "Phone number has been verified.";
export const PHONE_NUMBER_VERIFICATION_FAILURE =
  "Phone number verification failed.";

export enum CustomAssetType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  FILE = "FILE",
  COMPONENT_TEMPLATE = "COMPONENT_TEMPLATE",
  WORKOUT_EXERCISE = "WORKOUT_EXERCISE",
}

export const ComponentSlugs: Record<ComponentType, string> = {
  [ComponentType.CHECKIN]: "check-ins",
  [ComponentType.HABIT]: "habits",
  [ComponentType.LESSON]: "lessons",
  [ComponentType.WORKOUT]: "workouts",
  [ComponentType.MESSAGE]: "messages",
} as const;

export const componentSlugs = () => [...Object.values(ComponentSlugs)];
export const joinedComponentSlugs = componentSlugs().join("|");

export const defaultHomeScreenMessage = "Don't let anyone work harder than you";

export enum ClientPortalMode {
  WELCOME = "WELCOME",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
}

export enum ActivityType {
  MEAL_LOGGING = "MEAL_LOGGING",
  PROGRAM_COMPONENT = "PROGRAM_COMPONENT",
  CLIENT_FORM = "CLIENT_FORM",
}

export const activityResponseTypeLabels: Record<ActivityType, string> = {
  [ActivityType.PROGRAM_COMPONENT]: "Responses",
  [ActivityType.MEAL_LOGGING]: "Meal logs",
  [ActivityType.CLIENT_FORM]: "Forms",
};

export type AppError = {
  code: string;
  message: string;
};

export enum AcceptClientInviteInvalidReason {
  ANOTHER_USER_LOGGED = "ANOTHER_USER_LOGGED",
  INVITATION_ACCEPTED = "INVITATION_ACCEPTED",
  INVITATION_REVOKED = "INVITATION_REVOKED",
}

export const sevenDays = [...Array(7)].map((_, index) => index + 1);

export enum OnDays {
  EVERY_DAY = "EVERY DAY",
  DAY1 = "DAY 1",
  DAY2 = "DAY 2",
  DAY3 = "DAY 3",
  DAY4 = "DAY 4",
  DAY5 = "DAY 5",
  DAY6 = "DAY 6",
  DAY7 = "DAY 7",
  CUSTOM = "CUSTOM",
}

export enum ReminderType {
  AUTOMATIC = "AUTOMATIC",
  CUSTOM = "CUSTOM",
}

export enum MessageTimeType {
  MORNING = "MORNING",
  NOON = "NOON",
  AFTERNOON = "AFTERNOON",
  END_OF_DAY = "END OF DAY",
  CUSTOM = "CUSTOM",
}

export const messageTimeByType: Record<MessageTimeType, string> = {
  [MessageTimeType.MORNING]: "09:00",
  [MessageTimeType.NOON]: "12:00",
  [MessageTimeType.AFTERNOON]: "16:00",
  [MessageTimeType.END_OF_DAY]: "21:00",
  [MessageTimeType.CUSTOM]: "10:00",
};

export const mobilePromptPhotoAccess = (brandName: string) => {
  return `${brandName} needs access to your photos to enable certain features like meal logging, progress photos, and attaching photos to messages.`;
};

export enum AuthProvider {
  APPLE = "APPLE",
  GOOGLE = "GOOGLE",
  FIREBASE = "FIREBASE",
}

export const GoogleAuthScriptSrc = "https://apis.google.com/js/api:client.js";
export const AppleAuthScriptSrc =
  "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";

export const AppleAuthOptions = {
  clientId: "com.stridist.auth",
  scope: "name email",
  redirectURI: `https://${window.location.host}/auth/return`,
  usePopup: true,
};

export enum ResponsesView {
  SUMMARY = "summary",
  INDIVIDUAL = "individual",
}

export enum ClientSort {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",

  LAST_ACTIVE_ASC = "LAST_ACTIVE_ASC",
  LAST_ACTIVE_DESC = "LAST_ACTIVE_DESC",

  PROGRESS_ASC = "PROGRESS_ASC",
  PROGRESS_DESC = "PROGRESS_DESC",

  DATE_ADDED_ASC = "DATE_ADDED_ASC",
  DATE_ADDED_DESC = "DATE_ADDED_DESC",

  PROGRAM_END_ASC = "PROGRAM_END_ASC",
  PROGRAM_END_DESC = "PROGRAM_END_DESC",
}

export const clientSortOptions: Record<ClientSort, string> = {
  [ClientSort.NAME_ASC]: "Name (A-Z)",
  [ClientSort.NAME_DESC]: "Name (Z-A)",

  [ClientSort.LAST_ACTIVE_ASC]: "Last active (newest first)",
  [ClientSort.LAST_ACTIVE_DESC]: "Last active (oldest first)",

  [ClientSort.PROGRESS_ASC]: "Completion rate (high to low)",
  [ClientSort.PROGRESS_DESC]: "Completion rate (low to high)",

  [ClientSort.DATE_ADDED_DESC]: "Date added (newest first)",
  [ClientSort.DATE_ADDED_ASC]: "Date added (oldest first)",

  [ClientSort.PROGRAM_END_ASC]: "Due date programs (newest first)",
  [ClientSort.PROGRAM_END_DESC]: "Due date programs (oldest first)",
};

export type ProgramFilterFolder = number | "" | null;

export enum ProgramSort {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",

  DATE_ADDED_ASC = "DATE_ADDED_ASC",
  DATE_ADDED_DESC = "DATE_ADDED_DESC",
}

export const programSortOptions: Record<ProgramSort, string> = {
  [ClientSort.NAME_ASC]: "Name (A-Z)",
  [ClientSort.NAME_DESC]: "Name (Z-A)",

  [ClientSort.DATE_ADDED_ASC]: "Create date (oldest to newest)",
  [ClientSort.DATE_ADDED_DESC]: "Create date (newest to oldest)",
};

export const RATIONAL_NUMBER_REGEX = /^-?\d+([.,]{1}\d{1,10})?$/;

export enum Plan {
  UP_TO_5 = "UP_TO_5",
  UP_TO_15 = "UP_TO_15",
  UP_TO_30 = "UP_TO_30",
  UP_TO_50 = "UP_TO_50",
  UP_TO_75 = "UP_TO_75",
  UP_TO_100 = "UP_TO_100",
  UP_TO_200 = "UP_TO_200",
  UP_TO_INFINITY = "UP_TO_INFINITY",
  UP_TO_UNLIMITED = "UP_TO_UNLIMITED",
  FREE = "FREE",
  STARTER = "STARTER",
  PRO = "PRO",
  PROMO = "PROMO",
  YEARLY_UP_TO_50 = "YEARLY_UP_TO_50",
  YEARLY_UP_TO_100 = "YEARLY_UP_TO_100",
  YEARLY_UP_TO_200 = "YEARLY_UP_TO_200",
  YEARLY_UNLIMITED = "YEARLY_UNLIMITED",
}

export enum PlanSubscription {
  UP_TO_50 = "LAUNCH",
  UP_TO_100 = "BUILD",
  UP_TO_200 = "SCALE",
  UP_TO_UNLIMITED = "PREMIUM",
}

export function planClients(plan: Plan | null, numerical = false) {
  switch (plan) {
    case Plan.UP_TO_5:
      return numerical ? 5 : "5";
    case Plan.UP_TO_15:
      return numerical ? 15 : "15";
    case Plan.UP_TO_30:
      return numerical ? 30 : "30";
    case Plan.UP_TO_50:
      return numerical ? 50 : "50";
    case Plan.UP_TO_75:
      return numerical ? 75 : "75";
    case Plan.UP_TO_100:
      return numerical ? 100 : "100";
    case Plan.UP_TO_200:
      return numerical ? 200 : "200";
    case Plan.PROMO:
      return numerical ? 50 : "50";
    case Plan.YEARLY_UP_TO_50:
      return numerical ? 50 : "50";
    case Plan.YEARLY_UP_TO_100:
      return numerical ? 100 : "100";
    case Plan.YEARLY_UP_TO_200:
      return numerical ? 200 : "200";
    case Plan.UP_TO_INFINITY:
    case Plan.UP_TO_UNLIMITED:
    case Plan.YEARLY_UNLIMITED:
      return numerical ? Infinity : "unlimited";
    case Plan.FREE:
    case Plan.STARTER:
    case Plan.PRO:

    case null:
      return numerical ? Infinity : "unlimited";
    default:
      throw new Error(`Unknown plan ${plan}`);
  }
}

export const REACTIVATE_PROMO_CANCEL_BEFORE_DATE = new Date(
  "November 25, 2024",
);
export const REACTIVATE_PROMO_QUERY = "reactivated";

export enum ActivityStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum ActivitySort {
  COMPLETED_AT_DESC = "COMPLETED_AT_DESC",
  DATE_DESC = "DATE_DESC",
}

export const IsMobileAppModalShown = "is-mobile-app-modal-shown";

export enum ActivitySortLocalStorageParameter {
  FILTER = "coach-activity-filter-by-type",
  CLIENT = "coach-activity-filter-by-client",
  GROUP = "coach-activity-filter-by-group",
  SORT = "coach-activity-sort-by",
}

export const activitySortOptions: Record<ActivitySort, string> = {
  [ActivitySort.COMPLETED_AT_DESC]: "Date posted",
  [ActivitySort.DATE_DESC]: "Date scheduled",
};

export const defaultCoachActivityFilters = {
  [ActivityType.PROGRAM_COMPONENT]: true,
  [ActivityType.MEAL_LOGGING]: true,
  [ActivityType.CLIENT_FORM]: true,
};

export enum InviteCodeStartDateType {
  "IMMEDIATELY" = "IMMEDIATELY",
  "FIXED_DATE" = "FIXED_DATE",
}

export const round = (x: number, p = 0) => {
  const d = Math.pow(10, p);

  return Math.round(x * d) / d;
};

export const convertUnits = (
  unitType: UnitType,
  from: Units | string,
  to: Units | string,
  value: number,
) => {
  if (from === to) {
    return value;
  }

  switch (unitType) {
    case UnitType.WEIGHT:
      return to === Units.US || to === "LBS"
        ? round(2.205 * value)
        : round(value / 2.205, 1);

    case UnitType.LENGTH:
      return to === Units.US || to === "IN"
        ? round(value / 2.54, 1)
        : round(value * 2.54);

    case UnitType.PERCENTAGE:
    case UnitType.STEPS:
      return value;
  }
};

export const handleKeyDownOnFloatNumberNumberInput: any = (event) => {
  const invalid = !/[0-9.,]/.test(event.key);
  const isSpecialKey = event.key.length > 1;

  if (invalid && !isSpecialKey) {
    event.preventDefault();
  }
};

export const floatNumberInputProps = {
  onKeyDown: handleKeyDownOnFloatNumberNumberInput,
  min: 0,
  lang: "en-US",
  step: 0.1,
  pattern: "[0-9][0-9]*",
};

export const handleKeyDownOnWholeNumberNumberInput: any = (event) => {
  const invalid = !/[0-9]/.test(event.key);
  const isSpecialKey = event.key.length > 1;

  if (invalid && !isSpecialKey) {
    event.preventDefault();
  }
};

export const wholeNumberInputProps = {
  onKeyDown: handleKeyDownOnWholeNumberNumberInput,
  min: 0,
  lang: "en-US",
  step: 1,
  pattern: "[0-9]*",
};

export const nutritionTargetsArray = [
  {
    value: "TRAINING_DAYS",
    label: "Training Days",
  },
  {
    value: "OFF_DAYS",
    label: "Off Days",
  },
  {
    value: "REFEED_DAYS",
    label: "Refeed Days",
  },
  {
    value: "WEEKDAYS",
    label: "Weekdays",
  },
  {
    value: "WEEKENDS",
    label: "Weekends",
  },
  {
    value: "ALL_DAYS",
    label: "All Days",
  },
];

export const nutritionTrackingTypeArray = [
  {
    value: "MACROS",
    label: "Macros",
  },
  {
    value: "PORTIONS",
    label: "Portions",
  },
];

export const nutritionCommentsTypeArray = [
  {
    value: "FULL_MACROS",
    label: "Full Macros",
  },
  {
    value: "PROTEIN_CALORIES",
    label: "Protein + Calories",
  },
  {
    value: "CALORIES_ONLY",
    label: "Calories Only",
  },
  {
    value: "PROTEIN_ONLY",
    label: "Protein Only",
  },
  {
    value: "TRACKING_ONLY",
    label: "Tracking Only",
  },
];

export enum nutritionTrackingTypes {
  MACROS = "Macros",
  PORTIONS = "Portions",
}

export enum nutritionComments {
  FULL_MACROS = "FULL_MACROS",
  PROTEIN_CALORIES = "PROTEIN_CALORIES",
  CALORIES_ONLY = "CALORIES_ONLY",
  PROTEIN_ONLY = "PROTEIN_ONLY",
  TRACKING_ONLY = "TRACKING_ONLY",
}

export enum nutritionTargetsLabels {
  TRAINING_DAYS = "Training Days",
  OFF_DAYS = "Off Days",
  REFEED_DAYS = "Refeed Days",
  WEEKDAYS = "Weekdays",
  WEEKENDS = "Weekends",
  ALL_DAYS = "All Days",
}

export enum nutritionCommentsLabels {
  FULL_MACROS = "Full Macros",
  PROTEIN_CALORIES = "Protein + Calories",
  CALORIES_ONLY = "Calories Only",
  PROTEIN_ONLY = "Protein Only",
  TRACKING_ONLY = "Tracking Only",
}

export enum planStatus {
  TRIALING = "trialing",
  ACTIVE = "active",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}

export const planStatusLabel = {
  [planStatus.TRIALING]: "Trialing",
  [planStatus.ACTIVE]: "Active",
  [planStatus.INCOMPLETE]: "Incomplete",
  [planStatus.INCOMPLETE_EXPIRED]: "Incomplete expired",
  [planStatus.PAST_DUE]: "Past due",
  [planStatus.CANCELED]: "Canceled",
  [planStatus.UNPAID]: "Unpaid",
};

export enum ClientActivityStates {
  START = "start",
  SUMMARY = "summary",
  END = "end",
}

export enum LocalStorageKeys {
  TITLE = "title",
  FAVICON_LOGO_URL = "logoUrl",
  SHOW_SMALL_SCREEN_ALERT = "showSmallScreenAlert",
  IS_DRAWER_MINIMIZED = "isDrawerMinimized",
  LAST_CREATED_EXERCISE_CONFIG = "lastCreatedExerciseConfig",
}

export enum CommonElementId {
  COMPONENT_DIALOG = "component-dialog",
}

export enum RowType {
  // all ComponentType types
  LESSON = ComponentType.LESSON,
  HABIT = ComponentType.HABIT,
  CHECKIN = ComponentType.CHECKIN,
  WORKOUT = ComponentType.WORKOUT,
  MESSAGE = ComponentType.MESSAGE,

  // workout types
  WORKOUT_SECTION = "WORKOUT_SECTION",
  EXERCISE = "EXERCISE",
}

export enum WeekMoveDirection {
  DOWN = "DOWN",
  UP = "UP",
}

export type WeekMoveDirectionType = keyof typeof WeekMoveDirection;

export declare enum ProgramDefaultView {
  LIST = "LIST",
  CALENDAR = "CALENDAR",
  SPREADSHEET = "SPREADSHEET",
}
