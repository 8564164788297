import {
  AdminUserInfoDto,
  PaginatedListOfAdminUserInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import FbUsersService from "../../../services/FbUsersService";
import { ADMIN_USERS_QUERY_KEY } from "../UsersTable";
import { useQueryClient } from "@tanstack/react-query";
import { ADMIN_USER_QUERY_KEY } from "../UserPage";

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useOptimisticUpdateMutation({
    queryKey: [ADMIN_USERS_QUERY_KEY],
    mutationFn: FbUsersService.updateUser,
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfAdminUserInfoDto, newData) => {
        oldData.items = oldData.items.map((u) => {
          if (u.id === newData.id) {
            return new AdminUserInfoDto({ ...u, ...newData });
          }
          return u;
        });
        return oldData;
      },
    },
    options: {
      onSuccess: (_, vars) => {
        queryClient.setQueriesData(
          {
            queryKey: [ADMIN_USER_QUERY_KEY, { userId: vars.id }],
          },
          (oldData: AdminUserInfoDto) => {
            return new AdminUserInfoDto({ ...oldData, ...vars });
          },
        );
      },
    },
  });
};
