import clsx from "clsx";
import React from "react";
import {
  Menu,
  MenuProps,
  Divider,
  ListItemText,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  SelectComponentIcons,
  SelectComponentIconsProps,
} from "../program-component/SelectComponentIcons";
import { CurriculumComponent } from "../../redux/types";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface SelectComponentIconsMenuPropsBase
  extends Omit<MenuProps, "onSubmit">,
    Pick<SelectComponentIconsProps, "onSubmit" | "disableApplyToAll"> {}

export interface SelectComponentIconsMenuProps
  extends SelectComponentIconsMenuPropsBase {
  componentData: CurriculumComponent;
}

export function SelectComponentIconsMenu(props: SelectComponentIconsMenuProps) {
  const { className, onSubmit, disableApplyToAll, componentData, ...other } =
    props;
  const s = useStyles();

  return (
    <Menu className={clsx(s.root, className)} {...other}>
      <MenuItem disabled>
        <ListItemText primary="Select a custom icon" />
      </MenuItem>

      <Divider />
      <SelectComponentIcons
        componentData={componentData}
        onSubmit={onSubmit}
        disableApplyToAll={disableApplyToAll}
      />
    </Menu>
  );
}
