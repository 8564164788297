import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Divider,
  Skeleton,
  ListSubheader,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AssetType } from "../../constants";
import {
  LibrarySections,
  LibrarySection,
  defaultAssetTypes,
  LibraryAssetType,
} from "./constants";
import {
  ComponentLibraryAsset,
  ComponentLibraryAssetListMode,
} from "./ComponentLibraryAsset";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { useCustomAssets } from "./hooks/useCustomAssets";

const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: theme.spacing(2, 1),
  },
  list: {},
  divider: {
    backgroundColor: theme.palette.quote,
    margin: theme.spacing(0, -4, 0, 4),
  },

  subHeader: {
    textTransform: "uppercase",
    fontWeight: 700,
  },

  moreButton: {
    marginTop: theme.spacing(1.5),

    "$grid &": {
      gridColumn: "1 / -1",
    },
  },

  loading: {
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "$grid &": {
      gridColumn: "1 / -1",
    },
  },
  skeletonItem: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
}));

const FETCH_NEXT_PAGE_WHEN_REMAINING = 4;

export interface ComponentLibraryAssetListProps extends BoxProps {
  pageSize?: number;
  mode?: ComponentLibraryAssetListMode;
  query?: string;
  disableHeader?: boolean;
  assetTypes?: AssetType[];
}

export function ComponentLibraryAssetList(
  props: ComponentLibraryAssetListProps,
) {
  const {
    className,
    pageSize = 12,
    query = "",
    mode = ComponentLibraryAssetListMode.GRID,
    disableHeader = true,
    assetTypes = defaultAssetTypes,
    ...other
  } = props;

  const {
    data: customAssetsData,
    ref: componentListElementRef,
    isLoading: isLoadingInitial,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useCustomAssets({ query, assetTypes });
  const assets = customAssetsData?.pages.flatMap((page) => page.items) ?? [];

  const s = useStyles();

  const isGrid = mode === ComponentLibraryAssetListMode.GRID;

  const renderSkeletons = () => {
    const skeletonCount = isGrid ? 3 : 6;
    return Array.from(new Array(skeletonCount)).map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        className={s.skeletonItem}
        height={isGrid ? 200 : 50}
        width={"100%"}
      />
    ));
  };

  if (!assets.length && !isFetching) {
    return <Typography>Nothing found</Typography>;
  }

  return (
    <Box
      className={clsx(s.root, className, isGrid ? s.grid : s.list)}
      {...other}
    >
      {!disableHeader && assets.length > 0 && (
        <ListSubheader className={s.subHeader} disableGutters disableSticky>
          {LibrarySections[LibrarySection.ASSET_LIBRARY].name}
        </ListSubheader>
      )}
      {isLoadingInitial
        ? renderSkeletons()
        : assets.map((asset, index) => (
            <div key={asset.id}>
              {!isGrid && index !== 0 && <Divider className={s.divider} />}
              <ComponentLibraryAsset
                asset={{
                  id: asset.id!, // TODO_V2_API why response is nullable
                  assetType: asset.assetType! as unknown as LibraryAssetType,
                  content: asset.content!,
                }}
                mode={mode}
              />
              {index === assets.length - FETCH_NEXT_PAGE_WHEN_REMAINING && (
                <div ref={componentListElementRef} />
              )}
            </div>
          ))}

      {hasNextPage && (
        <LoadMoreButton
          onClick={() => fetchNextPage()}
          loading={isFetchingNextPage}
          disabled={isLoadingInitial}
        />
      )}
    </Box>
  );
}
