import React, { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AppLayout from "../../components/app/AppLayout";
import {
  CLIENT_ACTIVITY_ROUTE,
  CLIENT_HOME_ROUTE,
  CLIENT_PROFILE_ROUTE,
  CLIENT_SETTING_PROFILE_ROUTE,
  CLIENT_SETTING_ROUTE,
  GROUPS_ROUTE,
  HOME_ROUTE,
  MEAL_LOGGING_ROUTE,
  MESSAGES_ROUTE,
  PROGRAMS_ROUTE,
  SIGN_UP_CLIENT_COMPLETE,
} from "../../routes/routes";
import { groupNavigation } from "../../routes/coach/group/common";
import {
  ClientActivityStates,
  IsMobileAppModalShown,
  UserRole,
  defaultHomeScreenMessage,
} from "../../constants";
import {
  useCoachIsImpersonating,
  useCurrentUser,
  useCurrentUserRole,
} from "../../hooks/useCurrentUser";
import GroupContext, { TGroupRoute } from "../../contexts/GroupContext";
import { Skeleton } from "@mui/material";
import { GroupPostsAddButton } from "../../components/group-posts/GroupPostsAddButton";
import { programsNavigation } from "../../routes/client/programs/utils/common";
import { useCurrentWorkspace } from "../../hooks/useCurrentWorkspace";
import { clientSettingsNavigation } from "../../routes/client/settings/utils/common";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CompactDateDialogContext from "../../contexts/CompactDateDialogContext";
import { getISODate } from "../../utils/date";
import { NewMessageAction } from "../../components/messages/NewMessageAction";
import { ClientProfileActions } from "../../components/screen/ClientProfileScreen";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { FinishWorkoutModalContext } from "../../contexts/FinishWorkoutModalContext";
import dayjs from "dayjs";
import ProgramsContext from "../../contexts/ClientProgramsContext";
import {
  ClientEnrollmentDto,
  EnrollmentsStatusCountDto,
} from "@growth-machine-llc/stridist-api-client";
import { MobileAppModalContext } from "../../contexts/MobileAppModalContext";
import { getActionForGroup } from "./app-layout-helpers/common";
import { isClientSignUpComplete } from "../../utils/user";

const DEFAULT_CONFIG = {
  title: undefined,
  subtitle: <>&nbsp;</>,
  actions: [],
  tabsNavigation: undefined,
  appBar: true,
  appDrawer: true,
  filters: undefined,
  breadcrumbs: [{ name: "" }],
  disableSpeedDial: true,
  forceBackButton: false,
  appBarHideOnMobile: false,
};

const AppLayoutClient = () => {
  const location = useLocation();
  const { slug, program, date, component, status, recipient } = useParams();
  const user = useCurrentUser();
  const isCoachImpersonating = useCoachIsImpersonating();
  const { id: userId, role } = user;
  const workspace = useCurrentWorkspace();
  const navigate = useNavigate();
  const { unreadMessagesCount } = useCurrentUser();
  const isMobile = useMediaMobile("md", false);
  const { breakpoints } = useTheme();
  const mdSm = useMediaQuery(breakpoints.down("sm"));
  const [searchParams] = useSearchParams();
  const isWorkout = searchParams.get("workout");

  date === ":date" &&
    navigate(CLIENT_HOME_ROUTE.replace(":date", getISODate()));

  const PATHNAME_FIRST_SECTION = "/" + location.pathname.split("/")[1];
  const PATHNAME_LAST_SECTION = "/" + location.pathname.split("/").at(-1);

  const FORMATTED_DATE = date && dayjs(date).format("MMMM DD, YYYY");

  // LOADING CONTEXT
  const [loading, setLoading] = useState(true);

  const loadingContextValue = useMemo(
    () => ({ loading, setLoading }),
    [loading],
  );

  // GROUP CONTEXT
  const groupContextLoadingState = { name: "...", totalMembersCount: 0 };
  const [groupBriefInfo, setGroupBriefInfo] = useState<TGroupRoute>(
    groupContextLoadingState,
  );

  const groupContextValue = useMemo(
    () => ({
      groupBriefInfo,
      setGroupBriefInfo,
      resetGroupBriefInfo: () => setGroupBriefInfo(groupContextLoadingState),
    }),
    [groupBriefInfo],
  );

  const [programsData, setProgramsData] = useState<ClientEnrollmentDto[]>();
  const [enrollmentsCount, setEnrollmentsCount] =
    useState<EnrollmentsStatusCountDto>();

  const programsContextValue = useMemo(
    () => ({
      enrollmentsCount,
      setEnrollmentsCount,
    }),
    [enrollmentsCount],
  );
  // COMPACT DATA MODAL CONTEXT
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);

  const compactDateDialogContextValue = useMemo(
    () => ({ dateDialogOpen, setDateDialogOpen }),
    [dateDialogOpen],
  );

  // FINISH WORKOUT CONTEXT
  const [isFinishWorkoutModalOpen, setIsFinishWorkoutModalOpen] =
    React.useState(false);

  const finishWorkoutModalContextValue = useMemo(
    () => ({ isFinishWorkoutModalOpen, setIsFinishWorkoutModalOpen }),
    [isFinishWorkoutModalOpen],
  );
  const [isMobileAppModalShown, setIsMobileAppModalShown] =
    React.useState<boolean>(
      isCoachImpersonating
        ? false
        : window.localStorage.getItem(IsMobileAppModalShown) !== "false",
    );
  // CONFIGS
  const getConfig = () => {
    switch (PATHNAME_FIRST_SECTION) {
      case GROUPS_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: groupBriefInfo.name,
          subtitle: `${groupBriefInfo.totalMembersCount} member${
            groupBriefInfo.totalMembersCount === 1 ? "" : "s"
          }`,
          tabsNavigation: groupNavigation(slug, role as UserRole),
          actions: getActionForGroup({
            pathname: location.pathname,
            groupRouteQuery: groupBriefInfo,
            userId: userId,
            setManageGroupDialogOpen: () => {},
          }),
        };

      case PROGRAMS_ROUTE:
        switch (status) {
          case ClientActivityStates.START:
            return {
              ...DEFAULT_CONFIG,
              title: "Program",
              subtitle: "Start workout",
              appBar: !mdSm,
            };
          case ClientActivityStates.SUMMARY:
            return {
              ...DEFAULT_CONFIG,
              title: "Program",
              subtitle: "End workout",
            };
          case ClientActivityStates.END:
            return {
              ...DEFAULT_CONFIG,
              title: "Program",
              subtitle: "End workout",
              appBar: !mdSm,
            };
          default:
            if (isWorkout)
              return {
                ...DEFAULT_CONFIG,
                title: "Programs",
                subtitle: "During workout",
              };
            if (program && date && component)
              return {
                ...DEFAULT_CONFIG,
                appBar: false,
                appDrawer: false,
              };
            return {
              ...DEFAULT_CONFIG,
              title: "Programs",
              tabsNavigation: programsNavigation(
                enrollmentsCount?.active || 0,
                enrollmentsCount?.upcoming || 0,
                enrollmentsCount?.past || 0,
              ),
            };
        }
      case HOME_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Home",
          subtitle: workspace.message || defaultHomeScreenMessage,
          disableSpeedDial: false,
        };

      case CLIENT_SETTING_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "My Account",
          tabsNavigation: clientSettingsNavigation,
        };

      case CLIENT_ACTIVITY_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Activity",
          subtitle: "View and edit past responses.",
        };

      case MEAL_LOGGING_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Meals",
          subtitle: (
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => setDateDialogOpen(true)}
            >
              {FORMATTED_DATE}
            </Box>
          ),
          forceBackButton: true,
        };

      case MESSAGES_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: !isMobile ? "Messages" : null,
          subtitle: !isMobile
            ? `${unreadMessagesCount} new message${
                unreadMessagesCount === 1 ? "" : "s"
              }`
            : null,
          actions: !isMobile && [<NewMessageAction />],
          appBarHideOnMobile: recipient ? true : false,
        };

      case CLIENT_PROFILE_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Profile",
          subtitle: " ",
          actions: [
            ClientProfileActions(
              () => {
                setIsMobileAppModalShown(true);
                localStorage.setItem(IsMobileAppModalShown, "true");
              },
              () => navigate(CLIENT_SETTING_PROFILE_ROUTE),
              isMobile,
            ),
          ],
        };

      default:
        return { ...DEFAULT_CONFIG };
    }
  };

  const config = getConfig();

  useEffect(() => {
    if (user?.role === UserRole.CLIENT && !isClientSignUpComplete(user)) {
      navigate(SIGN_UP_CLIENT_COMPLETE);
    }
  }, [user]);

  useEffect(() => {
    setLoading(false);
  }, [location.pathname]);

  return (
    <GroupContext.Provider value={groupContextValue}>
      <ProgramsContext.Provider value={programsContextValue}>
        <CompactDateDialogContext.Provider
          value={compactDateDialogContextValue}
        >
          <FinishWorkoutModalContext.Provider
            value={finishWorkoutModalContextValue}
          >
            <MobileAppModalContext.Provider
              value={[isMobileAppModalShown, setIsMobileAppModalShown]}
            >
              <AppLayout
                title={
                  config.title === "..." || loading ? (
                    <Skeleton variant="text" width={500} />
                  ) : (
                    config.title
                  )
                }
                subtitle={
                  config.title === "..." || (config.subtitle && loading) ? (
                    <Skeleton variant="text" width={200} />
                  ) : (
                    config.subtitle
                  )
                }
                tabsNavigation={config.tabsNavigation}
                actions={config.actions}
                breadcrumbs={config.breadcrumbs}
                disableSpeedDial={config.disableSpeedDial}
                forceBackButton={config.forceBackButton}
                appBar={config.appBar}
                appDrawer={config.appDrawer}
                appBarHideOnMobile={config.appBarHideOnMobile}
              >
                <Outlet />
              </AppLayout>
            </MobileAppModalContext.Provider>
          </FinishWorkoutModalContext.Provider>
        </CompactDateDialogContext.Provider>
      </ProgramsContext.Provider>
    </GroupContext.Provider>
  );
};

export default AppLayoutClient;
