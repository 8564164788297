import clsx from "clsx";
import React from "react";
import { List, Typography, ListItem } from "@mui/material";
import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 0),
    padding: 0,
  },
  item: {
    display: "block",
    padding: 0,
    margin: theme.spacing(0, 0, 5, 0),
  },
  header: {
    fontSize: 16,
    margin: theme.spacing(0, 0, 2, 0),
    textTransform: "uppercase",
  },
  habitText: {
    float: "right",
    color: theme.palette.text.secondary,
  },
  compact: {
    "& $header": {
      color: theme.palette.primary.main,
    },
  },
}));

export function ActivityByTypesListSkeleton({ variant }: { variant?: string }) {
  const s = useStyles();

  const skeletonItems = Array.from({ length: 3 });

  return (
    <List
      className={clsx(s.root, {
        [s.compact]: variant === "compact",
      })}
    >
      <Skeleton
        animation="wave"
        width="30%"
        variant="text"
        className={s.header}
      />
      {skeletonItems.map((_, index) => (
        <ListItem key={index} className={s.item}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={70}
          />
        </ListItem>
      ))}
    </List>
  );
}
