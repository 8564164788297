import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { NoteEditor } from "../new-editor/NoteEditor";
import { isEmpty, isEmptyContent } from "../editor/utils/common";
import { EditorDialog, EditorDialogProps } from "../dialog/EditorDialog";
import { useBackupState } from "../../hooks/useLocalStorage";
import {
  ICreateUserNoteCommand,
  UserNoteContentDto,
} from "@growth-machine-llc/stridist-api-client";
import { UseMutateFunction } from "@tanstack/react-query";
import { useClient } from "../../hooks/useClient";
import useUpdateNoteMutation from "./mutations/UpdateNote";

const useStyles = makeStyles((theme) => ({
  root: {},

  container: {
    padding: 0,
    "& > :nth-child(1)": {
      margin: theme.spacing(0, 4),
    },
  },

  content: {
    padding: theme.spacing(1, 0),
    "& > :nth-child(2)": {
      margin: theme.spacing(0, 4),
    },
  },

  editor: {
    margin: theme.spacing(0, 4),
  },
}));

export interface UserNoteDialogProps extends EditorDialogProps {
  note?: UserNoteContentDto;
  createNote?: UseMutateFunction<number, unknown, ICreateUserNoteCommand, any>;
}

export function UserNoteDialog(props: UserNoteDialogProps) {
  const { className, note, createNote, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const originalContent = note ? JSON.parse(note.content) : [];
  const client = useClient();

  const { mutate: updateNote } = useUpdateNoteMutation();

  const [content, setContent, removeBackup] = useBackupState(
    "user-notes",
    note?.id,
    originalContent,
  );

  const handleSubmit = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const data = {
        id: note?.id,
        userId: client.id,
        content: JSON.stringify(content),
      };
      const options = {
        onSuccess: () => {
          removeBackup();
        },
      };
      if (note) {
        updateNote(data, options);
      } else {
        createNote(data, options);
      }
      onClose?.(event, "escapeKeyDown");
    },
    [content, note, onClose, removeBackup, createNote, client],
  );

  const isEmpty = isEmptyContent(content);

  return (
    <EditorDialog
      className={clsx(s.root, className)}
      classes={{
        container: s.container,
        content: s.content,
      }}
      title="Note"
      submitLabel="Save"
      onSubmit={handleSubmit}
      submitDisabled={isEmpty}
      {...other}
    >
      <NoteEditor className={s.editor} value={content} onChange={setContent} />
    </EditorDialog>
  );
}
