import { z } from "zod";
import { passwordRules } from "./password";

export type CoachSignupField =
  | "fullName"
  | "email"
  | "confirmEmail"
  | "password"
  | "addressLine1"
  | "addressLine2"
  | "country"
  | "city"
  | "postalCode";

export const stepFields: Record<number, CoachSignupField[]> = {
  1: ["fullName", "email", "confirmEmail", "password"],
  2: ["addressLine1", "addressLine2", "country", "city", "postalCode"],
};

export const coachSignupSchema = z
  .object({
    // Step 1
    fullName: z.string().min(1, "Full name is required"),
    email: z.string().email("Email is invalid"),
    confirmEmail: z.string(),
    password: z
      .string()
      .regex(
        passwordRules.lowercaseCharacter.regex,
        passwordRules.lowercaseCharacter.message,
      )
      .regex(
        passwordRules.uppercaseCharacter.regex,
        passwordRules.uppercaseCharacter.message,
      )
      .regex(
        passwordRules.numericCharacter.regex,
        passwordRules.numericCharacter.message,
      )
      .regex(
        passwordRules.specialCharacter.regex,
        passwordRules.specialCharacter.message,
      ),

    // Step 2
    addressLine1: z.string().min(1, "Address is required"),
    addressLine2: z.string().optional(),
    country: z.string().min(1, "Country is required"),
    city: z.string().min(1, "City is required"),
    postalCode: z.string().min(1, "Postal code is required"),
  })
  .refine(({ email, confirmEmail }) => email === confirmEmail, {
    message: "Emails do not match",
    path: ["confirmEmail"],
  });

export type CoachSignupSchema = z.infer<typeof coachSignupSchema>;
