import { clientFormsClient } from "../api/ApiClients";
import {
  DuplicateClientFormCommand,
  IDuplicateClientFormCommand,
  SubmitClientFormCommand,
  ISubmitClientFormCommand,
  IUpdateClientFormCommand,
  UpdateClientFormCommand,
  ISendClientFormBatchCommand,
  SendClientFormBatchCommand,
  ICreateClientFormCommand,
  CreateClientFormCommand,
} from "@growth-machine-llc/stridist-api-client";

import ServiceBase from "./ServiceBase";

class ClientFormsCoachService extends ServiceBase {
  async getForm(slug: string) {
    return clientFormsClient.getClientFormCoach(slug);
  }

  async getFormsList(page: number, pageSize: number) {
    return clientFormsClient.getClientFormCoachList(page, pageSize);
  }

  async createForm(data: ICreateClientFormCommand) {
    const command = CreateClientFormCommand.fromJS(data);
    return clientFormsClient.createClientForm(command);
  }

  async duplicateForm(id: number) {
    const data: IDuplicateClientFormCommand = { id };
    const command = new DuplicateClientFormCommand(data);
    return clientFormsClient.duplicateClientForm(command);
  }

  async deleteForm(id: number) {
    return clientFormsClient.removeClientForm(id);
  }

  async sendClientFormBatch(data: ISendClientFormBatchCommand) {
    const command = SendClientFormBatchCommand.fromJS(data);
    return clientFormsClient.sendClientFormBatch(command);
  }

  async submitForm(data: ISubmitClientFormCommand) {
    const command = new SubmitClientFormCommand(data);
    return clientFormsClient.submitClientForm(command);
  }

  async updateForm(data: IUpdateClientFormCommand) {
    const command = UpdateClientFormCommand.fromJS(data);
    return clientFormsClient.updateClientForm(data.id, command);
  }
}

export default new ClientFormsCoachService();
