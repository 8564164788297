import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { SendUsMessageLink } from "../../../components/app/SendUsMessageLink";
import makeStyles from "@mui/styles/makeStyles";
import { BRANDED_LOGIN_ROUTE, LOGIN_ROUTE } from "../../routes";
import { useLogout } from "../../../hooks/useLogout";
import { AppLogo } from "../../../components/app/AppLogo";
import AppLayout from "../../../components/app/AppLayout";
import {
  useCurrentBrand,
  useCurrentWorkspace,
} from "../../../hooks/useCurrentWorkspace";
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/auth/useAuth";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  logo: {
    marginBottom: theme.spacing(6),
    "& path": {
      fill: theme.palette.common.black,
    },
  },
  notFoundTitle: {
    fontSize: 32,
    fontWeight: "normal",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(6),
    textAlign: "center",
  },
  button: {
    fontSize: 13,
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.125, 4),
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),

    borderColor: theme.palette.common.black,
    borderWidth: 2,
    borderStyle: "solid",
    minWidth: theme.spacing(20),
  },
  split: {
    border: 0,
    borderTop: `1px solid ${theme.palette.border.primary}`,
    margin: theme.spacing(2, 0, 3),
    width: "100%",
  },
  notFoundText: {
    fontSize: 16,
    color: theme.palette.common.black,
  },
}));

export const LogoutRoute = () => {
  const s = useStyles();

  const workspace = useCurrentWorkspace();
  const { isBrandedApp } = useCurrentBrand();
  const returnUrl = isBrandedApp ? BRANDED_LOGIN_ROUTE : LOGIN_ROUTE;
  const { authorized } = useAuth();
  const navigate = useNavigate();
  const logout = useLogout({
    returnUrl,
  });

  if (!authorized) {
    return <Navigate to={returnUrl} />;
  }

  return (
    <AppLayout appBar={false} hideUpgradeBanner={true}>
      <Box className={s.wrapper}>
        <Box className={s.root}>
          <AppLogo className={s.logo} />
          <Typography
            className={s.notFoundTitle}
            variant="h2"
            children={
              <>
                Are you sure you <br /> want to leave?
              </>
            }
          />
          <Button
            className={s.button}
            variant="contained"
            children="Logout"
            onClick={() => {
              logout();
            }}
          />
          <Button
            variant="text"
            children="Back"
            sx={{ color: "black" }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <hr className={s.split} />
          <Typography className={s.notFoundText}>
            Need help? <SendUsMessageLink />
          </Typography>
        </Box>
      </Box>
    </AppLayout>
  );
};
