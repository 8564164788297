import { useMutation } from "@tanstack/react-query";
import { UseReactQueryPropsBase } from "../../../api/ReactQueryClient";
import SignUpService from "../../../services/SignUpService";
import BillingService from "../../../services/BillingService";

export const useCreateBillingAddressMutation = (
  props?: UseReactQueryPropsBase,
) => {
  return useMutation({
    mutationKey: ["create-billing-address"],
    mutationFn: BillingService.createBillingAddress,
    retry: 0,
    ...props,
  });
};

export const useUpdateBillingAddressMutation = (
  props?: UseReactQueryPropsBase,
) => {
  return useMutation({
    mutationKey: ["update-billing-address"],
    mutationFn: BillingService.updateBillingAddress,
    retry: 0,
    ...props,
  });
};
