import clsx from "clsx";
import React, { FormEvent } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { useAnalytics } from "../../hooks/useAnalytics";
import { ClientSettingsForm, ClientSettings } from "../form/ClientSettingsForm";
import {
  CompactContainer,
  CompactContainerProps,
} from "../container/CompactContainer";

import { ActionButton } from "../button/ActionButton";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  HOME_ROUTE,
  SIGN_UP_CLIENT_COMPLETE_PHOTO,
} from "../../routes/routes";
import { UserInfo } from "../../hooks/useCurrentUser";
import {
  Gender,
  IWorkspaceInfoDto,
  UnitSystem,
} from "@growth-machine-llc/stridist-api-client";
import { isClientSignUpComplete } from "../../utils/user";

import { useUpdateCurrentUserMutation } from "../../hooks/useUpdateCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {},

  submit: {
    margin: theme.spacing(2.5, 0, 3.5, 0),

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5.5, 0, 6.5, 0),
    },
  },
}));

export interface SignupClientScreenProps
  extends Omit<CompactContainerProps, "children"> {
  returnUrl?: string;
  user: UserInfo;
  workspace: IWorkspaceInfoDto;
}

export function SignupClientCompleteScreen(props: SignupClientScreenProps) {
  const s = useStyles();
  const navigate = useNavigate();
  const { className, user, workspace, returnUrl, ...other } = props;
  // TODO_API_V2 STR-1345: Fix settings type after this component is migrated. Replace any with ClientSettings
  const [settings, setSettings] = React.useState<ClientSettings>();
  const incomplete = user.displayName && user.username !== user.displayName;
  const [trackEvent] = useAnalytics();

  const { mutate: updateUser, isPending: signupInFlight } =
    useUpdateCurrentUserMutation();

  const { photoUrl: photoURL } = user;

  const disabled = signupInFlight;
  const completed = isClientSignUpComplete(settings);

  const handleSubmit = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      updateUser(
        {
          id: user.id,
          displayName: settings.displayName,
          height: settings.height,
          weight: settings.weight,
          units: settings.units as UnitSystem,
          location: settings.location,
          birthday: settings.birthday,
          gender: settings.gender as Gender,
        },
        {
          onSuccess() {
            navigate(photoURL ? HOME_ROUTE : SIGN_UP_CLIENT_COMPLETE_PHOTO);
            trackEvent("Client - Enter Info", settings);
          },
        },
      );
    },
    [photoURL, settings, trackEvent, updateUser, user.id],
  );

  const workspaceName = workspace.name || "Stridist";

  return (
    <CompactContainer
      className={clsx(s.root, className)}
      maxWidth="sm"
      title={incomplete ? "Welcome Back" : `Welcome to ${workspaceName}`}
      subtitle={
        incomplete
          ? "Please complete your missing profile details."
          : "First, tell us about yourself."
      }
      {...other}
    >
      <form onSubmit={handleSubmit}>
        <ClientSettingsForm
          user={user}
          onChange={setSettings}
          disabled={disabled}
          disableTimeZone
          isClient
        />

        <ActionButton
          className={s.submit}
          disabled={disabled || !completed}
          size="large"
          type="submit"
          fullWidth
        >
          Continue
        </ActionButton>
      </form>
    </CompactContainer>
  );
}
