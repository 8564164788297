import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import { selectComponentTempIdsMap } from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";

export default function* removeComponentWorker(payload : { weekId: number; componentId: number }) {
  const { componentId } = payload;
  const componentMap: TempIdsMap = yield select(selectComponentTempIdsMap);
  const checkedComponentId = componentMap?.[componentId] || componentId;

  // TODO_V2_API_CURRICULUM: check if weeks should be updated

  try {
    yield call(ComponentsService.remove, checkedComponentId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
