import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { Menu, MenuProps } from "../menu/Menu";
import { useDeleteCustomAsset } from "../component-library/hooks/useDeleteCustomAsset";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "999999 !important" as any,
  },
}));

export interface CustomAssetMenuProps extends MenuProps {
  assetId: number;
  deleteLabel?: React.ReactNode;
}

export function CustomAssetMenu(props: CustomAssetMenuProps) {
  const { className, onClose, assetId, deleteLabel, ...other } = props;
  const s = useStyles();
  const { mutate: deleteAsset } = useDeleteCustomAsset();

  const handleDeleteClick = React.useCallback(
    (event) => {
      deleteAsset(assetId);
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [assetId, onClose, deleteAsset],
  );

  return (
    <Menu
      className={clsx(s.root, className)}
      styleVariant="inverse"
      onClose={onClose}
      {...other}
    >
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon children={<RemoveIcon />} />
        <ListItemText primary={deleteLabel || "Delete asset"} />
      </MenuItem>
    </Menu>
  );
}
