import clsx from "clsx";
import React from "react";
import { ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LoadingButton from "./LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface LoadMoreButtonProps extends ButtonProps {
  loading?: boolean;
}

export function LoadMoreButton(props: LoadMoreButtonProps) {
  const {
    className,
    children = "Load more",
    variant = "text",
    ...other
  } = props;
  const s = useStyles();

  return (
    <LoadingButton
      className={clsx(s.root, className)}
      fullWidth
      variant={variant}
      {...other}
    >
      {children}
    </LoadingButton>
  );
}
