import React from "react";

import {
  ForgotPasswordFormProps,
  ForgotPasswordForm,
} from "./ForgotPasswordForm";
import { useMutation } from "@tanstack/react-query";
import SignUpService from "../../services/SignUpService";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

type State = {
  email: string;
  error?: string;
  sent: boolean;
};

type Props = {
  className?: string;
  email: string;
  workspaceSlug?: string;
  component?: React.FC<ForgotPasswordFormProps>;
  onCancel?: ForgotPasswordFormProps["onCancel"];
};

export function ForgotPassword(props: Props) {
  const {
    className,
    email,
    workspaceSlug,
    onCancel,
    component: Form = ForgotPasswordForm,
  } = props;

  const [state, setState] = React.useState<State>({
    email: email || "",
    error: null,
    sent: false,
  });

  const handleError = useGenericErrorHandler({
    setFieldError: (_, message) => {
      setState((prev) => ({
        ...prev,
        error: message,
      }));
    },
  });

  const { mutate: resetPassword, isPending: resetPasswordInFlight } =
    useMutation({
      mutationKey: ["reset-password"],
      mutationFn: SignUpService.resetPassword,
      onError: handleError,
    });

  function handleChange(event) {
    const { name, value } = event.target;
    setState((x) => (x.error === null ? x : { ...x, error: null }));
    setState((x) => (x[name] === value ? x : { ...x, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetPassword(
      { email: state.email, workspaceSlug },
      {
        onSuccess: () => {
          setState((x) => ({ ...x, sent: true }));
        },
      },
    );
  }

  function reset() {
    setState((x) => ({ ...x, sent: false }));
  }

  return (
    <Form
      className={className}
      state={state}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      onResend={reset}
      loading={resetPasswordInFlight}
    />
  );
}
