import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { MoreMenuButton } from "../button/MoreMenuButton";
import { NoteEditor } from "../new-editor/NoteEditor";
import { UserNoteMenu } from "../menu/UserNoteMenu";
import { UserNoteDialog } from "../user-notes/UserNoteDialog";
import { HeightOverflow } from "../container/HeightOverflow";

import useDependantKey from "../../hooks/useDependantKey";
import { UserNoteContentDto } from "@growth-machine-llc/stridist-api-client";
import useDeleteNoteMutation from "./mutations/DeleteNote";
import { formatDateToLongString, formatTimeToMilitary } from "../../utils/date";
import { Dayjs } from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(4, 3),
    boxShadow: theme.shadows[2],
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textTransform: "lowercase",
  },

  moreButton: {
    float: "right",
  },
}));

export interface UserNotesListItemProps extends BoxProps {
  note: UserNoteContentDto;
}

export function UserNotesListItem(props: UserNotesListItemProps) {
  const { className, note, ...other } = props;
  const s = useStyles();
  const { userId, created, content } = note;
  const value = React.useMemo(() => JSON.parse(content), [content]);
  const menuState = usePopupState({
    variant: "popover",
    popupId: "user-note-menu",
  });
  const [openDialog, setOpenDialog] = React.useState(false);
  const { mutate: removeNote } = useDeleteNoteMutation();

  const handleEditClick = React.useCallback(() => {
    menuState.close();
    setOpenDialog(true);
  }, [menuState]);

  const handleDeleteClick = React.useCallback(() => {
    menuState.close();
    removeNote(note.id);
  }, [menuState, note.id, removeNote]);

  const handleDialogClose = React.useCallback(() => {
    setOpenDialog(false);
  }, []);

  const noteEditorKey = useDependantKey(value);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <MoreMenuButton
        styleVariant="cardAction"
        className={s.moreButton}
        {...bindTrigger(menuState)}
      />
      <UserNoteMenu
        onEdit={handleEditClick}
        onDelete={handleDeleteClick}
        {...bindMenu(menuState)}
      />

      <Typography variant="h5" className={s.title}>
        {formatDateToLongString(created as Dayjs)}
      </Typography>
      <Typography variant="subtitle1" className={s.subtitle} gutterBottom>
        {formatTimeToMilitary(created as Dayjs)}
      </Typography>

      <HeightOverflow maxHeight={296} label="note">
        <NoteEditor key={noteEditorKey} value={value} readOnly />
      </HeightOverflow>
      {openDialog && (
        <UserNoteDialog
          open={openDialog}
          note={note}
          onClose={handleDialogClose}
        />
      )}
    </Box>
  );
}
