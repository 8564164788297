import clsx from "clsx";
import React from "react";
import { BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DropdownMenu, DropdownMenuItem } from "../menu/DropdownMenu";
import { colorSystem } from "../../theme";
import { ReactComponent as CalendarIcon } from "../../icons/CalendarDots.svg";
import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import { CompletionPeriod } from "../../constants";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  trigger: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "16px",
    color: theme.palette.common.black,
    backgroundColor: `${colorSystem.secondaryGray}4D`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 2),

    "& .MuiButton-startIcon": {
      marginLeft: 0,
    },

    "& .MuiButton-endIcon": {
      marginRight: 0,
    },
  },

  calendar: {
    marginLeft: 0,
  },

  caret: {
    width: 12,
  },
}));

const DATE_FORMAT = "MMM DD, YYYY";

type SelectablePeriod =
  | CompletionPeriod.LAST_7_DAYS
  | CompletionPeriod.LAST_30_DAYS
  | CompletionPeriod.BEFORE_NOW;

const DEFAULT_ITEM_LABELS: Record<SelectablePeriod, string> = {
  [CompletionPeriod.LAST_7_DAYS]: "Last 7 days",
  [CompletionPeriod.LAST_30_DAYS]: "Last 30 days",
  [CompletionPeriod.BEFORE_NOW]: "All time",
};

export interface PeriodFilterProps extends BoxProps {
  selectedPeriod?: CompletionPeriod;
  onPeriodChange?: DropdownMenuItem["onClick"];
  startDate?: string;
  itemLabels?: Partial<Record<SelectablePeriod, string>>;
}

export function PeriodFilter(props: PeriodFilterProps) {
  const {
    className,
    selectedPeriod = CompletionPeriod.ALL_TIME,
    onPeriodChange,
    startDate,
    itemLabels,
    ...other
  } = props;
  const s = useStyles();

  const labels: Record<SelectablePeriod, string> = React.useMemo(
    () => ({
      ...DEFAULT_ITEM_LABELS,
      ...(itemLabels ? itemLabels : {}),
    }),
    [itemLabels],
  );

  const items: DropdownMenuItem[] = React.useMemo(
    () =>
      Object.keys(labels).map((period) => ({
        children: labels[period],
        onClick: onPeriodChange,
        selected: period === selectedPeriod,
        data: { period },
      })),
    [labels, onPeriodChange, selectedPeriod],
  );

  const selectedPeriodLabel = React.useMemo(() => {
    const end = dayjs.utc().format(DATE_FORMAT);

    switch (selectedPeriod) {
      case CompletionPeriod.LAST_7_DAYS:
        return `${dayjs
          .utc()
          .subtract(6, "days")
          .format(DATE_FORMAT)} - ${end}`;
      case CompletionPeriod.LAST_30_DAYS:
        return `${dayjs
          .utc()
          .subtract(29, "days")
          .format(DATE_FORMAT)} - ${end}`;
      case CompletionPeriod.BEFORE_NOW:
        return startDate
          ? `${dayjs.utc(startDate).format(DATE_FORMAT)} - ${end}`
          : "All time";
    }
  }, [selectedPeriod, startDate]);

  const triggerGenerator = React.useMemo(
    () => (bindTrigger, menuState) => (
      <Button
        className={s.trigger}
        startIcon={<CalendarIcon className={s.calendar} />}
        endIcon={<CaretDownIcon className={s.caret} />}
        children={selectedPeriodLabel}
        {...bindTrigger(menuState)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPeriodLabel],
  );

  return (
    <DropdownMenu
      className={clsx(s.root, className)}
      menuId="select-progress-period"
      items={items}
      triggerGenerator={triggerGenerator}
      {...other}
    />
  );
}
