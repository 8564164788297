import { Card, CardProps, Box, CardContent } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { SkeletonUpdcomingPastProgramCartHeader } from "./UpcomingProgramSkeletonCard";

export interface PastProgramSkeletonCardProps extends CardProps {}

export function PastProgramSkeletonCard(props: PastProgramSkeletonCardProps) {
  return (
    <Card {...props}>
      <SkeletonUpdcomingPastProgramCartHeader />
      <CardContent sx={{ pl: "20px !important" }}>
        <Box>
          <Skeleton animation="wave" height={20} />
        </Box>
      </CardContent>
    </Card>
  );
}
