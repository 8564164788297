import { storageClient } from "../api/ApiClients";
import {
  CreateSignedUrlCommand,
  ICreateSignedUrlCommand,
  ITranscodeVideoCommand,
  TranscodeVideoCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class StorageService extends ServiceBase {
  async createSignedUrl(variables: ICreateSignedUrlCommand) {
    const command = new CreateSignedUrlCommand(variables);
    return storageClient.createSignedUrl(command);
  }

  async transcodeVideo(variables: ITranscodeVideoCommand) {
    const command = new TranscodeVideoCommand(variables);
    return storageClient.transcodeVideo(command);
  }
}

export default new StorageService();
