import ClientGoalsClientService from "../../../services/ClientGoalsClientService";
import {
  IUpdateCurrentValueClientGoalCommand,
  PaginatedListOfClientGoalDto,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { CLIENT_GOALS_LIST_QUERY_KEY } from "../../card/ClientGoalsCard";

export default function useUpdateCurrentGoalValueMutation(clientId: number) {
  return useOptimisticUpdateMutation({
    queryKey: [CLIENT_GOALS_LIST_QUERY_KEY, { clientId }],
    mutationFn: ClientGoalsClientService.updateCurrentGoalValue,
    optimisticUpdater: {
      updateFn: (
        store: PaginatedListOfClientGoalDto,
        variables: IUpdateCurrentValueClientGoalCommand,
      ) => {
        const goalIndex = store.items?.findIndex(
          (clientGoal) => clientGoal.id === variables.goalId,
        );
        if (goalIndex !== undefined && goalIndex !== -1) {
          store.items[goalIndex].currentValue =
            variables.currentValue.toString();
        }
        return store;
      },
    },
  });
}
