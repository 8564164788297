import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { UserNotesListItem } from "./UserNotesListItem";
import { UserNoteContentDto } from "@growth-machine-llc/stridist-api-client";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";
import { TInfiniteScrollContainerRef } from "../../hooks/useInfiniteScroll";
import { LoadMoreButton } from "../button/LoadMoreButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginBottom: theme.spacing(4),
  },
}));

export interface UserNotesListProps extends BoxProps {
  notes: UserNoteContentDto[];
  fetchRef: TInfiniteScrollContainerRef;
  fetchMore: () => void;
  loading: boolean;
  hasNext: boolean;
}

const FETCH_NOTES_WHEN_REMAINING = 3;

export function UserNotesList(props: UserNotesListProps) {
  const { className, notes, hasNext, loading, fetchMore, fetchRef, ...other } =
    props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {notes.map((note, i) => (
        <>
          {i == notes.length - FETCH_NOTES_WHEN_REMAINING && (
            <div key={i} ref={fetchRef} />
          )}
          <OptimisticUpdateContainer id={note.id} key={note.id}>
            <UserNotesListItem className={s.card} note={note} />
          </OptimisticUpdateContainer>
        </>
      ))}

      {hasNext && (
        <LoadMoreButton onClick={fetchMore} loading={loading}>
          Show older notes
        </LoadMoreButton>
      )}
    </Box>
  );
}
