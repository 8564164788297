import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  FormLabel,
  FormControl,
  TextField,
  SelectProps,
  TextFieldProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SchemaElements } from "../editor/normalizers/withSchema";

import {
  MealLoggingQuestion,
  MealLoggingQuestionType,
  MealLoggingOption,
} from "./types";
import { MealLoggingQuestionSelectType } from "./MealLoggingQuestionSelectType";
import { MealLoggingQuestionMultipleChoiceList } from "./MealLoggingQuestionMultipleChoiceList";
import { MEAL_LOGGING_OPTIONS_OTHER } from "./constants";
import { normalizeQuestion, sortMealLoggingOptions } from "./utils";
import { MealLoggingQuestionCheckboxesList } from "./MealLoggingQuestionCheckboxesList";
import { MealLoggingQuestionOptionsListItemProps } from "./MealLoggingQuestionOptionsListItem";
import {
  MealLoggingQuestionNutritionOptions,
  MealLoggingQuestionNutritionOptionsProps,
} from "./MealLoggingQuestionNutritionOptions";

const useStyles = makeStyles((theme) => ({
  root: {},

  field: {
    marginBottom: theme.spacing(2.5),

    "& label": {
      fontSize: 16,
      fontWeight: 700,
      textTransform: "uppercase",
      margin: theme.spacing(2, 0),
    },
  },
}));

export interface MealLoggingQuestionEditProps extends BoxProps {
  question: MealLoggingQuestion;
  onUpdate?: (question: MealLoggingQuestion) => void;
}

export function MealLoggingQuestionEdit(props: MealLoggingQuestionEditProps) {
  const { className, question, onUpdate, ...other } = props;
  const s = useStyles();
  const [settings, setSettings] = React.useState(normalizeQuestion(question));

  const handleUpdate = React.useCallback(
    (question: MealLoggingQuestion) => {
      setSettings(normalizeQuestion(question));

      if (onUpdate) {
        onUpdate(normalizeQuestion(question, false));
      }
    },
    [onUpdate],
  );

  const handleTypeChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      const type = event.target.value as MealLoggingQuestionType;

      handleUpdate({
        ...settings,
        type,
      } as MealLoggingQuestion);
    },
    [handleUpdate, settings],
  );

  const handleTextChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      const text = event.currentTarget.value;

      handleUpdate({
        ...settings,
        text,
      });
    },
    [handleUpdate, settings],
  );

  const handleOptionsUpdate = React.useCallback(
    (options: MealLoggingOption[]) => {
      if (
        settings.type === MealLoggingQuestionType.MULTIPLE_CHOICE ||
        settings.type === MealLoggingQuestionType.CHECKBOXES
      ) {
        handleUpdate({
          ...settings,
          options: sortMealLoggingOptions(options),
        });
      }
    },
    [handleUpdate, settings],
  );

  const handleOptionsAction: MealLoggingQuestionOptionsListItemProps["onAction"] =
    React.useCallback(
      (event, option) => {
        const {
          dataset: { action },
        } = event.currentTarget;

        if (
          settings.type === MealLoggingQuestionType.MULTIPLE_CHOICE ||
          settings.type === MealLoggingQuestionType.CHECKBOXES
        ) {
          switch (action) {
            case "remove":
              handleOptionsUpdate(
                settings.options.filter((it) => it.id !== option.id),
              );
              break;

            case "change-text":
              const text = (event.currentTarget as any).value;

              handleOptionsUpdate(
                settings.options.map((it) =>
                  it.id === option.id ? { ...it, text } : it,
                ),
              );
              break;

            case "add-other":
              handleOptionsUpdate(
                settings.options.map((it) =>
                  it.id === option.id
                    ? {
                        id: SchemaElements.generateId(),
                        text: MEAL_LOGGING_OPTIONS_OTHER,
                        prompt: true,
                      }
                    : it,
                ),
              );
              break;

            default:
              throw new Error(`Unknown action "${action}"`);
          }
        }
      },
      [handleOptionsUpdate, settings],
    );

  const handleNutritionOptionsUpdate: MealLoggingQuestionNutritionOptionsProps["onUpdate"] =
    React.useCallback(
      (trackingType, nutrientTypes) => {
        if (settings.type === MealLoggingQuestionType.NUTRITION) {
          handleUpdate({
            ...settings,
            trackingType,
            nutrientTypes,
          });
        }
      },
      [handleUpdate, settings],
    );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <FormControl className={s.field} fullWidth>
        <FormLabel htmlFor="question-type">Question type</FormLabel>
        <MealLoggingQuestionSelectType
          id="question-type"
          variant="outlined"
          value={settings.type}
          onChange={handleTypeChange}
        />
      </FormControl>

      {settings.type !== MealLoggingQuestionType.NUTRITION && (
        <FormControl className={s.field} fullWidth>
          <FormLabel htmlFor="question-text">Question text</FormLabel>
          <TextField
            id="question-text"
            variant="outlined"
            value={settings.text}
            onChange={handleTextChange}
          />
        </FormControl>
      )}

      {settings.type === MealLoggingQuestionType.MULTIPLE_CHOICE && (
        <MealLoggingQuestionMultipleChoiceList
          options={settings.options}
          onUpdate={handleOptionsUpdate}
          ListItemProps={{
            onAction: handleOptionsAction,
            hasOther: settings.options.some(({ prompt }) => prompt),
          }}
        />
      )}

      {settings.type === MealLoggingQuestionType.CHECKBOXES && (
        <MealLoggingQuestionCheckboxesList
          options={settings.options}
          onUpdate={handleOptionsUpdate}
          ListItemProps={{
            onAction: handleOptionsAction,
            hasOther: settings.options.some(({ prompt }) => prompt),
          }}
        />
      )}

      {settings.type === MealLoggingQuestionType.NUTRITION && (
        <MealLoggingQuestionNutritionOptions
          trackingType={settings.trackingType}
          nutrientTypes={settings.nutrientTypes}
          onUpdate={handleNutritionOptionsUpdate}
        />
      )}
    </Box>
  );
}
