import { ProgramSettingsDto } from "@growth-machine-llc/stridist-api-client";
import { PROGRAM_SETTINGS_QUERY_KEY } from "../../../../routes/coach/program/settings/CoachProgramSettingsRoute";
import InviteCodesService from "../../../../services/InviteCodesService";
import { useOptimisticUpdateMutation } from "../../../../hooks/useOptimisticUpdateMutation";

interface DeleteInviteCodeMutationProps {
  slugId: string;
}

export const useDeleteInviteCodeMutation = ({
  slugId,
}: DeleteInviteCodeMutationProps) => {
  return useOptimisticUpdateMutation({
    queryKey: [PROGRAM_SETTINGS_QUERY_KEY, { slugId }],
    mutationFn: InviteCodesService.deleteCode,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: ProgramSettingsDto) =>
        new ProgramSettingsDto({
          ...oldData,
          inviteCode: undefined,
        }),
    },
  });
};
