import clsx from "clsx";
import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardProps,
  Checkbox,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  bindMenu,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { ArrowForwardRounded, MoreHoriz } from "@mui/icons-material";

import { useSwitchUser } from "../../hooks/useSwitchUser";

import { CardAvatar } from "./CardAvatar";
import { getTodayDaysDiff } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import {
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_CLIENT_SETTINGS_ROUTE,
  COACH_MESSAGE_ROUTE,
} from "../../routes/routes";
import {
  ClientInfoDto,
  ClientStatsDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";
import { ClientStats } from "./ClientStats";
import useArchiveClientsMutation from "../coach-clients/mutations/useArchiveClientsMutation";
import ClientContextMenu from "../coach-clients/ClientContextMenu";
import useDeleteClientsMutation from "../coach-clients/mutations/useDeleteClientsMutation";

const useStyles = makeStyles((theme) => ({
  root: {},

  selected: {
    "& $avatar": {
      display: "none",
    },
    "& $checkbox": {
      display: "block",
    },
  },

  selectable: {
    "&:hover $avatar": {
      display: "none",
    },
    "&:hover $checkbox": {
      display: "block",
    },
  },

  actionArea: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    "& > div": {
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",

      "& > div": {
        marginBottom: 0,
      },
    },
  },

  actionAreaArchived: {
    padding: theme.spacing(3),
    justifyContent: "space-between",
  },

  header: {
    padding: 0,
    width: "100%",

    "& .MuiCardHeader-avatar": {
      marginRight: theme.spacing(1),
    },
    "& .MuiCardHeader-content": {
      overflow: "hidden",
    },

    [theme.breakpoints.up("md")]: {
      width: "28%",
    },
  },

  headerArchived: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 300,
      width: "30%",
    },
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.black,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(2.5),
  },

  text: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },

  checkbox: {
    width: 40,
    height: 40,
    display: "none",
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(1),

    "& span:nth-child(1)": {
      overflow: "hidden",
      borderRadius: theme.spacing(0.5),
    },

    "& svg": {
      width: "30px",
      height: "30px",
      marginTop: theme.spacing(-0.5),
      marginLeft: theme.spacing(-0.5),
      borderRadius: "6px",
    },

    "&:hover": {
      backgroundColor: `${theme.palette.quote} !important`,
    },

    "&:hover svg": {
      backgroundColor: theme.palette.background.paper,
    },

    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(0),
    },
  },

  email: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  weight: {
    flexShrink: 0,
  },

  chip: {
    fontWeight: "bold",
    fontSize: 11,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(1, 4.5),
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  chipEndPrograms: {
    fontWeight: 600,
    fontSize: 12,
    padding: theme.spacing(0),
    width: 110,
    height: 24,
    backgroundColor: theme.palette.progress.red,
    lineHeight: "13px",
  },
  chipInvited: {
    backgroundColor: theme.palette.activity.habit,
  },
  chipArchived: {
    backgroundColor: theme.palette.secondary.main,
  },
  tag: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "16px",
    width: "17%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: theme.spacing(0, 1.5),
    [theme.breakpoints.down("md")]: {
      width: "auto",
      padding: theme.spacing(0, 0, 1.5, 0),
    },
  },
  noVisibility: {
    visibility: "hidden",
  },
  statsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  endProgramsGreen: {
    backgroundColor: theme.palette.progress.green,
  },
  endProgramsYellow: {
    backgroundColor: theme.palette.progress.yellow,
  },
}));

export interface ClientCardProps extends Omit<CardProps, "onSelect"> {
  client: ClientInfoDto;
  stats: ClientStatsDto;
  selectable?: boolean;
  selected?: boolean;
  isSample: boolean;
  onSelect?: (clientId: number, selected: boolean) => void;
}

export function ClientCard(props: ClientCardProps) {
  const navigate = useNavigate();
  const {
    className,
    client,
    selectable,
    stats,
    onSelect,
    selected,
    isSample,
    ...other
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const userId = client.id;
  const isActive = client.status === UserInviteStatus.ACTIVE;
  const name = client.displayName || client.email;
  // TODO_API_V2 STR-1338: Currently backend supports multiple tags, but frontend only displays one, discuss updating frontend to support multiple tags
  const tag = client?.tags?.[0]?.title || "";
  const link = COACH_CLIENT_OVERVIEW_ROUTE.replace(
    ":username",
    client.username,
  );

  const { mutate: toggleArchiveClients, isPending: archivingClients } =
    useArchiveClientsMutation([client.status]);

  const { mutate: deleteArchivedClient, isPending: deletingClient } =
    useDeleteClientsMutation();

  const dateProgram =
    client.endPrograms &&
    dayjs(client.endPrograms, "YYYY-MM-DD").format("MMM DD, YYYY");

  const daysDiff = React.useMemo(() => {
    return (
      client.endPrograms &&
      getTodayDaysDiff(dayjs(client.endPrograms, "YYYY-MM-DD").toISOString())
    );
  }, [client.endPrograms]);

  const handleSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (onSelect) {
        const clientId = event.currentTarget.value;

        onSelect(parseInt(clientId), checked);
      }
    },
    [onSelect],
  );

  const handleStopPropagation = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
      event.stopPropagation(),
    [],
  );

  const handleCardClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => navigate(link),
    [link],
  );

  const handleArchive = React.useCallback(
    (archived: boolean) => {
      toggleArchiveClients({ clients: [client], archived: archived });
    },
    [toggleArchiveClients, userId],
  );

  const handleDelete = React.useCallback(() => {
    deleteArchivedClient({ clientIds: [userId] });
  }, [deleteArchivedClient, userId]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreClick = React.useCallback(
    (event) => {
      event?.preventDefault?.();
      event?.stopPropagation?.();

      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl],
  );

  const actionsMenuButton = (
    <IconButton onClick={handleMoreClick} size="large">
      <MoreHoriz />
    </IconButton>
  );

  return (
    <Card
      className={clsx(
        s.root,
        className,
        selectable && s.selectable,
        selected && s.selected,
      )}
      {...other}
    >
      <CardActionArea
        className={clsx(
          s.actionArea,
          client.status === UserInviteStatus.ARCHIVED && s.actionAreaArchived,
        )}
        onClick={handleCardClick}
      >
        <CardHeader
          className={clsx(
            s.header,
            client.status === UserInviteStatus.ARCHIVED && s.headerArchived,
          )}
          avatar={
            <>
              <CardAvatar className={s.avatar} alt={name} src={client.photoUrl}>
                {name[0]?.toUpperCase()}
              </CardAvatar>
              {selectable && (
                <Checkbox
                  className={s.checkbox}
                  checked={selected}
                  value={client.id}
                  onChange={handleSelect}
                  onClick={handleStopPropagation}
                />
              )}
            </>
          }
          title={name}
          titleTypographyProps={{
            variant: "h5",
            className: s.title,
          }}
          action={!mdUp && actionsMenuButton}
        />

        {isActive && (
          <>
            <Typography className={s.tag} children={tag} />
            <Chip
              className={clsx(
                s.chip,
                s.chipEndPrograms,
                !client.endPrograms && s.noVisibility,
                daysDiff === 1 && s.endProgramsYellow,
                daysDiff > 1 && s.endProgramsGreen,
              )}
              label={dateProgram}
            />

            <Box className={s.statsContainer}>
              <ClientStats stats={stats} />
            </Box>
          </>
        )}
        {client.status === UserInviteStatus.ARCHIVED && (
          <Chip className={clsx(s.chip, s.chipArchived)} label="Archived" />
        )}
        {mdUp && actionsMenuButton}
        {mdUp && <ArrowForwardRounded />}
      </CardActionArea>
      <ClientContextMenu
        anchorEl={anchorEl}
        handleClose={handleMoreClick}
        user={client}
        archivingClient={archivingClients}
        onArchivedChange={handleArchive}
        deletingClient={deletingClient}
        onDelete={handleDelete}
        isSample={isSample}
      />
    </Card>
  );
}
