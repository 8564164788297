import clsx from "clsx";
import React from "react";
import {
  ListItem,
  ListItemProps,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { Avatar } from "../avatar/Avatar";
import { MoreMenuButton } from "../button/MoreMenuButton";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { GroupMemberMenu } from "./GroupMemberMenu";
import { useNavigate } from "react-router-dom";
import { COACH_CLIENT_OVERVIEW_ROUTE } from "../../routes/routes";
import { GroupMemberDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },

  divider: {
    borderBottomColor: theme.palette.quote,
  },

  itemAvatar: {
    minWidth: theme.spacing(6),

    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(7),
    },
  },

  avatar: {
    margin: theme.spacing(0, 0, 0, 0),

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: theme.spacing(0, 2, 0, 0),
      fontSize: 18,
    },
  },

  primaryText: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },

  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
}));

export interface GroupMemberListItemProps extends ListItemProps {
  groupId: number;
  member: GroupMemberDto;
}

export function GroupMemberListItem(props: GroupMemberListItemProps) {
  const { className, groupId, member, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();
  const canEdit = user.role === UserRole.COACH;
  const menuState = usePopupState({
    variant: "popover",
    popupId: "group-member-menu",
  });
  const { isOpen } = menuState;

  const handleClick = React.useCallback(() => {
    if (canEdit) {
      navigate(
        COACH_CLIENT_OVERVIEW_ROUTE.replace(":username", member.username),
      );
    }
  }, [canEdit, member.username]);

  return (
    <ListItem
      classes={{
        root: clsx(s.root, className),
        divider: s.divider,
      }}
      button={canEdit}
      onClick={handleClick}
      {...(other as any)}
    >
      <ListItemAvatar className={s.itemAvatar}>
        <Avatar
          className={s.avatar}
          displayName={member.displayName}
          photoURL={member.photoUrl}
        />
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: s.primaryText,
          secondary: s.secondaryText,
        }}
        primary={member.displayName}
        secondary={canEdit && member.email}
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <MoreMenuButton
            styleVariant="cardAction"
            {...bindTrigger(menuState)}
          />
          {isOpen && (
            <GroupMemberMenu
              groupId={groupId}
              memberId={member.id}
              {...bindMenu(menuState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
