import React from "react";
import { DialogProps, Box, Button, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import art from "../../../icons/camera-art.svg";
import { UploadDialog } from "../../dialog/UploadDialog";
import {
  DropzoneUploadButton,
  DropzoneUploadButtonProps,
} from "../../button/DropzoneUploadButton";
import { AssetType } from "../../../constants";
import { colorSystem } from "../../../theme";

import { useCurrentUserId } from "../../../hooks/useCurrentUser";
import { useMediaMobile } from "../../../hooks/useMediaMobile";

import { getMimeTypes } from "../../../utils/file";
import { ElementType } from "../../editor/types/elements";
import Camera from "../../../icons/Camera";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FbUsersService from "../../../services/FbUsersService";
import LoadingActionButton from "../../button/LoadingActionButton";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { UserInfoDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  frame: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    backgroundColor: colorSystem.white2,
  },

  photo: {
    maxHeight: 218,
    maxWidth: "100%",
    width: "auto",
    borderRadius: theme.spacing(1),
  },

  art: {
    maxWidth: "100%",
  },

  uploadButton: {
    [theme.breakpoints.up("sm")]: {
      border: "none",
      backgroundColor: "transparent",
    },
  },

  text: {
    color: theme.palette.primary.main,
  },

  saveButton: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    padding: theme.spacing(2.25),
    marginTop: theme.spacing(5),
  },
}));

// export interface UploadProfilePhotoDialogProps
//   extends Omit<DialogProps, "onChange">,
//     Pick<DropzoneUploadButtonProps, "onChange">,
//     Pick<UploadDialogProps, "onSkipClick" | "noSkip"> {
//   onSaved: () => void;
// }

export function UploadProfilePhotoDialog(props: any) {
  const { className, onSaved, onChange, ...other } = props;
  const s = useStyles();
  const [src, setSrc] = React.useState("");
  const userId = useCurrentUserId();
  const isMobile = useMediaMobile("sm");
  const theme = useTheme();
  const { mutate: updateProfilePhoto, isPending: isUpdatingProfilePhoto } =
    useMutation({
      mutationKey: ["update-profile-photo"],
      mutationFn: FbUsersService.updateUser,
    });
  const handleChange = React.useCallback((photoURL: string) => {
    setSrc(photoURL);
  }, []);
  const queryClient = useQueryClient();

  const handleSaveClick = React.useCallback(() => {
    if (!isUpdatingProfilePhoto && userId) {
      updateProfilePhoto(
        {
          id: userId,
          photoUrl: src,
        },
        {
          onSuccess: (_, variables) => {
            if (onSaved) {
              onSaved();
            }
            queryClient.setQueryData(
              [CURRENT_USER_QUERY_KEY],
              (prev: UserInfoDto) => {
                return {
                  ...prev,
                  photoUrl: variables.photoUrl,
                  accounts: prev.accounts.map((account) =>
                    account.id === userId
                      ? { ...account, photoUrl: variables.photoUrl }
                      : account,
                  ),
                };
              },
            );
          },
        },
      );
    }
  }, [isUpdatingProfilePhoto, onSaved, src, updateProfilePhoto, userId]);

  const content = React.useMemo(
    () =>
      src ? (
        <Box className={s.frame}>
          <img className={s.photo} src={src} alt="" />
        </Box>
      ) : (
        <Camera className={s.art} fill={theme.palette.primary.main}></Camera>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [src],
  );

  const action = React.useMemo(
    () => (
      <>
        <DropzoneUploadButton
          className={src && s.uploadButton}
          variant={src ? "text" : "outlined"}
          onChange={handleChange}
          assetType={AssetType.USER_PHOTO}
          mimeType={getMimeTypes(ElementType.IMAGE)}
          inputName="photoURL"
          assetDescription="a profile photo"
          text={
            isMobile
              ? "Upload a profile photo"
              : src
                ? "Upload another image"
                : undefined
          }
          textClassName={s.text}
        />

        {src && (
          <LoadingActionButton
            className={s.saveButton}
            fullWidth
            variant="contained"
            children="Save profile photo"
            onClick={handleSaveClick}
            loading={isUpdatingProfilePhoto}
          />
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange, handleSaveClick, src],
  );

  return (
    <UploadDialog
      title="Add a profile photo"
      content={content}
      action={action}
      noSkip={Boolean(src)}
      {...other}
    />
  );
}
