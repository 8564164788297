import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const lockComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    currentState: boolean;
  }>,
) => {
  const { componentId, currentState } = action.payload;
  const component = state.value.components[componentId];
  if (!component) return;

  const newValue = !currentState;

  state.value.components[componentId] = {
    ...component,
    locked: newValue,
  };
};
