import {
  QueryClient,
  QueryClientProvider,
  QueryKey,
  ThrowOnError,
} from "@tanstack/react-query";
import React from "react";
import { useGenericErrorHandler } from "../hooks/useGenericErrorHandler";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../wrappers/router/coach/CoachClientProfileWrapper";
import { CURRENT_USER_QUERY_KEY } from "../wrappers/current-user/CurrentUserWrapper";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../components/coach-programs/CoachProgramsListScreen";
import { ACTIVITY_LIST_QUERY_KEY } from "../components/activity/CoachActivity";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../components/coach-clients/CoachClientsListScreen";
import { COACH_NOTIFICATIONS_SETTINGS_QUERY_KEY } from "../routes/coach/settings/profile/CoachSettingsProfileRoute";
import { GROUP_MEMBERS_LIST_QUERY_KEY } from "../components/group-members/GroupMembersList";
import { CLIENT_FORMS_LIST_QUERY_KEY } from "../components/coach-client-forms/CoachClientsFormsListScreen";
import { GROUP_POSTS_LIST_QUERY_KEY } from "../components/group-posts/GroupPostsList";
import { MESSAGES_THREAD_QUERY_KEY } from "../components/screen/MessagingScreen";
import { CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY } from "../routes/coach/client/calendar/CoachClientCalendarRoute";
import { COACH_GROUP_WRAPPER_QUERY_KEY } from "../routes/coach/group/CoachGroupRouteWrapper";
import { COACH_GROUPS_LIST_QUERY_KEY } from "../routes/coach/groups/CoachGroupsListRoute";
import { COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY } from "../routes/coach/settings/client-portal/CoachSettingsClientPortalRoute";
import { COACH_SETTINGS_THEME_QUERY_KEY } from "../routes/coach/settings/theme/CoachSettingsThemeRoute";
import { CLIENT_PROGRAMS_LIST_QUERY_KEY } from "../routes/client/programs/list/ClientProgramListRoute";
import { CLIENT_HOME_ROUTE_ACTIVITY_QUERY } from "../components/screen/ClientHomeScreen";
import { REACT_QUERY_CACHING_OPTIONS } from "./ReactQueryConfig";
import { REFRESH_URL } from "./AxiosInterceptor";

export type UseReactQueryPropsBase = {
  onSuccess?: (response: any) => void;
  onError?: (error: any) => void;
};

const MAX_RETRY_COUNT = 3;

/// Query keys that should throw error when failed. Required to trigger `ErrorBoundaryScreen.tsx`.
const QUERY_KEYS_TO_THROW_ERROR: string[] = [
  /* ------ Generic queries ------ */
  CURRENT_USER_QUERY_KEY,
  MESSAGES_THREAD_QUERY_KEY,
  ACTIVITY_LIST_QUERY_KEY,

  /* ------ Coach queries ------ */
  COACH_NOTIFICATIONS_SETTINGS_QUERY_KEY,
  COACH_SETTINGS_CLIENT_PORTAL_QUERY_KEY,
  COACH_SETTINGS_THEME_QUERY_KEY,
  COACH_GROUPS_LIST_QUERY_KEY,
  COACH_GROUP_WRAPPER_QUERY_KEY,
  GROUP_POSTS_LIST_QUERY_KEY,
  GROUP_MEMBERS_LIST_QUERY_KEY,
  CLIENT_FORMS_LIST_QUERY_KEY,
  CLIENT_ENROLLMENTS_FOR_CALENDAR_QUERY_KEY,
  COACH_CLIENT_GENERIC_QUERY_KEY,
  COACH_PROGRAMS_LIST_QUERY_KEY,
  COACH_CLIENTS_LIST_QUERY_KEY,

  /* ------ Client queries ------ */
  CLIENT_HOME_ROUTE_ACTIVITY_QUERY,
  CLIENT_PROGRAMS_LIST_QUERY_KEY,
];

const shouldRetry = (attemptIndex, error: any) => {
  // TODO_API_V2: Consider if retry is needed for specific endpoints.
  // SnackAlert may appear multiple times retrying request is not sent by react-query
  if (error?.config?.url?.toLowerCase()?.endsWith(REFRESH_URL.toLowerCase())) {
    return false;
  }
  return attemptIndex < MAX_RETRY_COUNT;
};

const shouldThrowError = (queryKey: QueryKey) => {
  return (
    typeof queryKey[0] === "string" &&
    QUERY_KEYS_TO_THROW_ERROR.includes(queryKey[0])
  );
};

const ReactQueryClient = ({ children }: { children: any }) => {
  const handleGenericError = useGenericErrorHandler({});

  const queryClient = new QueryClient({
    // TODO_API_V2 STR-1330: Consider about optimal default options
    defaultOptions: {
      queries: {
        // TODO_API_V2 STR-1331: Temporarily set to `all` to make rerendering work on cache update.
        // Consider replacing with another value after figure out how new optimized rendering works.
        notifyOnChangeProps: "all",
        ...REACT_QUERY_CACHING_OPTIONS,
        retry: shouldRetry, // Retry failed queries up to 3 times
        throwOnError: (_, { queryKey }) => shouldThrowError(queryKey),
      },
      mutations: {
        onError: handleGenericError,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryClient;
