import clsx from "clsx";
import React from "react";
import { DialogProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";

import { BaseDialog } from "./BaseDialog";
import dayjs, { Dayjs } from "dayjs";
import { UserInfoDto2 } from "@growth-machine-llc/stridist-api-client";
import { useUpdateUserMutation } from "../admin/mutations/useUpdateUserMutation";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

export interface UpdateTrialExpirationDateDialogProps extends DialogProps {
  user: UserInfoDto2;
}

export function UpdateTrialExpirationDateDialog(
  props: UpdateTrialExpirationDateDialogProps,
) {
  const { className, user, onClose, ...other } = props;
  const s = useStyles();
  const [date, setDate] = React.useState(user.trialExpiryDate?.toDate());
  const { mutate: updateUser, isPending: updateInFlight } =
    useUpdateUserMutation();

  const handleDateChange = React.useCallback((date: Date) => {
    setDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setDate(user.trialExpiryDate?.toDate());
  }, [user]);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleUpdateClick = React.useCallback(() => {
    updateUser(
      {
        id: user.id,
        trialExpiryDate: dayjs(date),
      },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
  }, [date, handleClose, updateUser, user.id]);

  const disabled = !date || updateInFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Update trial expiration date"
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={date}
        onChange={handleDateChange}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="Update trial expiration"
        onClick={handleUpdateClick}
        disabled={disabled}
      />
    </BaseDialog>
  );
}
