import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { template, uniq } from "lodash";

import { ReactComponent as SearchIcon } from "../../icons/Search.svg";
import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";
import { queryMatchesContent } from "../../utils/search";
import { SOMETHING_WENT_WRONG } from "../../constants";

import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import {
  ComponentTemplateBriefDto,
  PaginatedListOfComponentTemplateBriefDto,
} from "@growth-machine-llc/stridist-api-client";
import ComponentsService from "../../services/ComponentsService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { COMPONENT_TEMPLATES_LIST_QUERY_KEY } from "./ComponentTemplateDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.selected.light,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  search: {
    padding: theme.spacing(3, 3, 0, 3),
    "& > div": {
      borderRadius: 0,
    },
  },

  searchInput: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
  },

  body: {
    padding: theme.spacing(0, 3),
    overflowY: "auto",
  },

  title: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.75,
    margin: theme.spacing(4, 0, 1),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  category: {
    margin: theme.spacing(0, -2),
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  myTemplates: {
    margin: theme.spacing(0, -1),

    "& $template": {
      paddingRight: theme.spacing(5),
    },
  },

  template: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: `${theme.palette.quote}66`,
    },
  },

  templateInCategory: {
    marginLeft: theme.spacing(2),
  },

  caret: {
    color: theme.palette.text.secondary,
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    "&:not($caretOpen)": {
      transform: "rotate(-90deg)",
    },
  },

  caretOpen: {},

  removeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    display: "none",

    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },

    "$template:hover &": {
      display: "block",
    },
  },
}));

export interface ComponentTemplateLibraryProps
  extends Omit<BoxProps, "onSelect"> {
  templates: ComponentTemplateBriefDto[];
  onSelect?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    templateId: string,
  ) => void;
  isLoadingTemplatesList: boolean;
}

export function ComponentTemplateLibrary(props: ComponentTemplateLibraryProps) {
  const { className, templates, onSelect, isLoadingTemplatesList, ...other } =
    props;
  const s = useStyles();
  const [search, setSearch] = React.useState("");
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const { mutate: removeTemplate } = useOptimisticUpdateMutation({
    disableToastAlerts: true,
    queryKey: [COMPONENT_TEMPLATES_LIST_QUERY_KEY],
    exactQueryKey: false,
    mutationFn: ComponentsService.removeComponentTemplate,
    optimisticUpdater: {
      updateFn: (
        oldData: PaginatedListOfComponentTemplateBriefDto,
        variables,
        _,
      ) => {
        const updatedData = PaginatedListOfComponentTemplateBriefDto.fromJS({
          ...oldData,
          items: oldData.items.filter((item) => item.id !== variables),
        });

        return updatedData;
      },
    },
  });
  const { showToastAlert } = useToastAlert();
  const { privateTemplates, sharedTemplates } = React.useMemo(() => {
    const filtered = templates?.filter(({ title }) =>
      queryMatchesContent(title, search),
    );
    const privateTemplates = filtered?.filter((it) => it.private);
    const sharedTemplates = filtered?.filter((it) => !it.private);

    return { privateTemplates, sharedTemplates };
  }, [templates, search]);

  const categories = React.useMemo(() => {
    const categories = uniq(sharedTemplates?.map(({ category }) => category));
    categories.sort();

    return categories;
  }, [sharedTemplates]);

  const handleSearchChange = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      setSearch(event.currentTarget.value);
    },
    [],
  );

  const handleCategoryClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const {
        dataset: { category },
      } = event.currentTarget;

      setExpanded(
        expanded.includes(category)
          ? expanded.filter((it) => it !== category)
          : [...expanded, category],
      );
    },
    [expanded],
  );

  const handleTemplateClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        dataset: { templateId },
      } = event.currentTarget;
      if (onSelect) {
        onSelect(event, templateId);
      }
    },
    [onSelect],
  );

  const handleRemoveTemplate = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const {
        dataset: { templateId },
      } = event.currentTarget;

      removeTemplate(templateId, {
        onSuccess: () => {
          showToastAlert("success", {
            message: "Template removed",
          });
        },
      });
    },
    [removeTemplate, showToastAlert],
  );

  const SkeletonList = ({ count = 3 }) => {
    return (
      <List component="nav">
        {Array.from({ length: count }).map((_, index) => (
          <ListItem key={index}>
            <ListItemText>
              <Skeleton variant="text" width="60%" />
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <TextField
        className={s.search}
        InputProps={{
          className: s.searchInput,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={handleSearchChange as any}
        placeholder="Search"
        variant="outlined"
        fullWidth
      />

      <Box className={clsx(s.body)}>
        <Typography className={s.title} variant="subtitle1">
          My templates
        </Typography>
        {isLoadingTemplatesList ? (
          <SkeletonList />
        ) : privateTemplates?.length > 0 ? (
          <>
            <List component="div" className={s.myTemplates} disablePadding>
              {privateTemplates.map(({ id, title }) => (
                <ListItem
                  className={s.template}
                  button
                  key={id}
                  onClick={handleTemplateClick}
                  data-template-id={id}
                >
                  <ListItemText classes={{ primary: s.text }}>
                    {title}
                    <IconButton
                      className={s.removeButton}
                      onClick={handleRemoveTemplate}
                      data-template-id={id}
                      size="large"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography variant="body2">
            You don't have any private templates.
          </Typography>
        )}

        <Typography className={s.title} variant="subtitle1">
          Suggested templates
        </Typography>
        {isLoadingTemplatesList ? (
          <SkeletonList />
        ) : sharedTemplates?.length > 0 ? (
          <List component="nav">
            {categories.map((category: any) => {
              const open = expanded.includes(category);
              return (
                <React.Fragment key={category}>
                  <ListItem
                    className={s.category}
                    button
                    onClick={handleCategoryClick as any}
                    data-category={category}
                  >
                    <CaretDownIcon
                      className={clsx(s.caret, open && s.caretOpen)}
                    />
                    <ListItemText
                      classes={{ primary: s.text }}
                      primary={category}
                    />
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {sharedTemplates
                        .filter((it) => it.category === category)
                        .map(({ id, title }) => (
                          <ListItem
                            className={clsx(s.template, s.templateInCategory)}
                            button
                            key={id}
                            onClick={handleTemplateClick}
                            data-template-id={id}
                          >
                            <ListItemText
                              classes={{ primary: s.text }}
                              primary={title}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
          </List>
        ) : (
          <Typography variant="body2">No suggested templates. </Typography>
        )}
      </Box>
    </Box>
  );
}
