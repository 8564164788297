import clsx from "clsx";
import React from "react";
import {
  TableContainer,
  TableContainerProps,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Card,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardRounded } from "@mui/icons-material";
import { uniqBy } from "lodash";
import { ProgressLine } from "../progress/ProgressLine";
import { useLocation, useNavigate } from "react-router-dom";
import Calendar from "../../icons/Calendar";
import { ISO_DATE_FORMAT } from "../../utils/date";
import { HabitsCheckInsActivityDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  row: {
    cursor: "pointer",
  },

  cell: {
    padding: theme.spacing(2.5, 3),
  },

  label: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },

  data: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,

    "&$grey": {
      color: theme.palette.text.secondary,
    },
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  calendarIcon: {
    marginRight: theme.spacing(1),

    "&$grey path": {
      stroke: theme.palette.text.secondary,
    },
  },

  progressLine: {
    width: 100,
    marginRight: theme.spacing(2),
  },

  arrow: {
    color: theme.palette.text.secondary,
  },

  grey: {},
}));

enum COLUMN {
  NAME = "NAME",
  ACTIVE_STREAK = "ACTIVE_STREAK",
  COMPLETIONS = "COMPLETIONS",
  COMPLETION_RATE = "COMPLETION_RATE",
}

const label = {
  [COLUMN.NAME]: "Name",
  [COLUMN.ACTIVE_STREAK]: "Active streak",
  [COLUMN.COMPLETIONS]: "Completions",
  [COLUMN.COMPLETION_RATE]: "Completion rate",
};

export interface HabitCheckInProgressTableProps extends TableContainerProps {
  habitsCheckIns: HabitsCheckInsActivityDto[];
}

export function HabitCheckInProgressTable(
  props: HabitCheckInProgressTableProps,
) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const { className, habitsCheckIns, ...other } = props;
  const s = useStyles();
  const activities = habitsCheckIns;

  const handleActivityClick = React.useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      const {
        currentTarget: {
          dataset: { url },
        },
      } = event;

      if (url) {
        navigate(url);
      }
    },
    [],
  );

  const renderValue = React.useCallback(
    (column: COLUMN, activity: HabitsCheckInsActivityDto): React.ReactNode => {
      const inactive = activity.activeStreak === null;
      const typographyProps = {
        className: clsx(s.data, inactive && s.grey),
      };

      switch (column) {
        case COLUMN.NAME:
          return (
            <Box className={s.center}>
              <Calendar
                fill={theme.palette.primary.main}
                className={clsx(s.calendarIcon, inactive && s.grey)}
              />
              <Typography {...typographyProps}>
                {activity.component.title}
              </Typography>
            </Box>
          );
        case COLUMN.ACTIVE_STREAK:
          return (
            <Typography {...typographyProps}>
              {inactive
                ? "N/A"
                : `${activity.activeStreak} day${
                    activity.activeStreak === 1 ? "" : "s"
                  }`}
            </Typography>
          );
        case COLUMN.COMPLETIONS:
          return (
            <Typography {...typographyProps}>
              {activity.completion.completed} of {activity.completion.total}
            </Typography>
          );
        case COLUMN.COMPLETION_RATE:
          return (
            <Box className={s.center}>
              <ProgressLine
                className={s.progressLine}
                value={activity.completion.rate}
                disabled={inactive}
              />
              <Typography {...typographyProps}>
                {activity.completion.rate} %
              </Typography>
            </Box>
          );
        default:
          throw new Error("Invalid column value.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <TableContainer
      className={clsx(s.root, className)}
      component={Card}
      {...other}
    >
      <Table>
        <TableHead>
          <TableRow>
            {Object.values(COLUMN).map((column) => (
              <TableCell className={s.cell} key={column} align="left">
                <Typography className={s.label} variant="body1">
                  {label[column]}
                </Typography>
              </TableCell>
            ))}
            <TableCell className={s.cell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {uniqBy(activities, "component.id").map(
            (activity: HabitsCheckInsActivityDto) => {
              const { component, date } = activity;
              const url = `${location.pathname}/${date.format(ISO_DATE_FORMAT)}/${component.slug}?summary=yes`;

              return (
                <TableRow
                  key={activity.id}
                  className={s.row}
                  data-url={url}
                  onClick={handleActivityClick}
                >
                  {Object.values(COLUMN).map((column) => (
                    <TableCell className={s.cell} key={column} align="left">
                      {renderValue(column, activity)}
                    </TableCell>
                  ))}
                  <TableCell className={s.cell} align="right">
                    <ArrowForwardRounded className={s.arrow} />
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
