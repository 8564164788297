import React, { useContext } from "react";
import { TrainingSummary } from "../../../../../components/training-summary/TrainingSummary";
import TrackInfoTool from "../../../../../components/tools/TrackInfoTool";
import { useCurrentUser } from "../../../../../hooks/useCurrentUser";
import { useProgramComponentRoute } from "../../../../../wrappers/router/common/ProgramsComponentRouteWrapper";

export function ClientProgramActivitySummaryRoute() {
  const { username } = useCurrentUser();
  const { activity } = useProgramComponentRoute();
  return (
    <>
      <TrainingSummary activity={activity} clientUsername={username} />
      <TrackInfoTool
        trackInfo={{
          name: "Client - Program",
        }}
      />
    </>
  );
}
