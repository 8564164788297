import { call, put, select } from "redux-saga/effects";

import {
  TempIdsMap,
  updateComponentsTempIds,
  updateTempIdMap,
} from "../../../api/api-slice";
import WeeksService from "../../../../services/WeeksService";
import {
  selectPresentCurriculumId,
  selectWeekById,
} from "../../selectors/curriculum";
import { selectWeekTempIdsMap } from "../../../api/selectors";
import { WeekMoveDirection } from "../../../../constants";
import { DuplicateWeekResult } from "@growth-machine-llc/stridist-api-client";
import { setComponentSlug, setDuplicatedWeek, updateWaitingForApiStatus } from "../../curriculum-slice";
import { NormalizedCurriculumWeek } from "../../../types";

export function* addWeekWorker(payload: number) {
  const tempId = payload.toString();
  const programId: number = yield select(selectPresentCurriculumId);
  try {
    const realId = yield call(WeeksService.add, programId);
    yield put(updateTempIdMap({ type: "weeks", tempId, realId }));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function* deleteWeekWorker(payload: number) {
  const map: TempIdsMap = yield select(selectWeekTempIdsMap);
  const checkedWeekId = map[payload.toString()] || payload;
  try {
    yield call(WeeksService.delete, checkedWeekId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function* duplicateWeekWorker(payload: {
  newWeekId: number;
  weekToDuplicateId: number;
}) {
  const { weekToDuplicateId, newWeekId: tempId } = payload;
  if (!weekToDuplicateId || !tempId) return;
  const programId: number = yield select(selectPresentCurriculumId);

  const map: TempIdsMap = yield select(selectWeekTempIdsMap);
  const checkedWeekId = map[weekToDuplicateId.toString()] || weekToDuplicateId;
  try {
    const res: DuplicateWeekResult = yield call(
      WeeksService.duplicate,
      checkedWeekId,
      programId,
    );
    //const { id: realId, createdComponents } = res;

    // assume that week is duplicated in store until now
    // const selectedWeek: NormalizedCurriculumWeek = yield select(
    //   selectWeekById(tempId),
    // );

    yield put(setDuplicatedWeek({ tempId, week: res.week }));

    // if (!selectedWeek) throw new Error("Saga duplicateWeekWorker: week not found in slice.");

    // const storePositions = selectedWeek.positions;
    // const apiPositions = res.positions;

    // const mapChunk = storePositions.map((tempId, index) => ({
    //   tempId,
    //   realId: parseInt(apiPositions[index]),
    // }));
    // yield put(updateComponentsTempIds(mapChunk));

    // yield put(
    //   updateTempIdMap({ type: "weeks", tempId: tempId.toString(), realId }),
    // );
    // yield put(
    //   setComponentSlug(
    //     createdComponents.map(({ id, slug }) => ({
    //       id: parseInt(mapChunk.find((i) => i.realId === id).tempId),
    //       slug,
    //     })),
    //   ),
    // );

    //yield put(updateWaitingForApiStatus({ tempWeekId: tempId, waiting: false }));

  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function* moveWeekWorker(payload: {
  id: number;
  direction: WeekMoveDirection;
}) {
  const { id, direction } = payload;
  if (!id || !direction) return;

  const map: TempIdsMap = yield select(selectWeekTempIdsMap);
  const checkedWeekId = map[id.toString()] || id;
  try {
    yield call(WeeksService.move, checkedWeekId, direction);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function* updatePositionsWorker(payload: {
  id: number;
  positions: string[];
}) {
  const { id, positions: positionsRaw } = payload;
  if (!id || !positionsRaw) return;

  const positions = positionsRaw.map(String);

  const map: TempIdsMap = yield select(selectWeekTempIdsMap);
  const checkedWeekId = map[id.toString()] || id;
  try {
    yield call(WeeksService.updatePositions, checkedWeekId, positions);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function* updateDescriptionWorker(payload: { id: number; description: string }) {
  const { id, description } = payload;
  if (!id || !description) return;

  const map: TempIdsMap = yield select(selectWeekTempIdsMap);
  const checkedWeekId = map[id.toString()] || id;
  try {
    yield call(WeeksService.updateDescription, checkedWeekId, description);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
