import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SettingsCard } from "../card/SettingsCard";
import { ColorPickerGroup } from "../fields/ColorPickerGroup";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";

import { useUpdateThemeMutation } from "./mutations/UpdateThemeMutation";
import { IGetCoachWorkspaceThemeDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },

  label: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}));

export interface CoachThemeSettingsScreenProps
  extends Omit<ContainerProps, "children"> {
  workspace: IGetCoachWorkspaceThemeDto;
}

export function CoachThemeSettingsScreen(props: CoachThemeSettingsScreenProps) {
  const { className, workspace, ...other } = props;
  const s = useStyles();
  const [dirty, setDirty] = React.useState(false);
  const currentThemeOptions = {
    primaryColor: workspace.primaryColor,
  };

  const [themeOptions, setThemeOptions] = React.useState(currentThemeOptions);
  const {
    mutate: updateTheme,
    isPending: pending,
    status: updateThemeStatus,
  } = useUpdateThemeMutation();

  const handlePrimaryChange = React.useCallback(
    (primaryColor) => {
      setThemeOptions({
        ...themeOptions,
        primaryColor,
      });
      setDirty(true);
    },
    [setThemeOptions, themeOptions],
  );

  const handleSave = React.useCallback(() => {
    updateTheme(
      { id: workspace?.id, ...themeOptions },
      {
        onSuccess: () => {
          setDirty(false);
        },
      },
    );
  }, [themeOptions, updateTheme]);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={dirty} />
      <SettingsCard
        header="Theme settings"
        subheader="Change the appearance of what your clients see when they log in"
        ButtonProps={{
          disabled: !dirty || pending,
          onClick: handleSave,
          status: updateThemeStatus,
        }}
      >
        <Typography variant="h4" className={s.label}>
          Primary colour
        </Typography>

        <ColorPickerGroup
          value={themeOptions.primaryColor}
          onChange={handlePrimaryChange}
          disabled={pending}
        />
      </SettingsCard>
    </Container>
  );
}
