import clsx from "clsx";
import React, { useEffect } from "react";
import { Input, InputProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { SelectComponentIconsMenu } from "../menu/SelectComponentIconsMenu";
import { ComponentType } from "../../constants";
import { CurriculumComponent } from "../../redux/types";
import { ComponentIcon } from "./ComponentIcon";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectComponentById } from "../../redux/curriculum/selectors/curriculum";
import { selectComponentTempIdsMap } from "../../redux/api/selectors";

type StyleProps = {
  type: ComponentType;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "baseline",
  },

  input: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    padding: 0,
    lineHeight: 1.25,

    "& ::before": {
      border: "none",
    },
  },

  icon: {
    marginRight: theme.spacing(1.5),
    cursor: ({ type }) =>
      type === ComponentType.MESSAGE ? "default" : "pointer",
  },

  iconsMenu: {
    width: theme.spacing(40),
    zIndex: "9999 !important" as any,
  },
}));

export interface CoachComponentTitleProps extends InputProps {
  component: CurriculumComponent;
}

export function CoachComponentTitle(props: CoachComponentTitleProps) {
  const { className, component, onChange, defaultValue, ...other } = props;

  // Temp id check for right store sync
  const tempIdsMap = useCurriculumSelector(selectComponentTempIdsMap);
  const tempId = Number(
    Object.keys(tempIdsMap).find((k) => tempIdsMap[k] === component.id),
  );
  const checkedStoreId = tempId ? tempId : component.id;

  const storeComponent = useCurriculumSelector(
    selectComponentById(checkedStoreId),
  );
  const COMPONENT_DATA = storeComponent ?? component;
  const [title, setTitle] = React.useState(COMPONENT_DATA.title);
  const s = useStyles({ type: COMPONENT_DATA.type as ComponentType });
  const [reRendered, setReRendered] = React.useState(false);

  const iconsMenuState = usePopupState({
    variant: "popover",
    popupId: "iconsMenu",
  });

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTitle(event.currentTarget.value);

      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    setTimeout(() => {
      setReRendered(true);
    }, 5);
  }, []);

  useEffect(() => {
    setTitle(COMPONENT_DATA.title);
  }, [COMPONENT_DATA.title]);

  return (
    <>
      <Input
        disableUnderline
        className={clsx(s.root, className)}
        value={title === defaultValue ? "" : title}
        disabled={!reRendered}
        inputProps={{ className: s.input }}
        onChange={handleChange}
        startAdornment={
          <ComponentIcon
            componentData={COMPONENT_DATA}
            className={s.icon}
            size="small"
            {...(COMPONENT_DATA.type !== ComponentType.MESSAGE
              ? bindTrigger(iconsMenuState)
              : {})}
          />
        }
        {...other}
      />
      <SelectComponentIconsMenu
        {...bindMenu(iconsMenuState)}
        className={s.iconsMenu}
        componentData={COMPONENT_DATA}
        onSubmit={iconsMenuState.close}
      />
    </>
  );
}
