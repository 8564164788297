import {
  CreateClientGoalCommand,
  ICreateClientGoalCommand,
  IReorderClientGoalsCommand,
  IUpdateClientGoalCommand,
  IUpdateCurrentValueClientGoalCommand,
  ReorderClientGoalsCommand,
  UpdateClientGoalCommand,
  UpdateCurrentValueClientGoalCommand,
} from "@growth-machine-llc/stridist-api-client";
import { clientGoalsClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class ClientGoalsClientService extends ServiceBase {
  async getAllClientGoals(clientId: number) {
    const pageSize = 999;
    const pageNumber = 1;
    return clientGoalsClient.getClientGoals(clientId, pageNumber, pageSize);
  }
  async deleteClientGoal(id: number) {
    return clientGoalsClient.delete(id);
  }
  async createClientGoal(variables: ICreateClientGoalCommand) {
    const command = CreateClientGoalCommand.fromJS(variables);
    return clientGoalsClient.createClientGoal(command);
  }
  async updateCurrentGoalValue(
    variables: IUpdateCurrentValueClientGoalCommand,
  ) {
    const command = UpdateCurrentValueClientGoalCommand.fromJS(variables);
    return clientGoalsClient.updateCurrentValue(command);
  }
  async changeGoalOrder(variables: IReorderClientGoalsCommand) {
    const command = ReorderClientGoalsCommand.fromJS(variables);
    return clientGoalsClient.reorder(command);
  }
  async updateClientGoal(id: number, variables: IUpdateClientGoalCommand) {
    const command = UpdateClientGoalCommand.fromJS(variables);
    return clientGoalsClient.update(id, command);
  }
}

export default new ClientGoalsClientService();
