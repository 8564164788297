import { useEffect } from "react";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectGeneralLoading } from "../../redux/api/selectors";
import { useToastAlert } from "../../components/app/ToastAlert/ToastAlertProvider";

const PREVENT_MESSAGE =
  "We are still saving your changes to the program, hang tight.";

const PreventLeavingBeforeSaveWrapper = ({ children }) => {
  const apiLoading = useCurriculumSelector(selectGeneralLoading);
  const { showToastAlert } = useToastAlert();
  useEffect(() => {
    if (!apiLoading) return;

    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = true;
      showToastAlert("warning", { message: PREVENT_MESSAGE });
    }

    window.addEventListener("beforeunload", handleBeforeUnload, {
      capture: true,
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
    };
  }, [apiLoading]);

  return children;
};

export default PreventLeavingBeforeSaveWrapper;
