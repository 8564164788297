import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  FormControl,
  FormLabel,
  TextField,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { BaseDialog, BaseDialogProps } from "../dialog/BaseDialog";
import { getISODate, formatTime } from "../../utils/date";
import {
  MealLoggingQuestion,
  MealLoggingQuestionType,
} from "../meal-logging-settings/types";
import { MoreMenuButton } from "../button/MoreMenuButton";
import { isMobileApp } from "../../utils/mobile";
import { DateTimeDialog, DateTimeDialogProps } from "../dialog/DateTimeDialog";

import { ClientMealLoggingButton } from "./ClientMealLoggingButton";
import { ClientMealLoggingUploadPhoto } from "./ClientMealLoggingUploadPhoto";
import { ClientMealLoggingEmojiQuestion } from "./ClientMealLoggingEmojiQuestion";
import { ClientMealLoggingYesNoQuestion } from "./ClientMealLoggingYesNoQuestion";
import { ClientMealLoggingMultipleChoiceQuestion } from "./ClientMealLoggingMultipleChoiceQuestion";
import { useUpsertMealLoggingEntryMutation } from "./mutations/UpsertMealLoggingEntry";
import { ClientMealLoggingEntryMenu } from "./ClientMealLoggingEntryMenu";
import { useRemoveMealLoggingEntryMutation } from "./mutations/RemoveMealLoggingEntry";
import { ClientMealLoggingStarRatingQuestion } from "./ClientMealLoggingStarRatingQuestion";
import { ClientMealLoggingLongTextQuestion } from "./ClientMealLoggingLongTextQuestion";
import { ClientMealLoggingCheckboxesQuestion } from "./ClientMealLoggingCheckboxesQuestion";
import { ClientMealLoggingNumberQuestion } from "./ClientMealLoggingNumberQuestion";
import { ClientMealLoggingRatingScaleQuestion } from "./ClientMealLoggingRatingScaleQuestion";
import { ClientMealLoggingNutritionQuestion } from "./ClientMealLoggingNutritionQuestion";
import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  GetWorkspaceClientPortalDto,
  MealLoggingDto,
} from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  adminRoot: {
    marginTop: theme.spacing(7),
  },

  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.default,

    "& h2": {
      fontWeight: "bold",
    },

    "& fieldset": {
      width: "100%",
    },

    "& .MuiRadio-root": {
      color: theme.palette.primary.main,
    },
  },

  content: {
    paddingBottom: theme.spacing(11),
  },

  header: {
    position: "relative",
    marginBottom: theme.spacing(2),
  },

  back: {
    position: "absolute",
    top: theme.spacing(-1),
    left: theme.spacing(-1.5),

    padding: theme.spacing(1),
    color: theme.palette.text.secondary,

    "&:hover": {
      color: theme.palette.text.primary,
    },

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

  more: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),

    width: theme.spacing(4),
    height: theme.spacing(4),

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    textAlign: "center",
  },

  label: {
    color: `${theme.palette.text.secondary} !important`,
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  focused: {
    "& $button": {
      display: "none",
    },
  },

  notDisplayed: {
    display: "none",
  },

  button: {},
  selected: {},
}));

export interface ClientMealLoggingEntryDialogProps
  extends Omit<BaseDialogProps, "title"> {
  date: string;
  workspace: GetWorkspaceClientPortalDto;
  mealLoggingEntry: MealLoggingDto;
}

const renderQuestion = (
  option: MealLoggingQuestion,
  onUpdate: (option: MealLoggingQuestion) => void,
) => {
  switch (option.type) {
    case MealLoggingQuestionType.EMOJI:
      return (
        <ClientMealLoggingEmojiQuestion option={option} onUpdate={onUpdate} />
      );

    case MealLoggingQuestionType.YES_NO:
      return (
        <ClientMealLoggingYesNoQuestion option={option} onUpdate={onUpdate} />
      );

    case MealLoggingQuestionType.MULTIPLE_CHOICE:
      return (
        <ClientMealLoggingMultipleChoiceQuestion
          option={option}
          onUpdate={onUpdate}
        />
      );

    case MealLoggingQuestionType.STAR_RATING:
      return (
        <ClientMealLoggingStarRatingQuestion
          option={option}
          onUpdate={onUpdate}
        />
      );

    case MealLoggingQuestionType.LONG_TEXT:
      return (
        <ClientMealLoggingLongTextQuestion
          option={option}
          onUpdate={onUpdate}
        />
      );

    case MealLoggingQuestionType.CHECKBOXES:
      return (
        <ClientMealLoggingCheckboxesQuestion
          option={option}
          onUpdate={onUpdate}
        />
      );

    case MealLoggingQuestionType.NUMBER:
      return (
        <ClientMealLoggingNumberQuestion option={option} onUpdate={onUpdate} />
      );

    case MealLoggingQuestionType.RATING_SCALE:
      return (
        <ClientMealLoggingRatingScaleQuestion
          option={option}
          onUpdate={onUpdate}
        />
      );

    case MealLoggingQuestionType.NUTRITION:
      return (
        <ClientMealLoggingNutritionQuestion
          option={option}
          onUpdate={onUpdate}
        />
      );

    default:
      throw new Error("Unknown question type");
  }
};

export const CLIENT_PORTAL_QUERY_KEY = "client-portal";

export function ClientMealLoggingEntryDialog(
  props: ClientMealLoggingEntryDialogProps,
) {
  const me = useCurrentUser();
  const isImpersonating = me?.isImpersonating;

  const s = useStyles();

  const {
    className,
    date: viewDate,
    workspace,
    mealLoggingEntry,
    ...other
  } = props;
  const { onClose } = props;
  const today = viewDate === getISODate();
  const mealLoggingEntryId = mealLoggingEntry?.id;
  const [dirty, setDirty] = React.useState(false);
  const [photoURL, setPhotoURL] = React.useState(mealLoggingEntry?.photoUrl);
  const {
    createMealLogging,
    creatingMealLogging,
    updateMealLogging,
    updatingMealLogging,
  } = useUpsertMealLoggingEntryMutation(viewDate);
  const pending = creatingMealLogging || updatingMealLogging;
  const deleteLogging = useRemoveMealLoggingEntryMutation(viewDate);
  const [date, setDate] = React.useState<string>(
    mealLoggingEntry?.date.format("YYYY-MM-DD") || viewDate || getISODate(),
  );
  const [time, setTime] = React.useState<string>(
    mealLoggingEntry?.time || (today ? dayjs().format("HH:mm") : "12:00"),
  );
  const [description, setDescription] = React.useState(
    mealLoggingEntry?.description || "",
  );
  const [questions, setQuestions] = React.useState<MealLoggingQuestion[]>(
    JSON.parse(mealLoggingEntry?.answers || workspace.mealLoggingQuestions),
  );
  const [focused, setFocused] = React.useState(false);
  const [dateTimeDialogOpen, setDateTimeDialogOpen] = React.useState(false);

  const dateTime = `${date}T${time}`;
  const dateTimeText = React.useMemo(
    () => dayjs(dateTime).format("MMM D, YYYY [at] hh:mm a"),
    [dateTime],
  );

  const allAnswered = questions.every((it) => {
    switch (it.type) {
      case MealLoggingQuestionType.CHECKBOXES: {
        const parsed =
          it.answer && it.answer !== "" ? JSON.parse(it.answer) : [];
        return parsed.length > 0 && parsed.every((value) => Boolean(value));
      }
      case MealLoggingQuestionType.NUTRITION: {
        const parsed =
          it.answer && it.answer !== "" ? JSON.parse(it.answer) : {};
        return (
          Object.keys(parsed).length > 0 &&
          Object.values(parsed).every((value: any) => value >= 0)
        );
      }
      default:
        return Boolean(it.answer);
    }
  });
  const completed = date && time && description && allAnswered;
  const title = mealLoggingEntry ? formatTime(dateTime) : "Log a new meal";

  const menuState = usePopupState({
    variant: "popover",
    popupId: "mealEntryMenu",
  });

  const handleOpenDateTimeDialog = React.useCallback(() => {
    setDateTimeDialogOpen(true);
  }, []);

  const handleCloseDateTimeDialog = React.useCallback(() => {
    setDateTimeDialogOpen(false);
  }, []);

  const handleDateTimeChange: DateTimeDialogProps["onConfirm"] =
    React.useCallback((date, time) => {
      setDirty(true);
      setDate(date);
      setTime(time);
    }, []);

  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDirty(true);
      setDescription(event.currentTarget.value);
    },
    [],
  );

  const handlePhotoChange = React.useCallback((value: string) => {
    setDirty(true);
    setPhotoURL(value);
  }, []);

  const handleQuestionUpdate = React.useCallback(
    (option: MealLoggingQuestion) => {
      setDirty(true);
      setQuestions(
        questions.map((it) =>
          it.id === option.id
            ? {
                ...it,
                answer: option.answer as any,
              }
            : it,
        ),
      );
    },
    [questions],
  );

  const handleSubmit = React.useCallback(
    (event) => {
      const upsertPayload = {
        description,
        photoUrl: photoURL,
        answers: JSON.stringify(questions),
        date,
        time,
      };

      if (mealLoggingEntryId) {
        updateMealLogging(
          {
            id: mealLoggingEntryId,
            ...upsertPayload,
          },
          { onSuccess: () => onClose(event, "backdropClick") },
        );
      } else {
        createMealLogging(upsertPayload, {
          onSuccess: () => onClose(event, "backdropClick"),
        });
      }
    },
    [
      onClose,
      createMealLogging,
      updateMealLogging,
      mealLoggingEntryId,
      description,
      photoURL,
      questions,
      date,
      time,
    ],
  );

  const handleBack = React.useCallback(
    (event) => {
      if (onClose) {
        onClose("event", "backdropClick");
      }
    },
    [onClose],
  );

  const handleMenuItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const {
        dataset: { action },
      } = event.currentTarget;

      if (action === "remove") {
        deleteLogging.mutate(mealLoggingEntryId);

        handleBack(event);
      }
    },
    [handleBack, mealLoggingEntryId, deleteLogging.mutate],
  );

  React.useLayoutEffect(() => {
    if (isMobileApp) {
      const interval = setInterval(() => {
        const el = document.querySelector(
          "input[type=text]:focus, input[type=number]:focus, textarea:focus",
        );

        if (el && !focused) {
          setFocused(true);
        } else if (!el && focused) {
          const timer = setTimeout(() => setFocused(false), 500);

          return () => clearTimeout(timer);
        }
      }, 50);

      return () => clearInterval(interval);
    }
  }, [focused]);

  return (
    <>
      <BaseDialog
        className={clsx(s.root, className, {
          [s.adminRoot]: isImpersonating,
        })}
        classes={{
          paper: clsx(s.paper, focused && s.focused),
          content: s.content,
        }}
        header={
          mealLoggingEntry && (
            <Box className={s.header}>
              <IconButton
                className={s.back}
                onClick={handleBack}
                children={<ArrowBackOutlined />}
                disabled={!completed}
                size="large"
              />
              <Typography variant="h2" className={s.title} children={title} />
              <MoreMenuButton
                className={s.more}
                styleVariant="inverse"
                {...bindTrigger(menuState)}
              />
              <ClientMealLoggingEntryMenu
                onItemClick={handleMenuItemClick}
                {...bindMenu(menuState)}
              />
            </Box>
          )
        }
        title={!mealLoggingEntry && title}
        maxWidth="xs"
        fullScreen
        {...other}
      >
        <ClientMealLoggingUploadPhoto
          value={photoURL}
          onChange={handlePhotoChange}
        />

        {(!today || mealLoggingEntry) && (
          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="time"
              component="label"
              children="Add a time"
            />
            <TextField
              variant="outlined"
              fullWidth
              id="time"
              placeholder="Date"
              value={dateTimeText}
              required
              onClick={handleOpenDateTimeDialog}
            />
          </FormControl>
        )}

        <FormControl component="fieldset">
          <FormLabel
            className={s.label}
            htmlFor="description"
            component="label"
            children="Add a description"
          />
          <TextField
            variant="outlined"
            fullWidth
            id="description"
            placeholder="Description"
            value={description}
            onChange={handleDescriptionChange}
            multiline
            rows={5}
            required
          />
        </FormControl>

        {questions.map((option) => (
          <FormControl component="fieldset" key={option.id}>
            {option.type !== MealLoggingQuestionType.NUTRITION && (
              <FormLabel
                className={s.label}
                component="label"
                children={option.text}
              />
            )}
            {renderQuestion(option, handleQuestionUpdate)}
          </FormControl>
        ))}

        <ClientMealLoggingButton
          wrapperClass={s.button}
          disabled={!dirty || !completed || pending}
          onClick={handleSubmit}
          loading={pending}
        />
      </BaseDialog>

      <DateTimeDialog
        open={dateTimeDialogOpen}
        date={date}
        time={time}
        maxDate={dayjs()}
        onConfirm={handleDateTimeChange}
        onClose={handleCloseDateTimeDialog}
      />
    </>
  );
}
