import React from "react";
import { useParams } from "react-router-dom";
import { ClientPrograms } from "../../../../components/coach-clients/ClientPrograms";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachClientProgramsRoute() {
  const { username } = useParams();

  return (
    <>
      <ClientPrograms />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Client Programs",
          properties: {
            clientUsername: username,
          },
        }}
      />
    </>
  );
}
