import React from "react";
import { CardContent, Skeleton, useTheme } from "@mui/material";
import { Card, CardProps, CardHeader, Grid } from "@mui/material";

export function GroupPostSkeleton(props: CardProps) {
  const theme = useTheme();
  return (
    <Card {...props}>
      <CardContent>
        <Grid container gap={2}>
          <Grid item container height={80} alignItems="center" gap={3}>
            <Skeleton animation="wave" width={50} height="100%" />
            <Grid item>
              <Skeleton
                animation="wave"
                height={20}
                width={125}
                sx={{ mb: 1 }}
              />
              <Skeleton animation="wave" height={15} width={125} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" height={20} width={"75%"} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
