import {
  ClientInfoDto,
  PaginatedListOfClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import {
  predicateQueryKeyByParams,
  predicateTwoPartQueryKey,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import ClientsService from "../../../services/ClientsService";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { useQueryClient } from "@tanstack/react-query";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../../wrappers/router/coach/CoachClientProfileWrapper";
import { useInvalidateCoachProgramAndGroupQueries } from "../../../hooks/useInvalidateCoachProgramAndGroupQueries";

const useArchiveClientsMutation = (status: UserInviteStatus[]) => {
  const queryClient = useQueryClient();

  const invalidateCoachProgramAndGroupQueries =
    useInvalidateCoachProgramAndGroupQueries();

  return useOptimisticUpdateMutation({
    queryKey: [COACH_CLIENTS_LIST_QUERY_KEY, { status }],
    mutationFn: ({
      clients,
      archived,
    }: {
      clients: ClientInfoDto[];
      archived: boolean;
    }) =>
      ClientsService.batchArchiveClients({
        clientIds: clients.map((c) => c.id),
        archived,
      }),
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfClientInfoDto, { clients }) => {
        oldData.items = oldData.items.filter(
          (client) => !clients.some((c) => c.id === client.id),
        );
        return oldData;
      },
    },
    options: {
      onSuccess: (_, vars) => {
        queryClient.invalidateQueries({
          queryKey: [
            COACH_CLIENTS_LIST_QUERY_KEY,
            {
              status: status.includes(UserInviteStatus.ACTIVE)
                ? [UserInviteStatus.ARCHIVED]
                : [UserInviteStatus.ACTIVE],
            },
          ],
        });
        vars.clients.forEach((c) => {
          queryClient.invalidateQueries({
            queryKey: [
              COACH_CLIENT_GENERIC_QUERY_KEY,
              { username: c.username },
            ],
          });
        });

        invalidateCoachProgramAndGroupQueries();
      },
    },
  });
};

export default useArchiveClientsMutation;
