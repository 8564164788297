import React from "react";
import { Box, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { HistoryBlock } from "../history-block/HistoryBlock";
import { AppLayout } from "../app/AppLayout";
import { useCurrentUserRole } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { polyfillCSS } from "../../utils/css";

import { ClientForm } from "./ClientForm";
import { useParams } from "react-router-dom";
import {
  CLIENT_PROFILE_ROUTE,
  COACH_CLIENT_OVERVIEW_ROUTE,
} from "../../routes/routes";
import { useQuery } from "@tanstack/react-query";
import ClientFormsClientService from "../../services/ClientFormsClientService";
import { RefreshSlug } from "../routes/RefreshSlug";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";
import { PageSkeleton } from "../loading/PageSkeleton";
import { DefaultLoader } from "../loading/DefaultLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    paddingTop: polyfillCSS("var(--safe-area-inset-top)"),
    paddingLeft: polyfillCSS("var(--safe-area-inset-left)"),
    paddingRight: polyfillCSS("var(--safe-area-inset-right)"),
    paddingBottom: polyfillCSS("var(--safe-area-inset-bottom)"),
    position: "relative",
  },

  back: {
    alignSelf: "flex-start",
    position: "absolute",
    top: polyfillCSS(`calc(var(--safe-area-inset-top) + ${theme.spacing(1)})`),
    left: polyfillCSS(
      `calc(var(--safe-area-inset-left) + ${theme.spacing(3)})`,
    ),
  },
}));

export const CLIENT_FORM_QUERY_KEY = "client-form";

export function ClientFormScreen() {
  const { slug, username } = useParams();
  const s = useStyles();
  const { data: clientForm, isLoading } = useQuery({
    queryKey: [CLIENT_FORM_QUERY_KEY, slug, username],
    queryFn: () => ClientFormsClientService.getForm(slug, username),
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const role = useCurrentUserRole();
  const returnUrl =
    role === UserRole.COACH
      ? COACH_CLIENT_OVERVIEW_ROUTE.replace(":username", username)
      : CLIENT_PROFILE_ROUTE;

  return (
    <AppLayout appDrawer={false} appBar={false}>
      <Box className={s.root}>
        {isLoading ? (
          <DefaultLoader fillScreen />
        ) : (
          <>
            <RefreshSlug
              slugInfo={{
                slug: clientForm?.slug,
                slugId: clientForm?.slugId,
              }}
            />
            <HistoryBlock>
              <ClientForm
                clientForm={clientForm}
                readOnly={true}
                disabled={role === UserRole.COACH}
                returnUrl={returnUrl}
              />
            </HistoryBlock>
          </>
        )}
      </Box>
    </AppLayout>
  );
}
