import { Card, CardProps, CardHeader, CardActions, Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

export interface ProgramCardSkeleton extends CardProps {
  view?: "grid" | "list";
}

export function ProgramCardSkeleton({
  view = "list",
  ...props
}: ProgramCardSkeleton) {
  return (
    <Card
      sx={{
        ...(view === "grid" && {
          width: "100%",
        }),
      }}
      {...props}
    >
      <CardHeader
        sx={{
          "&.MuiCardHeader-root": {
            height: view === "grid" ? 335 : 120,
            paddingLeft: 0,
            ...(view === "grid" && {
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }),
          },
          "& .MuiCardHeader-avatar": {
            padding: 0,
            ...(view === "grid"
              ? { width: "100%", height: 220, margin: 0 }
              : { width: 172, paddingRight: 2, height: 120 }),
          },

          // make title and subheader full width
          "& .MuiCardHeader-content": {
            width: "100%",
            ...(view === "grid" && { paddingLeft: 5, paddingTop: 2 }),
          },
        }}
        avatar={
          <Skeleton
            animation="wave"
            variant="rounded"
            width={"100%"}
            height={"100%"}
            sx={{
              ...(view === "list"
                ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                : {
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }),
            }}
          />
        }
        title={
          <Box width="10%" mb={1}>
            <Skeleton animation="wave" height={25} />
          </Box>
        }
        subheader={
          <>
            <Box width="30%" mb={1}>
              <Skeleton animation="wave" height={30} />
            </Box>
            <Box width="20%">
              <Skeleton animation="wave" height={20} />
            </Box>
          </>
        }
      />
    </Card>
  );
}
