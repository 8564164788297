import clsx from "clsx";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { WeekCard, WeekCardProps } from "../program-week/WeekCard";
import { useProgramSchedule } from "../../hooks/useProgramSchedule";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ComponentDialog from "./ComponentDialog";
import { NormalizedCurriculumProgram } from "../../redux/types";
import { denormalizeCurriculum } from "../../redux/normalize";
import { selectCurriculum } from "../../redux/curriculum/selectors/curriculum";
import { useCurriculumSelector } from "../../redux/hooks";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    margin: theme.spacing(3, 0),
  },
}));

export interface ProgramWeeksListProps extends BoxProps {
  program: NormalizedCurriculumProgram;
  filters?: WeekCardProps["filters"];
  startDate?: string;
  onOpenMenu?: WeekCardProps["onOpenMenu"];
  weeks?: number[];
  programId?: number;
  duplicateWeekInFlight: boolean;
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickMove: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
}

export function ProgramWeeksList(props: ProgramWeeksListProps) {
  const {
    className,
    program,
    filters,
    startDate,
    onOpenMenu,
    weeks,
    programId,
    duplicateWeekInFlight,
    weeksActions,
    ...other
  } = props;
  const s = useStyles();
  const navigate = useNavigate();
  const scheduleAssets = denormalizeCurriculum(
    useCurriculumSelector(selectCurriculum).value,
  );
  const [, schedule] = useProgramSchedule(scheduleAssets, filters);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const componentSlugSearchParams = searchParams.get("component");
  const programSlug = params.slug;

  const [componentSlug, setComponentSlug] = useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = (e?: any, slug?: string) => {
    slug && setComponentSlug(slug);
    navigate(
      `?component=${slug ?? componentSlugSearchParams ?? componentSlug}`,
      { replace: true },
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    // update search params
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("component");
    setSearchParams(updatedParams);
    setOpenDialog(false);
  };

  useEffect(() => {
    if (componentSlugSearchParams && programSlug) {
      setComponentSlug(componentSlugSearchParams);
      handleClickOpenDialog();
    }
  }, [componentSlugSearchParams]);

  useEffect(() => {
    searchParams.size === 0 && handleCloseDialog();
  }, [searchParams]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {weeks.map((week, i) => (
        <WeekCard
          className={s.card}
          key={week}
          weekId={week}
          initSchedule={schedule}
          filters={filters}
          startDate={startDate}
          onOpenMenu={onOpenMenu}
          programId={programId}
          handleClickOpenDialog={handleClickOpenDialog}
          duplicateWeekInFlight={duplicateWeekInFlight}
          weeksActions={weeksActions}
        />
      ))}
      <ComponentDialog
        componentSlug={componentSlug}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleClickOpenDialog={handleClickOpenDialog}
      />
    </Box>
  );
}
