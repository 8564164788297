import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const lockAllComponentsAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    programId: number;
    locked: boolean;
  }>,
) => {
  const { programId, locked } = action.payload;
  if (!programId) return;

  state.value.components = Object.fromEntries(
    Object.entries(state.value.components).map(([key, component]) => [
      key,
      { ...component, locked },
    ]),
  );
};
