import React from "react";
import { ListMenu, ListMenuProps } from "./ListMenu";
import useResendInviteMutation from "../coach-clients/mutations/useResendInviteMutation";
import useRevokeInviteMutation from "../coach-clients/mutations/useRevokeInviteMutation";
import useRevokeAddedClientMutation from "../coach-clients/mutations/useRevokeAddedClientMutation";

export interface ClientPendingMenuProps extends Omit<ListMenuProps, "options"> {
  inviteId: number;
  clientId: number;
  isPending: boolean;
  onSendInvite: () => void;
}

export function ClientPendingMenu(props: ClientPendingMenuProps) {
  const { inviteId, onClose, isPending, clientId, onSendInvite, ...other } =
    props;
  const { mutate: resendInvite } = useResendInviteMutation();
  const { mutate: revokeInvite } = useRevokeInviteMutation();
  const { mutate: deleteUser } = useRevokeAddedClientMutation();

  const handleResendInvite = React.useCallback(
    (event) => {
      resendInvite(
        {
          id: inviteId,
        },
        {
          onSuccess: () => {
            onClose(event, "escapeKeyDown");
          },
        },
      );
    },
    [inviteId, resendInvite, onClose],
  );

  const handleRevokeInvite = React.useCallback(
    (event) => {
      revokeInvite(inviteId, {
        onSuccess: () => {
          onClose(event, "escapeKeyDown");
        },
      });
    },
    [revokeInvite, inviteId, onClose],
  );

  const handleRevokeAddedClient = React.useCallback(
    (event) => {
      deleteUser(clientId, {
        onSuccess: () => {
          onClose(event, "escapeKeyDown");
        },
      });
    },
    [onClose, clientId, deleteUser],
  );

  const optionsPending = [
    {
      label: "Revoke Invite",
      onClick: handleRevokeInvite,
    },

    {
      label: "Resend Invite",
      onClick: handleResendInvite,
    },
  ];

  const optionsAdded = [
    {
      label: "Delete",
      onClick: handleRevokeAddedClient,
    },

    {
      label: "Send Invite",
      onClick: onSendInvite,
    },
  ];

  return (
    <ListMenu
      options={isPending ? optionsPending : optionsAdded}
      onClose={onClose}
      {...other}
    />
  );
}
