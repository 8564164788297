import { userEmailsClient, fbUsersClient } from "../api/ApiClients";
import {
  ISendEmailVerificationCodeCommand,
  SendEmailVerificationCodeCommand,
  IUpdateEmailCommand,
  UpdateEmailCommand
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class UserEmailsService extends ServiceBase {
  async sendEmailVerificationCode(variables: ISendEmailVerificationCodeCommand) {
    const command = SendEmailVerificationCodeCommand.fromJS(variables);
    return userEmailsClient.sendEmailVerificationCode(command);
  }

  async updateEmail(variables: IUpdateEmailCommand) {
    const command = UpdateEmailCommand.fromJS(variables);
    return fbUsersClient.updateEmail(command);
  }
}

export default new UserEmailsService();
