import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import {
  selectComponentTempIdsMap,
  selectWeekTempIdsMap,
} from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";
import { IUpdateComponentCommand } from "@growth-machine-llc/stridist-api-client";

export default function* updateComponentWeekWorker(payload: {
  componentId: number;
  newWeekId: number;
}) {
  const { componentId, newWeekId } = payload;

  const componentMap: TempIdsMap = yield select(selectComponentTempIdsMap);
  const weekMap: TempIdsMap = yield select(selectWeekTempIdsMap);

  const checkedComponentId = componentMap?.[componentId] || componentId;
  const checkedNewWeekId = weekMap?.[newWeekId] || newWeekId;

  const command: IUpdateComponentCommand = {
    id: checkedComponentId,
    weekId: checkedNewWeekId,
  };

  try {
    yield call(ComponentsService.update, command);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
