import React from "react";

import ClientStatsSkeleton from "../loading/ClientStatsSkeleton";

import { ClientCardCompletion } from "./ClientCardCompletion";
import { ClientStatsDto } from "@growth-machine-llc/stridist-api-client";

export interface ClientStatsProps {
  stats: ClientStatsDto;
}

const ClientStatsCard = ({ stats }: ClientStatsProps) => (
  <ClientCardCompletion stats={stats} />
);

export const ClientStats = ({ stats }: ClientStatsProps) =>
  stats ? <ClientStatsCard stats={stats} /> : <ClientStatsSkeleton />;
