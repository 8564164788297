import React from "react";

const Goals = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 473 288"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M472.782 287.568H0V286.367H473L472.782 287.568Z" fill="#3F3D56" />
    <path
      d="M412.832 96.5642L400.868 120.257L365.096 136.525C361.276 142.984 349.137 143.478 348.254 139.302C347.263 134.616 362.995 127.075 362.995 127.075L392.916 106.83L395.534 89.4023L412.832 96.5642Z"
      fill="#A0616A"
    />
    <path
      d="M411.421 255.561L413.627 273.218L400.83 274.101L399.507 255.561H411.421Z"
      fill="#A0616A"
    />
    <path
      d="M409.656 270.57C410.8 269.36 411.855 268.069 412.814 266.707C414.648 264.168 416.275 277.192 416.275 277.192C416.275 277.192 418.04 282.489 417.599 285.137C417.157 287.786 407.008 287.786 405.243 287.344C403.478 286.903 394.653 287.344 394.653 287.344H381.415C369.942 282.047 381.415 278.516 381.415 278.516C384.945 278.074 396.859 267.039 396.859 267.039L399.507 262.183C401.272 261.742 403.037 268.363 403.037 268.363L409.656 270.57Z"
      fill="#2F2E41"
    />
    <path
      d="M444.516 255.781L446.723 273.438L433.926 274.321L432.602 255.781H444.516Z"
      fill="#A0616A"
    />
    <path
      d="M442.751 270.791C443.895 269.581 444.95 268.29 445.909 266.928C447.743 264.389 449.37 277.412 449.37 277.412C449.37 277.412 451.135 282.268 450.694 284.916C450.253 287.565 440.103 287.565 438.338 287.123C436.573 286.682 427.748 287.565 427.748 287.565H414.51C403.037 282.268 414.51 278.737 414.51 278.737C418.04 278.295 429.954 267.26 429.954 267.26L432.602 262.404C434.367 261.963 436.132 268.584 436.132 268.584L442.751 270.791Z"
      fill="#2F2E41"
    />
    <path
      d="M429.954 141.895C431.719 144.102 430.837 151.606 430.837 151.606C430.837 151.606 433.926 175.885 432.602 178.092C431.278 180.299 433.484 182.064 434.808 185.154C436.132 188.244 437.456 195.749 437.456 195.749C444.958 201.928 444.516 230.179 444.516 230.179L447.164 255.341C446.281 257.989 433.926 258.43 432.161 257.989C430.396 257.548 425.1 217.82 425.1 217.82L413.186 195.307C413.186 195.307 414.068 255.34 414.068 257.989C414.068 260.638 402.154 259.313 399.507 259.313C396.859 259.313 396.859 215.171 396.859 215.171L394.211 203.694L380.091 151.165V144.985L382.297 141.895C382.297 141.895 428.189 139.688 429.954 141.895Z"
      fill="#2F2E41"
    />
    <path
      d="M410.538 41.6943C418.093 41.6943 424.218 35.5678 424.218 28.0103C424.218 20.4527 418.093 14.3262 410.538 14.3262C402.984 14.3262 396.859 20.4527 396.859 28.0103C396.859 35.5678 402.984 41.6943 410.538 41.6943Z"
      fill="#A0616A"
    />
    <path
      d="M403.699 34.4102C406.471 40.5868 404.83 49.2139 399.286 58.6884L421.791 48.0943L418.26 45.0043L419.143 36.1759L403.699 34.4102Z"
      fill="#A0616A"
    />
    <path
      d="M403.699 48.5353C400.752 50.288 398.989 53.5082 397.977 56.785C396.103 62.8517 394.975 69.1242 394.618 75.4637L393.549 94.4432L380.311 144.765C391.784 154.477 398.403 152.27 413.848 144.324C429.292 136.378 431.057 146.972 431.057 146.972C431.057 146.972 432.381 146.531 431.057 145.207C429.734 143.882 431.057 145.207 432.381 143.882C433.705 142.558 432.381 143.882 431.94 142.558C431.499 141.234 431.94 142.117 432.381 141.675C432.822 141.234 430.616 137.261 430.616 137.261L434.146 104.154L438.559 57.3637C433.264 50.7424 418.261 45.0039 418.261 45.0039L404.581 54.7152C400.168 56.4808 403.699 49.4181 403.699 49.4181V48.5353Z"
      fill={fill}
    />
    <path
      d="M445.84 85.3945L447.605 111.88L425.1 144.104C425.1 151.608 421.129 153.374 421.129 153.374C419.625 150.972 418.297 148.464 417.157 145.869C414.951 141.014 418.481 137.041 418.481 137.041L433.926 104.376L427.307 88.0431L445.84 85.3945Z"
      fill="#A0616A"
    />
    <path
      d="M438.338 57.5843C445.84 60.2328 447.164 88.9252 447.164 88.9252C438.338 84.0696 427.748 92.0151 427.748 92.0151C427.748 92.0151 425.541 84.511 422.894 74.7997C422.094 72.0681 421.999 69.1785 422.616 66.4C423.233 63.6215 424.543 61.0444 426.424 58.9085C426.424 58.9085 430.837 54.9357 438.338 57.5843Z"
      fill={fill}
    />
    <path
      d="M423.115 32.2021C421.373 30.8085 418.997 33.3417 418.997 33.3417L417.604 20.799C417.604 20.799 408.897 21.8442 403.324 20.4506C397.752 19.0569 396.881 25.5025 396.881 25.5025C396.594 22.8996 396.535 20.2764 396.707 17.6633C397.055 14.5276 401.583 11.3919 409.594 9.30148C417.604 7.21102 421.784 16.2697 421.784 16.2697C427.356 19.0569 424.856 33.5958 423.115 32.2021Z"
      fill="#2F2E41"
    />
    <path
      d="M22.2352 216.389C23.7392 222.361 36.5635 235.792 49.7963 248.404C29.3902 197.984 41.0184 142.803 41.0184 142.803C37.2294 164.475 89.1582 273.536 89.1582 273.536L77.3306 280.512L73.3351 286.137C33.2822 267.735 22.2352 216.389 22.2352 216.389Z"
      fill="#E6E6E6"
    />
    <path d="M367.326 104.584H200.04V137.624H367.326V104.584Z" fill="#E6E6E6" />
    <path d="M367.326 52.3789H200.04V85.4185H367.326V52.3789Z" fill="#E6E6E6" />
    <path d="M367.326 0H200.04V33.0396H367.326V0Z" fill="#E6E6E6" />
    <path d="M203.606 29.4709H363.76V3.56641H203.606V29.4709Z" fill="white" />
    <path d="M203.606 81.965H363.76V56.0605H203.606V81.965Z" fill="white" />
    <path d="M203.606 134.057H363.76V108.152H203.606V134.057Z" fill="white" />
    <path
      d="M217.627 26.1735C223.075 26.1735 227.492 21.755 227.492 16.3045C227.492 10.854 223.075 6.43555 217.627 6.43555C212.178 6.43555 207.761 10.854 207.761 16.3045C207.761 21.755 212.178 26.1735 217.627 26.1735Z"
      fill={fill}
    />
    <path d="M338.587 12.4453H241.218V14.5907H338.587V12.4453Z" fill={fill} />
    <path d="M338.587 18.0215H241.218V20.1669H338.587V18.0215Z" fill={fill} />
    <path
      d="M217.627 78.9821C223.075 78.9821 227.492 74.5636 227.492 69.1131C227.492 63.6626 223.075 59.2441 217.627 59.2441C212.178 59.2441 207.761 63.6626 207.761 69.1131C207.761 74.5636 212.178 78.9821 217.627 78.9821Z"
      fill="#CCCCCC"
    />
    <path d="M338.587 65.252H241.218V67.3974H338.587V65.252Z" fill="#CCCCCC" />
    <path
      d="M338.587 70.8281H241.218V72.9736H338.587V70.8281Z"
      fill="#CCCCCC"
    />
    <path
      d="M217.627 131.189C223.075 131.189 227.492 126.771 227.492 121.32C227.492 115.87 223.075 111.451 217.627 111.451C212.178 111.451 207.761 115.87 207.761 121.32C207.761 126.771 212.178 131.189 217.627 131.189Z"
      fill="#CCCCCC"
    />
    <path
      d="M338.587 117.457H241.218V119.602H338.587V117.457Z"
      fill="#CCCCCC"
    />
    <path
      d="M338.587 123.035H241.218V125.181H338.587V123.035Z"
      fill="#CCCCCC"
    />
    <path
      d="M76.7051 95.3037C76.7051 95.3037 74.3985 109.807 83.9543 108.489C83.9543 108.489 121.848 99.9184 123.825 101.896C125.802 103.874 138.093 104.547 137.452 96.2092C136.808 87.8431 124.484 92.6668 124.484 92.6668C124.484 92.6668 105.372 95.3037 101.418 93.326C97.4641 91.3483 86.2608 92.0075 86.2608 92.0075L76.7051 95.3037Z"
      fill="#A0616A"
    />
    <path
      d="M105.622 142.602L100.368 192.472C100.368 192.472 98.7205 212.249 93.7779 216.534C93.7779 216.534 93.7779 222.797 92.1303 224.775L89.8238 250.815C89.8238 250.815 93.1189 260.374 92.7894 262.022C92.4599 263.67 94.1074 268.944 93.7779 269.603C93.4484 270.262 77.9615 269.603 77.9615 269.603L81.2566 216.534L91.4713 142.699L105.622 142.602Z"
      fill="#2F2E41"
    />
    <path
      d="M76.7544 266.907C76.7544 266.907 69.844 284.339 73.4494 286.443C77.0548 288.547 114.912 287.044 114.912 287.044C114.912 287.044 121.221 283.137 114.01 280.432C114.01 280.432 108.001 280.131 104.096 277.727C100.19 275.322 95.6829 270.814 95.6829 270.814C95.6829 270.814 95.3825 264.202 92.9789 265.705C92.2597 266.179 91.6476 266.798 91.182 267.522C90.7163 268.247 90.4073 269.061 90.2748 269.912L76.7544 266.907Z"
      fill="#2F2E41"
    />
    <path
      d="M66.0992 264.543C66.0992 264.543 58.5206 283.661 62.4747 285.968C66.4287 288.275 107.947 286.627 107.947 286.627C107.947 286.627 114.867 282.342 106.958 279.376C106.958 279.376 100.368 279.046 96.0845 276.409C91.8009 273.772 86.8583 268.828 86.8583 268.828C86.8583 268.828 86.5288 261.576 83.8927 263.224C83.104 263.744 82.4328 264.423 81.9221 265.218C81.4113 266.013 81.0724 266.905 80.9272 267.839L66.0992 264.543Z"
      fill="#2F2E41"
    />
    <path
      d="M96.7435 141.924C96.7435 141.924 93.1189 175.875 91.4714 183.126C90.4355 187.902 89.7745 192.751 89.4943 197.63C89.4943 197.63 89.1648 208.177 86.1992 211.144C85.3316 212.003 84.6498 213.032 84.1963 214.166C83.7429 215.3 83.5273 216.516 83.5632 217.737C83.5632 217.737 80.5976 251.028 82.2451 254.984C83.8926 258.939 86.1293 264.078 84.4818 266.715C82.8343 269.352 64.1222 267.509 64.1222 265.532C64.1222 263.554 64.1222 261.906 63.1336 260.917C62.1451 259.928 63.1336 253.665 63.1336 253.665C63.1336 253.665 59.1795 224.329 62.8041 215.759C66.4287 207.189 68.0763 183.786 68.0763 183.786C68.0763 183.786 65.4402 157.086 62.1451 152.801C58.85 148.516 62.8041 133.354 62.8041 133.354L96.7435 141.924Z"
      fill="#2F2E41"
    />
    <path
      d="M87.4665 36.491C87.8495 37.3639 88.7664 37.85 89.6012 38.31C94.439 40.9762 98.4885 44.8417 102.478 48.6626C101.483 45.2878 101.695 41.6713 103.078 38.436C103.555 37.3325 104.179 36.1464 103.838 34.9937C103.502 34.1697 102.918 33.4707 102.167 32.9938C100.539 31.7344 98.8378 30.5731 97.0717 29.5161C95.838 28.7777 93.3014 26.812 91.8856 27.0259C91.8113 28.9396 91.7724 30.6504 90.9347 32.4389C90.736 32.8632 87.6129 36.8248 87.4665 36.491Z"
      fill="#A0616A"
    />
    <path
      d="M104.322 40.7301C111.783 40.7301 117.832 34.6795 117.832 27.2156C117.832 19.7518 111.783 13.7012 104.322 13.7012C96.8608 13.7012 90.8123 19.7518 90.8123 27.2156C90.8123 34.6795 96.8608 40.7301 104.322 40.7301Z"
      fill="#A0616A"
    />
    <path
      d="M104.981 59.19C104.981 59.19 107.288 47.6532 100.698 43.6977C94.1074 39.7423 86.5287 35.1276 86.5287 35.1276C86.5287 35.1276 84.2221 34.798 83.2336 39.7423C83.2336 39.7423 64.4516 43.3682 62.4745 61.1677C60.4975 78.9673 63.7926 105.007 63.7926 105.007C63.7926 105.007 60.827 127.751 57.2024 139.947C57.2024 139.947 54.2369 142.584 67.4172 138.629C80.5976 134.673 103.663 149.836 103.663 149.836C103.663 149.836 105.97 147.528 105.97 145.88C105.97 144.232 110.912 94.1298 111.901 89.5151C112.889 84.9004 107.947 59.8492 104.981 59.19Z"
      fill="#E6E6E6"
    />
    <path
      d="M101.138 28.6091C101.95 28.1591 102.86 27.9167 103.788 27.9032C104.717 27.8893 105.624 27.619 106.409 27.122C107.685 26.142 107.779 24.2479 108.712 22.9372C110.064 21.0395 112.76 20.8111 115.088 20.906C116.511 20.9641 118.044 21.0492 119.243 20.279C120.473 19.4883 121.031 17.9986 121.446 16.5957C121.937 14.9366 122.254 12.9077 121.038 11.6773C120.194 10.8229 118.861 10.6539 117.923 9.9035C117.415 9.49738 110.705 7.86662 104.406 6.40031C101.27 5.67146 97.9753 6.11733 95.1456 7.65361C92.3158 9.18989 90.1469 11.7101 89.0488 14.7377C87.5942 18.7388 86.3476 22.4982 86.4763 23.3103C87.0921 27.1966 90.5279 30.2858 90.7647 34.2136C90.9903 33.129 91.4816 32.1174 92.1944 31.2695C92.9072 30.4216 93.8192 29.764 94.8486 29.3556C95.1068 29.2293 95.3999 29.1938 95.6807 29.2546C96.0221 29.3638 96.2216 29.7049 96.437 29.9914C97.0015 30.7421 98.7746 31.8809 99.7797 31.3484C100.679 30.8719 100.008 29.3762 101.138 28.6091Z"
      fill="#3F3D56"
    />
    <path
      d="M107.316 99.6629C107.033 99.9403 106.871 100.318 106.866 100.714C106.86 101.11 107.011 101.493 107.285 101.778L116.785 111.614C116.923 111.757 117.089 111.871 117.271 111.95C117.454 112.028 117.65 112.07 117.849 112.072C118.048 112.074 118.245 112.036 118.429 111.961C118.613 111.886 118.781 111.775 118.922 111.635L147.91 82.7619C148.068 82.6041 148.189 82.413 148.264 82.2024C148.339 81.9918 148.366 81.7671 148.343 81.5447C148.32 81.3224 148.248 81.108 148.132 80.9172C148.015 80.7264 147.857 80.564 147.67 80.442L136.271 73.0151C135.986 72.8274 135.646 72.7433 135.306 72.7767C134.966 72.8101 134.648 72.9591 134.405 73.1988L107.316 99.6629Z"
      fill="#3F3D56"
    />
    <path
      d="M148.109 80.8789C148.051 80.9673 147.985 81.0493 147.91 81.1238L118.99 110.542C118.849 110.682 118.681 110.793 118.497 110.868C118.313 110.943 118.116 110.981 117.917 110.979C117.718 110.977 117.522 110.936 117.339 110.857C117.157 110.778 116.991 110.664 116.853 110.521L107.149 100.277C107.083 100.208 107.023 100.133 106.972 100.053C106.81 100.33 106.752 100.657 106.81 100.973C106.867 101.289 107.036 101.574 107.285 101.777L116.785 111.612C116.923 111.755 117.088 111.869 117.271 111.948C117.454 112.027 117.65 112.068 117.849 112.07C118.048 112.072 118.245 112.034 118.429 111.959C118.613 111.884 118.781 111.773 118.921 111.633L147.91 82.7601C148.154 82.517 148.307 82.1974 148.343 81.8548C148.379 81.5122 148.296 81.1677 148.109 80.8789H148.109Z"
      fill={fill}
    />
    <path
      d="M145.425 81.2335L144.245 80.4811C144.369 80.7026 144.415 80.9597 144.374 81.2104C144.334 81.4612 144.21 81.6908 144.022 81.862C143.835 82.0332 143.595 82.1358 143.341 82.1531C143.088 82.1704 142.836 82.1014 142.627 81.9574L134.994 76.7074C134.82 76.5882 134.684 76.4227 134.599 76.2297C134.515 76.0367 134.487 75.8239 134.518 75.6157C134.549 75.4074 134.638 75.2121 134.775 75.0519C134.911 74.8917 135.09 74.7731 135.291 74.7096L134.683 74.322C134.604 74.2717 134.51 74.2499 134.417 74.2604C134.324 74.2708 134.238 74.3128 134.172 74.3794L108.492 99.6248C108.453 99.6636 108.423 99.7097 108.403 99.7603C108.383 99.8109 108.373 99.8651 108.374 99.9196C108.375 99.9741 108.387 100.028 108.409 100.078C108.431 100.128 108.462 100.173 108.502 100.21L117.829 109.301C117.906 109.374 118.009 109.414 118.115 109.412C118.221 109.411 118.323 109.368 118.398 109.293L145.494 81.8682C145.537 81.8249 145.57 81.7725 145.59 81.7147C145.611 81.6569 145.618 81.5953 145.611 81.5343C145.605 81.4734 145.585 81.4147 145.552 81.3627C145.52 81.3106 145.476 81.2664 145.425 81.2335Z"
      fill="white"
    />
    <path
      d="M115.786 94.0524L123.664 101.483L142.401 82.9632L132.892 76.7715L115.786 94.0524Z"
      fill="#E6E6E6"
    />
    <path
      d="M141.674 87.5318C141.725 87.5814 141.795 87.609 141.866 87.609C141.938 87.609 142.007 87.5814 142.059 87.5318L143.698 85.8926C143.749 85.8414 143.777 85.7721 143.777 85.6998C143.777 85.6275 143.749 85.5581 143.698 85.5069C143.646 85.4574 143.577 85.4297 143.505 85.4297C143.433 85.4297 143.364 85.4574 143.312 85.5069L141.674 87.1461C141.623 87.1973 141.594 87.2667 141.594 87.3389C141.594 87.4112 141.623 87.4806 141.674 87.5318Z"
      fill="#3F3D56"
    />
    <path
      d="M138.913 90.2936C138.964 90.3447 139.034 90.3733 139.106 90.3733C139.178 90.3733 139.247 90.3447 139.299 90.2936L140.937 88.6544C140.988 88.6032 141.017 88.5339 141.017 88.4617C141.016 88.3896 140.988 88.3204 140.937 88.2693C140.886 88.2183 140.817 88.1896 140.744 88.1895C140.672 88.1893 140.603 88.2178 140.552 88.2687L138.913 89.9079C138.862 89.9591 138.833 90.0285 138.833 90.1008C138.833 90.1731 138.862 90.2424 138.913 90.2936Z"
      fill="#3F3D56"
    />
    <path
      d="M115.968 102.272C116.984 102.272 117.808 101.447 117.808 100.431C117.808 99.414 116.984 98.5898 115.968 98.5898C114.951 98.5898 114.127 99.414 114.127 100.431C114.127 101.447 114.951 102.272 115.968 102.272Z"
      fill={fill}
    />
    <path
      d="M67.0876 90.1729C67.0876 90.1729 64.7811 104.676 74.3368 103.358C74.3368 103.358 112.23 94.7876 114.207 96.7653C116.184 98.7431 128.476 99.4163 127.834 91.0783C127.191 82.7122 114.866 87.5359 114.866 87.5359C114.866 87.5359 95.7549 90.1729 91.8008 88.1951C87.8467 86.2174 76.6434 86.8766 76.6434 86.8766L67.0876 90.1729Z"
      fill="#A0616A"
    />
    <path
      d="M80.5976 44.3555C80.5976 44.3555 65.7697 46.0036 65.7697 67.4289V94.7875L83.8927 87.2062C83.8927 87.2062 91.4713 69.4067 90.8124 64.4624C90.1534 59.5181 90.4828 45.0147 80.5976 44.3555Z"
      fill="#E6E6E6"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="473" height="287.568" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Goals;
