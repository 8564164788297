import { fbUsersClient } from "../api/ApiClients";
import {
  ClientSignUpCommand,
  ClientSignUpWithAuthTokenCommand,
  CompleteCoachSignUpCommand,
  IClientSignUpCommand,
  IClientSignUpWithAuthTokenCommand,
  ICompleteCoachSignUpCommand,
  IPreCoachSignUpCommand,
  IResetPasswordCommand,
  PreCoachSignUpCommand,
  ResetPasswordCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class SignUpService extends ServiceBase {
  async clientSignUp(variables: IClientSignUpCommand) {
    const command = ClientSignUpCommand.fromJS(variables);
    return fbUsersClient.clientSignUp(command);
  }

  async preCoachSignUp(variables: IPreCoachSignUpCommand) {
    const command = PreCoachSignUpCommand.fromJS(variables);
    return fbUsersClient.preCoachSignUp(command);
  }

  async completeCoachSignUp(variables: ICompleteCoachSignUpCommand) {
    const command = CompleteCoachSignUpCommand.fromJS(variables);
    return fbUsersClient.completeCoachSignUp(command);
  }

  async clientSignUpWithInviteCode(
    variables: IClientSignUpWithAuthTokenCommand,
  ) {
    const command = ClientSignUpWithAuthTokenCommand.fromJS(variables);
    return fbUsersClient.clientSignUpWithAuthToken(command);
  }

  async resetPassword(
    variables: IResetPasswordCommand
  ){
    const command = ResetPasswordCommand.fromJS(variables);
    fbUsersClient.resetPassword(command);
  }
}

export default new SignUpService();
