import React, { useContext } from "react";
import { StartWorkout } from "../../../../../components/startWorkout/StartWorkout";
import { useCurrentUser } from "../../../../../hooks/useCurrentUser";
import { useProgramComponentRoute } from "../../../../../wrappers/router/common/ProgramsComponentRouteWrapper";

export function ClientProgramActivityStartRoute() {
  const { username } = useCurrentUser();

  const { activity } = useProgramComponentRoute();

  return <StartWorkout activity={activity} username={username} />;
}
