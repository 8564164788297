import clsx from "clsx";
import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../../components/dialog/FullScreenDialog";

import {
  ClientSettingsForm,
  ClientSettings,
} from "../../components/form/ClientSettingsForm";

import {
  BriefClientInfo,
  Gender,
  IBriefClientInfo,
  PaginatedListOfClientInfoDto,
  TagDto,
  UnitSystem,
} from "@growth-machine-llc/stridist-api-client";
import {
  useQuery,
  useQueryClient,
  useMutation as useReactQueryMutation,
} from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import LoadingActionButton from "../button/LoadingActionButton";
import { useMutation as useTanStackMutation } from "@tanstack/react-query";
import TagsService from "../../services/TagsService";
import { TAGS_QUERY_KEY } from "../../wrappers/router/coach/CoachClientsWrapper";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../wrappers/router/coach/CoachClientProfileWrapper";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "./CoachClientsListScreen";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: 32,
  },
  tabs: {
    marginBottom: theme.spacing(6),
  },
  submit: {
    margin: theme.spacing(4, 0),
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  notificationsTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },
  notificationsSubtitle: {
    fontSize: 16,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1.25),
  },
}));

export interface ClientSettingsDialogProps extends FullScreenDialogProps {
  client: BriefClientInfo;
}

export function ClientSettingsDialog(props: ClientSettingsDialogProps) {
  const { className, open, onClose, client, ...other } = props;
  const s = useStyles();
  const { displayName, id: clientId } = client;

  // TODO_API_V2_TAGS STR-1338: Currently settings first tag as default, since UI is not supporting multiple tags.
  const defaultTag = client.tags[0];
  const [tag, setTag] = React.useState(defaultTag?.title || "");

  const { data: allTags, isLoading: tagsLoading } = useQuery({
    // Using the same `TAGS_QUERY_KEY` as in `CoachClientsWrapper.tsx` to share the cache.
    queryKey: [TAGS_QUERY_KEY],
    queryFn: TagsService.getTags,
    enabled: open,
  });

  const { mutate: updateClient, isPending: updatingClient } =
    useReactQueryMutation({
      mutationFn: FbUsersService.updateUser,
      onSuccess: (_, variables) => {
        queryClient.setQueryData<IBriefClientInfo>(
          [COACH_CLIENT_GENERIC_QUERY_KEY, { username: client.username }],
          (prev) => ({
            ...prev,
            ...variables,
          }),
        );
      },
    });
  const { mutate: createTag, isPending: createTagInFlight } =
    useTanStackMutation({
      mutationFn: TagsService.createTag,
      onSuccess: (id, { title }) =>
        onTagsChange((prev) => [new TagDto({ id, title }), ...prev]),
    });

  const { mutate: assignTagToClient, isPending: assignTagToClientInFlight } =
    useTanStackMutation({
      mutationFn: TagsService.assignTagsToClient,
      onSuccess: (_data, { tagIds }) => onTagAssignmentChange(tagIds),
    });

  const { mutate: deleteTag, isPending: deleteTagInFlight } =
    useTanStackMutation({
      mutationFn: TagsService.deleteTag,
      onSuccess: (_, tagId) => {
        onTagsChange((prev) => prev.filter((tag) => tag.id !== tagId));

        if (defaultTag.id === tagId) {
          onTagAssignmentChange([]);
        }
      },
    });

  const { mutate: unAssignClientTag, isPending: unAssigningClientTagInFlight } =
    useTanStackMutation({
      onSuccess: (_data) => onTagAssignmentChange([]),
      mutationFn: (variables: { clientId: number }) =>
        TagsService.assignTagsToClient({ ...variables, tagIds: [] }),
    });

  const [settings, setSettings] = React.useState<ClientSettings>();
  const [settingsDirty, setSettingsDirty] = React.useState(false);

  const dirty = React.useMemo(() => settingsDirty, [settingsDirty]);

  const disabled =
    updatingClient ||
    createTagInFlight ||
    assignTagToClientInFlight ||
    deleteTagInFlight ||
    unAssigningClientTagInFlight;

  const queryClient = useQueryClient();

  const onTagsChange = React.useCallback(
    (updateFn: (oldData: TagDto[]) => TagDto[]) => {
      queryClient.setQueryData<TagDto[]>([TAGS_QUERY_KEY], updateFn);
    },
    [queryClient],
  );

  const onTagAssignmentChange = React.useCallback(
    (newClientTagIds: number[]) => {
      if (newClientTagIds) {
        const newTags = allTags.filter((tag) =>
          newClientTagIds?.includes(tag.id),
        );
        queryClient.setQueryData<IBriefClientInfo>(
          [COACH_CLIENT_GENERIC_QUERY_KEY, { username: client.username }],
          (prev) => ({
            ...prev,
            tags: newTags,
          }),
        );
        queryClient.invalidateQueries({
          queryKey: [COACH_CLIENTS_LIST_QUERY_KEY, { isDialog: false }],
        });
      }
    },
    [queryClient, allTags, client.username],
  );

  const handleSettingsChange = React.useCallback((settings: ClientSettings) => {
    setSettingsDirty(true);
    setSettings(settings);
  }, []);

  const handleTagChange = React.useCallback((tag: string) => {
    setSettingsDirty(true);
    setTag(tag);
  }, []);

  const handleCreateTag = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      const tagIndex = allTags.findIndex(
        (item) => item?.title.toUpperCase() === tag.toUpperCase(),
      );
      if (
        (defaultTag?.title.toUpperCase() !== tag.toUpperCase() && tag) ||
        (defaultTag?.title.toUpperCase() === tag.toUpperCase() &&
          tag &&
          tagIndex === -1)
      ) {
        if (tagIndex === -1) {
          createTag(
            { title: tag },
            {
              onSuccess(newTagId) {
                assignTagToClient({
                  tagIds: [newTagId],
                  clientId,
                });

                if (onClose && !settings) {
                  onClose(event, "backdropClick");
                }
              },
            },
          );
        } else {
          const tagId = allTags[tagIndex].id;

          assignTagToClient(
            { tagIds: [tagId], clientId },
            {
              onSuccess: () => {
                if (onClose && !settings) {
                  onClose(event, "backdropClick");
                }
              },
            },
          );
        }
      } else if (
        defaultTag?.title.toUpperCase() === tag.toUpperCase() &&
        tag &&
        tagIndex !== -1
      ) {
        if (onClose && !settings) {
          onClose(event, "backdropClick");
        }
      } else if (defaultTag?.title && !tag) {
        unAssignClientTag(
          {
            clientId,
          },
          {
            onSuccess() {
              if (onClose && !settings) {
                onClose(event, "backdropClick");
              }
            },
          },
        );
      } else if (!defaultTag?.title && !tag) {
        if (onClose && !settings) {
          onClose(event, "backdropClick");
        }
      }
    },
    [
      createTag,
      allTags,
      tag,
      defaultTag,
      onClose,
      settings,
      assignTagToClient,
      clientId,
      unAssignClientTag,
    ],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      handleCreateTag(event).then(() => {
        if (settings) {
          const { email, username, displayName, ...otherSettings } = settings;

          const input = {
            ...otherSettings,
            id: client.id,
            displayName,
            height: +settings.height,
            weight: +settings.weight,
            gender: settings.gender as Gender,
            units: settings.units as UnitSystem,
          };

          updateClient(input, {
            onSuccess() {
              setSettingsDirty(false);
              if (onClose) {
                onClose(event, "backdropClick");
              }
            },
          });
        }
      });
    },
    [settings, client.id, updatingClient, onClose, handleCreateTag],
  );

  const handleDeleteTag = React.useCallback(
    (tagId, tagTitle) => {
      deleteTag(tagId, {
        onSuccess(_, errors) {
          if (tagTitle === tag) {
            handleTagChange("");
          }
        },
      });
    },
    [deleteTag, tag, handleTagChange],
  );

  return (
    <FullScreenDialog
      className={clsx(s.root, className)}
      open={open}
      onClose={onClose}
      showBackButton={false}
      showCloseButton
      {...other}
    >
      <Typography
        className={s.title}
        variant="h1"
        children={displayName ? `${displayName}'s Settings` : "Client Settings"}
      />
      <form onSubmit={handleSubmit} style={{ paddingTop: "20px" }}>
        <ClientSettingsForm
          user={client}
          onChange={handleSettingsChange}
          disabled={disabled}
          variant="coach"
          onChangeTag={handleTagChange}
          tag={tag}
          allTags={allTags}
          tagsLoading={tagsLoading}
          onDeleteTag={handleDeleteTag}
        />

        <LoadingActionButton
          className={s.submit}
          disabled={!dirty}
          loading={updatingClient}
          size="large"
          type="submit"
          fullWidth
        >
          Update
        </LoadingActionButton>
      </form>
    </FullScreenDialog>
  );
}
