import clsx from "clsx";
import React, { useMemo } from "react";
import {
  Card,
  CardProps,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
  Divider,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentType, UserRole, ActivityType } from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Link } from "../link/Link";
import { ActivityResponseItem } from "../activity/ActivityResponseItem";
import { ActivityResponseMealsList } from "../activity/ActivityResponseMealsList";
import { colorSystem } from "../../theme";
import { FeedbackForm } from "../form/FeedbackForm";
import { StarRating } from "../fields/StarRating";
import { ZoomableImage } from "../image/ZoomableImage";
import { maybePluralize } from "../../utils/text";

import { ReactComponent as PdfIcon } from "../../icons/pdf.svg";
import { ReactComponent as FileIcon } from "../../icons/paper-clip.svg";
import { ReactComponent as UnarchiveIcon } from "../../icons/ArrowBack.svg";
import { ReactComponent as PencilIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/HighlightOff.svg";

import { ActivityWorkoutResponse } from "./ActivityWorkoutResponse";
import {
  ActivityDto,
  MetricSourceType,
} from "@growth-machine-llc/stridist-api-client";
import { humanizeDate } from "../../utils/user";
import { IFileQuestionAnswer } from "../new-editor/components/checkInQuestions/FileQuestion";
import { useCoachReadAnswerMutation } from "./mutations/useCoachReadAnswerMutation";
import { useCoachToggleArchiveActivityMutation } from "./mutations/useCoachToggleArchiveActivityMutation";
import { useCreateActivityFeedbackMutation } from "../form/mutations/useCreateActivityFeedbackMutation";
import dayjs from "dayjs";
import { formatUtcTimeAgo } from "../../utils/date";
import {
  CLIENT_PROFILE_ROUTE,
  COACH_CLIENT_OVERVIEW_ROUTE,
} from "../../routes/routes";
import { TimeAgo } from "../../routes/utils/TimeAgo";
import { WorkoutExercise, WorkoutSection } from "../workout/types";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(4),
      overflow: "visible",

      "& a": {
        color: "inherit",
        textDecoration: "none !important",
      },
    },

    content: {
      padding: theme.spacing(3),
    },

    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },

    headerInfo: {
      display: "flex",
    },

    avatar: {
      width: 70,
      height: 70,
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },

    headerText: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },

    clientName: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "22px",
      color: theme.palette.common.black,
      marginBottom: theme.spacing(0.5),
    },

    title: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.common.black,
      marginBottom: theme.spacing(0.5),
    },

    otherInfo: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },

    actionButton: {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: "transparent",
      boxShadow: "none",
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: "16px",
      textTransform: "uppercase",

      "& svg": {
        width: 24,
        height: 24,
      },
    },

    archiveButton: {
      padding: theme.spacing(0.5),
    },

    metric: {
      marginTop: theme.spacing(3),

      "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },

    question: {
      fontSize: 14,
      fontWeight: 600,
      fontStyle: "italic",
      lineHeight: "17px",
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(1),
    },

    answer: {
      display: "flex",
      flexFlow: "column nowrap",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.common.black,
      whiteSpace: "pre-wrap",

      [theme.breakpoints.up("sm")]: {
        flexFlow: "row nowrap",
      },
    },

    file: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: colorSystem.white2,
      border: `1px solid ${colorSystem.secondaryGray}`,
      borderRadius: theme.spacing(1),
      cursor: "pointer",
    },

    caption: {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: theme.spacing(1),
      color: theme.palette.common.black,
    },

    actions: {
      padding: 0,
    },

    feedbacks: {
      width: "100%",
    },

    feedback: {
      padding: theme.spacing(3),
    },

    image: {
      width: "100%",
      height: theme.spacing(35),
      border: "1px solid",
      borderColor: theme.palette.border.primary,
      borderRadius: theme.spacing(1),
      objectFit: "cover",

      "&:not(:last-child)": {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
          marginBottom: 0,
          marginRight: theme.spacing(1),
        },
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "30%",
      },
    },

    tooltip: {
      padding: theme.spacing(0.75, 2),
    },

    form: {
      padding: theme.spacing(2, 3),
      backgroundColor: colorSystem.secondaryGray,
      flexDirection: "column",

      "& .MuiInputBase-root": {
        borderRadius: 0,
      },

      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
    },

    formButtons: {
      right: theme.spacing(3),
      top: theme.spacing(3),

      [theme.breakpoints.up("md")]: {
        right: theme.spacing(27.25),
      },
    },

    submitButton: {
      width: "100%",
      marginLeft: 0,
      marginTop: theme.spacing(2),

      [theme.breakpoints.up("md")]: {
        width: "initial",
        marginLeft: theme.spacing(2),
        marginTop: 0,
      },
    },

    avatarLink: {
      display: "none",

      [theme.breakpoints.up("sm")]: {
        display: "initial",
      },
    },
  };
});

export interface ActivityResponseCardProps extends CardProps {
  activity: ActivityDto;
  enableArchive?: boolean;
  handleOpenVideo?: (url) => void;
}

export function ActivityResponseCard(props: ActivityResponseCardProps) {
  const {
    className,
    activity,
    enableArchive = true,
    handleOpenVideo,
    ...other
  } = props;
  const s = useStyles();

  const { client, component, enrollment, answers } = activity;

  const metrics = useMemo(
    () =>
      activity.metrics.map((metric) => ({
        ...metric,
        ...(metric.type === MetricSourceType.CHECKIN_ANSWER_FILE &&
          (JSON.parse(metric.value) as IFileQuestionAnswer)),
      })),
    [activity.metrics],
  );

  const program = enrollment?.program;
  const { mutate: archive, isPending: archiveInFlight } =
    useCoachToggleArchiveActivityMutation();
  const { mutate: read, isPending: readInFlight } =
    useCoachReadAnswerMutation();

  const createNewFeedback = useCreateActivityFeedbackMutation();

  const user = useCurrentUser();

  React.useEffect(() => {
    if (
      user.role === UserRole.COACH &&
      !activity.coachReadAnswers &&
      !readInFlight
    ) {
      read(activity.id);
    }
  }, [
    read,
    user.id,
    user.role,
    readInFlight,
    activity.id,
    activity.coachReadAnswers,
  ]);

  const toggleArchived = React.useCallback(
    (archived: boolean) => {
      archive({ id: activity.id, archived });
    },
    [activity.id, archive],
  );

  const handleArchiveClick = React.useCallback(() => {
    toggleArchived(true);
  }, [toggleArchived]);

  const handleUnarchiveClick = React.useCallback(() => {
    toggleArchived(false);
  }, [toggleArchived]);

  const handleFileClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const {
        currentTarget: {
          dataset: { url },
        },
      } = event;

      if (url) {
        window.open(url, "_blank").focus();
      }
    },
    [],
  );

  const completedSets = useMemo(() => {
    if (!activity.workoutExerciseResults?.length) {
      return 0;
    }

    const workoutSection = JSON.parse(
      activity.workoutExerciseResults[0].value,
    ) as WorkoutSection;
    const exercises = workoutSection.exercises || [];

    return exercises.reduce((count, exercise) => {
      const exerciseSets = exercise.sets || [];
      return count + exerciseSets.filter((set) => set.checked).length;
    }, 0);
  }, [activity.workoutExerciseResults]);

  const clientHref = React.useMemo(
    () =>
      user?.role === UserRole.COACH
        ? COACH_CLIENT_OVERVIEW_ROUTE.replace(":username", client?.username)
        : CLIENT_PROFILE_ROUTE,
    [client],
  );

  const activityHref = React.useMemo(() => {
    const date = activity.date.format("YYYY-MM-DD");
    const href =
      activity.type === ActivityType.CLIENT_FORM &&
      user.role === UserRole.CLIENT
        ? `/forms/${activity?.clientForm?.slug}`
        : activity.type === ActivityType.MEAL_LOGGING &&
            user.role === UserRole.CLIENT
          ? `/meal-logging/${date}`
          : program && component
            ? `/programs/${program.slug}/${date}/${component.slug}`
            : undefined;

    if (href) {
      return user.role === UserRole.COACH ? `${clientHref}${href}` : href;
    }
  }, [activity, user.role, program, component, clientHref]);

  const titleNode = React.useMemo(() => {
    const href =
      activity.type === ActivityType.PROGRAM_COMPONENT
        ? `${activityHref}${
            component?.type === ComponentType.LESSON ? "" : "?summary=yes"
          }`
        : null;

    const title = (
      <Typography
        className={s.title}
        variant="h5"
        children={
          activity.type === ActivityType.PROGRAM_COMPONENT
            ? component?.title
            : activity.type === ActivityType.CLIENT_FORM
              ? "Form"
              : "Meal submission"
        }
      />
    );

    return (
      <Box display="flex" flexDirection="row">
        {href ? <Link>{title}</Link> : title}

        <Typography className={s.title} variant="h5">
          &nbsp;|&nbsp;
        </Typography>

        <Typography
          className={s.title}
          variant="h5"
          // TODO_API_V2_ACTIVITY: Consider about formatting date. Maybe retrieve it from the server
          children={activity?.date.format("MMM D, YYYY")}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, activityHref, component]);

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <CardContent className={s.content}>
        <Box className={clsx(s.header)}>
          <Box className={s.headerInfo}>
            <Link href={clientHref} className={s.avatarLink}>
              <Avatar
                className={s.avatar}
                variant="square"
                src={client.photoUrl}
                alt={client.displayName}
                children={client.displayName.substring(0, 1).toUpperCase()}
              />
            </Link>

            <Box className={s.headerText}>
              <Link href={clientHref}>
                <Typography
                  className={s.clientName}
                  variant="h4"
                  children={client.displayName}
                />
              </Link>

              {[
                ActivityType.PROGRAM_COMPONENT,
                ActivityType.CLIENT_FORM,
              ].includes(activity.type as ActivityType) && (
                <>
                  {titleNode}

                  <Typography className={s.otherInfo} variant="h5">
                    {activity.component?.type === ComponentType.WORKOUT
                      ? `Completed ${maybePluralize(completedSets, "set")}`
                      : `Answered ${maybePluralize(answers, "question")}`}{" "}
                    {
                      <TimeAgo
                        date={
                          activity.completedAt?.toString() ||
                          activity.submittedAt?.toString()
                        }
                      ></TimeAgo>
                    }
                    {activity.edited ? " (edited)" : ""}
                  </Typography>
                </>
              )}

              {activity.type === ActivityType.MEAL_LOGGING && (
                <>
                  {titleNode}

                  <Typography className={s.otherInfo} variant="h5">
                    Logged {maybePluralize(answers, "meal")}{" "}
                    {
                      <TimeAgo
                        date={activity.completedAt?.toString()}
                      ></TimeAgo>
                    }
                  </Typography>
                </>
              )}
            </Box>
          </Box>

          {enableArchive &&
            (activity.coachArchived ? (
              <Tooltip
                classes={{ tooltip: s.tooltip }}
                arrow
                placement="bottom"
                title="Move to inbox"
              >
                <Button
                  className={s.actionButton}
                  onClick={handleUnarchiveClick}
                  disabled={archiveInFlight}
                  children={<UnarchiveIcon />}
                />
              </Tooltip>
            ) : (
              <Tooltip
                classes={{ tooltip: s.tooltip }}
                arrow
                placement="top"
                title="Archive notification"
              >
                <IconButton
                  className={clsx(s.actionButton, s.archiveButton)}
                  onClick={handleArchiveClick}
                  disabled={archiveInFlight}
                  children={<ArchiveIcon />}
                  size="large"
                />
              </Tooltip>
            ))}

          {user.role === UserRole.CLIENT && (
            <Button
              className={s.actionButton}
              startIcon={<PencilIcon />}
              href={activityHref}
              children="Edit"
            />
          )}
        </Box>

        <div>
          {[ActivityType.PROGRAM_COMPONENT, ActivityType.CLIENT_FORM].includes(
            activity.type as ActivityType,
          ) &&
            metrics.map((metric) => (
              <Box className={s.metric} key={metric.id}>
                <Typography className={s.question}>{metric.name}</Typography>

                {metric.type === MetricSourceType.PROGRESS_PHOTO_SET ? (
                  <Box className={s.answer}>
                    {metric.formattedValue.split("|").map((url, index) => (
                      <ZoomableImage
                        className={s.image}
                        src={url}
                        alt={metric.name}
                        key={index}
                      />
                    ))}
                  </Box>
                ) : metric.type === MetricSourceType.CHECKIN_ANSWER_FILE ? (
                  <Box
                    className={s.file}
                    data-url={metric.file.url}
                    onClick={handleFileClick}
                  >
                    {metric.file.type.includes("pdf") ? (
                      <PdfIcon />
                    ) : (
                      <FileIcon />
                    )}
                    <Typography className={s.caption} variant="caption">
                      {metric.file.name}
                    </Typography>
                  </Box>
                ) : metric.type ===
                  MetricSourceType.CHECKIN_ANSWER_STAR_RATING ? (
                  <Box
                    sx={{
                      pointerEvents: "none",
                    }}
                  >
                    <StarRating value={Number(metric.formattedValue)} small />
                  </Box>
                ) : (
                  <Typography className={s.answer} variant="body1">
                    {metric.formattedValue}
                  </Typography>
                )}
              </Box>
            ))}

          {activity.type === ActivityType.MEAL_LOGGING && (
            <ActivityResponseMealsList activity={activity} />
          )}

          {activity.workoutExerciseResults.map((node) => (
            <ActivityWorkoutResponse
              key={node.id}
              workoutExerciseResult={node}
              handleOpenVideo={handleOpenVideo}
              activity={activity}
            />
          ))}
        </div>
      </CardContent>

      <CardActions className={s.actions}>
        <Box className={s.feedbacks}>
          {[...activity.activityFeedbacks].map((feedback) => (
            <React.Fragment key={feedback.id}>
              <Divider />
              <ActivityResponseItem
                className={s.feedback}
                feedback={feedback}
              />
            </React.Fragment>
          ))}
          <FeedbackForm
            className={s.form}
            activity={activity}
            createFeedbackMutation={createNewFeedback}
          />
        </Box>
      </CardActions>
    </Card>
  );
}
