import { fbUsersClient } from "../api/ApiClients";
import {
  IUpdateUserProfileCommand,
  IVerifyEmailCommand,
  UpdateUserProfileCommand,
  VerifyEmailCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class FbUsersService extends ServiceBase {
  async updateUser(variables: IUpdateUserProfileCommand) {
    const command = UpdateUserProfileCommand.fromJS(variables);

    return fbUsersClient.updateUserProfile(variables.id, command);
  }

  async deleteUser(id: number) {
    return fbUsersClient.deleteUser(id);
  }

  async getCurrentUser() {
    return fbUsersClient.getUserInfo();
  }

  async verifyUserEmail(variables: IVerifyEmailCommand) {
    const command = VerifyEmailCommand.fromJS(variables);
    return fbUsersClient.verifyEmail(command);
  }

  async updatePasswordDate() {
    return fbUsersClient.updatePasswordDate();
  }

  async getAdminUsers(
    role: any,
    pageNumber: number,
    pageSize: number,
    search?: string,
  ) {
    return fbUsersClient.adminGetUsersInfo(role, pageNumber, pageSize, search);
  }

  async getAdminUser(userId: number) {
    return fbUsersClient.adminGetUserInfo(userId);
  }
}

export default new FbUsersService();
