import React, { useEffect, useState } from "react";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { ComponentType } from "../../constants";
import CalendarSidebarTitle from "./components/CalendarSidebarTitle";
import { getColorByComponentType } from "./utils/common";

import { Eye } from "lucide-react";
import { CalendarDays } from "lucide-react";
import { FileText } from "lucide-react";
import SidebarScheduleTab from "./components/SidebarScheduleTab";
import SidebarDetailsTab from "./components/SidebarDetailsTab";
import { useEnableColors } from "../../hooks/useEnableColors";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectComponentBySlugId } from "../../redux/curriculum/selectors/curriculum";
import { extractSlugId } from "../../utils/slug";

enum SidebarTabs {
  DETAILS = "Details",
  PREVIEW = "Preview",
  SCHEDULE = "Schedule",
}

const TABS_ASSETS = [
  { tab: SidebarTabs.DETAILS, icon: <FileText />, title: "Details" },
  { tab: SidebarTabs.PREVIEW, icon: <Eye />, title: "Preview" },
  { tab: SidebarTabs.SCHEDULE, icon: <CalendarDays />, title: "Schedule" },
];

interface IProgramCalendarSidebarProps {
  slug: string;
  setActivityPreview?: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: boolean;
  handleClickOpenDialog: () => void;
  handleCloseDialog: () => void;
  fallback: JSX.Element;
}

const ProgramCalendarSidebar = (props: IProgramCalendarSidebarProps) => {
  const {
    slug,
    setActivityPreview,
    openDialog,
    handleClickOpenDialog,
    handleCloseDialog,
    fallback,
  } = props;
  const theme = useTheme();

  const slugId = extractSlugId(slug);
  const result = useCurriculumSelector(selectComponentBySlugId(slugId));

  // TODO_API_V2_REFRESH_SLUG: Investigate `undefined` result` after component updated with new slug
  const type = result?.type;

  const [activeTab, setActiveTab] = useState<SidebarTabs>(SidebarTabs.DETAILS);
  const enableColors = useEnableColors();
  const componentTypeColor = getColorByComponentType(type as ComponentType);
  const componentRouterPath = `${location.pathname}/${type?.toLowerCase()}/${slug}`;

  const getTabsContent = (activeTab: SidebarTabs) => {
    switch (activeTab) {
      case SidebarTabs.DETAILS:
        return (
          <SidebarDetailsTab
            componentData={result}
            handleClickOpenDialog={handleClickOpenDialog}
          />
        );
      case SidebarTabs.PREVIEW:
        return (
          <iframe
            style={{
              height: 650,
              width: "100%",
              borderRadius: theme.spacing(1),
            }}
            src={`${componentRouterPath}?preview=true`}
            title="Preview"
          />
        );
      case SidebarTabs.SCHEDULE:
        return (
          <SidebarScheduleTab
            componentData={result}
            componentTypeColor={componentTypeColor}
          />
        );
      default:
        break;
    }
  };

  const isIframePreview = activeTab === SidebarTabs.PREVIEW;

  interface ITabProps {
    tab: SidebarTabs;
    icon: JSX.Element;
    title: string;
    index: number;
  }

  const Tab = ({ tab, icon, title, index }: ITabProps) => {
    const isActive = tab === activeTab;
    const isFirst = index === 0;
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        color={
          isActive
            ? enableColors
              ? componentTypeColor
              : theme.palette.common.black
            : "inherit"
        }
        borderColor={
          isActive
            ? enableColors
              ? componentTypeColor
              : theme.palette.common.black
            : "inherit"
        }
      >
        {index !== 0 && (
          <Divider
            orientation="vertical"
            sx={{ height: "50%", marginBlock: "auto", marginInline: 0.25 }}
          />
        )}
        {isActive && !isFirst && (
          <Typography sx={{ pl: 1, fontWeight: 600 }}>{title}</Typography>
        )}
        <IconButton onClick={() => setActiveTab(tab)} color="inherit">
          {icon}
          {isActive && (
            <Divider
              sx={{
                borderColor: "inherit",
                position: "absolute",
                bottom: "4px",
                width: "50%",
              }}
            />
          )}
        </IconButton>
        {isActive && isFirst && (
          <Typography sx={{ pr: 1, fontWeight: 600 }}>{title}</Typography>
        )}
      </Box>
    );
  };

  return (
    result ? (
    <>
      <Box
        display={"flex"}
        justifyContent={"end"}
        sx={{
          borderColor: theme.palette.divider,
          height: "44px",
          position: "absolute",
          top: -44,
          right: 0,
          color: theme.palette.text.secondary,
        }}
      >
        {TABS_ASSETS.map((item, index) => (
          <Tab
            tab={item.tab}
            icon={item.icon}
            title={item.title}
            index={index}
          />
        ))}
      </Box>

      <CalendarSidebarTitle
        title={isIframePreview ? "Preview" : type}
        color={componentTypeColor}
        componentSlug={slug}
        setActivityPreview={setActivityPreview}
        openDialog={openDialog}
        handleClickOpenDialog={handleClickOpenDialog}
        handleCloseDialog={handleCloseDialog}
      />

      <Box key={slug}>{getTabsContent(activeTab)}</Box>
    </>
    ) : fallback
  );
};

export default ProgramCalendarSidebar;
