import React from "react";

export type ProgramWeek = {
  id: number;
  week: number;
};

export const ProgramWeeksContext =
  React.createContext<Array<ProgramWeek>>(null);

export function useProgramWeeks(): ProgramWeek[] {
  return React.useContext(ProgramWeeksContext);
}
