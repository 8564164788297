import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { Schedule } from "../../../../../components/schedule/types";
import { stringifyComponentDays } from "../../../../../utils/component";

export const updateComponentScheduleAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    schedule: Schedule;
  }>,
) => {
  const { componentId, schedule } = action.payload;
  const component = state.value.components[componentId];
  if (!component) return;

  const targetWeekId = Number(schedule.weekId);
  const initWeekId = component.weekId;

  // if schedule include week change
  if (targetWeekId !== initWeekId) {
    state.value.weeks[targetWeekId].components.push(componentId);
    state.value.weeks[initWeekId].components = state.value.weeks[
      initWeekId
    ].components.filter((id) => id !== componentId);

    state.value.weeks[targetWeekId].positions.push(componentId.toString());
    state.value.weeks[initWeekId].positions = state.value.weeks[
      initWeekId
    ].positions.filter((id) => id !== componentId.toString());
  }

  const updatedSchedule = {
    ...schedule,
    weekId: targetWeekId,
    days: stringifyComponentDays(schedule.days),
  };

  state.value.components[componentId] = {
    ...component,
    ...updatedSchedule,
  };
};
