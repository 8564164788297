import { InfiniteData } from "@tanstack/react-query";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import GroupPostsService from "../../../services/GroupPostsService";
import {
  GroupPostCommentDto,
  ICursorPaginatedListOfGroupPostCommentDto,
  UserBriefInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { createInfinitePaginatedDataUpdater } from "../../../utils/optimisticUpdate";
import { GROUP_POST_COMMENTS_LIST_QUERY_KEY } from "../../../hooks/groupPosts/useGroupPostComments";
import dayjs from "dayjs";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { createNewGroupPostComment } from "../utils/GroupUtil";

export const useCreateGroupPostCommentReplyMutation = (
  postId: number,
  parentCommentId: number,
) => {
  const { id: currentUserId, email, displayName, photoUrl } = useCurrentUser();
  const author = UserBriefInfoDto.fromJS({
    id: Number(currentUserId),
    email,
    displayName,
    photoUrl,
  });
  return useOptimisticUpdateMutation({
    disableToastAlerts: true,
    mutationFn: GroupPostsService.createReplyComment,
    queryKey: [GROUP_POST_COMMENTS_LIST_QUERY_KEY, { postId }],
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<ICursorPaginatedListOfGroupPostCommentDto>,
        variables,
        tempId,
      ) => {
        const updateFn = createInfinitePaginatedDataUpdater<
          GroupPostCommentDto,
          void
        >((items, _args, _tempId) =>
          items.map((item) => {
            if (item.id === parentCommentId) {
              item.replies.push(
                createNewGroupPostComment(tempId, author, variables.content),
              );
            }
            return item;
          }),
        );
        return updateFn(oldData);
      },
    },
    successUpdater: {
      updateFn: (oldData, variables, tempId) => {
        const update = createInfinitePaginatedDataUpdater<
          GroupPostCommentDto,
          void
        >(
          (items) =>
            items.map((item) => {
              const updatedReplies = item.replies.map((r) => {
                if (r.id === tempId) {
                  return {
                    ...r,
                    id: variables,
                  };
                }
                return r;
              });

              return {
                ...item,
                replies: updatedReplies,
              };
            }) as GroupPostCommentDto[],
        );

        return update(oldData);
      },
    },
  });
};
