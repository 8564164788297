import React from "react";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { ClientActivityScreen } from "../../../components/screen/ClientActivityScreen";

export function ClientActivityRoute() {
  return (
    <>
      <ClientActivityScreen />
      <TrackInfoTool
        trackInfo={{
          name: "Client - Activity",
        }}
      />
    </>
  );
}
