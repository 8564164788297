import clsx from "clsx";
import React from "react";
import { Container, Box, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { StaticContainer } from "../../components/routes/StaticContainer";
import { NewMessageAction } from "../messages/NewMessageAction";
import { useCurrentUser, useUserIsClient } from "../../hooks/useCurrentUser";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { colorSystem } from "../../theme";
import { polyfillCSS } from "../../utils/css";
import { ThreadsEmpty } from "../messages/ThreadsEmpty";
import { Threads } from "../messages/Threads";
import { Messages } from "../messages/Messages";
import { MessagesEmpty } from "../messages/MessagesEmpty";

import { useNavigate } from "react-router-dom";
import { COACH_MESSAGES_ROUTE, MESSAGES_ROUTE } from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import { UserRole } from "../../constants";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import ThreadsService from "../../services/ThreadsService";
import { DefaultLoader } from "../loading/DefaultLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "unset",
    display: "flex",
    position: "fixed",
    top: theme.spacing(19),
    bottom: 0,
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    paddingRight: polyfillCSS(
      "max(var(--safe-area-inset-right), 0px) !important",
    ),

    [theme.breakpoints.down("md")]: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },

    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.background.default,
    },

    [theme.breakpoints.up("lg")]: {
      position: "static",
      height: "calc(100vh - 128px)",

      paddingLeft: "0 !important",
    },
  },

  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: theme.spacing(2, 0),

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  showThreads: {
    top: polyfillCSS(`calc(${theme.spacing(8)} + var(--safe-area-inset-top))`),

    [theme.breakpoints.up("md")]: {
      top: polyfillCSS(
        `calc(${theme.spacing(23.25)} + var(--safe-area-inset-top))`,
      ),
    },
  },

  showMessages: {
    top: 0,

    [theme.breakpoints.up("md")]: {
      top: theme.spacing(23.25),
    },
  },

  threads: {
    width: "100%",
    flexShrink: 0,
    display: "none",
    overflow: "scroll",

    "$showThreads &": {
      display: "block",
    },

    [theme.breakpoints.up("md")]: {
      display: "block",
      width: theme.spacing(45.5),
      borderRightWidth: 1,
      borderRightStyle: "solid",
      borderRightColor: colorSystem.gray7,
    },
  },

  messages: {
    display: "none",
    width: "100%",

    "$showMessages &": {
      display: "block",
    },

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  newMessage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    padding: theme.spacing(2),
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.quote,

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    background: "white",
  },
}));

export interface MessagingScreenProps {
  selectedThread?: string;
  appDrawer?: boolean;
}

export const MESSAGES_THREADS_QUERY_KEY = "messages-threads";
export const MESSAGES_THREAD_QUERY_KEY = "messages-thread";

export function MessagingScreen(props: MessagingScreenProps) {
  const navigate = useNavigate();
  const s = useStyles(props);
  const isClient = useUserIsClient();
  const { role, id: userId } = useCurrentUser() || {};
  const { selectedThread, appDrawer } = props;
  const queryClient = useQueryClient();
  const { data: threads, isLoading } = useQuery(
    // NOTE: added userId to key to avoid caching when switching users
    {
      queryKey: [MESSAGES_THREADS_QUERY_KEY],
      queryFn: ThreadsService.getAllThreads,
      select: (pages) => pages.items,
    },
  );

  const { data: thread } = useQuery(
    // NOTE: added userId to key to avoid caching when switching users
    {
      queryKey: [MESSAGES_THREAD_QUERY_KEY, { recipient: selectedThread }],
      queryFn: () => ThreadsService.getThread(selectedThread),
      enabled: !!selectedThread,
      refetchOnWindowFocus: false,
    },
  );

  React.useEffect(() => {
    if (thread) {
      // Since new thread initialized in DB after first GET, refetch threads if new thread started
      // TODO_API_V2_REACT_QUERY_V5: Consider better solution as replacement for removed `onSuccess`
      threads &&
        threads.find((t) => t.id === thread.id) === undefined &&
        queryClient.invalidateQueries({
          queryKey: [MESSAGES_THREADS_QUERY_KEY],
        });
    }
  }, [thread]);

  const isMobile = useMediaMobile("md", false);
  const emptyThreads =
    !isLoading && !selectedThread && threads !== undefined && !threads.length;

  React.useEffect(() => {
    if (selectedThread === null) {
      navigate(isClient ? MESSAGES_ROUTE : COACH_MESSAGES_ROUTE);
    }
  }, [selectedThread]);

  return (
    <>
      <Container
        className={clsx(s.root, {
          [s.showThreads]: !selectedThread,
          [s.showMessages]: !!selectedThread,
        })}
        data-role="messages-list-container"
      >
        {emptyThreads ? (
          <ThreadsEmpty />
        ) : (
          <>
            <StaticContainer shouldUpdate={Boolean(threads)}>
              <Threads
                className={s.threads}
                threads={threads || null}
                selectedThread={selectedThread}
              />
            </StaticContainer>

            <Box className={s.messages}>
              {selectedThread ? (
                thread ? (
                  <Messages thread={thread} />
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="80vh"
                  >
                    <DefaultLoader size="large" />
                  </Box>
                )
              ) : (
                <MessagesEmpty />
              )}
            </Box>

            {!selectedThread && isMobile && (
              <Box className={s.newMessage}>
                <NewMessageAction fullWidth />
              </Box>
            )}
          </>
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: `${role === UserRole.COACH ? "Coach" : "Client"} - Messages`,
          properties: {
            thread: selectedThread,
          },
        }}
      />
    </>
  );
}
