import React from "react";

import {
  LoadingButtonWithStatus,
  LoadingButtonWithStatusProps,
} from "./LoadingButtonWithStatus";
import { LoadingActionButtonStyles } from "./LoadingActionButton";
import { useTheme } from "@mui/material";

export interface LoadingActionButtonWithStatusProps
  extends LoadingButtonWithStatusProps {}

function LoadingActionButtonWithStatus(
  props: LoadingActionButtonWithStatusProps,
) {
  const {
    variant = "contained",
    size = "large",
    sx: sxFromProps,
    ...other
  } = props;
  const theme = useTheme();
  const sx = LoadingActionButtonStyles(theme);

  return (
    <LoadingButtonWithStatus
      variant={variant}
      size={size}
      sx={{ ...sx, ...sxFromProps }}
      {...other}
    />
  );
}

export default LoadingActionButtonWithStatus;
