import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  Typography,
  Box,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ArrowBack } from "@mui/icons-material";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { CardMedia } from "../card/CardMedia";
import { useDebounce } from "../../hooks/useDebounce";
import { ProgramSort } from "../../constants";
import { LoadMoreButton } from "../button/LoadMoreButton";

import { CurriculumComponent } from "../../redux/types";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import CoachProgramsService from "../../services/CoachProgramsService";
import { ProgramStatus } from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProgramDialogSkeleton } from "../loading/ProgramDialogSkeleton";
import ComponentsService from "../../services/ComponentsService";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { CURRICULUM_VIEW_KEY } from "../../routes/coach/program/curriculum/CoachProgramCurriculumRoute";
import { extractSlugId } from "../../utils/slug";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectComponentTempIdsMap } from "../../redux/api/selectors";
import { useEditorProgram } from "../new-editor/hooks";

const useStyles = makeStyles((theme) => ({
  root: {},

  programs: {
    minWidth: 524,
    padding: theme.spacing(3),
  },

  weeks: {
    minWidth: 418,
    padding: theme.spacing(0),
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  searchInput: {
    color: theme.palette.text.secondary,
    "& > *": {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 16,
    },
  },

  program: {
    padding: theme.spacing(2, 0),
  },

  image: {
    width: 40,
    height: 40,
    borderRadius: 4,
    marginRight: theme.spacing(2),
  },

  primaryText: {
    fontSize: 18,
    fontWeight: 600,
  },

  list: {
    height: 350,
    overflowY: "auto",
  },

  secondaryText: {
    fontSize: 16,
    fontWeight: 500,
  },

  divider: {
    backgroundColor: theme.palette.quote,
  },

  backIcon: {
    minWidth: theme.spacing(3),
    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },

  backText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },

  week: {
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: theme.spacing(2),
  },
}));

export interface CopyComponentDialogProps extends DialogProps {
  componentData: CurriculumComponent;
}
export const COACH_DIALOG_PROGRAMS_LIST_QUERY_KEY =
  "coach-dialog-programs-list";
export const COACH_PROGRAM_WEEKS_LIST_QUERY_KEY = "coach-program-weeks-list";
const FETCH_NEXT_PAGE_WHEN_REMAINING = 4;
const PAGE_SIZE = 10;

export function CopyComponentDialog(props: CopyComponentDialogProps) {
  const s = useStyles();
  const { className, componentData, ...other } = props;
  const component = componentData;
  const { programId: currentProgramId } = useEditorProgram();
  const tempIdsMap = useCurriculumSelector(selectComponentTempIdsMap);
  const [query, setQuery] = React.useState<string>("");
  const delayedFilter = useDebounce(query, 250);
  const [programId, setProgramId] = React.useState<number>();
  const onError = useGenericErrorHandler({});
  const queryClient = useQueryClient();
  const { showToastAlert } = useToastAlert();
  const { data: programWeekData, isLoading: isLoadingWeeks } = useQuery({
    queryKey: [COACH_PROGRAM_WEEKS_LIST_QUERY_KEY, { programId }],
    queryFn: () => CoachProgramsService.getProgramWeeks(programId),
    enabled: !!programId,
  });
  const { mutate: copyComponent, isPending: isPendingCopy } = useMutation({
    mutationKey: ["copy-component"],
    mutationFn: (variables: { id: number; newWeekId?: number }) =>
      ComponentsService.duplicate(variables.id, variables.newWeekId),
    onSuccess: () => {
      showToastAlert("success", {
        message: "Component was copied",
      });
      const slugId = extractSlugId(programWeekData.programSlug);
      queryClient.invalidateQueries({
        queryKey: [CURRICULUM_VIEW_KEY, { slugId }],
        exact: true,
      });
    },
    onError,
  });

  const {
    data: programsData,
    ref: programElementRef,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: initialLoading,
    isFetching,
  } = useInfiniteScrollQuery({
    queryKey: [COACH_DIALOG_PROGRAMS_LIST_QUERY_KEY, { query: delayedFilter }],
    queryFn: ({ pageParam = 1 }) =>
      CoachProgramsService.getPrograms({
        status: [ProgramStatus.PUBLISHED, ProgramStatus.DRAFT],
        orderBy: ProgramSort.NAME_ASC,
        pageNumber: pageParam as number,
        clientsToTake: 0,
        pageSize: PAGE_SIZE,
        folderId: null,
        ungrouped: null,
        query: delayedFilter ? delayedFilter : null,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.hasNextPage ? pages.length + 1 : undefined;
    },
  });

  const programs = React.useMemo(
    () =>
      programsData?.pages
        ?.flatMap((page) => page.items)
        .filter((program) => program.id !== currentProgramId),
    [programsData?.pages, currentProgramId],
  );

  const handleQueryChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setQuery(event.currentTarget.value);
    },
    [],
  );

  const handleClose = React.useCallback(
    (event) => {
      if (props.onClose) {
        props.onClose(event, "backdropClick");
      }
    },
    [props],
  );

  const handleProgramClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        dataset: { programId },
      } = event.currentTarget;

      setProgramId(Number(programId));
    },
    [],
  );

  const handleBackClick = React.useCallback(() => {
    setProgramId(null);
  }, []);

  const handleWeekClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { weekId } = event.currentTarget.dataset;
      const input = {
        id: tempIdsMap[component.id] || component.id,
        newWeekId: Number(weekId),
      };

      copyComponent(input, {
        onSuccess: () => {
          handleClose(event);
        },
      });
    },
    [component.id, copyComponent, handleClose, tempIdsMap],
  );

  const handleMoreClick = React.useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const isLoadingPrograms =
    initialLoading || (isFetching && !isFetchingNextPage);

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: programId ? s.weeks : s.programs }}
      {...other}
    >
      {programId ? (
        <List>
          <ListItem button onClick={handleBackClick} disabled={isPendingCopy}>
            <ListItemIcon className={s.backIcon} children={<ArrowBack />} />
            <ListItemText
              primary="Select week"
              classes={{ primary: s.backText }}
            />
          </ListItem>
          <Divider />
          {isLoadingWeeks ? (
            Array.from({ length: 5 }).map((_, index) => (
              <ListItem key={index}>
                <Skeleton variant="text" width="50%" />
              </ListItem>
            ))
          ) : programWeekData.weeks.length === 0 ? (
            <ListItem>
              <ListItemText primary="No weeks available." />
            </ListItem>
          ) : (
            programWeekData.weeks.flatMap(({ id, weekNumber }) => (
              <ListItem
                key={id}
                data-week-id={id}
                onClick={handleWeekClick}
                button
                disabled={isPendingCopy}
              >
                <ListItemText
                  primary={`Week ${weekNumber}`}
                  classes={{ primary: s.week }}
                />
              </ListItem>
            ))
          )}
        </List>
      ) : (
        <Box>
          <Box className={s.header}>
            <Typography variant="h4" className={s.title} gutterBottom>
              Choose a program
            </Typography>

            <IconButton onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>

          <Box>
            <TextField
              className={s.searchInput}
              variant="outlined"
              value={query}
              onChange={handleQueryChange}
              placeholder="Search programs"
              fullWidth
            />
            {!isLoadingPrograms && (
              <List className={s.list}>
                {programs?.length ? (
                  programs.map((program, index) => (
                    <React.Fragment key={program.id}>
                      {index > 0 && <Divider className={s.divider} />}
                      <ListItem
                        className={s.program}
                        button
                        data-program-id={program.id}
                        onClick={handleProgramClick}
                      >
                        <ListItemIcon>
                          <CardMedia
                            className={s.image}
                            image={program.image}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: s.primaryText,
                            secondary: s.secondaryText,
                          }}
                          primary={program.name}
                          secondary={`${program.length} week${
                            program.length === 1 ? "" : "s"
                          } • ${program.enrolledTotal} enrolled`}
                        />
                        {index ===
                          programs.length - FETCH_NEXT_PAGE_WHEN_REMAINING && (
                          <div ref={programElementRef} />
                        )}
                      </ListItem>
                    </React.Fragment>
                  ))
                ) : (
                  <Typography variant="subtitle1">No programs found</Typography>
                )}
                {hasNextPage && (
                  <LoadMoreButton
                    onClick={handleMoreClick}
                    fullWidth
                    disabled={isLoadingPrograms}
                    loading={isFetchingNextPage}
                  />
                )}
              </List>
            )}

            {isLoadingPrograms && (
              <List className={s.list}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <ProgramDialogSkeleton key={index} />
                ))}
              </List>
            )}
          </Box>
        </Box>
      )}
    </Dialog>
  );
}
