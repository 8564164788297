import React, { FC, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import { ReactComponent as SaveIcon } from "../../icons/CheckMarkCircle2.svg";
import { floatNumberInputProps } from "../../constants";
import { useClient } from "../../hooks/useClient";
import dayjs from "dayjs";
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import NutritionEntriesService from "../../services/NutritionEntriesService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { CLIENT_NUTRITION_ENTRIES_QUERY_KEY, CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY } from "../../components/client-nutrition-targets/ClientNutritionCard";
import { NutritionEntryDto } from "@growth-machine-llc/stridist-api-client";
import LoadingButton from "../button/LoadingButton";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.1),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  actionIconButton: {
    color: theme.palette.text.secondary,
    "& svg": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),
    },
  },

  date: {
    fontSize: 16,
    color: theme.palette.text.primary,
  },
}));

export interface ClientAutoNutritionEntryHistoryItemProps extends BoxProps {
  entry: any;
  refresh: any;
  clientInfo: { clientId?: number; username?: string };
}

export const ClientAutoNutritionEntryHistoryItem: FC<
  ClientAutoNutritionEntryHistoryItemProps
> = (props) => {
  const { entry: defaultValue, refresh, clientInfo, ...restProps } = props;
  const { clientId, username } = clientInfo;
  const [entry, setEntry] = useState(defaultValue);
  const [dirty, setDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const s = useStyles(open);

  const client: any = useClient();
  const queryClient = useQueryClient();

  const { mutate: updateNutritionEntry, isPending: updateIsLoading } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_NUTRITION_ENTRIES_QUERY_KEY, { username }],
      mutationFn: NutritionEntriesService.updateNutritionEntry,
      optimisticUpdater: {
        updateFn: (
          oldData: NutritionEntryDto[],
          updatedEntry: NutritionEntryDto,
        ) => {
          if (!oldData) return [updatedEntry];
          return oldData.map((entry) =>
            entry.id === updatedEntry.id ? updatedEntry : entry,
          );
        },
      },
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY],
            exact: false,
          });
        },
      },
    });

  const { mutate: deleteNutritionEntry } = useOptimisticUpdateMutation({
    queryKey: [CLIENT_NUTRITION_ENTRIES_QUERY_KEY, { username }],
    mutationFn: NutritionEntriesService.deleteNutritionEntry,
    optimisticUpdater: {
      updateFn: (oldData: NutritionEntryDto[], id) => {
        return oldData.filter((entry) => entry.id !== id);
      },
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY],
          exact: false,
        });
      },
    },
  });

  const handleDelete = React.useCallback(() => {
    deleteNutritionEntry(entry.id);
  }, [deleteNutritionEntry, entry, refresh]);

  const handleSave = React.useCallback(() => {
    const { date, ...inputData } = entry;

    updateNutritionEntry(
      {
        ...inputData,
        date: date,
      },
      {
        onSuccess: (data: any, errors) => {},
      },
    );
  }, [updateNutritionEntry, entry, refresh]);

  const handleChangeField = React.useCallback(
    (field) => {
      return (event) => {
        setDirty(true);
        const newValueString = event.target.value;
        let newValue;
        if (newValueString) {
          newValue = parseFloat(newValueString);
          if (!Number.isFinite(newValue)) {
            return;
          }
        } else {
          newValue = null;
        }
        setEntry({
          ...entry,
          [field]: newValue,
        });
      };
    },
    [setEntry, entry],
  );

  React.useEffect(() => {
    setEntry(defaultValue);
  }, [defaultValue]);

  return (
    <Box py={1} {...restProps}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Button
            fullWidth
            variant="text"
            onClick={() => setOpen(!open)}
            style={{ justifyContent: "flex-start" }}
          >
            <Typography className={s.date} variant="h6">
              {dayjs(entry.date, "YYYY-MM-DD").format("MMM, DD YYYY")}
            </Typography>
          </Button>
        </Grid>
        {!client && (
          <Grid item>
            <LoadingButton
              onClick={handleSave}
              disabled={!dirty || updateIsLoading}
              size="large"
              variant="text"
              loading={updateIsLoading}
            >
              <SaveIcon />
            </LoadingButton>
            <IconButton
              className={s.actionIconButton}
              onClick={handleDelete}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {open && (
        <Grid container>
          {[
            {
              field: "calories",
              label: "Calories",
            },
            {
              field: "carbs",
              label: "Carbs",
            },
            {
              field: "fat",
              label: "Fat",
            },
            {
              field: "protein",
              label: "Protein",
            },
            {
              field: "cholesterol",
              label: "Cholesterol",
            },
            {
              field: "fiber",
              label: "Fiber",
            },
            {
              field: "sugar",
              label: "Sugar",
            },
            {
              field: "sodium",
              label: "Sodium",
            },
            {
              field: "transFat",
              label: "Trans Fat",
            },
            {
              field: "alcohol",
              label: "Alcohol",
            },
          ].map(({ field, label }) => (
            <Grid item xs={6} key={field}>
              <Box p={1}>
                <Typography variant="body1" className={s.label}>
                  {label}
                </Typography>
                <TextField
                  variant="standard"
                  value={entry[field]}
                  onChange={handleChangeField(field)}
                  className={s.input}
                  error={+entry[field] < 0}
                  type="number"
                  InputProps={{
                    inputProps: floatNumberInputProps,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
