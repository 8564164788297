import {
  ICreateStepCountCommand,
  CreateStepCountCommand,
  IUpdateStepCountCommand,
  UpdateStepCountCommand,
} from "@growth-machine-llc/stridist-api-client";

import { stepsCountsClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class StepsCountsService extends ServiceBase {
  async getSteps(clientId: number) {
    return stepsCountsClient.getAllStepsCounts(clientId);
  }
  async createStepsCount(variables: ICreateStepCountCommand) {
    const command = CreateStepCountCommand.fromJS(variables);
    return stepsCountsClient.createStepsCount(command);
  }
  async updateStepsCount(variables: IUpdateStepCountCommand) {
    const command = UpdateStepCountCommand.fromJS(variables);
    return stepsCountsClient.updateStepsCount(command.id, command);
  }
  async deleteStepsCount(id: number) {
    return stepsCountsClient.deleteStepsCount(id);
  }
}

export default new StepsCountsService();
