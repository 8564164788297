import { HttpError } from "../types";

export function reportError(error: Error | HttpError) {
  const Sentry: any = window["Sentry"];

  if (Sentry) {
    console.info("Reporting error to Sentry", error);
    Sentry.captureException(error);
  } else {
    console.error("Captured error, but no Sentry instance found", error);
  }
}

export class ErrorWithCode extends Error {
  code: string;

  constructor(code: string, ...args: any) {
    super(...args);
    this.code = code;
  }
}

export interface ErrorWithStatus {
  status: number;
  title: string;
  trace?: {
    error?: string;
  };
}

export interface BadRequestError extends ErrorWithStatus {
  status: 400;
  errors: Record<string, string[]>;
}

export interface FirebaseError {
  code: string;
  message: string;
}

export const isErrorWithStatus = (error: any): error is ErrorWithStatus =>
  error && "status" in error && "title" in error;

export const isBadRequestError = (
  error: ErrorWithStatus,
): error is BadRequestError =>
  isErrorWithStatus(error) && error.status === 400 && "errors" in error;

export const isFirebaseError = (error: any): error is FirebaseError =>
  error &&
  typeof error.code === "string" &&
  (error.code as string).includes("/");
