import clsx from "clsx";
import React, { ChangeEvent } from "react";
import {
  Box,
  BoxProps,
  TextField,
  Typography,
  TextFieldProps,
} from "@mui/material";
import { StyledComponentProps } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { UnsplashImage } from "./UnsplashImage";

import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import UnsplashImagesService from "../../services/UnsplashImagesService";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { UnsplashImageRecord } from "@growth-machine-llc/stridist-api-client";
import { useMutation } from "@tanstack/react-query";
import { useDebounce } from "../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  root: {},
  query: {
    marginBottom: theme.spacing(2),
  },
  queryInput: {
    fontWeight: 500,
  },
  resultsWrapper: {
    height: 350,
    overflowY: "auto",
  },
  empty: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
    margin: theme.spacing(2, 0),
  },
  grid: {
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "wrap",
    overflowY: "auto",
  },
  image: {
    height: theme.spacing(10),
    width: theme.spacing(18.5),
    marginBottom: theme.spacing(3.5),
    padding: 0,

    "&:last-of-type": {
      marginRight: "auto",
    },
  },
  more: {
    margin: theme.spacing(2, 0),
  },
}));

export interface UnsplashImagesProps
  extends Omit<BoxProps, "onSelect" | "classes">,
    StyledComponentProps<"root" | "image" | "imageSelected"> {
  onSelect?: (value: string) => void;
  pageSize?: number;
  searchInputProps?: TextFieldProps;
  defaultQuery?: string;
  searchInputVariant?: "standard" | "outlined" | "filled";
}

const UNSPLASH_IMAGES_PAGE_SIZE = 16;
const FETCH_NEXT_PAGE_WHEN_REMAINING = 4;
export function UnsplashImages(props: UnsplashImagesProps) {
  const {
    className,
    defaultQuery = "",
    pageSize = UNSPLASH_IMAGES_PAGE_SIZE,
    onSelect,
    searchInputProps = {},
    searchInputVariant,
    classes = {},
    ...other
  } = props;

  const [query, setQuery] = React.useState(defaultQuery);

  const delayedQuery = useDebounce(query, 400);

  const {
    data: unsplashImagesData,
    ref: unsplashLastElementRef,
    isLoading: isLoadingInitial,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: ["unsplash-images", { query: delayedQuery }],
    queryFn: ({ pageParam = 1 }) =>
      UnsplashImagesService.getUnsplashImagesPaginated(
        pageParam as number,
        pageSize,
        query === "" ? undefined : query,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const unsplashImages = React.useMemo(
    () => unsplashImagesData?.pages.flatMap((page) => page.items) ?? [],
    [unsplashImagesData],
  );

  const s = useStyles();
  const [selected, setSelected] = React.useState<string | null>(null);

  const { mutate: trackUnsplashImage, isPending: trackingInFlight } =
    useMutation({
      mutationKey: ["track-unsplash-image"],
      mutationFn: UnsplashImagesService.trackImage,
    });

  const handleChangeQuery = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [],
  );

  const handleMore = React.useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const handleSelect = React.useCallback(
    (image: UnsplashImageRecord) => {
      setSelected(image.id);

      if (onSelect) {
        trackUnsplashImage(image.id, {
          onSuccess: () => {
            onSelect(image.urls.regular);
          },
        });
      }
    },
    [onSelect, trackUnsplashImage],
  );

  return (
    <Box className={clsx(s.root, classes.root, className)} {...other}>
      <TextField
        className={s.query}
        InputProps={{ className: s.queryInput }}
        placeholder="Search for an image"
        variant={searchInputVariant}
        value={query}
        fullWidth
        disabled={isLoadingInitial}
        {...searchInputProps}
        onChange={handleChangeQuery}
      />

      <Box className={s.resultsWrapper}>
        {!isLoadingInitial && unsplashImages.length > 0 ? (
          <Box className={s.grid}>
            {unsplashImages.map((record, index) => (
              <>
                <Box key={record.id} className={clsx(s.image, classes.image)}>
                  <UnsplashImage
                    classes={{ selected: classes.imageSelected }}
                    imageRecord={record}
                    onSelect={handleSelect}
                    selected={selected === record.id}
                    selectPending={selected === record.id && trackingInFlight}
                  />
                </Box>
                {unsplashImages.length - index ===
                  FETCH_NEXT_PAGE_WHEN_REMAINING && (
                  <div
                    id={`unsplash-images-${index}`}
                    ref={unsplashLastElementRef}
                  />
                )}
              </>
            ))}

            {hasNextPage && (
              <LoadMoreButton
                loading={isFetchingNextPage}
                className={s.more}
                onClick={handleMore}
                fullWidth
              />
            )}
          </Box>
        ) : (
          <Typography variant="body1" className={s.empty}>
            {isLoadingInitial ? "Loading..." : "No images found."}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
