import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const removeComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ weekId: number; componentId: number }>,
) => {
  const { componentId, weekId } = action.payload;
  delete state.value.components[componentId];

  const week = state.value.weeks[weekId];
  if (!week) return;

  week.components = week.components.filter((id) => id !== componentId);
  week.positions = week.positions.filter((id) => id !== componentId.toString());
};
