import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Add } from "@mui/icons-material";

import { ActionButton, ActionButtonProps } from "../button/ActionButton";

import { useNavigate } from "react-router-dom";
import { COACH_CLIENTS_FORM_ROUTE } from "../../routes/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ClientFormsCoachService from "../../services/ClientFormsCoachService";
import { CLIENT_FORMS_LIST_QUERY_KEY } from "./CoachClientsFormsListScreen";
import {
  isOptimisticUpdateTempId,
  useOptimisticUpdateMutation,
} from "../../hooks/useOptimisticUpdateMutation";
import {
  ClientFormCoachListDto,
  PaginatedListOfClientFormCoachListDto,
} from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface CreateClientFormActionProps extends ActionButtonProps {}

export function CreateClientFormAction(props: CreateClientFormActionProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const queryClient = useQueryClient();
  const { mutate: createForm, isPending: createFormInFlight } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_FORMS_LIST_QUERY_KEY],
      mutationFn: ClientFormsCoachService.createForm,
      optimisticUpdater: {
        updateFn: (oldData: PaginatedListOfClientFormCoachListDto, _, id) => {
          oldData.items.unshift(
            ClientFormCoachListDto.fromJS({
              id,
              name: null,
              created: dayjs(),
              totalQuestions: 0,
            }),
          );
          return oldData;
        },
      },
      options: {
        onSuccess: (form, _, context: any) => {
          queryClient.setQueriesData(
            { queryKey: [CLIENT_FORMS_LIST_QUERY_KEY] },
            (oldData: PaginatedListOfClientFormCoachListDto) => {
              const item = oldData.items.find(
                (item) => item.id === context.tempId,
              );
              item.id = form.id;
              item.slug = form.slug;
              return oldData;
            },
          );
          navigate(COACH_CLIENTS_FORM_ROUTE.replace(":slug", form.slug));
        },
      },
    });

  const navigate = useNavigate();

  const handleClick = React.useCallback(
    () => createForm({}),

    [createForm],
  );

  return (
    <ActionButton
      className={clsx(s.root, className)}
      startIcon={<Add />}
      size="small"
      children="Add a form"
      disabled={createFormInFlight}
      onClick={handleClick}
      {...other}
    />
  );
}
