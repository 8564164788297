import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  IconButton,
  TextFieldProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ListIcon } from "../../icons/ListCircleSharp.svg";
import { ReactComponent as GridIcon } from "../../icons/GridSharp.svg";
import { AssetType } from "../../constants";

import { LibrarySection, LibrarySections } from "./constants";
import {
  ComponentLibraryAssetFilter,
  defaultLibraryAssetFilters,
} from "./ComponentLibraryAssetFilter";
import { ComponentLibraryAssetList } from "./ComponentLibraryAssetList";
import { ComponentLibraryAssetUploadButton } from "./ComponentLibraryAssetUploadButton";
import { ComponentLibrarySection } from "./ComponentLibrarySection";
import { ComponentLibrarySectionList } from "./ComponentLibrarySectionList";
import { ComponentLibraryAssetListMode } from "./ComponentLibraryAsset";
import { SearchField } from "../fields/SearchField";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  search: {
    margin: theme.spacing(2, 0),
    "& fieldset": {
      borderRadius: 0,
    },
  },

  filters: {
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-2),
  },

  modeButton: {
    marginRight: theme.spacing(-2),

    "& svg": {
      color: theme.palette.text.secondary,
    },
  },

  results: {},
}));

export interface ComponentLibraryProps extends BoxProps {
  section?: LibrarySection;
  onSectionChange?: (section: LibrarySection) => void;
}

export function ComponentLibrary(props: ComponentLibraryProps) {
  const s = useStyles();
  const { className, section, onSectionChange, ...other } = props;
  const [mode, setMode] = React.useState(ComponentLibraryAssetListMode.GRID);
  const [query, setQuery] = React.useState<string>("");
  const [assetFilters, setAssetFilters] = React.useState(
    defaultLibraryAssetFilters,
  );
  const assetTypes = Object.entries(assetFilters)
    .filter(([, value]) => value)
    .map(([key]) => key as AssetType);

  const handleModeToggle = React.useCallback(() => {
    setMode((mode) =>
      mode === ComponentLibraryAssetListMode.GRID
        ? ComponentLibraryAssetListMode.LIST
        : ComponentLibraryAssetListMode.GRID,
    );
  }, []);

  const handleQueryChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setQuery(event.currentTarget.value);
    },
    [],
  );

  React.useEffect(() => {
    setQuery("");
  }, [section]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Typography variant="h2">
          {LibrarySections[section]?.name || "Explore"}
        </Typography>

        {section === LibrarySection.ASSET_LIBRARY && (
          <IconButton
            className={s.modeButton}
            onClick={handleModeToggle}
            size="large"
          >
            {mode === ComponentLibraryAssetListMode.GRID ? (
              <ListIcon />
            ) : (
              <GridIcon />
            )}
          </IconButton>
        )}
      </Box>

      {section === LibrarySection.ASSET_LIBRARY && (
        <>
          <ComponentLibraryAssetFilter
            className={s.filters}
            filters={assetFilters}
            onChange={setAssetFilters}
          />
          <ComponentLibraryAssetUploadButton />
        </>
      )}

      <SearchField
        className={s.search}
        variant="outlined"
        fullWidth
        value={query || ""}
        onChange={handleQueryChange}
        placeholder={
          section === LibrarySection.ASSET_LIBRARY ? "Find an asset" : "Search"
        }
        hideSearchIcon
      />

      {section === LibrarySection.ASSET_LIBRARY ? (
        <Box className={s.results}>
          <ComponentLibraryAssetList
            mode={mode}
            query={query}
            assetTypes={assetTypes}
          />
        </Box>
      ) : section ? (
        <Box className={s.results}>
          <ComponentLibrarySection section={section} query={query} />
        </Box>
      ) : query ? (
        <Box className={s.results}>
          {Object.keys(LibrarySections).map((section: any) =>
            section === LibrarySection.ASSET_LIBRARY ? (
              <ComponentLibraryAssetList
                key={section}
                mode={ComponentLibraryAssetListMode.LIST}
                query={query}
                disableHeader={false}
              />
            ) : (
              <ComponentLibrarySection
                key={section}
                section={section}
                query={query}
              />
            ),
          )}
        </Box>
      ) : (
        <ComponentLibrarySectionList onSectionChange={onSectionChange} />
      )}
    </Box>
  );
}
