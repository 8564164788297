import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Avatar, AvatarProps } from "../../components/avatar/Avatar";

import { AvatarsDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface EnrolledAvatarProps extends AvatarProps {
  enrollment: AvatarsDto;
}

export function EnrolledAvatar(props: EnrolledAvatarProps) {
  const { className, enrollment, ...other } = props;
  const s = useStyles();
  const { user } = enrollment;
  const { displayName, photoUrl } = user;

  return (
    <Avatar 
      className={clsx(className, s.root)} 
      displayName={displayName}
      photoURL={photoUrl}
      {...other} 
    />
  );
}
