import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import MealLoggingService from "../../../services/MealLoggingService";
import { CLIENT_MEALLOGGING_LIST_QUERY_KEY } from "../ClientMealLoggingEntriesList";
import {
  MealLoggingDto,
  PaginatedListOfMealLoggingDto,
  IPaginatedListOfMealLoggingDto,
} from "@growth-machine-llc/stridist-api-client";
import { ACTIVITY_LIST_QUERY_KEY } from "../../activity/CoachActivity";

export const useUpsertMealLoggingEntryMutation = (date: string) => {
  const queryClient = useQueryClient();

  const onMealLoggingUpsert = () => {
    // TODO_API_V2_MEALLOGGING: Replace with optimistic update
    queryClient.invalidateQueries({
      queryKey: [CLIENT_MEALLOGGING_LIST_QUERY_KEY],
      exact: false,
    });
    queryClient.invalidateQueries({ queryKey: [ACTIVITY_LIST_QUERY_KEY] });
  };

  const create = useMutation({
    mutationKey: ["create-meal-logging-entry"],
    mutationFn: MealLoggingService.createMealLoggingEntry,
    onSuccess: onMealLoggingUpsert,
  });

  const update = useMutation({
    mutationKey: ["update-meal-logging-entry"],
    mutationFn: MealLoggingService.updateMealLoggingEntry,
    onSuccess: onMealLoggingUpsert,
  });

  return {
    createMealLogging: create.mutate,
    creatingMealLogging: create.isPending,
    updateMealLogging: update.mutate,
    updatingMealLogging: update.isPending,
  };
};
