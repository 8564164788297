import clsx from "clsx";
import React, { useMemo } from "react";
import { Box, BoxProps, Button, Typography, CardMedia } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { isEmptyContent } from "../editor/utils/common";

import { GroupDescriptionEditor } from "./GroupDescriptionEditor";
import { GroupDescriptionDialog } from "./GroupDescriptionDialog";
import useDependantKey from "../../hooks/useDependantKey";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  label: {
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  button: {
    float: "right",
    marginTop: theme.spacing(-1.5),
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
  },

  cover: {
    width: "100%",
    height: theme.spacing(20),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(3),
  },
}));

export interface GroupDescriptionProps extends BoxProps {
  group: Omit<GroupDto2, "members" | "totalMembersCount">;
}

export function GroupDescription(props: GroupDescriptionProps) {
  const { className, group, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const content = React.useMemo(
    () => JSON.parse(group.description) || [],
    [group.description],
  );
  const canEdit = user.id === group.coachId;

  const key = useDependantKey(content);

  const handleEditDialogOpen = React.useCallback(() => {
    setOpenEditDialog(true);
  }, []);

  const handleEditDialogClose = React.useCallback(() => {
    setOpenEditDialog(false);
  }, []);

  if (!canEdit && isEmptyContent(content)) {
    return null;
  }

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {group.image && <CardMedia className={s.cover} image={group.image} />}
      {canEdit && (
        <Button
          className={s.button}
          variant="text"
          onClick={handleEditDialogOpen}
        >
          Edit
        </Button>
      )}
      <Typography variant="h4" className={s.label}>
        About
      </Typography>

      <Typography component="div" key={key}>
        <GroupDescriptionEditor value={content} readOnly />
      </Typography>

      <GroupDescriptionDialog
        open={openEditDialog}
        description={group.description}
        slug={group.slug}
        onClose={handleEditDialogClose}
      />
    </Box>
  );
}
