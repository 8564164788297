import {
  Card,
  CardProps,
  Box,
  Grid,
  CardContent,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  workoutContainer: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.gray7,
    borderRadius: 4,
    padding: 20,
    cursor: "pointer",
  },
  workout: {
    padding: `${theme.spacing(0)} !important`,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: colorSystem.gray7,
    marginTop: 20,
  },
  footer: {
    marginTop: 24,
    display: "flex",
    justifyContent: "space-between",
  }
}));

export interface WorkoutResultsSkeletonCard extends CardProps {}

export function WorkoutResultsSkeletonCard(props: WorkoutResultsSkeletonCard) {
  const s = useStyles();
  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={s.workoutContainer} {...props}>
          <CardContent className={s.workout}>
            <Box className={s.header}>
                <Box width="100%">
              <Box width="60%">
                <Skeleton animation="wave" height={27} />
              </Box>
              <Box width="50%">
                <Skeleton animation="wave" height={21} />
              </Box>
              </Box>
            </Box>
            <Box className={s.line} />
            <Box className={s.footer}>
              <Box width="17%">
                <Skeleton animation="wave" height={18} />
              </Box>
              <Box width="17%">
                <Skeleton animation="wave" height={18} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
