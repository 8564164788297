import React, { FC, useCallback, useState } from "react";
import { Box, Popover, TextField, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { floatNumberInputProps } from "../../constants";
import { colorSystem } from "../../theme";
import { ReactComponent as CalendarEmptyIcon } from "../../icons/CalendarEmpty.svg";
import { DatePicker } from "../fields/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { clientProfileTextfieldStyles } from "../client-generic-measurement/MeasurementDrawer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import NutritionEntriesService from "../../services/NutritionEntriesService";
import { CLIENT_NUTRITION_ENTRIES_QUERY_KEY, CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY } from "../../components/client-nutrition-targets/ClientNutritionCard";
import { NutritionEntryDto } from "@growth-machine-llc/stridist-api-client";
import LoadingButton from "../button/LoadingButton";

const useStyles = makeStyles((theme) => ({
  input: {
    "& input": {
      fontWeight: 500,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  button: {
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
  },

  calendar: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    height: 56,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    fontSize: 16,
    fontFamily: "Montserrat",
    color: colorSystem.black,
    fontWeight: 500,
    cursor: "pointer",
  },
}));

export interface AddAutoNutritionEntryFormProps {
  entries: readonly any[];
  refresh: any;
  children?: any;
  clientInfo: { clientId?: number; username?: string };
}

const AddAutoNutritionEntryForm: FC<AddAutoNutritionEntryFormProps> = (
  props,
) => {
  const { entries, refresh, children, clientInfo } = props;
  const theme = useTheme();

  const s = useStyles();
  const { clientId, username } = clientInfo;
  const [date, setDate] = useState(() => dayjs(new Date()).startOf("day"));
  const [entry, setEntry] = useState({
    protein: "0",
    carbs: "0",
    fat: "0",
    calories: "0",
  });

  const datePickerState = usePopupState({
    variant: "popover",
    popupId: "auto-nutrition-details-date",
  });

  const queryClient = useQueryClient();

  const { mutate: createNutritionEntry, isPending } = useMutation({
    mutationFn: NutritionEntriesService.createNutritionEntry,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [CLIENT_NUTRITION_ENTRIES_QUERY_KEY, { username }],
        (oldData: NutritionEntryDto[] | undefined) => {
          const existingData = oldData || [];
          
          const newNutritionEntry = NutritionEntryDto.fromJS({
            ...variables,
            id: data,
            clientId,
            lastModified: dayjs(),
          });
  
          return [newNutritionEntry, ...existingData];
        },
      );
      queryClient.invalidateQueries({
        queryKey: [CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY],
        exact: false,
      });
    },
  });
  

  const handleClickSave = React.useCallback(() => {
    createNutritionEntry({
      date: date,
      ...Object.entries(entry).reduce((result, [key, value]) => {
        return {
          ...result,
          [key]: +value,
        };
      }, {}),
    });
  }, [createNutritionEntry, refresh, date, entry]);

  const isValid = useCallback(() => {
    return !Object.values(entry).some((value) => +value < 0);
  }, [entry]);

  const renderField = useCallback(
    (field: string, label: string) => {
      return (
        <Box mb={2.5}>
          <Typography variant="h4" className={s.label}>
            {label}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={entry[field]}
            onChange={(event) => {
              setEntry({
                ...entry,
                [field]: event.target.value,
              });
            }}
            className={s.input}
            error={+entry[field] < 0}
            type="number"
            InputProps={{
              inputProps: floatNumberInputProps,
            }}
            sx={clientProfileTextfieldStyles(theme.palette.primary.main)}
          />
        </Box>
      );
    },
    [entry, setEntry, s],
  );

  return (
    <>
      <Box mb={2.5}>
        <Typography variant="h4" className={s.label}>
          Date
        </Typography>
        <Box {...bindTrigger(datePickerState)} className={s.calendar}>
          <span>{dayjs(date, "YYYY-MM-DD").format("MMM DD YYYY")}</span>
          <CalendarEmptyIcon />
        </Box>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          {...bindPopover(datePickerState)}
        >
          <DatePicker
            value={date.toDate()}
            onChange={(newDate) => {
              setDate((newDate as Dayjs).startOf("day"));
              datePickerState.close();
            }}
            highlightDays={entries.map(({ date }) => dayjs(date).toDate())}
          />
        </Popover>
      </Box>
      {renderField("protein", "Protein")}
      {renderField("carbs", "Carbohydrate")}
      {renderField("fat", "Fat")}
      {renderField("calories", "Calories")}
      <LoadingButton
        fullWidth
        className={s.button}
        variant="contained"
        onClick={handleClickSave}
        disabled={!isValid() && isPending}
        loading={isPending}
      >
        Add entry
      </LoadingButton>
      {children}
    </>
  );
};

export default AddAutoNutritionEntryForm;
