import React from "react";
import {
  Grid,
  Card,
  CardContent,
  BoxProps,
  Box,
  Skeleton,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { DefaultLoader } from "./DefaultLoader";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 1, 2, 3),
    [theme.breakpoints.up("sm")]: {
      boxShadow: "none",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorSystem.gray7,
      borderRadius: 4,
    },
  },
  item: {
    padding: `${theme.spacing(0)} !important`,
  },
  value: {
    height: 34,
    width: theme.spacing(6),
  },
  label: {
    height: 20,
    width: theme.spacing(11),
  },
  chartContainer: {
    height: "100%",
    position: "relative",
  },
  loader: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "107%",
  },
}));

export interface ClientExerciseChooseSkeleton extends BoxProps {}

const ClientExerciseChooseSkeleton = (props: ClientExerciseChooseSkeleton) => {
  const s = useStyles();
  const options = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          padding: 10,
        },
      },

      x: {
        grid: {
          display: false,
        },
        ticks: {},
      },
    },

    responsive: true,
    legend: {
      display: false,
    },
  };
  const dataCharts = {
    labels: [],
    datasets: [],
  };
  return (
    <Grid container spacing={2}>
      {Array.from({ length: 4 }).map((_, i) => (
        <Grid item xs={6} sm={6} md={2}>
          <Card className={s.container}>
            <CardContent className={s.item}>
              <Skeleton animation="wave" className={s.label} />
              <Skeleton animation="wave" className={s.value} />
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={8}>
        <Box className={s.chartContainer}>
          <Box className={s.loader}>
            <DefaultLoader />
          </Box>
          <Bar height={150} data={dataCharts} options={options} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ClientExerciseChooseSkeleton;
