import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { EnrollmentSort, GroupEnrollmentSort } from "../../../../constants";

import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { ProgramEnrollments } from "../../../../components/coach-programs/ProgramEnrollments";
import { PaginationContext } from "../../../../utils/paging";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../../../services/EnrollmentsClientService";
import { extractSlugId } from "../../../../utils/slug";

export const PROGRAM_ENROLLMENTS_QUERY_KEY = "program-enrollments";

export function CoachProgramEnrollmentsRoute() {
  const { slug } = useParams();
  const slugId = extractSlugId(slug);
  const {
    data: programEnrollmentsData,
    isLoading: programEnrollmentDataLoading,
  } = useQuery({
    queryKey: [PROGRAM_ENROLLMENTS_QUERY_KEY, { slugId }],
    queryFn: () =>
      EnrollmentsClientService.getEnrollments({
        programSlug: slug,
      }),
  });

  return (
    <>
      <RefreshSlug
        slugInfo={{
          slug: programEnrollmentsData?.program?.slug,
          slugId: programEnrollmentsData?.program?.slugId,
        }}
      />
      <PaginationContext.Provider
        value={{
          programSlug: slug,
        }}
      >
        <ProgramEnrollments
          enrollments={programEnrollmentsData?.enrollments || []}
          groupEnrollments={programEnrollmentsData?.groupEnrollments || []}
          programEnrollmentDataLoading={programEnrollmentDataLoading}
        />
      </PaginationContext.Provider>

      {!programEnrollmentDataLoading && programEnrollmentsData?.program && (
        <TrackInfoTool
          trackInfo={{
            name: "Coach - Program Enrollments",
            properties: {
              programId: programEnrollmentsData.program.id,
              programSlug: programEnrollmentsData.program.slug,
              programTitle: programEnrollmentsData.program.name,
            },
          }}
        />
      )}
    </>
  );
}
