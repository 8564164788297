import { useDispatch, useSelector } from "react-redux";
import type { CurriculumDispatch, CurriculumRootState } from "./store";

// typed actions hook
export const useCurriculumDispatch =
  useDispatch.withTypes<CurriculumDispatch>();

// typed selector hook
export const useCurriculumSelector =
  useSelector.withTypes<CurriculumRootState>();
