import { keepPreviousData, useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { EnrollmentStatus } from "../../constants";
import { Box, Grid, Typography } from "@mui/material";
import { ClientProgramProgressCard } from "../card/ClientProgramProgressCard";
import React from "react";
import { CardPagination } from "../pagination/CardPagination";
import makeStyles from "@mui/styles/makeStyles";
import { ActiveProgramSkeletonCard } from "../loading/ActiveProgramSkeletonCard";

export const CLIENT_PROFILE_PROGRAMS_LIST_QUERY_KEY =
  "client-overview-programs-list";
const CLIENT_PROGRAMS_PAGE_SIZE = 3;

interface IClientDetailsProgramProps {
  clientId: number;
}
const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const ClientDetailsPrograms = ({ clientId }: IClientDetailsProgramProps) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const s = useStyles();
  const {
    data,
    isFetching,
    isPlaceholderData: isPrevPageData,
    isLoading: isFirstPageFirstFetch,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [
      CLIENT_PROFILE_PROGRAMS_LIST_QUERY_KEY,
      { clientId, currentPage },
    ],
    queryFn: () =>
      EnrollmentsClientService.getClientEnrolments({
        status: EnrollmentStatus.ALL,
        pageNumber: currentPage,
        pageSize: CLIENT_PROGRAMS_PAGE_SIZE,
        clientId: clientId,
      }),
  });

  const showCards = data?.items?.length && !isPrevPageData;
  const showSkeleton = isFirstPageFirstFetch || (isFetching && isPrevPageData);

  return (
    <>
      {showCards
        ? data?.items?.map((enrollment) => (
            <Grid key={enrollment.id} item xs={12}>
              <ClientProgramProgressCard enrollment={enrollment} />
            </Grid>
          ))
        : showSkeleton
          ? Array.from({ length: CLIENT_PROGRAMS_PAGE_SIZE }).map(
              (_, index) => (
                <Grid key={index} item xs={12}>
                  <ActiveProgramSkeletonCard key={index} />
                </Grid>
              ),
            )
          : null}

      {data?.totalPages > 1 && (
        <Box className={s.pagination}>
          <CardPagination
            page={currentPage}
            count={data?.totalPages}
            onChange={(_, page) => setCurrentPage(page)}
          />
        </Box>
      )}
    </>
  );
};

export default ClientDetailsPrograms;
