import clsx from "clsx";
import React, { ComponentProps } from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FeedbackForm } from "../form/FeedbackForm";
import { UserRole } from "../../constants";
import { useCurrentUser, UserInfo } from "../../hooks/useCurrentUser";

import { ActivityFeedback } from "./ActivityFeedback";
import {
  ActivityBriefDto,
  ActivityFeedbackDto,
  IClientFormClientInfoDto,
  IMealLoggingActivityDto,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ActivitiesService from "../../services/ActivitiesService";
import { ACTIVITY_LIST_QUERY_KEY } from "../activity/CoachActivity";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { ACTIVITY_FEEDBACKS_QUERY_KEY } from "./ActivityFeedbackDrawer";
import { CLIENT_MEALLOGGING_ACTIVITY_QUERY_KEY } from "../client-meal-logging/ClientMealLoggingEntriesList";
import { CLIENT_FORM_QUERY_KEY } from "../client-forms/ClientFormScreen";
import { PROGRAM_COMPONENT_QUERY_KEY } from "../../hooks/activities/useActivity";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0),
    paddingBottom: theme.spacing(2.5),
  },

  feedback: {
    marginLeft: 0,
    "&:not(:nth-child(1))": {
      borderTop: `1px solid ${theme.palette.quote}`,
    },
  },

  form: {
    flexDirection: "column",
    marginTop: theme.spacing(2.25),

    "& [data-slate-editor]": {
      "& .MuiTypography-root": {
        minHeight: theme.spacing(14),
      },

      "&::before": {
        left: theme.spacing(1.75),
        top: theme.spacing(1.75),
      },
    },
  },

  button: {
    width: "100%",
    backgroundColor: theme.palette.common.black,
    marginLeft: 0,
    marginTop: theme.spacing(2),
  },
}));

export interface ActivityFeedbackListProps extends BoxProps {
  activity: ComponentProps<typeof FeedbackForm>["activity"];
  feedbacks: ActivityFeedbackDto[];
  submitMutation: ComponentProps<typeof FeedbackForm>["createFeedbackMutation"];
}

export function ActivityFeedbackList(props: ActivityFeedbackListProps) {
  const { className, activity, feedbacks, submitMutation, ...other } = props;
  const s = useStyles();
  const formRef = React.useRef<HTMLFormElement>();
  const user = useCurrentUser();
  const queryClient = useQueryClient();
  const { mutate: read, isPending: readInFlight } = useMutation({
    mutationFn: ActivitiesService.coachReadActivity,
    onSuccess: (_, id) => {
      // updating activity read status on root cache
      queryClient.setQueriesData<
        IMealLoggingActivityDto | IClientFormClientInfoDto | ActivityBriefDto
      >(
        {
          predicate: ({ queryKey }) =>
            [
              CLIENT_MEALLOGGING_ACTIVITY_QUERY_KEY,
              CLIENT_FORM_QUERY_KEY,
              PROGRAM_COMPONENT_QUERY_KEY,
            ].includes(queryKey[0] as string),
        },
        (prev) => ({
          ...prev,
          // Updating answers read status in the meal logging activity
          ...("coachReadAnswers" in prev &&
            prev.id === activity.id && {
              coachReadAnswers: true,
            }),

          // Updating answers read status in the client form activity
          ...("activity" in prev &&
            prev.activity.id === activity.id && {
              activity: {
                ...prev.activity,
                coachReadAnswers: true,
              },
            }),
        }),
      );

      // invalidating `.../activity` route query to refetch the list
      queryClient.invalidateQueries({
        queryKey: [ACTIVITY_LIST_QUERY_KEY],
        exact: false,
        refetchType: "none",
      });
    },
  });

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }
  }, [feedbacks.length]);

  React.useEffect(() => {
    if (
      user.role === UserRole.COACH &&
      !activity.coachReadAnswers &&
      !readInFlight
    ) {
      read(activity.id);
    }
  }, [read, readInFlight, activity.id, activity.coachReadAnswers, user.role]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {feedbacks.map((feedback) => (
        <>
          <ActivityFeedback
            className={s.feedback}
            key={feedback.id}
            feedback={feedback}
            activityId={activity.id}
          />
        </>
      ))}
      <FeedbackForm
        className={s.form}
        createFeedbackMutation={submitMutation}
        activity={activity}
        formRef={formRef}
      />
    </Box>
  );
}
