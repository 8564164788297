import clsx from "clsx";
import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerProps,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientBodyWeightDrawerHeader } from "./DrawerHeader";
import ClientBodyWeightHistoryRow from "./MeasurementHistoryRow";
import { ReactComponent as PlusIcon } from "../../icons/AddCircleOutline.svg";
import {
  floatNumberInputProps,
  MeasurementTypeInfo,
  MeasurementUnit,
  TypeMeasurement,
  Units,
} from "../../constants";
import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  BodyMeasurementRecordDto,
  StepsCountDto,
} from "@growth-machine-llc/stridist-api-client";
import LoadingButton from "../button/LoadingButton";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  header: {
    marginBottom: theme.spacing(4),
  },

  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  save: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
  },

  history: {
    fontSize: 20,
    margin: theme.spacing(3, 4, 2, 0),
  },
  addResult: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    fontWeight: 500,
    float: "right",

    "& svg": {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  select: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  sortOption: {
    fontWeight: 500,
  },

  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),
  },
  sort: {},
}));

export const parseAsFloat = (value: string) => {
  const parsed = parseFloat(value.replace(/^0+/, ""));

  return Number.isNaN(parsed) ? 0 : parsed;
};

export type MetricType = {
  id?: number;
  name?: string;
  date?: dayjs.Dayjs;
  measurement?: number;
  unit?: string;
  index?: number;
  history?: boolean;
  setConfirmDelete?: (value: boolean) => void;
};

export type MeasurementStep = {
  id?: number;
  measuredOn?: dayjs.Dayjs;
  unit?: MeasurementUnit;
  value?: number;
};

export const stepFromBodyMeasurement = (src: BodyMeasurementRecordDto) =>
  ({ ...src }) as MeasurementStep;
export const stepFromStepsCount = (src: StepsCountDto) =>
  ({
    measuredOn: dayjs(src.targetDate),
    id: src.id,
    value: src.count,
  }) as MeasurementStep;

export interface ClientGoalsDrawerEditViewProps extends DrawerProps {
  steps: MeasurementStep[];
  clientId: number;
  disabled: boolean;
  title: string;
  subtitle: string;
  isBodyMeasurement?: boolean;
  creating?: boolean;
  creatingForDate?: dayjs.Dayjs;
  selectedType?: MeasurementTypeInfo;
  items?: {
    label: string;
    name: TypeMeasurement;
  }[];
  updatingId?: number;
  unit?: MeasurementUnit;
  handleSave: (
    { id, date, measurement }: MetricType,
    onSuccess?: () => void,
  ) => void;
  handleRemove: ({ id }: MetricType) => void;
  handleTypeChange?: (value: string) => void;
}
export const clientProfileTextfieldStyles = (color) => ({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: color,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: color,
  },
});
export function MeasurementDrawer(props: ClientGoalsDrawerEditViewProps) {
  const {
    steps,
    clientId,
    className,
    open,
    disabled,
    title,
    subtitle,
    updatingId,
    creating,
    creatingForDate,
    isBodyMeasurement = false,
    items,
    selectedType,
    unit,
    handleSave,
    handleRemove,
    handleTypeChange,
    onClose,
    ...other
  } = props;
  const s = useStyles();
  const theme = useTheme();

  const { units } = useCurrentUser();

  const { showToastAlert } = useToastAlert();

  const today = steps.find(
    (item) =>
      dayjs(item?.measuredOn).format("MM-DD-YYYY") ===
      dayjs().format("MM-DD-YYYY"),
  );

  const [stepsValue, setStepsValue] = React.useState(today?.value || "");
  const [editingIndex, setEditingIndex] = React.useState(-1);
  const [editingDate, setEditingDate] = React.useState(false);
  const [editingValue, setEditingValue] = React.useState(false);
  const [newHistory, setNewHistory] = React.useState<
    MeasurementStep & { newHistory?: boolean }
  >(null);

  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleCancel = React.useCallback(() => {
    setEditingIndex(-1);
  }, []);

  const handleEditing = React.useCallback((value: number) => {
    setEditingIndex(value);
  }, []);

  const handleCreateClick = React.useCallback(() => {
    setNewHistory({
      newHistory: true,
      measuredOn: isBodyMeasurement ? dayjs() : dayjs().subtract(1, "days"),
      value: parseFloat("0"),
    });
    setEditingIndex(0);
  }, [setNewHistory]);

  const valuesHistory: (MeasurementStep & { newHistory?: boolean })[] = [
    newHistory,
    ...(isBodyMeasurement
      ? steps
      : steps.filter(
          (item) =>
            item?.measuredOn.format("DD-MM-YYYY") !==
            dayjs().format("DD-MM-YYYY"),
        )),
  ];

  const handleStepsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target.value === "") {
        setStepsValue(event.target.value);
      } else {
        const value = parseFloat(event.target.value);
        setStepsValue(value);
      }
    },
    [],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: clsx(s.paper),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      {...other}
      children={
        <Box className={clsx(className)}>
          <ClientBodyWeightDrawerHeader
            className={s.header}
            onClose={handleClose}
            title={title}
          />
          {isBodyMeasurement ? (
            <Select
              className={clsx(s.select, s.sort)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }}
              variant="outlined"
              fullWidth
              onChange={(e) => handleTypeChange(e.target.value)}
              value={selectedType?.name}
            >
              {items.map(({ label, name }) => (
                <MenuItem className={s.sortOption} key={label} value={name}>
                  <ListItemText
                    classes={{ primary: s.primaryText }}
                    primary={label}
                  />
                </MenuItem>
              ))}
            </Select>
          ) : (
            <>
              <Box className={s.inputWrapper}>
                <Typography variant="h4" className={s.label}>
                  {subtitle}
                </Typography>
                <Box className={s.box}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={stepsValue}
                    onChange={handleStepsChange}
                    className={s.input}
                    error={stepsValue === null}
                    autoFocus
                    type={"number"}
                    InputProps={{
                      inputProps: floatNumberInputProps,
                    }}
                    sx={clientProfileTextfieldStyles(
                      theme.palette.primary.main,
                    )}
                  />
                  <LoadingButton
                    variant="text"
                    color="primary"
                    className={s.save}
                    loading={
                      creatingForDate === today?.measuredOn ||
                      updatingId === today?.id
                    }
                    onClick={() =>
                      handleSave(
                        {
                          id: today?.id,
                          date: dayjs(new Date()),
                          measurement: +stepsValue,
                        },
                        () => {
                          showToastAlert("success", {
                            message: `${today?.id ? "Saved" : "Created"} successfully`,
                          });
                          !today?.id && handleClose();
                        },
                      )
                    }
                    disabled={disabled}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </>
          )}
          <Box>
            <Box className={s.historyContainer}>
              <Typography variant="h4" className={s.history}>
                History
              </Typography>
              {!newHistory && (
                <Button
                  variant="text"
                  color="primary"
                  className={s.addResult}
                  onClick={handleCreateClick}
                  disabled={disabled}
                >
                  Add result
                  <PlusIcon />
                </Button>
              )}
            </Box>
            <Divider />
            <Box>
              {valuesHistory.map(
                (item, index) =>
                  item && (
                    <React.Fragment key={index}>
                      <ClientBodyWeightHistoryRow
                        editing={index === editingIndex}
                        waitingForEditConfirmation={
                          updatingId === item?.id ||
                          (creating && item?.newHistory)
                        }
                        handleEditing={() => handleEditing(index)}
                        onCancel={handleCancel}
                        editingDate={editingDate}
                        value={item}
                        setEditingDate={setEditingDate}
                        editingValue={editingValue}
                        setEditingValue={setEditingValue}
                        index={index}
                        onSave={handleSave}
                        disabled={disabled}
                        onRemove={handleRemove}
                        setNewHistory={setNewHistory}
                        newHistory={newHistory}
                        today={today}
                        isBodyMeasurement={isBodyMeasurement}
                        unit={unit}
                        selectedType={selectedType}
                      />
                      {index < valuesHistory.length - 1 && <Divider />}
                    </React.Fragment>
                  ),
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
