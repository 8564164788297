import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SelectProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SearchField } from "../fields/SearchField";
import {
  programSortOptions,
  ProgramSort,
  ProgramFilterFolder,
} from "../../constants";
import { ReactComponent as DisplayAsGridIcon } from "../../icons/DisplayAsGrid.svg";
import { ReactComponent as DisplayAsListIcon } from "../../icons/DisplayAsList.svg";

import { CoachProgramCardDisplay } from "./CoachProgramCard";
import { ProgramFolderDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  search: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),
  },

  select: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=combobox]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },

  sort: {},

  view: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  viewList: {
    padding: theme.spacing(1),
  },

  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),

    "$sort [role=combobox] &": {
      "&::before": {
        content: '"Sort by: "',
      },
    },

    "$view [role=combobox] &": {
      display: "none !important",
    },
  },

  icon: {
    color: theme.palette.text.secondary,
    minWidth: "auto",

    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },

    "[role=combobox] & svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

  sortOption: {
    fontWeight: 500,
  },
}));

export interface CoachProgramsFilterProps extends BoxProps {
  programFolders?: ProgramFolderDto[];

  query: string;
  folderId: ProgramFilterFolder;
  sortKey: ProgramSort;

  onChangeQuery?: (query: string) => void;
  onChangeFolder?: (folder: ProgramFilterFolder) => void;
  onChangeSortKey?: (sortKey: ProgramSort) => void;

  display?: CoachProgramCardDisplay;
  onChangeDisplay?: (display: CoachProgramCardDisplay) => void;
}

export function CoachProgramsFilter(props: CoachProgramsFilterProps) {
  const s = useStyles();
  const {
    className,
    programFolders,
    query,
    folderId,
    sortKey,
    onChangeQuery,
    onChangeFolder,
    onChangeSortKey,
    display = CoachProgramCardDisplay.list,
    onChangeDisplay,

    ...other
  } = props;

  const handleChangeQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const query = event.currentTarget.value;

      if (onChangeQuery) {
        onChangeQuery(query);
      }
    },
    [onChangeQuery],
  );

  const handleChangeFolder = React.useCallback<SelectProps["onChange"]>(
    (event) => {
      const value = event.target.value;

      const folder = value === "" ? value : (value as ProgramFilterFolder);
      if (onChangeFolder) {
        onChangeFolder(folder);
      }
    },
    [onChangeFolder],
  );

  const handleChangeSort = React.useCallback(
    (event) => {
      const sortKey = event.target.value as ProgramSort;

      if (onChangeSortKey) {
        onChangeSortKey(sortKey);
      }
    },
    [onChangeSortKey],
  );

  const handleChangeDisplay = React.useCallback(
    (event) => {
      const display = event.target.value as CoachProgramCardDisplay;

      if (onChangeDisplay) {
        onChangeDisplay(display);
      }
    },
    [onChangeDisplay],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <SearchField
        className={s.search}
        variant="outlined"
        fullWidth
        hideSearchIcon
        placeholder="Search programs"
        value={query}
        onChange={handleChangeQuery}
      />
      <Select
        className={clsx(s.select, s.sort)}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        variant="outlined"
        fullWidth
        value={sortKey}
        onChange={handleChangeSort}
      >
        {Object.entries(programSortOptions).map(([key, label]) => (
          <MenuItem className={s.sortOption} key={key} value={key}>
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary={label}
            />
          </MenuItem>
        ))}
      </Select>

      <Select
        className={clsx(s.select)}
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          classes: {
            list: s.viewList,
          },
        }}
        value={folderId}
        onChange={handleChangeFolder}
        disabled={!programFolders}
        displayEmpty
      >
        <MenuItem value={null}>
          <ListItemText
            classes={{ primary: s.primaryText }}
            primary="Any folder"
          />
        </MenuItem>
        <MenuItem value={""}>
          <ListItemText
            classes={{ primary: s.primaryText }}
            primary="Ungrouped"
          />
        </MenuItem>
        {(programFolders || []).map((programFolder) => {
          return (
            <MenuItem key={programFolder.id} value={programFolder.id}>
              <ListItemText
                classes={{ primary: s.primaryText }}
                primary={programFolder.name}
              />
            </MenuItem>
          );
        })}
      </Select>

      <Select
        className={clsx(s.select, s.view)}
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          classes: {
            list: s.viewList,
          },
        }}
        value={display}
        onChange={handleChangeDisplay}
      >
        <MenuItem value={CoachProgramCardDisplay.grid}>
          <ListItemText
            classes={{ primary: s.primaryText }}
            primary="Grid View"
          />
          <ListItemIcon className={s.icon}>
            <DisplayAsGridIcon />
          </ListItemIcon>
        </MenuItem>

        <MenuItem value={CoachProgramCardDisplay.list}>
          <ListItemText
            classes={{ primary: s.primaryText }}
            primary="List View"
          />
          <ListItemIcon className={s.icon}>
            <DisplayAsListIcon />
          </ListItemIcon>
        </MenuItem>
      </Select>
    </Box>
  );
}
