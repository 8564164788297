import React, { useContext, useState } from "react";
import { Container, Portal, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";

import { CreateFirstProgramFolder } from "../program/CreateFirstProgramFolder";
import { polyfillCSS } from "../../utils/css";
import { PageSkeleton } from "../loading/PageSkeleton";
import {
  ProgramFolderUpsertDialog,
  ProgramFolderUpsertDialogProps,
} from "./ProgramFolderUpsertDialog";

import { ProgramFolderCard } from "./ProgramFolderCard";
import {
  ProgramFolderDeleteDialog,
  ProgramFolderDeleteDialogProps,
} from "./ProgramFolderDeleteDialog";
import ProgramsFoldersButtonContext from "../../contexts/ProgramsFoldersPopupContext";
import TrackInfoTool from "../tools/TrackInfoTool";
import { useCoachProgramsListScreenContext } from "../../contexts/CoachProgramsListScreenContext";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(5)} + var(--safe-area-inset-right)) !important`,
    ),
  },

  card: {
    marginBottom: theme.spacing(3),
  },
}));

export function CoachProgramsFoldersScreen() {
  const s = useStyles();

  const { programFolders, programFoldersLoading: isLoading } =
    useCoachProgramsListScreenContext();

  const deleteDialogState = usePopupState({
    variant: "popover",
    popupId: "program-folder-delete",
  });
  const context = useContext(ProgramsFoldersButtonContext);
  const renameDialogState = context;

  const handleUpserted: ProgramFolderUpsertDialogProps["onUpserted"] =
    React.useCallback(() => {
      renameDialogState.close();
    }, [renameDialogState]);

  const handleDeleted: ProgramFolderDeleteDialogProps["onDeleted"] =
    React.useCallback(() => {
      deleteDialogState.close();
    }, [deleteDialogState]);

  const [actionableFolderId, setActionableFolderId] = useState<number | null>(
    null,
  );

  const hasProgramFolders = programFolders?.length > 0;

  return (
    <>
      <Container className={s.root} maxWidth="xl">
        {isLoading ? (
          <PageSkeleton fullWidth />
        ) : hasProgramFolders ? (
          programFolders.map((programFolder) => (
            <ProgramFolderCard
              key={programFolder.id}
              className={s.card}
              folder={programFolder}
              onRenameClick={() => {
                setActionableFolderId(programFolder.id);
                renameDialogState.open();
              }}
              onDeleteClick={() => {
                setActionableFolderId(programFolder.id);
                deleteDialogState.open();
              }}
            />
          ))
        ) : (
          <CreateFirstProgramFolder {...bindTrigger(renameDialogState)} />
        )}
      </Container>
      {renameDialogState.isOpen && (
        <ProgramFolderUpsertDialog
          {...bindPopover(renameDialogState)}
          onClose={() => {
            if (actionableFolderId) {
              setActionableFolderId(null);
            }
            renameDialogState.close();
          }}
          folderId={actionableFolderId}
          folderName={
            actionableFolderId
              ? programFolders.find(({ id }) => id === actionableFolderId)?.name
              : undefined
          }
          onUpserted={handleUpserted}
        />
      )}
      {deleteDialogState.isOpen && (
        <ProgramFolderDeleteDialog
          {...bindPopover(deleteDialogState)}
          onClose={() => {
            if (actionableFolderId) {
              setActionableFolderId(null);
            }
            deleteDialogState.close();
          }}
          folderId={actionableFolderId}
          onDeleted={handleDeleted}
        />
      )}

      <TrackInfoTool
        trackInfo={{
          name: "Coach - Program Folders",
          properties: {},
        }}
      />
    </>
  );
}
