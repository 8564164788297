import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";

import { GroupNavItemListItem } from "./GroupNavItemListItem";
import { useInfiniteQuery } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import LoadingButton from "@mui/lab/LoadingButton";
import { GroupSort, GroupStatus } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(8),
  },

  more: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
    padding: theme.spacing(0.5),

    "&:hover": {
      background: "none !important",
    },

    "& svg": {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      marginLeft: theme.spacing(0.5),
    },
  },
}));

export interface GroupNavItemListProps extends BoxProps {}

export const NAVBAR_GROUP_LIST_QUERY_KEY = "get-current-user-groups";

const GROUP_NAV_ITEM_LIST_PAGE_SIZE = 5;

export function GroupNavItemList(props: GroupNavItemListProps) {
  const { className, ...other } = props;
  const s = useStyles();

  const {
    data: groupsData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage: loading,
  } = useInfiniteQuery({
    queryKey: [NAVBAR_GROUP_LIST_QUERY_KEY],
    queryFn: ({ pageParam }) =>
      GroupsService.getGroups(
        GroupStatus.ACTIVE,
        GroupSort.NAME_ASC,
        pageParam ?? 1,
        GROUP_NAV_ITEM_LIST_PAGE_SIZE,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.hasNextPage ? pages.length + 1 : undefined;
    },
    placeholderData: {
      pages: [],
      pageParams: [],
    },
  });

  const groups = groupsData?.pages?.flatMap((page) => page.items);

  const handleMoreClick = React.useCallback(() => {
    if (!loading) {
      fetchNextPage();
    }
  }, [loading, fetchNextPage]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {groups.map(({ id, name, slug }) => (
        <GroupNavItemListItem key={id} slug={slug} name={name} />
      ))}

      {hasNextPage && (
        <LoadingButton
          className={s.more}
          onClick={handleMoreClick}
          loading={loading}
          endIcon={<CaretDownIcon />}
        >
          <span>Show more</span>
        </LoadingButton>
      )}
    </Box>
  );
}
