import React from "react";

import LoadingButton, { LoadingButtonProps } from "./LoadingButton";
import { CircularProgress, Theme } from "@mui/material";

export interface LoadingButtonActionProps extends LoadingButtonProps {}

export const LoadingActionButtonStyles = (theme: Theme, loading?: boolean) => ({
  "&.MuiButton-root": {
    borderRadius: theme.spacing(0.5),
    fontSize: 16,
    fontWeight: 700,
    whiteSpace: "nowrap",

    "&.Mui-disabled, &[disabled]": {
      color: loading ? "transparent" : theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },
  },

  "&.MuiButton-sizeSmall": {
    padding: theme.spacing(0.75, 5.5),
  },
  "&.MuiButton-sizeMedium": {
    padding: theme.spacing(0.75, 8.25),
  },
  "&.MuiButton-sizeLarge": {
    padding: theme.spacing(1.5, 4.25),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1.5, 6.25),
    },
  },

  "& .MuiCircularProgress-root": {
    color: theme.palette.common.white,
  },
});

const LoadingActionButton = ({
  children,
  ...props
}: LoadingButtonActionProps) => {
  const { variant = "contained", size = "large", loading, ...other } = props;
  return (
    <LoadingButton
      variant={variant}
      size={size}
      loading={loading}
      sx={(theme) => {
        return LoadingActionButtonStyles(theme, loading);
      }}
      {...other}
    >
      {children}
    </LoadingButton>
  );
};

export default LoadingActionButton;
