import React from "react";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  useCurrentBrand,
  useCurrentWorkspace,
} from "../../hooks/useCurrentWorkspace";
import { UserRole } from "../../constants";
import { generateThumbnailUrl } from "../../utils/thumbnail";

import logo from "./logo.svg";
import logoColor from "./logo-color.svg";
import logoFull from "./logo-full.svg";
import logoFullMono from "./logo-full-mono.svg";

export interface AppLogoProps {
  className?: string;
  size?: number;
  main?: boolean;
  full?: boolean;
  color?: boolean;
  width?: number;
  height?: number;
  onClick?: () => void;
  appStoreLogo?: boolean;
}

export function AppLogo(props: AppLogoProps) {
  const {
    className,
    size = 60,
    main = false,
    full = false,
    color = false,
    width = 60,
    height = 60,
    appStoreLogo,
    onClick,
  } = props;
  const user = useCurrentUser();
  const workspace = useCurrentWorkspace();
  const { isBrandedApp } = useCurrentBrand();
  const hasLogo = Boolean(workspace?.logoUrl);

  const logoURL = React.useMemo(
    () =>
      isBrandedApp || (!main && workspace?.logoUrl)
        ? generateThumbnailUrl({
            src: appStoreLogo
              ? workspace?.brandedApp?.logoUrl
              : workspace?.logoUrl,
            scale: {
              w: size,
              h: size,
            },
          })
        : full
          ? color
            ? logoFull
            : logoFullMono
          : color
            ? logoColor
            : logo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [color, full, main, size, user, workspace?.logoUrl],
  );

  return (
    <img
      className={className}
      src={logoURL}
      width={hasLogo ? size : width}
      height={hasLogo ? size : height}
      alt={main ? "Stridist" : workspace?.name}
      onClick={onClick}
    />
  );
}
