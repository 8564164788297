import { EnrollmentsVm } from "@growth-machine-llc/stridist-api-client";
import { PROGRAM_ENROLLMENTS_QUERY_KEY } from "../../../routes/coach/program/enrollments/CoachProgramEnrollmentsRoute";
import EnrollmentsClientService from "../../../services/EnrollmentsClientService";
import { COACH_PROGRAMS_WRAPPER_QUERY_KEY } from "../../../wrappers/router/coach/CoachProgramsWrapper";
import {
  MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY,
  MANAGE_PROGRAM_GROUPS_DIALOG_QUERY_KEY,
} from "../../groups/ClientsAndGroupsSelectDialog";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { useQueryClient } from "@tanstack/react-query";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../../coach-programs/CoachProgramsListScreen";
import { extractSlugId } from "../../../utils/slug";

export const useUnenrollMutation = (type, programSlug) => {
  const queryClient = useQueryClient();

  const mutationFn =
    type === "group"
      ? EnrollmentsClientService.unenrollGroup
      : EnrollmentsClientService.unenrollClient;

  const successToastMessage = "Enrollment has been removed.";
  const slugId = extractSlugId(programSlug);
  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [PROGRAM_ENROLLMENTS_QUERY_KEY, { slugId }],
    });

    queryClient.invalidateQueries({
      queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
    });

    queryClient.invalidateQueries({
      queryKey: [COACH_PROGRAMS_WRAPPER_QUERY_KEY, { slugId }],
    });

    queryClient.resetQueries({
      queryKey:
        type === "group"
          ? [MANAGE_PROGRAM_GROUPS_DIALOG_QUERY_KEY]
          : [MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY],
      exact: false,
    });
  };

  const optimisticUpdater = (oldData, id) => {
    if (type === "client") {
      return new EnrollmentsVm({
        ...oldData,
        enrollments: oldData.enrollments
          ? oldData.enrollments.filter((enrollment) => enrollment.id !== id)
          : [],
      });
    } else if (type === "group") {
      const groupIdToRemove = oldData.groupEnrollments.find(
        (groupEnrollment) => groupEnrollment.id === id,
      )?.group?.id;

      return new EnrollmentsVm({
        ...oldData,
        enrollments: oldData.enrollments
          ? oldData.enrollments.filter(
              (enrollment) =>
                !(
                  enrollment.viaGroup && enrollment.groupId === groupIdToRemove
                ),
            )
          : oldData.enrollments,
        groupEnrollments: oldData.groupEnrollments
          ? oldData.groupEnrollments.filter(
              (enrollment) => enrollment.id !== id,
            )
          : oldData.groupEnrollments,
      });
    }
    return oldData;
  };

  return useOptimisticUpdateMutation({
    queryKey: [PROGRAM_ENROLLMENTS_QUERY_KEY, { slugId }],
    mutationFn,
    optimisticUpdater: { updateFn: optimisticUpdater },
    successToastMessage,
    options: { onSuccess },
  });
};
