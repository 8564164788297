import React from "react";

import {
  BrandedAppStatus,
  IWorkspaceInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import { brandedAppWorkspaceKey } from "../utils/mobile";

export const CurrentWorkspaceContext =
  React.createContext<IWorkspaceInfoDto>(null);

export function useCurrentWorkspace() {
  return React.useContext(CurrentWorkspaceContext);
}

export function useCurrentBrand() {
  const workspace = useCurrentWorkspace();
  const brandedApp = workspace?.brandedApp;

  // Indicates if the current environment is a branded mobile app.
  const isMobileBrandedApp = Boolean(brandedAppWorkspaceKey);

  // Indicates if the current workspace is branded.
  const isBrandedWorkspace =
    brandedApp?.status === BrandedAppStatus.IN_PROGRESS ||
    brandedApp?.status === BrandedAppStatus.PUBLISHED;

  // Indicates if the branded app is published to the stores.
  const isBrandedAppPublished =
    brandedApp?.status === BrandedAppStatus.PUBLISHED;

  // Indicates if the current environment is branded, either as a mobile app or a workspace.
  const isBranded = isMobileBrandedApp || isBrandedWorkspace;

  // The name of the brand, using the workspace name if branded; otherwise or if workspace name is empty, defaults to "Stridist".
  const brandName =
    isBrandedWorkspace && workspace?.name ? workspace.name : "Stridist";

  // The name of the app in app stores. It can be either branded app name (if app is published) or Stridist.
  const appName = isBrandedAppPublished ? brandedApp?.name : "Stridist";

  // App URL in Apple AppStore
  const AppleStoreUrl = isBrandedAppPublished
    ? brandedApp?.appleStoreUrl
    : process.env.REACT_APP_APP_STORE_URL;

  const brandedAppLogo = brandedApp?.logoUrl ?? workspace?.logoUrl;

  // App URL in Google Play Market
  const GoogleStoreUrl = isBrandedAppPublished
    ? brandedApp?.googlePlayUrl
    : process.env.REACT_APP_GOOGLE_PLAY_URL;

  return {
    isBrandedApp: isMobileBrandedApp,
    isBrandedWorkspace,
    brandName,
    isBranded,
    appName,
    AppleStoreUrl,
    GoogleStoreUrl,
    isBrandedAppPublished,
    brandedAppLogo,
  };
}
