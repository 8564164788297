import {
  CreateBodyMeasurementCommand,
  ICreateBodyMeasurementCommand,
  IUpdateBodyMeasurementCommand,
  UpdateBodyMeasurementCommand,
} from "@growth-machine-llc/stridist-api-client";
import { bodyMeasurementClient } from "../api/ApiClients";
import ServiceBase from "./ServiceBase";

class BodyMeasurementService extends ServiceBase {
  async getBodyMeasurements(clientId: number) {
    return bodyMeasurementClient.getAllMeasurements(clientId);
  }
  async createBodyMeasurement(variables: ICreateBodyMeasurementCommand) {
    const command = CreateBodyMeasurementCommand.fromJS(variables);
    return bodyMeasurementClient.createMeasurement(command);
  }
  async updateBodyMeasurement(variables: IUpdateBodyMeasurementCommand) {
    const command = UpdateBodyMeasurementCommand.fromJS(variables);
    return bodyMeasurementClient.updateMeasurement(command.id, command);
  }
  async deleteBodyMeasurement(id: number) {
    return bodyMeasurementClient.deleteMeasurement(id);
  }
}

export default new BodyMeasurementService();
