import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  Checkbox,
  CheckboxProps,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog, BaseDialogProps } from "./BaseDialog";

import {
  ClientDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import ClientsService from "../../services/ClientsService";

const useStyles = makeStyles((theme) => ({
  root: {},

  list: {
    maxHeight: 420,
    overflowY: "scroll",
    // TODO: Add scroll styling
  },

  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 0),
  },

  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  name: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
  },

  email: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  sectionDivider: {},

  button: {
    marginTop: theme.spacing(2),
  },
}));

export interface SelectClientsDialogProps
  extends Omit<DialogProps, "title">,
    Pick<BaseDialogProps, "title" | "description"> {
  onConfirm: (clients: ClientDto[]) => void;
  minSelected?: number;
  maxSelected?: number;
}

export function SelectClientsDialog(props: SelectClientsDialogProps) {
  const {
    className,
    onClose,
    title = "Select clients",
    onConfirm,
    maxSelected = Infinity,
    ...other
  } = props;

  const s = useStyles();
  const [selectedClients, setSelectedClients] = React.useState<ClientDto[]>([]);

  const { data: clientsData, isLoading } = useQuery({
    queryKey: ["select-dialog-clients"],
    queryFn: () => ClientsService.getAllClients([UserInviteStatus.ACTIVE]),
  });

  const clients = React.useMemo(() => {
    return clientsData?.items || [];
  }, [clientsData]);

  const handleClose = React.useCallback(
    (event = {}) => {
      setSelectedClients([]);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleClientToggle: CheckboxProps["onChange"] = React.useCallback(
    (event, checked) => {
      const { email } = event.currentTarget.dataset;

      setSelectedClients((selectedClients) => {
        if (!checked) {
          return selectedClients.filter((c) => c.email !== email);
        } else {
          const client = clients.find(
            ({ email: clientEmail }) => clientEmail === email,
          );

          return client && selectedClients.length < maxSelected
            ? [...selectedClients, client]
            : selectedClients;
        }
      });
    },
    [clients, maxSelected],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={title}
      onClose={handleClose}
      {...other}
    >
      <Box className={s.list}>
        {isLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} height={30} sx={{ mb: 1.5 }} />
            ))
          : clients.map(({ email, displayName }, index, array) => (
              <React.Fragment key={email}>
                <Box className={s.item}>
                  <Checkbox
                    onChange={handleClientToggle}
                    checked={Boolean(
                      selectedClients.find((client) => client.email === email),
                    )}
                    inputProps={
                      {
                        "data-email": email,
                      } as any
                    }
                  />
                  <Box className={s.info}>
                    <Typography className={s.name} children={displayName} />
                    <Typography className={s.email} children={email} />
                  </Box>
                </Box>
                {index < array.length - 1 && <Divider />}
              </React.Fragment>
            ))}
      </Box>
    </BaseDialog>
  );
}
